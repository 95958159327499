import React, { useEffect, useState } from 'react'
import { plazaServices } from '../tollPlaza/plazaReportService';
import HighchartsReact from "highcharts-react-official";
import { useHistory } from 'react-router-dom';
import Highcharts from "highcharts";
import moment from 'moment';

export const Dashboard = () => {
    const [data, setData] = useState([])
    const [orderbyStatus, setOrderByStatus] = useState([])
    const [line, setLine] = useState(null);
    const [masterData, setMasterData] = useState([])
    const history = useHistory();
    useEffect(() => {
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $group: {
                        _id: "$walmart.listingStatus",
                        count: { $sum: 1 },

                    }
                },
                {
                    $project: {
                        _id: 0,
                        status: "$_id",
                        count: 1
                    }
                },
            ]
        }
        const orderStatusPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $group: {
                        _id: "$lastStatus",
                        count: { $sum: 1 },
                        total: { $sum: `$charge.amount` },
                        totalType: { $sum: `$qty` }

                    }
                },
                {
                    $project: {
                        _id: 0,
                        status: "$_id",
                        count: 1,
                        total: 1,
                        totalType: 1
                    }
                },
            ]
        }

        Promise.all([plazaServices.general(serviceId, payload, "nq-processing-status"), plazaServices.general(serviceId, orderStatusPayload, "nq-order-items")]).then((res) => {
            let data = pieChartType(res[0], "Status Wise Isbn Listing Breakup", "/walmart-tittle")
            setData(data);
            data = pieChartType(res[1], "Status Wise Order Breakup", "/wm-orders")
            setOrderByStatus(data);
            let statusMap = {
                "ShippedToUS": { count: 0, total: 0, status: "ShippedToUS" },
                "Shipped": { count: 0, total: 0, status: "Shipped" },
                "Delivered": { count: 0, total: 0, status: "Delivered" },
                "SentToRepro": { count: 0, total: 0, status: "SentToRepro" },
                "Cancelled": { count: 0, total: 0, status: "Cancelled" },
                "Acknowledged": { count: 0, total: 0, status: "Acknowledged" },
                "inTransit": { count: 0, total: 0, status: "InTransit" },
                "inProcess": { count: 0, total: 0, status: "In Process" },
                "total": { count: 0, total: 0, status: "Total Order" }
            };
            let totalcount = 0;
            let totalTotal = 0;
            res[1].forEach((item) => {
                if (statusMap[item.status]) {
                    statusMap[item.status] = item;
                }
                totalTotal += item.total || 0;
                totalcount += item.count || 0;
            });
            statusMap["inProcess"].count = totalcount - (statusMap["Delivered"].count || 0) - (statusMap["Cancelled"].count || 0);
            statusMap["inProcess"].total = totalTotal - (statusMap["Delivered"].total || 0) - (statusMap["Cancelled"].total || 0);
            statusMap["inTransit"].count = statusMap["ShippedToUS"].count + (statusMap["Shipped"].count || 0);
            statusMap["inTransit"].total = statusMap["ShippedToUS"].total + (statusMap["Shipped"].total || 0);
            statusMap["total"].total = totalTotal;
            statusMap["total"].count = totalcount
            statusMap["Ack"] = statusMap["Acknowledged"]
            statusMap["Ack"].status = "Ack";
            setMasterData(statusMap);

        })
    }, [])
    useEffect(() => {

        const lineChartPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        "statusLog": { $elemMatch: { status: "Delivered" } }
                    }
                },
                {
                    $project: {
                        "statusLog": 1,
                        "orderDate": 1,
                    }
                },

            ]

        }

        const LineChart = (month, delayData) => {
            let seriesData = [];
            for (const key in delayData) {
                seriesData.push({
                    name: `${key} days`,
                    data: delayData[key].reverse()
                })
            }
            month = month.reverse()
            const lineChart = {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: 'Monthly Order Delivery Count & Days to Deliver',
                    align: 'center'
                },
                xAxis: {
                    categories: month
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                legend: {
                    reversed: true
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: seriesData
            };
            return lineChart;
        };

        Promise.all([plazaServices.general(serviceId, lineChartPayload, "nq-order-items")]).then((res) => {
            if (res[0]) {
                let day_MonthMap = {};
                let latestMonth = 0;
                let firstMonth = 13;
                res[0].map((item) => {
                    let date1 = new Date(item.orderDate);
                    let date2;
                    let month;
                    item.statusLog.map((key) => {
                        if (key.status === "Delivered") date2 = new Date(key.dt);
                    })
                    let date = moment(date1)
                    month = date.month()
                    if (firstMonth > month) firstMonth = month;
                    if (month > latestMonth) latestMonth = month;
                    const differenceInMilliseconds = date2 - date1;
                    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
                    if (!day_MonthMap[`${differenceInDays}`]) {
                        day_MonthMap[`${differenceInDays}`] = new Array(12).fill(0);
                        day_MonthMap[`${differenceInDays}`][month]++;
                    }
                    else day_MonthMap[`${differenceInDays}`][month]++;
                })
                for (const key in day_MonthMap) {
                    day_MonthMap[key] = day_MonthMap[key].slice(firstMonth, latestMonth + 1);
                }
                let monthNames = Array.from({ length: 12 }, (_, index) => index).slice(firstMonth, latestMonth + 1).map((item) => {
                    return moment().month(item).format('MMMM')
                })
                const lineChartConfig = LineChart(monthNames, day_MonthMap);
                setLine(lineChartConfig);
            }
        })
    }, [])
    const serviceId = localStorage.getItem("serviceId");
    const showCharts = (charts, col, card) => {
        return <div className='col-12 h-auto'>
            <div className='col-12'><HighchartsReact highcharts={Highcharts} options={charts} /></div>
        </div>
    }

    const pieChartType = (res, field, route) => {
        let siteCount = [];
        let checkData = 0;
        res.map((item) => {
            if (item.count === null) item.count = 0
            let data = {
                category: item.status,
                name: item.status,
                y: item.count
            }
            if (item.count > 0) checkData = 1;
            if (item.status.length) siteCount.push(data);
        })
        if (!checkData) siteCount = [];
        let slabs = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            title: {
                text: `${field}`,
                style: {
                    fontSize: "21px",
                    fontWeight: "700",
                    fontFamily: "Roboto"
                },
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.y}</b>",
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b>: {point.y}",
                        style: {
                            fontSize: "11px",
                        },
                    },
                    point: {
                        events: {
                            click: function (e) {
                                history.push(`${route}`, { message: e.point.category });
                            }
                        }
                    },
                    showInLegend: false,
                },
            },
            series: [
                {
                    name: "",
                    colorByPoint: true,
                    data: siteCount
                },
            ],
        };
        return slabs;
    }

    const showMessage = (Message) => {
        return <div className="my-info-message borderStyle2 " style={{ borderRadius: "4px" }}>{Message}</div>;
    }

    return (
        <div className='grid justify-content-center ml-1 m-0'>
            <div className='grid nested-grid w-full m-0'>
                <div className=' card col-12 lg:col-6 flex justify-content-center align-items-center col-12 borderStyle2 mt-3 '>
                    {showCharts(orderbyStatus, "", "")}
                </div>
                <div className=' col-12 lg:col-6 flex flex-wrap'>
                    <div className='w-full'>
                        <h4 className='text-center m-0'>Order Status Breakup</h4>
                        <div className=''>
                            <div className='flex flex-wrap'>
                                <div className='lg:col-6 col-12 '>
                                    <div className='displayCard shadow-4 bg-white m-0 borderStyle1'>
                                        <h5 className='text-primary text-center mb-2 text-600'>Total Order</h5>
                                        <div className='w-full flex justify-content-between'>
                                            <div className='font-bold'>
                                                <p className='m-0 text-base text-left text-600'>Qty</p>
                                                <p className='m-0 text-xl'>{masterData?.total?.count ?? 0}</p>
                                            </div>
                                            <div className='font-bold'>
                                                <p className='m-0 text-base text-right text-600'>Value</p>
                                                <p className='m-0 text-xl font-bold'>$ {masterData?.total?.total?.toFixed(2) ?? 0}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='lg:col-6 col-12 '>
                                    <div className='displayCard shadow-4 bg-white m-0 borderStyle3'>
                                        <h5 className='text-primary text-center mb-2 text-600'>Cancelled</h5>
                                        <div className='w-full flex justify-content-between'>
                                            <div className='font-bold'>
                                                <p className='m-0 text-base text-left text-600'>Qty</p>
                                                <p className='m-0 text-xl'>{masterData?.Cancelled?.count ?? 0}</p>
                                            </div>
                                            <div className='font-bold'>
                                                <p className='m-0 text-base text-right text-600'>Value</p>
                                                <p className='m-0 text-xl font-bold'>$ {masterData?.Cancelled?.total?.toFixed(2) ?? 0}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-wrap'>
                                <div className='lg:col-6 col-12 '>
                                    <div className='displayCard shadow-4 bg-white m-0 borderStyle1'>
                                        <h5 className='text-primary text-center mb-2 text-600'>In Process</h5>
                                        <div className='w-full flex justify-content-between'>
                                            <div className='font-bold'>
                                                <p className='m-0 text-base text-left text-600'>Qty</p>
                                                <p className='m-0 text-xl'>{masterData?.inProcess?.count ?? 0}</p>
                                            </div>
                                            <div className='font-bold'>
                                                <p className='m-0 text-base text-right text-600'>Value</p>
                                                <p className='m-0 text-xl font-bold'>$ {masterData?.inProcess?.total?.toFixed(2) ?? 0}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='lg:col-6 col-12 '>
                                    <div className='displayCard shadow-4 bg-white m-0 borderStyle4'>
                                        <h5 className='text-primary text-center mb-2 text-600'>Delivered</h5>
                                        <div className='w-full flex justify-content-between'>
                                            <div className='font-bold'>
                                                <p className='m-0 text-base text-left text-600'>Qty</p>
                                                <p className='m-0 text-xl'>{masterData?.Delivered?.count ?? 0}</p>
                                            </div>
                                            <div className='font-bold'>
                                                <p className='m-0 text-base text-right text-600'>Value</p>
                                                <p className='m-0 text-xl font-bold'>$ {masterData?.Delivered?.total?.toFixed(2) ?? 0}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 className='text-center m-0 mt-3 mb-1'>In Process Orders Breakup</h4>
                        <div className='col-12 pt-0'>
                            <div className='col-12 card shadow-5 overflow-auto flex borderStyle1'>
                                <table className='w-full'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className='p-1 text-lg text-primary'>Acknowledged</th>
                                            <th className='p-1 text-lg text-primary white-space-nowrap'>Sent To Repro</th>
                                            <th className='p-1 text-lg text-primary white-space-nowrap'>In Transit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='p-1 font-bold'>
                                            <td className='py-3 text-600 text-base'>Quantity</td>
                                            <td className='p-3 text-xl text-center'>{masterData?.Acknowledged?.count ?? 0}</td>
                                            <td className='p-3 text-xl text-center'>{masterData?.SentToRepro?.count ?? 0}</td>
                                            <td className='p-3 text-xl text-center'>{masterData?.inTransit?.count ?? 0}</td>
                                        </tr>
                                        <tr className='p-1 font-bold'>
                                            <td className='py-3 text-600 text-base'>Value</td>
                                            <td className='p-3 text-xl text-center white-space-nowrap'>$ {masterData?.Acknowledged?.total?.toFixed(2) ?? 0}</td>
                                            <td className='p-3 text-xl text-center white-space-nowrap'>$ {masterData?.SentToRepro?.total?.toFixed(2) ?? 0}</td>
                                            <td className='p-3 text-xl text-center white-space-nowrap'>$ {masterData?.inTransit?.total?.toFixed(2) ?? 0}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' flex  flex-wrap w-full    justify-content-around '>
                <div className=" lg:col-6 col-12   lg:mb-0 mb-2" style={{ gap: '10px' }}>
                    <div className="borderStyle1 card"><HighchartsReact highcharts={Highcharts} options={data} /></div></div>
                <div className="lg:col-6 col-12 " >
                    <div className="borderStyle2 card"><HighchartsReact highcharts={Highcharts} options={line} /></div>
                </div>
            </div>
        </div>
    )
}
