import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Skeleton } from "primereact/skeleton";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";
import { FuelMonitoringService } from "../../service/FuelMonitoringService";
import { secondsToHms } from "../../utilities/utillFunction";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { VehicleSrvice } from "../../service/VehicleService";
import { connect } from 'react-redux';
import { stationaryVehicle } from "../../lib/constants";

 class FuelAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            things: null,
            selectedThing: null,
            selectedRange: null,
            lineChart: null,
            avgCPH: null,
            maxCPH: null,
            minCPH: null,
            multiChartOption: null,
            data: null,
        };
        this.serviceID = localStorage.getItem("serviceId");
        this.fuelMonitoringService = new FuelMonitoringService();
        this.vehicleService = new VehicleSrvice();
        this.onThingChange = this.onThingChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.colors = [colorPalettes.color1, colorPalettes.color2, colorPalettes.color2_1, colorPalettes.color3, colorPalettes.color4, colorPalettes.color5];
        this.arr=this.props.thingName.thingNameContainer.length > 0?this.props.thingName.thingNameContainer[this.props.thingName.thingNameContainer.length-1]: [];
    }

    componentDidMount = () => {
        if (this.props.isVehicle) {
            let things = [];
            let startDate = moment().subtract(30, "d").format("YYYY-MM-DD");
            let endDate = moment().subtract(1, "d").format("YYYY-MM-DD");

            let payload = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            date: {
                                $gte: startDate,
                                $lte: endDate,
                            },
                        },
                    },
                ],
            };
            Promise.all([this.vehicleService.getFleetsData(this.serviceID), this.fuelMonitoringService.getFuelData(this.serviceID, payload), this.vehicleService.getAggregateHistory(this.serviceID, payload)])
                .then((res) => {
                    const fleetData = res[0];
                    const fuelData = res[1];
                    let things_map = {};
                    const vehicleData = res[2].map((item) => {
                        let index = fleetData.findIndex((elm) => elm.fleetName === item.thingName);
                        if (index > -1) {
                            things_map[item.thingName] =  fleetData[index]
                            return {
                                vehicleNumber: fleetData[index].vehicle.vehicleNumber,
                                fleetType: fleetData[index].fleetType,
                                ...item,
                            };
                        }
                    });

                    let data = fuelData.map((item) => {
                        let index = vehicleData.findIndex((elm) => elm.thingName === item.thingName && elm.date === item.date);
                        if (index > -1) {
                            return {
                                ...item,
                                ...vehicleData[index],
                            };
                        }
                    });
                    data = data.filter(function (element) {
                        return element !== undefined;
                    });
                    data.forEach((element) => {
                        if (!things.some((item) => item.name === element.thingName)) {
                            things.push({
                                name: element.thingName,
                                code: element.thingName,
                                make: element.make,
                                model: element.model,
                                state: element.state,
                                city: element.city,
                                country: element.operatingCountry,
                                zip: element.zip,
                                vehicleNumber: element.vehicleNumber.toUpperCase(),
                                fleetType: element.fleetType,
                            });
                        }
                    });
                    let temp = data.sort((a, b) => {
                        const date1 = new Date(a.date);
                        const date2 = new Date(b.date);
                        return date1 - date2;
                    });
                    let model = [];
                    temp.forEach((item) => {
                        if (!model.includes(item.model)) {
                            model.push(item.model);
                        }
                    });

                    let multiChartOption = [];
                    model.forEach((item) => {
                        let dgs = temp.filter((elm) => elm.model === item);
                        let series = [],
                            categories = [],
                            title = `Daywise Efficiency comparison for all (${dgs[0].make}/${item}) vehicles`;

                        let yAxis = {
                            title: {
                                text: "Km/L",
                            },
                        };
                        let tooltip = {
                            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                            pointFormat: '<tr><td style="color:{series.color};padding:0;text-transform:uppercase">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} Km/L</b></td></tr>',
                            footerFormat: "</table>",
                            shared: true,
                            useHTML: true,
                        };

                        let sizeWiseChartOPtion = this.getChartOPtion2();
                        dgs.forEach((elm, index) => {
                            if (!categories.includes(elm.date)) {
                                categories.push(elm.date);
                            }
                            if (stationaryVehicle.includes(elm.fleetType)) {
                                if (!series.some((e) => e.thingName === elm.thingName)) {
                                    let cph = 0;
                                    if (elm.fuelConsumed > 0 && elm.totalrunhours > 60) {
                                        cph = parseFloat((elm.fuelConsumed / (elm.totalrunhours / 3600)).toFixed(2));
                                    }
                                    series.push({
                                        name: things_map[elm.thingName] ? things_map[elm.thingName].alias : elm.vehicleNumber,
                                        data: [cph],
                                        color: this.colors[index],
                                        thingName: elm.thingName
                                    });
                                    yAxis.title.text = "L/hr";
                                    tooltip.pointFormat = '<tr><td style="color:{series.color};padding:0;text-transform:uppercase">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} L/hr</b></td></tr>';
                                } else {
                                    let cph = 0;
                                    if (elm.fuelConsumed > 0 && elm.totalrunhours > 60) {
                                        cph = parseFloat((elm.fuelConsumed / (elm.totalrunhours / 3600)).toFixed(2));
                                    }
                                    let index = series.findIndex((e) => e.thingName === elm.thingName);
                                    series[index].data = [...series[index].data, cph];
                                }
                            } else {
                                if (!series.some((e) => e.thingName === elm.thingName)) {
                                    let cph = 0;
                                    if (elm.fuelConsumed > 0 && elm.totalrunhours > 60) {
                                        cph = parseFloat((elm.totalDistanceTravelled / elm.fuelConsumed).toFixed(2));
                                    }
                                    series.push({
                                        name: things_map[elm.thingName] ? things_map[elm.thingName].alias : elm.vehicleNumber,
                                        data: [cph],
                                        color: this.colors[index],
                                        thingName: elm.thingName
                                    });
                                } else {
                                    let cph = 0;
                                    if (elm.fuelConsumed > 0 && elm.totalrunhours > 60) {
                                        cph = parseFloat((elm.totalDistanceTravelled / elm.fuelConsumed).toFixed(2));
                                    }
                                    let index = series.findIndex((e) => e.thingName === elm.thingName);
                                    series[index].data = [...series[index].data, cph];
                                }
                            }
                        });
                        sizeWiseChartOPtion.series = series;
                        sizeWiseChartOPtion.xAxis.categories = categories;
                        sizeWiseChartOPtion.title.text = title;
                        sizeWiseChartOPtion.yAxis = yAxis;
                        sizeWiseChartOPtion.tooltip = tooltip;
                        multiChartOption.push(sizeWiseChartOPtion);
                    });

                    this.setState(
                        {
                            things: things,
                            selectedThing: things[0],
                            selectedRange: [new Date(startDate), new Date(endDate)],
                            multiChartOption,
                            data,
                        },
                        () => {
                            this.initReport(things[0], this.state.selectedRange);
                        }
                    );
                })
                .catch((e) => console.log(e));
        } else {
            let things = [];
            let startDate = moment().subtract(30, "d").format("YYYY-MM-DD");
            let endDate = moment().subtract(1, "d").format("YYYY-MM-DD");

            let payload = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            date: {
                                $gt: moment().subtract(30, "d").format("YYYY-MM-DD"),
                                $lt: moment().subtract(1, "d").format("YYYY-MM-DD"),
                            },
                        },
                    },
                ],
            };
            if (this.props.thingName.thingNameContainer.length > 0) {
                payload.aggregate = [{
                    $match: {
                        "thingName": { $in: this.arr }
                    }
                }, {
                    $match: {
                        date: {
                            $gt: moment().subtract(30, "d").format("YYYY-MM-DD"),
                            $lt: moment().subtract(1, "d").format("YYYY-MM-DD"),
                        },
                    },
                }]
            }
            let thingPayload = {};
            if (this.arr.length > 0) {
                thingPayload = {
                    filter: { "thingName": { "$in": this.arr } },
                }
            }
            Promise.all([this.fuelMonitoringService.getThings(this.serviceID, thingPayload), this.fuelMonitoringService.getDailySummeryData(this.serviceID, payload)])
                .then((res) => {
                    let things_map = {};
                    res[0].forEach((element) => {
                        things_map[element.thingName] = {
                            name: element.thingName,
                            code: element.thingName,
                            siteId: element.location.siteId,
                            siteName: element.alias || element.location.siteName,
                            state: element.location.state,
                            city: element.location.city,
                            country: element.location.operatingCountry,
                            zip: element.location.zip,
                        }
                    });
                    things = Object.values(things_map)
                    let temp = res[1].sort((a, b) => {
                        const date1 = new Date(a.date);
                        const date2 = new Date(b.date);
                        return date1 - date2;
                    });
                    let size = [];
                    temp.forEach((item) => {
                        if (item.size && !size.includes(item.size)) {
                            size.push(item.size);
                        }
                    });
                    let multiChartOption = [];
                    size.forEach((item) => {
                        let dgs = temp.filter((elm) => elm.size === item);
                        let series = [],
                            categories = [],
                            title = "Day wise CPH comparison (DGs of size " + item + ")";

                        let sizeWiseChartOPtion = this.getChartOPtion();
                        dgs.forEach((elm, index) => {
                            if (!categories.includes(moment(elm.date).format("Do MMM YYYY"))) {
                                categories.push(moment(elm.date).format("Do MMM YYYY"));
                            }
                            if (!series.some((e) => e.thingName === elm.thingName)) {
                                let cph = 0;
                                if (elm.fuelConsumed > 0 && elm.totalrunhours > 60) {
                                    cph = parseFloat((elm.fuelConsumed / (elm.totalrunhours / 3600)).toFixed(2));
                                }
                                series.push({
                                    name: things_map[elm.thingName] ? things_map[elm.thingName].siteName : elm.thingName,
                                    data: [cph],
                                    color: this.colors[index],
                                    thingName : elm.thingName
                                });
                            } else {
                                let cph = 0;
                                if (elm.fuelConsumed > 0 && elm.totalrunhours > 60) {
                                    cph = parseFloat((elm.fuelConsumed / (elm.totalrunhours / 3600)).toFixed(2));
                                }
                                let index = series.findIndex((e) => e.thingName === elm.thingName);
                                series[index].data = [...series[index].data, cph];
                            }
                        });
                        sizeWiseChartOPtion.series = series;
                        sizeWiseChartOPtion.xAxis.categories = categories;
                        sizeWiseChartOPtion.title.text = title;
                        multiChartOption.push(sizeWiseChartOPtion);
                    });
                    this.setState(
                        {
                            things: things,
                            selectedThing: things.length ? things[0] : null ,
                            selectedRange: [new Date(startDate), new Date(endDate)],
                            multiChartOption,
                        },
                        () => {
                            this.initReport((things.length ? things[0] : null),
                                this.state.selectedRange
                            );
                        }
                    );
                })
                .catch((e) => console.log(e));
        }
    };

    onThingChange = (e) => {
        this.setState({ selectedThing: e.value }, () => {
            this.initReport(this.state.selectedThing, this.state.selectedRange);
        });
    };
    onDateChange = (e) => {
        this.setState({ selectedRange: e.value }, () => {
            if (this.state.selectedRange.length > 1 && this.state.selectedRange[1] !== null) {
                this.initReport(this.state.selectedThing, this.state.selectedRange);
            }
        });
    };

    getChartOPtion = () => {
        return {
            chart: {
                type: "spline",
            },
            title: {
                text: "",
                style: chartHeadingStyle,
            },
            xAxis: {
                categories: [],
                crosshair: true,
            },
            yAxis: {
                title: {
                    text: "Fuel Consumed/Run Hour",
                },
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} L/Hr</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [],
        };
    };

    getChartOPtion2 = () => {
        return {
            chart: {
                type: "spline",
            },
            title: {
                text: "",
                style: chartHeadingStyle,
            },
            xAxis: {
                categories: [],
                crosshair: true,
            },
            yAxis: {},
            tooltip: {},
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            legend: {
                itemStyle: {
                    textTransform: "uppercase",
                },
            },
            series: [],
        };
    };

    initReport = async (thing, selectedRange) => {
        if (!thing) return
        let startDat = moment(selectedRange[0]).format("YYYY-MM-DD");
        let endDat = moment(selectedRange[1]).format("YYYY-MM-DD");
        let dateMap = new Map();
        const payload4 = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        "thingName": thing.name,
                        date: {
                            $gte: startDat,
                            $lte: endDat,
                        },
                    },
                }
            ],
        };

        if (this.props.isVehicle) {
            let newData;
            if (stationaryVehicle.includes(thing.fleetType)) {
                newData = await this.fuelMonitoringService.getDailySummeryData(this.serviceID, payload4);
            }
            else {
                await Promise.all([this.vehicleService.getAggregateHistory(this.serviceID, payload4),this.fuelMonitoringService.getFuelData(this.serviceID, payload4)]).then((res)=>{
                    newData=res[0];
                    res[1].map((item) => {
                        dateMap.set(item.date, item);
                    })
                })
            }
            let totalFuelConsumed = 0;
            let totalDistance = 0;
            let avgCPH = "--";
            let newArray = [];
            if (newData && newData.length > 0) {
                if (stationaryVehicle.includes(thing.fleetType)) {
                    newData.forEach((item) => {
                        totalFuelConsumed += item.fuelConsumed;
                        totalDistance += item.totalrunhours;
                        if (item.fuelConsumed > 0) {
                            newArray.push({
                                ...item,
                                fuelEfficency: item.totalrunhours !== 0 && item.fuelConsumed !== 0 ? parseFloat((item.fuelConsumed / (item.totalrunhours / 3600)).toFixed(2)) : 0,
                            });
                        }
                    });
                    totalDistance = totalDistance / 3600;
                    if(totalFuelConsumed && totalDistance>0) avgCPH = (totalFuelConsumed / totalDistance).toFixed(2) + "L/hr";
                    let maxCPH = {};
                    let minCPH = {};
                    if (newArray.length) {
                        maxCPH = newArray.reduce((previous, current) => {
                            return current.fuelEfficency > previous.fuelEfficency ? current : previous;
                        });
                        minCPH = newArray.reduce((previous, current) => {
                            return current.fuelEfficency < previous.fuelEfficency ? current : previous;
                        });
                    }
                    this.setState({ avgCPH, maxCPH, minCPH });
                } else {
                    newData.forEach((item) => {
                        let fuelItem = dateMap.get(item.date);
                        totalFuelConsumed += fuelItem.fuelConsumed;
                        totalDistance += item.totalDistanceTravelled;
                        if (fuelItem.fuelConsumed > 0) {
                            newArray.push({
                                ...fuelItem,
                                fuelEfficency: parseFloat((item.totalDistanceTravelled / fuelItem.fuelConsumed).toFixed(2)),
                            });
                        }
                        if (totalFuelConsumed && totalDistance>0) avgCPH = (totalDistance / totalFuelConsumed).toFixed(2) + " Km/L";
                    });
                    let maxCPH = {};
                    let minCPH = {};
                    if(newArray.length){
                        maxCPH = newArray.reduce((previous, current) => {
                            return current.fuelEfficency > previous.fuelEfficency ? current : previous;
                        });
                        minCPH = newArray.reduce((previous, current) => {
                            return current.fuelEfficency < previous.fuelEfficency ? current : previous;
                        });
                        this.setState({ avgCPH, maxCPH, minCPH });
                    }
                    this.setState({ avgCPH, maxCPH, minCPH });
                }
            } else {
                this.setState({ avgCPH, maxCPH: {}, minCPH: {} });
            }
        } else {
            const payload1 = {
                filter: {
                    runDuration: { $gte: 60 },
                    thingName: thing.name,
                    date: {
                        $gte: moment(selectedRange[0]).format("YYYY-MM-DD"),
                        $lte: moment(selectedRange[1]).format("YYYY-MM-DD"),
                    },
                },
            };

            Promise.all([this.fuelMonitoringService.getSessionData(this.serviceID, payload1), this.fuelMonitoringService.getFuelData(this.serviceID, payload4)]).then((res) => {
                let totalFuelConsumed = 0;
                let totalDistance = 0;
                let avgCPH = "--";
                if (res[1] && res[1].length > 0) {
                    res[1].forEach((item) => {
                        totalFuelConsumed += item.fuelConsumed;
                        totalDistance += item.totalrunhours / 3600;
                    });
                    if (totalFuelConsumed && totalDistance > 0) avgCPH = (totalFuelConsumed / totalDistance).toFixed(2) + "L/hr";
                    this.setState({ avgCPH, maxCPH: {}, minCPH: {} });
                } else {
                    this.setState({ avgCPH, maxCPH: {}, minCPH: {} });
                }

                let maxCPH = null;
                let minCPH = null;
                let lineChart = {
                    chart: {
                        type: "spline",
                        height: "400px",
                        backgroundColor: "transparent",
                    },
                    title: {
                        text: `CPH Variation with Temperature on (${moment(selectedRange[0]).format("Do MMM, YYYY")}-${moment(selectedRange[1]).format("Do MMM, YYYY")})`,
                        style: chartHeadingStyle,
                    },
                    accessibility: {
                        enabled: false,
                    },
                    tooltip: {
                        valueDecimals: 2,
                    },
                    xAxis: {
                        categories: [],
                        labels: {
                            style: {
                                color: "#86969F",
                            },
                        },
                        title: {
                            text: "Temprature",
                        },
                    },
                    yAxis: {
                        stackLabels: {
                            enabled: false,
                        },
                        labels: {
                            style: {
                                color: colorPalettes.color2,
                            },
                        },
                        title: {
                            text: "Fuel Consumed / Run Hour",
                            style: {
                                color: colorPalettes.color2,
                            },
                        },
                    },
                    plotOptions: {
                        series: {
                            label: {
                                connectorAllowed: false,
                            },
                            pointStart: 0,
                            borderRadius: 5,
                            pointWidth: 9,
                            borderWidth: 0,
                        },
                    },
                    series: [
                        {
                            name: "Fuel Consumed / Run Hour",
                            data: [],
                            color: colorPalettes.color2,
                            tooltip: {
                                valueSuffix: " L/Hr",
                            },
                        },
                    ],
                };
                if(res[0] && res[0].length){
                    maxCPH = res[0].reduce((previous, current) => {
                        return current.fuelConsumedperRunHour > previous.fuelConsumedperRunHour ? current : previous;
                    });
                    minCPH = res[0].reduce((previous, current) => {
                        return current.fuelConsumedperRunHour < previous.fuelConsumedperRunHour ? current : previous;
                    });
                    let sessionData = res[0].sort((a, b) => {
                        return a.avgTemp - b.avgTemp;
                    });
                

                    let temp = [],
                        cph = [];
                    sessionData.forEach((item) => {
                        if (item.fuelConsumedperRunHour > 0) {
                            if (item.avgTemp > 0) {
                                temp.push(item.avgTemp + " °C");
                                cph.push(item.fuelConsumedperRunHour);
                            }
                        }
                    });
                    lineChart.xAxis.categories = temp;
                    lineChart.series[0].data = cph;
                }
                this.setState({ lineChart: lineChart, avgCPH, maxCPH : maxCPH ?? {}, minCPH : minCPH ?? {}});
            });
        }
    };

    template(options) {
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <span className={titleClassName}>Fuel Efficiency comparison</span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        );
    }

    render() {
        return (
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <div className="grid">
                            <div className="lg:col-6 lg:col-offset-6">
                                <div className="grid">
                                    {this.props.isVehicle ? (
                                        this.state.things ? (
                                            <div className="field lg:col-6 col-12 mb-0">
                                                <Dropdown value={this.state.selectedThing} options={this.state.things} onChange={this.onThingChange} optionLabel="vehicleNumber" placeholder="Select a vehicle" className="w-full" style={{ textTransform: "uppercase" }} filter />
                                            </div>
                                        ) : null
                                    ) : this.state.things ? (
                                        <div className="field lg:col-6 col-12 mb-0">
                                            <Dropdown value={this.state.selectedThing} options={this.state.things} onChange={this.onThingChange} optionLabel="siteName" placeholder="Select a Site" className="w-full" filter />
                                        </div>
                                    ) : null}

                                    <div className="field lg:col-6 col-12 mb-0">
                                        <Calendar id="range" value={this.state.selectedRange} onChange={this.onDateChange} selectionMode="range" readOnlyInput showIcon className="w-full" maxDate={new Date()} />
                                    </div>
                                </div>
                            </div>
                            <div className="lg:col-2 col-12">
                                {this.state.avgCPH ? (
                                    <div className="w-full border-1 border-solid border-200 border-round temperaturebox" style={{ height: "190px" }}>
                                        {this.props.isVehicle ? <p className="mt-6 font-bold">Average Fuel Efficiency</p> : <p className="mt-6 font-bold">Average CPH</p>}

                                        <h3 className="text-primary">{this.state.avgCPH}</h3>
                                    </div>
                                ) : (
                                    <Skeleton height="190px" className="mb-2"></Skeleton>
                                )}
                            </div>
                            <div className="lg:col-10 col-12">
                                <div className="grid">
                                    <div className="col-12 lg:col-6">
                                        {this.state.maxCPH ? (
                                            Object.keys(this.state.maxCPH).length ? (
                                                <div className="w-full border-1 border-solid border-200 border-round temperatureboxList">
                                                    {this.props.isVehicle ? <h4>Maximum Fuel Efficiency on ({moment(this.state.maxCPH.date).format("Do MMM, YY")})</h4> : <h4>Maximum CPH on ({moment(this.state.maxCPH.date).format("Do MMM, YY")})</h4>}
                                                    {/* <h4>Maximum CPH on ({moment(this.state.maxCPH.date).format("Do MMM, YY")})</h4> */}
                                                    <div className="flex justify-content-between font-bold">
                                                        <ul className="m-0 p-0 pr-2 flex-1">
                                                            <li>
                                                                <p>Start Time :</p>
                                                                <p className="text-primary">{moment(this.state.maxCPH.startTime).format("HH:mm A")}</p>
                                                            </li>
                                                            {this.props.isVehicle ? (
                                                                <>
                                                                    <li>
                                                                        <p>Efficiency :</p>
                                                                        <p className="text-primary">
                                                                            {this.state.maxCPH.fuelEfficency} {stationaryVehicle.includes(this.state.selectedThing.fleetType)? "L/hr" : "Km/L"}
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Distance :</p>
                                                                        <p className="text-primary">{this.state.maxCPH.totalDistanceTravelled} Km</p>
                                                                    </li>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <li>
                                                                        <p>Duration :</p>
                                                                        <p className="text-primary">{secondsToHms(this.state.maxCPH.runDuration)}</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>CPH :</p>
                                                                        <p className="text-primary">{this.state.maxCPH.fuelConsumedperRunHour} L/Hr</p>
                                                                    </li>
                                                                </>
                                                            )}
                                                            <li>
                                                                <p>Fuel Consumed: </p>
                                                                <p className="text-primary">{this.state.maxCPH.fuelConsumed} L</p>
                                                            </li>
                                                        </ul>
                                                        <ul className="m-0 p-0 pl-2 flex-1">
                                                            <li>
                                                                <p>Avg Temp :</p>
                                                                <p className="text-primary">{this.state.maxCPH.avgTemp} °C</p>
                                                            </li>
                                                            <li>
                                                                <p>Avg Humidity :</p>
                                                                <p className="text-primary">{this.state.maxCPH.avgHumidity} %</p>
                                                            </li>
                                                            <li>
                                                                <p>Avg. AQI PM10 :</p>
                                                                <p className="text-primary">{this.state.maxCPH.avgAqi10}</p>
                                                            </li>
                                                            <li>
                                                                <p>Avg. AQI PM2.5 :</p>
                                                                <p className="text-primary">{this.state.maxCPH.avgAqi25}</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{height:"190px", lineHeight:"190px"}} className="no-data-grid text-center font-bold">No data</div>
                                            )
                                        ) : (
                                            // <div className="no-data-grid"></div>
                                            <Skeleton height="190px" className="mb-2"></Skeleton>
                                        )}
                                    </div>
                                    <div className="col-12 lg:col-6">
                                        {this.state.minCPH ? (
                                            Object.keys(this.state.minCPH).length ? (
                                                <div className="w-full border-1 border-solid border-200 border-round temperatureboxList">
                                                    {this.props.isVehicle ? <h4>Minimum Fuel Efficiency on ({moment(this.state.minCPH.date).format("Do MMM, YY")})</h4> : <h4>Minimum CPH on ({moment(this.state.minCPH.date).format("Do MMM, YY")})</h4>}

                                                    <div className="flex justify-content-between font-bold">
                                                        <ul className="m-0 p-0 pr-2 flex-1">
                                                            <li>
                                                                <p>Start Time :</p>
                                                                <p className="text-primary">{moment(this.state.minCPH.startTime).format("HH:mm A")}</p>
                                                            </li>
                                                            {this.props.isVehicle ? (
                                                                <>
                                                                    <li>
                                                                        <p>Efficiency :</p>
                                                                        <p className="text-primary">
                                                                            {this.state.minCPH.fuelEfficency} {stationaryVehicle.includes(this.state.selectedThing.fleetType)? "L/hr" : "Km/L"}
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Distance :</p>
                                                                        <p className="text-primary">{this.state.minCPH.totalDistanceTravelled} Km</p>
                                                                    </li>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <li>
                                                                        <p>Duration :</p>
                                                                        <p className="text-primary">{secondsToHms(this.state.minCPH.runDuration)}</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>CPH :</p>
                                                                        <p className="text-primary">{this.state.minCPH.fuelConsumedperRunHour} L/Hr</p>
                                                                    </li>
                                                                </>
                                                            )}
                                                            <li>
                                                                <p>Fuel Consumed: </p>
                                                                <p className="text-primary">{this.state.minCPH.fuelConsumed} L</p>
                                                            </li>
                                                        </ul>
                                                        <ul className="m-0 p-0 pl-2 flex-1">
                                                            <li>
                                                                <p>Avg Temp :</p>
                                                                <p className="text-primary">{this.state.minCPH.avgTemp} °C</p>
                                                            </li>
                                                            <li>
                                                                <p>Avg Humidity :</p>
                                                                <p className="text-primary">{this.state.minCPH.avgHumidity} %</p>
                                                            </li>
                                                            <li>
                                                                <p>Avg. AQI PM10 :</p>
                                                                <p className="text-primary">{this.state.minCPH.avgAqi10}</p>
                                                            </li>
                                                            <li>
                                                                <p>Avg. AQI PM2.5 :</p>
                                                                <p className="text-primary">{this.state.minCPH.avgAqi25}</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{height:"190px", lineHeight:"190px"}} className="no-data-grid text-center font-bold">No data</div>
                                            )
                                        ) : (
                                            <Skeleton height="190px" className="mb-2"></Skeleton>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">{this.state.lineChart && this.state.lineChart.series[0].data.length > 0 ? <HighchartsReact highcharts={Highcharts} options={this.state.lineChart} /> : null}</div>
                            {/* <div className="col-12">
                                <HighchartsReact highcharts={Highcharts} options={this.state.lineChart} />
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <Panel headerTemplate={this.template} toggleable>
                        <div className="">{this.state.multiChartOption ? this.state.multiChartOption.map((item, index) => <HighchartsReact key={"chart-" + index} highcharts={Highcharts} options={item} />) : null}</div>
                    </Panel>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        thingName: state
    };
};
export default connect(mapStateToProps)(FuelAnalytics);
