import React, { useEffect, useState } from "react";
import DBService from "../../service/dbService";
import XLSX from "sheetjs-style";
import { Skeleton } from "primereact/skeleton";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { WINDZONE_COLORS } from "../../lib/constants";

const CountWidgets = () => {
    const [exceptions, setExceptions] = useState([]);
    const [count, setCount] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [windZone, setWindZone] = useState([]);
    const myStates = JSON.parse(localStorage.getItem("states"));

    useEffect(async () => {
        let exceptionsQuery = {
            operation: "aggregate",
            aggregate: [{ $group: { _id: "$status", count: { $sum: 1 } } }],
        };

        let wzWiseQuery = {
            operation: "aggregate",
            aggregate: [{ $group: { _id: "$windZone", count: { $sum: 1 } } }, { $sort: { _id: 1 } }],
        };

        if (myStates.length > 0) {
            exceptionsQuery = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            st: {
                                $in: myStates,
                            },
                        },
                    },
                    { $group: { _id: "$status", count: { $sum: 1 } } },
                ],
            };
            wzWiseQuery = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            st: {
                                $in: myStates,
                            },
                        },
                    },
                    { $group: { _id: "$windZone", count: { $sum: 1 } } },
                    { $sort: { _id: 1 } },
                ],
            };
        }

        Promise.all([DBService.getSitesWithPutRequest(exceptionsQuery), DBService.getSitesWithPutRequest(wzWiseQuery)]).then((res) => {
            let countData = res[0];
            let windZoneData = res[1];
            let correctSites = [];
            if (windZoneData.length) {
                let notfound = windZoneData.findIndex((w) => w._id == "");
                if (notfound > -1) {
                    windZoneData.push({ _id: "Wind Zone Not Found", count: windZoneData[notfound].count });
                    windZoneData.splice(notfound, 1);
                }

                let notfoundNull = windZoneData.findIndex((w) => w._id == null);
                if (notfoundNull > -1) {
                    let notFoundDataIndex = windZoneData.findIndex((w) => w._id === "Wind Zone Not Found");
                    if (notFoundDataIndex > -1) {
                        windZoneData[notFoundDataIndex].count += windZoneData[notfoundNull].count;
                        windZoneData.splice(notfoundNull, 1);
                    } else {
                        windZoneData.push({ _id: "Wind Zone Not Found", count: windZoneData[notfoundNull].count });
                        windZoneData.splice(notfoundNull, 1);
                    }
                }
            }
            setWindZone(windZoneData);

            if (countData && countData.length) {
                let sum = countData.reduce((sum, e) => {
                    return sum + e.count;
                }, 0);
                correctSites.push({ _id: "Total Sites", count: sum });

                let index = countData.findIndex((c) => c._id === "Okay");
                if (index > -1) {
                    correctSites.push({ _id: countData[index]._id, count: countData[index].count });
                    countData.splice(index, 1);
                }
                setExceptions(countData);
                setCount(correctSites);
            } else {
                setCount([{ _id: "Total Sites", count: 0 }]);
            }
        });
    }, []);

    const onExceptionClick = async (data) => {
        let res = await DBService.getSiteDetails({ filter: { status: data._id } });
        if (res && res.length) {
            setModalData(res);
            setShowModal(true);
        }
    };
    const getHeader = () => {
        return <Button icon="pi pi-download" className="p-button-rounded mr-2 mb-2" onClick={() => onDownload()}></Button>;
    };

    const onDownload = () => {
        let exportData = [];
        exportData.push(["Site Id", "Lattitude", "Longitude"]);
        modalData.forEach((e) => {
            exportData.push([e.sid, e.lat, e.long]);
        });
        var workbook = XLSX.utils.book_new(),
            worksheet = XLSX.utils.aoa_to_sheet(exportData);
        workbook.SheetNames.push("First");
        workbook.Sheets["First"] = worksheet;
        XLSX.writeFile(workbook, "report.xlsx");
    };

    return (
        <div className="col-12">
            <div className="grid">
                {count && count.length ? (
                    count.map((c, i) => {
                        return (
                            <div key={i} className="col-12 lg:col-3 md:col-6 sm:col-3 xs:col-12" style={{ padding: "0.5rem" }}>
                                <div className=" color1-bg card ">
                                    <div>
                                        <h5 className="mb-3 text-2xl">{c._id}</h5>
                                        <h5 className=" font-medium text-2xl color1-bg" style={{ marginBottom: "0" }}>
                                            {c.count}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <Skeleton height="50px" className="mb-2"></Skeleton>
                )}
            </div>
            <div className="grid">
                {exceptions && exceptions.length
                    ? exceptions.map((c, i) => {
                          return (
                              <div key={i} className="col-12 lg:col-3 md:col-6 sm:col-3 xs:col-12" onClick={() => onExceptionClick(c)} style={{ cursor: "pointer", padding: "0.5rem" }}>
                                  <div className="color3-bg card ">
                                      <h5 className="mb-3 text-2xl">{c._id}</h5>
                                      <h5 className=" font-medium text-2xl color3-bg" style={{ marginBottom: "0" }}>
                                          {c.count}
                                      </h5>
                                  </div>
                              </div>
                          );
                      })
                    : null}
            </div>
            <div className="grid">
                {windZone && windZone.length
                    ? windZone.map((c, i) => {
                          return (
                              <div key={i} className="col-12 lg:col-2 md:col-6 sm:col-3 xs:col-12 my-2" onClick={() => onExceptionClick(c)} style={{ padding: "0.4rem" }}>
                                  <div className="card" style={{ background: WINDZONE_COLORS[c._id] }}>
                                      <h5 className="mb-3 text-2xl">{c._id}</h5>
                                      <h5 className=" font-medium text-2xl" style={{ marginBottom: "0" }}>
                                          {c.count}
                                      </h5>
                                  </div>
                              </div>
                          );
                      })
                    : null}
            </div>
            <Dialog header={getHeader} visible={showModal} modal style={{ width: "50vw" }} onHide={() => setShowModal(false)}>
                <div className="col-12">
                    <DataTable value={modalData} paginator={true} rows={10} responsiveLayout="scroll">
                        <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Site Id" style={{ width: "100px" }} field={"sid"}></Column>
                        <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Latitude" style={{ width: "100px" }} field={"lat"}></Column>
                        <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Longitude" field="long"></Column>
                    </DataTable>
                </div>
            </Dialog>
        </div>
    );
};

export default CountWidgets;
