import React, { useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { secondsToHm } from "../../utilities/utillFunction";
import { DataTable } from "primereact/datatable";
import { Employee } from "../../service/Employee";
import moment from "moment";
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button"
import XLSX from "sheetjs-style";
import { Skeleton } from 'primereact/skeleton';

const EmployeeDashboard = () => {
    const serviceId = localStorage.getItem("serviceId");
    const serviceDetails = JSON.parse(localStorage.getItem("service"));
    const [displayBasic, setDisplayBasic] = useState(false);
    const [position, setPosition] = useState("center");
    const [data, setData] = useState(null);
    const [manPower, setManPower] = useState([]);
    const [sites, setSites] = useState([]);
    const [allManPowerData, setallManPowerData] = useState(null);
    const [manPowerData, setManPowerData] = useState(null);
    //chart State
    const [employeeCountPreDay, setEmployeeCountPreDay] = useState(null);
    const [workForceStatus, setWorkForceStatus] = useState(null);
    const [performenceChart, setPerformenceChart] = useState(null);
    const [travelTimeChart, setTravelTimeChart] = useState(null);
    const [distanceChart, setDistanceChart] = useState(null);
    const [sitewisemanpower, setSitewisemanpower] = useState(null);
    const [dailyAvgDistanceTravel, setDailyAvgDistanceTravel] = useState(null);
    const [dailyAvgTimeTravel, setDailyAvgTimeTravel] = useState(null);
    const [ticketsStatusChart, setTicketsStatusChart] = useState(null);
    const [troubleTicketsChart, setTroubleTicketsChart] = useState(null);
    const [respondTimeChart, setRespondTimeChart] = useState(null);
    const [resolveTimeChart, setResolveTimeChart] = useState(null);
    const [onSiteWorkHours, setOnSiteWorkHours] = useState(null);
    const [monthlyAverageTravelTimeChart, setMonthlyAverageTravelTimeChart] = useState(null);

    const [enabledEmpWise, setEnabledEmpWise] = useState(false)
    const [employeeName, setEmployeeName] = useState(null)
    const empIdObj = useRef([])
    const [showModal, setShowModal] = useState(false)
    const [onChartClickData, setOnChartClickData] = useState([])

    const employeeService = new Employee();

    const getRanges = (arr) => {
        arr.sort((a, b) => a - b);
        let min = arr[0];
        let max = arr[arr.length - 1];
        if (min % 10) min = Math.floor(min / 10) * 10;
        if (max % 10) max = max + (10 - (max % 10));
        return bucket([min, max], 4);
    };
    const bucket = ([min, max], bucketsNumber) => {
        return Array.from({ length: bucketsNumber }).map((el, idx, arr, step = Math.floor((max - min) / bucketsNumber)) => {
            return idx === bucketsNumber - 1 ? [min + idx * step, max] : [min + idx * step, min + (idx + 1) * step];
        });
    };

    useEffect(() => {
        let allthings = []
        let motherObj = {
            thing: [],
            allEmpData: [],
            msterShift: [],
            empIds: [],
        };
        Promise.all([employeeService.getAllEmployee(serviceId), employeeService.getEmployeeShift(serviceId)])
            .then((res) => {
                if (res[0].responseCode === 200) {
                    const allEmployeeMetaData = res[0].responseData;
                    const allEmpMetaDataLen = allEmployeeMetaData.length
                    const shift = res[1].length > 0 ? res[1] : [];
                    let empId = {}
                    if (allEmpMetaDataLen > 0) {
                        allEmployeeMetaData.forEach((elm) => {
                            if (!allthings.includes(elm.thingName)) allthings.push(elm.thingName);
                            if (!(elm.empId in empId)) empId[elm.empId] = {
                                name: elm.name + " " + elm.empId,
                                code: elm.thingName,
                                empId: elm.empId
                            }
                            //if (!empId.includes(elm.empId)) empId.push(elm.empId);
                        });
                        motherObj.thing = allthings; // All thing Name set to array.
                        motherObj.allEmpData = allEmployeeMetaData; // All Employee data set to array.
                        motherObj.empIds = Object.keys(empId); // All Employee ID data set to array.
                        empIdObj.current = Object.values(empId)
                    }
                    motherObj.msterShift = shift;
                    setData(motherObj);
                }
            })
            .catch((e) => console.log(e.message));
    }, []);

    useEffect(() => {
        if (data) {
            let { empIds } = data;
            if (!employeeName && enabledEmpWise) return
            if (employeeName && employeeName.code && enabledEmpWise) {
                empIds = [employeeName.empId]
            }
            let start_date = moment().subtract(30, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss");
            let end_date = moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD HH:mm:ss");
            const payload1 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            TechnicianID: {
                                $in: empIds,
                            },
                        },
                    },
                    {
                        $group: {
                            _id: null,
                            total: { $sum: 1 },
                            openedCount: { $sum: { $cond: [{ $and: [{ $gte: ["$InProcess_ts", start_date] }, { $lte: ["$InProcess_ts", end_date] }] }, 1, 0] } },
                            acceptedCount: { $sum: { $cond: [{ $and: [{ $gte: ["$Accepted_ts", start_date] }, { $lte: ["$Accepted_ts", end_date] }] }, 1, 0] } },
                            rejectedCount: { $sum: { $cond: [{ $and: [{ $gte: ["$Rejected_ts", start_date] }, { $lte: ["$Rejected_ts", end_date] }] }, 1, 0] } },
                            reachedCount: { $sum: { $cond: [{ $and: [{ $gte: ["$Reached_ts", start_date] }, { $lte: ["$Reached_ts", end_date] }] }, 1, 0] } },
                            pendingCustCount: { $sum: { $cond: [{ $and: [{ $gte: ["$PendingWithCustomer_ts", start_date] }, { $lte: ["$PendingWithCustomer_ts", end_date] }] }, 1, 0] } },
                            resolvedCount: { $sum: { $cond: [{ $and: [{ $gte: ["$Resolved_ts", start_date] }, { $lte: ["$Resolved_ts", end_date] }] }, 1, 0] } },
                            closedCount: { $sum: { $cond: [{ $and: [{ $gte: ["$Closed_ts", start_date] }, { $lte: ["$Closed_ts", end_date] }] }, 1, 0] } },
                            pendingActCount: { $sum: { $cond: [{ $and: [{ $gte: ["$PendingAction_ts", start_date] }, { $lte: ["$PendingAction_ts", end_date] }] }, 1, 0] } },
                        },
                    },
                ],
            };
            const payload2 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            date: {
                                $gte: moment().subtract(7, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                                $lte: moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                            },
                        },
                    },
                    {
                        $group: {
                            _id: "$date",
                            totalRaised: {
                                $sum: "$noofticketsinprocess",
                            },
                            totalAccepted: {
                                $sum: "$noofticketsaccepted",
                            },
                            totalResolved: {
                                $sum: "$noofticketsresolved",
                            },
                            totalClosed: {
                                $sum: "$noofticketsclosed",
                            },
                        },
                    },
                ],
            };
            const payload3 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            timestamp: {
                                $gte: moment().subtract(7, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                                $lte: moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                            },
                            TechnicianID: {
                                $in: empIds,
                            },
                        },
                    },
                    {
                        $group: {
                            _id: {
                                date: {
                                    $substr: ["$timestamp", 0, 10],
                                },
                            },
                            avgWorkHr: {
                                $avg: "$worktime",
                            },
                        },
                    },
                ],
            };
            const payload4 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            date: {
                                $gte: moment().subtract(7, "day").startOf("day").format("YYYY-MM-DD"),
                                $lte: moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD"),
                            },
                            TechnicianID: {
                                $in: empIds,
                            },
                        },
                    },
                    {
                        $group: {
                            _id: "$date",
                            avgResponseTime: { $avg: "$responsemeantimetoday" },
                            avgResolveTime: { $avg: "$resolvemeantimetoday" },
                        },
                    },
                ],
            };
            const payload5 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            date: {
                                $gte: moment().subtract(31, "day").startOf("day").format("YYYY-MM-DD"),
                                $lte: moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD"),
                            },
                            TechnicianID: {
                                $in: empIds,
                            },
                        },
                    },
                    {
                        $group: {
                            _id: 1,
                            avgResponseTime: { $avg: "$responsemeantimetoday" },
                            avgResolveTime: { $avg: "$resolvemeantimetoday" },
                        },
                    },
                ],
            };
            Promise.all([
                employeeService.getTicketCountByStatus(serviceId, payload1),
                employeeService.getTroubleTickets(serviceId, payload2),
                employeeService.getTicketTimings(serviceId, payload4),
                employeeService.getTicketCountByStatus(serviceId, payload3),
                employeeService.getTicketTimings(serviceId, payload5),
            ]).then((res) => {
                const _ticketStatusCount = res[0];
                const _troubleTickets = res[1];
                const _troubleTicketsTiming = res[2];
                const _workHrTiming = res[3];
                const _responseAndResolvedTiming = res[4];
                //Trouble Tickets
                let dates = [],
                    raisedTickets = [],
                    acceptedTickets = [],
                    resolvedTickets = [],
                    closedTickets = [];

                _troubleTickets.sort((a, b) => new Date(a._id).getTime() - new Date(b._id).getTime())
                _troubleTickets.forEach((item) => {
                    dates.push(moment(item._id, "YYYY-MM-DD").format("Do MMM"));
                    raisedTickets.push(item.totalRaised);
                    acceptedTickets.push(item.totalAccepted);
                    resolvedTickets.push(item.totalResolved);
                    closedTickets.push(item.totalClosed);
                });
                //Ticket Timing
                let resPonseDates = [],
                    responsemeantime = [],
                    resolvemeantime = [];
                _troubleTicketsTiming.sort((a, b) => new Date(a._id).getTime() - new Date(b._id).getTime());
                _troubleTicketsTiming.forEach((item) => {
                    resPonseDates.push(moment(item._id, "YYYY-MM-DD").format("Do MMM"));
                    responsemeantime.push(parseFloat((item.avgResponseTime / 3600).toFixed(1)));
                    resolvemeantime.push(parseFloat(parseFloat(item.avgResolveTime / 3600).toFixed(1)));
                });
                //Mean Time to Resolve, Day Wise (Last 7 days)
                const resolveTimeChart = {
                    chart: {
                        type: "column",
                    },
                    title: {
                        text: "Mean Time To Resolve, Day Wise (Last 7 Days)",
                        style: chartHeadingStyle,
                    },
                    xAxis: {
                        categories: resPonseDates,
                        crosshair: true,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Resolution Time (Hrs)",
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} Hr</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                        },
                    },
                    series: [
                        {
                            showInLegend: false,
                            name: "Min Resolve Time",
                            data: resolvemeantime,
                            color: colorPalettes.color2,
                        },
                    ],
                };
                if (_responseAndResolvedTiming && _responseAndResolvedTiming.length) {
                    resolveTimeChart.yAxis.plotLines = [
                        {
                            color: "#FF0000",
                            width: 2,
                            value: _responseAndResolvedTiming[0].avgResolveTime > 0 ? (_responseAndResolvedTiming[0].avgResolveTime / 3600).toFixed(1) : 0,
                            zIndex: 9,
                            label: {
                                align: "right",
                                formatter: function () {
                                    let elm = `${this.options.value}Hr`;
                                    return elm;
                                },
                                style: {
                                    color: "#FF0000",
                                    fontWeight: "bold",
                                },
                            },
                        },
                    ];
                }
                setResolveTimeChart(resolveTimeChart);
                //Mean Time to Respond, Day Wise (Last 7 days)
                const respondTimeChart = {
                    chart: {
                        type: "column",
                    },
                    title: {
                        text: "Mean Time To Respond, Day Wise (Last 7 Days)",
                        style: chartHeadingStyle,
                    },
                    xAxis: {
                        categories: resPonseDates,
                        crosshair: true,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Response Time (Hrs)",
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} Hr</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                        },
                    },
                    series: [
                        {
                            showInLegend: false,
                            name: "Min Respond Time",
                            data: responsemeantime,
                            color: colorPalettes.color1,
                        },
                    ],
                };
                if (_responseAndResolvedTiming && _responseAndResolvedTiming.length) {
                    respondTimeChart.yAxis.plotLines = [
                        {
                            color: "#FF0000",
                            width: 2,
                            value: _responseAndResolvedTiming[0].avgResponseTime > 0 ? (_responseAndResolvedTiming[0].avgResponseTime / 3600).toFixed(1) : 0,
                            zIndex: 9,
                            label: {
                                align: "right",
                                formatter: function () {
                                    let elm = `${this.options.value}Hr`;
                                    return elm;
                                },
                                style: {
                                    color: "#FF0000",
                                    fontWeight: "bold",
                                },
                            },
                        },
                    ];
                }
                setRespondTimeChart(respondTimeChart);
                //Trouble Tickets
                // console.log("sdasca: ", dates);
                const troubleTicketsChart = {
                    chart: {
                        type: "bar",
                    },
                    title: {
                        text: "Day wise Trouble Tickets (Last 7 days)",
                        style: chartHeadingStyle,
                    },
                    xAxis: {
                        categories: dates,
                        crosshair: true,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Tickets Counts",
                        },
                        stackLabels: {
                            enabled: true,
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                    },
                    plotOptions: {
                        series: {
                            pointWidth: 40,
                        },
                        bar: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                            with: 10,
                            stacking: "normal",
                            dataLabels: {
                                enabled: true,
                            },
                        },
                    },
                    series: [
                        {
                            name: "Raised",
                            data: raisedTickets,
                            color: colorPalettes.color4,
                        },
                        {
                            name: "Accepted",
                            data: acceptedTickets,
                            color: colorPalettes.color1,
                        },
                        {
                            name: "Resolved",
                            data: resolvedTickets,
                            color: colorPalettes.color2,
                        },
                        {
                            name: "Closed",
                            data: closedTickets,
                            color: colorPalettes.color5,
                        },
                    ],
                };
                setTroubleTicketsChart(troubleTicketsChart);
                if (_ticketStatusCount && _ticketStatusCount.length) {
                    //Ticket Status
                    const ticketsStatusChart = {
                        chart: {
                            type: "pie",
                            options3d: {
                                enabled: true,
                                alpha: 45,
                                beta: 0,
                            },
                        },
                        title: {
                            text: "Tickets Status Wise Breakup (Last 30 Days)",
                            align: "center",
                            style: chartHeadingStyle,
                        },
                        accessibility: {
                            point: {
                                valueSuffix: "%",
                            },
                        },
                        tooltip: {
                            pointFormat: "{series.name}: <b>{point.y}</b>",
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: "pointer",
                                depth: 35,
                                dataLabels: {
                                    enabled: true,
                                    format: "{point.name}({y})",
                                },
                            },
                        },
                        series: [
                            {
                                type: "pie",
                                name: "Ticket Status",
                                data: [
                                    {
                                        name: "Raised Ticket",
                                        y: _ticketStatusCount[0].openedCount,
                                        color: colorPalettes.color4,
                                    },
                                    {
                                        name: "Closed Ticket",
                                        y: _ticketStatusCount[0].closedCount,
                                        color: colorPalettes.color5,
                                    },
                                    {
                                        name: "Resolved Ticket",
                                        y: _ticketStatusCount[0].resolvedCount,
                                        color: colorPalettes.color2,
                                    },
                                    {
                                        name: "Accepted Ticked",
                                        y: _ticketStatusCount[0].acceptedCount,
                                        color: colorPalettes.color1,
                                    },
                                ],
                            },
                        ],
                    };
                    setTicketsStatusChart(ticketsStatusChart);
                }

                if (_workHrTiming && _workHrTiming.length) {
                    let workHourCat = [],
                        avgWorkhr = [];

                    _workHrTiming.sort((a, b) => new Date(a._id.date).getTime() - new Date(b._id.date).getTime());
                    _workHrTiming.forEach((item) => {
                        workHourCat.push(moment(item._id.date, "YYYY-MM-DD").format("Do MMM"));
                        avgWorkhr.push(parseFloat((item.avgWorkHr / 3600).toFixed(2)));
                    });
                    // Day wise on site work hour for last 7 days
                    const onSiteWorkHr = {
                        chart: {
                            type: "column",
                        },
                        title: {
                            text: "Day Wise On Site work hours (Last 7 days)",
                            align: "center",
                            style: chartHeadingStyle,
                        },
                        xAxis: {
                            categories: workHourCat,
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: "On Site Work Hours (Hr)",
                            },
                        },
                        tooltip: {
                            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} hr</b></td></tr>',
                            footerFormat: "</table>",
                            shared: true,
                            useHTML: true,
                        },
                        plotOptions: {
                            column: {
                                pointPadding: 0.2,
                                borderWidth: 0,
                            },
                        },
                        series: [
                            {
                                name: "Work Hours",
                                data: avgWorkhr,
                                color: colorPalettes.color1,
                            },
                        ],
                    };
                    setOnSiteWorkHours(onSiteWorkHr);
                }
            });
        }
    }, [data, employeeName?.code, enabledEmpWise]);

    useEffect(() => {
        const currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
        const today = moment().format("YYYY-MM-DD");
        const formDate = moment(new Date()).subtract(30, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        const toDate = moment(new Date()).subtract(1, "day").endOf("day").format("YYYY-MM-DD HH:mm:ss");
        let date1 = moment(new Date(formDate)).format("YYYY-MM-DD");
        let date2 = moment(new Date(toDate)).format("YYYY-MM-DD");
        let expectedNoOfEmp = 0;
        if (data) {
            let { thing, allEmpData, msterShift, empIds } = data;

            if (!employeeName && enabledEmpWise) return
            if (employeeName && employeeName.code && enabledEmpWise) {
                empIds = [employeeName.empId]
                thing = [employeeName.code]
            }
            const currentRunningShift = msterShift.filter((e) => {
                let _start = moment(`${today} ${e.startTime}:00:00`);
                let _end = moment(`${today} ${e.endTime}:00:00`);
                return moment(currentDateTime).isBetween(_start, _end);
            });
            let _expectedEmployee = []
            currentRunningShift.forEach((item) => {
                _expectedEmployee.push(...allEmpData.filter((elm) => elm.shift === item.shift));
            });
            expectedNoOfEmp += _expectedEmployee.length;

            const payload = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            $and: [
                                {
                                    ts: { $gte: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"), $lte: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss") },
                                },
                                {
                                    thingname: { $in: thing },
                                },
                            ],
                        },
                    },
                    {
                        $group: {
                            _id: "$thingname",
                            maxTs: {
                                $max: "$ts",
                            },
                            records: {
                                $push: "$$ROOT",
                            },
                        },
                    },
                    {
                        $project: {
                            items: {
                                $filter: {
                                    input: "$records",
                                    as: "records",
                                    cond: {
                                        $eq: ["$$records.ts", "$maxTs"],
                                    },
                                },
                            },
                        },
                    },
                    {
                        $unwind: "$items",
                    },
                    {
                        $project: {
                            _id: 0,
                            thingname: "$_id",
                            data: "$items",
                        },
                    },
                    {
                        $match: {
                            "data.state": "InsideGF",
                        },
                    },
                    {
                        $group: {
                            _id: "$data.name",
                            count: {
                                $sum: 1,
                            },
                            data: {
                                $push: "$data",
                            },
                        },
                    },
                ],
                allowDiskUse: true,
            };
            const payload1 = {
                filter: {
                    thingName: {
                        $in: thing,
                    },
                    startTime: {
                        $gte: formDate,
                        $lte: toDate,
                    },
                },
                projection: {
                    thingName: 1,
                    date: 1,
                    startTime: 1,
                    endTime: 1,
                    totalTravelTime: 1,
                    totalDistanceTravelled: 1
                }
            };
            const payload2 = {
                filter: {
                    $and: [
                        { ts: { $gt: moment(new Date()).startOf("day").format("YYYY-MM-DD HH:mm:ss") } },
                        {
                            thingName: {
                                $in: thing,
                            },
                        },
                    ],
                },
            };
            let avgDistance_travelTime = {
                $gte: moment().subtract(6, "day").format("YYYY-MM-DD"),
                $lte: moment().format("YYYY-MM-DD"),
            }
            const payload3 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            $and: [
                                {
                                    date: avgDistance_travelTime,
                                },
                                {
                                    thingName: { $in: thing },
                                },
                            ],
                        },
                    },
                    {
                        $group: {
                            _id: "$date",
                            averageDistanceTravelled: {
                                $avg: "$totalDistanceTravelled",
                            },
                            averageTravelTime: {
                                $avg: "$totalTravelTime",
                            },
                        },
                    },
                    {
                        $sort: { date: 1 },
                    },
                ],
                allowDiskUse: true,
            };
            const payload4 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            thingName: {
                                $in: thing,
                            },
                            totalTravelTime: {
                                $gt: 0
                            }
                        },
                    },
                    {
                        $group: {
                            _id: {
                                date: { $toDate: "$date" }
                            },
                            avgTravelTime: {
                                $avg: "$totalTravelTime"
                            },
                        },
                    },
                    {
                        $group: {
                            _id: {
                                month: { $month: "$_id.date" },
                                year: { $year: "$_id.date" }
                            },
                            monthlyworkingHour: {
                                $avg: "$avgTravelTime"
                            }
                        }
                    },
                    {
                        $sort: {
                            "_id.year": -1,
                            "_id.month": -1
                        }
                    }
                ],
                allowDiskUse: true,
            };

            const payload5 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            TechnicianID: {
                                $in: empIds,
                            },
                            worktime: {
                                $gt: 0
                            }
                        },
                    },
                    {
                        $group: {
                            _id: {
                                date: { $toDate: "$timestamp" }
                            },
                            avgWorkHr: {
                                $avg: "$worktime",
                            },
                        },
                    },
                    {
                        $group: {
                            _id: {
                                month: { $month: "$_id.date" },
                                year: { $year: "$_id.date" }
                            },
                            monthlyonsiteworkingHour: {
                                $avg: "$avgWorkHr"
                            }
                        }
                    },
                    {
                        $sort: {
                            "_id.year": -1,
                            "_id.month": -1
                        }
                    }
                ],
            };
            const payload6 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            $and: [
                                {
                                    date: avgDistance_travelTime
                                },
                                {
                                    thingName: { $in: thing },
                                },
                            ],
                        },
                    },
                    {
                        $group: {
                            _id: 1,
                            averageDistanceTravelled: {
                                $avg: "$totalDistanceTravelled",
                            },
                            averageTravelTime: {
                                $avg: "$totalTravelTime",
                            },
                        },
                    },
                    {
                        $sort: { date: 1 },
                    },
                ],
                allowDiskUse: true,
            };
            const payload7 = {
                operation: "aggregate",
                aggregate: [{
                    $match: {
                        date: { $gte: moment(formDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"), $lte: moment(toDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") },
                        thingName: { $in: thing },
                    }
                }, {
                    $group: {
                        _id: "$date",
                        empCount: { $sum: 1 }
                    }
                }, {
                    $sort: {
                        "_id": 1
                    }
                }]
            }

            let siteWiseCurrentEmpData = [],
                manCount = [],
                site = [],
                categories = [],
                attendenceCount = [],
                currentPresentEmployees = [],
                employee = [];
            Promise.all([
                employeeService.getManpower(serviceId, payload),
                employeeService.getLocationData(serviceId, payload1),
                employeeService.getLatestRecord(serviceId, payload2),
                employeeService.getLocationAggrigation(serviceId, payload3),
                employeeService.getLocationAggrigation(serviceId, payload4),
                employeeService.getTicketCountByStatus(serviceId, payload5),
                employeeService.getLocationAggrigation(serviceId, payload6),
                employeeService.getEmpAttendanceCount(serviceId, payload7)
            ]).then((res) => {
                const _manPower = res[0];
                const _locationData = res[1];
                const _latestEmployee = res[2];
                const _dalyAvgDistanceTravelTime = res[3];
                const _montlyAvgTravelTime = res[4];
                const _montlyAvgOnSiteWorkHr = res[5];
                // const _montlyAvgOnSiteWorkHr = monthWiseOnsiteWorkHr;
                const _monthlyAvgDistanceAndTravelTime = res[6];
                const _emp30daysAttendanceCount = res[7]

                _manPower.forEach((item) => {
                    if (item !== null) {
                        manCount.push(item.count);
                        site.push(item._id);
                        let currentPresentEmployeeData = item.data;
                        currentPresentEmployeeData.forEach((elm) => {
                            let index = allEmpData.findIndex((element) => element.thingName === elm.thingname);
                            if (index > -1) {
                                siteWiseCurrentEmpData.push({
                                    ...elm,
                                    employeeName: allEmpData[index].name,
                                });
                            }
                        });
                    }
                });

                // Daily Workforce Attendance Trends(Last 30 Days)
                let attendindex = 0
                while (moment(date1).isSameOrBefore(date2)) {
                    categories.push(moment(date1).format("Do MMM"))
                    if (date1 === _emp30daysAttendanceCount[attendindex]?._id) {
                        attendenceCount.push(_emp30daysAttendanceCount[attendindex].empCount)
                        attendindex++
                    }
                    else {
                        attendenceCount.push(null)
                    }
                    date1 = moment(date1).add(1, "days").format("YYYY-MM-DD");
                }
                // Current present employees
                _latestEmployee.forEach((item) => {
                    if (!currentPresentEmployees.some((a) => a === item.thingName)) {
                        currentPresentEmployees.push(item.thingName);
                    }
                });

                let _actualPresent = []
                currentPresentEmployees.map((item) => {
                    let index = allEmpData.findIndex((e) => e.thingName === item)
                    if (index > -1) {
                        _actualPresent.push(allEmpData[index])
                    }
                })

                _locationData.forEach((a) => {
                    if (!employee.includes(a.thingName)) {
                        employee.push(a.thingName);
                    }
                });

                let totalAvgTravelTimes = 0;
                let totalAvgIdelTime = 0;
                let poor = 0;
                let average = 0;
                let good = 0;
                let vGood = 0;
                let travelDistanceArray = [];
                let range;
                let poorDistance = 0;
                let averageDistance = 0;
                let goodDistance = 0;
                let excelntDistance = 0;

                let scheduledActualEmpWHTrendsData = {
                    "0-25 %": [],
                    "25-50 %": [],
                    "50-75 %": [],
                    ">75 %": []
                }

                employee.forEach((emp) => {
                    let empArr = _locationData.filter((elm) => elm.thingName === emp);
                    let totalTimeEachEmployee = 0,
                        totalTravelTimeEachEmployee = 0,
                        totalIdelTimeEachEmployee = 0,
                        totalDistanceEachEmployee = 0;
                    empArr.forEach((item) => {
                        let end = moment(item.endTime);
                        let start = moment(item.startTime);
                        let tempTotalTime = moment.duration(end.diff(start)).as("hours");
                        totalTimeEachEmployee += tempTotalTime;
                        totalTravelTimeEachEmployee += item.totalTravelTime / 3600;
                        totalIdelTimeEachEmployee += totalTimeEachEmployee - totalTravelTimeEachEmployee;
                        totalDistanceEachEmployee += item.totalDistanceTravelled / 1000;
                    });

                    let avgTotalTime = totalTimeEachEmployee / empArr.length;

                    if (avgTotalTime <= 2) {
                        poor += 1;
                        scheduledActualEmpWHTrendsData["0-25 %"].push(emp)
                    } else if (avgTotalTime > 2 && avgTotalTime <= 4) {
                        average += 1;
                        scheduledActualEmpWHTrendsData["25-50 %"].push(emp)
                    } else if (avgTotalTime > 4 && avgTotalTime <= 6) {
                        good += 1;
                        scheduledActualEmpWHTrendsData["50-75 %"].push(emp)
                    } else if (avgTotalTime > 6) {
                        vGood += 1;
                        scheduledActualEmpWHTrendsData[">75 %"].push(emp)
                    }

                    let avgTotalDistanceEachEmployee = totalDistanceEachEmployee / empArr.length;
                    travelDistanceArray.push(avgTotalDistanceEachEmployee);

                    let avgTravelTimeEachEmployee = totalTravelTimeEachEmployee / empArr.length;
                    let avgIdelTime = totalIdelTimeEachEmployee / empArr.length;
                    totalAvgIdelTime += avgIdelTime / employee.length;
                    totalAvgTravelTimes += avgTravelTimeEachEmployee / employee.length;
                });

                range = getRanges(travelDistanceArray);
                travelDistanceArray.forEach((elm) => {
                    if (elm > range[0][0] && elm <= range[0][1]) {
                        poorDistance += 1;
                    } else if (elm > range[1][0] && elm <= range[1][1]) {
                        averageDistance += 1;
                    } else if (elm > range[2][0] && elm <= range[3][1]) {
                        goodDistance += 1;
                    } else if (elm > range[3][0] && elm <= range[3][1]) {
                        excelntDistance += 1;
                    }
                });

                //
                let dayWiseCat = [],
                    distanceData = [],
                    timeData = [];
                if (_dalyAvgDistanceTravelTime.length > 0) {
                    _dalyAvgDistanceTravelTime.forEach((item) => {
                        dayWiseCat.push(moment(item._id, "YYYY-MM-DD").format("Do MMM, YYYY"));
                        distanceData.push(parseFloat((item.averageDistanceTravelled / 1000).toFixed(2)));
                        timeData.push(parseFloat((item.averageTravelTime / 3600).toFixed(1)));
                    });
                }

                //Monthly average travel time

                let monthsName = [],
                    monthlyavgOnsiteWorkHour = [],
                    monthlyAverageTravelTime = [];
                if (_montlyAvgTravelTime.length) {
                    for (let i = 0; i < 6; i++) {
                        if (_montlyAvgTravelTime[i] && _montlyAvgTravelTime[i].monthlyworkingHour){
                            monthlyAverageTravelTime.push(parseFloat((_montlyAvgTravelTime[i].monthlyworkingHour / 3600).toFixed(2)));
                            monthsName.push(moment(_montlyAvgTravelTime[i]._id.month, "MM").format("MMM"));
                        }
                        // else monthlyAverageTravelTime.push(null)
                        if (_montlyAvgOnSiteWorkHr.length > 0) monthlyavgOnsiteWorkHour.push(parseFloat((_montlyAvgOnSiteWorkHr[i].monthlyonsiteworkingHour / 3600).toFixed(2)));
                    }
                }


                //Monthly average travel time
                const monthlyAverageTravelTimeChart = {
                    chart: {
                        type: "column",
                    },
                    title: {
                        text: "Month wise Avg Daily Travel Time and Avg Daily On site Work hours",
                        align: "center",
                        style: chartHeadingStyle,
                    },
                    xAxis: {
                        categories: monthsName,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Travel Time (Hr)",
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:.1f} Hr</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                        },
                    },
                    series: [
                        {
                            name: "Average Travel Time",
                            data: monthlyAverageTravelTime,
                            color: colorPalettes.color2,
                        },
                        {
                            name: "On Site Work Hour",
                            data: monthlyavgOnsiteWorkHour,
                            color: colorPalettes.color5,
                        },
                    ],
                };
                setMonthlyAverageTravelTimeChart(monthlyAverageTravelTimeChart);
                //Average Daly Distance Travel (Last 7 Days)
                const averageDalyDistanceTravel = {
                    chart: {
                        type: "column",
                    },
                    title: {
                        text: "Average Daily Distance Travelled (Last 7 Days)",
                        align: "center",
                        style: chartHeadingStyle,
                    },
                    xAxis: {
                        categories: dayWiseCat,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Distance Travelled (Km)",
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:.1f} Km</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                        },
                    },
                    series: [
                        {
                            showInLegend: false,
                            name: "Distance Travelled",
                            data: distanceData,
                            color: colorPalettes.color1,
                        },
                    ],
                };
                if (_monthlyAvgDistanceAndTravelTime && _monthlyAvgDistanceAndTravelTime.length) {
                    averageDalyDistanceTravel.yAxis.plotLines = [
                        {
                            color: "#FF0000",
                            width: 2,
                            value: _monthlyAvgDistanceAndTravelTime[0].averageDistanceTravelled > 0 ? (_monthlyAvgDistanceAndTravelTime[0].averageDistanceTravelled / 1000).toFixed(1) : 0,
                            zIndex: 9,
                            label: {
                                align: "right",
                                formatter: function () {
                                    let elm = `${this.options.value}Km`;
                                    return elm;
                                },
                                style: {
                                    color: "#FF0000",
                                    fontWeight: "bold",
                                },
                            },
                        },
                    ];
                }
                setDailyAvgDistanceTravel(averageDalyDistanceTravel);
                //Average Daly Time Travel (Last 7 Days)
                const averageTimeTravelled = {
                    chart: {
                        type: "column",
                    },
                    title: {
                        text: "Average Daily Time Travelled (Last 7 Days)",
                        align: "center",
                        style: chartHeadingStyle,
                    },
                    xAxis: {
                        categories: dayWiseCat,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Time Travelled (Hr)",
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:.1f} Hr</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                        },
                    },
                    series: [
                        {
                            showInLegend: false,
                            name: "Time Travelled",
                            data: timeData,
                            color: colorPalettes.color2,
                        },
                    ],
                };
                if (_monthlyAvgDistanceAndTravelTime && _monthlyAvgDistanceAndTravelTime.length) {
                    averageTimeTravelled.yAxis.plotLines = [
                        {
                            color: "#FF0000",
                            width: 2,
                            value: _monthlyAvgDistanceAndTravelTime[0].averageTravelTime > 0 ? (_monthlyAvgDistanceAndTravelTime[0].averageTravelTime / 3600).toFixed(1) : 0,
                            zIndex: 9,
                            label: {
                                align: "right",
                                formatter: function () {
                                    let elm = `${this.options.value}Hr`;
                                    return elm;
                                },
                                style: {
                                    color: "#FF0000",
                                    fontWeight: "bold",
                                },
                            },
                        },
                    ];
                }
                setDailyAvgTimeTravel(averageTimeTravelled);
                //Preformance Chart
                const performanceChart = {
                    chart: {
                        type: "pie",
                        options3d: {
                            enabled: true,
                            alpha: 45,
                            beta: 0,
                        },
                    },
                    title: {
                        text: "Scheduled Vs Actual Work Hours Trends Last 30 Days",
                        align: "center",
                        style: chartHeadingStyle,
                    },
                    accessibility: {
                        point: {
                            valueSuffix: "%",
                        },
                    },
                    tooltip: {
                        pointFormat: "{series.name}: <b>{point.y}</b>",
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            depth: 35,
                            dataLabels: {
                                enabled: true,
                                format: "{point.name}",
                            },
                        },
                    },
                    series: [
                        {
                            type: "pie",
                            name: "Employee",
                            data: [
                                {
                                    name: "0-25 %",
                                    y: poor,
                                    color: colorPalettes.color1,
                                },
                                {
                                    name: "25-50 %",
                                    y: average,
                                    color: colorPalettes.color2,
                                },
                                {
                                    name: "50-75 %",
                                    y: good,
                                    color: colorPalettes.color3,
                                },
                                {
                                    name: ">75 %",
                                    y: vGood,
                                    color: colorPalettes.color4,
                                },
                            ],
                            point: {
                                events: {
                                    click: (e) => {
                                        let filteredWHTrendsData = allEmpData.filter((item) => scheduledActualEmpWHTrendsData[e.point.name].includes(item.thingName))
                                        handleChartClick(filteredWHTrendsData)
                                    }
                                }
                            }
                        },
                    ],
                };
                setPerformenceChart(performanceChart);
                //
                const travelTimeChart = {
                    chart: {
                        type: "pie",
                        options3d: {
                            enabled: true,
                            alpha: 45,
                            beta: 0,
                        },
                    },
                    title: {
                        text: "Time Spent Breakup(Avg for Last 30 Days) ",
                        align: "center",
                        style: chartHeadingStyle,
                    },
                    accessibility: {
                        point: {
                            valueSuffix: "%",
                        },
                    },
                    tooltip: {
                        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            depth: 35,
                            dataLabels: {
                                enabled: true,
                                format: "{point.name}",
                            },
                        },
                    },
                    series: [
                        {
                            type: "pie",
                            name: "Employee",
                            data: [
                                {
                                    name: "Work Time",
                                    y: totalAvgIdelTime,
                                    color: colorPalettes.color1,
                                },
                                {
                                    name: "Travel Time",
                                    y: totalAvgTravelTimes,
                                    color: colorPalettes.color2,
                                },
                            ],
                        },
                    ],
                };
                setTravelTimeChart(travelTimeChart);
                //
                const distanceChart = {
                    chart: {
                        type: "pie",
                        options3d: {
                            enabled: true,
                            alpha: 45,
                            beta: 0,
                        },
                    },
                    title: {
                        text: "Avg Distance Breakup for Last 30 Days",
                        align: "center",
                        style: chartHeadingStyle,
                    },
                    accessibility: {
                        point: {
                            valueSuffix: "%",
                        },
                    },
                    tooltip: {
                        pointFormat: "{series.name}: <b>{point.y}</b>",
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            depth: 35,
                            dataLabels: {
                                enabled: true,
                                format: "{point.name}",
                            },
                        },
                    },
                    series: [
                        {
                            name: "Employee",
                            type: "pie",
                            data: [
                                {
                                    name: range[0][0] + "-" + range[0][1] + "Km",
                                    y: poorDistance,
                                    color: colorPalettes.color1,
                                },
                                {
                                    name: range[1][0] + "-" + range[1][1] + "Km",
                                    y: averageDistance,
                                    color: colorPalettes.color2,
                                },
                                {
                                    name: range[2][0] + "-" + range[2][1] + "Km",
                                    y: goodDistance,
                                    color: colorPalettes.color3,
                                },
                                {
                                    name: range[3][0] + "-" + range[3][1] + "Km",
                                    y: excelntDistance,
                                    color: colorPalettes.color4,
                                },
                            ],
                        },
                    ],
                };
                setDistanceChart(distanceChart);
                //Site wise man power
                const sitewisemanpower = {
                    chart: {
                        type: "column",
                    },
                    title: {
                        text: `Sitewise Manpower presence (As of ${moment(new Date()).format("Do MMM, YYYY")})`,
                        align: "center",
                        style: chartHeadingStyle,
                    },

                    xAxis: {
                        categories: site,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Manpower",
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                    },
                    legend: {
                        enabled: false,
                    },
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: true,
                                format: "{point.y}",
                            },
                            pointWidth: 30,
                            point: {
                                events: {
                                    click: chartClick,
                                },
                            },
                        },
                    },
                    series: [
                        {
                            name: "Total Employee",
                            colorByPoint: true,
                            data: manCount,
                        },
                    ],
                };
                setSitewisemanpower(sitewisemanpower);
                // Workforce Status Chart
                const barIndexValueMap = {
                    "Employee": allEmpData,
                    "Expected Present": _expectedEmployee,
                    "Actual Present": _actualPresent
                }
                const WorkforceStatus = {
                    chart: {
                        type: "column",
                    },
                    title: {
                        text: "Workforce Status",
                        align: "center",
                        style: chartHeadingStyle,
                    },

                    xAxis: {
                        categories: ["Total Employee", "Expected Present", "Actual Present"],
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Manpower",
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                    },
                    legend: {
                        enabled: false,
                    },
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: true,
                                format: "{point.y}",
                            },
                            pointWidth: 30,
                        },
                    },
                    series: [
                        {
                            name: "Total Employee",
                            colorByPoint: true,
                            data: [
                                {
                                    name: "Employee",
                                    y: allEmpData.length,
                                    color: colorPalettes.color1,
                                },
                                {
                                    name: "Expected Present",
                                    y: expectedNoOfEmp,
                                    color: colorPalettes.color2,
                                },
                                {
                                    name: "Actual Present",
                                    y: currentPresentEmployees.length,
                                    color: colorPalettes.color3,
                                },
                            ],
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: (e) => handleChartClick(barIndexValueMap[e.point.name])
                                }
                            }
                        },
                    ],
                };
                setWorkForceStatus(WorkforceStatus);
                // Daily Workforce Attendance Trends(Last 30 Days)
                const dateWiseEmployeeAttendance = {
                    chart: {
                        type: "spline",
                    },
                    title: {
                        text: "Daily Workforce Attendance Trends(Last 30 Days)",
                        align: "center",
                        style: chartHeadingStyle,
                    },

                    xAxis: {
                        categories: categories,
                        title: {
                            text: !enabledEmpWise ? "No. of Employee" : "Present",
                        },
                    },
                    yAxis: {
                        title: {
                            text: "No. of Employee",
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "";
                            },
                        },
                    },
                    tooltip: {
                        crosshairs: true,
                        shared: true,
                    },
                    plotOptions: {
                        spline: {
                            marker: {
                                radius: 4,
                                lineColor: colorPalettes.color1,
                                lineWidth: 1,
                            },
                        },
                    },
                    series: [
                        {
                            name: !enabledEmpWise ? "Number Of Employee" : "Present",
                            marker: {
                                symbol: "square",
                            },
                            data: attendenceCount,
                            color: colorPalettes.color1,
                        },
                    ],
                };
                setEmployeeCountPreDay(dateWiseEmployeeAttendance);
            });
            setManPower(manCount);
            setSites(site);
            setallManPowerData(siteWiseCurrentEmpData);
        }
    }, [data, employeeName?.code, enabledEmpWise]);

    const handleChartClick = (data) => {
        setOnChartClickData(data)
        setShowModal(true)
    }
    const getHeader = (name) => {
        return <div className="flex align-items-center"><Button icon="pi pi-download" tooltip="Click to download" className="p-button-rounded mr-2 mb-2" onClick={() => onDownload()}></Button><span style={{ display: "inlineBlock", verticalAlign: "middle" }}>Employee Details</span></div>;
    };
    const onDownload = () => {
        let exportData = [];
        exportData.push(["SL", "Employee Id", "Name", "Email", "Mobile", "Circle"]);
        onChartClickData.forEach((e, i) => {
            exportData.push([i + 1, e.empId, e.name, e.email, e.mobile, e.circle]);
        });
        var workbook = XLSX.utils.book_new(),
            worksheet = XLSX.utils.aoa_to_sheet(exportData);
        workbook.SheetNames.push("First");
        workbook.Sheets["First"] = worksheet;
        XLSX.writeFile(workbook, "Employee.xlsx");
    }

    const dialogFuncMap = {
        displayBasic: setDisplayBasic,
    };

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const chartClick = (e) => {
        onClick("displayBasic");
        let categoryName = e.point.category;
        const manPower = allManPowerData.filter((item) => item.name === categoryName);
        setManPowerData(manPower);
    };

    const timeConvert = (value) => {
        return (
            <>
                <p>{secondsToHm(value.duration)}</p>
            </>
        );
    };

    const tsConvert = (value) => {
        return <>{serviceDetails.region === "us-east-1" || serviceDetails.region === "us" || serviceDetails.region === "US" ? moment(value.ts).format("MMM DD, HH:mm") : moment(value.ts).format("Do MMM, HH:mm")}</>;
    };

    const tableHeader = (
        <ColumnGroup>
            <Row>
                <Column header="Site Name" className="bg-primary"></Column>
                <Column header="Name" className="bg-primary"></Column>
                <Column header="Duration" className="bg-primary"></Column>
                <Column header="State" className="bg-primary"></Column>
                <Column header="InTime" className="bg-primary"></Column>
            </Row>
        </ColumnGroup>
    );

    const handleEnabledWiseChange = (e) => {
        setEnabledEmpWise(e.value)
        setEmployeeName(null)

        setEmployeeCountPreDay(null)
        setWorkForceStatus(null)
        setPerformenceChart(null)
        setTravelTimeChart(null)
        setDistanceChart(null)
        setSitewisemanpower(null)
        setDailyAvgDistanceTravel(null)
        setDailyAvgTimeTravel(null)
        setTicketsStatusChart(null)
        setTroubleTicketsChart(null)
        setRespondTimeChart(null)
        setResolveTimeChart(null)
        setOnSiteWorkHours(null)
        setMonthlyAverageTravelTimeChart(null)
    }
    return (
        <div className="grid">
            {(data && data.empIds.length > 1) && <div className="col-12">
                <div className="displayCard bg-white flex align-items-center">
                    <div className="col-8">
                        {enabledEmpWise && <Dropdown value={employeeName} options={empIdObj.current} onChange={(e) => setEmployeeName(e.value)} className="bg-white w-full" optionLabel="name" filter placeholder="Select Employee" />}
                    </div>
                    <div className="col-4">
                        <h5 className="text-center m-0">Employee Wise Report
                            <InputSwitch style={{ float: "right" }} checked={enabledEmpWise} onChange={(e) => handleEnabledWiseChange(e)} />
                        </h5>
                    </div>
                </div>
            </div>}
            {!enabledEmpWise && <>
                {workForceStatus ? (
                    <div className="col-12 lg:col-6">
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={workForceStatus} />
                        </div>
                    </div>
                ) : <div className="col-12 lg:col-6">
                    <div className="card">
                        <Skeleton width="100%" height="50vh"></Skeleton>
                    </div>
                </div>}
                {performenceChart ? (
                    <div className="col-12 lg:col-6">
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={performenceChart} />
                        </div>
                    </div>
                ) : <div className="col-12 lg:col-6">
                    <div className="card">
                        <Skeleton width="100%" height="50vh"></Skeleton>
                    </div>
                </div>}
            </>}

            {employeeCountPreDay ? (
                <div className="col-12">
                    <div className="card">
                        <HighchartsReact highcharts={Highcharts} options={employeeCountPreDay} />
                    </div>
                </div>
            ) : !enabledEmpWise ? <div className="col-12">
                <div className="card">
                    <Skeleton width="100%" height="50vh"></Skeleton>
                </div>
            </div> : null}

            {dailyAvgDistanceTravel ? (
                <div className="col-12 lg:col-6">
                    <div className="card">
                        <HighchartsReact highcharts={Highcharts} options={dailyAvgDistanceTravel} />
                        <p className="text-center font-bold c-30-day-w" style={{ fontSize: "12px" }}>
                            <span>Avg Daily Distance Travelled - Last 07 Days</span>
                        </p>
                    </div>
                </div>
            ) : null}

            {dailyAvgTimeTravel ? (
                <div className="col-12 lg:col-6">
                    <div className="card">
                        <HighchartsReact highcharts={Highcharts} options={dailyAvgTimeTravel} />
                        <p className="text-center font-bold c-30-day-w" style={{ fontSize: "12px" }}>
                            <span>Avg Daily Time Travelled - Last 07 Days </span>
                        </p>
                    </div>
                </div>
            ) : null}

            {travelTimeChart ? (
                <div className="col-12 lg:col-6">
                    <div className="card">
                        <HighchartsReact highcharts={Highcharts} options={travelTimeChart} />
                    </div>
                </div>
            ) : null}
            {distanceChart ? (
                <div className="col-12 lg:col-6">
                    <div className="card">
                        <HighchartsReact highcharts={Highcharts} options={distanceChart} />
                    </div>
                </div>
            ) : null}

            {sitewisemanpower && sitewisemanpower.series[0].data.length > 0 ? (
                <div className="col-12">
                    <HighchartsReact highcharts={Highcharts} options={sitewisemanpower} />
                    <Dialog header="" visible={displayBasic} style={{ width: "50vw" }} onHide={() => onHide("displayBasic")}>
                        <div className="datatable-selection-demo mt-4">
                            <div className="card">
                                <DataTable value={manPowerData} dataKey="" responsiveLayout="scroll" headerColumnGroup={tableHeader} rows={10} paginator>
                                    <Column field="name"></Column>
                                    <Column field="employeeName"></Column>
                                    <Column body={timeConvert}></Column>
                                    <Column field="state"></Column>
                                    <Column field={tsConvert}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </Dialog>
                </div>
            ) : null}
            {ticketsStatusChart ? (
                <div className="col-12 lg:col-6">
                    <div className="card">
                        <HighchartsReact highcharts={Highcharts} options={ticketsStatusChart} />
                    </div>
                </div>
            ) : null}
            {troubleTicketsChart ? (
                <div className="col-12 lg:col-6">
                    <div className="card">
                        <HighchartsReact highcharts={Highcharts} options={troubleTicketsChart} />
                    </div>
                </div>
            ) : null}
            {onSiteWorkHours ? (
                <div className="col-12 lg:col-6">
                    <div className="card">
                        <HighchartsReact highcharts={Highcharts} options={onSiteWorkHours} />
                    </div>
                </div>
            ) : null}

            {monthlyAverageTravelTimeChart ? (
                <div className="col-12 lg:col-6">
                    <div className="card">
                        <HighchartsReact highcharts={Highcharts} options={monthlyAverageTravelTimeChart} />
                    </div>
                </div>
            ) : null}

            {respondTimeChart ? (
                <div className="col-12 lg:col-6">
                    <div className="card">
                        <HighchartsReact highcharts={Highcharts} options={respondTimeChart} />
                        <p className="text-center font-bold c-30-day-w" style={{ fontSize: "12px" }}>
                            <span>Mean Time To Respond (Last 30 Days)</span>
                        </p>
                    </div>
                </div>
            ) : null}
            {resolveTimeChart ? (
                <div className="col-12 lg:col-6">
                    <div className="card">
                        <HighchartsReact highcharts={Highcharts} options={resolveTimeChart} />
                        <p className="text-center font-bold c-30-day-w" style={{ fontSize: "12px" }}>
                            <span>Mean Time To Resolve (Last 30 Days)</span>
                        </p>
                    </div>
                </div>
            ) : null}

            <Dialog header={getHeader} visible={showModal} modal style={{ width: "50vw" }} onHide={() => setShowModal(false)}>
                <div className="col-12">
                    {onChartClickData ? (
                        <DataTable value={onChartClickData} paginator={true} rows={10} responsiveLayout="scroll">
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Name" field={"name"}></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Employee Id" field={"empId"}></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Email" field={"email"}></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Phone No" style={{ width: "100px" }} field={"mobile"}></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Circle" field={"circle"}></Column>
                        </DataTable>
                    ) : <span>No Data Found</span>}
                </div>
            </Dialog>
        </div>
    );
};

export default EmployeeDashboard;
