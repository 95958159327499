/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
export default class MyxComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
        this.hideSpinner = this.hideSpinner.bind(this);
    }
    hideSpinner() {
        this.setState({
            loading: false,
        });
    }
    render() {
        return (
            <div className="container">
                <iframe src={`${encodeURI(this.props.site.myxlink)}#view=fitH`} style={{ height: "80vh", width: "100%" }} onLoad={this.hideSpinner} />
            </div>
        );
    }
}
