import React, { Component } from "react";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Badge } from "primereact/badge";
import { FuelMonitoringService } from "../../service/FuelMonitoringService";
import moment from "moment";
import { colorPalettes } from "../../utilities/constant";
import { VehicleSrvice } from "../../service/VehicleService";
import CardList from "../UiComponents/CardList";
import FuelLebelIgnitionChart from "../ChartsComponents/FuelLebelIgnitionChart";
import FuelConsumption from "../ChartsComponents/FuelConsumption";
import { Skeleton } from "primereact/skeleton";
import { InputText } from "primereact/inputtext";
import { connect } from 'react-redux';

class FuelUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRange: null,
            fuelLevelChart: null,
            consumptionChart: null,
            tableData: null,
            chartData: null,
            selectedChartData: null,
            livechart: null,
            selectedSite: null,
            fuelingDefuelingData: null,
            products: [],
            innerWidth: window.innerWidth,
        };
        this.responsiveOptions = [
            {
                breakpoint: "1024px",
                numVisible: 3,
                numScroll: 3,
            },
            {
                breakpoint: "600px",
                numVisible: 2,
                numScroll: 2,
            },
            {
                breakpoint: "480px",
                numVisible: 1,
                numScroll: 1,
            },
        ];
        this.serviceID = localStorage.getItem("serviceId");
        this.fuelMonitoringService = new FuelMonitoringService();
        this.vehicleService = new VehicleSrvice();
        this.eventTypeTemplate = this.eventTypeTemplate.bind(this);
        this.igTypeTemplate = this.igTypeTemplate.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.arr=this.props.thingName.thingNameContainer.length > 0?this.props.thingName.thingNameContainer[this.props.thingName.thingNameContainer.length-1]:[];
    }

    componentDidMount = () => {
        let startDate = moment().subtract(30, "d").format("YYYY-MM-DD");
        let endDate = moment().subtract(1, "d").format("YYYY-MM-DD");
        let dateRange = [new Date(startDate), new Date(endDate)];

       // if (this.props.isVehicle || !this.props.isVehicle) {  
            let isFuel = !this.props.isVehicle ? true : false
            let commonPayload={};
            if(this.arr.length>0){
                commonPayload= {
                    filter: {
                        "thingName": { $in:this.arr }
                    },
                }
            } 
            Promise.all([this.vehicleService.getVehicles(this.serviceID, isFuel, commonPayload), this.vehicleService.getFleetsData(this.serviceID, isFuel, commonPayload)])
                .then((res) => {
                    const vehicles = res[0];
                    const fleet = res[1];
                    let vehiclesData = vehicles.map((item) => {
                        let index = isFuel ? fleet.findIndex((elm) => elm.thingName === item.thingName) : fleet.findIndex((elm) => elm.fleetName === item.thingName);
                        if (index > -1) {
                            return {
                                ...item,
                                ...(!isFuel && {plateNo: fleet[index].vehicle.vehicleNumber}),
                                ...{alias: fleet[index].alias|| "" }
                            };
                        }
                    });
                    vehiclesData.sort((a,b)=> {if(a.ts < b.ts) return 1; else if(a.ts > b.ts) return -1; else return 0} )
                    this.setState({ selectedRange: dateRange, selectedSite: vehiclesData[0].thingName, tableData: vehiclesData }, () => {
                        this.initReport(this.state.selectedSite, this.state.selectedRange[0], this.state.selectedRange[1]);
                        this.getTrandeChart(this.state.selectedSite);
                        this.initChart(this.state.selectedSite);
                        this.initFuelingEvent(this.state.selectedSite);
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        // } else {
        //     Promise.all([this.fuelMonitoringService.getCurrentFuelData(this.serviceID), this.fuelMonitoringService.getThings(this.serviceID)])
        //         .then((res) => {
        //             console.log("-----------",res)
        //             const currentFuelData = res[0].responseData;
        //             const sites = res[1];
        //             let tableData = [];
        //             let chartData = [];
        //             sites.forEach((item) => {
        //                 let index = -1;
        //                 if (currentFuelData.length > 0) {
        //                     index = currentFuelData.findIndex((elm) => elm.thingName === item.thingName);
        //                 }
        //                 let tempObj = {
        //                     thingName: item.thingName,
        //                     make: item.location.siteId,
        //                     model: item.location.siteName,
        //                     lastFuelLevel: index > -1 ? currentFuelData[index].data[currentFuelData[index].data.length - 1].fuel : "--",
        //                     time: index > -1 ? moment(currentFuelData[index].data[currentFuelData[index].data.length - 1].ts).format("Do MMM, YY HH:mm") : "--",
        //                     ignition: index > -1 ? (currentFuelData[index].data[currentFuelData[index].data.length - 1].ignition === 0 ? "OFF" : "ON") : "--",
        //                 };
        //                 tableData.push(tempObj);
        //             });
        //             if (currentFuelData.length > 0) {
        //                 currentFuelData.forEach((item) => {
        //                     let fuelLevel = [];
        //                     let ignition = [];
        //                     let dataTime = [];
        //                     item.data.forEach((elm) => {
        //                         dataTime.push(moment(elm.ts).format("HH:mm"));
        //                         fuelLevel.push(elm.fuel);
        //                         ignition.push(elm.ignition === 1 ? 100 : 0);
        //                     });
        //                     let tempObj2 = {
        //                         data: [
        //                             {
        //                                 name: "Fuel Level",
        //                                 data: fuelLevel,
        //                                 type: "area",
        //                                 color: colorPalettes.color2,
        //                                 tooltip: {
        //                                     valueSuffix: " L",
        //                                 },
        //                             },
        //                             {
        //                                 name: "Ignition",
        //                                 data: ignition,
        //                                 type: "spline",
        //                                 color: colorPalettes.color4,
        //                             },
        //                         ],
        //                         category: dataTime,
        //                         title: "Fuel Level Trend (for the day)",
        //                     };
        //                     chartData.push(tempObj2);
        //                 });
        //             }

        //             this.setState({ tableData, selectedSite: tableData[0].thingName, chartData, selectedChartData: chartData[0], selectedRange: dateRange }, () => {
        //                 this.initReport(this.state.selectedSite, this.state.selectedRange[0], this.state.selectedRange[1]);
        //                 this.getTrandeChart(this.state.selectedSite);
        //                 if (this.state.selectedChartData) {
        //                     this.initChart(this.state.selectedChartData);
        //                 }
        //                 this.initFuelingEvent(this.state.selectedSite);
        //             });
        //         })
        //         .catch((e) => {
        //             console.log(e);
        //         });
        // }
    };

    onSelectItem = (item) => {
        //this.setState({...this.state, })
        this.setState({ selectedSite: item.thingName, fuelLevelChart : null, livechart: null}, () => {
            this.initReport(this.state.selectedSite, this.state.selectedRange[0], this.state.selectedRange[1]);
            this.getTrandeChart(this.state.selectedSite);
            this.initChart(this.state.selectedSite);
            this.initFuelingEvent(this.state.selectedSite);
        });
    };

    onDateChange = (e) => {
        this.setState({ selectedRange: e.value }, () => {
            if (this.state.selectedRange[1] !== null) {
                this.initReport(this.state.selectedSite, this.state.selectedRange[0], this.state.selectedRange[1]);
                this.initFuelingEvent(this.state.selectedSite);
            }
        });
    };

    initChart = (data) => {
        //if (this.props.isVehicle || !this.props.isVehicle) {
            const payload = {
                serviceId: this.serviceID,
                thingName: data,
                date: moment().format("YYYY-MM-DD"),
                type: "fuel"
            };

            this.vehicleService.getLiveFuelLeve(payload).then((res) => {
                let dateLable = [];
                let fuelLevel = [];
                let ignition = [];
                if (res && Object.keys(res).length) {
                    res.data.forEach((item) => {
                        dateLable.push(moment(item.ts).format("MMM Do, HH:mm"));
                        fuelLevel.push(item.fuel);
                        ignition.push(item.ign === 1 ? 100 : 0);
                    });
                }
                const chart = {
                    data: [
                        {
                            name: "Fuel Level",
                            data: fuelLevel,
                            type: "area",
                            color: colorPalettes.color2,
                            tooltip: {
                                valueSuffix: " L",
                            },
                        },
                        {
                            name: "Ignition",
                            data: ignition,
                            type: "spline",
                            color: colorPalettes.color4,
                        },
                    ],
                    category: dateLable,
                    title: "Fuel Level Trend (for the day)",
                };
                this.setState({ livechart: chart });
            });
        // } else {
        //     this.setState({ livechart: data });
        // }
    };

    getTrandeChart = (thing) => {
        const form_date = moment().subtract(7, "d").format("YYYY-MM-DD");
        const to_date = moment().subtract(1, "d").format("YYYY-MM-DD");
        const payload1 = {
            filter: {
                thingName: thing,
                ts: {
                    $gte: moment(form_date).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                    $lte: moment(to_date).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                },
                eventIoId: {$ne: 247}
            },
            projection: {
                _id: 1,
                ts: 1,
                fuelLevelCalibrated: 1,
                ignition: 1,
            },
        };
        this.fuelMonitoringService
            .getFuelLevelTrends(this.serviceID, payload1)
            .then((res) => {
                if (res) {
                    let fuelTrandesData = res.sort((a, b) => {
                        const date1 = new Date(a.ts);
                        const date2 = new Date(b.ts);
                        return date1 - date2;
                    });
                    let dateLable = [];
                    let fuelLevel = [];
                    let ignition = [];
                    fuelTrandesData.forEach((item) => {
                        dateLable.push(moment(item.ts).format("MMM Do, HH:mm"));
                        fuelLevel.push(item.fuelLevelCalibrated);
                        ignition.push(item.ignition === 1 ? 100 : 0);
                    });

                    const trandChart = {
                        title: `Fuel Level Trend (${moment(form_date).format("Do MMM, YYYY")} to ${moment(to_date).format("Do MMM, YYYY")})`,
                        category: dateLable,
                        data: [
                            {
                                name: "Fuel Level",
                                data: fuelLevel,
                                type: "area",
                                color: colorPalettes.color2,
                                tooltip: {
                                    valueSuffix: " L",
                                },
                            },
                            {
                                name: "Ignition",
                                data: ignition,
                                type: "spline",
                                color: colorPalettes.color4,
                            },
                        ],
                    };

                    this.setState({ fuelLevelChart: trandChart });
                }
                else{
                    this.setState({ fuelLevelChart: null })
                }
            })
            .catch((e) => console.log(e));
    };

    initFuelingEvent = (thingName) => {
        const query = {
            filter: {
                thingName: thingName,
                date: {
                    $gte: moment(this.state.selectedRange[0]).format("YYYY-MM-DD"),
                    $lte: moment(this.state.selectedRange[1]).format("YYYY-MM-DD"),
                },
            },
        };
        let fuelingDefuelingData;
        this.fuelMonitoringService.getFuelingHistory(this.serviceID, query).then((res) => {
            res.sort((a, b) => {
                return new Date(b.startTime).getTime() - new Date(a.startTime).getTime();
            });

            fuelingDefuelingData = res.map((item) => {
                return {
                    thingName: item.thingName,
                    siteName: item.siteName,
                    state: item.state,
                    volume: item.amount.toFixed(2),
                    type: item.type,
                    dateTime: moment(item.startTime).format("Do MMM, YY HH:mm"),
                };
            });
            this.setState({ fuelingDefuelingData });
        });
    };

    initReport = (thing, startDate, endDate) => {
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        thingName: thing,
                        date: {
                            $gte: startDate,
                            $lte: endDate,
                        },
                    },
                },
            ],
        };

        this.fuelMonitoringService
            .getDailySummeryData(this.serviceID, payload)
            .then((res) => {
                if (res && res.length) {
                    let dailyData = res.sort((a, b) => {
                        const date1 = new Date(a.date);
                        const date2 = new Date(b.date);
                        return date1 - date2;
                    });
                    let categories = [],
                        consumedFule = [],
                        counsumedCost = [];
                    dailyData.forEach((item) => {
                        categories.push(moment(item.date).format("Do MMM, YYYY"));
                        consumedFule.push(item.fuelConsumed < 0 ? 0 : parseFloat(item.fuelConsumed.toFixed(2)));
                        counsumedCost.push(parseFloat(item.fuelConsumedCost.toFixed(2)));
                    });
                    const consumptionChart = {
                        title: `Daily Fuel Consumption`,
                        category: categories,
                        data: [
                            {
                                name: "Fuel Consumed",
                                type: "column",
                                yAxis: 1,
                                data: consumedFule,
                                color: colorPalettes.color1,
                                tooltip: {
                                    valueSuffix: " L",
                                },
                            },
                            {
                                name: "Consumption Cost",
                                type: "spline",
                                data: counsumedCost,
                                color: colorPalettes.color4,
                                tooltip: {
                                    valueSuffix: " INR",
                                },
                            },
                        ],
                    };
                    this.setState({ consumptionChart: consumptionChart });
                }
            })
            .catch((e) => console.log(e));
    };

    eventTypeTemplate = (item) => {
        return item.type === "fuelling" ? <Badge value="Fuelling" severity="success" className="mr-2"></Badge> : <Badge value="Defuelling" severity="danger" className="mr-2"></Badge>;
    };

    igTypeTemplate = (item) => {
        return item.ignition === "ON" ? <Badge value="ON" severity="success" className="mr-2"></Badge> : <Badge value="OFF" severity="danger" className="mr-2"></Badge>;
    };

    NoChartDataDisplay = (reportTime) => (
        <>
            <div className="col-12 " style={{ color: "#01579B" }}>
                <div className="card flex flex-column align-items-center justify-content-center" style={{ height: "30vh", backgroundColor: "#B3E5FC" }}>
                    <span className="fa-solid fa-circle-info" style={{ fontSize: "3rem" }}></span>
                    <h3>No data received For {reportTime}</h3>
                </div>
            </div>
        </>
    );
    render = () => {
        const headerGroup1 = (
            <ColumnGroup>
                <Row>
                    <Column header="Date & Time" className="bg-primary" />
                    <Column header="Volume(L)" className="bg-primary" />
                    <Column header="Event Type" className="bg-primary" />
                </Row>
            </ColumnGroup>
        );
        return (
            <div className="grid">
                <div className="col-12 lg:col-3">{this.state.tableData ? <CardList data={this.state.tableData} onSelectItem={this.onSelectItem} selectedItem={this.state.selectedSite} isVehicle={this.props.isVehicle} /> : null}</div>
                <div className="col-12 lg:col-9">
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                {!this.state.livechart ? <Skeleton width="100%" height="200px" className="mt-2" /> : (this.state.livechart.data[0].data.length > 0 && this.state.livechart.data[1].data.length > 0) ? <FuelLebelIgnitionChart chartData={this.state.livechart} /> : this.NoChartDataDisplay("The Day")}

                                {!this.state.fuelLevelChart ? (
                                    <Skeleton width="100%" height="200px" className="mt-2" />
                                ) : this.state.fuelLevelChart.data[0].data.length > 0 && this.state.fuelLevelChart.data[1].data.length > 0 ? (
                                    <div className="col-12">
                                        <FuelLebelIgnitionChart chartData={this.state.fuelLevelChart} />
                                    </div>
                                ) : (this.NoChartDataDisplay("Last Week"))}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className="grid">
                                    {this.state.selectedRange ? (
                                        <div className="col-12">
                                            <h3 className="text-center">Fuel Usage Report ({moment(this.state.selectedRange[0]).format("Do MMM, YYYY") + " to " + moment(this.state.selectedRange[1]).format("Do MMM, YYYY")})</h3>
                                        </div>
                                    ) : null}
                                    <div className="field col-12 md:col-4">
                                        <Calendar id="range" value={this.state.selectedRange} onChange={this.onDateChange} selectionMode="range" readOnlyInput showIcon className="w-full" maxDate={new Date()} />
                                    </div>
                                    <div className="col-12"></div>
                                    {!this.state.consumptionChart ? null : this.state.consumptionChart.data[0].data.length > 0 && this.state.consumptionChart.data[1].data.length > 0 ? (
                                        <div className="col-12">
                                            <FuelConsumption chartData={this.state.consumptionChart} />
                                        </div>
                                    ) : (
                                        this.state.consumptionChart && this.state.consumptionChart.data[0].data.length === 0 && this.state.consumptionChart.data[1].data.length === 0 && <div className="no-data-grid">No data found</div>
                                    )}

                                    <div className="col-12">
                                        {this.state.fuelingDefuelingData ? (
                                            <>
                                                <h3>Fueling/Defueling Events</h3>
                                                <DataTable value={this.state.fuelingDefuelingData} headerColumnGroup={headerGroup1} responsiveLayout="scroll" paginator rows={10}>
                                                    <Column field="dateTime" className="font-semibold"></Column>
                                                    <Column field="volume" className="white-space-nowrap font-semibold"></Column>
                                                    <Column body={this.eventTypeTemplate}></Column>
                                                </DataTable>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        thingName: state
    };
};

export default connect(mapStateToProps)(FuelUsage);

