import React, { Component } from "react";
import moment from "moment";
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { FuelSrvice } from "../../../service/FuelService";

class FuellingEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: null,
            things: null, 
            selectedThing: null, 
            fuelingEventData: null
        };
        this.fuelService = new FuelSrvice();
        this.handelFulingDate = this.handelFulingDate.bind(this);
        this.handelFuelVolume = this.handelFuelVolume.bind(this);
    }

    componentDidMount = () => {
        let current_date = moment().format("YYYY-MM-DD");
        let previous_date = moment().subtract(6, "days").format("YYYY-MM-DD");
        
        this.setState({ 
            selectedThing: this.props.things 
        }, () => this.createReport(this.state.selectedThing, current_date, previous_date)); 
    }

    createReport = (things, current_date, previous_date) => {
        let thingArr = [];
        things.forEach(item => {
            thingArr.push({"thingName": item.name})
        });
        const payload = {
            "filter": {
                "$or": thingArr,
                "time": {"$gte": previous_date,"$lte": current_date}
            }
        };
        this.fuelService.getFuelingEventReport(this.props.serviceId, payload).then(res => {
            this.setState({ fuelingEventData: res, dateRange: [new Date(previous_date), new Date(current_date)] })
        });
    }

    handelDateChange = (e) => {
        this.setState({ dateRange: e.value }, () => {
            if (this.state.dateRange[1] !== null) {
                this.createReport(this.state.selectedThing, moment(this.state.dateRange[1]).format("YYYY-MM-DD"), moment(this.state.dateRange[0]).format("YYYY-MM-DD"));
            }
        });
    }

    handelThingChange = (e) => {
        let current_date = moment(this.state.dateRange[1]).format("YYYY-MM-DD");
        let previous_date = moment(this.state.dateRange[0]).format("YYYY-MM-DD");
        this.setState({ selectedThing: e.value }, () => this.createReport(this.state.selectedThing, current_date, previous_date))
    }

    handelFuelVolume = (item) => {
        return parseFloat(item.volume).toFixed(2);
    }
    
    handelFulingDate = (item) => {
        return moment(item.time, "YYYY-MM-DD HH:mm:ss").format("MMM D, YYYY HH:mm a")
    }

    render = () => {
        let headerGroup = (<ColumnGroup>
            <Row>
                <Column header="Date & Time" className=" bg-primary"></Column>
                <Column header="Equipment Name" className=" bg-primary"></Column>
                <Column header="Site ID" className=" bg-primary"></Column>
                <Column header="Site Name" className=" bg-primary"></Column>
                <Column header="Fueling / De-Fueling" className=" bg-primary"></Column>
                <Column header="Volume (Ltr)" className=" bg-primary"></Column>
            </Row>
        </ColumnGroup>);
        return (
            <div className="grid">
                <div className="col-12 lg:col-3">
                    <Calendar id="range" value={this.state.dateRange} onChange={(e) => this.handelDateChange(e)} selectionMode="range" readOnlyInput className="w-full" />
                </div>
                <div className="col-12 lg:col-3">
                    <MultiSelect
                        className="w-full"
                        value={this.state.selectedThing}
                        options={this.props.things}
                        onChange={(e) => this.handelThingChange(e)}
                        optionLabel="name"
                        placeholder="Select a Site"
                        // maxSelectedLabels={3}
                    />
                </div>
                <div className="col-12" style={{maxHeight: '300px', overflowY: 'auto'}}>
                    {this.state.fuelingEventData ? (
                        <DataTable headerColumnGroup={headerGroup} value={this.state.fuelingEventData} responsiveLayout="scroll" showGridlines stripedRows>
                            <Column body={this.handelFulingDate} className="white-space-nowrap"></Column>
                            <Column field="thingName" className="white-space-nowrap"></Column>
                            <Column field="siteId" className="white-space-nowrap" ></Column>
                            <Column field="siteName" className="white-space-nowrap"></Column>
                            <Column field="fuelling/defuelling" className="white-space-nowrap"></Column>
                            <Column body={this.handelFuelVolume} className="white-space-nowrap"></Column>
                        </DataTable>
                    ) : null }
                </div>
            </div>
        )
    }
}

export default FuellingEvent;