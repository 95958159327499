import React, { Component } from "react";
import { connect } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Chart } from "primereact/chart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TowerService } from "../../service/TowerService";
import { mapKey } from "../../shared/data/const";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import TowerTransitionComponent from "./TowerTransitionComponent";
import { dispatchAction, convertCamelCase, getPercentageChange } from "../../utilities";
import { convertValue } from "../../utilities/unitConversion";
import { TabView, TabPanel } from "primereact/tabview";
import { colorPalettes, pieChartColors, barChartColors } from "../../lib/constants";
const Tenancy = {
    Zero: { $eq: 0 },
    Single: { $eq: 1 },
    Double: { $eq: 2 },
    Multi: { $eq: 3 },
};

const pieOptions = {
    plugins: {
        legend: {
            labels: {
                font: { size: 14 },
                usePointStyle: true,
                pointStyle: "circle",
            },
        },
    },
};

const textColor = getComputedStyle(document.body).getPropertyValue("--text-color") || "rgba(0, 0, 0, 0.87)";
const gridLinesColor = getComputedStyle(document.body).getPropertyValue("--divider-color") || "rgba(160, 167, 181, .3)";
const fontFamily = getComputedStyle(document.body).getPropertyValue("--font-family");

const barChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 1.5,
    plugins: {
        legend: {
            labels: {
                font: { size: 14 },
                fontFamily,
                color: textColor,
            },
        },
    },
    scales: {
        x: {
            ticks: {
                font: {
                    size: 14,
                },
                color: "black",
            },
            grid: {
                color: "white",
            },
        },
        y: {
            ticks: {
                fontFamily,
                color: textColor,
            },
            grid: {
                color: gridLinesColor,
            },
        },
    },
    barThickness: 30,
};

// var weatherbarChartOptions = JSON.parse(JSON.stringify(barChartOptions))

const getPieChartOptions = (pieClick) => {
    return {
        plugins: {
            legend: {
                display: true,
                labels: {
                    font: {
                        size: 14,
                    },
                    usePointStyle: true,
                    pointStyle: "circle",
                    // color: "#495057",
                    fontSize: "20px",
                },
            },
        },
        onClick: pieClick.bind(this),
    };
};

const borderColor = getComputedStyle(document.body).getPropertyValue("--divider-color") || "rgba(160, 167, 181, .3)";

class PortfolioComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allTowers: [],
            towerTypesCount: [],
            expandedRows: null,
            doughnutData: {},
            towerTypeBarData: {},
            tenancyPieData: {},
            tenancyPieIndex: null,
            tenancyStateWise: [],
            tIndex: -1,
            H: null,
            map: null,
            locationArr: [],
            displayInfo: false,
            unit: this.props.unit,
            dgCapacityStateWise: [],
            dgCapaityValue: null,
            getDigitizationStatusData: null,
        };
        this.towerService = new TowerService();
        this.serviceId = localStorage.getItem("serviceId");

        // this.count = [{"status": "Visual Model Available - " + countData["Visual Model Available"][this.serviceId], "count": "Integrated for Operations - " +  countData["Integrated for Operations"][this.serviceId]}];
        this.TenantNmQuery = {};
    }

    componentDidMount = () => {
        this.getAllData();
    };

    getAllData = () => {
        let distanceFromRoad = this.props.unit === "FPS" ? 1.609 : 1;
        Promise.all([
            this.towerService.getDegitizeAnalysis(),
            this.towerService.getAllTowers(),
            this.towerService.getTowerTypesCount(),
            this.towerService.getTenancyCount(),
            this.towerService.getWeatherData(),
            this.towerService.getOccupancyByTenants(),
            this.towerService.getDieselCapacityWiseCount(),
            this.towerService.getStateWiseInventoryCount(),
            this.towerService.getAccessibilityData(distanceFromRoad),
            this.towerService.getTowerTypeWiseCount(),
            this.towerService.getZoningWiseCount(),
            this.towerService.getFreeSlotsCount(),
            this.towerService.getTransportData(),
            this.towerService.getAntennaDifferenceCount(),
            this.towerService.getDigitizationStatus(this.serviceId),
        ])
            .then((response) => {
                let scope = response[0];
                let allTowers = response[1];
                let towerTypes = response[2];
                let tenancyCount = response[3][0];
                let weather = response[4];
                let tOccupancyCnt = response[5];
                let dgCapacityCount = response[6];
                let stateWiseInventoyCnt = response[7];
                let accessibiltyCount = response[8][0];
                let towerTypeCnt = response[9];
                let zoningCount = response[10];
                let freeSlotsData = response[11][0];
                let siteTypeData = response[12][0];
                let antennaDiffCount = response[13][0];
                this.totalSites = scope[0].towerIds.length;
                this.degitizeSites = allTowers.length;
                this.remainigSites = this.totalSites - this.degitizeSites;
                let getDigitizationStatusData = response[14];
                this.setState({ getDigitizationStatusData: getDigitizationStatusData });
                let doughnutData = {
                    labels: ["Remaining Site", "Digitized Site"],
                    datasets: [
                        {
                            data: [this.remainigSites, this.degitizeSites],
                            backgroundColor: [colorPalettes.color5, colorPalettes.color1],
                        },
                    ],
                };
                // Prepare tower type data
                var towerTyNMMap = { GBT: "Self Support", GBM: "Monopole" };
                // let towerTypeNm = [], towerTypeCount = [];
                // towerTypes.forEach(t => {
                // towerTypeNm.push(t._id);
                // towerTypeCount.push(t.count);
                // });
                // let newTNames = towerTypeNm.map(t => {
                // 	return t = towerTyNMMap[t] + " / " + t;
                // })
                // let towerTypeBarData = {
                // 	labels: newTNames,
                // 	datasets: [
                // 		{
                // 			label: 'Digitized',
                // 			data: towerTypeCount,
                // 			fill: false,
                // 			backgroundColor: colorPalettes.color1,
                // 			borderColor: colorPalettes.color1,
                // 			tension: .4
                // 		}
                // 	]
                // };

                //prepare weather data
                let weatherCondition = [],
                    weatherCnt = [];
                weather.forEach((t) => {
                    weatherCondition.push(t._id.description);
                    weatherCnt.push(t.count);
                });
                let weatherBarData = {
                    labels: weatherCondition,
                    datasets: [
                        {
                            label: "Weather Events",
                            data: weatherCnt,
                            fill: false,
                            backgroundColor: colorPalettes.color1,
                            borderColor: colorPalettes.color1,
                            tension: 0.4,
                        },
                    ],
                };

                //Tenancy count
                const tenancyPieData = {
                    labels: Object.keys(Tenancy),
                    datasets: [
                        {
                            data: [tenancyCount.noTenancy, tenancyCount.singleTenancy, tenancyCount.doubleTenancy, tenancyCount.multiTenancy],
                            backgroundColor: pieChartColors,
                            borderColor,
                        },
                    ],
                };

                let locationArr = [];
                let heightArr = [],
                    weightWithWindArr = [],
                    weightArr = [],
                    loadPercentage = [];
                this.atcLoadPerArr = [];
                //getting tower types and state
                allTowers.forEach((item) => {
                    locationArr.push({
                        customerSiteid: item.customerSiteid,
                        pos: {
                            lat: item.siteDetails.location.latitude,
                            lng: item.siteDetails.location.longitude,
                        },
                    });
                    let height = 0,
                        weightWithWind = 0,
                        weight = 0,
                        windLoadPercentage = 0,
                        atcloadPercentage = 0;
                    height = Math.ceil(convertValue(item.towerDetails.towerHeight.heightfromBase, "height", this.props.unit, null, true, null));
                    if (item.towerDetails.capacity.usedLoadCapacity && item.towerDetails.capacity.usedLoadCapacityWithWindLoad) {
                        weightWithWind = Math.ceil(convertValue(item.towerDetails.capacity.usedLoadCapacityWithWindLoad, "weight", this.props.unit, null, true, null));
                        windLoadPercentage = Math.abs(getPercentageChange(item.towerDetails.capacity.usedLoadCapacity, item.towerDetails.capacity.usedLoadCapacityWithWindLoad));
                    }
                    if (item.towerDetails.capacity.usedLoadCapacity) weight = Math.ceil(convertValue(item.towerDetails.capacity.usedLoadCapacity, "weight", this.props.unit, null, true, null));
                    if (item.atcTowerLoading && item.atcTowerLoading.deginedLoading) {
                        atcloadPercentage = parseFloat((((item.atcTowerLoading.actualLoading - item.atcTowerLoading.deginedLoading) / item.atcTowerLoading.actualLoading) * 100).toFixed(2));
                        this.atcLoadPerArr.push({ perc: atcloadPercentage, sites: [item.customerSiteid] });
                    }

                    heightArr.push(height);
                    weightWithWindArr.push(weightWithWind);
                    weightArr.push(weight);
                    loadPercentage.push(windLoadPercentage);
                });

                //Prepare height ranges
                var heightRanges = this.getRanges(heightArr);
                //Prepare weight with wind load ranges
                var weightWithWindRanges = this.getRanges(weightWithWindArr);
                //Prepare weight ranges
                var weightRanges = this.getRanges(weightArr);
                //Prepare windload percentage ranges
                var windLoadPercentageRanges = this.getRanges(loadPercentage);
                // this.getMap(locationArr, 7)

                //prepare Hight wise dataset
                let t_hight_count = [];
                heightRanges.forEach((item, index) => {
                    // let t_hight = item.split("-");
                    let t_h_from = item[0];
                    let t_h_to = item[1];

                    const t_height_length = allTowers.filter((elm) => {
                        if (index === 0) {
                            return convertValue(elm.towerDetails.towerHeight.heightfromBase, "height", this.props.unit, null, true, null) >= t_h_from && convertValue(elm.towerDetails.towerHeight.heightfromBase, "height", this.props.unit, null, true, null) <= t_h_to;
                        } else {
                            return convertValue(elm.towerDetails.towerHeight.heightfromBase, "height", this.props.unit, null, true, null) > t_h_from && convertValue(elm.towerDetails.towerHeight.heightfromBase, "height", this.props.unit, null, true, null) <= t_h_to;
                        }
                    });
                    let detail_circle = [];
                    if (t_height_length.length > 0) {
                        let stateWiseTTCount = {};
                        t_height_length.forEach((item) => {
                            let ttKey = item.towerDetails.towerConfig.towerType;
                            let towerSubType = towerTyNMMap[ttKey] ? towerTyNMMap[ttKey] + "/" : "";
                            if (stateWiseTTCount[item.siteDetails.location.state]) {
                                if (stateWiseTTCount[item.siteDetails.location.state][towerSubType + ttKey]) {
                                    stateWiseTTCount[item.siteDetails.location.state][towerSubType + ttKey] += 1;
                                    stateWiseTTCount[item.siteDetails.location.state]["towerIdsArr"].push(item.customerSiteid);
                                } else {
                                    stateWiseTTCount[item.siteDetails.location.state][towerSubType + ttKey] = 1;
                                    stateWiseTTCount[item.siteDetails.location.state]["towerIdsArr"].push(item.customerSiteid);
                                }
                            } else {
                                let typeCnt = { State: "", towerIdsArr: [] };
                                towerTypes.forEach((t) => {
                                    typeCnt[(towerTyNMMap[t._id] ? towerTyNMMap[t._id] + "/" : "") + t._id] = 0;
                                });
                                stateWiseTTCount[item.siteDetails.location.state] = typeCnt;
                                stateWiseTTCount[item.siteDetails.location.state][towerSubType + ttKey] = 1;
                                stateWiseTTCount[item.siteDetails.location.state]["towerIdsArr"].push(item.customerSiteid);
                            }
                        });

                        Object.keys(stateWiseTTCount).forEach((k) => {
                            stateWiseTTCount[k]["State"] = k;
                            detail_circle.push(stateWiseTTCount[k]);
                        });
                    }
                    t_hight_count.push({
                        id: "tower-height-count-" + index,
                        height: t_h_from + " - " + t_h_to + convertValue(null, "height", this.props.unit, null, null, true),
                        total: t_height_length.length,
                        circleDetails: [...detail_circle],
                    });
                });

                //prepare weight with wind load wise data
                // let rangesCntObj = {};
                // let dataSets = [];
                // weightWithWindRanges.forEach((w, i) => {
                // 	let w_min = w[0];
                // 	let w_max = w[1];
                // 	let rangeKey = (w_min + " - " + w_max + convertValue(null, "weight", this.props.unit, null, null, true));
                // 	const filteredTowers = allTowers.filter(elm => {
                // 		if (!elm.towerDetails.capacity.usedLoadCapacityWithWindLoad) elm.towerDetails.capacity.usedLoadCapacityWithWindLoad = 0;

                // 		if (i === 0) {
                // 			return (convertValue(elm.towerDetails.capacity.usedLoadCapacityWithWindLoad, "weight", this.props.unit, null, true, null) >= w_min &&
                // 				convertValue(elm.towerDetails.capacity.usedLoadCapacityWithWindLoad, "weight", this.props.unit, null, true, null) <= w_max)
                // 		}
                // 		else {
                // 			return (convertValue(elm.towerDetails.capacity.usedLoadCapacityWithWindLoad, "weight", this.props.unit, null, true, null) > w_min &&
                // 				convertValue(elm.towerDetails.capacity.usedLoadCapacityWithWindLoad, "weight", this.props.unit, null, true, null) <= w_max)
                // 		}
                // 	});

                // 	if (filteredTowers.length) {
                // 		let obj = { label: rangeKey, fill: true, borderColor: barColors[i], backgroundColor: barColors[i], data: [] };

                // 		let typeCntObj = {};
                // 		towerTypeNm.forEach(t => {
                // 			let typCnt = filteredTowers.filter(tt => tt.towerDetails.towerConfig.towerType === t);
                // 			typeCntObj[t] = typCnt.length || 0;
                // 			obj.data.push(typCnt.length || 0);

                // 		});
                // 		rangesCntObj[rangeKey] = typeCntObj;
                // 		dataSets.push(obj);
                // 	}
                // })

                // const weightWithWindLoadBarChart = {
                // 	labels: towerTypeNm,
                // 	datasets: dataSets
                // };

                this.towerService.getStateWiseData(parseFloat(tenancyCount.avgTenancy.toFixed(2))).then((stateWiseData) => {
                    this.setState({
                        stateWiseData: stateWiseData,
                    });
                });

                //Prepare weigth wise data
                // let weightDataSet = [], weightRangesCntObj = {};
                // weightRanges.forEach((w, i) => {
                // 	let w_min = w[0];
                // 	let w_max = w[1];
                // 	let rangeKey = (w_min + " - " + w_max + convertValue(null, "weight", this.props.unit, null, null, true));
                // 	const filteredTowers = allTowers.filter(elm => {
                // 		if (i === 0) {
                // 			return (convertValue(elm.towerDetails.capacity.usedLoadCapacity, "weight", this.props.unit, null, true, null) >= w_min &&
                // 				convertValue(elm.towerDetails.capacity.usedLoadCapacity, "weight", this.props.unit, null, true, null) <= w_max)
                // 		}
                // 		else {
                // 			return (convertValue(elm.towerDetails.capacity.usedLoadCapacity, "weight", this.props.unit, null, true, null) > w_min &&
                // 				convertValue(elm.towerDetails.capacity.usedLoadCapacity, "weight", this.props.unit, null, true, null) <= w_max)
                // 		}
                // 	});

                // 	if (filteredTowers.length) {
                // 		let obj = { label: rangeKey, fill: true, borderColor: barColors[i], backgroundColor: barColors[i], data: [] };

                // 		let typeCntObj = {};
                // 		towerTypeNm.forEach(t => {
                // 			let typCnt = filteredTowers.filter(tt => tt.towerDetails.towerConfig.towerType === t);
                // 			typeCntObj[t] = typCnt.length || 0;
                // 			obj.data.push(typCnt.length || 0);

                // 		});
                // 		weightRangesCntObj[rangeKey] = typeCntObj;
                // 		weightDataSet.push(obj);
                // 	}
                // });

                // const weightBarChart = {
                // 	labels: towerTypeNm,
                // 	datasets: weightDataSet
                // };

                // Aggregated weight chart
                let weightBeans = [],
                    newWeightDataset = [];
                this.weightRangesAndIds = [];
                weightRanges.forEach((w, i) => {
                    let w_min = w[0];
                    let w_max = w[1];
                    let rangeKey = w_min + " - " + w_max + convertValue(null, "weight", this.props.unit, null, null, true);
                    const filteredTowers = allTowers.filter((elm) => {
                        if (i === 0) {
                            return convertValue(elm.towerDetails.capacity.usedLoadCapacity, "weight", this.props.unit, null, true, null) >= w_min && convertValue(elm.towerDetails.capacity.usedLoadCapacity, "weight", this.props.unit, null, true, null) <= w_max;
                        } else {
                            return convertValue(elm.towerDetails.capacity.usedLoadCapacity, "weight", this.props.unit, null, true, null) > w_min && convertValue(elm.towerDetails.capacity.usedLoadCapacity, "weight", this.props.unit, null, true, null) <= w_max;
                        }
                    });

                    if (filteredTowers.length) {
                        weightBeans.push(rangeKey);
                        this.weightRangesAndIds.push({
                            ranges: rangeKey,
                            sites: filteredTowers.map((t) => {
                                return t.customerSiteid;
                            }),
                        });
                        newWeightDataset.push(filteredTowers.length);
                    }
                });

                const newweightBarChart = {
                    labels: weightBeans,
                    datasets: [{ label: "Towers", fill: true, borderColor: colorPalettes.color1, backgroundColor: colorPalettes.color1, data: newWeightDataset }],
                };

                //Wind Load
                let weightwithWindBeans = [],
                    weightWithWindData = [];
                this.weightWindRangesAndIds = [];
                weightWithWindRanges.forEach((w, i) => {
                    let w_min = w[0];
                    let w_max = w[1];
                    let rangeKey = w_min + " - " + w_max + convertValue(null, "weight", this.props.unit, null, null, true);
                    const filteredTowers = allTowers.filter((elm) => {
                        if (!elm.towerDetails.capacity.usedLoadCapacityWithWindLoad) elm.towerDetails.capacity.usedLoadCapacityWithWindLoad = 0;

                        if (i === 0) {
                            return convertValue(elm.towerDetails.capacity.usedLoadCapacityWithWindLoad, "weight", this.props.unit, null, true, null) >= w_min && convertValue(elm.towerDetails.capacity.usedLoadCapacityWithWindLoad, "weight", this.props.unit, null, true, null) <= w_max;
                        } else {
                            return convertValue(elm.towerDetails.capacity.usedLoadCapacityWithWindLoad, "weight", this.props.unit, null, true, null) > w_min && convertValue(elm.towerDetails.capacity.usedLoadCapacityWithWindLoad, "weight", this.props.unit, null, true, null) <= w_max;
                        }
                    });

                    if (filteredTowers.length) {
                        weightwithWindBeans.push(rangeKey);
                        this.weightWindRangesAndIds.push({
                            ranges: rangeKey,
                            sites: filteredTowers.map((t) => {
                                return t.customerSiteid;
                            }),
                        });
                        weightWithWindData.push(filteredTowers.length);
                    }
                });

                const newWeightWithWindBarChart = {
                    labels: weightwithWindBeans,
                    datasets: [{ label: "Towers", fill: true, borderColor: colorPalettes.color2, backgroundColor: colorPalettes.color2, data: weightWithWindData }],
                };

                // Prepare tenent wise data
                let tenantNm = [],
                    tenantDataset = [],
                    tenantObj = {};

                tOccupancyCnt.sort((a, b) => {
                    return a._id.localeCompare(b._id, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                });

                tOccupancyCnt.forEach((t, i) => {
                    Object.keys(t).forEach((k) => {
                        if (k !== "_id") {
                            if (tenantObj[k] && tenantObj[k].length) tenantObj[k].push(t[k]);
                            else tenantObj[k] = [t[k]];
                        }
                    });
                    tenantNm.push(t._id);
                    this.TenantNmQuery[t._id] = { "tenants.antennas": { $elemMatch: { tennantName: t._id } } };
                });
                Object.keys(tenantObj).forEach((t, i) => {
                    const isAllZero = tenantObj[t].every((item) => item === 0);
                    if (!isAllZero) {
                        tenantDataset.push({ label: "Towers", fill: true, borderColor: colorPalettes.color3, backgroundColor: colorPalettes.color3, data: tenantObj[t] });
                    }
                });

                let tenantsNMBarChart;
                if (tenantNm.length) tenantsNMBarChart = { labels: tenantNm, datasets: tenantDataset };

                //Diesel generator capacity pie data
                let dbCapacity = [],
                    dgCapacityCnt = [],
                    dgPieData;
                if (dgCapacityCount.length) {
                    dgCapacityCount.forEach((t) => {
                        dbCapacity.push(t._id);
                        dgCapacityCnt.push(t.count);
                    });
                    dgPieData = {
                        labels: dbCapacity,
                        datasets: [
                            {
                                data: dgCapacityCnt,
                                backgroundColor: pieChartColors,
                                borderColor,
                            },
                        ],
                    };
                }

                //State Wise Inventory Count Data
                let stateNmAndSiteIds = [],
                    stateBarDataset = [],
                    inventoryObj = {};
                if (stateWiseInventoyCnt && stateWiseInventoyCnt.length) {
                    stateWiseInventoyCnt.forEach((s, i) => {
                        let valuePresent = false;
                        let sites = [];
                        Object.keys(s).forEach((k) => {
                            if (s[k].length) valuePresent = true;
                            if (k !== "_id") {
                                if (inventoryObj[k] && inventoryObj[k].length) {
                                    inventoryObj[k].push(s[k].length);
                                    sites = sites.concat(s[k]);
                                } else {
                                    inventoryObj[k] = [s[k].length];
                                    sites = sites.concat(s[k]);
                                }
                            }
                        });
                        if (valuePresent) {
                            stateNmAndSiteIds.push({ name: s._id, sites: sites });
                            s.sites = sites;
                        }
                    });
                }
                let inventoryId = 0;
                Object.keys(inventoryObj).forEach((t, i) => {
                    const isAllZero = inventoryObj[t].every((item) => item === 0);

                    if (!isAllZero) {
                        let res = t.replace(/[A-Z]/g, " $&").trim();
                        let str = res.charAt(0).toUpperCase() + res.slice(1);
                        stateBarDataset.push({ label: str, fill: true, borderColor: barChartColors[inventoryId], backgroundColor: barChartColors[inventoryId], data: inventoryObj[t] });
                        inventoryId += 1;
                    }
                });

                let stateWiseInventoryBarChart;
                let statenm = stateNmAndSiteIds.map((s) => {
                    return s.name;
                });
                if (stateNmAndSiteIds.length) stateWiseInventoryBarChart = { labels: statenm, datasets: stateBarDataset };

                //Prepare accessibilty data
                let criteria = [],
                    criteriaCnt = [],
                    accessibilityChart;
                Object.keys(accessibiltyCount).forEach((k) => {
                    if (k !== "_id") {
                        let res = k.replace(/[A-Z]/g, " $&").trim();
                        let str = res.charAt(0).toUpperCase() + res.slice(1);
                        if (k === "fromRoad") str = " > 1" + (this.props.unit === "FPS" ? " mile " : " km") + str;
                        criteria.push(str);
                        criteriaCnt.push(accessibiltyCount[k].length);
                    }
                });

                accessibilityChart = {
                    labels: criteria,
                    datasets: [
                        {
                            label: "Towers",
                            fill: false,
                            borderColor: colorPalettes.color2,
                            backgroundColor: colorPalettes.color2,
                            data: criteriaCnt,
                        },
                    ],
                };

                // New tower type bar chart
                let siteTypes = [],
                    nmObj = {},
                    ttnmArr = [];
                towerTypeCnt.forEach((a) => {
                    siteTypes.push(a._id);
                    if (a.towerSubTypes) {
                        a.towerSubTypes.forEach((s) => {
                            if (nmObj[s.towerSubType] && nmObj[s.towerSubType].length) nmObj[s.towerSubType].push(s.count);
                            else nmObj[s.towerSubType] = [s.count];
                        });
                    }
                });

                Object.keys(nmObj).forEach((t, i) => {
                    ttnmArr.push({ label: t, fill: true, borderColor: barChartColors[i], backgroundColor: barChartColors[i], data: nmObj[t] });
                });

                var newTowerTypeWiseChart = {
                    labels: siteTypes,
                    datasets: ttnmArr,
                };
                //Zoning data
                let zoningNm = [],
                    zoningCnt = [];
                let zoningDoughnutData;

                if (zoningCount && zoningCount.length) {
                    zoningCount.forEach((z) => {
                        zoningNm.push(z._id);
                        zoningCnt.push(z.count);
                    });
                    zoningDoughnutData = {
                        labels: zoningNm,
                        datasets: [
                            {
                                data: zoningCnt,
                                backgroundColor: pieChartColors,
                                hoverBackgroundColor: pieChartColors,
                            },
                        ],
                    };
                }
                //Wind load percentage chart
                let windLoadPerBeans = [],
                    windLoadPerDataset = [];
                this.windLoadPerrangesAndIds = [];
                windLoadPercentageRanges.forEach((w, i) => {
                    let w_min = w[0];
                    let w_max = w[1];
                    let rangeKey = w_min + " - " + w_max + " %";
                    const filteredTowers = allTowers.filter((elm) => {
                        let windLoadPer = 0;
                        if (elm.towerDetails.capacity.usedLoadCapacity && elm.towerDetails.capacity.usedLoadCapacityWithWindLoad) windLoadPer = Math.abs(getPercentageChange(elm.towerDetails.capacity.usedLoadCapacity, elm.towerDetails.capacity.usedLoadCapacityWithWindLoad));
                        if (i === 0) return windLoadPer >= w_min && windLoadPer <= w_max;
                        else return windLoadPer > w_min && windLoadPer <= w_max;
                    });

                    if (filteredTowers.length) {
                        windLoadPerBeans.push(rangeKey);
                        this.windLoadPerrangesAndIds.push({
                            ranges: rangeKey,
                            sites: filteredTowers.map((t) => {
                                return t.customerSiteid;
                            }),
                        });
                        windLoadPerDataset.push(filteredTowers.length);
                    }
                });

                const windloadPerBarChart = {
                    labels: windLoadPerBeans,
                    datasets: [{ label: "Towers", fill: true, borderColor: colorPalettes.color4, backgroundColor: colorPalettes.color4, data: windLoadPerDataset }],
                };

                //Prepare freeslots data

                let slots = [],
                    slotsCnt = [],
                    freeSlotsChart;
                this.freeSlotsIds = [];
                Object.keys(freeSlotsData).forEach((k) => {
                    if (k !== "_id") {
                        let res = k.replace(/[A-Z]/g, " $&").trim();
                        let str = res.charAt(0).toUpperCase() + res.slice(1);
                        slots.push(str);
                        slotsCnt.push(freeSlotsData[k].length);
                        this.freeSlotsIds.push({ sites: freeSlotsData[k] });
                    }
                });

                if (slotsCnt.length) {
                    freeSlotsChart = {
                        labels: slots,
                        datasets: [
                            {
                                label: "Towers",
                                fill: false,
                                borderColor: colorPalettes.color1,
                                backgroundColor: colorPalettes.color1,
                                data: slotsCnt,
                            },
                        ],
                    };
                }

                // Prepare site type data

                let siteTypeLabel = [],
                    stCnt = [],
                    siteTypeChart;
                this.siteTypeIds = [];
                Object.keys(siteTypeData).forEach((k) => {
                    if (k !== "_id") {
                        let res = k.replace(/[A-Z]/g, " $&").trim();
                        let str = res.charAt(0).toUpperCase() + res.slice(1);
                        siteTypeLabel.push(str);
                        stCnt.push(siteTypeData[k].length);
                        this.siteTypeIds.push({ sites: siteTypeData[k] });
                    }
                });

                siteTypeChart = {
                    labels: siteTypeLabel,
                    datasets: [
                        {
                            label: "Sites",
                            fill: false,
                            borderColor: colorPalettes.color5,
                            backgroundColor: colorPalettes.color5,
                            data: stCnt,
                        },
                    ],
                };

                //Prepare atc antenna loading chart

                let labels = [],
                    towersCnt = [],
                    atcAntannaLoadingChart;
                this.atcAntennaDifferenceIds = [];
                if (antennaDiffCount) {
                    Object.keys(antennaDiffCount).forEach((k) => {
                        if (k !== "_id") {
                            // let res = k.replace(/[A-Z]/g, ' $&').trim();
                            // let str = res.charAt(0).toUpperCase() + res.slice(1);
                            labels.push(k);
                            towersCnt.push(antennaDiffCount[k].length);
                            this.atcAntennaDifferenceIds.push({ sites: antennaDiffCount[k] });
                        }
                    });
                }

                if (towersCnt && towersCnt.length) {
                    atcAntannaLoadingChart = {
                        labels: labels,
                        datasets: [
                            {
                                label: "Sites",
                                fill: false,
                                borderColor: colorPalettes.color3,
                                backgroundColor: colorPalettes.color3,
                                data: towersCnt,
                            },
                        ],
                    };
                }

                // Prepare sitewise ATC loading
                let siteids = [],
                    atcOverallLoadingChart,
                    percentageArr = [];
                this.atcLoadPerArr.forEach((s) => {
                    siteids.push(s.sites[0]);
                    percentageArr.push(s.perc);
                });

                if (siteids && siteids.length) {
                    atcOverallLoadingChart = {
                        labels: siteids,
                        datasets: [
                            {
                                label: "Percentage",
                                fill: false,
                                borderColor: colorPalettes.color4,
                                backgroundColor: colorPalettes.color4,
                                data: percentageArr,
                            },
                        ],
                    };
                }

                this.setState({
                    towerHeights: t_hight_count,
                    allTowers: allTowers,
                    doughnutData: doughnutData,
                    // towerTypeBarData: towerTypeBarData,
                    tenancyPieData: tenancyPieData,
                    weatherBarData: weatherBarData,
                    tenantsNMBarChart: tenantsNMBarChart,
                    locationArr: locationArr,
                    // towerTypeNm: towerTypeNm,
                    dgPieData: dgPieData,
                    dbCapacity: dbCapacity,
                    stateNmAndSiteIds: stateNmAndSiteIds,
                    stateWiseInventoryBarChart: stateWiseInventoryBarChart,
                    accessibilityChart: accessibilityChart,
                    siteTypes: siteTypes,
                    newTowerTypeWiseChart: newTowerTypeWiseChart,
                    newweightBarChart: newweightBarChart,
                    newWeightWithWindBarChart: newWeightWithWindBarChart,
                    zoningDoughnutData: zoningDoughnutData,
                    windloadPerBarChart: windloadPerBarChart,
                    freeSlotsChart: freeSlotsChart,
                    siteTypeChart: siteTypeChart,
                    atcAntannaLoadingChart: atcAntannaLoadingChart,
                    atcOverallLoadingChart: atcOverallLoadingChart,
                });
            })
            .catch((e) => {
                console.error(e);
            });
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.unit !== this.props.unit) {
            this.setState(
                {
                    // Updated all the remaining state which were not updating in the getAllData function
                    expandedRows: null,
                    tenancyPieIndex: null,
                    tenancyStateWise: [],
                    tIndex: -1,
                    dgCapacityStateWise: [],
                    dgCapaityValue: null,
                    displayInfo: false,
                },
                () => {
                    this.getAllData();
                }
            );
        }
    };

    getTowerTypeBarChartOptions = () => {
        let opt = JSON.parse(JSON.stringify(barChartOptions));
        opt.onClick = this.onTowerTypeBarClick.bind(this);
        return opt;
    };

    onTowerTypeBarClick = (evt, element) => {
        if (element.length > 0) {
            let ttNm = this.state.siteTypes.find((e, i) => i === element[0].index);
            if (ttNm) {
                let query = { "towerDetails.towerConfig.siteType": ttNm };

                this.towerService.getTowerQuery(query).then((data) => {
                    this.setState({
                        detailedViewClick: true,
                        details: data,
                    });
                });
            }
        }
    };

    getTenantBarChartOptions = () => {
        let opt = JSON.parse(JSON.stringify(barChartOptions));
        opt.onClick = this.onTenantNmBarClick.bind(this);
        return opt;
    };

    onTenantNmBarClick = (evt, element) => {
        if (element.length > 0) {
            let foundTen = Object.keys(this.TenantNmQuery).find((e, i) => i === element[0].index);
            if (foundTen) {
                this.towerService.getTowerQuery(this.TenantNmQuery[foundTen]).then((data) => {
                    this.setState({
                        detailedViewClick: true,
                        details: data,
                    });
                });
            }
        }
    };
    getInventoryBarChartOptions = () => {
        let opt = JSON.parse(JSON.stringify(barChartOptions));
        // delete opt.barThickness;
        opt.onClick = this.onInventoryBarClick.bind(this);
        return opt;
    };

    onInventoryBarClick = (evt, element) => {
        if (element.length > 0) {
            let foundObj = this.state.stateNmAndSiteIds.find((e, i) => i === element[0].index);
            if (foundObj) {
                let query = { customerSiteid: { $in: foundObj.sites } };
                this.towerService.getTowerQuery(query).then((data) => {
                    this.setState({
                        detailedViewClick: true,
                        details: data,
                    });
                });
            }
        }
    };

    getWeightChartOptions = (onClickFunc) => {
        let opt = JSON.parse(JSON.stringify(barChartOptions));
        // delete opt.barThickness;
        opt.onClick = onClickFunc;
        return opt;
    };

    getLoadPercentageOptions = (onClickFunc) => {
        let opt = JSON.parse(JSON.stringify(barChartOptions));
        opt.scales.x.ticks.font.size = 12;
        opt.barThickness = 20;
        opt.onClick = onClickFunc;
        return opt;
    };

    getAccessibilityBarChartOptions = () => {
        let accessopt = JSON.parse(JSON.stringify(barChartOptions));
        // delete accessopt.barThickness;
        return accessopt;
    };

    getRanges = (arr) => {
        arr.sort((a, b) => a - b);
        let min = arr[0];
        let max = arr[arr.length - 1];
        if (min % 10) min = Math.floor(min / 10) * 10;
        if (max % 10) max = max + (10 - (max % 10));
        return this.bucket([min, max], 4);
    };

    bucket = ([min, max], bucketsNumber) => {
        return Array.from({ length: bucketsNumber }).map((el, idx, arr, step = Math.floor((max - min) / bucketsNumber)) => {
            return idx === bucketsNumber - 1 ? [min + idx * step, max] : [min + idx * step, min + (idx + 1) * step];
        });
    };

    onWeightBarClick = (evt, element) => {
        this.handleBarClick(element, this.weightRangesAndIds);
    };

    onWindLoadWeightBarClick = (evt, element) => {
        this.handleBarClick(element, this.weightWindRangesAndIds);
    };

    onWindLoadPerBarClick = (evt, element) => {
        this.handleBarClick(element, this.windLoadPerrangesAndIds);
    };

    onFreeSlotsBarClick = (evt, element) => {
        this.handleBarClick(element, this.freeSlotsIds);
    };

    onSiteTypeBarClick = (evt, element) => {
        this.handleBarClick(element, this.siteTypeIds);
    };

    onAtcAntennaDifferenceClick = (evt, element) => {
        this.handleBarClick(element, this.atcAntennaDifferenceIds);
    };

    onATCOverallLoadPercentageClick = (evt, element) => {
        this.handleBarClick(element, this.atcLoadPerArr);
    };

    handleBarClick = (element, data) => {
        if (element.length > 0) {
            let foundObj = data.find((e, i) => i === element[0].index);
            if (foundObj) {
                let query = { customerSiteid: { $in: foundObj.sites } };
                this.towerService.getTowerQuery(query).then((data) => {
                    this.setState({
                        detailedViewClick: true,
                        details: data,
                    });
                });
            }
        }
    };

    onHeightStateClick = (data) => {
        let filteredTowers = this.state.allTowers.filter((t) => data.towerIdsArr.includes(t.customerSiteid));
        this.setState({
            detailedViewClick: true,
            details: filteredTowers,
        });
    };

    rowExpansionTemplate(data) {
        let column_keys = [];
        if (data.circleDetails && data.circleDetails.length) column_keys = Object.keys(data.circleDetails[0]).filter((k) => k !== "towerIdsArr");
        return column_keys.length ? (
            <div className="orders-subtable">
                <h6 className="text-center">Statewise Breakup for {data.height}</h6>
                <DataTable
                    style={{ fontSize: "18px" }}
                    value={data.circleDetails}
                    showGridlines
                    onRowClick={(e) => {
                        this.onHeightStateClick(e.data);
                    }}
                    responsiveLayout="scroll"
                    scrollHeight="15rem"
                >
                    {column_keys.map((k) => {
                        return <Column key={k} className="color2-bg" header={k} field={k}></Column>;
                    })}
                </DataTable>
            </div>
        ) : null;
    }

    getHeightSubTableHeader = (statewiseData) => {
        return (
            <ColumnGroup>
                <Row>
                    {Object.keys(statewiseData).map((k) => {
                        return <Column key={k + "header"} style={{ padding: "0.5rem, 0.5rem" }} className="green-bg" field={k} header={k === "_id" ? "State" : convertCamelCase(k)}></Column>;
                    })}
                </Row>
            </ColumnGroup>
        );
    };

    getHeaderGroup(statewiseData) {
        return (
            <ColumnGroup>
                <Row>
                    {Object.keys(statewiseData).map((k) => {
                        return <Column key={k + "header"} style={{ padding: "0.5rem, 0.5rem" }} className="color1-bg" field={k} header={k === "_id" ? "State" : convertCamelCase(k)}></Column>;
                    })}
                </Row>
            </ColumnGroup>
        );
    }

    getTenancyTableHeader = (columnHeader) => {
        return (
            <ColumnGroup>
                <Row>
                    <Column className="color1_1-bg" header={columnHeader} colSpan={2}></Column>
                </Row>
                <Row>
                    <Column className="color1-bg" field="_id" header="State"></Column>
                    <Column className="color1-bg" field="count" header="Total" sortable></Column>
                </Row>
            </ColumnGroup>
        );
    };
    getMap = (locations, zoom) => {
        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(document.getElementById("map"), defaultLayers.vector.normal.map, {
            center: { lat: locations[0].pos.lat, lng: locations[0].pos.lng },
            zoom: zoom,
            pixelRatio: window.devicePixelRatio || 1,
        });
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        H.ui.UI.createDefault(map, defaultLayers);
        this.setState(
            {
                H: H,
                map: map,
            },
            () => {
                this.addSVGMarkers(H, map, locations);
            }
        );
    };

    addSVGMarkers = (H, map, pos) => {
        var svgMarkup =
            '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path style="fill:#01010100; stroke:none;" d="M0 0L0 32L32 32L32 0L0 0z"/><path style="fill:#ef3d49; stroke:none;" d="M15 28L17 28C19.368 24.4869 21.95 20.9627 23.5208 17C31.413 -2.9091 1.1202 -3.05081 8.2716 16C9.88256 20.2915 12.4852 24.1989 15 28z"/><path style="fill:#ef2121; stroke:none;" d="M15 5L16 6L15 5z"/><path style="fill:#ffffff; stroke:none;" d="M13.7461 7.03241C10.2098 9.89313 14.9493 15.6823 18.3927 12.3781C21.696 9.20841 17.2399 4.20618 13.7461 7.03241z"/><path style="fill:#ff166b; stroke:none;" d="M12 7L13 8L12 7z"/><path style="fill:#36d1ba; stroke:none;" d="M5 27L5 29C11.8299 31.882 20.17 31.882 27 29L27 27L20 25L20 27L24 27L24 28L8 28L8 27L12 27L12 25L5 27z"/></svg>';
        var group = new H.map.Group();
        pos.forEach((item) => {
            var bearsIcon = new H.map.Icon(svgMarkup),
                bearsMarker = new H.map.Marker({ lat: item.pos.lat, lng: item.pos.lng }, { icon: bearsIcon });
            group.addObject(bearsMarker);
            //map.addObject(bearsMarker);
        });
        map.addObject(group);
        // map.getViewModel().setLookAtData({
        //     maxZoom: 4,
        //     bounds: group.getBoundingBox()
        //   });
    };

    // startClustering = (H, map, locations) => {

    // 	let dataPoints = locations.map((item) => {
    // 		return new H.clustering.DataPoint(item.pos.lat, item.pos.lng);
    // 	});

    // 	// SVG template to use for noise icons
    // 	let noiseSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#ef3d49; stroke:none;" d="M31 56L33 56C40.5258 44.8443 53.6928 28.5437 49.1968 14C44.4021 -1.50966 20.7607 -2.25617 15.1335 13.0008C9.81722 27.4147 23.4684 44.8265 31 56z"/><path style="fill:#fb2020; stroke:none;" d="M43 5L44 6L43 5M45 7L46 8L45 7z"/><path style="fill:#FFFFFF; stroke:none;" d="M31.0039 10.7184C19.6233 12.0631 21.6376 29.9849 32.9992 28.5008C44.379 27.0143 42.3713 9.37518 31.0039 10.7184z"/><path style="fill:#fb2020; stroke:none;" d="M24 14L25 15L24 14M39 14L40 15L39 14M27 27L28 28L27 27M17 32L18 33L17 32M24 45L25 46L24 45M38 47L39 48L38 47z"/><path style="fill:#35d1ba; stroke:none;" d="M40 50L50 56C37.8658 59.8545 26.1342 59.8545 14 56L23 50C6.51653 52.2305 13.5425 60.2246 26 61.7145C32.7432 62.521 42.7746 62.6744 48.9406 59.4275C58.7703 54.2514 44.1392 50.5345 40 50z"/><path style="fill:#fb2020; stroke:none;" d="M36 50L37 51L36 50z"/><path style="fill:#27dada; stroke:none;" d="M43 50L44 51L43 50M39 51L40 52L39 51M13 52L14 53L13 52M44 53L45 54L44 53z"/></svg>';
    // 	// Create an icon to represent the noise points
    // 	// Note that same icon will be used for all noise points
    // 	let noiseIcon = new H.map.Icon(noiseSvg, {
    // 		size: { w: 40, h: 40 },
    // 		anchor: { x: 10, y: 10 },
    // 	});

    // 	let clusterSvgIcon = '<svg xmlns="http://www.w3.org/2000/svg">';
    // 	clusterSvgIcon += '<g>';
    // 	clusterSvgIcon += '<circle cx="{radius}px" cy="{radius}px" r="{radius}px" fill="#3f51b5" />';
    // 	clusterSvgIcon += '<text x="25%" y="50%" font-family="Verdana" font-size="{fontSize}" fill="white">{count}</text>';
    // 	clusterSvgIcon += '</g>';
    // 	clusterSvgIcon += '</svg>';

    // 	let clusteredDataProvider = new H.clustering.Provider(dataPoints, {
    // 		theme: {
    // 			getClusterPresentation: function (cluster) {
    // 				// Use cluster weight to change the icon size
    // 				var weight = cluster.getWeight();
    // 				// Calculate circle size
    // 				let radius = weight * 5;
    // 				let diameter = radius * 2;
    // 				// Replace variables in the icon template
    // 				// let svgString = clusterSvgTemplate.replace(/\{radius\}/g, radius).replace(/\{diameter\}/g, diameter);
    // 				let svgString = clusterSvgIcon.replace(/\{count\}/g, weight).replace(/\{radius\}/g, radius).replace(/\{diameter\}/g, diameter).replace(/\{fontSize\}/g, radius);

    // 				let clusterIcon = new H.map.Icon(svgString, {
    // 					size: { w: diameter, h: diameter },
    // 					anchor: { x: radius, y: radius },
    // 				});
    // 				// Create a marker for the cluster
    // 				let clusterMarker = new H.map.Marker(cluster.getPosition(), {
    // 					icon: clusterIcon,
    // 					min: cluster.getMinZoom(),
    // 					max: cluster.getMaxZoom(),
    // 				});
    // 				// Bind cluster data to the marker
    // 				clusterMarker.setData(cluster);
    // 				return clusterMarker;
    // 			},
    // 			getNoisePresentation: function (noisePoint) {
    // 				let noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
    // 					icon: noiseIcon,
    // 					min: noisePoint.getMinZoom(),
    // 				});
    // 				noiseMarker.setData(noisePoint);
    // 				return noiseMarker;
    // 			},
    // 		},
    // 		clusteringOptions: {
    // 			eps: 0,
    // 			minWeight: 1,
    // 		},
    // 	});
    // 	let clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);
    // 	this.state.map.addLayer(clusteringLayer);
    // }

    onPieClick = (evt, element) => {
        if (element.length > 0 && this.state.tIndex !== element[0].index) {
            let foundTen = Object.keys(Tenancy).find((e, i) => i === element[0].index);
            if (foundTen) {
                this.towerService
                    .getStateWiseTenancyCount(Tenancy[foundTen])
                    .then((resp) => {
                        if (resp && resp.length) {
                            this.setState({
                                tenancyStateWise: resp,
                                tIndex: element[0].index,
                                tName: foundTen,
                            });
                        }
                    })
                    .catch();
            }
        }
    };

    onDGPieClick = (evt, element) => {
        if (element.length > 0) {
            let capacity = this.state.dbCapacity.find((e, i) => i === element[0].index);
            if (capacity) {
                let query = { "siteEquipment.dieselGenerator.capacity": capacity };
                this.towerService
                    .getStateWiseDGCapacityCount(query)
                    .then((resp) => {
                        if (resp && resp.length) {
                            this.setState({
                                dgCapacityStateWise: resp,
                                dgCapaityValue: capacity,
                            });
                        }
                    })
                    .catch();
            }
        }
    };

    tenancyStateHandle = (e) => {
        let query = { "siteDetails.location.state": e.data._id, "towerDetails.tenancyCount": Tenancy[this.state.tName] };
        this.towerService.getTowerQuery(query).then((data) => {
            this.setState({
                detailedViewClick: true,
                details: data,
            });
        });
    };

    dgStateHandle = (e) => {
        let query = { "siteDetails.location.state": e.data._id, "siteEquipment.dieselGenerator.capacity": this.state.dgCapaityValue };
        this.towerService.getTowerQuery(query).then((data) => {
            this.setState({
                detailedViewClick: true,
                details: data,
            });
        });
    };

    handleStatewiseClick = (e) => {
        this.towerService.getStateAllTowers(e.data._id).then((data) => {
            this.setState({
                detailedViewClick: true,
                details: data,
            });
        });
    };

    handleBack = () => {
        this.setState(
            {
                detailedViewClick: false,
                details: [],
            },
            () => {
                // this.getMap(this.state.locationArr, 10)
            }
        );
    };

    onInfoClick = (id) => {
        this.setState({
            displayInfo: true,
            infoText: "Calculated using formula from engineeringtoolbox.com Assumption : Air Density - " + (this.props.unit === "FPS" ? "0.081 pounds/cubic foot" : "1.29 Kg/cubic meter") + " , wind speed: max wind speed at the location in the last 1 year",
        });
    };

    getStateWiseTable = (value, columnHeader, onRowClick) => {
        return (
            <DataTable value={value} showGridlines headerColumnGroup={this.getTenancyTableHeader(columnHeader)} responsiveLayout="scroll" onRowClick={(e) => onRowClick(e)}>
                <Column className="color2-bg" field="_id"></Column>
                <Column className="color2-bg" field="count"></Column>
            </DataTable>
        );
    };

    render = () => {
        return this.state.detailedViewClick ? (
            <TowerTransitionComponent towers={this.state.details} hadelBack={this.handleBack} />
        ) : (
            <div className="grid">
                <Dialog header="Info" visible={this.state.displayInfo} className="responsive-dialog" modal onHide={() => this.setState({ displayInfo: false, infoText: null })}>
                    <p>{this.state.infoText}</p>
                </Dialog>

                <div className="col-12 lg:col-8 md:col-8">
                    <div className="grid">
                        <div className="col-12 lg:col-6 xs:col-12">
                            <div className="portfolio-widgets">
                                <div className="grid">
                                    <div className="col-12 lg:col-11">
                                        {this.totalSites ? (
                                            <h5 className=" text-2xl" style={{ marginBottom: 0 }}>
                                                {"Site Digitization Status"}{" "}
                                            </h5>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="flex justify-content-center">
                                    <Chart style={{ position: "relative", height: "30vh", width: "26vh" }} type="doughnut" data={this.state.doughnutData} options={pieOptions} />
                                </div>
                                {/* <div className="lg:col-12 flex flex-wrap justify-content-between ">
										<div className="lg:col-6 xs:col-8">
											<Chart
												style={{ position: 'relative', height: "22vh", width: "22vh" }}
												type='doughnut'
												data={this.state.doughnutData}
												options={pieOptions}
											/>
										</div>
										<div className="lg:col-6  xs:col-4">
											{/* <DataTable value={countData} showGridlines style={{fontSize: "1rem"}}
											>
												<Column headerClassName='color1-bg' bodyClassName="color2-bg" header="Status" field="title"></Column>
												<Column headerClassName='color1-bg' bodyClassName="color2-bg" header="Total" field="count"></Column>
											</DataTable> */}
                                {/* <ul>
												<li>Capture Completed - 21</li>

												<li>Visual Model Available - 21</li>

												<li>BIM Available - 21</li>

												<li>Integrated for Operations : 21</li>

											</ul> */}

                                {/* <div className="lg:col-12 md:col-12 " style={{ paddingLeft: "2vh", paddingRight: "2vh", minHeight: "5vh"}}>
										{this.degitizeSites ? <span className="overview-status p-1 color1-bg" style={{ fontSize: "1rem", float: "left" }}>Capture Completed - 21</span> : null}
									</div> */}
                                {/* </div> */}

                                {/* </div> */}
                                <DataTable value={this.state.getDigitizationStatusData} showGridlines style={{ fontSize: "0.9rem", fontWeight: "500" }}>
                                    <Column headerClassName="color1-bg p-1" bodyClassName="color2-bg p-1" header="Capture Completed" field="Capture Completed"></Column>
                                    <Column headerClassName="color1-bg p-1" bodyClassName="color2-bg p-1" header="Visual Model Available" field="Visual Model Available"></Column>
                                    <Column headerClassName="color1-bg p-1" bodyClassName="color2-bg p-1" header="BIM Available" field="BIM Available"></Column>
                                </DataTable>
                                {/* <div className="lg:col-12 md:col-12 xs:col-12" style={{  float: "auto", fontWeight: "bold" }}>
										<span className="overview-status p-1 font900" style={{ fontSize: "1rem", float: "left", color: "#676464" }}>{"Capture Completed - " + countData['Capture Completed'][this.serviceId]}</span>
										<span className="overview-status p-1" style={{ fontSize: "1rem", float: "right", color: "#676464" }}>{"Visual Model Available - " + countData['Visual Model Available'][this.serviceId]}</span>
									</div>

									<div className="lg:col-12 md:col-12 " style={{ float: "auto", fontWeight: "bold" }}>
										<span className="overview-status p-1" style={{ fontSize: "1rem", float: "right", color: "#676464" }}>{"BIM Available  -" + countData['BIM Available'][this.serviceId]}</span>
										<span className="overview-status p-1" style={{ fontSize: "1rem", float: "left", color: "#676464" }}>{"Integrated for Operations - " + countData['Integrated for Operations'][this.serviceId]}</span>
									</div> */}
                            </div>
                        </div>
                        <div className="col-12 lg:col-6">
                            <div className="portfolio-widgets">
                                <h5 className="mb-3 text-2xl">Tower Type</h5>
                                <Chart type="bar" data={this.state.newTowerTypeWiseChart} style={{ height: "35vh" }} options={this.getTowerTypeBarChartOptions()}></Chart>
                            </div>
                        </div>
                        {/* <div className='col-12 lg:col-6'>
								<div className='portfolio-widgets'>
									<h5 className='mb-3 text-2xl'>Tower Type</h5>
									<Chart type="bar" data={this.state.towerTypeBarData} style={{ height: "35vh" }} options={this.getTowerTypeBarChartOptions()}></Chart>
								</div>
							</div> */}
                        {this.state.stateWiseData && this.state.stateWiseData.length ? (
                            <div className="col-12 lg:col-12" style={{ paddingTop: "0.5rem" }}>
                                <div className="card">
                                    <h5 className="mb-3 text-2xl">Statewise Summary</h5>
                                    <DataTable style={{ fontSize: "18px" }} value={this.state.stateWiseData} headerColumnGroup={this.getHeaderGroup(this.state.stateWiseData[0])} showGridlines onRowClick={(e) => this.handleStatewiseClick(e)} responsiveLayout="scroll" scrollHeight="15rem">
                                        {Object.keys(this.state.stateWiseData[0]).map((k) => {
                                            return <Column key={k} className="color2-bg" field={k}></Column>;
                                        })}
                                    </DataTable>
                                </div>
                            </div>
                        ) : null}
                        <div className="col-12 lg:col-6">
                            <div className="portfolio-widgets">
                                <div className="grid">
                                    <div className="col-12 lg:col-11">
                                        <h5 className="text-2xl" style={{ marginBottom: 0 }}>
                                            Static Load
                                        </h5>
                                    </div>
                                </div>
                                <Chart type="bar" style={{ height: "35vh" }} data={this.state.newweightBarChart} options={this.getWeightChartOptions(this.onWeightBarClick)}></Chart>
                            </div>
                        </div>
                        <div className="col-12 lg:col-6">
                            <div className="portfolio-widgets">
                                <div className="grid">
                                    <div className="col-12 lg:col-11">
                                        <h5 className="text-2xl" style={{ marginBottom: 0 }}>
                                            Wind Adjusted Load
                                        </h5>
                                        {/* <h6 className=' text-xl' style={{ marginBottom: 0, marginTop: 0 }}>{"(Wind Adjusted)"}</h6> */}
                                    </div>
                                    <div className="lg:col-1">
                                        <button type="button" className=" p-link" onClick={() => this.onInfoClick()}>
                                            <i className="pi pi-info-circle fs-large"></i>
                                        </button>
                                    </div>
                                </div>
                                <Chart type="bar" style={{ height: "35vh" }} data={this.state.newWeightWithWindBarChart} options={this.getWeightChartOptions(this.onWindLoadWeightBarClick)}></Chart>
                            </div>
                        </div>
                        <div className="col-12 lg:col-6">
                            <div className="portfolio-widgets">
                                <h5 className="mb-3 text-2xl" style={{ marginBottom: 0 }}>
                                    Wind Impact On Load
                                </h5>
                                <Chart type="bar" style={{ height: "35vh" }} data={this.state.windloadPerBarChart} options={this.getWeightChartOptions(this.onWindLoadPerBarClick)}></Chart>
                            </div>
                        </div>

                        <div className="col-12 lg:col-6">
                            <div className="portfolio-widgets">
                                <h5 className="mb-3 text-2xl" style={{ marginBottom: 0 }}>
                                    Site Type
                                </h5>
                                <Chart type="bar" style={{ height: "35vh" }} data={this.state.siteTypeChart} options={this.getWeightChartOptions(this.onSiteTypeBarClick)}></Chart>
                            </div>
                        </div>

                        <div className="col-12 lg:col-6">
                            <div className="portfolio-widgets">
                                <h5 className="mb-3 text-2xl" style={{ marginBottom: 0 }}>
                                    Accessibility
                                </h5>
                                <Chart type="bar" style={{ height: "35vh" }} data={this.state.accessibilityChart} options={this.getAccessibilityBarChartOptions()}></Chart>
                            </div>
                        </div>

                        <div className="col-12 lg:col-6">
                            <div className="portfolio-widgets">
                                <h5 className="mb-3 text-2xl">Tower Height</h5>
                                <DataTable
                                    style={{ fontSize: "18px" }}
                                    value={this.state.towerHeights}
                                    expandedRows={this.state.expandedRows}
                                    onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                                    responsiveLayout="scroll"
                                    rowExpansionTemplate={(e) => this.rowExpansionTemplate(e)}
                                    dataKey="id"
                                >
                                    <Column className="color1-bg" expander={true} style={{ width: "3em", color: "white" }} />
                                    <Column className="color1-bg" field="height" style={{ fontWeight: "bold" }} header="Height" />
                                    <Column className="color1-bg" field="total" style={{ fontWeight: "bold" }} header="Total" />
                                </DataTable>
                            </div>
                        </div>
                        {this.state.zoningDoughnutData ? (
                            <div className="col-12 lg:col-6">
                                <div className="portfolio-widgets">
                                    <h5 className="mb-3 text-2xl" style={{ marginBottom: 0 }}>
                                        Zoning
                                    </h5>
                                    <div className="lg:col-offset-2 lg:col-8">
                                        <Chart type="doughnut" style={{ position: "relative" }} data={this.state.zoningDoughnutData} options={pieOptions}></Chart>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {/* <div className='col-12 lg:col-6'>
								<div className='portfolio-widgets'>
									<h5 className='mb-3 text-2xl'>Extreme Weather Events</h5>
									<Chart type="bar" data={this.state.weatherBarData} options={weatherbarChartOptions}></Chart>
								</div>
							</div> */}
                    </div>
                </div>
                <div className="col-12 lg:col-4">
                    <div className="portfolio-widgets">
                        <h5 className="mb-3 text-2xl">Tenancy</h5>
                        <div className=" lg:col-offset-2 lg:col-8">
                            <Chart id="tenancy" type="pie" data={this.state.tenancyPieData} options={getPieChartOptions(this.onPieClick)}></Chart>
                        </div>

                        {this.state.tenancyStateWise && this.state.tenancyStateWise.length ? <div style={{ marginTop: "5vh" }}>{this.getStateWiseTable(this.state.tenancyStateWise, this.state.tName + " Tenancy", this.tenancyStateHandle)}</div> : null}
                    </div>
                    {this.state.tenantsNMBarChart ? (
                        <div style={{ paddingTop: "1rem" }}>
                            <div className="portfolio-widgets" style={{ paddingTop: "1rem", overflowX: "auto" }}>
                                <div className="grid">
                                    <div className="col-12 lg:col-11">
                                        <h5 className="text-2xl" style={{ marginBottom: 0 }}>
                                            Occupancy By Tenancy
                                        </h5>
                                    </div>
                                </div>
                                <Chart type="bar" style={{ height: "35vh", position: "relative", width: this.TenantNmQuery && Object.keys(this.TenantNmQuery).length > 6 ? "40rem" : "25rem" }} data={this.state.tenantsNMBarChart} options={this.getTenantBarChartOptions()}></Chart>
                            </div>
                        </div>
                    ) : null}
                    {this.state.freeSlotsChart ? (
                        <div style={{ paddingTop: "1rem" }}>
                            <div className="portfolio-widgets" style={{ paddingTop: "1rem", overflowX: "auto" }}>
                                <h5 className="mb-3 text-2xl" style={{ marginBottom: 0 }}>
                                    Free Radio Center
                                </h5>
                                <Chart type="bar" style={{ height: "35vh", position: "relative", width: "30rem" }} data={this.state.freeSlotsChart} options={this.getWeightChartOptions(this.onFreeSlotsBarClick)}></Chart>
                            </div>
                        </div>
                    ) : null}
                    {this.state.atcAntannaLoadingChart ? (
                        <div style={{ paddingTop: "1rem" }}>
                            <div className="portfolio-widgets" style={{ paddingTop: "1rem", overflowX: "auto" }}>
                                <h5 className="mb-3 text-2xl" style={{ marginBottom: 0 }}>
                                    Active Equipment - Billing vs Actual
                                </h5>
                                <Chart type="bar" style={{ height: "35vh", position: "relative", width: "30rem" }} data={this.state.atcAntannaLoadingChart} options={this.getWeightChartOptions(this.onAtcAntennaDifferenceClick)}></Chart>
                            </div>
                        </div>
                    ) : null}

                    {this.state.atcOverallLoadingChart ? (
                        <div style={{ paddingTop: "1rem" }}>
                            <div className="portfolio-widgets" style={{ paddingTop: "1rem", overflowX: "auto", minHeight: "55vh" }}>
                                <h5 className="mb-3 text-2xl" style={{ marginBottom: 0 }}>
                                    Overall Loading
                                </h5>
                                <Chart type="bar" style={{ height: "42vh", position: "relative", width: "60rem" }} data={this.state.atcOverallLoadingChart} options={this.getLoadPercentageOptions(this.onATCOverallLoadPercentageClick)}></Chart>
                            </div>
                        </div>
                    ) : null}
                    {/* <div style={{ paddingTop: "0.5rem" }}>
							<div className="card">
								<div
									id="map"
									style={{
										height: "64vh",
										overflow: "hidden",
									}}
								></div>
							</div>
						</div> */}
                </div>

                <div className="col-12">
                    <TabView>
                        <TabPanel header="Inventory Portfolio">
                            <div className="grid lg:col-8 md:col-8">
                                {this.state.stateWiseInventoryBarChart ? (
                                    <div className="col-12 lg:col-6">
                                        <div className="portfolio-widgets">
                                            <h5 className="mb-3 text-2xl" style={{ marginBottom: 0 }}>
                                                Geographical Distribution
                                            </h5>
                                            <Chart type="bar" style={{ height: "35vh" }} data={this.state.stateWiseInventoryBarChart} options={this.getInventoryBarChartOptions()}></Chart>
                                        </div>
                                    </div>
                                ) : null}
                                {this.state.dgPieData ? (
                                    <div className="col-12 lg:col-6">
                                        <div className="portfolio-widgets">
                                            <h5 className="mb-3 text-2xl">Diesel Generator</h5>
                                            <div className="lg:col-offset-2 lg:col-8">
                                                <Chart type="pie" data={this.state.dgPieData} options={getPieChartOptions(this.onDGPieClick)}></Chart>
                                            </div>
                                            {this.state.dgCapacityStateWise && this.state.dgCapacityStateWise.length ? <div style={{ marginTop: "5vh" }}>{this.getStateWiseTable(this.state.dgCapacityStateWise, this.state.dgCapaityValue, this.dgStateHandle)}</div> : null}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return { unit: state.changeUnit };
};
export default connect(mapStateToProps, { dispatchAction })(PortfolioComponent);
