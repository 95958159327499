import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { CognitoUser, CognitoUserPool, AuthenticationDetails } from "amazon-cognito-identity-js";
import { poolData } from "../lib/constants";
import { Toast } from "primereact/toast";

const userPool = new CognitoUserPool(poolData);

const ChangePassword = () => {
    const emailid = localStorage.getItem("email");
    const [errMsg, setErrMsg] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);
    const [oldPasswordType, setOldPasswordType] = useState("password");
    const [newPasswordType, setNewPasswordType] = useState("password");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const toast = useRef();

    const validateInputs = () => {
        if (!oldPassword) return "Please enter the old password";
        if (!newPassword) return "Please enter the new password";
    };

    const onUserChangePassword = async (e) => {
        setErrMsg(null)
        e.preventDefault();
        let validationRes = validateInputs();

        if (typeof validationRes === "string") {
            setErrMsg(validationRes);
        } 
        else {
            setDisableBtn(true);
            let authenticationDetails = new AuthenticationDetails({
                Username: emailid,
                Password: oldPassword,
            });

            let userData = {
                Username: emailid,
                Pool: userPool,
            };

            let cognitoUser = new CognitoUser(userData);
            try {
                    cognitoUser.authenticateUser(authenticationDetails, {
                        onSuccess:()=>{
                            cognitoUser.changePassword(oldPassword, newPassword, (err, res) => {
                                if (err) {
                                    toast.current.show({ severity: 'error', summary: 'Error', detail: err.message });
                                    setDisableBtn(false);
                                    return;
                                } else {
                                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Password Changed' });
                                     setNewPassword("")
                                     setOldPassword("")
                                    setDisableBtn(false);
        
                                };
                        });
                        },
                        onFailure: (error)=>{
                            setErrMsg(error.message);
                            setDisableBtn(false);
                        }
                    })
                   
            } catch (err) {
                setErrMsg(err.message);
                setDisableBtn(false);
            }
        }
    };

    const toggleOldPassword = (e) => {
        if (oldPasswordType === "password") {
            setOldPasswordType("text");
            return;
        }
        setOldPasswordType("password");
    };

    const toggleNewPassword = (e) => {
        if (newPasswordType === "password") {
            setNewPasswordType("text");
            return;
        }
        setNewPasswordType("password");
    };

    return (
        <div style={{height: "80vh"}} className="flex flex-column">
            <Toast ref={toast} />
            <div className=" align-self-center  mt-auto mb-auto">
                <div className=" card flex flex-column col-12" style={{ color: "#616161",padding: "2rem"}}>
                   
                    <form>
                        <div className="mb-4 px-3 font-bold align-self-center" style={{ textAlign: "center" , fontSize:"1.9rem"}}>
                            Change Password
                        </div>
                        
                        <div className="mb-1 px-3" style={{fontSize: "1.2rem"}}>
                            <label>Old Password</label>
                        </div>
                        <div className="p-inputgroup mb-3 px-3">
                            <span className="p-float-label">
                                <InputText style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} value={oldPassword} type={oldPasswordType} id="oldpassword" onChange={(e) => setOldPassword(e.target.value)} />
                                <div className="p-inputgroup-addon" onClick={toggleOldPassword}>
                                    {oldPasswordType === "password" ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
                                </div>
                            </span>
                        </div>
                        <div className="mb-1 px-3"  style={{fontSize: "1.2rem"}}>
                            <label>New Password</label>
                        </div>
                        <div className="p-inputgroup mb-3 px-3">
                            <span className="p-float-label">
                                <InputText style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} value={newPassword} type={newPasswordType} id="newpassword" onChange={(e) => setNewPassword(e.target.value)} />
                                <div className="p-inputgroup-addon" onClick={toggleNewPassword}>
                                    {newPasswordType === "password" ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
                                </div>
                            </span>
                        </div>
                        <div className="mb-3 px-3" style={{ color: "red" }}>
                            {errMsg}
                        </div>
                        <div className="mb-3 px-3">
                            <Button className="login-button mt-1 mb-3 px-3 col-12" style={{fontSize: "1.2rem"}} label="Change Password" disabled={disableBtn} onClick={onUserChangePassword}></Button>
                        </div>
                    </form> 

                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
