import React from 'react'
const Upload = ({accept, error, children, multiple, fileName, onchange, fileInputDisabled}) => {
    
    return (
    <div>
        <div className='grid justify-content-center'>
            <div className="flex card m-3 flex-column align-items-center border-1 border-dashed border-400 border-round-md" style={{ padding: "1rem", minWidth:"40%" }}>
                <div className="fileUpload">
                    <div className="text-6xl mb-2" >
                        <i className="pi pi-upload" style={{ fontSize: "2rem", color: "#009688" }}></i>
                    </div>
                    <span className='text-lg'>{fileName ?? ""}</span>
                    <span className="mt-3 mb-3 block text-xl">Upload File</span>
                    <input type="file" className="upload" value="" multiple={multiple} onChange={onchange} accept={accept} disabled={fileInputDisabled}/>
                </div>
                {children}
                {error ? (
                    <>
                        <br />
                        <span className="color2">{error}</span>
                    </>
                ) : null}
                <br />
            </div>
        </div>
    </div>
  )
}

export default Upload