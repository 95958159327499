import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";

const FuelConsumption = ({ chartData }) => {
    const [state, setState] = useState(null);

    useEffect(() => {
        const option = {
            chart: {
                zoomType: "xy",
            },
            title: {
                text: "",
                align: "center",
                style: chartHeadingStyle,
            },
            xAxis: [
                {
                    categories: [],
                    crosshair: true,
                },
            ],
            yAxis: [
                {
                    // Primary yAxis
                    labels: {
                        format: "{value} INR",
                        style: {
                            color: colorPalettes.color4,
                        },
                    },
                    title: {
                        text: "Consumption Cost",
                        style: {
                            color: colorPalettes.color4,
                        },
                    },
                },
                {
                    // Secondary yAxis
                    title: {
                        text: "Fuel Consumed",
                        style: {
                            color: colorPalettes.color1,
                        },
                    },
                    labels: {
                        format: "{value} L",
                        style: {
                            color: colorPalettes.color1,
                        },
                    },
                    opposite: true,
                },
            ],
            tooltip: {
                shared: true,
            },
            series: [],
        };
        option.series = chartData.data;
        option.xAxis[0].categories = chartData.category;
        option.title.text = chartData.title;
        setState(option);
    }, [chartData]);

    return state ? <HighchartsReact highcharts={Highcharts} options={state} /> : null;
};

export default FuelConsumption;
