import axios from "axios";
async function general(serviceID, payload, collection) {
    let res;
    if (payload && payload.operation && payload.operation === "aggregate") {
        if (collection === "nq-totalRevenue_by_plaza_date_daily") {
            const matches = payload.aggregate;
            for (let i = 0; i < matches.length; i++) {
                if (matches[i].$match?.tablename) {
                    delete matches[i].$match.tablename;
                    matches[i].$match = {
                        ...matches[i].$match,
                        $or: [
                            { tablename: { $in: ["cch1", "cch2"] } },
                            {
                                tablename: "pos",
                                plazacode: { $nin: ["CZ", "GF", "BS1", "BS2"] }
                            }
                        ]
                    };
                }
            }
        }
        res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + `/${collection}`, payload);
    }
    else res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + `/${collection}`, payload)
    if (res.status !== 200) return [];
    return res.data;
}

async function apiGeneralFunction(serviceID, payload,collection) {
    let res=await axios.post(process.env.REACT_APP_API_PARSER_URL + "/docdb/" + serviceID + `/${collection}`, payload)
    if (res.status !== 200) return [];
    return res.data;
}

export const plazaServices = {
    general,
    apiGeneralFunction
}