const initialState =  localStorage.getItem("service") ?  JSON.parse(localStorage.getItem("service")).region.toLowerCase().includes('us') && !(JSON.parse(localStorage.getItem("service"))?.location?.operatingCountry === "canada") ? "FPS" : "MKS"  : "MKS";
const changeUnit = (state = initialState, action) => {
    switch (action.type) {
        case "CHANGEUNIT":
            return (state = action.payload);
        default:
            return state;
    }
};
export default changeUnit;
