import axios from "axios";
import { mapKey } from "../utilities/constant";
import { dummy_data } from "../utilities/staticData";
const map_url = "https://revgeocode.search.hereapi.com/v1/revgeocode";

export class VehicleSrvice {
    constructor() {
        this.serviceID = localStorage.getItem("serviceId");
        // this.service = JSON.parse(localStorage.getItem('service'));
        // this.serTypeNm = this.service ? this.service.serviceTypeName : "";
    }

    getCurrentWeather = async (position, weatherUnitSys) => {
        const { lat, lng } = position;
        const res = await axios.get("https://api.openweathermap.org/data/2.5/weather?lat=" + lat + "&lon=" + lng + "&appid=db5175c62511584caecb67ad6d034d40&units=" + weatherUnitSys);
        return res.data;
    };

    getVehicles = async (serviceID, isFuel, payload) => {
        let collName = isFuel ? "nq-fuel-meta" : "nq-obd-meta"
        if(!isFuel)payload={}
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/" + collName, payload);
        return res.data;
    };
    getLiveFuelLeve = async (payload) => {
        const res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/fuel", payload);
        return res.data;
    };

    getVehicleDivingData = async (serviceID, payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-obd-data", payload);
        return res.data;
    };

    getVehicleGreenDivingScore = async (payload) => {
        const res = await axios.post(process.env.REACT_APP_API_PARSER_URL, payload);
        return res.data;
    };

    getVehicleNumber = async (serviceID) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-fleets", { projection: { fleetName: 1, alias: 1, "vehicle.vehicleNumber": 1, "vehicle.make": 1, "vehicle.model": 1, "location.siteName": 1 } });
        return res.data;
    };

    getFleetsData = async (serviceID, isFuel, payload) => {
        let collName = isFuel ? "nq-items" : "nq-fleets";
        if(!isFuel) payload = payload || {}
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/" + collName, payload);
        return res.data;
    };

    getAllCurrentVehicle = async (serviceID) => {
        const res = await axios.post(process.env.REACT_APP_ANALYTICS_SERVICE_URL + "/get-current-location", { serviceId: serviceID });
        return res.data;
    };
    getAllOBDVehicle = async (serviceID) => {
        const res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/meta", { serviceId: serviceID });
        return res.data;
    };
    getLatLongDetails = async (position) => {
        const res = await axios.get(map_url + "?at=" + position + `&lang=en-US&apikey=${mapKey}`);
        return res.data;
    };
    getRoute = async (origin, destination, wayPoints) => {
        const route_url = `https://router.hereapi.com/v8/routes?xnlp=CL_JSMv3.1.30.9&apikey=${process.env.REACT_APP_MAP_API_KEY}&routingMode=short&transportMode=car&origin=${encodeURIComponent(origin)}&destination=${encodeURIComponent(destination)}&${wayPoints}&return=polyline,summary,actions`;

        const res = await axios.get(route_url);
        return res.data;
    };
    getVehicleGreenDivingAvgScore = async (serviceID, payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-obd-history", payload);
        return res.data;
    };
    getVehicleReport = async (serviceID, form_date, to_date, thingName) => {
        const payload = { serviceId: serviceID, reportType: 10, reportFromDate: form_date, reportToDate: to_date, thingName: thingName };
        const token = localStorage.getItem("token");
        // const token = 'eyJraWQiOiIxY3JqR0w1VDJSVHVsaUMwSFhhRFBQUWVETCtcL2ltS2FhaFRJdXU0RmFLMD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIwMTg3NGNlMS03MTdhLTQzMTktOGNhMC1hZmQ1NGFmNjRmNTMiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5hcC1zb3V0aC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoLTFfT1l0dGYyNEkxIiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjpmYWxzZSwiY29nbml0bzp1c2VybmFtZSI6IjAxODc0Y2UxLTcxN2EtNDMxOS04Y2EwLWFmZDU0YWY2NGY1MyIsImF1ZCI6IjcxYTlnaDJlYTIyYzBvZ29nM2p0bGxpb3NhIiwiZXZlbnRfaWQiOiI5ODAxYmExNi03Yjg5LTQyNmEtYTBkOC1lMjdiYzY1NzFhNGYiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY2MzA0OTg3MywibmFtZSI6Ik5leHRxb3JlIFN1cHBvcnQiLCJwaG9uZV9udW1iZXIiOiIrOTE5MzIxOTUyNDQyIiwiZXhwIjoxNjYzMDUzNDczLCJpYXQiOjE2NjMwNDk4NzMsImVtYWlsIjoic3VwcG9ydEBuZXh0cW9yZS5jb20ifQ.Vj1xi_DbLRsuTE31Acz5I_1ccrfzsaW8A95VnU3bqZpp-NuooWfSta7eR1Be35rRU_Al1OQkyTr-CgwZaHvG7YbPbTeyh5aC4V6AJbPMTOuhfvVYA0JJzR1HHpGZFlugT5q3J32USK4pYPGGyJUn6G6Ql8e6fibIYp0-xrzrbDKART0uL6A6IvBQuBgeGTm58u4FjhvqJtrLkovydOTNgwJqJJi_cJUzzafqUoscEIz3RkCUZZOlopveUrN5bq0cWznMtSJAH8kqc-pqdHuqPxltXna0kIMjGnP9q5jmq5Kd96eNmIUF6LJ5HW7cysmM4CPp3tHFGQK__RnA_eXdRg';
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        const res = await axios.post(process.env.REACT_APP_ANALYTICS_SERVICE_URL + "/report", payload, { headers: headers });

        if (res.data.responseData.data && res.data.responseData.data.length === 0) {
            return dummy_data;
        } else {
            return res.data;
        }
    };

    getHistory = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-obd-history", payload);

        return res.data;
    };

    getAggregateHistory = async (serviceID, payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-obd-history", payload);
        return res.data;
    };

    getObdDataHistory = async (serviceID, payload) => {
        // payload.serviceid = serviceID;
        // const res = await axios.post(process.env.REACT_APP_API_PARSER_URL+'/hist', payload);
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-obd-data", payload);
        return res.data;
    };
    getVehicleParams = async (query) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/nextqore/nq-obd-master", query);
        return res.data;
    };

    getGeoFences = async () => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + this.serviceID + "/nq-geo-fences", {});
        return res.data;
    };

    getGeoEvents = async (query) => {
        query.state = { $in: ["OutsideGF", "InsideGF"] };
        query.name = { $ne: null };
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + this.serviceID + "/nq-geo-events", { filter: query });
        return res.data;
    };

    getThingData = async (thingName) => {
        let query = { serviceId: this.serviceID };
        if (thingName) query.thingName = thingName;
        const res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/meta", query);
        return res.data;
    };

    getReportData = async (serviceId, payload, report) => {
        const res = report === "evtr" ? await axios.post(process.env.REACT_APP_API_PARSER_URL + "/docdb/" + serviceId + "/nq-sessions-history", payload) : await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-daily-history", payload)
        return res.data
    }

    getAggregateMonthlyAnalytics  =async(payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + this.serviceID + "/nq-monthly-analytics", payload)
        return res.data
    }
}
