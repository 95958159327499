import React from "react";

const ProjectGrid = ({ data, showDetails }) => {
    return (
        <div className="folder-grid mt-3">
            {data.map((item, index) => {
                return (
                    <div className="folder" key={index} onClick={(ev) => showDetails(`${process.env.REACT_APP_FILE_SHARE_BASE_URL}${item.fsid}`)}>
                        {/* <img src="images/folder_icon.png" alt="Project name" /> */}
                        <img src="https://fs.nextqore.com/assets/icons/folder.svg" alt="Project name" />
                        <div className="folder-lable">
                            <p className="text-lg font-medium mb-0">{item.name}</p>
                            {/* <p className="mb-0 text-sm text-500">{item.size} Kb</p>
                            <span className="text-sm text-500">{moment(item.time).format("DD-MM-YYYY hh:mm A")}</span> */}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ProjectGrid;
