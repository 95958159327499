import axios from "axios";

export class NoiseService {
    getHourelyNoiseData = async (serviceId, startTime, endTime, thingName) => {
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        $and: [{ thingName: thingName }, { ts: { $gte: startTime, $lte: endTime } }],
                    },
                },
                {
                    $group: {
                        _id: "$hr",
                        maxNoise: { $max: "$noise" },
                        minNoise: { $min: "$noise" },
                        avgNoise: { $avg: "$noise" },
                        AboveLimitCnt: {
                            $sum: {
                                $cond: [
                                    {
                                        $and: [{ $gte: ["$hr", 6] }, { $lte: ["$hr", 20] }],
                                    },
                                    {
                                        $cond: [{ $gte: ["$noise", 55] }, 1, 0],
                                    },
                                    {
                                        $cond: [{ $gte: ["$noise", 45] }, 1, 0],
                                    },
                                ],
                            },
                        },
                    },
                },
            ],
        };
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-noise-data", payload);
        return res.data;
    };

    getMunitInterVelNoiseData = async (serviceId, startTime, endTime, thingName) => {
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        $and: [{ thingName: thingName }, { ts: { $gte: startTime, $lte: endTime } }],
                    },
                },
                {
                    $project: {
                        _id: 0,
                        hr: 1,
                        noise: 1,
                        min_range: {
                            $cond: [
                                {
                                    $and: [
                                        {
                                            $gte: ["$min", 0],
                                        },
                                        {
                                            $lte: ["$min", 4],
                                        },
                                    ],
                                },
                                "0-4",
                                {
                                    $cond: [
                                        {
                                            $and: [
                                                {
                                                    $gte: ["$min", 5],
                                                },
                                                {
                                                    $lte: ["$min", 9],
                                                },
                                            ],
                                        },
                                        "5-9",
                                        {
                                            $cond: [
                                                {
                                                    $and: [
                                                        {
                                                            $gte: ["$min", 10],
                                                        },
                                                        {
                                                            $lte: ["$min", 14],
                                                        },
                                                    ],
                                                },
                                                "10-14",
                                                {
                                                    $cond: [
                                                        {
                                                            $and: [
                                                                {
                                                                    $gte: ["$min", 15],
                                                                },
                                                                {
                                                                    $lte: ["$min", 19],
                                                                },
                                                            ],
                                                        },
                                                        "15-19",
                                                        {
                                                            $cond: [
                                                                {
                                                                    $and: [
                                                                        {
                                                                            $gte: ["$min", 20],
                                                                        },
                                                                        {
                                                                            $lte: ["$min", 24],
                                                                        },
                                                                    ],
                                                                },
                                                                "20-24",
                                                                {
                                                                    $cond: [
                                                                        {
                                                                            $and: [
                                                                                {
                                                                                    $gte: ["$min", 25],
                                                                                },
                                                                                {
                                                                                    $lte: ["$min", 29],
                                                                                },
                                                                            ],
                                                                        },
                                                                        "25-29",
                                                                        {
                                                                            $cond: [
                                                                                {
                                                                                    $and: [
                                                                                        {
                                                                                            $gte: ["$min", 30],
                                                                                        },
                                                                                        {
                                                                                            $lte: ["$min", 34],
                                                                                        },
                                                                                    ],
                                                                                },
                                                                                "30-34",
                                                                                {
                                                                                    $cond: [
                                                                                        {
                                                                                            $and: [
                                                                                                {
                                                                                                    $gte: ["$min", 35],
                                                                                                },
                                                                                                {
                                                                                                    $lte: ["$min", 39],
                                                                                                },
                                                                                            ],
                                                                                        },
                                                                                        "35-39",
                                                                                        {
                                                                                            $cond: [
                                                                                                {
                                                                                                    $and: [
                                                                                                        {
                                                                                                            $gte: ["$min", 40],
                                                                                                        },
                                                                                                        {
                                                                                                            $lte: ["$min", 44],
                                                                                                        },
                                                                                                    ],
                                                                                                },
                                                                                                "40-44",
                                                                                                {
                                                                                                    $cond: [
                                                                                                        {
                                                                                                            $and: [
                                                                                                                {
                                                                                                                    $gte: ["$min", 45],
                                                                                                                },
                                                                                                                {
                                                                                                                    $lte: ["$min", 49],
                                                                                                                },
                                                                                                            ],
                                                                                                        },
                                                                                                        "45-49",
                                                                                                        {
                                                                                                            $cond: [
                                                                                                                {
                                                                                                                    $and: [
                                                                                                                        {
                                                                                                                            $gte: ["$min", 50],
                                                                                                                        },
                                                                                                                        {
                                                                                                                            $lte: ["$min", 54],
                                                                                                                        },
                                                                                                                    ],
                                                                                                                },
                                                                                                                "50-54",
                                                                                                                "55-59",
                                                                                                            ],
                                                                                                        },
                                                                                                    ],
                                                                                                },
                                                                                            ],
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            ],
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    $group: {
                        _id: {
                            hr: "$hr",
                            min_range: "$min_range",
                        },
                        maxNoise: {
                            $max: "$noise",
                        },
                        minNoise: {
                            $min: "$noise",
                        },
                        avgNoise: {
                            $avg: "$noise",
                        },
                    },
                },
                { $sort: { _id: 1 } },
            ],
        };
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-noise-data", payload);
        return res.data;
    };

    getDistinctThings = async (serviceId) => {
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $group: {
                        _id: "$thingName",
                    },
                },
            ],
        };
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-noise-data", payload);
        return res.data;
    };

    getThingDetails = async (serviceId) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-spaces", {});
        return res.data;
    };

    getThingsData = async (serviceId) => {
        const payload = {
            filter: {
                "installationParameters.type": { $in: ["noisesensor", "noiseaqisensor"] }
            },
            projection: { "sensorIdentification.itemId": 1, _id: 0 }
        }
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-config-sensors", payload);
        return res.data;
    }
    getDownloadData = async (startTime, endTime, thingName, serviceId) =>{
        let payload = {
            operation :"aggregate",
            "aggregate" : [
                {
                    $match:{
                        $and:[{  ts: {$gte:startTime, $lte: endTime}, thingName: thingName}],
                       
                    }
                },
                {
                  $project:{
                      date: {$arrayElemAt: [{$split:["$ts"," "]} ,0]},
                      hr: 1,
                      noise:1,
                  }  
                },
                {
                        $group:{
                            _id:{
                                hr:"$hr",
                                date:"$date"
                            },
                            max: {"$max": "$noise"},
                            min:{"$min": "$noise"},
                            avg:{"$avg": "$noise"}
                        }
                    },{
                        $sort:{"_id.date":1, "_id.hr":1}
                    }
            ]
        }
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-noise-data", payload);
        return res.data;
    }
}

