import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import dbService from "../../service/dbService";
import { Column } from "primereact/column";
import configService from "../../service/configService";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { thingNameMap } from "../../lib/constants";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';

const ThingList = () => {
    const [things, setThings] = useState(null);
    const [serviceBlock, setServiceBlock] = useState(null);
    const [service, setService] = useState(null);
    const history = useHistory();
    const [updImeiThing, setUpdImeiThing] = useState(null);
    const [updImeiModal, setupdImeiModal] = useState(false);
    const [imei, setIMEI] = useState(null);
    const [imeiErrMsg, setImeiErrMsg] = useState(null);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [aliasModel,setAliasModel]=useState(false);
    const [alias,setAlias]=useState("");
    const [aliasThing,setAliasThing]=useState(null); 
    const [aliasErrMsg,setAliasErrMsg]=useState(null);
    let serviceId = localStorage.getItem("serviceId");
    const imageBodyTemplate = (thing) => {
        return <img alt="" src={process.env.REACT_APP_IMAGE_STATIC_URL + "/" + serviceId + "/" + thing[serviceBlock + "Name"] + ".jpg"} onError={(e) => (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")} style={{ maxWidth: "100%", maxHeight: "30px" }} />;
    }

    const getThingNameAndBtn = (thing) => {
        return (
            <div>{serviceBlock === "item" ? thing["thingName"] : thing[serviceBlock + "Name"]}<br></br>
                {thing.imei ? null : <Button label="Add IMEI" onClick={() => {setUpdImeiThing(thing);setupdImeiModal(true) }}></Button>}
            </div>
        )
    }
    const updateAlias = (thing) => {
        return (
            <div>
                {thing.alias}
                {thing.alias?
                <Button label="Update Alias" style={{ cursor: "pointer", float: "down" }} className="mr-2 mb-2 block"  onClick={() => { setAliasModel(true)
                setAliasThing(thing)}}></Button>:null}
            </div>
        )
    }

    useEffect(() => {
        dbService
            .getServiceDetails(serviceId)
            .then((res) => {
                setService(res[0]);
                setServiceBlock(res[0].serviceBlock);
            })
            .catch((e) => console.error(e));
    }, []);

    useEffect(() => {
        if (serviceBlock)
            configService
                .getListOfThings(serviceBlock)
                .then((things) => {
                    if (things && things.length) {
                        if (serviceBlock === "fleet") {
                            things.forEach((t) => {
                                t = Object.assign(t, t.vehicle);
                                t.property = t.make + " " + t.model;
                                t.vehicleNumber = t.vehicleNumber.toUpperCase();
                                t.loc = t.location ? (t.location.address1 ? t.location.address1 + ", " : "") + (t.location.city ? t.location.city + ", " : "") + t.location.state + ", " + t.location.zip : "";
                            });
                        }
                        // if (serviceBlock === "item") {
                        //     things.forEach((t) => {
                        //         t
                        //     })
                        // }
                        setThings(things);
                    }
                })
                .catch((e) => console.error(e));
    }, [serviceBlock]);

    const onAddThing = () => {
        if (service.serviceTypeName === "Vehicle tracking with OBD" || service.serviceTypeName === "Vehicle Tracking") history.push("/device-config");
        else history.push("/upload-file");
    };

    const footer = (
        <>
            <Button type="button" label="Save" icon="pi pi-check" disabled={saveBtnLoading} className="p-button-text" onClick={() => onSave()} />
            <Button type="button" label="Close" icon="pi pi-times" className="p-button-text" onClick={() => onCancel()} />
        </>
    )

    const aliasFooter = (
        <>
            <Button type="button" label="Save" icon="pi pi-check" disabled={saveBtnLoading} className="p-button-text" onClick={() => aliasSave()} />
            <Button type="button" label="Close" icon="pi pi-times" className="p-button-text" onClick={() => onCancel()} />
        </>
    )
    const onSave = async () => {
        if (!imei || imei.length !== 15) return setImeiErrMsg("Please insert 15 digit IMEI");
        let temp="";
        if(serviceBlock==="item"){
            temp=updImeiThing["thingName"]
        }
        else temp=updImeiThing[serviceBlock+"Name"]
        if (updImeiThing) {
            let payload = { serviceId, imei, thingName:temp,serviceBlock};
            setImeiErrMsg("");
            setSaveBtnLoading(true);
            let res = await configService.addImeiInThing(payload);

            if (res.responseCode === 200) {
                setImeiErrMsg("IMEI successfully added");
            } else {
                setImeiErrMsg("Something went wrong");
                setSaveBtnLoading(false);
            }
            let serBlck= serviceBlock;
            setServiceBlock("");
            setServiceBlock(serBlck);
        }
    }

    const aliasSave= async()=>{
        if(alias){
            let res;
            if(serviceBlock==="item"){
                res=await dbService.updateAlias(serviceId,aliasThing.thingName,alias);
            }
            else{
                res=await dbService.updateAlias(serviceId,aliasThing[serviceBlock + "Name"],alias);
            }
            if(res.ok===1){
                setAliasErrMsg("Alias Updated Sucessfully");
                let temp=things;
                if(serviceBlock==="item"){
                    temp.map((item)=>{
                        if(item.thingName===aliasThing.thingName)item.alias=alias;
                    })
                }
                else{
                    temp.map((item)=>{
                        if(item[serviceBlock + "Name"]===aliasThing[serviceBlock + "Name"])item.alias=alias;
                    })
                }
                setThings(temp);
            }
            else{
                setAliasErrMsg("something went wrong");

            }
        }
        else{
            setAliasErrMsg("alias is empty");
        }
    }

   const getDialog=()=>{
    return(
        <Dialog header={"Update Alias  for " +(serviceBlock==="item" ? ((aliasThing && aliasThing.thingName)?aliasThing.thingName.toUpperCase():"" ):( (aliasThing && aliasThing[serviceBlock+"Name"])?aliasThing[serviceBlock+"Name"].toUpperCase():"")) + " : " } visible={aliasModel} modal style={{ width: '50vw' }} footer={aliasFooter} onHide={() => onCancel()} >
            <div className="field m-0 md:col-6 col-12 mb-3">
                <InputText type="string" id="alias" className="w-full" placeholder="alias" name="alias" onChange={(e) => { setAlias(e.target.value) }} />
           </div>
            <div className="col-12">
                {aliasErrMsg ? <span>{aliasErrMsg}</span> : null}
            </div>
        </Dialog>
    )
    
   }
    
    const onCancel = () => {
        let serBlck= serviceBlock;
        setServiceBlock("");
        setImeiErrMsg("");
        setUpdImeiThing(null);
        setIMEI(null);
        setupdImeiModal(false);
        setSaveBtnLoading(false);
        setServiceBlock(serBlck);
        setAliasModel(false);
        setAliasErrMsg("")
        setAliasThing(null);
    }

    return (
        <div className="col-12">
            <div className="card">
                <div className="grid">
                    {serviceBlock ? (
                        <>
                         <div className="col">
                            <h3>{"List of " + serviceBlock + "s"} </h3>    
                            </div>
                            <div className="col-12">
                                <Button label="Add" icon="pi pi-plus" style={{ cursor: "pointer", float: "right" }} className="mr-2 mb-2" onClick={() => onAddThing()}></Button>
                            </div>
                        </>
                    ) : null}

                    {things && things.length ? (
                        serviceBlock === "fleet" ? (
                            <div className="col-12">
                                <DataTable value={things} paginator={true} rows={10} responsiveLayout="scroll">
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Image" body={imageBodyTemplate}></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Thing Name" style={{ width: "100px" }} body={getThingNameAndBtn}></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Type" style={{ width: "100px" }} field={serviceBlock + "Type"}></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Make Model" style={{ width: "150px" }} field="property"></Column>
                                    {serviceBlock !== "fleet" ? <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Site Name" field="location.siteName"></Column> : <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Vehicle No." field="vehicleNumber"></Column>}
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Alias" style={{ width: "10px" }} field={updateAlias}></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Location" field="loc"></Column>
                                </DataTable>

                                <Dialog header={"Add IMEI  for " + ((updImeiThing && updImeiThing.vehicle) ? updImeiThing.vehicle.vehicleNumber.toUpperCase() : "") + " : "} visible={updImeiModal} modal style={{ width: '50vw' }} footer={footer} onHide={() => onCancel()} >
                                    <div className="col-12">
                                        <div className="field m-0 md:col-6 col-12 mb-3">
                                            <InputText type="number" id="imei" className="w-full" placeholder="IMEI" name="IMEI" onChange={(e) => { setIMEI(e.target.value) }} />
                                        </div>
                                        <div className="col-12">
                                            {imeiErrMsg ? <span>{imeiErrMsg}</span> : null}
                                        </div>
                                    </div>
                                </Dialog>
                                {getDialog()}
                            </div>
                        ) : serviceBlock === "item" ? (
                            <div className="col-12">
                                <DataTable value={things} paginator={true} rows={10} responsiveLayout="scroll">
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Thing Name" field="thingName" body={service.serviceTypeName.toLowerCase().includes("fuel") ? getThingNameAndBtn: null}></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Type" field="type"></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="State" field="location.state"></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Site Name" field="location.siteName"></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Alias" style={{ width: "10px" }} field={updateAlias}></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Zip/Pin" field="location.zip"></Column>
                                </DataTable>
                                <Dialog header={"Add IMEI  for "+ ((updImeiThing && updImeiThing.thingName)?updImeiThing.thingName.toUpperCase() : ":")+ ""} visible={updImeiModal} modal style={{ width: '50vw' }} footer={footer} onHide={() => onCancel()} >
                                    <div className="col-12">
                                        <div className="field m-0 md:col-6 col-12 mb-3">
                                            <InputText type="number" id="imei" className="w-full" placeholder="IMEI" name="IMEI" onChange={(e) => { setIMEI(e.target.value) }} />
                                        </div>
                                        <div className="col-12">
                                            {imeiErrMsg ? <span>{imeiErrMsg}</span> : null}
                                        </div>
                                    </div>
                                </Dialog>
                                {getDialog()}
                            </div>
                        ) : serviceBlock === "object" ? (
                            <div className="col-12">
                                <DataTable value={things} paginator={true} rows={10} responsiveLayout="scroll">
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Name" field="objectName"></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Type" field="objectType"></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="State" field="location.state"></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Site Name" field="location.siteName"></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Alias" style={{ width: "10px" }} field={updateAlias}></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Zip/Pin" field="location.zip"></Column>
                                </DataTable>
                                {getDialog()}
                            </div>
                        ) : serviceBlock === "space" ? (
                            <div className="col-12">
                                <DataTable value={things} paginator={true} rows={10} responsiveLayout="scroll">
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Name" field="spaceName"></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Type" field="spaceType"></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="State" field="location.state"></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Site Name" field="location.siteName"></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Alias" style={{ width: "10px" }} field={updateAlias}></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Zip/Pin" field="location.zip"></Column>
                                </DataTable>
                                {getDialog()}
                            </div>
                        ) : null
                    ) : null}
                </div>
            </div>
        </div>
    );
};
export default ThingList;
