export const login = (user) => {
    return { type: "LOGIN", payload: user };
};
export const logout = () => {
    return { type: "LOGOUT" };
};

export const changeUnit = (unitType) => {
    return { type: "CHANGEUNIT", payload: unitType };
};

export const statesList = (states) => {
    return { type: "SETSTATES", payload: states };
};

export const setThingName = (things) => {
    return { type: "SETTHINGNAME", payload: things };
};
