import React, { Component } from "react";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import moment from "moment";
import { AssetService } from "../../service/AssetService";

class PerformanceDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            dateRange: null,
            dgFreq: null,
            dgBattery: null,
            dgLoading: null,
            engWaterTemp: null,
            ebVoltageQuli: null,
            lowOilPressure: null,
            dgOf20Kva: null,
            dgOf30Kva: null,
            dgOffEBVolt: null,
            selectedDg: null,
            lowOilPressureThing: null,
            lubeOilDialog: false,
            title: null,
            lubeOilBrakeupData: null,
            dgOffEBVoltThing: null,
        };

        this.headerGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Sl" className="bg-primary" />
                    <Column header="Thing Name" className="bg-primary" />
                    <Column header="State" className="bg-primary" />
                    <Column header="City" className="bg-primary" />
                    <Column header="Frequency(Min)" className="bg-primary" />
                    <Column header="Frequency(Avg)" className="bg-primary" />
                    <Column header="Frequency(Max)" className="bg-primary" />
                    <Column header="DG Frequency Outside Limit" className="bg-primary" />
                </Row>
            </ColumnGroup>
        );

        this.dgBatteryHeaderGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Sl" className="bg-primary" />
                    <Column header="Thing Name" className="bg-primary" />
                    <Column header="State" className="bg-primary" />
                    <Column header="City" className="bg-primary" />
                    <Column header="DG Battery Volt < 10 V" className="bg-primary" />
                </Row>
            </ColumnGroup>
        );

        this.dgLoadingHeaderGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Sl" className="bg-primary" />
                    <Column header="Thing Name" className="bg-primary" />
                    <Column header="State" className="bg-primary" />
                    <Column header="City" className="bg-primary" />
                    <Column header="R-PhaLoadCurrent" className="bg-primary" />
                    <Column header="Y-PhaLoadCurrent" className="bg-primary" />
                    <Column header="Y Phase current variation %" className="bg-primary" />
                    <Column header="B-PhaLoadCurrent" className="bg-primary" />
                    <Column header="B Phase current variation %" className="bg-primary" />
                </Row>
            </ColumnGroup>
        );

        this.engWaterTempHeaderGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Sl" className="bg-primary" />
                    <Column header="Thing Name" className="bg-primary" />
                    <Column header="State" className="bg-primary" />
                    <Column header="City" className="bg-primary" />
                    <Column header="Water Temperature Threshold > 90C" className="bg-primary" />
                </Row>
            </ColumnGroup>
        );

        this.ebVoltageQuliHeaderGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Sl" className="bg-primary" />
                    <Column header="Thing Name" className="bg-primary" />
                    <Column header="State" className="bg-primary" />
                    <Column header="City" className="bg-primary" />
                    <Column header="EB-R-PhaseVolt" className="bg-primary" />
                    <Column header="EB-Y-PhaseVolt" className="bg-primary" />
                    <Column header="EB-B-PhaseVolt" className="bg-primary" />
                    <Column header="DG On/Off" className="bg-primary" />
                </Row>
            </ColumnGroup>
        );

        this.lowOilPressureHeaderGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Sl" className="bg-primary" />
                    <Column header="Thing Name" className="bg-primary" />
                    <Column header="State" className="bg-primary" />
                    <Column header="City" className="bg-primary" />
                    <Column header="Low Lube Oil Pressure" className="bg-primary" />
                </Row>
            </ColumnGroup>
        );

        this.dgOfSizeKvaHeaderGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="" colSpan={3} className="bg-primary" />
                    <Column header="R-Phase Current" colSpan={3} className="bg-primary text-center" />
                    <Column header="Y-Phase Current" colSpan={3} className="bg-primary text-center" />
                    <Column header="B-Phase Current" colSpan={3} className="bg-primary text-center" />
                </Row>
                <Row>
                    {/* <Column header="Sl" className="bg-primary"/> */}
                    <Column header="Thing Name" className="bg-primary" />
                    <Column header="State" className="bg-primary" />
                    <Column header="City" className="bg-primary" />
                    <Column header="Min" className="bg-primary text-center" />
                    <Column header="Avg" className="bg-primary text-center" />
                    <Column header="Max" className="bg-primary text-center" />
                    <Column header="Min" className="bg-primary text-center" />
                    <Column header="Avg" className="bg-primary text-center" />
                    <Column header="Max" className="bg-primary text-center" />
                    <Column header="Min" className="bg-primary text-center" />
                    <Column header="Avg" className="bg-primary text-center" />
                    <Column header="Max" className="bg-primary text-center" />
                </Row>
            </ColumnGroup>
        );

        this.dgOffEBVoltHeaderGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Sl" className="bg-primary" />
                    <Column header="Thing Name" className="bg-primary" />
                    <Column header="State" className="bg-primary" />
                    <Column header="City" className="bg-primary" />
                    <Column header="EB-R-PhaseVolt" className="bg-primary" />
                    <Column header="EB-Y-PhaseVolt" className="bg-primary" />
                    <Column header="EB-B-PhaseVolt" className="bg-primary" />
                    <Column header="DG Off Count" className="bg-primary" />
                </Row>
            </ColumnGroup>
        );

        this.lubeOilBrakeupDataHeaderGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Thing Name" className="bg-primary" />
                    <Column header="State" className="bg-primary" />
                    <Column header="City" className="bg-primary" />
                    <Column header="Lube Oil Pressure" className="bg-primary" />
                    <Column header="On" className="bg-primary" />
                </Row>
            </ColumnGroup>
        );

        this.ebVoltBrakeupDataDataHeaderGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Thing Name" className="bg-primary" />
                    <Column header="State" className="bg-primary" />
                    <Column header="City" className="bg-primary" />
                    <Column header="EB-R-PhaseVolt" className="bg-primary" />
                    <Column header="EB-Y-PhaseVolt" className="bg-primary" />
                    <Column header="EB-B-PhaseVolt" className="bg-primary" />
                    <Column header="On" className="bg-primary" />
                </Row>
            </ColumnGroup>
        );

        this.serviceID = localStorage.getItem("serviceId");
        this.assetService = new AssetService();
        this.onDateChange = this.onDateChange.bind(this);
        this.getRTableLable = this.getRTableLable.bind(this);
        this.getYTableLable = this.getYTableLable.bind(this);
        this.getBTableLable = this.getBTableLable.bind(this);
    }

    componentDidMount = () => {
        const endDay = moment().subtract(1, "days").endOf("day").format("YYYY-MM-DD HH:mm:ss");
        const startDay = moment().subtract(3, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss");

        this.setState({ dateRange: [new Date(startDay), new Date(endDay)] }, () => {
            this.initReport(this.state.dateRange);
        });
    };

    initReport = (dateRange) => {
        let startDay = moment(dateRange[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss");
        let endDay = moment(dateRange[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss");

        const payload = {
            filter: {
                date: { $gte: startDay, $lte: endDay },
            },
        };
        this.assetService.getDGDailyData(this.serviceID, payload).then((res) => {
            let data = res;
            let dgs = [],
                dgFreq = [],
                dgBattery = [],
                engWaterTemp = [],
                dgLoading = [],
                ebVoltageQuli = [],
                lowOilPressure = [],
                dgOf20Kva = [],
                dgOf30Kva = [],
                dgCurrentData = [],
                dgOffEBVolt = [];

            data.forEach((item) => {
                if (!dgs.includes(item.thingName)) {
                    dgs.push(item.thingName);
                }
            });

            dgs.forEach((item) => {
                let dgFrequency = data.filter((elm) => elm.thingName === item);
                let minFrq = Math.min(...dgFrequency.map((el) => el.MinDGFrequency));
                let maxFrq = Math.max(...dgFrequency.map((el) => el.MaxDGFrequency));

                let rminCurr = Math.min(...dgFrequency.map((el) => el.MinRPhaseCurrent));
                let rmaxCurr = Math.max(...dgFrequency.map((el) => el.MaxRPhaseCurrent));

                let yminCurr = Math.min(...dgFrequency.map((el) => el.MinYPhaseCurrent));
                let ymaxCurr = Math.max(...dgFrequency.map((el) => el.MaxYPhaseCurrent));

                let bminCurr = Math.min(...dgFrequency.map((el) => el.MinBPhaseCurrent));
                let bmaxCurr = Math.max(...dgFrequency.map((el) => el.MaxBPhaseCurrent));

                let avgFrqSum = 0,
                    avgFrq = 0;

                let rPhaseLoadCurrSum = 0,
                    rPhaseLoadCurr = 0;
                let yPhaseLoadCurrSum = 0,
                    yPhaseLoadCurr = 0;
                let bPhaseLoadCurrSum = 0,
                    bPhaseLoadCurr = 0;

                let rPhaseCurrentAvgSum = 0,
                    rPhaseCurrentAvg = 0;
                let yPhaseCurrentAvgSum = 0,
                    yPhaseCurrentAvg = 0;
                let bPhaseCurrentAvgSum = 0,
                    bPhaseCurrentAvg = 0;

                let ebRPhaVoltSum = 0,
                    ebRPhaVolt = 0;
                let ebYPhaVoltSum = 0,
                    ebYPhaVolt = 0;
                let ebBPhaVoltSum = 0,
                    ebBPhaVolt = 0;
                let onOffLimit = 0,
                    switchOnCount = 0;

                let recordCountSum = 0;

                let lowLubOilPressure_count = 0,
                    DGBatteryOOR_Count = 0,
                    EngineWaterTempOOR_Count = 0,
                    outsideLimitCount = 0;

                dgFrequency.forEach((el) => {
                    if (el.NumberofRecords_DGON !== 0) {
                        avgFrqSum += el.AvgDGFrequency * el.NumberofRecords_DGON;
                        rPhaseLoadCurrSum += el.AvgRPhaseCurrent * el.NumberofRecords_DGON;
                        yPhaseLoadCurrSum += el.AvgYPhaseCurrent * el.NumberofRecords_DGON;
                        bPhaseLoadCurrSum += el.AvgBPhaseCurrent * el.NumberofRecords_DGON;
                        rPhaseCurrentAvgSum += el.AvgRPhaseCurrent * el.NumberofRecords_DGON;
                        yPhaseCurrentAvgSum += el.AvgYPhaseCurrent * el.NumberofRecords_DGON;
                        bPhaseCurrentAvgSum += el.AvgBPhaseCurrent * el.NumberofRecords_DGON;
                        ebRPhaVoltSum += el.AvgEBRPhaseVolt * el.NumberofRecords_DGON;
                        ebYPhaVoltSum += el.AvgEBYPhaseVolt * el.NumberofRecords_DGON;
                        ebBPhaVoltSum += el.AvgEBBPhaseVolt * el.NumberofRecords_DGON;
                        recordCountSum += el.NumberofRecords_DGON;
                        outsideLimitCount += el.DGFrequencyOOR_Count;
                        onOffLimit += el.EBVoltOOR_DGOFF_Count;
                        switchOnCount += el.DGSwitchedOnCount;
                        lowLubOilPressure_count += el.LowLubeOilPressureOOR_Count;
                        DGBatteryOOR_Count += el.DGBatteryOOR_Count;
                        EngineWaterTempOOR_Count += el.EngineWaterTempOOR_Count;
                    }
                });
                avgFrq = avgFrqSum / recordCountSum;

                rPhaseLoadCurr = rPhaseLoadCurrSum / recordCountSum;
                yPhaseLoadCurr = yPhaseLoadCurrSum / recordCountSum;
                bPhaseLoadCurr = bPhaseLoadCurrSum / recordCountSum;

                rPhaseCurrentAvg = rPhaseCurrentAvgSum / recordCountSum;
                yPhaseCurrentAvg = yPhaseCurrentAvgSum / recordCountSum;
                bPhaseCurrentAvg = bPhaseCurrentAvgSum / recordCountSum;

                let bPhaseCurrVariation = (Math.abs(rPhaseLoadCurr - bPhaseLoadCurr) * 100) / rPhaseLoadCurr;
                let yPhaseCurrVariation = (Math.abs(rPhaseLoadCurr - yPhaseLoadCurr) * 100) / rPhaseLoadCurr;

                ebRPhaVolt = ebRPhaVoltSum / recordCountSum;
                ebYPhaVolt = ebYPhaVoltSum / recordCountSum;
                ebBPhaVolt = ebBPhaVoltSum / recordCountSum;

                let tempFrqObj = {
                    sl: dgFreq.length + 1,
                    thingName: item,
                    state: dgFrequency[0].state,
                    city: dgFrequency[0].city,
                    frequencyMin: minFrq,
                    frequencyAvg: Number.isNaN(avgFrq) ? 0 : avgFrq.toFixed(2),
                    frequencyMax: maxFrq,
                    frequencyCount: outsideLimitCount,
                };
                dgFreq.push(tempFrqObj);

                let tempLoading = {
                    sl: dgLoading.length + 1,
                    thingName: item,
                    state: dgFrequency[0].state,
                    city: dgFrequency[0].city,
                    rPhaseCurrent: Number.isNaN(rPhaseLoadCurr) ? 0 : rPhaseLoadCurr.toFixed(2),
                    yPhaseCurrent: Number.isNaN(yPhaseLoadCurr) ? 0 : yPhaseLoadCurr.toFixed(2),
                    yPhaseCurrentVariation: Number.isNaN(yPhaseCurrVariation) ? 0 : yPhaseCurrVariation.toFixed(2),
                    bPhaseCurrent: Number.isNaN(bPhaseLoadCurr) ? 0 : bPhaseLoadCurr.toFixed(2),
                    bPhaseCurrentVariation: Number.isNaN(bPhaseCurrVariation) ? 0 : bPhaseCurrVariation.toFixed(2),
                };
                dgLoading.push(tempLoading);

                let ebVoltageQuliTemp = {
                    sl: ebVoltageQuli.length + 1,
                    thingName: item,
                    state: dgFrequency[0].state,
                    city: dgFrequency[0].city,
                    ebRPhaVolt: Number.isNaN(ebRPhaVolt) ? 0 : ebRPhaVolt.toFixed(2),
                    ebYPhaVolt: Number.isNaN(ebYPhaVolt) ? 0 : ebYPhaVolt.toFixed(2),
                    ebBPhaVolt: Number.isNaN(ebBPhaVolt) ? 0 : ebBPhaVolt.toFixed(2),
                    dgOnOff: switchOnCount,
                };
                ebVoltageQuli.push(ebVoltageQuliTemp);

                if (lowLubOilPressure_count > 0) {
                    let i = lowOilPressure.length + 1;
                    let tempObj = {
                        sl: i,
                        thingName: item,
                        state: dgFrequency[0].state,
                        city: dgFrequency[0].city,
                        oilPressureCount: lowLubOilPressure_count,
                    };
                    lowOilPressure.push(tempObj);
                }

                if (DGBatteryOOR_Count > 0) {
                    let i = dgBattery.length + 1;
                    let tempObj = {
                        sl: i,
                        thingName: item,
                        state: dgFrequency[0].state,
                        city: dgFrequency[0].city,
                        DGBatteryVolt: DGBatteryOOR_Count,
                    };
                    dgBattery.push(tempObj);
                }

                if (EngineWaterTempOOR_Count > 0) {
                    let i = engWaterTemp.length + 1;
                    let tempObj = {
                        sl: i,
                        thingName: item,
                        state: dgFrequency[0].state,
                        city: dgFrequency[0].city,
                        waterTempCount: EngineWaterTempOOR_Count,
                    };
                    engWaterTemp.push(tempObj);
                }

                if (onOffLimit > 0) {
                    let i = dgOffEBVolt.length + 1;
                    let tempObj = {
                        sl: i,
                        thingName: item,
                        state: dgFrequency[0].state,
                        city: dgFrequency[0].city,
                        ebRPhaVolt: Number.isNaN(ebRPhaVolt) ? 0 : ebRPhaVolt.toFixed(2),
                        ebYPhaVolt: Number.isNaN(ebYPhaVolt) ? 0 : ebYPhaVolt.toFixed(2),
                        ebBPhaVolt: Number.isNaN(ebBPhaVolt) ? 0 : ebBPhaVolt.toFixed(2),
                        dgOnOff: onOffLimit,
                    };
                    dgOffEBVolt.push(tempObj);
                }

                dgCurrentData.push({
                    thingName: item,
                    state: dgFrequency[0].state,
                    city: dgFrequency[0].city,
                    size: dgFrequency[0].size,
                    rPhaCurrMin: rminCurr,
                    rPhaCurrAvg: Number.isNaN(rPhaseCurrentAvg) ? 0 : rPhaseCurrentAvg.toFixed(2),
                    rPhaCurrMax: rmaxCurr,
                    yPhaCurrMin: yminCurr,
                    yPhaCurrAvg: Number.isNaN(rPhaseCurrentAvg) ? 0 : yPhaseCurrentAvg.toFixed(2),
                    yPhaCurrMax: ymaxCurr,
                    bPhaCurrMin: bminCurr,
                    bPhaCurrAvg: Number.isNaN(rPhaseCurrentAvg) ? 0 : bPhaseCurrentAvg.toFixed(2),
                    bPhaCurrMax: bmaxCurr,
                });

                dgOf20Kva = dgCurrentData.filter((el) => el.size === "20kva" || el.size === "20-kva");
                dgOf30Kva = dgCurrentData.filter((el) => el.size === "30kva" || el.size === "30-kva");
            });

            this.setState({
                dgFreq,
                dgBattery,
                dgLoading,
                engWaterTemp,
                ebVoltageQuli,
                lowOilPressure,
                dgOf20Kva,
                dgOf30Kva,
                dgOffEBVolt,
                data,
            });
        });
    };

    onDateChange = (e) => {
        this.setState({ dateRange: e.value }, () => {
            if (this.state.dateRange[1] !== null) {
                this.initReport(this.state.dateRange);
            }
        });
    };

    getRTableLable = (item) => {
        if (item.size === "20kva" || item.size === "20-kva") {
            if (item.rPhaCurrAvg >= 7 && item.rPhaCurrAvg <= 21) {
                return <span style={{ backgroundColor: "#16CD00", width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.rPhaCurrAvg}</span>;
            } else if (item.rPhaCurrAvg < 7) {
                return <span style={{ backgroundColor: "#F1DF03", width: "100%", color: "#000", display: "inline-block", lineHeight: "3" }}>{item.rPhaCurrAvg}</span>;
            } else if (item.rPhaCurrAvg > 21) {
                return <span style={{ backgroundColor: "#F93505", width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.rPhaCurrAvg}</span>;
            }
        } else if (item.size === "30kva" || item.size === "30-kva") {
            if (item.rPhaCurrAvg >= 10 && item.rPhaCurrAvg <= 32) {
                return <span style={{ backgroundColor: "#16CD00", width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.rPhaCurrAvg}</span>;
            } else if (item.rPhaCurrAvg < 10) {
                return <span style={{ backgroundColor: "#F1DF03", width: "100%", color: "#000", display: "inline-block", lineHeight: "3" }}>{item.rPhaCurrAvg}</span>;
            } else if (item.rPhaCurrAvg > 32) {
                return <span style={{ backgroundColor: "#F93505", width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.rPhaCurrAvg}</span>;
            }
        }
    };

    getYTableLable = (item) => {
        if (item.size === "20kva" || item.size === "20-kva") {
            if (item.yPhaCurrAvg >= 7 && item.yPhaCurrAvg <= 21) {
                return <span style={{ backgroundColor: "#16CD00", width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.yPhaCurrAvg}</span>;
            } else if (item.yPhaCurrAvg < 7) {
                return <span style={{ backgroundColor: "#F1DF03", width: "100%", color: "#000", display: "inline-block", lineHeight: "3" }}>{item.yPhaCurrAvg}</span>;
            } else if (item.yPhaCurrAvg > 21) {
                return <span style={{ backgroundColor: "#F93505", width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.yPhaCurrAvg}</span>;
            }
        } else if (item.size === "30kva" || item.size === "30-kva") {
            if (item.yPhaCurrAvg >= 10 && item.yPhaCurrAvg <= 32) {
                return <span style={{ backgroundColor: "#16CD00", width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.yPhaCurrAvg}</span>;
            } else if (item.yPhaCurrAvg < 10) {
                return <span style={{ backgroundColor: "#F1DF03", width: "100%", color: "#000", display: "inline-block", lineHeight: "3" }}>{item.yPhaCurrAvg}</span>;
            } else if (item.yPhaCurrAvg > 32) {
                return <span style={{ backgroundColor: "#F93505", width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.yPhaCurrAvg}</span>;
            }
        }
    };

    getBTableLable = (item) => {
        if (item.size === "20kva" || item.size === "20-kva") {
            if (item.bPhaCurrAvg >= 7 && item.bPhaCurrAvg <= 21) {
                return <span style={{ backgroundColor: "#16CD00", width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.bPhaCurrAvg}</span>;
            } else if (item.bPhaCurrAvg < 7) {
                return <span style={{ backgroundColor: "#F1DF03", width: "100%", color: "#000", display: "inline-block", lineHeight: "3" }}>{item.bPhaCurrAvg}</span>;
            } else if (item.bPhaCurrAvg > 21) {
                return <span style={{ backgroundColor: "#F93505", width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.bPhaCurrAvg}</span>;
            }
        } else if (item.size === "30kva" || item.size === "30-kva") {
            if (item.bPhaCurrAvg >= 10 && item.bPhaCurrAvg <= 32) {
                return <span style={{ backgroundColor: "#16CD00", width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.bPhaCurrAvg}</span>;
            } else if (item.bPhaCurrAvg < 10) {
                return <span style={{ backgroundColor: "#F1DF03", width: "100%", color: "#000", display: "inline-block", lineHeight: "3" }}>{item.bPhaCurrAvg}</span>;
            } else if (item.bPhaCurrAvg > 32) {
                return <span style={{ backgroundColor: "#F93505", width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.bPhaCurrAvg}</span>;
            }
        }
    };

    onOilPressureSelectDg = (e) => {
        // let brakeUpData = this.state.data.filter((elm) => elm.thingName === e.value.thingName);
        this.setState({ selectedDg: e.value });
    };

    onHide(name) {
        this.setState({
            [`${name}`]: false,
        });
    }

    onSelectLowOilPressure = (e) => {
        let item = e.value;
        let thingName = item.thingName;
        let startDay = this.state.dateRange[0];
        let endDay = this.state.dateRange[1];

        const payload = {
            filter: {
                thingName: thingName,
                ts: { $gte: new Date(startDay), $lte: new Date(endDay) },
            },
        };
        let title = thingName + " DG Low Lube Oil Pressure from " + moment(startDay).format("Do MMM, YYYY") + " to " + moment(endDay).format("Do MMM, YYYY");
        let lubeOilBrakeupData = [];
        this.assetService.getData(this.serviceID, payload).then((res) => {
            // let data = res[0].filter(item => item.thingName === thingName && item.LowLubeOilPressureOOR_Count > 0);
            lubeOilBrakeupData = res.filter((elm) => elm.DGStatus === 1 && elm.LubeOilPressure < 3);
            this.setState({ lowOilPressureThing: item, lubeOilDialog: true, title, lubeOilBrakeupData });
        });
    };

    onSelectEbVolt = (e) => {
        let item = e.value;
        let thingName = item.thingName;
        let startDay = this.state.dateRange[0];
        let endDay = this.state.dateRange[1];

        const payload = {
            filter: {
                thingName: thingName,
                ts: { $gte: new Date(startDay), $lte: new Date(endDay) },
            },
        };
        let title = thingName + " DG EB Volt " + moment(startDay).format("Do MMM, YYYY") + " to " + moment(endDay).format("Do MMM, YYYY");
        let ebVoltBrakeupData = [];
        this.assetService.getData(this.serviceID, payload).then((res) => {
            // let data = res[0].filter(item => item.thingName === thingName && item.LowLubeOilPressureOOR_Count > 0);
            ebVoltBrakeupData = res.filter((elm) => elm.DGStatus === 0 && (elm["EB-R-PhaseVolt"] < 170 || elm["EB-R-PhaseVolt"] > 270 || elm["EB-Y-PhaseVolt"] < 170 || elm["EB-Y-PhaseVolt"] > 270 || elm["EB-B-PhaseVolt"] < 170 || elm["EB-B-PhaseVolt"] > 270));
            // console.log(ebVoltBrakeupData);
            this.setState({ dgOffEBVoltThing: item, title, ebVoltDialog: true, ebVoltBrakeupData });
        });
    };

    getUserFormatDate = (item) => {
        return moment(item.ts).format("Do MMM, YYYY HH:mm");
    };

    render = () => {
        return (
            <div className="grid">
                <div className="col-12 ">
                    {this.state.dateRange ? (
                        <div className="grid">
                            <div className="field lg:col-offset-9 col-12 md:col-6 lg:col-3 mt-3">
                                <label htmlFor="date">Select Date Range</label>
                                <Calendar id="range" value={this.state.dateRange} onChange={this.onDateChange} selectionMode="range" showIcon readOnlyInput className="w-full bg-white" />
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="col-12">
                    <div className="grid">
                        <div className="col-12 lg:col-12">
                            {this.state.dgOf20Kva ? (
                                <div className="card">
                                    <div className="flex flex-wrap align-items-center justify-content-between">
                                        <h3 className="capitalize">Utilization of 20-KVA DGs</h3>
                                        <div className="flex align-items-center">
                                            <div className="mx-2">
                                                <span className="crate green"></span> 7-21 amp(Normal)
                                            </div>
                                            <div className="mx-2">
                                                <span className="crate yellow"></span> &#60; 7 amp(Under Utilized)
                                            </div>
                                            <div className="mx-2">
                                                <span className="crate red"></span> &#62; 21 amp(Over Utilized)
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable value={this.state.dgOf20Kva} headerColumnGroup={this.dgOfSizeKvaHeaderGroup} paginator responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} showGridlines>
                                        {/* <Column field="sl" className="font-semibold"></Column> */}
                                        <Column field="thingName" className="font-semibold"></Column>
                                        <Column field="state" className="white-space-nowrap"></Column>
                                        <Column field="city" className="white-space-nowrap"></Column>
                                        <Column field="rPhaCurrMin" className="white-space-nowrap text-center"></Column>
                                        <Column body={this.getRTableLable} className="white-space-nowrap p-0 text-center"></Column>
                                        <Column field="rPhaCurrMax" className="white-space-nowrap text-center"></Column>
                                        <Column field="yPhaCurrMin" className="white-space-nowrap text-center"></Column>
                                        <Column body={this.getYTableLable} className="white-space-nowrap p-0 text-center"></Column>
                                        <Column field="yPhaCurrMax" className="white-space-nowrap text-center"></Column>
                                        <Column field="bPhaCurrMin" className="white-space-nowrap text-center"></Column>
                                        <Column body={this.getBTableLable} className="white-space-nowrap p-0 text-center"></Column>
                                        <Column field="bPhaCurrMax" className="white-space-nowrap text-center"></Column>
                                    </DataTable>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="grid">
                        <div className="col-12 lg:col-12">
                            {this.state.dgOf30Kva ? (
                                <div className="card">
                                    <div className="flex flex-wrap align-items-center justify-content-between">
                                        <h3 className="capitalize">Utilization of 30-KVA DGs</h3>
                                        <div className="flex align-items-center">
                                            <div className="mx-2">
                                                <span className="crate green"></span> 10-32 amp(Normal)
                                            </div>
                                            <div className="mx-2">
                                                <span className="crate yellow"></span> &#60; 10 amp(Under Utilized)
                                            </div>
                                            <div className="mx-2">
                                                <span className="crate red"></span> &#62; 32 amp(Over Utilized)
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable value={this.state.dgOf30Kva} headerColumnGroup={this.dgOfSizeKvaHeaderGroup} paginator responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} showGridlines>
                                        {/* <Column field="sl" className="font-semibold"></Column> */}
                                        <Column field="thingName" className="font-semibold"></Column>
                                        <Column field="state" className="white-space-nowrap"></Column>
                                        <Column field="city" className="white-space-nowrap"></Column>
                                        <Column field="rPhaCurrMin" className="white-space-nowrap text-center"></Column>
                                        <Column body={this.getRTableLable} className="white-space-nowrap p-0 text-center"></Column>
                                        <Column field="rPhaCurrMax" className="white-space-nowrap text-center"></Column>
                                        <Column field="yPhaCurrMin" className="white-space-nowrap text-center"></Column>
                                        <Column body={this.getYTableLable} className="white-space-nowrap p-0 text-center"></Column>
                                        <Column field="yPhaCurrMax" className="white-space-nowrap text-center"></Column>
                                        <Column field="bPhaCurrMin" className="white-space-nowrap text-center"></Column>
                                        <Column body={this.getBTableLable} className="white-space-nowrap p-0 text-center"></Column>
                                        <Column field="bPhaCurrMax" className="white-space-nowrap text-center"></Column>
                                    </DataTable>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    {this.state.dgFreq ? (
                        <div className="card">
                            <h3 className="capitalize">DG Frequency</h3>
                            <DataTable value={this.state.dgFreq} headerColumnGroup={this.headerGroup} paginator responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} showGridlines>
                                <Column field="sl" className="font-semibold"></Column>
                                <Column field="thingName" className="font-semibold"></Column>
                                <Column field="state" className="white-space-nowrap"></Column>
                                <Column field="city" className="white-space-nowrap"></Column>
                                <Column field="frequencyMin" className="white-space-nowrap"></Column>
                                <Column field="frequencyAvg" className="white-space-nowrap"></Column>
                                <Column field="frequencyMax" className="white-space-nowrap"></Column>
                                <Column field="frequencyCount" className="white-space-nowrap"></Column>
                            </DataTable>
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-6">
                    {this.state.dgBattery ? (
                        <div className="card">
                            <h3 className="capitalize">DG Battery</h3>
                            <DataTable value={this.state.dgBattery} headerColumnGroup={this.dgBatteryHeaderGroup} paginator responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} showGridlines>
                                <Column field="sl" className="font-semibold"></Column>
                                <Column field="thingName" className="font-semibold"></Column>
                                <Column field="state" className="white-space-nowrap"></Column>
                                <Column field="city" className="white-space-nowrap"></Column>
                                <Column field="DGBatteryVolt" className="white-space-nowrap"></Column>
                            </DataTable>
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-6">
                    {this.state.engWaterTemp ? (
                        <div className="card">
                            <h3 className="capitalize">Engine Water Temperature</h3>
                            <DataTable value={this.state.engWaterTemp} headerColumnGroup={this.engWaterTempHeaderGroup} paginator responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} showGridlines>
                                <Column field="sl" className="font-semibold"></Column>
                                <Column field="thingName" className="font-semibold"></Column>
                                <Column field="state" className="white-space-nowrap"></Column>
                                <Column field="city" className="white-space-nowrap"></Column>
                                <Column field="waterTempCount" className="white-space-nowrap"></Column>
                            </DataTable>
                        </div>
                    ) : null}
                </div>
                <div className="col-12">
                    {this.state.dgLoading ? (
                        <div className="card">
                            <h3 className="capitalize">DG Loading</h3>
                            <DataTable value={this.state.dgLoading} headerColumnGroup={this.dgLoadingHeaderGroup} paginator responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} showGridlines>
                                <Column field="sl" className="font-semibold"></Column>
                                <Column field="thingName" className="font-semibold"></Column>
                                <Column field="state" className="white-space-nowrap"></Column>
                                <Column field="city" className="white-space-nowrap"></Column>
                                <Column field="rPhaseCurrent" className="white-space-nowrap"></Column>
                                <Column field="yPhaseCurrent" className="white-space-nowrap"></Column>
                                <Column field="yPhaseCurrentVariation" className="white-space-nowrap"></Column>
                                <Column field="bPhaseCurrent" className="white-space-nowrap"></Column>
                                <Column field="bPhaseCurrentVariation" className="white-space-nowrap"></Column>
                            </DataTable>
                        </div>
                    ) : null}
                </div>
                <div className="col-12">
                    {this.state.ebVoltageQuli ? (
                        <div className="card">
                            <h3 className="capitalize">EB Voltage Quality</h3>
                            <DataTable value={this.state.ebVoltageQuli} headerColumnGroup={this.ebVoltageQuliHeaderGroup} paginator responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} showGridlines>
                                <Column field="sl" className="font-semibold"></Column>
                                <Column field="thingName" className="font-semibold"></Column>
                                <Column field="state" className="white-space-nowrap"></Column>
                                <Column field="city" className="white-space-nowrap"></Column>
                                <Column field="ebRPhaVolt" className="white-space-nowrap"></Column>
                                <Column field="ebYPhaVolt" className="white-space-nowrap"></Column>
                                <Column field="ebBPhaVolt" className="white-space-nowrap"></Column>
                                <Column field="dgOnOff" className="white-space-nowrap"></Column>
                            </DataTable>
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-5">
                    {this.state.lowOilPressure ? (
                        <div className="card">
                            <h3 className="capitalize">Low Lube Oil Pressure (No. of instances)</h3>
                            <DataTable value={this.state.lowOilPressure} headerColumnGroup={this.lowOilPressureHeaderGroup} paginator responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} showGridlines>
                                <Column field="sl" className="font-semibold"></Column>
                                <Column field="thingName" className="font-semibold"></Column>
                                <Column field="state" className="white-space-nowrap"></Column>
                                <Column field="city" className="white-space-nowrap"></Column>
                                <Column field="oilPressureCount" className="white-space-nowrap"></Column>
                            </DataTable>
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-7">
                    <Dialog header={this.state.title} visible={this.state.ebVoltDialog} style={{ width: "70vw" }} onHide={() => this.onHide("ebVoltDialog")}>
                        <DataTable value={this.state.ebVoltBrakeupData} headerColumnGroup={this.ebVoltBrakeupDataDataHeaderGroup} paginator responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} showGridlines>
                            <Column field="thingName" className="font-semibold"></Column>
                            <Column field="state" className="white-space-nowrap"></Column>
                            <Column field="city" className="white-space-nowrap"></Column>
                            <Column field="EB-R-PhaseVolt" className="white-space-nowrap"></Column>
                            <Column field="EB-Y-PhaseVolt" className="white-space-nowrap"></Column>
                            <Column field="EB-B-PhaseVolt" className="white-space-nowrap"></Column>
                            <Column body={this.getUserFormatDate} className="white-space-nowrap"></Column>
                        </DataTable>
                    </Dialog>
                    {this.state.dgOffEBVolt ? (
                        <div className="card">
                            <h3 className="capitalize">DG Off When EB Volt &#60; 170 or &#62; 270 Volts </h3>
                            <DataTable
                                value={this.state.dgOffEBVolt}
                                headerColumnGroup={this.dgOffEBVoltHeaderGroup}
                                paginator
                                responsiveLayout="scroll"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                rows={10}
                                showGridlines
                                selectionMode="single"
                                selection={this.state.dgOffEBVoltThing}
                                onSelectionChange={this.onSelectEbVolt}
                                dataKey="thingName"
                            >
                                <Column field="sl" className="font-semibold"></Column>
                                <Column field="thingName" className="font-semibold"></Column>
                                <Column field="state" className="white-space-nowrap"></Column>
                                <Column field="city" className="white-space-nowrap"></Column>
                                <Column field="ebRPhaVolt" className="white-space-nowrap"></Column>
                                <Column field="ebYPhaVolt" className="white-space-nowrap"></Column>
                                <Column field="ebBPhaVolt" className="white-space-nowrap"></Column>
                                <Column field="dgOnOff" className="white-space-nowrap"></Column>
                            </DataTable>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };
}

export default PerformanceDashboard;
