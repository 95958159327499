import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";

const FuelLebelIgnitionChart = ({ chartData }) => {
    const [state, setState] = useState(null);

    useEffect(() => {
        const option = {
            chart: {
                // type: "spline",
                zoomType: "x",
                backgroundColor: "transparent",
            },
            title: {
                text: "",
                style: chartHeadingStyle,
            },
            accessibility: {
                screenReaderSection: {
                    beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                },
            },
            tooltip: {
                valueDecimals: 2,
            },
            xAxis: {
                categories: [],
                labels: {
                    style: {
                        color: "#86969F",
                    },
                },
            },
            yAxis: {
                min: 0,
                stackLabels: {
                    enabled: false,
                },
                labels: {
                    style: {
                        color: colorPalettes.color2,
                    },
                },
                title: {
                    text: "Fuel Level",
                    style: {
                        color: colorPalettes.color2,
                    },
                },
            },
            tooltip: {
                formatter: function () {
                    return this.points.reduce(function (s, point) {
                        let lable = point.series.name === "Ignition" ? (point.y === 100 ? "ON" : "OFF") : point.y;
                        return s + "<br/>" + point.series.name + ": " + lable;
                    }, "<b>" + this.x + "</b>");
                },
                // pointFormat: `<span style="color:{series.color}">{series.name}</span>: <b>${series.name === "Ignition" ? (point.y === 100 ? `ON` : `OFF`) : point.y}</b><br/>`,
                shared: true,
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false,
                    },
                    pointStart: 0,
                    borderRadius: 5,
                    pointWidth: 9,
                    borderWidth: 0,
                },
            },
            series: [],
        };
        option.series = chartData.data;
        option.xAxis.categories = chartData.category;
        option.title.text = chartData.title;
        setState(option);
    }, [chartData]);

    return state ? <HighchartsReact highcharts={Highcharts} options={state} /> : null;
};

export default FuelLebelIgnitionChart;
