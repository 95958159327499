import { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import dbservices from "../../service/dbService";
import { userFSAccess } from "../../utilities/constant";
import Unauth from "./components/Unauth";
import { userRoleMap } from "../../utilities/utillFunction";

const FileDashboard = () => {
    const toast = useRef(null);
    const service = JSON.parse(localStorage.getItem("service"));
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const serviceId = localStorage.getItem("serviceId");
    const [access, setAccess] = useState(null);
    const [myRole, setMyRole] = useState(null);
    const [projects, setProjects] = useState(null);
    const [events, setEvents] = useState(null);
    const [status, setStatus] = useState(null);
    const [unauthorized, setUnauthorized] = useState(false);

    useEffect(() => {
        let accessID = getAccessId(permissions, service.links);
        if (accessID === "unauthorized") {
            setUnauthorized(true);
        } else {
            setAccess(accessID);
            getProjectsList(accessID);
        }
    }, []);

    function getAccessId(per, links) {
        let myAccessPermision = per.filter((value) => userFSAccess.includes(value));

        if (myAccessPermision.length) {
            let role = per[0].split("_")[0];
            setMyRole(role);
            return links[`${role}_WRITE`];
        } else {
            return "unauthorized";
        }
    }

    function getProjectsList(accessID) {
        Promise.all([dbservices.getProjectList(accessID), dbservices.getEventCount(serviceId), dbservices.getStatusWiseEventCount(serviceId)])
            .then((res) => {
                if (res[0] && res[0].status === "ok") {
                    setProjects(res[0].results);
                }
                setEvents(res[1]);
                setStatus(res[2]);
            })
            .catch((err) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
            });
    }
    return !unauthorized ? (
        <div className="grid">
            <Toast ref={toast} />
            {projects && (
                <div className="col-12 lg:col-3 md:col-6 sm:col-3 xs:col-12" style={{ padding: "0.5rem" }}>
                    <div className="color3-bg card ">
                        <div>
                            <h5 className="mb-3 text-2xl">Total Project</h5>
                            <h5 className="font-medium text-2xl color3-bg mb-0">{projects.length}</h5>
                        </div>
                    </div>
                </div>
            )}

            {events &&
                events.map((item, index) => (
                    <div className="col-12 lg:col-3 md:col-6 sm:col-3 xs:col-12" style={{ padding: "0.5rem" }} key={`ev-${index}`}>
                        <div className={`color${index + 1}-bg card`}>
                            <div>
                                {myRole === "UserManagement.READ" ? <h5 className="mb-3 text-2xl">{userRoleMap(service, item._id)}</h5> : <h5 className="mb-3 text-2xl">{item._id === myRole ? "My Events" : "Others Events"}</h5>}

                                <h5 className={`font-medium text-2xl color${index + 1}-bg mb-0`}>{item.count}</h5>
                            </div>
                        </div>
                    </div>
                ))}

            {status && (
                <div className="col-12 mt-3">
                    <h3>Status wise events</h3>
                    <hr />
                    <div className="grid">
                        {status.map((item, index) => (
                            <div className="col-12 lg:col-3 md:col-6 sm:col-3 xs:col-12" style={{ padding: "0.5rem" }} key={`ev-${index}`}>
                                <div className={`color${index + 1}-bg card`}>
                                    <div>
                                        <h5 className="mb-3 text-2xl">{item._id}</h5>

                                        <h5 className={`font-medium text-2xl color${index + 1}-bg mb-0`}>{item.count}</h5>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    ) : (
        <Unauth />
    );
};

export default FileDashboard;
