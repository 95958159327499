import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import XLSX from "sheetjs-style";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import publisherService from "../../service/publisherService";
import { barChartColors, pieChartColors, piechartOptions, barChartOptions } from "../../utilities/constant";
import { Chart } from "primereact/chart";
import { Tag } from 'primereact/tag';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Calendar } from "primereact/calendar";
import { Badge } from "primereact/badge";
import DBservice from "../../service/dbService";
import { PUBLISHERS_CHANNEL } from "../../lib/constants"
import { Tooltip } from 'primereact/tooltip';

const LcixDashboard = () => {
    const serviceID = localStorage.getItem("serviceId");
    const [totalCuSalesAndRevenue, setTotalCuSalesAndRevenue] = useState(null);
    const [salesForTheDay, setsalesForTheDay] = useState(null);
    const [salesForTheMonth, setsalesForTheMonth] = useState(null);
    const [report, setReport] = useState([]);
    const [isbnQtyPieData, setIsbnQtyPieData] = useState(null);
    const [isbnSalesBar, setIsbnSalesBar] = useState(null);
    const [highchartPieOption, setHighchartPieOption] = useState(null);
    const [listedISBN, setListedISBNData] = useState(null);
    const [dateRange, setDateRange] = useState(null);
    const [todayDt, setTodaysDt] = useState(null);
    const [usdPerInr, setUSDPerInr] = useState(null);
    const [channelCountMap, setChanneCountMap] = useState(null);
    const [isbnOrders, setIsbnOrders] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [selectedISBN, setSelectedISBN] = useState(null)
    const [allIsbnMapdata, setAllIsbnMap] = useState({})
    const [refreshDate, setRefreshDate] = useState(null)
    const [totalBooksQuantity, setTotalBooksQty] = useState(0)
    useEffect(() => {
        const todayStart = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
        const todayEnd = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
        const startofMonth = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
        const endofMonth = moment().endOf("month").format("YYYY-MM-DD HH:mm:ss");

        const endDate = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
        const startDate = moment().subtract(180, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss");

        let dateRange = [new Date(startDate), new Date(endDate)];
        setDateRange(dateRange);
        setTodaysDt(new Date(todayEnd));
        Promise.all([
            publisherService.getTotalCumulativeSalesAndRevenue(serviceID),
            publisherService.getSalesForRangeUSD(serviceID, todayStart, todayEnd),
            publisherService.getSalesForRangeUSD(serviceID, startofMonth, endofMonth),
            publisherService.getRangeReport(serviceID, startDate, endDate),
            publisherService.getISBNWiseTotalCumulativeSales(serviceID),
            publisherService.getListedIsbns(serviceID),
            DBservice.getTodaysExchangeRate(),
            publisherService.getChannelWiseData(serviceID),
            publisherService.getAllIsbns(serviceID),
            publisherService.getRefreshDate(serviceID)
        ]).then((res) => {
            const totalCuSalesAndRevenue = res[0][0];
            const salesForTheDay = res[1];
            const salseForTheMonth = res[2];

            let isbnWiseData = res[4];
            let listedISBNData = res[5][0];
            if (res[6] && res[6].length) {
                let usdPerINR = res[6][0].rate['INR'];
                setUSDPerInr(usdPerINR);
            }

            let channelWisedata = res[7];
            let channelMap = {};
            if (channelWisedata && channelWisedata.length) {
                channelWisedata.forEach(a => {
                    if((a._id.toLowerCase() === "other channels") || (a._id.toLowerCase() === "bookscape")){
                        let count = channelMap["bookscape"] || 0
                        channelMap["bookscape"] = count + a.count
                    }
                    channelMap[a._id.toLowerCase()] = a.count
                })
            }
            let allIsbns = res[8], allIsbnMap = {};
            if(allIsbns && allIsbns.length){
                allIsbns.map(b => {
                    allIsbnMap[b.isbn] = structuredName(b.title);
                });
            }

            const reportData = res[3].map((item, index) => ({
                sl: index + 1,
                ...item,
                isbn: item._id,
                orderChannel: item.channel ? item.channel.join(",") :  "",
                revenue: "30",
                publisher: "LCIX",
                title: allIsbnMap[item._id]
            }));

            let salesArray = [], revenueArr = [], isbns = [], isbnQty = [], totalBooksQty = 0;
            isbnWiseData.forEach((d, i) => {
                salesArray.push(d.salesUSD);
                revenueArr.push(d.commUSD);
                totalBooksQty += d.totalQty
                let bookName = allIsbnMap[d._id]
                isbns.push(bookName)
                isbnQty.push({
                    name: bookName,
                    y: d.totalQty,
                    color: pieChartColors[i]
                })
            })

            let datasetArray = [{ fill: true, label: "Sales", backgroundColor: barChartColors[0], borderColor: barChartColors[0], data: salesArray },
            { fill: true, label: "Revenue", backgroundColor: barChartColors[1], borderColor: barChartColors[1], data: revenueArr }]

            let pieOption = {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: "ISBN wise Quantity Sold",
                    style: {
                        fontSize: "21px",
                        fontWeight: "700",
                        fontFamily: "Roboto"
                    },
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y}</b>",
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format: "<b>{point.name}</b>: {point.y}",
                            style: {
                                fontSize: "11px",
                            },
                        },
                        showInLegend: false,
                    },
                },
                series: [
                    {
                        name: "",
                        colorByPoint: true,
                        data: isbnQty
                    },
                ],
            };

            const IsbnQtyPie = {
                labels: isbns,
                datasets: [
                    {
                        data: isbnWiseData.map(i => { return i.totalQty }),
                        backgroundColor: pieChartColors,
                        // borderColor: "rgba(160, 167, 181, .3)"
                    },
                ],
            };
            if(res[9] && res[9].length){
                let date = new Date(res[9][0].ts);
                setRefreshDate(moment(date).format("MM-DD-YYYY HH:mm"))
            }
            setChanneCountMap(channelMap);
            setListedISBNData(listedISBNData);
            setTotalCuSalesAndRevenue(totalCuSalesAndRevenue);
            setsalesForTheDay(salesForTheDay[0]);
            setsalesForTheMonth(salseForTheMonth[0]);
            setReport(reportData);
            setIsbnQtyPieData(IsbnQtyPie);
            setIsbnSalesBar({
                labels: isbns, datasets: datasetArray
            })
            setHighchartPieOption(pieOption)
            setAllIsbnMap(allIsbnMap)
            setTotalBooksQty(totalBooksQty)
        });
    }, []);
    const header = (
        <ColumnGroup>
            <Row>
                <Column header="Sl" className="color1-bg"></Column>
                <Column header="ISBN" className="color1-bg"></Column>
                <Column header="Publisher" className="color1-bg"></Column>
                <Column header="Title" className="color1-bg"></Column>
                <Column header="Sales Channel" className="color1-bg"></Column>
                <Column header="Qty. Sold" className="color1-bg"></Column>
                <Column header="Total MSRP (INR)" className="color1-bg"></Column>
                <Column header="Total MSRP (USD)" className="color1-bg"></Column>
                <Column header="Total Returned (INR)" className="color1-bg"></Column>
                <Column header="Total Returned (USD)" className="color1-bg"></Column>
                <Column header="Revenue Share Model ( % / Fixed)" className="color1-bg"></Column>
                <Column header="Revenue Share (USD)" className="color1-bg"></Column>
                <Column header="Credits (USD)" className="color1-bg"></Column>
            </Row>
        </ColumnGroup>
    )
    const structuredName = (title) =>{
        if(title.includes("Grumbalina")){
            title = title.split(" ").splice(2).join(" ")
            return title
        }
        else{
            return title
        }
    }

    const totalInrTemplate = (item, key) => {
        return (item[key] ? new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(item[key]) : "");
    }
    const totalUsdTemplate = (item, key) => {
        return (item[key] ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(item[key]) : "");
    }
    const salesUSD = (item) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(item.totalSalesUSD);
    }
    const handleDateChange = (e) => {
        setDateRange(e.value);
    }
    useEffect(async () => {
        if (dateRange) {
            let startDate = moment(dateRange[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss");
            let endDate = moment(dateRange[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss");
            let res = await publisherService.getRangeReport(serviceID, startDate, endDate)
            const reportData = res.map((item, index) => ({

                sl: index + 1,
                ...item,
                isbn: item._id,
                orderChannel: item.channel ? (item.channel.includes("bookscape") && item.channel.includes("other channels")) ? item.channel.filter((a)=>(a !== "other channels")).join(",") : item.channel.join(",").replace("other channels", "bookscape") : "",
                revenue: "30",
                publisher: "LCIX",
            }));
            setReport(reportData);
        }
    }, [dateRange])

    const isbnClick = async (e,isbn) =>{
        let res = await publisherService.getISBNOrdersData(serviceID, Number(e.target.innerText))
        const ISBNOrdersData = res.map((item, index) => ({
            sl: index + 1,
            ...item,
        }));
        if(res && res.length){
            setIsbnOrders(ISBNOrdersData)
            setShowModal(true)
            setSelectedISBN(isbn)
        }
    }

    const getHeader = () => {
        return <div className="flex align-items-center"><Button icon="pi pi-download" tooltip="Click to download" className="p-button-rounded mr-2 mb-2" onClick={() => onDownload()}></Button><span style={{display:"inlineBlock",verticalAlign:"middle"}}>ISBN - {selectedISBN}</span></div>;
    };
    const onDownload = () => {
        let exportData = [];
        exportData.push(["SL", "Order No", "Order Date", "Order Status","Channnel","Total Quantity", "Sales in RS", "Sales in USD"]);
        isbnOrders.forEach((e,i) => {
            exportData.push([i+1,e.orderNo, e.orderDt, e.OrderStatus, e.channel,e.totalQuantity,e.salesINR, e.salesUSD]);
        });
        var workbook = XLSX.utils.book_new(),
            worksheet = XLSX.utils.aoa_to_sheet(exportData);
        workbook.SheetNames.push("First");
        workbook.Sheets["First"] = worksheet;
        XLSX.writeFile(workbook, "ordersByISBN.xlsx");
    };
    return (
        <div className="grid" style={{ justifyContent: "space-around" }}>
            {refreshDate ? <div className=" col-12 grid">
                <div className="col-12 lg:col-4 md:col-4 lg:col-offset-8 md:col-offset-8">
                    <div className="flex justify-content-around">
                        <h5 className="m-0 text-2xl">Last Refresh : </h5>
                        <div className="font-medium text-2xl">{refreshDate}</div>
                    </div>
                </div>
            </div> : null}
            <div className="col-12 grid">
                <div className="col-12 lg:col-2 md:col-6 sm:col-6 xs:col-6">
                    <div className="card " style={{ paddingBottom: "0" }}>
                        <div className="grid">
                            <div className="lg:col-12">
                                <h5 className='mb-2 '>Titles Licensed <Badge className=" color3-bg text-xl" value={listedISBN ? listedISBN.total : "0"}></Badge> </h5>
                                <h5>
                                    Titles Listed
                                    &nbsp;<Badge className=" color3-bg text-xl" value={listedISBN ? listedISBN.listed : "0"}></Badge>
                                </h5>
                            </div>
                            {/* <div className="lg:col-4">
                                {usdPerInr ? <h5 className='mb-3 text-xl '>{"$ - "} <span>&#8377;</span>
                                    <div><Tag className=" color5-bg text-xl" style={{ padding: "0.5rem" }} value={usdPerInr.toFixed(2)}></Tag></div> </h5> : null}

                            </div> */}
                        </div>
                    </div>

                </div>
                <div className="col-12 lg:col-2 md:col-6 sm:col-6 xs:col-6">
                    <div className="card" >
                        {usdPerInr ? <h5 style={{ marginBottom: "0" }} className=' text-xl '>{"Spot Rate :  USD-INR"}
                            <div style={{ marginTop: "0.5rem" }}><Tag className=" color5-bg text-xl" style={{ padding: "0.5rem" }} value={usdPerInr.toFixed(2)}></Tag></div> </h5> : null}
                    </div>

                </div>
                <div className="col-12 lg:col-4 md:col-6 sm:col-6 xs:col-6">
                    <div className="card color1-bg">
                        <h5 className='mb-1 text-2xl'>Total Cumulative Sales</h5>
                        <div className="flex justify-content-between">
                            <h5 className="text-2xl mt-0 mb-2">Quantity</h5>
                            <h5 className="text-2xl mt-0 mr-2 mb-2">Amount</h5>
                        </div>
                        <div className='flex flex-wrap justify-content-between '>
                            <div className='font-medium text-2xl color1-bg'>
                                {totalBooksQuantity}
                            </div>
                            <div className='font-medium text-2xl color1-bg' style={{ float: "right" }}>
                                {new Intl.NumberFormat("en-US", { style: "currency", currency: "INR" }).format(totalCuSalesAndRevenue ? totalCuSalesAndRevenue.totalSalesINR.toFixed(2) : 0)} / {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalCuSalesAndRevenue ? totalCuSalesAndRevenue.totalSalesUSD.toFixed(2) : 0)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-4 md:col-6 sm:col-6 xs:col-6">
                    <div className="card color2-bg">
                        <h5 className='mb-3 text-2xl'>Total Revenue</h5>
                        <div className='flex flex-wrap justify-content-between '>
                            <div className='font-medium text-2xl color2-bg'>
                                {new Intl.NumberFormat("en-US", { style: "currency", currency: "INR" }).format(totalCuSalesAndRevenue ? totalCuSalesAndRevenue.totalCommINR.toFixed(2) : 0)}
                            </div>
                            <div className='font-medium text-2xl color2-bg' style={{ float: "right" }}>
                                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalCuSalesAndRevenue ? totalCuSalesAndRevenue.totalCommUSD.toFixed(2) : 0)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 lg:col-4 p-0" >
                <div className="" style={{ minHeight: "65vh" }}>
                    <div className="col-12" >
                        <div className=" displayCard h-auto" style={{ backgroundColor: "white" }}>
                            <h5 className='mb-1 text-2xl'>Sales</h5>
                            <div /*className="mb-3"*/ className="displayCard mb-3 color3-bg" >
                                <h5 className='mb-1' style={{ fontSize: "1.4rem" }}>For the Day</h5>
                                <div className="flex justify-content-between">
                                    <h5 className=" mt-0 mb-2" style={{ fontSize: "1.4rem" }}>Quantity</h5>
                                    <h5 className=" mt-0 mr-2 mb-2" style={{ fontSize: "1.4rem" }}>Sales</h5>
                                </div>
                                <div className='flex flex-wrap justify-content-between '>
                                    <div className='font-medium color3-bg' style={{ fontSize: "1.4rem" }}>
                                        {salesForTheDay && salesForTheDay.totalQty ? salesForTheDay.totalQty : 0}
                                    </div>
                                    <div className='font-medium color3-bg' style={{ float: "right", fontSize: "1.4rem" }}>
                                        {new Intl.NumberFormat("en-US", { style: "currency", currency: "INR" }).format(salesForTheDay ? salesForTheDay.totalSalesINR.toFixed(2) : 0)} / {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(salesForTheDay ? salesForTheDay.totalSalesUSD.toFixed(2) : 0)}
                                    </div>
                                </div>
                            </div>
                            <div className="displayCard color4-bg">
                                <h5 className='mb-1' style={{ fontSize: "1.4rem" }}>For the Month</h5>
                                <div className="flex justify-content-between">
                                    <h5 className=" mt-0 mb-2" style={{ fontSize: "1.3rem" }}>Quantity</h5>
                                    <h5 className=" mt-0 mr-2 mb-2" style={{ fontSize: "1.3rem" }}>Sales</h5>
                                </div>
                                <div className='flex flex-wrap justify-content-between '>
                                    <div className='font-medium color4-bg' style={{ fontSize: "1.4rem" }}>
                                        {salesForTheMonth && salesForTheMonth.totalQty ? salesForTheMonth.totalQty : 0}
                                    </div>
                                    <div className='font-medium color4-bg' style={{ float: "right", fontSize: "1.4rem" }}>
                                        {new Intl.NumberFormat("en-US", { style: "currency", currency: "INR" }).format(salesForTheMonth ? salesForTheMonth.totalSalesINR.toFixed(2) : 0)} / {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(salesForTheMonth ? salesForTheMonth.totalSalesUSD.toFixed(2) : 0)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className=" displayCard h-auto" style={{ backgroundColor: "white" }}>
                            <h5 className='mb-1 text-2xl'>Revenue</h5>
                            <div  className="displayCard mb-3" style={{ backgroundColor: "#f6c245fc" }} >
                                <h5 className='mb-1' style={{ fontSize: "1.4rem" }}>For the Day</h5>

                                <div className='flex flex-wrap justify-content-between '>
                                    <div></div>
                                    <div className='font-medium' style={{ float: "right", fontSize: "1.4rem" }}>
                                        {new Intl.NumberFormat("en-US", { style: "currency", currency: "INR" }).format(salesForTheDay ? salesForTheDay.totalCommINR.toFixed(2) : 0)} / {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(salesForTheDay ? salesForTheDay.totalCommUSD.toFixed(2) : 0)}
                                    </div>
                                </div>
                            </div>
                            <div className="displayCard" style={{ backgroundColor: "#f69446" }}>
                                <h5 className='mb-1' style={{ fontSize: "1.4rem" }}>For the Month</h5>

                                <div className='flex flex-wrap justify-content-between '>
                                    <div></div>
                                    <div className='font-medium' style={{ float: "right", fontSize: "1.4rem" }}>
                                        {new Intl.NumberFormat("en-US", { style: "currency", currency: "INR" }).format(salesForTheMonth ? salesForTheMonth.totalCommINR.toFixed(2) : 0)} / {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(salesForTheMonth ? salesForTheMonth.totalCommUSD.toFixed(2) : 0)}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="col-12 lg:col-8">
                <div className="grid">

                    <div className="col-12 lg:col-6 ">
                        <div className="card" style={{ "height": "71vh", overflow: "auto", maxHeight:"71vh" }}>
                            {
                                isbnQtyPieData ?
                                    <HighchartsReact highcharts={Highcharts} style={{ height: "100%", overflow: "auto" }} options={highchartPieOption} />
                                    : null
                            }
                        </div>

                    </div>
                    <div className="col-12 lg:col-6">
                        {
                            isbnSalesBar ?
                                <div className="card" style={{ paddingTop: "1rem", overflowX: "auto", height: "71vh" }}>
                                    <h5 className="mb-3 text-2xl" style={{ marginBottom: 0 }}>
                                        ISBN wise sales and revenue in USD
                                    </h5>
                                    <Chart type="bar" style={{ height: "50vh", position: "relative", width: "40rem" }} data={isbnSalesBar} options={barChartOptions}></Chart>

                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
            <div className="col-12">
                {
                    channelCountMap ?
                        <div className="card" style={{ paddingBottom: "0" }}>
                            <h5 className="mb-3 text-2xl" style={{ marginBottom: 0 }}>
                                Channel wise sales
                            </h5>
                            <div className="grid col-12 " style={{ justifyContent: "space-between" }} >
                                {
                                    Object.keys(PUBLISHERS_CHANNEL).map(p => {
                                        return (
                                            <div key={p} className="col-12 lg:col-2 md:col-6 sm:col-6 xs:col-6" >
                                                <div className="grid align-items-center" style={{fontWeight: 600}}>
                                                    <div className=" first-letter-cap text-2xl " style={{width: "8rem"}}>{p}</div>
                                                    
                                                    {/* <div className=" lg:col-6">
                                                        <img src={process.env.PUBLIC_URL + PUBLISHERS_CHANNEL[p]} alt="" style={{ height: ((p === "flipkart" || p === "jio" || p === "amazon") ? "4.5rem" : "5rem"), width: ((p === "flipkart" || p === "jio" || p === "amazon") ? "3rem" : "7rem") }} />
                                                    </div> */}
                                                    <div className=" lg:col-4" style={{ fontSize: "1.5rem", fontWeight: "bold", paddingTop: "1.2rem", paddingLeft: "1rem" }}>
                                                        <Tag className=" color1-bg text-2xl pr-3 pl-3"   value={(channelCountMap[p] || 0)}></Tag>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                        : null
                }

            </div>

            <div className="col-12">
                <div className="datatable-selection-demo ">
                    <div className="card">
                        <>
                            <div className="col-12 lg:col-6 md:col-6">
                                <div className="flex align-items-center">
                                    <h6 className="m-0 text-2xl" style={{ marginBottom: 0 }}>
                                        Orders Detail from : 
                                    </h6>
                                    <Calendar tooltip="Default last 180 days data" id="dtrange" value={dateRange} maxDate={new Date(todayDt)} onChange={handleDateChange} selectionMode="range" showIcon readOnlyInput className="bg-white w-6 calFS" style={{fontSize:"30px"}}  /></div>
                            </div>
                            <DataTable emptyMessage="Data not found" value={report} showGridlines responsiveLayout="scroll" headerColumnGroup={header} rows={10} paginator>
                                <Column field="sl" bodyClassName="white-space-nowrap uppercase color2-bg "></Column>
                                <Column field="isbn" bodyClassName="white-space-nowrap download uppercase color2-bg" body={(rowData) => (<span style={{borderBottom:"2px solid white", cursor:"pointer"}} onClick={(e)=>{isbnClick(e,rowData.isbn)}}>{rowData.isbn}</span>)} ></Column>
                                <Column field="publisher" bodyClassName="white-space-nowrap uppercase color2-bg"></Column>
                                <Column  bodyClassName="white-space-nowrap uppercase color2-bg" body={(rowData) => (<span >{allIsbnMapdata[rowData.isbn]}</span>)}></Column>
                                <Column field="orderChannel" bodyClassName="white-space-nowrap uppercase color2-bg"></Column>
                                <Column field="qtySold" bodyClassName="white-space-nowrap uppercase color2-bg"></Column>
                                <Column field="totalMsrpINR" body={(e) => totalInrTemplate(e, "totalMsrpINR")} bodyClassName="white-space-nowrap uppercase color2-bg text-right"></Column>
                                <Column field="totalMsrpUSD" body={(e) => totalUsdTemplate(e, "totalMsrpUSD")} bodyClassName="white-space-nowrap uppercase color2-bg text-right"></Column>
                                <Column field="totalReturnINR" body={(e) => totalInrTemplate(e, "totalReturnINR")} bodyClassName="white-space-nowrap uppercase color2-bg text-right"></Column>
                                <Column field="totalReturnUSD" body={(e) => totalUsdTemplate(e, "totalReturnUSD")} bodyClassName="white-space-nowrap uppercase color2-bg text-right"></Column>
                                <Column field="revenue" bodyClassName="white-space-nowrap uppercase color2-bg"></Column>
                                <Column field="totalSalesUSD" body={(e) => totalUsdTemplate(e, "revenueShareUSD")} bodyClassName="white-space-nowrap uppercase color2-bg text-right"></Column>
                                <Column field="totalCreditsUSD" body={(e) => totalUsdTemplate(e, "totalCreditsUSD")} bodyClassName="white-space-nowrap uppercase color2-bg text-right"></Column>

                            </DataTable>
                        </>
                    </div>
                </div>
            </div>
            <Dialog header={getHeader} visible={showModal} modal style={{ width: "60vw" }} onHide={() => setShowModal(false)}>
                <div className="col-12">
                    {isbnOrders ? (
                        <DataTable value={isbnOrders} paginator={true} rows={10} responsiveLayout="scroll">
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="SL" style={{ width: "50px" }} field={"sl"}></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Order Date: IST" style={{width:"100px"}} field={"orderDt"} body={(rowData) => (<span >{moment(rowData.orderDt).format("MM-DD-YYYY HH:mm")}</span>)}></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Order Status" style={{ width: "100px" }} field={"status"}></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Channel" style={{ width: "100px" }} field={"channel"}></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Total Quantity" style={{ width: "100px" }} field="totalQuantity"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Sales in INR" style={{ width: "100px" }} field="salesINR" body={(e) => totalInrTemplate(e, "salesINR")}></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Sales in USD" style={{ width: "100px" }} field="salesUSD" body={(e) => totalUsdTemplate(e, "salesUSD")}></Column>
                        </DataTable>
                    ) : <span>No Data Found</span>}
                </div>
            </Dialog>
        </div>
    );
};

export default LcixDashboard;
