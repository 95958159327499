import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import axios from 'axios'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Row } from 'primereact/row'
import { ColumnGroup } from 'primereact/columngroup'
import { Dialog } from 'primereact/dialog'
import { Panel } from 'primereact/panel';
import { Calendar } from "primereact/calendar";
import { Button } from 'primereact/button'

const banks = ["Axis"]
const ShowPlazaDetails = ({ plazas=[],plazaAccess=[], newAddedFile }) => {

    const [plazaWiseUploads, setPlazaWiseUploads] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState([])
    const dateArray = useRef([])
    const axisPlazas = useRef([])
    const plazaWiseMapData = useRef({})
    const [showMultiple, setShowMultiple] = useState(false)
    const [dateRange, setDateRange] = useState([new Date(moment().subtract(7, "days")), new Date(moment().subtract(1, "days"))])
    const serviceId = localStorage.getItem("serviceId")

    useEffect(() => {
        if (plazas && plazas.length && dateRange && dateRange[0] && dateRange[1]) {
            let startDate;
            let endDate;
            if(showMultiple){
                endDate = moment(dateRange[0]).format("YYYY-MM-DD")
                startDate = moment(dateRange[1]).format("YYYY-MM-DD")
            }
            else{
               startDate =  moment().subtract(1, "days").format("YYYY-MM-DD")
               endDate =  moment().subtract(1, "days").format("YYYY-MM-DD")
            }
            let payload = {
                //add plazas array filter
                filter: {
                    uploadDate: { $gte: endDate, $lte: startDate },
                    ...(plazaAccess.length && {plazaId : {"$in": plazaAccess}})
                },
                projection: { _id: 0, uploadDate: 1, plazaId: 1, user: 1, fileType: 1 }
            }
            axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-plaza-file-updates", payload)
                .then((result) => {
                    let res = result.data
                    let plazaWiseMap = {}
                    res.length && res.map(item => {
                        if (item.plazaId in plazaWiseMap) {
                            if (item.uploadDate in plazaWiseMap[item.plazaId]) {
                                plazaWiseMap[item.plazaId][item.uploadDate].push(item)
                            }
                            else {
                                plazaWiseMap[item.plazaId] = {
                                    ...plazaWiseMap[item.plazaId], [item.uploadDate]: [item]
                                }
                            }
                        }
                        else {
                            plazaWiseMap[item.plazaId] = {
                                plazaId: item.plazaId, [item.uploadDate]: [item]
                            }
                        }
                    })
                    plazas.forEach((item) => {
                        banks.includes(item.CCH) && axisPlazas.current.push(item.PlazaCode)
                        if (!(item.PlazaCode in plazaWiseMap)) {
                            plazaWiseMap[item.PlazaCode] = { plazaId: item.PlazaCode }
                        }
                    })

                    let startValue = moment().diff(moment(startDate, "YYYY-MM-DD"), "days")
                    let endValue = moment().diff(moment(endDate, "YYYY-MM-DD"), "days")
                    const dateArr = []
                    for (let i = startValue; i <= endValue; i++) {
                        dateArr.push(moment().subtract(i, "days").format("YYYY-MM-DD"))
                    }
                    dateArray.current = dateArr
                    plazaWiseMapData.current = plazaWiseMap
                    setPlazaWiseUploads(Object.values(plazaWiseMap))
                })
        }
    }, [plazas, dateRange, showMultiple])

    useEffect(() => {
        if (newAddedFile) {
            let index = plazaWiseUploads.findIndex(item => item.plazaId === newAddedFile.plazaId)

            if (index > -1) {
                if (newAddedFile.uploadDate in plazaWiseUploads[index]) {
                    if (plazaWiseUploads[index][newAddedFile.uploadDate].length === 2) return
                    plazaWiseUploads[index][newAddedFile.uploadDate] = [...plazaWiseUploads[index][newAddedFile.uploadDate], newAddedFile]
                }
                else {
                    plazaWiseUploads[index][newAddedFile.uploadDate] = [newAddedFile]
                }
            }
            //setPlazaWiseUploads(plazaWiseUploads)
        }
    }, [newAddedFile])
    const header = (
        <ColumnGroup>
            <Row>
                <Column header="Plaza" className="color1-bg"></Column>
            </Row>
        </ColumnGroup>
    )
    /*const handleClick = (e) => {
        setSelectedDate(e.target.parentNode.childNodes[0].innerText)
    }*/
    const showColor = (row, date) => {
        let isAxis = axisPlazas.current.includes(row.plazaId)
        let style = { backgroundColor: '#ed542e' }
        if (isAxis) {
            switch (row[date]?.length ?? 0) {
                case 1:
                    if (row[date][0].fileType === "bank") {
                        return htmlTemp("#ed542e", "green")

                    }
                    else {
                        return htmlTemp("green", "#ed542e")

                    }

                case 2:
                    return htmlTemp("green", "green")

                default:
                    return htmlTemp("#ed542e", "#ed542e")
            }
        }
        else {
            if (row[date]?.length) style = { backgroundColor: "green" }
            return <div className='cursor-pointer' style={{ ...style, color: "white", height: " 1rem" }} onClick={(e) => showDetails(row, date)}></div>
        }

        function htmlTemp(color1, color2) {
            const table_style = {
                border: "2px solid black",
                textAlign: "center",
                margin: "auto",
                borderCollapse: "collapse",
            }
            return (<table style={{...table_style, width: "80%"}} border-collapse="collapse" >
                <thead>
                    <tr>
                        <th style={table_style}>Toll</th>
                        <th style={table_style}>Bank</th>
                    </tr>
                </thead>
                <tbody>
                    <tr onClick={(e) => showDetails(row, date)}>
                        <td style={table_style} className='w-6 h-1rem' bgcolor={color1}></td>
                        <td style={table_style} className='w-6 h-1rem' bgcolor={color2}></td>
                    </tr>
                </tbody>
            </table>)

            /* return <div className='col-12 cursor-pointer p-0 flex' onClick={(e) => showDetails(row, date)}>
                <div className='w-6' ><span>Toll</span><div className='w-full  h-1rem' style={{ backgroundColor: color1 }}></div></div>
                <div className='w-6' ><span>Bank</span><div className='w-full  h-1rem' style={{ backgroundColor: color2 }}></div></div>
            </div>*/
        }
    }
    const showDetails = (row, date) => {
        if (row[date]?.length) {
            setModalData(row[date])
            setShowModal(true)
        }
    }
    return (
        <div className=''>
            <Button className='mb-2' style={{ float: "right" }} label='Multiple Days' onClick={() => setShowMultiple(!showMultiple)} />
            {showMultiple ? <Panel className='card col-12 lg:col-12'>
            <h5 className='mb-4 font-bold'>Multiple Days Upload Data</h5>
                <p className='m-0 mb-1'>Select Date Range</p>
                <Calendar value={dateRange} onChange={(e) => setDateRange(e.value)} minDate={new Date(moment().subtract(3, 'months').format("YYYY-MM-DD"))} maxDate={new Date(moment().format("YYYY-MM-DD"))} dateFormat="dd/mm/yy" showIcon selectionMode='range' className="w-6" placeholder="Select Date Range" />
                <DataTable emptyMessage="Data not found" value={plazaWiseUploads ?? []} showGridlines responsiveLayout="scroll" rows={10} paginator>
                    <Column header="Plaza" className="color1-bg" field="plazaId" style={{ width: "60px" }} bodyClassName="white-space-nowrap uppercase color2-bg "></Column>
                    {
                        dateArray.current.map((d) => { return (<Column key={d} headerClassName="color1-bg" style={{ width: "60px" }} header={moment(d).format('MMM DD-YYYY')} field={(e) => showColor(e, d)}></Column>) })
                    }
                </DataTable>
            </Panel> : <OneDayFileUploadStatus plazaWiseUploads={plazaWiseUploads ?? []} showColor={showColor} axisPlazas={axisPlazas} />}
            <Dialog header="Upload Details" visible={showModal} modal style={{ width: "60vw" }} onHide={() => setShowModal(false)}>
                <div className="col-12">
                    {modalData ? (
                        <DataTable value={modalData} paginator={true} rows={10} responsiveLayout="scroll">
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Plaza ID" field="plazaId"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Upload Date" field="uploadDate"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Uploaded By" field="user"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="File Type" field="fileType"></Column>
                        </DataTable>
                    ) : <span>No Data Found</span>}
                </div>
            </Dialog>
        </div>
    )
}

export default ShowPlazaDetails

const OneDayFileUploadStatus = ({ plazaWiseUploads, showColor }) => {
    const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD")
    const style = {
        border: { border: "1px", borderStyle: "solid", borderColor: "#e4e4e4" }
    }

    return <><div className=" col-12 overflow-x-auto card">
        <h5>File Upload Status for :- {moment(yesterday,"YYYY-MM-DD").format("YYYY, MMM DD")}</h5>
        <table style={{ borderCollapse: "collapse" }} className="w-full">
            <thead className="bg-primary">
                <tr>
                    {plazaWiseUploads.map(item => {
                        return <th key={item.plazaId} style={{ width: "8rem", borderWidth: "1px", borderColor: "white", borderStyle: "solid" }} >{item.plazaId}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                <tr>
                    {plazaWiseUploads.map(item => {
                        return <td key={item.plazaId} style={{ ...style.border, width: "8rem", textAlign: "center", verticalAlign: "middle" }}>{showColor(item, moment().subtract(1, "days").format("YYYY-MM-DD"))}</td>
                    })}
                </tr>
            </tbody>
        </table>
    </div>
        <div className='card'>
            <h5 className='mb-0'>Uploaded File Details</h5>
            {plazaWiseUploads ? (
                <DataTable value={plazaWiseUploads.filter(item => item[yesterday])} paginator={true} rows={10} responsiveLayout="scroll">
                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Plaza ID" field="plazaId"></Column>
                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Upload Date" field={(row) => row[yesterday][0].uploadDate}></Column>
                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Uploaded By" field={(row) => row[yesterday][0].user}></Column>
                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="File Type" field={(row) => row[yesterday][0].fileType}></Column>
                </DataTable>
            ) : <span>No Data Found</span>}
        </div>
    </>
}

