import React, { useEffect, useRef, useState } from "react";
import { classNames } from "primereact/utils";
import { useLocation, useHistory } from "react-router-dom";
import { Redirect } from "react-router";

import AppTopbar from "./AppTopbar";
import AppInlineMenu from "./AppInlineMenu";
import AppFooter from "./AppFooter";
import AppMenu from "./AppMenu";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import AuthorizedRoutes from "./routes";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "swagger-ui-react/swagger-ui.css";
import "./App.scss";
import { removeDomainName } from "./utilities/utillFunction";

import VehicleListComponent from "./pages/vehicleTraker/VehicleListComponent";
import FuelReport from "./pages/fuelMonitoring/FuelReport";

export const RTLContext = React.createContext();

const App = () => {
    const [menuMode, setMenuMode] = useState("static");
    const [inlineMenuPosition, setInlineMenuPosition] = useState("bottom");
    const [desktopMenuActive, setDesktopMenuActive] = useState(true);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [colorMode, setColorMode] = useState("light");
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState("filled");
    const [isRTL, setRTL] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
    const [menuTheme, setMenuTheme] = useState("light");
    const [topbarTheme, setTopbarTheme] = useState("blue");
    const [theme, setTheme] = useState("indigo");
    const [isInputBackgroundChanged, setIsInputBackgroundChanged] = useState(false);
    const [inlineMenuActive, setInlineMenuActive] = useState({});
    const [newThemeLoaded, setNewThemeLoaded] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const copyTooltipRef = useRef();
    let currentInlineMenuKey = useRef(null);
    const location = useLocation();
    const history = useHistory()

    PrimeReact.ripple = true;

    let searchClick;
    let topbarItemClick;
    let menuClick;
    let inlineMenuClick;
    const menu = JSON.parse(localStorage.getItem("menu"));
    const service = JSON.parse(localStorage.getItem("service"))
    const token = localStorage.getItem("token")
    const exp = localStorage.getItem("exp")
    useEffect(()=>{
        let timeOutId;
        if (token) {
            if(exp){
                if(exp >= Date.now()) {
                    userLogout(timeOutId)
                    return
                }
                timeOutId = setTimeout(() => {
					userLogout();
				}, exp * 1000 - Date.now());
            }
            if (service) {
                if (location.pathname === "/") {
                    if (menu[0]?.url) {
                        history.push(removeDomainName(menu[0].url))
                    }
                    else if (service.serviceTypeName === "TeamTrac") history.push("/employee-summery?");
                }
            }
            else {
                history.push("/services")
            }
        }
        return () => { clearInterval(timeOutId) }
    },[])

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        onColorModeChange(colorMode);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onColorModeChange = (mode) => {
        setColorMode(mode);
        setIsInputBackgroundChanged(true);

        if (isInputBackgroundChanged) {
            if (mode === "dark") {
                setInputStyle("filled");
            } else {
                setInputStyle("outlined");
            }
        }

        if (mode === "dark") {
            setMenuTheme("dark");
            setTopbarTheme("dark");
        } else {
            setMenuTheme("light");
            setTopbarTheme("blue");
        }

        const layoutLink = document.getElementById("layout-css");
        const layoutHref = process.env.PUBLIC_URL + "/assets/layout/css/layout-" + mode + ".css";
        replaceLink(layoutLink, layoutHref);

        const themeLink = document.getElementById("theme-css");
        const urlTokens = themeLink.getAttribute("href").split("/");
        urlTokens[urlTokens.length - 1] = "theme-" + mode + ".css";
        const newURL = urlTokens.join("/");

        replaceLink(themeLink, newURL, () => {
            setNewThemeLoaded(true);
        });
    };

	const userLogout = (timeOutId) => {
		localStorage.clear();
        clearTimeout(timeOutId)
		history.push("/login");
	}

    const replaceLink = (linkElement, href, callback) => {
        if (isIE()) {
            linkElement.setAttribute("href", href);

            if (callback) {
                callback();
            }
        } else {
            const id = linkElement.getAttribute("id");
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute("href", href);
            cloneLinkElement.setAttribute("id", id + "-clone");

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener("load", () => {
                linkElement.remove();
                cloneLinkElement.setAttribute("id", id);

                if (callback) {
                    callback();
                }
            });
        }
    };

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onMenuClick = (event) => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isDesktop()) setDesktopMenuActive((prevState) => !prevState);
        else setMobileMenuActive((prevState) => !prevState);

        event.preventDefault();
    };

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        if (activeTopbarItem === event.item) setActiveTopbarItem(null);
        else {
            setActiveTopbarItem(event.item);
        }

        event.originalEvent.preventDefault();
    };

    const onSearch = (event) => {
        searchClick = true;
        setSearchActive(event);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items && (menuMode === "overlay" || !isDesktop())) {
            hideOverlayMenu();
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const onRootMenuItemClick = (event) => {
        setMenuActive((prevState) => !prevState);
    };

    const onRightMenuButtonClick = () => {
        setRightMenuActive((prevState) => !prevState);
    };

    const onMobileTopbarButtonClick = (event) => {
        setMobileTopbarActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onDocumentClick = (event) => {
        if (!searchClick && event.target.localName !== "input") {
            setSearchActive(false);
        }

        if (!topbarItemClick) {
            setActiveTopbarItem(null);
        }

        if (!menuClick && (menuMode === "overlay" || !isDesktop())) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }
            hideOverlayMenu();
        }

        if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
            let menuKeys = { ...inlineMenuActive };
            menuKeys[currentInlineMenuKey.current] = false;
            setInlineMenuActive(menuKeys);
        }

        if (!menuClick && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }

        searchClick = false;
        topbarItemClick = false;
        inlineMenuClick = false;
        menuClick = false;
    };

    const hideOverlayMenu = () => {
        setMobileMenuActive(false);
        setDesktopMenuActive(false);
    };

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const isHorizontal = () => {
        return menuMode === "horizontal";
    };

    const isSlim = () => {
        return menuMode === "slim";
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const onInlineMenuClick = (e, key) => {
        let menuKeys = { ...inlineMenuActive };
        if (key !== currentInlineMenuKey.current && currentInlineMenuKey.current) {
            menuKeys[currentInlineMenuKey.current] = false;
        }

        menuKeys[key] = !menuKeys[key];
        setInlineMenuActive(menuKeys);
        currentInlineMenuKey.current = key;
        inlineMenuClick = true;
    };

    const layoutContainerClassName = classNames("layout-wrapper ", "layout-menu-" + menuTheme + " layout-topbar-" + topbarTheme, {
        "layout-menu-static": menuMode === "static",
        "layout-menu-overlay": menuMode === "overlay",
        "layout-menu-slim": menuMode === "slim",
        "layout-menu-horizontal": menuMode === "horizontal",
        "layout-menu-active": desktopMenuActive,
        "layout-menu-mobile-active": mobileMenuActive,
        "layout-topbar-mobile-active": mobileTopbarActive,
        "layout-rightmenu-active": rightMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": !ripple,
        "layout-rtl": isRTL,
    });
    if(!token){
        return <Redirect exact from="*" to={"/login"} />
    }

    return (token && service) ? (
        <RTLContext.Provider value={isRTL}>
            <div className={layoutContainerClassName} onClick={onDocumentClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
                <AppTopbar
                    horizontal={isHorizontal()}
                    activeTopbarItem={activeTopbarItem}
                    onMenuButtonClick={onMenuButtonClick}
                    onTopbarItemClick={onTopbarItemClick}
                    onRightMenuButtonClick={onRightMenuButtonClick}
                    onMobileTopbarButtonClick={onMobileTopbarButtonClick}
                    mobileTopbarActive={mobileTopbarActive}
                    searchActive={searchActive}
                    onSearch={onSearch}
                />

                <div className="menu-wrapper" onClick={onMenuClick}>
                    <div className="layout-menu-container">
                        {(inlineMenuPosition === "top" || inlineMenuPosition === "both") && <AppInlineMenu menuKey="top" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />}
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick} menuMode={menuMode} active={menuActive} />
                    </div>
                </div>

                <div className="layout-main">
                    {/* <AppBreadcrumb routes={routes} /> */}

                    <div className="layout-content">
                        <AuthorizedRoutes />
                    </div>

                    <AppFooter colorMode={colorMode} />
                </div>

                {mobileMenuActive && <div className="layout-mask modal-in"></div>}
            </div>
        </RTLContext.Provider>
    ) : (
        <Redirect exact from="*" to={"/services"} />
    );
};

export default App;
