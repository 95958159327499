import React, { useEffect, useState } from "react";
import { VehicleSrvice } from "../../service/VehicleService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { convertValue } from "../../utilities/unitConversion";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Image } from "primereact/image";
import { useHistory } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { useSelector } from "react-redux";
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";

const PerformanceAndDaignostics = (props) => {
    const vehicleService = new VehicleSrvice();
    const history = useHistory();
    const serviceId = localStorage.getItem("serviceId");
    const unitSystem = useSelector((state) => state.changeUnit);
    const [thingData, setThingData] = useState(null);
    const [staticOBD, setStaticOBD] = useState(null);
    const [obdParams, setObdParams] = useState(null);
    const [obdData, setObdData] = useState(null);
    const [chartOBD, setChartOBD] = useState(null);
    const [selectedDt, setDate] = useState(null);
    const [fromDt, setFromDate] = useState(null);
    const [maxDt, setMaxDt] = useState(null);
    const [isloading, setIsLoading] = useState(true);
    const params = new URLSearchParams(props.location.search);
    const obj = Object.fromEntries(params);

    const getThingData = (thingName) => {
        if (thingName)
            vehicleService.getThingData(thingName).then((resp) => {
                if (resp && resp.length) {
                    setThingData(resp[0]);
                }
            });
    };
    useEffect(() => {
        getThingData(obj.thingName);
    }, []);

    useEffect(() => {
        if (thingData && Object.keys(thingData).length && !obdParams) {
            vehicleService.getVehicleParams({ filter: { make: thingData.make, model: thingData.model } }).then((response) => {
                let obdParams = response[0].obdparam;
                let date = moment(thingData.ts, "YYYY-MM-DD HH:mm:ss").startOf("day").format("YYYY-MM-DD HH:mm:ss");

                setDate(new Date(date));
                setMaxDt(new Date(date));
                setFromDate(date);
                setObdParams(obdParams);
            });
        }
    }, [thingData]);

    useEffect(() => {
        if (obdParams && obdParams.length) {
            getOBDHistory(fromDt);
        }
    }, [obdParams]);

    const getOBDHistory = (fromdt, todt) => {
        if (fromdt) {
            let projection = { _id: 0, ts: 1 };

            obdParams.forEach((item) => {
                projection[item.param] = 1;
            });

            let tsquery = { ts: { $gte: fromdt } };
            if (todt) tsquery.ts["$lte"] = todt;
            let query = {
                serviceid: serviceId,
                filter: { thingName: thingData.thingName, ts: { $gte: fromdt } },
                projection: projection,
            };
            vehicleService.getObdDataHistory(serviceId, query).then((res) => {
                if (res.length > 0) {
                    let obd = res.sort((a, b) => {
                        const date1 = new Date(a.ts);
                        const date2 = new Date(b.ts);
                        return date1 - date2;
                    });
                    handelOBD(obdParams, obd);
                }
            });
        }
    };

    const handelOBD = (obdParm, data) => {
        let staticData = {
            number_of_dtc: "",
            distance_traveled_milon: "",
            distance_since_codes_clear: "",
            time_since_codes_cleared: "",
            fault_codes: "",
        };
        staticData.number_of_dtc = data[data.length - 1].numberofdtc;
        staticData.distance_traveled_milon = data[data.length - 1].distancetraveledmilon;
        staticData.distance_since_codes_clear = data[data.length - 1].distancesincecodesclear;
        staticData.time_since_codes_cleared = data[data.length - 1].timesincecodescleared;
        staticData.fault_codes = data[data.length - 1].faultcodes;

        let obdArr = obdParm.filter((item) => item.param !== "numberofdtc" && item.param !== "distancetraveledmilon" && item.param !== "distancesincecodesclear" && item.param !== "timesincecodescleared" && item.param !== "faultcodes" && item.param !== "ts");

        let grandArr = [];
        obdArr.forEach((item) => {
            let temp = [];
            let chartLable = [];
            data.forEach((elm) => {
                let value = parseFloat(convertValue(elm[item.param], item.displayType, unitSystem));
                chartLable.push(moment(elm.ts, "YYYY-MM-DD HH:mm:ss").format("HH:mm"));
                temp.push(value);
            });
            grandArr.push({
                title: item.displayName,
                type: item.displayType,
                seq: item.displaySeq,
                lable: chartLable,
                data: temp,
            });
        });
        grandArr.sort((a, b) => a.seq - b.seq);
        let chartOBD = grandArr.map((item) => {
            return {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                    height: (9 / 16) * 100 + "%",
                },
                title: {
                    text:
                        item.type === "temperature"
                            ? unitSystem === "FPS"
                                ? `${item.title} \xB0F`
                                : `${item.title} \xB0C.`
                            : item.type === "distance"
                            ? unitSystem === "FPS"
                                ? `${item.title} miles`
                                : `${item.title} km`
                            : item.type === "percent"
                            ? `${item.title} %`
                            : item.type === "speed"
                            ? unitSystem === "FPS"
                                ? `${item.title} mph`
                                : `${item.title} kmph`
                            : `${item.title}`,
                    style: chartHeadingStyle,
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                legend: {
                    align: "center",
                    verticalAlign: "bottom",
                    x: 0,
                    y: 0,
                    floating: false,
                    backgroundColor: "transparent",
                    borderColor: "#CCC",
                    borderWidth: 0,
                    shadow: false,
                },
                tooltip: {
                    headerFormat: "<b>Time: {point.x}</b><br/>",
                    pointFormat:
                        item.type === "temperature"
                            ? unitSystem === "FPS"
                                ? "{series.name}: {point.y} \xB0F"
                                : "{series.name}: {point.y} \xB0C."
                            : item.type === "distance"
                            ? unitSystem === "FPS"
                                ? "{series.name}: {point.y} miles"
                                : "{series.name}: {point.y} km"
                            : item.type === "percent"
                            ? "{series.name}: {point.y} %"
                            : item.type === "speed"
                            ? unitSystem === "FPS"
                                ? "{series.name}: {point.y} mph"
                                : "{series.name}: {point.y} kmph"
                            : "{series.name}: {point.y}",
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: item.lable,
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        borderRadius: 5,
                        pointWidth: 9,
                        borderWidth: 0,
                        lineWidth: 1,
                    },
                },
                series: [
                    {
                        name: item.title,
                        data: item.data,
                        color: colorPalettes.color2,
                    },
                ],
            };
        });
        setIsLoading(false);
        setObdData(obdData);
        // setObdParams(obdParams);
        setStaticOBD(staticData);
        setChartOBD(chartOBD);
    };
    const handleDateChange = (e) => {
        let from_date = moment(e.value).startOf("day").format("YYYY-MM-DD HH:mm:ss");
        let to_date = moment(e.value).endOf("day").format("YYYY-MM-DD HH:mm:ss");
        setDate(e.value);
        setIsLoading(true);
        getOBDHistory(from_date, to_date);
    };

    const handleBackButton = () => {
        history.push("/obdperformance");
    };

    return (
        <div className="card">
            <div className="grid">
                {isloading ? (
                    <div className="col-12 lg:col-12 xs:col-12">
                        <div className="grid">
                            <div className="col-12 lg:col-12 xs:col-12">
                                <Skeleton height="50px" className="mb-2"></Skeleton>
                            </div>
                            <div className="col-12 lg:col-12 xs:col-12">
                                <Skeleton height="50px" className="mb-2"></Skeleton>
                            </div>
                        </div>
                    </div>
                ) : staticOBD ? (
                    <>
                        <div className="col-12 lg:col-12 xs:col-12" style={{ paddingTop: "2vh" }}>
                            <div className="grid" style={{ justifyContent: "space-around" }}>
                                <div className="w-full flex justify-content-between">
                                    <Button icon="pi pi-arrow-left" className="mr-2" onClick={handleBackButton} />
                                    <div className="flex">
                                        <Image className="mr-2" src={process.env.REACT_APP_IMAGE_STATIC_URL + "/" + serviceId + "/" + thingData.thingName + ".jpg"} alt="Image" width="38" height="38" preview />
                                        <div className="mt-2">
                                            <span className="color1 font-medium text-blue-700 ">
                                                <font style={{ color: "black" }}>Licence Plate No: </font>
                                                {obj.vehicleNumber.toUpperCase()}&nbsp;&nbsp;
                                                <font style={{ color: "black" }}>vehicle: </font>
                                                {thingData.make.toUpperCase()} {thingData.model.toUpperCase()}
                                            </span>
                                        </div>
                                    </div>
                                    <Calendar id="datepicker" value={selectedDt} onChange={(e) => handleDateChange(e)} maxDate={maxDt} showIcon readOnlyInput style={{ width: "150px", marginRight: "10px" }} />
                                </div>
                            </div>
                        </div>
                        {staticOBD.number_of_dtc !== undefined ? (
                            <div className="col-12 lg:col-3">
                                <div className="card" style={{ width: "260px" }}>
                                    <div className="flex align-items-center w-100" style={{ height: "35px" }}>
                                        <div className="text-base font-bold text-blue-800"> Number of Diagnostic Trouble Codes</div>
                                        <div className="flex-1 text-base font-bold text-right">{staticOBD.number_of_dtc}</div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {staticOBD.distance_traveled_milon !== undefined ? (
                            <div className="col-12 lg:col-3 ">
                                <div className="card" style={{ width: "260px" }}>
                                    <div className="flex align-items-center w-100" style={{ height: "35px" }}>
                                        <div className="text-base font-bold text-blue-800">Distance since Malfunction Indicator Lamp On</div>
                                        <div className="flex-1 text-base font-bold text-right">{staticOBD.distance_traveled_milon}</div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {staticOBD.distance_since_codes_clear !== undefined ? (
                            <div className="col-12 lg:col-3">
                                <div className="card">
                                    <div className="flex align-items-center w-100" style={{ height: "35px" }}>
                                        <div className="text-base font-bold text-blue-800">Distance Since Codes Clear</div>
                                        <div className="flex-1 text-base font-bold text-right">{staticOBD.distance_since_codes_clear}</div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {staticOBD.time_since_codes_cleared !== undefined ? (
                            <div className="col-12 lg:col-3">
                                <div className="card">
                                    <div className="flex align-items-center w-100" style={{ height: "35px" }}>
                                        <div className="text-base font-bold text-blue-800">Time Since Codes Cleared</div>
                                        <div className="flex-1 text-base font-bold text-right">{staticOBD.time_since_codes_cleared}</div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {staticOBD.fault_codes !== undefined ? (
                            <div className="col-12 lg:col-3">
                                <div className="card">
                                    <div className="flex align-items-center w-100" style={{ height: "35px" }}>
                                        <div className="text-base font-bold text-blue-800">Fault Codes</div>
                                        <div className="flex-1 text-base font-bold text-right">{staticOBD.fault_codes}</div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
            <div className="grid">
                {isloading ? (
                    <>
                        <div className=" col-12 lg:col-6 xs:col-6">
                            <Skeleton height="200px" className="mb-2"></Skeleton>
                        </div>
                        <div className="col-12 lg:col-6 xs:col-6">
                            <Skeleton height="200px" className="mb-2"></Skeleton>
                        </div>
                        <div className="col-12 lg:col-6 xs:col-6">
                            <Skeleton height="200px" className="mb-2"></Skeleton>
                        </div>
                        <div className="col-12 lg:col-6 xs:col-6">
                            <Skeleton height="200px" className="mb-2"></Skeleton>
                        </div>
                    </>
                ) : chartOBD ? (
                    chartOBD.map((item, index) => (
                        <div className="col-12 lg:col-6" key={"chart" + index}>
                            <HighchartsReact highcharts={Highcharts} options={item} />
                        </div>
                    ))
                ) : null}
            </div>
        </div>
    );
};

export default PerformanceAndDaignostics;
