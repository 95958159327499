import React, { useEffect } from "react";
import moment from "moment";
import { VehicleSrvice } from "../../service/VehicleService";
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";
import { useState } from "react";
import AppChart from "../ChartsComponents/AppChart";
import { FuelSrvice } from "../../service/FuelService";
import { convertValue } from "../../utilities/unitConversion";
import ItemCurrentStatus from "../ChartsComponents/ItemCurrentStatus";
import UseageSummery from "../ChartsComponents/UseageSummery";
import FleetMilage from "../ChartsComponents/FleetMilage";
import ScoreDitribution from "../ChartsComponents/ScoreDitribution";
import BestDrivenVehicle from "../ChartsComponents/BestDrivenVehicle";
import AggressiveDriving from "../ChartsComponents/AggressiveDriving";
import GroupBarChart from "../ChartsComponents/GroupBarChart";
import { stationaryVehicle } from "../../lib/constants";

const VehicleWithFuelSummery = () => {
    const serviceID = localStorage.getItem("serviceId");
    const service = JSON.parse(localStorage.getItem("service"));
    const unitSys = service.region.includes("us") ? "FPS" : "MKS";
    const vehicleService = new VehicleSrvice();
    const fuelService = new FuelSrvice();
    const pieColor = [colorPalettes.color1, colorPalettes.color1_1, colorPalettes.color2, colorPalettes.color2_1, colorPalettes.color3, colorPalettes.color4, colorPalettes.color5];
    const endDay = moment().subtract(0, "days").endOf("day").format("YYYY-MM-DD");
    const startDay = moment().subtract(30, "days").startOf("day").format("YYYY-MM-DD");

    const [currentStatus, setCurrentStatus] = useState(null);
    const [modelWiseChart, setmodelWiseChart] = useState(null);
    const [useageSummery, setUseageSummery] = useState(null);
    const [fleetMilageData, setFleetMilageData] = useState(null);
    const [scoreDistribution, setScoreDistribution] = useState(null);
    const [bestScoreDistribution, setBestDrivenVehicle] = useState(null);
    const [aggressiveDriving, setAggressiveDriving] = useState(null);
    const [fuelConsumption, setFuelConsumption] = useState(null);
    const [fuelingDefuelingChart, setFuelingDefuelingChart] = useState(null);
    const [fuelInventryChart, setFuelInventryChart] = useState(null);
    const [inventory, setInventory] = useState({ vol: 0, cost: 0 });
    const [things, setThings] = useState(null);

    useEffect(() => {
        const thingNames = [];
        vehicleService.getVehicles(serviceID).then((response) => {
            response.forEach((item) => {
                thingNames.push(item.thingName);
            });
            setThings(thingNames);
        });
    }, []);

    useEffect(() => {
        if (things) {
            const to_date = moment().subtract(1, "days").format("YYYY-MM-DD");
            const from_date = moment().subtract(30, "days").format("YYYY-MM-DD");
            const payload = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            startTime: {
                                $gte: from_date,
                                $lte: to_date,
                            },
                            thingName: {
                                $in: things,
                            },
                        },
                    },
                    {
                        $group: {
                            _id: "$thingName",
                            maxDistanceInDay: {
                                $max: "$totalDistanceTravelled",
                            },
                            noOfDays: {
                                $sum: 1,
                            },
                            avgDailyDistance: {
                                $avg: "$totalDistanceTravelled",
                            },
                            totalDistance: {
                                $sum: "$totalDistanceTravelled",
                            },
                        },
                    },
                ],
            };
            const payload1 = {
                filter: {
                    date: moment().subtract(1, "days").format("YYYY-MM-DD"),
                },
            };
            const payload2 = {
                operation: "aggregate",
                aggregate: [
                    { $match: { startTime: { $gte: moment().subtract(7, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss"), $lte: moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD HH:mm:ss") } } },
                    { $group: { _id: { date: "$date" }, avgMilage: { $sum: "$totalDistanceTravelled" } } },
                    { $sort: { "_id.date": -1 } },
                ],
            };
            const payload3 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            startTime: {
                                $gte: moment(from_date).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                                $lte: moment(to_date).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                            },
                        },
                    },
                    {
                        $group: {
                            _id: {
                                thingName: "$thingName",
                            },
                            total_harse_acc: {
                                $sum: "$greendriving.harsh_acc",
                            },
                            total_harsh_brak: {
                                $sum: "$greendriving.harsh_brak",
                            },
                            total_harsh_corn: {
                                $sum: "$greendriving.harsh_corn",
                            },
                            total_harsh_speeding: {
                                $sum: "$greendriving.harsh_speeding",
                            },
                        },
                    },
                    {
                        $sort: {
                            "_id.greendriving.driveroverallscore": -1,
                        },
                    },
                ],
            };
            const payload4 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            date: {
                                $gte: from_date,
                                $lte: to_date,
                            },
                        },
                    },
                    {
                        $group: {
                            _id: {
                                type: "$type",
                                thingName: "$thingName",
                            },
                            amount: {
                                $sum: "$amount",
                            },
                        },
                    },
                ],
            };
            Promise.all([
                vehicleService.getFleetsData(serviceID),
                vehicleService.getVehicles(serviceID),
                vehicleService.getAggregateHistory(serviceID, payload),
                vehicleService.getAggregateHistory(serviceID, payload2),
                vehicleService.getHistory(serviceID, payload1),
                vehicleService.getAggregateHistory(serviceID, payload3),
                fuelService.getFuelConsumption(serviceID, from_date, to_date),
                fuelService.getFuelingDefuelingEvents(serviceID, payload4),
                fuelService.getFuelVolAndCurrency(serviceID,{}),
            ]).then((response) => {
                const fleetData = response[0];
                let thingName_alias_map = {}
                fleetData.forEach(r => {
                    thingName_alias_map[r.fleetName] = r.alias || ""
                })
                const vehiclesData = response[1].map((item) => {
                    let index = fleetData.findIndex((elm) => elm.fleetName === item.thingName);
                    if (index > -1) {
                        return {
                            vehicleNumber: fleetData[index].vehicle.vehicleNumber,
                            alias: fleetData[index].alias || "",
                            ...item,
                        };
                    }
                });
                const totalVehicle = vehiclesData.length;
                const vehicles = response[2].map((item) => {
                    let index = fleetData.findIndex((elm) => elm.fleetName === item._id);
                    if (index > -1) {
                        return {
                            vehicleNumber: fleetData[index].vehicle.vehicleNumber,
                            alias: fleetData[index].alias || "",
                            ...item,
                        };
                    }
                });

                const fleetMilage = response[3];

                const drivingRating = response[4];

                let excludesVehicles = fleetData.filter((item) =>stationaryVehicle.includes(item.fleetType));

                const greenDrivingRating = drivingRating.filter((item) => !excludesVehicles.some((elm) => elm.fleetName === item.thingName));
                const aggressiveDriving = response[5].map((item) => {
                    let index = fleetData.findIndex((elm) => elm.fleetName === item._id.thingName);
                    if (index > -1) {
                        return {
                            vehicleNumber: fleetData[index].vehicle.vehicleNumber,
                            alias: fleetData[index].alias || "",
                            ...item,
                        };
                    }
                });
                const fuelConsumptionData = response[6].map((item) => {
                    let index = fleetData.findIndex((elm) => elm.fleetName === item._id);
                    if (index > -1) {
                        return {
                            vehicleNumber: fleetData[index].vehicle.vehicleNumber,
                            alias: fleetData[index].alias || "",
                            ...item,
                        };
                    }
                });

                const fuelingDefuling = response[7].map((item) => {
                    let index = fleetData.findIndex((elm) => elm.fleetName === item._id.thingName);
                    if (index > -1) {
                        return {
                            vehicleNumber: fleetData[index].vehicle.vehicleNumber,
                            alias: fleetData[index].alias || "",
                            ...item,
                        };
                    }
                });

                const fuelInventoryData = response[8].map((item) => {
                    let index = fleetData.findIndex((elm) => elm.fleetName === item.thingName);
                    if (index > -1) {
                        return {
                            vehicleNumber: fleetData[index].vehicle.vehicleNumber,
                            alias: fleetData[index].alias || "",
                            ...item,
                        };
                    }
                });

                //Chart==1
                let onMove = 0,
                    stationary = 0,
                    Offline = 0,
                    modelWiseDataSet = [];
                vehiclesData.forEach((item, index) => {
                    if (!modelWiseDataSet.some((elm) => elm.name.toLowerCase() === `${item.make}(${item.model})`)) {
                        modelWiseDataSet.push({
                            name: item.make.toUpperCase() + "(" + item.model.toUpperCase() + ")",
                            y: 1,
                            color: pieColor[index],
                        });
                    } else {
                        let i = modelWiseDataSet.findIndex((elm) => elm.name.toLowerCase() === `${item.make}(${item.model})`);
                        if (i > -1) {
                            modelWiseDataSet[i].y += 1;
                        }
                    }

                    if (item.ignition === 1) {
                        onMove += 1;
                    } else if (item.ignition === 0) {
                        stationary += 1;
                    }
                });

                //Chart == 2
                let avgDailyDistance = [],
                    maxDistanceInDay = [],
                    totalDays = [],
                    vehicleCategory = [],
                    mixCategory = [];
                vehicles.forEach((item) => {
                    let avgDD = convertValue(item.avgDailyDistance, "distance", unitSys);
                    let maxDD = convertValue(item.maxDistanceInDay, "distance", unitSys);
                    avgDailyDistance.push(parseFloat(avgDD));
                    maxDistanceInDay.push(parseFloat(maxDD));
                    totalDays.push(item.noOfDays);
                    vehicleCategory.push(item.vehicleNumber.toUpperCase());
                    mixCategory.push(item.alias || item.vehicleNumber);
                });

                //Chart==3
                let date = [];
                let avgMilage = [];
                let newArray = fleetMilage.map((item) => {
                    let date = item._id.date.split("-");
                    let newDate = `${date[1]}-${date[2]}-${date[0]}`;
                    return {
                        date: newDate,
                        avgMilage: convertValue(item.avgMilage, "distance", unitSys, true, false),
                    };
                });

                newArray.sort((a, b) => {
                    return new Date(b.date).getTime() - new Date(a.date).getTime();
                });
                newArray.forEach((item) => {
                    date.push(moment(item.date, "MM-DD-YYYY").format("MMM DD"));
                    avgMilage.push(parseFloat(item.avgMilage));
                });

                //Chart==4 & 5
                if (greenDrivingRating.length > 0) {
                    let avgDrivingScore = [];
                    let less50 = 0;
                    let less60 = 0;
                    let less75 = 0;
                    let less90 = 0;
                    let less100 = 0;
                    let bestDrivenVehiclesCat = [];
                    greenDrivingRating.forEach((item) => {
                        avgDrivingScore.push(item.greendriving.driveroverallscore);
                        bestDrivenVehiclesCat.push((thingName_alias_map[item.thingName]) || item.thingName);
                        if (item.greendriving.driveroverallscore < 50) {
                            less50 += 1;
                        } else if (item.greendriving.driveroverallscore >= 50 && item.greendriving.driveroverallscore < 60) {
                            less60 += 1;
                        } else if (item.greendriving.driveroverallscore >= 60 && item.greendriving.driveroverallscore < 75) {
                            less75 += 1;
                        } else if (item.greendriving.driveroverallscore >= 75 && item.greendriving.driveroverallscore < 90) {
                            less90 += 1;
                        } else {
                            less100 += 1;
                        }
                    });
                    setScoreDistribution({
                        title: `Driving Score Distribution`,
                        seriesData: [
                            {
                                name: "< 50",
                                y: less50,
                                drilldown: "< 50",
                                color: colorPalettes.color1,
                            },
                            {
                                name: "50-60",
                                y: less60,
                                drilldown: "50-60",
                                color: colorPalettes.color2,
                            },
                            {
                                name: "60-75",
                                y: less75,
                                drilldown: "60-75",
                                color: colorPalettes.color3,
                            },
                            {
                                name: "75-90",
                                y: less90,
                                drilldown: "75-90",
                                color: colorPalettes.color4,
                            },
                            {
                                name: "90-100",
                                y: less100,
                                drilldown: "90-100",
                                color: colorPalettes.color5,
                            },
                        ],
                        drilldownData: [
                            {
                                name: "< 50",
                                id: "< 50",
                                data: [less50],
                                color: colorPalettes.color1,
                            },
                            {
                                name: "50-60",
                                id: "50-60",
                                data: [less60],
                                color: colorPalettes.color2,
                            },
                            {
                                name: "60-75",
                                id: "60-75",
                                data: [less75],
                                color: colorPalettes.color3,
                            },
                            {
                                name: "75-90",
                                id: "75-90",
                                data: [less90],
                                color: colorPalettes.color4,
                            },
                            {
                                name: "90-100",
                                id: "90-100",
                                data: [less100],
                                color: colorPalettes.color5,
                            },
                        ],
                    });
                    const bestDrivenVeh = avgDrivingScore.sort((a, b) => b - a);
                    setBestDrivenVehicle({
                        title: `Best driven Vehicles`,
                        data: [
                            {
                                name: "Vehicle",
                                data: bestDrivenVeh,
                                color: colorPalettes.color1,
                            },
                        ],
                        category: bestDrivenVehiclesCat,
                    });
                }

                //Chart == 6
                let aggressiveDriver_categories = [],
                    harse_acc = [],
                    harsh_brak = [],
                    harsh_corn = [];
                aggressiveDriving.forEach((item) => {
                    aggressiveDriver_categories.push((item.alias || item.vehicleNumber.toUpperCase()));
                    harse_acc.push(item.total_harse_acc);
                    harsh_brak.push(item.total_harsh_brak);
                    harsh_corn.push(item.total_harsh_corn);
                });
                //Chart == 7
                const fuelConsumptionVol = [];
                const fuelConsumptionRate = [];
                const fuelConsumptionCategory = [];
                fuelConsumptionData.forEach((obj) => {
                    fuelConsumptionCategory.push((obj.alias || obj.vehicleNumber.toUpperCase()));
                    fuelConsumptionVol.push(parseFloat(obj.fuelConsumed.toFixed(2)));
                    fuelConsumptionRate.push(parseFloat(obj.fuelConsumedCost.toFixed(2)));
                });

                //Chart === 8
                let fuelingDataSet = [];
                let deFuelingDataSet = [];
                let fuelingDefulingCat = [];
                let fuelingDefulingEvent = fuelingDefuling.map((item) => {
                    return {
                        ...item._id,
                        amount: item.amount,
                        vehicleNumber: item.vehicleNumber,
                    };
                });

                fuelingDefulingEvent.forEach((element) => {
                    if (!fuelingDefulingCat.some((item) => item === element.vehicleNumber)) {
                        fuelingDefulingCat.push( (thingName_alias_map[element.thingName] || element.vehicleNumber.toUpperCase()));
                    }
                });

                fuelingDefulingCat.forEach((item) => {
                    let i = fuelingDefulingEvent.findIndex((elm) => elm.type === "fuelling" && item.toLowerCase() === elm.vehicleNumber);
                    let j = fuelingDefulingEvent.findIndex((elm) => elm.type === "defuelling" && item.toLowerCase() === elm.vehicleNumber);

                    if (i > -1) {
                        fuelingDataSet.push(parseFloat(fuelingDefulingEvent[i].amount.toFixed(2)));
                    } else {
                        fuelingDataSet.push(0);
                    }
                    if (j > -1) {
                        deFuelingDataSet.push(parseFloat(fuelingDefulingEvent[j].amount.toFixed(2)));
                    } else {
                        deFuelingDataSet.push(0);
                    }
                });

                //chart==9
                let fuelInventoryVol = [],
                    fuelInventoryVolCost = [],
                    fuelInventryCat = [];

                fuelInventoryData.forEach((item) => {
                    let today = moment().format("YYYY-MM-DD");
                    let tsDate = moment(item.ts).format("YYYY-MM-DD");
                    if (moment(tsDate, "YYYY-MM-DD").isSame(moment(today, "YYYY-MM-DD"))) {
                        fuelInventryCat.push((thingName_alias_map[item.thingName] || item.vehicleNumber.toUpperCase()));
                        fuelInventoryVol.push(item.fuelLevelCalibrated);
                        fuelInventoryVolCost.push(parseFloat((item.fuelrate * item.fuelLevelCalibrated).toFixed(2)));
                    }
                });
                let fuelInventoryTotalVol = [],
                    fuelInventoryVolTotalCost = [];
                fuelInventoryData.forEach((item) => {
                    let today = moment().format("YYYY-MM-DD");
                    let tsDate = moment(item.ts).format("YYYY-MM-DD");
                    if (moment(tsDate, "YYYY-MM-DD").isSame(moment(today, "YYYY-MM-DD"))) {
                        fuelInventoryTotalVol.push(item.fuelLevelCalibrated);
                        fuelInventoryVolTotalCost.push(item.fuelrate * item.fuelLevelCalibrated);
                    }
                });

                let totalFuelVol = 0;
                let totalFuelCost = 0;
                fuelInventoryTotalVol.forEach((item) => (totalFuelVol += item));
                fuelInventoryVolTotalCost.forEach((item) => (totalFuelCost += item));

                //Chart==1
                const vehicleCurrentStatus = [
                    {
                        name: "Moving",
                        y: onMove,
                        color: colorPalettes.color1,
                    },
                    {
                        name: "Stationary",
                        y: stationary,
                        color: colorPalettes.color5,
                    },
                    {
                        name: "Offline",
                        y: Offline,
                        color: colorPalettes.color2,
                    },
                ];
                const vehicleStatusHeading = `Current Status - (Total ${totalVehicle})`;

                //chart==2

                const useageSummeryData = [
                    {
                        name: "Avg. Daily Distance",
                        type: "column",
                        data: avgDailyDistance,

                        color: colorPalettes.color2,
                        tooltip: {
                            valueSuffix: unitSys === "FPS" ? " miles" : " Km",
                        },
                    },
                    {
                        name: "Max Distance in Day",
                        type: "column",
                        data: maxDistanceInDay,

                        color: colorPalettes.color4,
                        tooltip: {
                            valueSuffix: unitSys === "FPS" ? " miles" : " Km",
                        },
                    },
                    {
                        name: "Days Travelled",
                        type: "line",
                        yAxis: 1,
                        data: totalDays,
                        color: colorPalettes.color5,
                    },
                ];
                const useageSummeryTitle = `Usage Summary for Last 30 Days(${moment(from_date, "YYYY-MM-DD").format("Do MMM")} - ${moment(to_date, "YYYY-MM-DD").format("Do MMM")})`;

                //Chart==7
                const fuelConsumedTitle = "Monthly Fuel Consumption (Volume and Cost)";
                const fuelConsumedyAxis = [
                    {
                        title: {
                            text: "Consumption Volume in litres",
                        },
                    },
                    {
                        title: {
                            text: "Consumption Currency in Rs",
                        },
                        opposite: true,
                    },
                ];
                const fuelConsumedydata = [
                    {
                        type: "column",
                        data: fuelConsumptionVol,
                        name: "Volume",
                        color: colorPalettes.color2,
                    },
                    {
                        type: "column",
                        data: fuelConsumptionRate,
                        name: "Cost",
                        yAxis: 1,
                        color: colorPalettes.color4,
                    },
                ];
                const fuelConsumedcategories = fuelConsumptionCategory;

                setCurrentStatus({
                    title: vehicleStatusHeading,
                    data: vehicleCurrentStatus,
                });
                setUseageSummery({
                    category: mixCategory,
                    title: useageSummeryTitle,
                    data: useageSummeryData,
                });
                setFleetMilageData({
                    title: `Fleet Mileage (Last 7 Days)`,
                    data: [{ name: "Mileage", data: avgMilage, color: colorPalettes.color4 }],
                    category: date,
                });
                setAggressiveDriving({
                    title: `Aggressively Driven vehicles`,
                    data: [
                        {
                            name: "Harsh Braking",
                            data: harsh_brak,
                            color: colorPalettes.color2,
                        },
                        {
                            name: "Harsh Cornering",
                            data: harsh_corn,
                            color: colorPalettes.color5,
                        },
                        {
                            name: "Harsh Acceleration",
                            data: harse_acc,
                            color: colorPalettes.color3,
                        },
                    ],
                    category: aggressiveDriver_categories,
                });
                setmodelWiseChart({
                    title: "Vehicle distribution by Make/Model",
                    data: modelWiseDataSet,
                });
                setFuelConsumption({
                    title: fuelConsumedTitle,
                    yAxis: fuelConsumedyAxis,
                    data: fuelConsumedydata,
                    categories: fuelConsumedcategories,
                });
                setFuelingDefuelingChart({
                    title: "Vehicle wise Fuelling/DeFuelling (Last 30 days)",
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Volume (Ltr)",
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} L</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                    },
                    data: [
                        {
                            name: "Fueling",
                            data: fuelingDataSet,
                            color: colorPalettes.color2,
                        },
                        {
                            name: "Defueling",
                            data: deFuelingDataSet,
                            color: colorPalettes.color4,
                        },
                    ],
                    categories: fuelingDefulingCat,
                });
                setFuelInventryChart({
                    title: "Current Fuel Inventory (Volume and Cost)",
                    yAxis: [
                        {
                            title: {
                                text: "Fuel Volume in litres",
                            },
                        },
                        {
                            title: {
                                text: "Fuel cost in Rs",
                            },
                            opposite: true,
                        },
                    ],
                    data: [
                        {
                            type: "column",
                            data: fuelInventoryVol,
                            name: "Volume",
                            color: colorPalettes.color2,
                        },
                        {
                            type: "column",
                            data: fuelInventoryVolCost,
                            name: "Cost",
                            yAxis: 1,
                            color: colorPalettes.color4,
                        },
                    ],
                    categories: fuelInventryCat,
                });
                setInventory({
                    vol: totalFuelVol,
                    cost: totalFuelCost,
                });
            });
        }
    }, [things]);

    return (
        <div className="grid">
            <div className="col-12 lg:col-4">{currentStatus ? <ItemCurrentStatus chartData={currentStatus} /> : null}</div>
            <div className="col-12 lg:col-8">{useageSummery ? <UseageSummery chartData={useageSummery} unit={unitSys} /> : null}</div>
            <div className="col-12 lg:col-6">{modelWiseChart ? <ItemCurrentStatus chartData={modelWiseChart} /> : null}</div>
            <div className="col-12 lg:col-6">{fleetMilageData ? <FleetMilage chartData={fleetMilageData} unit={unitSys} /> : null}</div>
            <div className="col-12">{fuelConsumption ? <GroupBarChart chartData={fuelConsumption} /> : null}</div>
            <div className="col-12">{fuelingDefuelingChart ? <GroupBarChart chartData={fuelingDefuelingChart} /> : null}</div>

            {fuelInventryChart ? (
                <div className="col-12 lg:col-12 ">
                    <div className="grid card flex justify-content-evenly">
                        <div className="col-12 lg:col-9">
                            <GroupBarChart chartData={fuelInventryChart} />
                        </div>

                        <div className="col-12 lg:col-3  ">
                            <div className="border-solid border-green-50 p-6">
                                <p className="font-bold">Current Fuel Inventory</p>
                                <h3 className="text-primary">{inventory.vol.toFixed(2)} Ltr</h3>
                            </div>
                            <div className="border-solid border-green-50 mt-3 p-6">
                                <p className="font-bold">Current Fuel inventory Value</p>
                                <h3 className="text-primary">{new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(inventory.cost)}</h3>
                            </div>
                        </div>
                    </div>

                    {/* </div> */}
                </div>
            ) : null}
            <div className="col-12">{aggressiveDriving ? <AggressiveDriving chartData={aggressiveDriving} /> : null}</div>
            <div className="col-12 lg:col-4">
                {scoreDistribution ? (
                    <>
                        <ScoreDitribution chartData={scoreDistribution} />
                    </>
                ) : null}
            </div>
            <div className="col-12 lg:col-8">{bestScoreDistribution ? <BestDrivenVehicle chartData={bestScoreDistribution} /> : null}</div>
        </div>
    );
};

export default VehicleWithFuelSummery;
