import React, { Component } from "react";
import FuelUsage from "../Common/FuelUsage";

class FuelTrackingUsage extends Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return <FuelUsage isVehicle={false} />;
    };
}

export default FuelTrackingUsage;
