import axios from "axios"
import moment from "moment";
const weatherData = ["humidity", "temp", "wind"]
const getThings = async (serviceId) =>{
    const payload = {
        projection: { thingName : 1, description : 1, _id: 0 }
    }
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-items", payload);
    return res.data;
}

const getLatestPingTime = async (thingName) => {
    const payload = {
        "query": `SELECT MAX(time) AS time from bigrockDB.sensordata WHERE thingName = '${thingName}'`
    }
    let res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/timestream/bigrockDB/sensordata", payload)
    return res.data
}

const getLatestTemp = async (thingName, startTime, endTime, sensorList) => {
    let query = `WITH filtered_data AS (SELECT * FROM bigrockDB.sensordata WHERE thingName = '${thingName}' AND time >= '${startTime}' AND time < '${endTime}') SELECT `

    sensorList.forEach(s => { query += '(SELECT ' + s + ' FROM filtered_data WHERE ' + s + ' BETWEEN -100 AND 200 ORDER BY time DESC LIMIT 1) AS ' + s + ',' })

    weatherData.forEach((s, i) => { query += '(SELECT ' + s + ' FROM filtered_data WHERE ' + s + ' BETWEEN -100 AND 200 ORDER BY time DESC LIMIT 1) AS ' + s + (( i !== weatherData.length -1 ) ? ',' : '') })

    const payload = {"query": query}
    let res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/timestream/bigrockDB/sensordata", payload)
    return res.data
}

const getTempTrends = async (startTime, endTime, thing, isdetailedChart, sensor, sensorList) =>{
    let dateDiff = moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(moment(startTime, "YYYY-MM-DD HH:mm:ss"), "days")
    startTime = moment(startTime, "YYYY-MM-DD HH:mm:ss").tz('America/New_York', true).utc().format("YYYY-MM-DD HH:mm:ss")
    endTime = moment(endTime, "YYYY-MM-DD HH:mm:ss").tz('America/New_York', true).utc().format("YYYY-MM-DD HH:mm:ss")
    let payload
    if (isdetailedChart) {
        payload = {
            "query" : `SELECT COALESCE(${sensor}, -101) AS ${sensor}, time FROM bigrockDB.sensordata WHERE thingName = '${thing}' AND time >= '${startTime}' and time < '${endTime}' ORDER BY time`
        }
    }
    else {
        if (dateDiff === 0) {
            let query = 'SELECT '
            sensorList.forEach(s => { query += 'COALESCE(' + s + ', -101) as ' + s + ',' })
            weatherData.forEach((w,i) => { query += 'COALESCE(' + w + ', -101) as ' +  w + ','})
            query += 'time'
            query += ` FROM bigrockDB.sensordata WHERE thingName = '${thing}' AND time >= '${startTime}' and time < '${endTime}' ORDER BY time `
            payload = { "query": query }
        }
        else {
            let sampleTime = dateDiff > 10 ? '24h' : dateDiff > 3 ? '1h' : '15m'
            let query = `SELECT thingName, bin(time, ${sampleTime}) as time_slot, SUBSTRING(TO_ISO8601(bin(time, ${sampleTime})), 1, 4) as year, SUBSTRING(TO_ISO8601(bin(time, ${sampleTime})), 6, 2) as month, SUBSTRING(TO_ISO8601(bin(time, ${sampleTime})), 9, 2) as day, SUBSTRING(TO_ISO8601(bin(time, ${sampleTime})), 12, 2) as hour, SUBSTRING(TO_ISO8601(bin(time, ${sampleTime})), 15, 2) as min, `
            let min_max = ''
            sensorList.forEach(s => { 
                query += 'COALESCE(MAX(' + s + '),201) as max_' + s + ','
                query += 'COALESCE(AVG(' + s + '),201) as avg_' + s + ','
                min_max += ' and ' + s + ' BETWEEN -100 AND 200'
            })

            weatherData.forEach((w, i) => { 
                query += 'COALESCE(MAX(' + w + '), 201) as max_' +  w + ','
                query += 'COALESCE(AVG(' + w + '), 201) as avg_' +  w + (( i !== weatherData.length -1 ) ? ',' : '')
            })

            query += ` FROM bigrockDB.sensordata WHERE thingName = '${thing}' and time >= '${startTime}' and time < '${endTime}' `
            query += min_max

            query += ` GROUP BY thingName, bin(time, ${sampleTime}) ORDER BY time_slot`

            payload = {
                "query": query
            }
        }
    }
    let res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/timestream/bigrockDB/sensordata", payload)
    return res.data
}
const getSensorInfo = async (serviceId) =>{
    const payload = {
        operation: "aggregate",
        aggregate: [
            { $project: { "sensorIdentification.sensorNumber": 1, "sensorIdentification.itemId": 1, "sensorIdentification.sensorLocationDescription": 1, "installationParameters.name": 1, "installationParameters.code": 1,"manufacturingParameters.parameterName": 1, _id: 0, } },
            { $sort: { "sensorIdentification.sensorNumber": 1 } }]
    }
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-config-sensors", payload);
    return res.data;
}

const getMaxTempForDay = async (thing, startTime, endTime, sensorList) =>{
    let query = `SELECT `
    let min_max = ''
    sensorList.forEach((s, i) => { 
        query += 'MAX(' + s + ') as max_' + s + (( i !== sensorList.length -1 ) ? ',' : '') 
        min_max += ' AND ' + s + ' < 200 AND ' + s + ' > -100'
    })
    query += ` FROM bigrockDB.sensordata WHERE thingName = '${thing}' AND time >= '${startTime}' and time < '${endTime}' `
    query += min_max

    const payload = { "query": query }
    let res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/timestream/bigrockDB/sensordata", payload)
    return res.data
}

const getLastFourTemp = async (thing, startTime, endTime, sensorList) => {
    let query = `WITH filtered_data AS (SELECT * FROM bigrockDB.sensordata WHERE thingName = '${thing}' AND time >= '${startTime}' AND time < '${endTime}' ORDER BY time DESC) SELECT `
    let min_max = ''
    sensorList.forEach((s, i) => { 
        query += 'ROUND(AVG(' + s + '),3) as average_' + s + (( i !== sensorList.length -1 ) ? ',' : '') 
        min_max += '( SELECT ' + s + ' FROM filtered_data WHERE ' + s + ' < 200 AND ' + s + ' > -100 LIMIT 4 ) AS average_' + s + (( i !== sensorList.length -1 ) ? ',' : '')
    })
    query += ' FROM ' + min_max;
    const payload = {"query": query}
    let res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/timestream/bigrockDB/sensordata", payload)
    return res.data
}

const getAlertsData = async (startTime, endTime, thing, serviceId) => {
    startTime = moment(startTime, "YYYY-MM-DD HH:mm:ss").tz('America/New_York', true).utc().format("YYYY-MM-DD HH:mm:ss")
    endTime = moment(endTime, "YYYY-MM-DD HH:mm:ss").tz('America/New_York', true).utc().format("YYYY-MM-DD HH:mm:ss")
    let payload = {
        operation : "aggregate",
        aggregate : [
            {
              $match: {
                   thingName : thing,
                   ts : {"$gte": startTime, "$lte": endTime},
                   value : {"$gt": -100, "$lt": 200}
               } 
            },
            {
                $group: {
              _id: "$sensor",
              alerts: { $push: {date:"$ts",value:"$value",alertType:"$alert"} }
            }
          },
          {
            $project: {
              _id: 0,
              sensor: "$_id",
              alerts: 1,
            }
          }
        ]
    }
    let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-temparature-alerts", payload)
    return res.data
}

const service = {
    getThings,
    getLatestTemp,
    getTempTrends,
    getSensorInfo,
    getMaxTempForDay,
    getLastFourTemp,
    getAlertsData,
    getLatestPingTime
}

export default service
