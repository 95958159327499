import React, { Component } from "react";
import moment from "moment";
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { FuelSrvice } from "../../../service/FuelService";

class Session extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: null,
            things: null, 
            selectedThing: null, 
            sessionData: null
        };
        this.fuelService = new FuelSrvice();
        this.hendelDate = this.hendelDate.bind(this);
        this.handelOnTime = this.handelOnTime.bind(this);
        this.handelStopTime = this.handelStopTime.bind(this);
        this.handelfuelConsumed = this.handelfuelConsumed.bind(this);
    }

    componentDidMount = () => {
        let current_date = moment().format("YYYY-MM-DD");
        let previous_date = moment().subtract(6, "days").format("YYYY-MM-DD");
        this.setState({ 
            selectedThing: this.props.things 
        }, () => this.createReport(this.state.selectedThing, current_date, previous_date)); 
    }

    createReport = (things, current_date, previous_date) => {
        let thingArr = [];
        things.forEach(item => {
            thingArr.push({"thingName": item.name})
        });
        const payload = {
            "filter": {
                "$or": thingArr,
                "date": {"$gte": previous_date,"$lte": current_date}
            }
        };
        this.fuelService.getSessionReport(this.props.serviceId, payload).then(res => {
            this.setState({ sessionData: res, dateRange: [new Date(previous_date), new Date(current_date)] })
        });
    }

    handelDateChange = (e) => {
        this.setState({ dateRange: e.value }, () => {
            if (this.state.dateRange[1] !== null) {
                this.createReport(this.state.selectedThing, moment(this.state.dateRange[1]).format("YYYY-MM-DD"), moment(this.state.dateRange[0]).format("YYYY-MM-DD"));
            }
        });
    }

    handelThingChange = (e) => {
        let current_date = moment(this.state.dateRange[1]).format("YYYY-MM-DD");
        let previous_date = moment(this.state.dateRange[0]).format("YYYY-MM-DD");
        this.setState({ selectedThing: e.value }, () => this.createReport(this.state.selectedThing, current_date, previous_date))
    }

    hendelDate = (item) => {
        return moment(item.ignitionOnTime, "YYYY-MM-DD HH:mm:ss").format("MMM D, YYYY")
    };

    handelfuelConsumed = (item) => {
        return parseFloat(item.fuelConsumed).toFixed(2);
    }
    handelOnTime = (item) => {
        return moment(item.ignitionOnTime, "YYYY-MM-DD HH:mm:ss").format("HH:mm a")
    }
    handelStopTime = (item) => {
        return moment(item.ignitionOffTime, "YYYY-MM-DD HH:mm:ss").format("HH:mm a")
    }

    render = () => {
        let headerGroup = (<ColumnGroup>
            <Row>
                <Column header="Date" className="bg-primary"></Column>
                <Column header="Site ID" className="bg-primary"></Column>
                <Column header="Site Name" className="bg-primary"></Column>
                <Column header="Start Time" className="bg-primary"></Column>
                <Column header="Stop Time" className="bg-primary"></Column>
                <Column header="Run Duration(Mins)" className="bg-primary"></Column>
                <Column header="Fuel Consumed" className="bg-primary"></Column>
            </Row>
        </ColumnGroup>);
        return (
            <div className="grid">
                <div className="col-12 lg:col-3">
                    <Calendar id="range" value={this.state.dateRange} onChange={(e) => this.handelDateChange(e)} selectionMode="range" readOnlyInput className="w-full" />
                </div>
                <div className="col-12 lg:col-3">
                    <MultiSelect
                        className="w-full"
                        value={this.state.selectedThing}
                        options={this.props.things}
                        onChange={(e) => this.handelThingChange(e)}
                        optionLabel="name"
                        placeholder="Select a Site"
                        // maxSelectedLabels={3}
                    />
                </div>
                <div className="col-12" style={{maxHeight: '300px', overflowY: 'auto'}}>
                    {this.state.sessionData ? (
                        <DataTable headerColumnGroup={headerGroup} value={this.state.sessionData} responsiveLayout="scroll" showGridlines stripedRows>
                            <Column 
                                body={this.hendelDate} 
                                className="white-space-nowrap"></Column>
                            <Column 
                                field="siteId" 
                                className="white-space-nowrap"></Column>
                            <Column 
                                field="siteName" 
                                className="white-space-nowrap"></Column>
                            <Column 
                                body={this.handelOnTime} 
                                className="white-space-nowrap"></Column>
                            <Column 
                                body={this.handelStopTime} 
                                className="white-space-nowrap"></Column>
                            <Column 
                                field="runDuration" 
                                className="white-space-nowrap"></Column>
                            <Column 
                                body={this.handelfuelConsumed} 
                                className="white-space-nowrap"></Column>
                        </DataTable>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default Session;