const httpConstants = {
    METHOD_TYPE: {
        POST: 'POST',
        PATCH: 'PATCH',
        PUT: 'PUT',
        GET: 'GET',
        DELETE: 'DELETE',
    },
    CONTENT_TYPE: {
        APPLICATION_JSON: 'application/json',
        MULTIPART_FORM_DATA: 'multipart/form-data',
        APPLICATION_FORM_URLENCODED: 'application/x-www-form-urlencoded',
        IMAGE_PNG: 'image/png'
    },
    DEVICE_TYPE: {
        WEB: 'web'
    },
    API_END_POINT: {
        GET_USER_PERMISSIONS: '/get-user-permission',
        GET_SERVICES_MENU: '/web-get-menu-option',
        UPLOAD_CONFIG_FILE: '/upload-config-file',
        GET_REGIONS:'/get-regions',
        TENANT_GET_USERS: "/get-users",
        ADD_USER_BY_CUSTMER: "/add-user-by-customer",
        UPDATE_USER_PERMISSION: '/update-user-permissions',
        GET_COUNTRY_LIST: "/get-country-list",
        GET_STATES_LIST: '/get-list-state',
        GET_LIST_OF_CITIES: '/get-list-city',
        GET_LIST_OF_PINCODES: '/get-list-pincode',
        ALLOW_T_AND_C_FOR_SERVICES: '/allow-terms-for-services'
    },
    combinatorValue: [
        { name: "AND", code: "$and" },
        { name: "OR", code: "$or" },
    ],
    parameterValues: [
        { name: 'Tower Type', code: 'towerDetails.towerConfig.towerType' },
        { name: 'Tower Height', code: 'towerDetails.towerHeight.heightfromBase' },
        { name: 'Tenancy Count', code: 'towerDetails.tenancyCount' },
        { name: 'State', code: 'siteDetails.location.state' },
        { name: 'City', code: 'siteDetails.location.city' },
        { name: 'Zipcode', code: 'siteDetails.location.zipcode' },
        { name: 'Wind Zone', code: 'siteDetails.environmental.windZone' },
        { name: 'Seismic Zone', code: 'siteDetails.environmental.seismicZone' },
        { name: 'Tower Load(Wind Load adjusted)', code: 'towerDetails.capacity.usedLoadCapacityWithWindLoad' },
    ],
    operator_Value: [
        { name: "Equal To", code: "$eq" },
        { name: "Greater Than", code: "$gt" },
        { name: "Less Than", code: "$lt" },
        { name: "Contains", code: "$in" },
        { name: "Greater Than Equal", code: "$gte" },
        { name: "Less Than Equal", code: "$lte" },
    ],
    for_alpha_numeric_operator: [
        { name: "Equal To", code: "$eq" },
        { name: "Contains", code: "$in" },
    ],
    for_numeric_operator: [
        { name: "Equal To", code: "$eq" },
        { name: "Greater Than", code: "$gt" },
        { name: "Less Than", code: "$lt" },
        { name: "Greater Than Equal", code: "$gte" },
        { name: "Less Than Equal", code: "$lte" },
    ],
    tower_types: [
        { name: "GBT", code: "GBT" },
        { name: "RTT", code: "RTT" },
        { name: "GBM", code: "GBM" },
        { name: "RTP", code: "RTP" },
    ]
};

const pHValues = [
    { action: "calibph4", name: "pH 4" },
    { action: "calibph7", name: "pH 7" },
    { action: "calibph10", name: "pH 10" }
];

const ecValues = [
    { action: "calibec1413", name: "EC 1413 uS" },
    { action: "calibec12880", name: "EC 12880 us" },
];

const configFileData = {
    "cloudConnectivity": "Cellular", 
    "numberOfPaths": "Singular",
    "reportingMethod": "Periodic", 
    "sensorRedundancy":"Singular",
    "cloudConnection": "gateway"
}

const thingNameMap = {
    "fleet": "vehicle",
    "item": "asset"
}

const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID_NEW,
    ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENTID_NEW
}

const poolDataOld = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENTID
}

const moduleAccess = {
    BuildOperateDataPipelineSetup: {
        block: true,
        read: false,
        write: false
    },
    BuildOperateDataPipelineManagePipeline: {
        block: true,
        read: false,
        write: false
    },
    ManageThingsMonitorPerformance: {
        block: true,
        read: false,
        write: false
    },
    ManageThingsMonitorQuality: {
        block: true,
        read: false,
        write: false
    },
    'Alerts&Notification': {
        block: true,
        read: false,
        write: false
    }
}

const accessTitle = [
    {title: "Setup", name: "BuildOperateDataPipelineSetup", "BuildOperateDataPipelineSetup.READ":  false, "BuildOperateDataPipelineSetup.WRITE": false},
    {title: "Manage Pipeline", name: "BuildOperateDataPipelineManagePipeline", "BuildOperateDataPipelineManagePipeline.READ": false, "BuildOperateDataPipelineManagePipeline.WRITE": false},
    {title: "Monitor Things Performance", name: "ManageThingsMonitorPerformance", "ManageThingsMonitorPerformance.READ": false, "ManageThingsMonitorPerformance.WRITE": false },
    {title: "Monitor Things Quality", name: "ManageThingsMonitorQuality"},
    {title: "Alters & Notifications", name: 'Alerts&Notification'}
]

const VEH_SERVICE_COLLECTIONS = {
    "Vehicle Tracking": "veh",
    "Vehicle tracking with OBD": "obd",
    "Vehicle tracking with Fuel": "vehfuel",
    "Vehicle tracking with OBD and Fuel": "obdfuel"
}

const WINDZONE_COLORS = {
    "Zone 1":"rgba(91, 132, 152, 0.8)",
    "Zone 2":"rgba(255, 241, 118, 0.6)",
    "Zone 3":"rgba(0, 172, 193, 0.5)",
    "Zone 4":"rgba(46, 125, 50, 0.5)",
    "Zone 5":"rgba(255, 0, 0, 0.6)",
    "Zone 6":"rgba(223, 30, 99, 0.7)",
    "Zone 7": "rgba(254,178,76,0.7)",
    "Zone 8":"rgba(255,112,146,0.8)",
    "Zone 9": "rgba(117,107,177, 0.8)",
    "Zone 10":"rgba(255,128,109, 0.7)"

}

const WINDZONE_SPEED = {
    "Zone 6":"200 kmph",
    "Zone 5":"180 kmph",
    "Zone 4":"170 kmph",
    "Zone 3":"160 kmph",
    "Zone 2":"140 kmph",
    "Zone 1":"120 kmph",
}

const SITE_MAPPING_SERVICE_LOGO = {
    "ciyk7r5v37": "/images/atc_logo.png"
}
const colorPalettes = {
    bar_x_axis_text: "black",
    color1: "#264653",
    color1_1: "#111f25",
    color2: "#2A9D8F",
    "color2_1 ": "#0da291e",
    color3: "#E9C46A",
    color4: "#F4A261",
    color5: "#E76F51",
};

const barChartColors = [colorPalettes.color2, colorPalettes.color4, colorPalettes.color3, colorPalettes.color5, colorPalettes.color1];
const pieChartColors = [colorPalettes.color1, colorPalettes.color5, colorPalettes.color2, colorPalettes.color4, colorPalettes.color5];

const PUBLISHERS_CHANNEL = {
    "amazon":  "/images/amazon.svg",
    "flipkart": "/images/flipkart.svg",
    "bookscape": "/images/Bookscape.svg",
    "jio": "/images/jio.svg",
    "meesho": "/images/meesho.svg"
}

const bigRockMountainData = [

    {"id":{"S":"1"},"sensorId":{"S":"internal_1"},"cancelledBy":{"S":""},"sensorName":{"S":"BearingOpp"},"EmailNotification":{"S":"2023-09-11T18:27:19"},"cancelledAt":{"S":"09/11/2023 6:35 PM"},"machineName":{"S":"LippmannJawCrusher"},"createdAt":{"S":"2023-11-20T12:01:40"},"status":{"S":"32.30"}},
    {"id":{"S":"1"},"sensorId":{"S":"internal_1"},"cancelledBy":{"S":""},"sensorName":{"S":"BearingOpp"},"EmailNotification":{"S":"2023-09-11T18:27:19"},"cancelledAt":{"S":"09/11/2023 6:35 PM"},"machineName":{"S":"LippmannJawCrusher"},"createdAt":{"S":"2023-11-20T12:15:40"},"status":{"S":"33.30"}},
    {"id":{"S":"1"},"sensorId":{"S":"internal_1"},"cancelledBy":{"S":""},"sensorName":{"S":"BearingOpp"},"EmailNotification":{"S":"2023-09-11T18:27:19"},"cancelledAt":{"S":"09/11/2023 6:35 PM"},"machineName":{"S":"LippmannJawCrusher"},"createdAt":{"S":"2023-11-20T12:30:40"},"status":{"S":"34.30"}},
    {"id":{"S":"1"},"sensorId":{"S":"internal_1"},"cancelledBy":{"S":""},"sensorName":{"S":"BearingOpp"},"EmailNotification":{"S":"2023-09-11T18:27:19"},"cancelledAt":{"S":"09/11/2023 6:35 PM"},"machineName":{"S":"LippmannJawCrusher"},"createdAt":{"S":"2023-11-20T12:45:40"},"status":{"S":"30.30"}},
    {"id":{"S":"1"},"sensorId":{"S":"internal_1"},"cancelledBy":{"S":""},"sensorName":{"S":"BearingOpp"},"EmailNotification":{"S":"2023-09-11T18:27:19"},"cancelledAt":{"S":"09/11/2023 6:35 PM"},"machineName":{"S":"LippmannJawCrusher"},"createdAt":{"S":"2023-11-20T13:01:40"},"status":{"S":"29.30"}},
    {"id":{"S":"1"},"sensorId":{"S":"internal_1"},"cancelledBy":{"S":""},"sensorName":{"S":"BearingOpp"},"EmailNotification":{"S":"2023-09-11T18:27:19"},"cancelledAt":{"S":"09/11/2023 6:35 PM"},"machineName":{"S":"LippmannJawCrusher"},"createdAt":{"S":"2023-11-20T13:15:40"},"status":{"S":"28.30"}},

//     {"Item":{"id":{"S":"2"},"sensorId":{"S":"internal_2"},"sensorName":{"S":"BearingCenterLeft"},"EmailNotification":{"S":"2023-09-11T16:39:03"},"cancelledAt":{"S":"09/11/2023 6:35 PM"},"status":{"S":"35.22"},"machineName":{"S":"LippmannJawCrusher"},"createdAt":{"S":"2023-09-29T22:46:40"}}},
// {"Item":{"id":{"S":"1"},"sensorId":{"S":"internal_1"},"cancelledBy":{"S":""},"sensorName":{"S":"BearingOpp"},"EmailNotification":{"S":"2023-09-11T18:27:19"},"cancelledAt":{"S":"09/11/2023 6:35 PM"},"machineName":{"S":"LippmannJawCrusher"},"createdAt":{"S":"2023-09-29T22:46:40"},"status":{"S":"32.30"}}},
// {"Item":{"id":{"S":"4"},"sensorId":{"S":"internal_4"},"sensorName":{"S":"BearingDrive"},"EmailNotification":{"S":"2023-09-11T18:34:20"},"cancelledAt":{"S":"09/11/2023 6:35 PM"},"machineName":{"S":"LippmannJawCrusher"},"createdAt":{"S":"2023-09-29T22:46:40"},"status":{"S":"32.97"}}},
// {"Item":{"id":{"S":"3"},"sensorId":{"S":"internal_3"},"sensorName":{"S":"BearingCenterRight"},"EmailNotification":{"S":"2023-09-11T18:51:23"},"cancelledAt":{"S":"09/11/2023 6:51 PM"},"status":{"S":"35.02"},"machineName":{"S":"LippmannJawCrusher"},"createdAt":{"S":"2023-09-29T22:46:40"}}}

]

// const tempAlerts = [{msg: "No signal or null signal", color: "blue", ts: "2023-11-21T12:01:40" },
// {msg: "Warning, temp rising", color: "yellow", ts: "2023-11-21T12:30:56"},
// {msg: "Warning, temp rising", color: "orange", ts: "2023-11-21T13:06:23"},
// {msg: "Temp too high", color: "red", ts: "2023-11-21T13:15:40"},
// {msg: "Warning, temp rising", color: "orange", ts: "2023-11-21T14:01:40"},
// {msg: "No signal or null signal", color: "blue", ts: "2023-11-21T14:06:40"}]

const tempAlerts = []

const tempAlertsSeverity = {
    "red": {color: "#660505" , background: "#eb1313b5" },
    "orange": {color: "#7f3c03" , background: "#ff842dd4" },
    "yellow": {color: "#7f6003" , background: "#f8d46a" },
    "blue": {color: "#01579B" , background: "#B3E5FC" }
}

const stationaryVehicle=["boom lift","hydra","crane"];

module.exports = {
    httpConstants,
    pHValues,
    ecValues,
    configFileData,
    thingNameMap, 
    poolData,
    moduleAccess,
    accessTitle,
    VEH_SERVICE_COLLECTIONS,
    WINDZONE_COLORS,
    WINDZONE_SPEED,
    SITE_MAPPING_SERVICE_LOGO,
    colorPalettes,
    barChartColors,
    pieChartColors,
    PUBLISHERS_CHANNEL,
    poolDataOld,
    bigRockMountainData,
    tempAlerts,
    tempAlertsSeverity,
    stationaryVehicle
}