import axios from "axios";


async function uploadSitesAPI(payload) {
    const res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/sites/update", payload);
    return res;
}

async function dowloadReport(payload){
    const res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/sites/report", payload);
    return res;
}

async function getUploadReport(payload,serviceID){
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-site-addresses-analysis", payload);
    return res.data;
}

const service = {
    uploadSitesAPI,
    dowloadReport,
    getUploadReport
};

export default service;
