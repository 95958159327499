import { combineReducers } from "redux";
import user from "./user";
import changeUnit from "./unit";
import stateList from "./stateList";
import thingNameContainer from "./locationSpecific";
const rootReducer = combineReducers({
    user,
    changeUnit,
    stateList,
    thingNameContainer
});

export default rootReducer;
