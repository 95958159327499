import React from 'react'

export const Procedures = () => {
    return (
        <div className="w-full h-full">
            <iframe src="https://s3.us-east-1.amazonaws.com/nq.aws.us.shared1.nextqore.images/ProceduresandManuals.pdf" title="Procedures and Manuals" className="w-full h-full">
            </iframe>
        </div>
    )
}
