import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { InputText } from "primereact/inputtext";
import XLXS from "sheetjs-style";
import { Button } from "primereact/button";
import configService from "../../service/configService";
import dbService from "../../service/dbService";
import tenantService from "../../service/tenant";
import { configFileData, thingNameMap } from "../../lib/constants";
import { Messages } from "primereact/messages";

const SiteDetails = {
    "Site ID": "",
    "Site Name": "",
    "Address 1": "",
    "Address 2": "",
    "Zip/Pincode": "",
    "Operating Country": "",
    "Lat/Long": "",
};

class DeviceConfig extends Component {
    messageRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            fleetName: null,
            fleetType: "Car",
            vehicleType: null,
            vehicleDescription: "",
            yearofManufacture: null,
            imei: null,
            errMsg: "",
            disSubmitBtn: false,
            itemImage: null,
        };
        this.serviceId = localStorage.getItem("serviceId");
        this.headerTemplate = this.headerTemplate.bind(this);

        this.type = [
            { name: "Car", code: "cars" },
            { name: "Truck", code: "trucks" },
        ];

        this.itemType = [
            { name: "DG", code: "dg" },
            { name: "AC", code: "ac" },
        ];

        addLocale("es", {
            firstDayOfWeek: 1,
            dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
            dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
            dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
            monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
            monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
            today: "Hoy",
            clear: "Limpiar",
        });
        this.getSensorMasterData = this.getSensorMasterData.bind(this);
    }

    componentDidMount = () => {
        Promise.all([dbService.getServiceDetails(this.serviceId), dbService.getAllowedImeis(this.serviceId)])
            .then((res) => {
                let service = res[0];
                let allowedImeis = res[1];
                let stateObj = {};

                if (service && service.length) {
                    stateObj.serviceData = service[0];
                    if (service[0].sensors) this.sensors = service[0].sensors;
                    if (service[0].gateway) this.gateway = service[0].gateway;
                }

                if (this.sensors && this.sensors.length) {
                    this.getSensorMasterData(this.sensors);
                    if (service[0].serviceBlock === "item") this.getRegions();
                }
                if (allowedImeis && allowedImeis.length) {
                    stateObj.allowedImeis = allowedImeis;
                }
                this.setState(stateObj);
            })
            .catch((e) => console.error(e));
    };

    async getSensorMasterData(sensors) {
        if (sensors && sensors.length) {
            await Promise.all(
                sensors.map(async (s) => {
                    let query = {};
                    let findObj = { make: s.make, model: s.model };

                    if (s.dataAttribute !== "Aggregate") findObj.parameterName = s.parameterName;
                    else query.projection = { _id: 0, parameterName: 0 };
                    query.filter = findObj;

                    const masterSensor = await dbService.getSensorMaster(query);
                    s = Object.assign(s, masterSensor[0]);
                })
            );
        }
    }

    getRegions = async () => {
        let regions = await tenantService.getRegions({ serviceAvailable: "active" });
        this.setState({ regions: regions });
    };

    onTypeChange = (e) => {
        this.setState({ vehicleType: e.value });
    };
    onIMEIChange = (e) => {
        this.setState({ imei: e.value });
    };

    headerTemplate(options) {
        const { className, chooseButton } = options;

        return (
            <div className={className} style={{ backgroundColor: "transparent", display: "flex", alignItems: "center" }}>
                {chooseButton}
            </div>
        );
    }

    onSubmit = () => {
        let res = this.validateFleetInput();
        if (typeof res === "string") {
            return this.setState({
                errMsg: res,
            });
        } else {
            this.setState({ disSubmitBtn: true });
            let jsonData = this.createJsonData(res);
            this.exportExcel(jsonData);
        }
    };

    createFleetName = (vehNo, vehMk) => {
        let res = vehNo + "_" + vehMk;
        if (res.length > 16) res = res.slice(0, 16);
        return res;
    };

    createItemName = (itemType, siteId) => {
        let res = itemType + siteId;
        if (res.length > 16) res = res.slice(0, 16);
        return res;
    };

    createJsonData = (res) => {
        let arr = [];
        let startFile = { "S No.": 0 };
        if (this.state.serviceData.serviceBlock === "fleet") {
            startFile["Fleet Name"] = res.name;
        }

        let obj = {
            "Gateway Name": "gw" + res["name"],
            "Gateway Make": this.gateway.make,
            "Gateway Model": this.gateway.model,
            "Gateway Supplier Id": "",
            "Gateway Supplier Name": "",
            "Cloud Connectivity": configFileData.cloudConnectivity,
            "Number of Paths": configFileData.numberOfPaths,
            "Network Provider Id 1": "",
            "Network Provider Name 1": "",
            "Network Provider Id 2": "",
            "Network Provider Name 2": "",
            "Keep Alive Frequency (Mins)": "",
            "Authentication String - Get": "",
            "Authentication String - Trap": "",
        };
        delete res.name;

        startFile = Object.assign(startFile, res);
        startFile = Object.assign(startFile, SiteDetails);
        startFile = Object.assign(startFile, obj);
        if (this.state.serviceData.serviceBlock === "fleet" && this.state.serviceData.location) {
            if (this.state.serviceData.location.zip) startFile["Zip/Pincode"] = this.state.serviceData.location.zip;
            if (this.state.serviceData.location.operatingCountry) startFile["Operating Country"] = this.state.serviceData.location.operatingCountry;
            if (this.state.serviceData.location.address1) startFile["Address1"] = this.state.serviceData.location.address1;
        }

        arr.push(startFile);

        if (this.sensors && this.sensors.length) {
            this.sensors.forEach((s, i) => {
                let sensorObj = {
                    "S No.": i + 1,
                    "Sensor Number": i + 1,
                    "Sensor type": s.type || s.parameterName,
                    "Sensor Make": s.make,
                    "Sensor Model": s.model,
                    "Supplier Id": "",
                    "Supplier Name": "",
                    "Parameter Name": s.parameterName,
                    "Operating Range(Min)": s.dataAttribute === "Aggregate" ? "" : s.operatingRangeMin,
                    "Operating Range(Max)": s.dataAttribute === "Aggregate" ? "" : s.operatingRangeMax,
                    "Cloud Connection": configFileData.cloudConnection,
                    "Reporting Method": configFileData.reportingMethod,
                    "Frequency of Data Publishing(Mins)": s.freq,
                    "Debounce Logic": "",
                    "Sensor Redundancy": configFileData.sensorRedundancy,
                    "Data Attribute": s.dataAttribute,
                    "Synchronous Groups": "",
                    ifNumber: "",
                    "Component Number": "",
                    "Component Name": "",
                    "Sub Component Number": "",
                    "Sub Component Name": "",
                    "Part Number": "",
                    "Part Name": "",
                    "Sensor Location Description": "",
                };
                arr.push(sensorObj);
            });
        }
        return arr;
    };

    validateFleetInput = () => {
        let res = {};

        if (!this.gateway || !(this.sensors && this.sensors.length)) return "Gateway and sensor information is not present, please contact to the our admin";
        if (!this.state.vehicleType) return "Please select the vehicle type";
        else res["Fleet Type"] = this.state.vehicleType.code;
        if (!this.state.vehNumber) return "Please enter the license plate number";
        else res["Vehicle Number"] = this.state.vehNumber;
        if (!this.state.alias) return "Please enter the alias for the vehicle";
        else res["Alias"] = this.state.alias;
        if (!this.state.vehMake) return "Please enter the vehicle make";
        else res["Vehicle Make"] = this.state.vehMake;
        if (!this.state.vehModel) return "Please enter the vehicle model";
        else res["Vehicle Model"] = this.state.vehModel;
        if (!this.state.vehicleDescription) return "Please enter the vehicle description";
        else res["Vehicle Description"] = this.state.vehicleDescription;
        if (!this.state.yearofManufacture) return "Please enter the year of manufacture";
        else res["Vehicle Manufacturing Date"] = new Date(this.state.yearofManufacture + "-01-01");
        if (!this.state.imei) return "Please enter the tracking device IMEI";
        else res["IMEI"] = this.state.imei.imei;
        if (!this.state.itemImage) return "Please upload the image";

        if (this.state.serviceData.serviceBlock === "fleet") {
            res["name"] = this.createFleetName(res["Vehicle Number"], res["Vehicle Make"]);
            // res['name'] = res["Fleet Name"];
        }

        res["Vehicle Batch Number"] = "";

        return res;
    };

    validateItemInput = () => {
        let res = { "Batch Number": "" };
        if (!this.gateway || !(this.sensors && this.sensors.length)) return "Gateway and sensor information is not present, please contact to the our admin";
        if (!this.state.itemType) return "Please select the item type";
        else res["Item Type"] = this.state.itemType;
        if (!this.state.itemMake) return "Please enter the item make";
        else res["Item Make"] = this.state.itemMake;
        if (!this.state.itemModel) return "Please enter the item model";
        else res["Item Model"] = this.state.itemModel;
        if (!this.state.size) return "Please enter size of the item";
        else res["Size"] = this.state.size;
        if (!this.state.instdt) return "Please enter the installation date";
        else res["Installation Date"] = this.state.instdt;

        if (!this.state.siteId) return "Please enter site id";
        else res["Site ID"] = this.state.siteId;
        if (!this.state.siteName) return "Please enter site name";
        else res["Site Name"] = this.state.siteName;
        if (!this.state.address1) return "Please enter address";
        else res["Address 1"] = this.state.address1;
        if (!this.state.address2) return "Please enter city and state";
        else res["Address 2"] = this.state.address2;
        if (!this.state.pincode) return "Please enter the zip/pincode";
        else res["Zip/Pincode"] = this.state.pincode;
        if (!this.state.country) return "Please select the country";
        else res["Operating Country"] = this.state.country;

        if (!this.state.lat) return "Please enter the latitude";
        else if (!this.state.lng) return "Please enter the longitude";
        else res["Lat/Long"] = this.state.lat + "," + this.state.lng;

        if (this.state.serviceData.serviceBlock === "item") {
            res["Item Name"] = this.createItemName(res["Item Type"], res["Site ID"]);
            res["name"] = res["Item Name"];
        }

        return res;
    };

    s2ab(s) {
        var buf = s;
        var view = new Uint8Array(s);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    exportExcel = (jsonData) => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
        const ws = XLXS.utils.json_to_sheet(jsonData, { cellDates: true, dateNF: "yyyy/mm/dd" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLXS.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });

        let formData = new FormData();
        formData.append("file", data, this.serviceId + ".xlsx");

        formData.append("serviceId", this.serviceId);
        this.messageRef.current.show({ severity: "info", content: "This process may take some time. Please do not close the window", life: 30000 });
        this.setState({
            disSubmitBtn: true,
            errMsg: "",
        });
        configService
            .uploadConfigFile(formData, this.serviceId)
            .then((resp) => {
                if (typeof resp.message === "string") {
                    this.uploadImage(jsonData)
                        .then((res) => {
                            if (res.responseCode === 200) {
                                console.info("Image uploaded sucessfully");

                                return this.setState(
                                    {
                                        errMsg: resp.message,
                                    },
                                    () => {
                                        this.props.history.push("/allThings");
                                    }
                                );
                            }
                        })
                        .catch((e) => {
                            console.error(e);
                            return this.setState({
                                errMsg: resp.message,
                            });
                        });
                }
            })
            .catch((e) => {
                return this.setState({
                    errMsg: e.message,
                    disSubmitBtn: false,
                });
            });
    };

    uploadImage = (jsonData) => {
        let formData = new FormData();
        let keyNM = "";
        if (this.state.serviceData.serviceBlock === "fleet") keyNM = "Fleet Name";
        else if (this.state.serviceData.serviceBlock === "item") keyNM = "Item Name";

        let thingName = jsonData[0][keyNM];
        formData.append("images", this.state.itemImage);
        formData.append("thing", this.state.serviceData.serviceBlock);
        formData.append("type", "");
        formData.append("folderName", this.serviceId);
        formData.append("newFileName", thingName + ".jpg");
        formData.append([this.state.serviceData.serviceBlock + "Name"], thingName);
        return configService.uploadImage(formData, this.serviceId);
    };

    handleDateChange = (e) => {
        // console.log("========date change===>", e)
    };

    fileUpload = (e) => {
        if (e.target.files && e.target.files.length) {
            document.getElementById("itemImage").src = URL.createObjectURL(e.target.files[0]);
            document.getElementById("itemImage").width = "200";
            document.getElementById("itemImage").height = "200";
            this.setState({
                itemImage: e.target.files[0],
            });
        }
    };

    getForm = () => {
        switch (this.state.serviceData.serviceBlock) {
            case "fleet":
                return (
                    <div className="col-12">
                        <div className="card">
                            <div className="grid">
                                <div className="col-12 lg:col-4">
                                    <div className="field">
                                        <label htmlFor="typeofVehicle" className="block">
                                            Type of Vehicle
                                        </label>
                                        <Dropdown value={this.state.vehicleType} options={this.type} onChange={this.onTypeChange} optionLabel="name" placeholder="Select a vehicle type" className="w-full" />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="licensePlateNo" className="block">
                                            License Plate No.
                                        </label>
                                        <InputText type="text" name="licensePlateNo" id="licensePlateNo" aria-describedby="licensePlateNo-help" className="w-full uppercase" onChange={(e) => this.setState({ vehNumber: e.target.value })} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="alias" className="block">
                                            Alias.
                                        </label>
                                        <InputText type="text" name="alias" id="alias" aria-describedby="alias-help" className="w-full" onChange={(e) => this.setState({ alias: e.target.value })} />
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 md:col-6 lg:md:col-6">
                                            <div className="field">
                                                <label htmlFor="make" className="block">
                                                    Make
                                                </label>
                                                <InputText type="text" name="make" id="make" aria-describedby="make-help" className="w-full" onChange={(e) => this.setState({ vehMake: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:md:col-6">
                                            <div className="field">
                                                <label htmlFor="model" className="block">
                                                    Model
                                                </label>
                                                <InputText type="text" name="model" id="model" aria-describedby="model-help" className="w-full" onChange={(e) => this.setState({ vehModel: e.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="vehicleDescription" className="block">
                                            Vehicle Description
                                        </label>
                                        <InputTextarea value={this.state.vehicleDescription} onChange={(e) => this.setState({ vehicleDescription: e.target.value })} rows={3} className="w-full" />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="yearofManufacture">Year of Manufacture</label>
                                        <InputText
                                            type="number"
                                            id="yearpicker"
                                            onChange={(e) => {
                                                this.setState({ yearofManufacture: parseInt(e.target.value) });
                                            }}
                                            className="w-full"
                                        />
                                    </div>
                                    {this.state.allowedImeis && this.state.allowedImeis.length ? (
                                        <div className="field">
                                            <label htmlFor="trackingDeviceIMEI" className="block">
                                                Tracking Device IMEI
                                            </label>
                                            <Dropdown value={this.state.imei} options={this.state.allowedImeis} onChange={this.onIMEIChange} optionLabel="imei" placeholder="Select a Device IMEI" className="w-full" />
                                        </div>
                                    ) : null}
                                    <div className="field">
                                        <label htmlFor="image">Upload Image(jpg only)</label>
                                        <br />
                                        <input type="file" accept="image/jpeg " onChange={(e) => this.fileUpload(e)}></input>
                                    </div>

                                    {this.state.errMsg ? (
                                        <div className="field">
                                            <label style={{ color: "red" }}>{this.state.errMsg}</label>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-12 lg:col-8">
                                    <img id="itemImage" alt="" />
                                </div>
                                <div className="col-12">
                                    <Button label="Save" aria-label="Save" disabled={this.state.disSubmitBtn} onClick={() => this.onSubmit()} />
                                    <Button label="Cancel" aria-label="Cancel" onClick={() => this.props.history.push("/allThings")} className="ml-3 p-button-outlined p-button-danger" />
                                </div>
                                <div className="col-12">
                                    <Messages ref={this.messageRef} />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "item":
                return (
                    <div className="col-12">
                        <div className="card">
                            <div className="grid">
                                <div className="col-12 lg:col-4">
                                    <div className="field">
                                        <label htmlFor="typeofVehicle" className="block">
                                            Type of Item
                                        </label>
                                        <Dropdown value={this.state.itemType} options={this.itemType} onChange={this.onTypeChange} optionLabel="name" placeholder="Select a item type" className="w-full" />
                                    </div>
                                    {/* <div className="field">
                                    <label htmlFor="licensePlateNo" className="block">
                                        License Plate No.
                                    </label>
                                    <InputText type="text" name="licensePlateNo" id="licensePlateNo" aria-describedby="licensePlateNo-help" className="w-full uppercase" onChange={(e) => this.setState({ "vehNumber": e.target.value })} />
                                </div> */}
                                    <div className="grid">
                                        <div className="col-12 md:col-6 lg:md:col-6">
                                            <div className="field">
                                                <label htmlFor="make" className="block">
                                                    Make
                                                </label>
                                                <InputText type="text" name="make" id="make" aria-describedby="make-help" className="w-full" onChange={(e) => this.setState({ itemMake: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:md:col-6">
                                            <div className="field">
                                                <label htmlFor="model" className="block">
                                                    Model
                                                </label>
                                                <InputText type="text" name="model" id="model" aria-describedby="model-help" className="w-full" onChange={(e) => this.setState({ itemModel: e.target.value })} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label htmlFor="size">Size</label>
                                        <InputText
                                            type="number"
                                            id="size"
                                            onChange={(e) => {
                                                this.setState({ size: parseInt(e.target.value) });
                                            }}
                                            className="w-full"
                                        />
                                    </div>

                                    <div className="field">
                                        <label htmlFor="size">Installation Date</label>
                                        <br />
                                        <Calendar id="instdt" value={this.state.instdt} onChange={(e) => this.setState({ instdt: e.target.value })} maxDate={new Date()} showIcon readOnlyInput style={{ width: "150px", marginRight: "10px" }} />
                                    </div>

                                    <div className="field">
                                        <label className="block">Site Id</label>
                                        <InputText type="text" id="siteid" aria-describedby="model-help" className="w-full" onChange={(e) => this.setState({ siteId: e.target.value })} />
                                    </div>

                                    <div className="field">
                                        <label className="block">Site Name</label>
                                        <InputText type="text" id="sitenm" aria-describedby="model-help" className="w-full" onChange={(e) => this.setState({ siteName: e.target.value })} />
                                    </div>

                                    <div className="field">
                                        <label className="block">Address</label>
                                        <InputText type="text" id="address1" aria-describedby="model-help" className="w-full" onChange={(e) => this.setState({ address1: e.target.value })} />
                                    </div>

                                    <div className="field">
                                        <label className="block">City & State</label>
                                        <InputText type="text" id="address2" aria-describedby="model-help" className="w-full" onChange={(e) => this.setState({ address2: e.target.value })} />
                                    </div>

                                    <div className="field">
                                        <label className="block">Zip/Pincode</label>
                                        <InputText type="number" id="address2" aria-describedby="model-help" className="w-full" onChange={(e) => this.setState({ pincode: parseInt(e.target.value) })} />
                                    </div>

                                    {this.state.regions && this.state.regions.length ? (
                                        <div className="field">
                                            <label htmlFor="country" className="block">
                                                Country
                                            </label>
                                            <Dropdown value={this.state.country} options={this.state.regions} onChange={(e) => this.setState({ country: e.target.value })} optionLabel="countryName" placeholder="Select the country" className="w-full" />
                                        </div>
                                    ) : null}
                                    {this.state.errMsg ? (
                                        <div className="field">
                                            <label style={{ color: "red" }}>{this.state.errMsg}</label>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-12">
                                    <Button label="Save" aria-label="Save" onClick={() => this.onSubmit()} />
                                    <Button label="Cancel" aria-label="Cancel" className="ml-3 p-button-outlined p-button-danger" />
                                </div>
                            </div>
                        </div>
                    </div>
                );
        }
    };

    render = () => {
        return (
            <div className="col-12">
                {this.state.serviceData ? (
                    <div className="col-12">
                        <h3>{"Add new " + thingNameMap[this.state.serviceData.serviceBlock]}</h3>
                    </div>
                ) : null}
                {this.state.serviceData ? this.getForm() : null}
            </div>
        );
    };
}

export default DeviceConfig;
