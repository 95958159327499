import React from "react";
import { useEffect, useRef } from "react";
import moment from "moment";
import { useState } from "react";
import { mapKey } from "../../utilities/constant";
import { Employee } from "../../service/Employee";
import { connect } from "react-redux";
import EmployeeLiveReport from "./employeeLiveReport";
import { InputText } from "primereact/inputtext";

function EmployeeLiveLocation() {
    const serviceId = localStorage.getItem("serviceId");
    const [empMeta, setEmpMeta] = useState(null);
    const [things, setThings] = useState([]);
    const [empMetaAndLoc, setEmpMetaAndLoc] = useState([]);
    const [copyEmpMetaAndLoc, setCopyEmpMetaAndLoc] = useState([]);
    const [detailsReport, setDetailReport] = useState(false);
    const [selectedEmp, setSelectedEmp] = useState(null);
    const [backBtn, setBackButton] = useState(false);
    const serviceDetails = JSON.parse(localStorage.getItem("service"));
    const mapRef = React.createRef();
    const employeeService = new Employee();
    const [map, setMap] = useState(null);
    const [group, setGroup] = useState(null);
    const [search, setSearch] = useState("");
    const [geofences, setGeoFences] = useState(null);
    const timerRef = useRef(null);
    useEffect(() => {
        // employeeService.getGeoFence(serviceId)
        //     .then((geofences) => {
        //         setGeoFences(geofences);
        //         return employeeService.getAllEmployee(serviceId);
        //     })
            employeeService.getAllEmployee(serviceId)
            .then((res) => {
                if (res.responseCode === 200) {
                    let empMetaMap = {};
                    if (res.responseData && res.responseData.length) {
                        let things = res.responseData.map((a) => {
                            if (a.thingName) empMetaMap[a.thingName] = a;
                            return a.thingName;
                        });
                        setEmpMeta(empMetaMap);
                        setThings(things);
                    }
                } else {
                    console.error(res);
                }
            })
            .catch((e) => {
                console.error(e);
            });

        return () => {
            window.clearTimeout(timerRef.current);
        };
    }, []);

    useEffect(() => {
        if (things && things.length) getEmployeeData();
    }, [things]);

    const getEmployeeData = () => {
        employeeService
            .getLatestRecord(serviceId, {
                filter: {
                    $and: [
                        { ts: { $gt: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss") } },
                        {
                            thingName: {
                                $in: things,
                            },
                        },
                    ],
                },
            })
            .then((latestData) => {
                if (latestData && latestData.length) {
                    latestData.forEach((l) => {
                        if (empMeta[l.thingName]) l = Object.assign(l, empMeta[l.thingName]);
                    });
                    setEmpMetaAndLoc(latestData);
                    setCopyEmpMetaAndLoc(latestData);
                    if (timerRef.current) window.clearTimeout(timerRef.current);
                    timerRef.current = setTimeout(() => {
                        getEmployeeData();
                    }, 900000);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    useEffect(() => {
        if (empMetaAndLoc.length) {
            getMap(empMetaAndLoc);
        }
    }, [empMetaAndLoc]);

    const addGeoFencesToMap = (H, group, ui) => {
        if (geofences && geofences.length) {
            geofences.forEach((g) => {
                let polygon = H.util.wkt.toGeometry(g.data);
                let geoFence = new H.map.Polygon(polygon, { style: { strokeColor: "rgba(128, 0, 0, 0.6)" }, data: g });
                geoFence.addEventListener("tap", function (evt) {
                    let data = evt.target.getData();
                    let bubble = new H.ui.InfoBubble(
                        { lat: data.lat, lng: data.lon },
                        {
                            content: `<div id="vehicle-content"><div id="vehicle-info"><div id="info">${data.name} </div></div></div>`,
                        }
                    );
                    ui.addBubble(bubble);
                });
                group.addObject(geoFence);
            });
        }
    };

    const getMap = (empMetaAndLoc) => {
        if (map) {
            let objects = group.getObjects();
            empMetaAndLoc.forEach((e) => {
                if (e.thingName) {
                    let objectIndex = objects.findIndex((o) => o.data.thingName === e.thingName);

                    if (objectIndex > -1) {
                        objects[objectIndex].setGeometry({ lat: e.lat, lng: e.lon });
                        map.getViewModel().setLookAtData({
                            bounds: group.getBoundingBox(),
                            zoom: 12,
                        });
                    }
                }
            });

            setEmpMetaAndLoc(empMetaAndLoc);
        } else {
            const H = window.H;
            const platform = new H.service.Platform({
                apikey: mapKey,
            });
            const defaultLayers = platform.createDefaultLayers();
            const map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
                center: { lat: empMetaAndLoc[0].lat, lng: empMetaAndLoc[0].lon },
                zoom: 5,
                pixelRatio: window.devicePixelRatio || 1,
            });
            new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
            const ui = H.ui.UI.createDefault(map, defaultLayers);

            let markers = [];
            let group = new H.map.Group();

            for (let i = 0; i < empMetaAndLoc.length; i++) {
                let mark;
                let station = { lat: empMetaAndLoc[i].lat, lng: empMetaAndLoc[i].lon, ts: empMetaAndLoc[i].ts };

                mark = getMarker(H, station, empMetaAndLoc[i], ui);
                markers.push(mark);
            }
            // addGeoFencesToMap(H, group, ui);
            group.addObjects(markers);
            map.addObject(group);
            // get geo bounding box for the group and set it to the map
            map.getViewModel().setLookAtData({
                bounds: group.getBoundingBox(),
                // zoom: 13
            });
            setGroup(group);
            setMap(map);
        }
    };

    const getMarker = (H, station, data, ui) => {
        let svgMarkup =
            '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path style="fill:#01010100; stroke:none;" d="M0 0L0 32L32 32L32 0L0 0z"/><path style="fill:#ef3d49; stroke:none;" d="M15 28L17 28C19.368 24.4869 21.95 20.9627 23.5208 17C31.413 -2.9091 1.1202 -3.05081 8.2716 16C9.88256 20.2915 12.4852 24.1989 15 28z"/><path style="fill:#ef2121; stroke:none;" d="M15 5L16 6L15 5z"/><path style="fill:#ffffff; stroke:none;" d="M13.7461 7.03241C10.2098 9.89313 14.9493 15.6823 18.3927 12.3781C21.696 9.20841 17.2399 4.20618 13.7461 7.03241z"/><path style="fill:#ff166b; stroke:none;" d="M12 7L13 8L12 7z"/><path style="fill:#36d1ba; stroke:none;" d="M5 27L5 29C11.8299 31.882 20.17 31.882 27 29L27 27L20 25L20 27L24 27L24 28L8 28L8 27L12 27L12 25L5 27z"/></svg>';

        let bearsIcon = new H.map.Icon(svgMarkup);
        let marker = new H.map.Marker(station, { icon: bearsIcon, data: data });
        let bubble;

        marker.addEventListener(
            "tap",
            function (evt) {
                let empData = evt.target.getData();
                bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                    content: `<div id="vehicle-content"><div id="vehicle-info"><div id="info">${empData.name} </div></div></div>`,
                });
                ui.addBubble(bubble);
            },
            false
        );

        return marker;
    };

    const onEmpClick = (emp) => {
        setSelectedEmp(emp);
        setMap(null);
        setGroup(null);
        window.clearTimeout(timerRef.current);
        setDetailReport(true);
        setBackButton(false);
    };

    const reportBack = () => {
        setDetailReport(false);
        setBackButton(true);
    };

    useEffect(() => {
        if (backBtn) {
            getMap(empMetaAndLoc);
        }
    }, [backBtn]);

    const handleSearch = (val) => {
        setSearch(val);
        let data = copyEmpMetaAndLoc.filter((item) => item.name.includes(val) || item.email.includes(val));
        setEmpMetaAndLoc(data);
    };
    return detailsReport ? (
        <EmployeeLiveReport emp={selectedEmp} reportBack={reportBack} geofences={geofences} />
    ) : (
        <div className="grid flex-row-reverse m-0">
            <div className="col-12">
                <h3 style={{ margin: "0", textAlign: "center", padding: "10px 0" }}>Team's Location</h3>
            </div>
            <div className="col-12 md:col-6 lg:col-8">
                <div className="card p-0">
                    <div ref={mapRef} className="list_map_grid"></div>
                </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4">
                <div className="p-input-icon-right col-12">
                    <InputText value={search} onChange={(e) => handleSearch(e.target.value)} className="bg-white w-full" placeholder="Search" />
                </div>
                <br />
                <div className="vehicle_list_grid" style={{ paddingTop: "2vh" }}>
                    {empMetaAndLoc && empMetaAndLoc.length
                        ? empMetaAndLoc.map((item) => (
                              <div className="card report_opt_card mb-3" key={item.email} onClick={(e) => onEmpClick(item)}>
                                  <div>
                                      {/* <div className="">
                                    <Image className="mr-2" src={process.env.REACT_APP_IMAGE_STATIC_URL + "/" + this.serviceID + "/" + item.thingName + ".jpg"} alt="Image" width="65" height="65" preview />
                                </div> */}
                                      <div className="flex-1" style={{ fontSize: "1.1rem" }}>
                                          <div className="font-medium color-1 mb-0 ">{item.name}</div>
                                          <div className="font-medium color-1 mb-0 ">{item.email}</div>
                                          <div className="text-md font-medium mb-0">
                                              Last Data Received : <span className="text-blue-500 mr-3">{moment(item.ts).fromNow()}</span>
                                          </div>

                                          <div className="text-md font-medium mb-0">
                                              On: <span className="text-blue-500 mr-3 ">{serviceDetails.region === "us-east-1" || serviceDetails.region === "us" || serviceDetails.region === "US" ? moment(item.ts).format("MMM DD,YYYY HH:mm") : moment(item.ts).format("Do MMM, YYYY HH:mm")}</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          ))
                        : null}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return { unit: state.changeUnit };
}

export default connect(mapStateToProps)(EmployeeLiveLocation);
