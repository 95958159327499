import React from 'react'
import { Reports } from './reports';

const sixMonth=["Clean transaction should have same class as per Mapper Vehicle Class, AVC Vehicle Class and Vehicle class"]
const queryMap={
    
    "Clean transaction should have same class as per Mapper Vehicle Class, AVC Vehicle Class and Vehicle class":{
        collection: "nq-CleanTrass_diffVehClass_quarterly",
            payload: {
                filter: {},
                projection: { _id: 0, plazacode: 1, vehno: 1, dt: 1,month:1,mvc:1,transno:1,paymentmethod:1 }
            },
            field: ["vehno","plazacode",  "dt","month","mvc","transno","paymentmethod"],
            head: ["Vehicle Number","plaza" ,"Time", "Month","Vehicle Class","Transaction Number","Payment Method"]

    }
    
};

export const SixMonthly = () => {

  return (
    <div><Reports all={sixMonth} queryMap={queryMap} type={"Quaterly"} background={"bg-green-700"} /></div>
  )
}
