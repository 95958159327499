import React, { useState, useEffect, useContext, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import PhoneInput,{ isValidPhoneNumber} from 'react-phone-number-input';
import { StateContext } from '../utilities/context/context';
import TenantService from "../service/tenant";
import { Toast } from 'primereact/toast';

const ConfirmSignup = () => {
    const [mobile, setMobile] = useState("");
    const [code, setCode] = useState(null);
    const [errMsg, setErrMsg] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);
    const history = useHistory();
    const {countryCode, countryCodeVal, fetchUserCountryCode,setCountryCodeVal}  = useContext(StateContext)
    const toast = useRef();

    useEffect(()=>{
        fetchUserCountryCode()
    },[])
    const validateInputs = () => {
        if(!mobile) return "Please enter the phone number";
        if(!code) return "Please enter the verification code";
        if(mobile && !isValidPhoneNumber(mobile)) return "Invalid phone number" 
    }

    const onConfirm = async() => {
        setErrMsg("")
        let validationRes = validateInputs();

        if (typeof validationRes === "string") {
           setErrMsg(validationRes);
        }
        else {
            setDisableBtn(true);
            let userData = {
                username: mobile,
                confirmationCode : code
            };

            let confirmSignupRes = await TenantService.confirmSignup(userData)

            if(confirmSignupRes && !Object.keys(confirmSignupRes.responseData).length && !confirmSignupRes.success){
                setErrMsg(confirmSignupRes.message.message)
                setDisableBtn(false);
                return
            }
            toast.current.show({ severity: 'success', content: "User Confirmed" });
            setTimeout(() => {
                history.push("/login");
            }, 2000)

        }
    }

    return (
        <div className="pages-body flex flex-column login-img">
            <Toast ref={toast} />
            <div className=" align-self-center  mt-auto mb-auto">
                <div className=" card flex flex-column col-12" style={{ color: "#616161" }}>
                    <div>
                        <img alt="" src="./images/nexLogo.svg" className="logo-fixing" /><br />
                    </div>
                    <div className="mb-3 px-3 font-bold align-self-center">Enter Your Phone No and Verification Code</div>
                    <div className="mb-1 px-3">
                        <label>Phone Number</label>
                    </div>
                    <div className="mb-3 px-3">
                    <PhoneInput placeholder="Enter phone number" className="p-inputtext p-component p-filled w-full" value={mobile}  onChange={setMobile} international defaultCountry={countryCode} />
                    </div>
                    <div className="mb-1 px-3">
                        <label>Verification Code</label>
                    </div>
                    <div className="mb-3 px-3">
                        <InputText className="col-12" type="text" id="code" onChange={(e) => setCode(e.target.value)} />

                    </div>
                    <div className="mb-3 px-3" style={{color: "red"}}>{errMsg}</div>
                    <div className="mb-3 px-3">
                        <Button className="login-button mb-3 px-3 col-12" label="Sign Up" disabled={disableBtn} onClick={onConfirm}></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmSignup;
