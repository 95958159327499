import { httpService, manageFormRequest } from "./httpService";
import { httpConstants } from "../lib/constants";
import axios from "axios"

function getTenantServiceUrl() {
    return process.env.REACT_APP_TENANT_SERVICE_URL;
     //return 'http://localhost:3002';
}

async function getPermissions() {
    let url = getTenantServiceUrl() + httpConstants.API_END_POINT.GET_USER_PERMISSIONS;
    return httpService(httpConstants.METHOD_TYPE.POST, null, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0) return Promise.reject();
            return Promise.resolve(response.responseData);
        })
        .catch(function (err) {
            return Promise.reject(err);
        });
}

async function getMenuOptions() {
    let url = getTenantServiceUrl() + httpConstants.API_END_POINT.GET_SERVICES_MENU;
    return httpService(httpConstants.METHOD_TYPE.POST, null, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0) return Promise.reject();
            return Promise.resolve(response.responseData);
        })
        .catch(function (err) {
            return Promise.reject(err);
        });
}

async function getRegions(requestData) {
    let url = getTenantServiceUrl() + httpConstants.API_END_POINT.GET_REGIONS;
    return httpService(httpConstants.METHOD_TYPE.POST, requestData, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) return Promise.reject();
            return Promise.resolve(response.responseData);
        })
        .catch(function (err) {
            return Promise.reject(err);
        });
}

async function getUsers(requestData) {
    let url = getTenantServiceUrl() + httpConstants.API_END_POINT.TENANT_GET_USERS;
    return httpService(httpConstants.METHOD_TYPE.POST, requestData, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0) return Promise.reject();
            return Promise.resolve(response.responseData);
        })
        .catch(function (err) {
            return Promise.reject(err);
        });
}

async function addUserByCustomer(requestData) {
    let url = getTenantServiceUrl() + httpConstants.API_END_POINT.ADD_USER_BY_CUSTMER;
    return await manageFormRequest(httpConstants.METHOD_TYPE.POST, requestData, url);
}

async function updateUserPermission(requestData) {
    let url = getTenantServiceUrl() + httpConstants.API_END_POINT.UPDATE_USER_PERMISSION;
    return await manageFormRequest(httpConstants.METHOD_TYPE.POST, requestData, url);
}

async function allowTAndCForServices(requestData) {
    let url = getTenantServiceUrl() + httpConstants.API_END_POINT.ALLOW_T_AND_C_FOR_SERVICES;
    return httpService(httpConstants.METHOD_TYPE.POST, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function loginUser(requestData) {
    let url = getTenantServiceUrl() + "/user-login";
    let response = await axios({
        method: "post",
        url: url,
        data: requestData,
        headers: { "Content-Type": "application/json" },
    });
    return response.data;
}

async function forgotSendCode(requestData) {
    let url = getTenantServiceUrl() + "/forgot-send-code";
    let response = await axios({
        method: "post",
        url: url,
        data: requestData,
        headers: { "Content-Type": "application/json" },
    });
    return response.data;
}

async function forgotCNFPswd(requestData) {
    let url = getTenantServiceUrl() + "/forgot-cnf-pswd";
    let response = await axios({
        method: "post",
        url: url,
        data: requestData,
        headers: { "Content-Type": "application/json" },
    });
    return response.data;
}

async function confirmSignup(requestData) {
    let url = getTenantServiceUrl() + "/confirm-signup";
    let response = await axios({
        method: "post",
        url: url,
        data: requestData,
        headers: { "Content-Type": "application/json"},
    });
    return response.data;
}

const service = {
    getPermissions,
    getMenuOptions,
    getRegions,
    getUsers,
    addUserByCustomer,
    updateUserPermission,
    allowTAndCForServices,
    loginUser,
    forgotSendCode,
    forgotCNFPswd,
    confirmSignup
};

export default service;
