import React, { useEffect, useState } from "react";
import { Panel } from "primereact/panel";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import DBService from "../../service/dbService";
import { colorPalettes } from "../../utilities/constant";
import { Dropdown } from "primereact/dropdown";
import WeatherChart from "../ChartsComponents/weatherChart";
import { Message } from "primereact/message";
import { Skeleton } from "primereact/skeleton";
import { useSelector } from "react-redux";
import { convertValue } from "../../utilities/unitConversion";
var citytimerId;

const WeatherTrends = () => {
    const [states, setStates] = useState(null);
    const [cities, setCities] = useState(null);
    const [zipcodes, setZipcodes] = useState(null);
    const [siteIds, setSiteIds] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedZip, setSelectedZip] = useState(null);
    const [selectedSite, setSelectedSite] = useState(null);

    const [infoMsg, setInfoMsg] = useState("");
    const [errMsg, setErrMsg] = useState(null);
    const [search, setSearch] = useState("");

    const [tempChartData, setTempChartData] = useState(null)
    const [humidityChartData, setHumidityChartData] = useState(null)
    const [percipitationChartData, setPercipitationChartData] = useState(null)
    const [windspeedChartData, setWindSpeedChartData] = useState(null)

    const serviceId = localStorage.getItem("serviceId");
    const myStates = JSON.parse(localStorage.getItem("states"));
    const unit = useSelector(state => state.changeUnit)

    useEffect(() => {
        const getStateWiseCount = async () => {
            let query = {
                projection: { latlongs: 0, _id: 0, midlat: 0, midlong: 0, count: 0 },
            };
            if (myStates.length > 0) {
                query = { filter: { st: { $in: myStates } }, ...query }
            }
            DBService.getStateWiseSites(query).then((res) => {
                if (res && res instanceof Array && res.length) setStates(res);
                else setInfoMsg("Sites upload pending");
            });
        };
        getStateWiseCount();
    }, []);

    const template = (options) => {
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <span className={titleClassName}>
                    <i className="pi-pw pi pi-filter"></i> Filter
                </span>
            </div>
        );
    }
    const onStateChange = (e) => {
        setBtnLoading(true);
        setCities([]);
        setZipcodes([]);
        setSelectedCity(null);
        setSelectedZip(null);
        setSelectedState(e.value);
    }
    const onCityChange = (e) => {
        setBtnLoading(true);
        setSelectedCity(e.value);
    }
    const onZipCodeChange = (e) => {
        setBtnLoading(true);
        setSelectedZip(e.value);
    }
    const onFilterSubmit = async () => {
        if (!selectedCity && !selectedZip) return setErrMsg("Please select a city or zip to proceed further");
        if (!selectedSite) return setErrMsg("Please select the site id")
        let query = {
            filter: { siteid: selectedSite.sid }, projection: { avgtemp_c: 1, avghumidity: 1, date: 1, totalprecip_mm: 1, maxwind_kph: 1, _id: 0 }
        }
        let weatherData = await DBService.getHistoricalWeatherData(serviceId, query)
        let time = [], temp = [], humidity = [], percipitation = [], windSpeed = [];
        weatherData.sort((a, b) => {return new Date(a.date) - new Date(b.date)}).forEach(w => {
            temp.push(parseFloat(convertValue(w.avgtemp_c,"temperature", unit,  null, true)));
            humidity.push(w.avghumidity)
            time.push(w.date)
            percipitation.push(convertValue(w.totalprecip_mm,"precipitation", unit,  null,true))
            windSpeed.push(convertValue(w.maxwind_kph,"speed", unit,  null, true))
        })

        const chart = {
            data: [{ name: "Temperature", data: temp, color: colorPalettes.color1 }],
            category: time,
            title: "Temperature Trend",
            yAxisTitle: `Temperature in  ${unit==="MKS"?"\xB0C":"°F"}`,
            yAxixLabelColor: colorPalettes.color1
        };
        const humidityChart = {
            data: [{ name: "Humidity", data: humidity, color: colorPalettes.color2 }],
            category: time,
            title: "Humidity Trend",
            yAxisTitle: "Humidity",
            yAxixLabelColor: colorPalettes.color2
        }
        const percipitationChart = {
            data: [{ name: "Percipitation ", data: percipitation, color: colorPalettes.color3 }],
            category: time,
            title: "Percipitation Trend",
            yAxisTitle: `Percipitation in ${unit==="MKS"?"mm":"inches"}`,
            yAxixLabelColor: colorPalettes.color3
        }
        const windSpeedChart = {
            data: [{ name: "Wind Speed", data: windSpeed, color: colorPalettes.color4 }],
            category: time,
            title: "Max wind Speed Trend",
            yAxisTitle: `Speed in ${unit==="MKS"?"kph":"mph"}`,
            yAxixLabelColor: colorPalettes.color4
        }

        setTempChartData(chart)
        setHumidityChartData(humidityChart)
        setPercipitationChartData(percipitationChart)
        setWindSpeedChartData(windSpeedChart)
        setErrMsg("")
    }
    const onReset = () => {
        setCities([])
        setZipcodes([])
        setSelectedState(null)
        setSelectedCity(null)
        setSelectedZip(null)
        setSelectedSite(null)
        setTempChartData(null)
        setHumidityChartData(null)
        setPercipitationChartData(null)
        setWindSpeedChartData(null)
        setErrMsg(null)
        setSearch("")
    }
    const getCitiesFromDb = async (states) => {
        if (states && states.length) {
            let st = selectedState.map((s) => s.st);
            let query = { filter: { st: { $in: st } }, projection: { _id: 0, latlongs: 0, midlat: 0, midlong: 0, count: 0, st: 0 } };
            let zipquery = { filter: { st: { $in: st } }, projection: { _id: 0, latlongs: 0, midlat: 0, midlong: 0, count: 0, st: 0 } };
            Promise.all([DBService.getSitesCities(query), DBService.getSitesZipcodes(zipquery)]).then((res) => {
                let cities = res[0];
                let zipcodes = res[1];
                setCities(cities);
                setZipcodes(zipcodes);
                setBtnLoading(false);
            });
        }
    }
    useEffect(() => {
        cityDebounceFunction(getCitiesFromDb, 2000, selectedState);
    }, [selectedState]);
    const cityDebounceFunction = (func, delay, params) => {
        clearTimeout(citytimerId);
        citytimerId = setTimeout(() => {
            func(params);
        }, delay);
    }
    useEffect(() => {
        cityDebounceFunction(getSiteIds, 2000, { selectedCity, selectedZip })
    }, [selectedCity, selectedZip])
    const onSiteIdChange = (e) => {
        setSelectedSite(e.value);
    }
    const getSiteIds = (params) => {
        if ((params.selectedCity instanceof Array && params.selectedCity.length) || (params.selectedZip instanceof Array && params.selectedZip.length)) {
            let query, funcArray = [];
            if (params.selectedCity && params.selectedCity.length) {
                let cid = selectedCity.map((s) => s.ct);
                query = { operation: "distinct", key: 'latlongs.sid', query: { ct: { $in: cid } } }
                funcArray.push(DBService.getCitiesGropingState(serviceId, query))
            }
            if (params.selectedZip && params.selectedZip.length) {
                let zipIds = selectedZip.map((s) => s.zip);
                query = { operation: "distinct", key: 'latlongs.sid', query: { zip: { $in: zipIds } } }
                funcArray.push(DBService.getZipsGroupingCity(serviceId, query))
            }
            Promise.all(funcArray).then((res) => {
                let sites = new Set()
                res.forEach(r => {
                    r.forEach(s => { sites.add(s) })
                })
                setSiteIds([...sites].map(s => { return { sid: s } }))
                setBtnLoading(false);
            });
        }
    }

    return states && states.length ? (
        <>
            <div className=" lg:col-offset-3 lg:col-6 md:col-offset-2 md:col-8 color1-bg font-bold card " style={{ fontSize: "clamp(12px, 4vw, 26px)", minHeight: "50px", textAlignLast: "center" }}>
                <div className="p-2">Site wise Detailed Weather Trend</div>
            </div>
            <div>
                <Panel headerTemplate={template} collapsed={false}>
                    <div className="grid">
                        <div className="col-12 md:col-2">
                            <MultiSelect value={selectedState} options={states} onChange={onStateChange} optionLabel="st" placeholder=" Select State" className="m-2 bg-white w-full" filter />
                        </div>
                        {cities && cities.length ? (
                            <div className="col-12 md:col-2">
                                <MultiSelect value={selectedCity} onChange={onCityChange} options={cities} optionLabel="ct" className="m-2 bg-white w-full" placeholder="Select Cities" filter />
                            </div>
                        ) : null}
                        {zipcodes && zipcodes.length ? (
                            <div className="col-12 md:col-2">
                                <MultiSelect value={selectedZip} onChange={onZipCodeChange} options={zipcodes} optionLabel="zip" className="m-2 bg-white w-full" placeholder="Select Zipcodes" filter />
                            </div>
                        ) : null}
                        {siteIds && siteIds.length ? (
                            <div className="col-12 md:col-2">
                                <Dropdown value={selectedSite} options={siteIds} onChange={onSiteIdChange} optionLabel="sid" placeholder=" Select Sites" className="m-2 bg-white w-full" filter />
                            </div>
                        ) : null}
                        <div className="col-12 md:col-2">
                            <Button label="Search" className="m-2" loading={btnLoading} onClick={onFilterSubmit} />
                        </div>
                        <div className="col-12 md:col-1">
                            <Button label="Reset" className="p-button-danger m-2" loading={btnLoading} onClick={onReset} />
                        </div>
                        <div className="col-12">{errMsg ? <span style={{ color: "red" }}>{errMsg}</span> : null}</div>

                    </div>
                    <div>
                        <Message style={{ background: "none" }} text="Apply Filters to see each site detailed weather trends" />
                    </div>
                </Panel>
            </div>
            {tempChartData ?
                <div className="col-12 card mt-3">
                    <WeatherChart chartData={tempChartData} />
                </div>
                : null}
            {humidityChartData ?
                <div className="col-12 card mt-3">
                    <WeatherChart chartData={humidityChartData} />
                </div>
                : null}
            {percipitationChartData ? <div className="col-12 card mt-3">
                <WeatherChart chartData={percipitationChartData} />
            </div>
                : null}
            {windspeedChartData ?
                <div className="col-12 card mt-3">
                    <WeatherChart chartData={windspeedChartData} />
                </div>
                : null}
        </>
    ): infoMsg ? (
        <div className="col-12">
            <div className="card flex flex-column align-items-center justify-content-center color3-bg " style={{ height: "60vh" }}>
                <span className="pi pi-info-circle" style={{ fontSize: "3rem" }}></span>
                <h3>{infoMsg}</h3>
            </div>
        </div>
    ) : (
        <Skeleton height="50px" className="mb-2"></Skeleton>
    );
}

export default WeatherTrends;