import { useEffect, useState, useRef } from "react";
import dbservices from "../../service/dbService";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import ProjectGrid from "./components/ProjectGrid";
import ProjectList from "./components/ProjectList";
import { userFSAccess } from "../../utilities/constant";
import Unauth from "./components/Unauth";
import { userRoleMap } from "../../utilities/utillFunction";

const ManageProject = () => {
    const toast = useRef(null);
    const service = JSON.parse(localStorage.getItem("service"));
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const [myRole, setMyRole] = useState(null);
    const [access, setAccess] = useState(null);
    const [projectName, setProjectName] = useState("");
    const [projects, setProjects] = useState([]);
    const [layoutOpt, setLayoutOpt] = useState("Grid");
    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showProject, setShowProject] = useState(false);
    const [path, setPath] = useState(null);
    const [validate, setValidate] = useState(false);
    const [unauthorized, setUnauthorized] = useState(false);
    const [isAdmin, setIsadmin] = useState(false);

    useEffect(() => {
        let accessID = getAccessId(permissions, service.links);
        if (accessID == "Admin") {
            setIsadmin(true);
        } else if (accessID === "unauthorized") {
            setUnauthorized(true);
        } else {
            setAccess(accessID);
            getProjectsList(accessID.write);
        }
    }, []);

    function getProjectsList(accessID) {
        dbservices
            .getProjectList(accessID)
            .then((res) => {
                if (res && res.status === "ok") {
                    setProjects(res.results);
                }
            })
            .catch((err) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
            });
    }

    function getAccessId(per, links) {
        let myAccessPermision = per.filter((value) => userFSAccess.includes(value));
        if (JSON.stringify(myAccessPermision) === JSON.stringify(["Admin_READ", "Admin_WRITE"])) {
            return "Admin";
        } else if (myAccessPermision.length) {
            let role = per[0].split("_")[0];
            setMyRole(role);
            return {
                read: links[`${role}_READ`],
                write: links[`${role}_WRITE`],
            };
        } else {
            return "unauthorized";
        }
    }
    function handelsubmit(ev) {
        setIsDisabled(true);
        ev.preventDefault();
        if (!projects.some((elm) => elm.name.toLowerCase() === projectName.toLowerCase())) {
            setValidate(false);
            dbservices
                .addProject(access.write, projectName)
                .then((res) => {
                    setShow(false);
                    getProjectsList(access.write);
                    toast.current.show({ severity: "success", summary: "Success", detail: "Project created", life: 3000 });
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
                })
                .finally(() => setIsDisabled(false));
        } else {
            setValidate(true);
            setIsDisabled(false);
        }
    }
    function showDetails(path) {
        setShowProject(true);
        setPath(path);
    }

    return !unauthorized ? (
        isAdmin ? (
            <div className="grid w-100 justify-content-center align-items-center" style={{ height: "80vh" }}>
                <div className="text-center d-block">
                    <p>Please use the "Workplace" menu to view the projects.</p>
                </div>
            </div>
        ) : (
            <div className="grid">
                <Toast ref={toast} />
                <Dialog header="Add Project" visible={show} style={{ width: "fit-content" }} onHide={() => setShow(false)}>
                    <div className="grid">
                        <div className="col-12">
                            <form className="p-5" onSubmit={handelsubmit}>
                                <span className="p-float-label mb-2">
                                    <InputText name="projectname" id="project" value={projectName} onChange={(e) => setProjectName(e.target.value)} style={{ width: "300px" }} />
                                    <label htmlFor="project">Project Name</label>
                                    {validate && (
                                        <small id="username2-help" className="p-error block">
                                            Project already exist.
                                        </small>
                                    )}
                                </span>
                                <Button style={{ width: "100%" }} label="Add" type="submit" disabled={projectName === "" || isDisabled ? true : false} />
                            </form>
                        </div>
                    </div>
                </Dialog>
                <Dialog header="Project" visible={showProject} style={{ width: "70vw" }} onHide={() => setShowProject(false)}>
                    <div className="grid">
                        <div className="col-12">
                            <iframe title="filestash" src={path} style={{ width: "100%", height: "80vh" }}></iframe>
                        </div>
                    </div>
                </Dialog>
                <div className="md:col-8 col-12">
                    <div className="w-100 flex justify-content-between">
                        <h3 className="mb-3">My Projects({userRoleMap(service, myRole)})</h3>
                        <div>
                            {/* <Button icon="pi pi-list" onClick={() => setLayoutOpt("List")} className={layoutOpt === "List" ? "mr-2 p-button-info" : "mr-2 p-button-outlined p-button-info"} />
                        <Button icon="pi pi-th-large" onClick={() => setLayoutOpt("Grid")} className={layoutOpt === "Grid" ? "mr-2 p-button-info" : "mr-2 p-button-outlined p-button-info"} /> */}
                            {myRole === "Artwork" ? null : <Button label="Add Project" icon="pi pi-plus" onClick={() => setShow(true)} />}
                        </div>
                    </div>
                    {layoutOpt === "List" ? <ProjectList data={projects} showDetails={showDetails} /> : <ProjectGrid data={projects} showDetails={showDetails} />}
                </div>
                <div className="md:col-4 col-12">
                    <Panel header="My Downloads">{access && <iframe title="filestash" src={`${process.env.REACT_APP_FILE_SHARE_BASE_URL}${access.read}`} className="download-panel"></iframe>}</Panel>
                </div>
            </div>
        )
    ) : (
        <Unauth />
    );
};
export default ManageProject;
