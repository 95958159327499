import { Card } from "primereact/card";
import moment from "moment";
export function customizedMarker() {
    return (
        <span className="custom-marker shadow-1" style={{ backgroundColor: "#9C27B0" }}>
            <i className="fa-solid fa-gas-pump"></i>
        </span>
    );
}

export function customizedContent(item) {
    return (
        <Card title={item.type + " :  " + (item.amount.toFixed(2) + " Ltr")} subTitle={moment(item.startTime).format("MMM DD, HH:mm")} style={{ textTransform: "capitalize" }}>
            <p>
                <i className="fa-solid fa-indian-rupee-sign mr-2" style={{ color: "rgb(156, 39, 176)", fontSize: "16px" }}></i> {item.fuelCost}
            </p>
            <p>
                <i className="pi pi-map-marker mr-2" style={{ color: "rgb(156, 39, 176)", fontSize: "16px" }} />
                {item.city + " ," + item.state + ", " + item.zipcode || ""}
            </p>
        </Card>
    );
}

export function loader(width, height, color) {
    return (
        <svg width={width} height={height} viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke={color} data-testid="oval-svg">
            <g fill="none" fillRule="evenodd"><g transform="translate(1 1)" strokeWidth="2" data-testid="oval-secondary-group">
                <circle strokeOpacity=".5" cx="0" cy="0" r="20" stroke={color} strokeWidth="2"></circle>
                <path d="M20 0c0-9.94-8.06-20-20-20"><animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s" repeatCount="indefinite"></animateTransform></path>
            </g></g>
        </svg>
    )
}

export function threeCircleLoader(width, height, color) {
    return (
        <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xmlSpace="preserve" data-testid="three-circles-svg">
            <path fill={color} d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="2s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform></path>
            <path fill={color} d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="-360 50 50" repeatCount="indefinite"></animateTransform></path>
            <path fill={color} d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5L82,35.7z">
                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="2s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform></path></svg>
    )
}
