import React, { useCallback } from "react";
import { useEffect, useState, useRef } from "react";
import {tempAlertsSeverity } from "../../lib/constants";
import Highcharts, { useSerialIds } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import {colorPalettes } from "../../utilities/constant";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import GaugeChart from 'react-gauge-chart'
import bigRockService from "./bigRockService"
import { Skeleton } from "primereact/skeleton";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import { convertValue } from "../../utilities/unitConversion";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch, useSelector } from "react-redux";
import { changeUnit } from "../../Actions";

const allSensors = {
    sensorName: "All Sensors",
    sensorCode: `sensor_all`,
    sensorNumber : "n"
}
let tempHumiditySpeed = [{sensorCode: "temp"}, {sensorCode: "humidity"},{sensorCode:"wind"}]
let chartHeadingMap = {"temp":"Site Temperature", "humidity":"Humidity ", "wind":"Wind"}

const BigRockMountainDashboard = () => {
    const [tempChartData, setTempChartData] = useState({})
    const [things, setThings] = useState(null)
    const [thingName, setThingName] = useState({})
    const [sensorsName, setSensorsName] = useState({})
    const [sensorList, setSensorList] =  useState([])
    const [latestData, setLatesData] = useState("")
    const [load, setLoader] = useState(true)
    const [showSkeleton, setShowSkeleton] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [dateRange, setDateRange] =  useState({})
    const [maxTemp, setMaxTemp] = useState({})
    const [tempAlerts, setTempAlerts] = useState({})
    const [avg, setAvg] = useState({})
    const [showTrends, setShowTrends] = useState(null)
    const [switchValue, setSwitchValue] = useState(false)
    const sensorsInfo= useRef({});
    const latestPingDate = useRef("")
    const serviceID = localStorage.getItem("serviceId")
    const unit = useSelector(state => state.changeUnit)
    const previousUnit = useRef(unit)
    const dispatch = useDispatch()

    useEffect(() => {
        Promise.all([bigRockService.getThings(serviceID), bigRockService.getSensorInfo(serviceID)]).then((res) => {
            let things = []
                res[0].forEach((item) => {
                things.push({
                    name: item.description ?? "--",
                    code: item.thingName
                })
            })
            sensorsInfo.current = {}
            res[1].forEach((item)=>{
                if (item.sensorIdentification.itemId in sensorsInfo.current) {
                    sensorsInfo.current[item.sensorIdentification.itemId].push({
                        sensorName: item.sensorIdentification.sensorLocationDescription,
                        sensorCode: item.manufacturingParameters.parameterName,
                        sensorNumber : item.sensorIdentification.sensorNumber
                    })
                }
                else {
                    sensorsInfo.current[item.sensorIdentification.itemId] = [
                        {
                            sensorName: item.sensorIdentification.sensorLocationDescription,
                            sensorCode: item.manufacturingParameters.parameterName,
                            sensorNumber : item.sensorIdentification.sensorNumber
                        },
                    ]
                }
            })
            setThings(things)
            //setSensorList(sensorList)
            if (things.length) {
                fetchLatestPingTime(things[0]).then(()=>{
                    setThingName(things[0])
                    setSensorsName(allSensors)
                })     
            }
        })
        
    }, [])

    useEffect(() => {
        if(unit === "MKS") setSwitchValue(true)
        else setSwitchValue(false)
    }, [unit]);

    useEffect(()=>{
        if(thingName.code && sensorsName.sensorCode){
            if(sensorsName.sensorCode === "sensor_all"){
                setSensorList(sensorsInfo.current[thingName.code])
            }
            else{
              let filteredSensors =  sensorsInfo.current[thingName.code].filter((item)=> sensorsName.sensorCode === item.sensorCode)
              setSensorList(filteredSensors)
            }
        }
    },[sensorsName.sensorCode])

    useEffect(() => {
        let interval;
        if (thingName && thingName.code && dateRange && dateRange.start && dateRange.end ) {
            setShowSkeleton(true)
            setShowTrends(null)
            clearInterval(interval)
            const startTime = moment(dateRange.start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
            const endTime = moment(dateRange.end).endOf("day").format("YYYY-MM-DD HH:mm:ss");
            let dateDiff = moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(moment(startTime, "YYYY-MM-DD HH:mm:ss"), "days")
            let sensorsArr = sensorsInfo.current[thingName.code].map(s => {return s.sensorCode})
            Promise.all([bigRockService.getTempTrends(startTime, endTime, thingName.code, null, null, sensorsArr), bigRockService.getAlertsData(startTime, endTime, thingName.code, serviceID)]).then((res) => {
                let charts = {}
                if (res[0]) {
                    let sensors = sensorsInfo.current[thingName.code].concat(tempHumiditySpeed)
                    let diffGtSevenDays = dateDiff > 10 ? true : false
                    sensors && sensors.forEach((s) => {
                        let maxValue = -Infinity
                        let Categories = []
                        if (dateDiff > 0) {
                            let avgData = []
                            let maxData = []
                            res[0].forEach((item, index) => {
                                let date = item.year + "-" + item.month + "-" + item.day + " " + item.hour + ":" + item.min
                                date = diffGtSevenDays ? date : moment.utc(date, "YYYY-MM-DD HH:mm").tz(`America/New_York`).format("MMM D, YY HH:mm")
                                let timeString = date ;
                                let avgdata = `avg_${s.sensorCode}`
                                let maxdata = `max_${s.sensorCode}`
                                if(item[avgdata] && !(item[avgdata] < -100) && !(item[maxdata] > 200)){
                                    Categories.push(timeString)

                                    let tempAvg
                                    let tempMax
                                    if(s.sensorCode === "wind"){
                                        tempAvg = Number(convertValue(item[avgdata],"speed", unit, null, true))
                                        tempMax = Number(convertValue(item[maxdata],"speed", unit, null, true))
                                    }
                                    else if(s.sensorCode === "humidity"){
                                        tempAvg = item[avgdata]
                                        tempMax = item[maxdata]
                                    }else{
                                        tempAvg = Number(convertValue(item[avgdata],"temperature", unit, null, true))
                                        tempMax = Number(convertValue(item[maxdata],"temperature", unit, null, true))
                                    }
                                    
                                    avgData.push(tempAvg)
                                    maxData.push(tempMax)
                                    if (tempMax >= maxValue) {
                                        maxValue = tempMax
                                    }
                                }
                            })
                            let chartHeading = Object.keys(chartHeadingMap).includes(s.sensorCode) ? chartHeadingMap[s.sensorCode]  : "Temperature"
                            const chart = getChartOPtion(chartHeading, Categories, avgData, maxData, maxValue, dateDiff, s.sensorCode)
                            charts[s.sensorCode] = JSON.parse(JSON.stringify(chart))
                        }
                        else{
                            let sensorData = []
                            res[0].forEach((item, index) => {
                                let timeString = moment.utc(item.time, "YYYY-MM-DDTHH:mm:ss").tz(`America/New_York`).format("MMM D, YY HH:mm")
                                if (typeof(item[s.sensorCode] === "Number") && !(item[s.sensorCode] > 200) && !(item[s.sensorCode] < -100)) {
                                    Categories.push(timeString)
                                    let tempValue
                                    if(s.sensorCode === "wind") tempValue = Number(convertValue(item[s.sensorCode],"wind", unit, null, true))
                                    else if (s.sensorCode === "humidity") tempValue = item[s.sensorCode]
                                    else tempValue = Number(convertValue(item[s.sensorCode],"temperature", unit, null, true))
                                    sensorData.push(tempValue)
                                    if (tempValue >= maxValue) {
                                        maxValue = tempValue
                                    }
                                }
                            })
                            let chartHeading = Object.keys(chartHeadingMap).includes(s.sensorCode) ? chartHeadingMap[s.sensorCode]  : "Temperature"
                            const chart = getDetailedChartOPtion(chartHeading, Categories, sensorData, maxValue)
                            charts[s.sensorCode] = JSON.parse(JSON.stringify(chart))
                        }
                    })
                    if(dateDiff>7){
                        for(const key in charts){
                            charts[key].plotOptions.series.point.events.click=(e)=>{
                                showDetailedPlottingsForTheDay(e)
                            }
                        }
                    }
                    setTempChartData(charts)
                }
                setShowSkeleton(false)

                let alertPriorityEnum = Object.freeze({
                    "red": 1,
                    "orange": 2,
                    "yellow": 3,
                    "blue": 4                  
                })
                if(res[1] && res[1].length){
                    let tempObj = {}
                    res[1].forEach(item => {

                        let tempInstances = item.alerts.length ?? 0
                        let alertsCount = 0
                        let latestDateItem 
                        if (item.alerts.length) {
                            item.alerts.sort((a, b) => alertPriorityEnum[a.alertType] - alertPriorityEnum[b.alertType])

                            latestDateItem = item.alerts[0]
                            item.alerts.forEach((item) => {
                                item.alertType === "red" && alertsCount++
                                if(latestDateItem.date <= item.date){
                                    latestDateItem = item
                                }
                            })
                            let currentTimeinUtc = moment.utc().format("YYYY-MM-DD HH:mm:ss") 
                            if(moment(currentTimeinUtc).diff(moment(latestDateItem.date, "YYYY-MM-DD HH:mm:ss"), "minutes") > 15){
                                    latestDateItem = null
                            }
                        }

                        tempObj[item.sensor] = {
                            alerts : item.alerts,
                            highTempInstances : tempInstances,
                            temperatureAlerts : alertsCount,
                            ...(latestDateItem && {status : latestDateItem.alertType})
                        }
                    })
                    setTempAlerts(tempObj)
                }
                else{
                    setTempAlerts({})
                }                
            })

        }
    }, [thingName.code, dateRange.start, dateRange.end])

    useEffect(() => {
        if(thingName && thingName.code){
            fetchLatestData(thingName.code)
        }
        async function fetchLatestData(thing) {
            const startTime = moment(latestPingDate.current,"YYYY-MM-DD HH:mm:ss").tz(`America/New_York`, true).startOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
            const endTime = moment(startTime, "YYYY-MM-DD HH:mm:ss").tz(`America/New_York`, true).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss");
            let sensorsArr = sensorsInfo.current[thing].map(s => {return s.sensorCode})

            Promise.all([bigRockService.getLatestTemp(thing, startTime, endTime, sensorsArr), bigRockService.getMaxTempForDay(thingName.code, startTime, endTime, sensorsArr), bigRockService.getLastFourTemp(thingName.code, startTime, endTime, sensorsArr)]).then(res=>{
                let latestTemp = res[0].length ? res[0][0] : []
                let maxTemp = res[1].length ? res[1][0] : {}
                let avgTemp = res[2].length ? res[2][0] : {}
                setLatesData(latestTemp)
                setMaxTemp(maxTemp)
                setAvg(avgTemp)
                setSensorsName(allSensors)
                if(sensorsInfo.current[thingName.code]){setSensorList(JSON.parse(JSON.stringify(sensorsInfo.current[thingName.code])))}
                setLoader(false)
            }).catch(err=> console.error("Error in fetchLatesDatafunc", err))
        }
        let interval = setInterval(() => {
            fetchLatestData(thingName.code)
        }, 900000)

        return () => {
            clearInterval(interval)
        }
    }, [thingName.code])

    useEffect(()=>{
        if (tempChartData && thingName.code) {
            setShowTrends(null)
            changeChartUnit()
        }
    },[unit])

    const getChartOPtion = (name, xAxis, avgData, maxData, maxValue, dateDiff, sensorCode) => {
        return {
            chart: {
                _id: sensorCode,
                height: "500px",
                type: "spline",
                zoomType: "x",
            },
            title: {
                text: name + " Trends",
                style: {
                    fontSize: "18px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                    color: "black"
                },
            },
            xAxis: {
                categories: xAxis,
                crosshair: true,
                title: {
                    text: "Time"
                },
                scrollbar:{
                    enabled :true
                }
            },
            yAxis: {
                title: {
                    text: name,
                },
                plotLines: [{
                    value: maxValue,
                    color: 'red',
                    dashStyle: 'solid',
                    zIndex: 5,
                    width: 2,
                    label : {
                        text :"Max " + name,
                        align : "left",
                        
                    }
                  }]
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><th style="color:{series.color};padding:0;text-transform:uppercase">{series.name}: </th>' + '<th style="padding:0"><b>{point.y:.1f}</b></th></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
                series:{
                    cursor: "pointer",
                    point : {
                        events : {
                            click : null
                        }
                    }
                    
                }
            },
            legend: {
                itemStyle: {
                    textTransform: "uppercase",
                },
            },
            series: [{
                name: "Avg",
                data: avgData,
                color: colorPalettes.color2
            },
            {
                name: "Max",
                data: maxData,
                color: colorPalettes.color4
            }
            ],
        };
    }

    const getDetailedChartOPtion = (name, xAxis, sensorData, maxValue) => {
        return {
            chart: {
                height: "500px",
                type: "spline",
                zoomType: "x",
            },
            title: {
                text: name + " Trends",
                style: {
                    fontSize: "18px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                    color: "black"
                },
            },
            xAxis: {
                categories: xAxis,
                crosshair: true,
                title: {
                    text: "Time"
                },
                scrollbar:{
                    enabled :true
                }
            },
            yAxis: {
                title: {
                    text: name,
                },
                plotLines: [{
                    value: maxValue,
                    color: 'red',
                    dashStyle: 'solid',
                    zIndex: 5,
                    width: 2,
                    label : {
                        text :"Max " + name,
                        align : "left",

                    }
                  }]
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><th style="color:{series.color};padding:0;text-transform:uppercase">{series.name}: </th>' + '<th style="padding:0"><b>{point.y:.1f}</b></th></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
                series:{
                    cursor: "pointer",
                }
            },
            legend: {
                itemStyle: {
                    textTransform: "uppercase",
                },
            },
            series: [{
                name: name,
                data: sensorData,
                color: colorPalettes.color2
            },
            // {
            //     name: "Max",
            //     data: maxData,
            //     color: colorPalettes.color4
            // }
            ],
        };
    }

    const fetchLatestPingTime = async (thingName) => {
        await bigRockService.getLatestPingTime(thingName.code).then((res)=>{
            let latestDate = (res && res.length) ? res[0].time : {}
            let start = getDateObj(moment.utc(latestDate).tz(`America/New_York`).startOf("day").format("YYYY-MM-DD HH:mm:ss"))
            let end = getDateObj(moment.utc(latestDate).tz(`America/New_York`).endOf("day").format("YYYY-MM-DD HH:mm:ss"))
            latestPingDate.current = end
            let initialDate = { start, end }
            setDateRange({})
            setThingName(thingName)
            setDateRange(initialDate)
        })
    }

    const showDetailedPlottingsForTheDay = async (e) => {
        setModalData(null)
        if (e && e.point.category && e.point?.series?.chart?.options?.chart?._id) {
            let sensorId = e.point.series.chart.options.chart._id
            const startTime = moment(e.point.category).startOf("day").format("YYYY-MM-DD HH:mm:ss")
            const endTime = moment(e.point.category).endOf("day").format("YYYY-MM-DD HH:mm:ss");
            let detailedChartDataRes = await bigRockService.getTempTrends(startTime, endTime, thingName.code, "detailedChart", sensorId)
            let maxValue = -Infinity
            let Categories = []
            let sensorData = []
                detailedChartDataRes.forEach((item, index) => {
                    let timeString = moment.utc(item.time, "YYYY-MM-DDTHH:mm:ss").tz(`America/New_York`).format("MMM D, YY HH:mm")
                    if (!(item[sensorId] < -100) && !(item[sensorId] > 200)) {
                        Categories.push(timeString)
                        let tempDetailedValue
                        if(sensorId === "wind") tempDetailedValue = Number(convertValue(item[sensorId],"wind", previousUnit.current, null, true))
                        else if(sensorId === "humidity") tempDetailedValue = item[sensorId]
                        else tempDetailedValue = Number(convertValue(item[sensorId],"temperature", previousUnit.current, null, true))
                        sensorData.push(tempDetailedValue)
                        if (tempDetailedValue >= maxValue) {
                            maxValue = tempDetailedValue
                        }
                    }
                })
                let chartHeading = Object.keys(chartHeadingMap).includes(sensorId) ? chartHeadingMap[sensorId]  : "Temperature"
                const chart = getDetailedChartOPtion(chartHeading, Categories, sensorData, maxValue)
                setModalData(chart)
                setShowModal(true)
        }
    }
    const getDateObj = (dateString) => {
        const [date, time] = dateString.split(" ");
        const [year, month,day ] = date.split("-").map(Number);
        const [hours, minutes, seconds] = time.split(":").map(Number);
        return new Date(year, month - 1, day, hours, minutes, seconds);
      };
    const changeChartUnit = () =>{
        let tempChart = JSON.parse(JSON.stringify(tempChartData))
        let sensors = sensorsInfo.current[thingName.code].concat(tempHumiditySpeed)
        sensors.forEach((s) => {
            let toChangeValue = (unit === "MKS" && previousUnit.current === "FPS") ? true : false
            for (let i = 0; i < tempChart[s.sensorCode].series.length; i++) {
                tempChart[s.sensorCode].series[i].data.forEach((d, index) => {
                    if(s.sensorCode === "wind") tempChart[s.sensorCode].series[i].data[index] = Number(convertValue(d, "speed", unit, null, true, null, toChangeValue))
                    else if(s.sensorCode === "humidity") tempChart[s.sensorCode].series[i].data[index] = d
                    else tempChart[s.sensorCode].series[i].data[index] = Number(convertValue(d, "temperature", unit, null, true, null, toChangeValue))
                })
            }
            let maxTempVal = tempChart[s.sensorCode].yAxis.plotLines[0].value
            if(s.sensorCode === "wind") tempChart[s.sensorCode].yAxis.plotLines[0].value = Number(convertValue(maxTempVal, "speed", unit, null, true, null, toChangeValue))
            else if(s.sensorCode === "humidity") tempChart[s.sensorCode].yAxis.plotLines[0].value = maxTempVal
            else tempChart[s.sensorCode].yAxis.plotLines[0].value = Number(convertValue(maxTempVal, "temperature", unit, null, true, null, toChangeValue))
    })
        setTempChartData(tempChart)
        previousUnit.current = unit
    }
    const handleTrendsBtnClick = async (e) =>{
        if(e.target.id){
            if(showTrends?.chartId === e.target.id){
                setShowTrends(null)
            }
            else {
                let chartObj = JSON.parse(JSON.stringify(tempChartData[e.target.id]))
                setShowTrends({chart:chartObj, chartId:e.target.id})
            }
        }
    }
    const handleThingChange = (e) =>{
        fetchLatestPingTime(e.value).then(()=> {
            setThingName(e.value)
        })
    }

    const handleUnitChange = (e) => {
        let newUnit;
        if (unit === "FPS") newUnit = "MKS";
        else newUnit = "FPS";
        dispatch(changeUnit(newUnit));
        setSwitchValue(e.target.value);
    };
    return (
        !load ? <div className="grid pt-2">
            <h1 className="text-center w-full" style={{color:"grey"}}>ADA Equipment
            <div className="text-base mt-2" style={{ paddingLeft: "1.5rem", float: "right" }}>
                        Imperial
                        <InputSwitch checked={switchValue} style={{ marginLeft: "0.8rem", marginRight: "0.8rem" }} onChange={(e) => handleUnitChange(e)} />
                        Metric
                    </div>
            </h1>
            <div className="col-12 flex flex-wrap justify-content-between">
                <div className="col-12 pl-4 pr-4 lg:col-3 border-round shadow-1">
                    <div className="col-12">
                        <label htmlFor="minmax" className="block" style={{ fontWeight: 700, fontSize: "18px" }}>
                            Select Equipment
                        </label>
                        <Dropdown value={thingName} options={things} onChange={(e) => handleThingChange(e)} className="bg-white w-full" optionLabel="name" placeholder="Select Item" />
                    </div>
                    <div className="col-12">
                        <label htmlFor="minmax" className="block" style={{ fontWeight: 700, fontSize: "18px" }}>
                            Select Sensor
                        </label>
                        <Dropdown value={sensorsName} options={[...sensorsInfo.current[thingName.code], allSensors]} onChange={(e) => setSensorsName(e.value)} className="bg-white w-full" optionLabel="sensorName" placeholder="All Sensors" />
                    </div>
                </div>
                <div className="col-12 pl-2 pr-2 lg:m-0 mt-4 lg:col-4 border-round shadow-1 bg-white">
                {!showSkeleton ? 
                    <div className="col-12 flex-column gap-1 align-items-center" style={{ fontFamily: "barlow" }}>
                           <div className="col-12 grid justify-content-between align-items-center"><div className="col-6"><h4 className="m-0">Site Temperature</h4></div><h4 className="m-0">{(!(latestData["temp"] === null) && !(latestData["temp"] === undefined)) ? convertValue(latestData["temp"], "temperature", unit ) : "-"}</h4><Button id="temp" icon="fa-solid fa-chart-line"  onClick={handleTrendsBtnClick}></Button></div>
                           <div className="col-12 grid justify-content-between align-items-center"><div className="col-6"><h4 className="m-0">Wind Speed</h4></div><h4 className="m-0">{(!(latestData["wind"]  === null) && !(latestData["wind"]  === undefined))? convertValue(latestData["wind"],"speed", unit) : "-kmph"}</h4> <Button id="wind" icon="fa-solid fa-chart-line" onClick={handleTrendsBtnClick}></Button></div>
                           <div className="col-12 grid justify-content-between align-items-center"><div className="col-6"><h4 className="m-0">Humidity</h4></div><h4 className="m-0">{(!(latestData["humidity"]  === null) && !(latestData["humidity"]  === undefined)) ? latestData["humidity"] : ""}</h4><Button id="humidity" icon="fa-solid fa-chart-line" onClick={handleTrendsBtnClick}></Button></div>
                    </div>
                    : <Skeleton height="12rem"></Skeleton>}
                </div>
                <div className="col-12 lg:col-4 lg:m-0 mt-4 border-round shadow-1" >
                    <h4 style={{ "textAlign": "center" }} className="mt-3">Filter temperature trends and Alerts</h4>
                    <div className="grid justify-content-around col-12">
                        <div className="lg:col-6 col-12">
                            <h5 className="mb-1" >Start Date</h5>
                            <Calendar value={dateRange.start} onChange={(e) => setDateRange({ ...dateRange, "start": new Date(moment(e.value)) })} maxDate={latestPingDate.current} dateFormat="dd/mm/yy" className="bg-white w-full" showIcon style={{ maxWidth: "300px" }} />
                        </div>
                        <div className="lg:col-6 col-12">
                            <h5 className="mb-1"> End Date </h5>
                            <Calendar value={dateRange.end} onChange={(e) => setDateRange({ ...dateRange, "end": new Date(moment(e.value)) })} minDate={dateRange.start} maxDate={latestPingDate.current} dateFormat="dd/mm/yy" className="bg-white w-full" showIcon style={{ maxWidth: "300px" }} />
                        </div>
                    </div>
                </div>
            </div>
            {showTrends ? <div className="col-12" >
                <div className="card" style={{ height: "520px" }}>
                    {!showSkeleton ? <HighchartsReact highcharts={Highcharts} options={showTrends?.chart} /> : <Skeleton height="32rem"></Skeleton>}
                </div>
            </div>: null}
            {((things && !things.length) || (thingName && !(thingName.code in sensorsInfo.current))) ? <h3 className='text-center mt-8' >{`No Data received from the Sensors`}</h3> :
               sensorList.map((item, index) => {
                    return (
                        <div key={item.sensorNumber} className="w-full">
                            <div className="col-12 mt-5 shadow-3 border-round" style={{ backgroundColor: "#F0F0F0" }}>
                                <div className="col-12 grid align-items-center pb-0">
                                    <div className="col-12 lg:col-6 flex align-items-center">
                                        <label htmlFor="minmax" className="block" style={{ fontWeight: 700, fontSize: "18px" }}>
                                            {`Sensor No. ${item.sensorNumber} : ${item.sensorName}`}
                                        </label>
                                        <div className="border-circle w-3rem h-3rem ml-4" style={{ backgroundColor: tempAlertsSeverity[tempAlerts[item.sensorCode]?.status]?.background ?? "#6BFF00", borderRadius: "2px", borderColor: tempAlertsSeverity[tempAlerts[item.sensorCode]?.status]?.color ?? "#00FF00", borderStyle: "solid" }}></div>
                                    </div>
                                </div>
                                <div className="col-12 grid">
                                    <div className="col-12 lg:col-4 md:col-6 sm:col-6 xs:col-6" >
                                        <div className="card" style={{ height: window.screen.width > 1980 ? "28rem" :"23rem" }}>
                                            <div style={{ fontWeight: 700, fontSize: "18px", paddingBottom: "1rem", textAlign: "center"}}>Real Time Temperature Reading</div>
                                           {!showSkeleton ?
                                            <><GaugeChart id="gauge-chart2" textColor="black"
                                                nrOfLevels={20}
                                                percent={latestData[item.sensorCode] ? latestData[item.sensorCode]/100 : 0}
                                                style={{ height: window.screen.width > 1980 ? "75%" : "60%", width: "100%", display:"block", overflow:"hidden" }}
                                                formatTextValue={(value) => convertValue(value,"temperature", unit)}
                                                fontSize = "2rem"
                                            />
                                            <div className="text-xl " >
                                                <table >
                                                    <tbody>
                                                        <tr>
                                                            <td className="pr-4"><p className="font-bold mt-1 mb-0">Last 4 Avg Temp</p></td>
                                                            <td><Tag style={{backgroundColor : colorPalettes.color1}} className="text-lg">{avg["average_" + item.sensorCode] ? convertValue(avg["average_" + item.sensorCode], "temperature", unit, null, true) :  "--"}</Tag></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-4"><p className="font-bold mt-1 mb-0">Max Temp </p></td>
                                                            <td><Tag style={{backgroundColor : colorPalettes.color5}} className="text-lg">{maxTemp[`max_${item.sensorCode}`] ? convertValue(maxTemp[`max_${item.sensorCode}`], "temperature", unit, null, true) : "--"}</Tag></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div></> : <Skeleton height="18rem"></Skeleton>}
                                        </div>
                                        
                                    </div>
                                    <div className="col-12 lg:col-4 md:col-6 sm:col-6 xs:col-6">
                                        <div className="card h-full" style={{ maxHeight: window.screen.width > 1980 ? "28rem" : "23rem", overflowY: "auto", overflowX:"auto" }}>
                                            <div style={{ fontWeight: 700, fontSize: "1.4rem", textAlign: "center" }}>Alerts</div>
                                            {
                                               !showSkeleton ? (item.sensorCode in tempAlerts && tempAlerts[item.sensorCode].alerts.length) ? tempAlerts[item.sensorCode].alerts.map((t, i) => {
                                                    return (
                                                        <div key={i} className="p-message p-component p-message-info p-message-enter-done" style={{ color: tempAlertsSeverity[t.alertType].color, background: tempAlertsSeverity[t.alertType].background }} >
                                                            <div className="pt-3 pb-3 px-0">
                                                                <div className="col-12 grid align-items-center justify-content-between  p-0" >
                                                                    <div className="col-4 text-bold text-xl text-center">{t.value ? convertValue(t.value, "temperature", unit) : "--"}
                                                                    </div>
                                                                    <div className="col-8 text-bold text-xl">{moment.utc(t.date, "YYYY-MM-DDTHH:mm:ss").tz(`America/New_York`).format("YYYY-MM-DD HH:mm:ss")}
                                                                    </div>
                                                                    {/* <div className="col-2 md:col-4 text-center"><Button label="ACK" className="p-button-raised p-button-success w-2rem" />
                                                                    </div> */}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                }) : <h5 className="text-center mt-6">No Events Recorded</h5> : <Skeleton height="19rem"></Skeleton>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 lg:col-4 md:col-6 sm:col-6 xs:col-6">
                                        <div className="col-12 card h-full p-3 flex flex-column justify-content-between">
                                        <div  className="mb-2 lg:mb-0" style={{ fontWeight: 700, fontSize: "20px", textAlign: "center" }}>High temp instance/alerts count</div>
                                            {!showSkeleton ? <>
                                            <div className="card mb-4 lg:m-0 flex flex-column justify-content-between" style={{ backgroundColor: "#f9a793" }}>
                                                <h6 className=' text-2xl'> High Temperature Instances</h6>
                                                <div className=''>
                                                    <h4 className="m-0 text-4xl text-right">{tempAlerts[item.sensorCode]?.highTempInstances ?? 0}</h4>
                                                </div>
                                            </div>
                                            <div className="displayCard color2-bg displayCard flex flex-column justify-content-between">
                                                <h6 className=' text-2xl'> High Temperature Alerts</h6>
                                                <div className=''>
                                                    <h4 className="m-0 text-4xl text-right">{tempAlerts[item.sensorCode]?.temperatureAlerts ?? 0}</h4>
                                                </div>
                                            </div>
                                            </> : <Skeleton height="18rem"></Skeleton>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card" style={{ height: "520px" }}>
                                        {!showSkeleton ?
                                            <HighchartsReact highcharts={Highcharts} options={tempChartData[item.sensorCode]} /> : <Skeleton height="32rem"></Skeleton>
                                        }
                                    </div>
                                </div>
                            </div>
                            <Dialog visible={showModal} header="Detailed Temperature Trends" style={{ width: "80vw" }} onHide={() => setShowModal(false)}>
                                <div className="col-12">
                                    <div className="card" style={{ height: "520px" }}>
                                        {modalData ?
                                            <HighchartsReact highcharts={Highcharts} options={modalData} /> : <Skeleton height="32rem"></Skeleton>
                                        }
                                    </div>
                                </div>
                            </Dialog>
                        </div>)
                })}

        </div> : <div style={{ width: "100%", textAlign: "center", height: "80vh", verticalAlign: "center", position: "relative" }}><i className="pi pi-spin pi-spinner" style={{ fontSize: '4rem', verticalAlign: "middle", position: "absolute", top: "45%", left: "45%" }}></i></div>
    )
}

export default React.memo(BigRockMountainDashboard);