import React, { useEffect, useState } from "react";
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";
import { Ripple } from "primereact/ripple";
import { DataTable } from "primereact/datatable";
import { Column } from "jspdf-autotable";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import dbService from '../../service/dbService'
import itemService from '../../service/itemService'
import { Skeleton } from 'primereact/skeleton';
import { Button } from "primereact/button";

const BatteriesLiveStatus = () => {
    const serviceID = localStorage.getItem("serviceId");
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedSite, setSelectedSite] = useState(null);

    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [site, setSite] = useState(null);
    const [stateMap, setStateMap] = useState(null)
    const [cityMap, setCityMap] = useState(null)
    const [meta, setMeta] = useState(null)
    const [btnLoading, setBtnLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [filter, setFilter] = useState(null)
    useEffect(async () => {
        let projection = { 'location.state': 1, 'location.city': 1, 'location.zip': 1, thingName: 1, alias: 1, 'location.siteName': 1 }
        let things = await dbService.getThingNames(serviceID, projection)
        let statesMap = {}, citiesMap = {};

        things.map(t => {
            if (statesMap[t.location.state]) statesMap[t.location.state]['cities'].push({ name: t.location.city, value: t.location.city })
            else statesMap[t.location.state] = { name: t.location.state, value: t.location.state, cities: [{ name: t.location.city, value: t.location.city }] }

            if (citiesMap[t.location.city]) citiesMap[t.location.city]['sites'].push({ name: t.location.siteName, value: t.thingName })
            else citiesMap[t.location.city] = { name: t.location.city, value: t.location.city, sites: [{ name: t.location.siteName, value: t.thingName, thingName: t.thingName }] }
        })
        setStateMap(statesMap)
        setCityMap(citiesMap)
        setState(Object.values(statesMap))
        setCity(Object.values(citiesMap))
        setSelectedState(things[0].location.state)
        setSelectedCity(things[0].location.city)
        setSelectedSite(things[0].thingName)
        getData(things[0].thingName)
    }, [])

    const getData = async (thing) => {
        let query = { filter: { 'thingName': thing }, projection: { 'effective_capacity': 1, _id: 0 }, "sort": [["date", -1]] }

        Promise.all([itemService.getDayData(serviceID, query), itemService.getLiveData(serviceID, [thing])])
            .then((res) => {
                let data = res[0]
                let meta = res[1]
                let eff_cap;
                if (data && data.length) eff_cap = data[0].effective_capacity

                if (meta && meta.length) {
                    meta[0]['voltage'] = 'Voltage'
                    meta[0]['eff_cap'] = eff_cap
                    setMeta(meta[0])
                } else setErrMsg("Data not present for applied filter")
                setFilter({site: thing })
            })
        setBtnLoading(false)
    }
    const handleFilter = (ftype, value, e) => {
        if (ftype === 'state') {
            setSelectedState(value)
            setSite([])
            setSelectedCity(null)
            setSelectedSite(null)
        }
        if (ftype === 'city') setSelectedCity(value)
        if (ftype === 'site') setSelectedSite(value)
    }

    useEffect(() => {
        if (selectedState && stateMap && stateMap[selectedState]) setCity(stateMap[selectedState].cities)
    }, [selectedState, stateMap])

    useEffect(() => {
        if (selectedCity && cityMap && cityMap[selectedCity]) setSite(cityMap[selectedCity].sites)
    }, [selectedCity, cityMap])

    const template = (options) => {
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";

        return (
            <div className={className} >
                <span className={titleClassName}>
                    <i className="pi-pw pi pi-filter"></i> Filter
                </span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        );
    };
    const twoColumn = ({ meta, column1, column2 }) => {
        return (
            <div className="col-12">
                <div className="displayCard color4-bg grid" style={{ padding: "0", borderRadius: "5px" }} >
                    <div className="col-6" style={{ padding: "0", borderRight: "1px solid #000000ab" }}>
                        <h4 style={{ padding: '1rem', marginBottom: "0"}}>{column1.title}</h4>
                        {meta ? <h5 style={{ padding: '1rem', margin: 0, backgroundColor: "white", color: "#000000ab", borderBottomLeftRadius: "5px" }}>{meta[column1.key]?.toFixed(2) ?? "--"}</h5> : <Skeleton height="3.5rem"></Skeleton>}
                    </div>
                    <div className="col-6" style={{ padding: "0" }}>
                        <h4 style={{ padding: '1rem', marginBottom: "0" }}>{column2.title}</h4>
                        {meta ? <h5 style={{ padding: '1rem', margin: 0, backgroundColor: "white", color: "#000000ab", borderBottomRightRadius: "5px" }}>{meta[column2.key]?.toFixed(2) ?? "--"}</h5> : <Skeleton height="3.5rem"></Skeleton>}
                    </div>

                </div>
            </div>
        )
    }

    const oneColumn = (title, meta, classNames, key) => {
        return (
            <div className={"displayCard " + classNames} style={{ padding: "0", borderRadius: "5px" }} >
                <h4 style={{ padding: '1rem', marginBottom: "0", wordWrap: "break-word" }}>{title}</h4>
                {meta ?
                    <h5 style={{ padding: '1rem', margin: 0, backgroundColor: "white", color: "#000000ab", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                        {title == 'BB Status' ? (meta[key] > 0 ? "Charging" : (meta[key] == 0 ? 'Neutral' : 'Discharging')) : ((meta[key] != null || meta[key] !== undefined) ? meta[key] : '--')}
                    </h5>
                    : <Skeleton height="3.5rem"></Skeleton>
                }
            </div>)
    }
    const getTableHeader = (columnHeader, data) => {
        return (
            <ColumnGroup>
                <Row>
                    <Column className="color1_1-bg" header={columnHeader} colSpan={data.cell_num + 1}></Column>
                </Row>
                <Row>
                    <Column className="color1-bg" field="_id" header="Cell No"></Column>
                    {
                        Array.from({ length: data.cell_num }, (_, i) => (
                            <Column key={i} className="color1-bg" field={data['cell_vtg' + (i + 1)]} header={i + 1}>{data['cell_vtg' + (i + 1)]}</Column>
                        ))}
                </Row>
            </ColumnGroup>
        );
    };

    const getTable = (value) => {
        return (
            <DataTable value={[value]} showGridlines headerColumnGroup={getTableHeader("CELL VOLTAGE (Volts)", value)} responsiveLayout="scroll">
                <Column className="color2-bg" field="voltage"></Column>
                {
                    Array.from({ length: value.cell_num }, (_, i) => (
                        <Column key={i} className="color2-bg" body={(row) => tableValue(row, 'cell_vtg' + (i + 1))}></Column>
                    ))
                }
            </DataTable>
        );
    };

    const tableValue = (row, key) => {
        return (
            <div>
                {row[key].toFixed(2)}
            </div>
        )
    }
    const validateInput = () => {
        if (!(selectedState && selectedCity && selectedSite)) return "Please apply filters to see result";
    }
    const onFilterSubmit = () => {
        const err = validateInput();
        if (err) return setErrMsg(err);
        setErrMsg(null);
        if(filter.site !== selectedSite) {
            setBtnLoading(true);
            getData(selectedSite)
        }
    }

    return (
        <>

            <div className="col-12">
                <Panel headerTemplate={template} toggleable="false">
                    <div className="grid">
                        <div className="col-12 md:col-2">
                            <Dropdown value={selectedState} options={state} onChange={(e) => handleFilter('state', e.target.value)} optionLabel="name" placeholder="State" className="m-2 bg-white w-full" />
                        </div>
                        <div className="col-12 md:col-2">
                            <Dropdown value={selectedCity} options={city} onChange={(e) => handleFilter('city', e.target.value)} optionLabel="name" placeholder="City" className="m-2 bg-white w-full" />
                        </div>
                        <div className="col-12 md:col-2">
                            <Dropdown value={selectedSite} options={site} onChange={(e) => handleFilter('site', e.target.value, e)} optionLabel="name" placeholder="Site Name" className="m-2 bg-white w-full" />
                        </div>
                        <div className="col-12 md:col-2">
                            <Button label="Search" className="m-2" loading={btnLoading} onClick={onFilterSubmit} />
                        </div>
                        <div className="col-12">{errMsg ? <span style={{ color: "red" }}>{errMsg}</span> : null}</div>
                    </div>
                </Panel>
            </div>

            <div className="col-12 grid justify-content-between" style={{ paddingRight: "0", }}>
                <div className="col-12 lg:col-2" >
                    {oneColumn('BB Status', meta, "color5-bg", 'cur')}
                </div>
                <div className="col-12 lg:col-4" >
                    {twoColumn({ meta, column1: { title: "Voltage (Volts)", key: 'Voltage' }, column2: { title: "Current (Amps)", key: 'cur' } })}
                </div>
                <div className="col-12 lg:col-4" style={{ paddingRight: "0" }}>
                    {twoColumn({ meta, column1: { title: "State of Health (SOH)%", key: 'soh' }, column2: { title: "State of Charge (SOC)%", key: 'soc' } })}
                </div>
                <div className="col-12 lg:col-2" >
                    {oneColumn('Last Seen', meta, "color4-bg", 'ts')}
                </div>
            </div>
            <div className="col-12 grid">
                <div className="col-12 lg:col-offset-2 lg:col-4">
                    {twoColumn({ meta, column1: { title: "Ambient Temp (°C)", key: 'temp_amb' }, column2: { title: "Module Temp (°C)", key: 'temp_PCB' } })}
                </div>
                <div className="col-12 lg:col-2" >
                    {oneColumn('AH Remaining', meta, "color4-bg", 'rem_cap')}
                </div>
                <div className="col-12 lg:col-2 " >
                    {oneColumn('Cycles Completed', meta, "color4-bg", 'cyc_count')}
                </div>
                <div className="col-12 lg:col-2 " >
                    {oneColumn('Effective Capacity (AH)', meta, "color4-bg", 'eff_cap')}
                </div>
            </div>

            <div className="col-12">
                {meta ? getTable(meta) : null}
            </div>
        </>
    )
}

export default BatteriesLiveStatus;