import React, { Component } from "react";

// import { useParams } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import MyxComponent from "./Components/myxComponent";


class MenuSiteProgressComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
			towerid: null,
			region: null,
			invcode: null,
            url:null

		};


    }
    fetchdata = () => {
        // console.log("Ids do not matach")
        const { towerid , region, invcode } = this.props.match.params;
        let currentState = {
            towerid: towerid,
			region: region,
			invcode: invcode,
            site: {
                myxlink: region === "IN" ? "https://india.platform.myxrobotics.com/twins/"+towerid+"/?invcode="+invcode : "https://platform.myxrobotics.com/twins/"+towerid+"/?invcode="+invcode
            }

        }
        this.setState(currentState)
    };

    componentDidUpdate(prevProps) {
        if (this.props.match.params.towerid !== prevProps.match.params.towerid) {
            // ... write code to get new data using new prop, also update your state
            this.fetchdata();
        }
    }

    componentDidMount() {
        this.fetchdata();
    }

    render = () =>
        this.state.towerid === null && this.state.region === null && this.state.invcode === null? null : (
            <TabView>
                    <TabPanel header="Digital Twin">
                        <MyxComponent site={this.state.site}/>
                    </TabPanel>
            </TabView>
        );
}

export default MenuSiteProgressComponent;
