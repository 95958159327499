import React, { Component } from "react";
import FuelUsage from "../Common/FuelUsage";

class VehFuelUsage extends Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return <FuelUsage isVehicle={true} />;
    };
}

export default VehFuelUsage;
