import React from 'react';
const Marina = () => {
    return (
        <div className="marina_img">
            <div style={{ width: "101px", height: "30px", backgroundColor: "rgb(0, 60, 67, 0.5)", transform: "translateX(77px) translateY(100px) rotate(47deg)" }}></div>

        </div>
    )

}

export default Marina;