import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import Highcharts, { color, format, getOptions } from "highcharts";
import { Calendar } from "primereact/calendar";
import HighchartsReact from "highcharts-react-official";
import { Dropdown } from "primereact/dropdown";
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";
import service from "../../service/dbService";
import { useSelector } from 'react-redux';
import indoorService from "./indoorService";
const style = {
    border: { border: "1px", borderStyle: "solid", borderColor: "#e4e4e4" }
}

const IndoorMonitor = () => {
    const [thingsData, setThingsData] = useState(null)
    const [allThingsData, setAllThingsData] = useState(null)
    const [optedService, setOptedService] = useState(false)
    const [load, setLoader] = useState(true)
    const [thingName, setThingName] = useState({})
    const thingsNameMap = useRef({})
    const [pieChart, setPieChart] = useState({})
    const [dateRange, setDateRange] = useState([new Date(moment().startOf("day").format("YYYY-MM-DD")), new Date(moment().endOf("day").format("YYYY-MM-DD"))])
    const serviceId = localStorage.getItem("serviceId")
    const seriesData = useRef([])
    const thingNames = useSelector(state => state.thingNameContainer);
    let arr = thingNames.length > 0 ? thingNames[thingNames.length - 1] : [];

    useEffect(() => {
        if (thingName && thingName.code) {
            let filteredThings
            if (thingName.code === "all_sites") {
                filteredThings = JSON.parse(JSON.stringify(allThingsData.filter((item) => item.code !== thingName.code)))
            }
            else {
                filteredThings = JSON.parse(JSON.stringify(allThingsData.filter((item) => item.code === thingName.code)))
                filteredThings[0].data.chart.height = '200px'
            }
            setThingsData(filteredThings)
        }
        else {
            setThingsData(allThingsData)
        }
    }, [thingName.code])

    useEffect(() => {
        const today = moment().format("YYYY-MM-DD");
        function getIndoorData() {
            let promiseArr = [indoorService.getThingsData(serviceId), indoorService.getIndoorData(serviceId, arr), indoorService.getDayChartDetails(today, serviceId, arr)];
            if (/*arr.length === 0*/ true) {
                promiseArr = [service.getThingNames(serviceId), ...promiseArr];
            }
            Promise.all(promiseArr).then((res) => {
                let first = [];
                if (arr.length > 0) {
                    thingNames.map((item) => {
                        if (!Array.isArray(item)) {
                            first.push(item);
                        }
                    })
                }
                let _thingDetails = arr.length > 0 ? first : res[0];
                let thingsInfo = arr.length > 0 ? res[0] : res[1]
                let indoorData = arr.length > 0 ? res[1] : res[2]
                let chartData = arr.length > 0 ? res[2] : res[3]
                let thingsMap = new Map()
                // to show only those spaces whose data is present.
                setLoader(false)
                thingsInfo.forEach((item) => {
                    let index = _thingDetails.findIndex((elm) => ((elm.spaceName === item.sensorIdentification.itemId) && (item.installationParameters.type === "temperature_and_humidity_sensor")));
                    if (index > -1) {
                        thingsMap.set(item.sensorIdentification.itemId, {
                            name: _thingDetails[index].alias || _thingDetails[index].description,
                            code: item.sensorIdentification.itemId,
                            meta: {},
                            data: {}
                        })
                        thingsNameMap.current[item.sensorIdentification.itemId] = _thingDetails[index].alias || _thingDetails[index].description
                    }
                });
                if (thingsMap.size) {
                    setOptedService(true)
                }
                indoorData.map((item) => {
                    let thingData = thingsMap.has(item.thingName)
                    if (thingData) {
                        let value = thingsMap.get(item.thingName)
                        value.meta = item
                        thingsMap.set(item.thingName, value)
                    }
                })
                for (let [key, value] of thingsMap.entries()) {
                    let categories = []
                    let humidity = []
                    let temp = []
                    chartData.map((item) => {
                        if (item.thingName === key) {
                            let timeString = item.hr + ":" + "00"
                            categories.push(moment(timeString, "HH:mm").format("hh:mm a"))
                            humidity.push(item.avg_humidity)
                            temp.push(item.avg_temp)
                        }
                    })
                    const chart = getChartOPtion(`${value.name} - ${moment(today).format("Do, MMM YYYY")}`, categories, humidity, temp, thingsMap.size, ["humidity", "Temp"])
                    value.data = chart
                    thingsMap.set(key, value)
                }
                setThingsData(Array.from(thingsMap.values()))
                let allThings = JSON.parse(JSON.stringify(Array.from(thingsMap.values())))
                let allSitesObj = {
                    name: "All Sites",
                    code: "all_sites",
                    meta: {},
                    data: {}
                }
                allThings.push(allSitesObj)
                setAllThingsData(allThings)
            });
        }
        getIndoorData()
        let interval = setInterval(() => {
            getIndoorData()
        }, 900000)
        return (() => {
            clearInterval(interval)
        })
    }, [])

    useEffect(() => {
        if(dateRange && dateRange[1]){
            getPieChartOption()
        }
    }, [dateRange, thingName.code])

    const getChartOPtion = (name, categories, data1, data2, len, seriesname) => {
        return {
            chart: {
                height: "190px",
                type: "spline",
            },
            title: {
                text: name,
                style: chartHeadingStyle,
            },
            xAxis: {
                categories: categories,
                crosshair: true,
            },
            yAxis: [{
                title: {
                    text: 'Humidity Level',
                    style: {
                        color: "#e76f51"   // Match color of Series 1
                    }
                },
                labels: {
                    format: '{value}%',
                    style: {
                        color: "#e76f51"  // Match color of Series 1
                    }
                },
                opposite: false  // This axis will be on the left side
            }, {
                title: {
                    text: 'Temp Level',
                    style: {
                        color: "#2A9D8F"  // Match color of Series 2
                    }
                },
                labels: {
                    format: "{value}\u{00B0}C",
                    style: {
                        color: "#2A9D8F"  // Match color of Series 2
                    }
                },
                opposite: true   // This axis will be on the right side
            }],
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><th style="color:{series.color};padding:0;text-transform:uppercase">{series.name}: </th>' + '<th style="padding:0"><b>{point.y:.1f}</b></th></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            legend: {
                itemStyle: {
                    textTransform: "uppercase",
                },
            },
            series: [{
                name: seriesname[0],
                data: data1,
                yAxis: 0,
                color: "#e76f51"
            },
            {
                name: seriesname[1],
                data: data2,
                yAxis: 1,
                color: "#2A9D8F"
            }],
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ['viewFullscreen', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
                    }
                }
            }
        };
    }

    const getPieChartOption = async () => {
        let startTime = moment(dateRange[0]).startOf("day").format("YYYY-MM-DD")
        let endTime = moment(dateRange[1]).endOf("day").format("YYYY-MM-DD")
        let thing;
        if ((thingName.code && thingName.code !== "all_sites")) thing = thingName.code

        let res = await indoorService.getPieChartDetails(startTime, endTime, serviceId, thing)
        let dbKeysMap = {
            "humidity_less_20": "Below 20%",
            "humidity_bet_20_40": "20 - 40%",
            "humidity_bet_40_60": "40 - 60%",
            "humidity_bet_60_80": "60 - 80%",
            "humidity_above_80": "Above 80%"
        }

        let seriesDataArr = []
        if (res && res.length) {
            for (const [key, value] of Object.entries(res[0])) {
                if (key !== "_id") {
                    seriesDataArr.push({
                        name: dbKeysMap[key],
                        y: Number((value / 3600).toFixed(2))
                    })
                }
            }
        }

        let pchart = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            title: {
                text: "Humidity - Hrs",
                style: {
                    fontSize: "21px",
                    fontWeight: "700",
                    fontFamily: "Roboto"
                },
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.y}</b>",
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b>: {point.y}",
                        style: {
                            fontSize: "11px",
                        },
                        formatter: function () {
                            return 'Index ' + this.point.index + ': ' + this.point.name;
                        }
                    },
                    showInLegend: false,
                },
            },
            series: [
                {
                    name: 'Duration',
                    colorByPoint: true,
                    data: seriesDataArr
                }
            ]
        };
        seriesData.current = seriesDataArr
        setPieChart(pchart)
    }


    return (
        <>
            <div className="field col-12 lg:col-4">
                <label htmlFor="minmax" className="block">
                    Select Location
                </label>
                <Dropdown value={thingName} options={allThingsData} onChange={(e) => setThingName(e.value)} className="bg-white w-full" optionLabel="name" placeholder="All Sites" />
            </div>
            {thingsData && thingsData.length && thingsData[0].meta && !Object.keys(thingsData[0].meta).length ?
                <>
                    <div className="col-12 " style={{ color: "#01579B" }}>
                        <div className="card flex flex-column align-items-center justify-content-center" style={{ height: "60vh", backgroundColor: "#B3E5FC" }}>
                            <span className="fa-solid fa-circle-info" style={{ fontSize: "3rem" }}></span>
                            <h3>No data received from the sensors</h3>
                        </div>
                    </div>
                </> :
                <>
                    <div className="col-12 grid justify-content-between">
                        <div className='grid col-12 m-0 overflow-x-auto lg:col-6'>
                            <table className="w-full card">
                                <thead>
                                    <tr>
                                        <th><h5 className="mb-1 text-xl">Location</h5></th>
                                        <th><h5 className="mb-1 text-xl">Humidity %</h5></th>
                                        <th><h5 className="mb-1 text-xl">Temperature  &deg;C</h5></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {thingsData && thingsData.map((item, index) => {
                                        return <tr style={style.border} key={index} className="h-12rem min-h-12rem" >
                                            <td className="card text-center" style={{ width: "33%", height: 100 / thingsData?.length + "%" }} ><i style={{ color: "#EA4335" }} className="fa fa-solid fa-location-dot text-3xl" ></i><h5>{item.name}</h5></td>
                                            <td className="card text-center bg-primary" style={{ width: "33%", height: 100 / thingsData?.length + "%" }} ><h1 className="m-0 text-6xl">{item.meta.humidity?.toFixed(2) ?? "--"}</h1></td>
                                            <td className="card text-center bg-primary" style={{ width: "33%", height: 100 / thingsData?.length + "%" }} ><h1 className="m-0 text-6xl">{item.meta.temp?.toFixed(2) ?? "--"}</h1></td>
                                        </tr>
                                    })}
                                    {/* <tr style={style.border} >
                                            <td className="card text-center" style={{ width: "33%", height: 100 / thingsData?.length + "%" }} ><h5>Public AQI</h5></td>
                                            <td className="card text-center bg-primary" style={{ width: "33%", height: 100 / thingsData?.length + "%" }} ><h1 style={{ color: getAQIColor("pm2_5", thingsData?.length && thingsData[0].meta[0].pub_pm2_5) }} className="m-0 text-6xl">{thingsData?.length ? thingsData[0].meta[0].pub_pm2_5 : "--"}</h1><p style={{ color: getAQIColor("pm2_5", thingsData?.length && thingsData[0].meta[0].pub_pm2_5, "") }} className="text-xl">{getAQIColor("pm2_5", thingsData?.length && thingsData[0].meta[0].pub_pm2_5, "category")}</p></td>
                                            <td className="card text-center bg-primary" style={{ width: "33%", height: 100 / thingsData?.length + "%" }} ><h1 style={{ color: getAQIColor("pm_10", thingsData?.length && thingsData[0].meta[0].pub_pm10) }} className="m-0 text-6xl">{thingsData?.length ? thingsData[0].meta[0].pub_pm10 : "--"}</h1><p style={{ color: getAQIColor("pm_10", thingsData?.length && thingsData[0].meta[0].pub_pm10) }} className="text-xl">{getAQIColor("pm_10", thingsData?.length && thingsData[0].meta[0].pub_pm10, "category")}</p></td>
                                        </tr> */}

                                </tbody>
                            </table>
                        </div>

                        <div className="grid col-12 m-0 lg:col-6 p-0">
                            {thingsData && thingsData.length ? thingsData.map((item, index) => {
                                return <div className="col-12" key={index}>
                                    <div className="card h-full p-1">
                                        <HighchartsReact highcharts={Highcharts} options={item.data} />
                                    </div>
                                </div>
                            }) : null}
                        </div>
                    </div >
                    <div className="col-12">
                        <div className="grid card">
                            <div className="field col-12 lg:col-4">
                                <label htmlFor="minmax" className="block">
                                    Select Date Range
                                </label>
                                <Calendar value={dateRange} onChange={(e) => setDateRange(e.value)} minDate={new Date(moment().subtract(3, 'months').format("YYYY-MM-DD"))} maxDate={new Date(moment().format("YYYY-MM-DD"))} dateFormat="dd/mm/yy" showIcon selectionMode='range' className=" w-full" placeholder='Select Date Range' />
                            </div>
                            <div className="col-12 flex flex-wrap">
                                <div className="col-12 lg:col-4">
                                    <div className=" w-full overflow-x-auto">
                                        <h4 className="text-center">Humidity - Hrs</h4>
                                        <table style={{ borderCollapse: "collapse" }} className="w-full">
                                            <thead className="bg-primary">
                                                <tr>
                                                    <th className="th">#</th>
                                                    <th className="th">Humidity</th>
                                                    <th className="th">Duration (Hrs)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {seriesData.current.length ? seriesData.current.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="td" style={style.border} >{index + 1}</td>
                                                            <td className="td" style={style.border} >{item.name}</td>
                                                            <td className="td" style={style.border} >{item.y}</td>
                                                        </tr>)
                                                })
                                                    :
                                                    <p>NO Data</p>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-8">
                                    <div className="w-full">
                                        <HighchartsReact highcharts={Highcharts} options={pieChart} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </>
    )
}

export default IndoorMonitor