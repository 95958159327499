import React, { useEffect, useState } from "react";
import apis from "../../service/analytics";

const QuickSight = () => {
    const serviceID = localStorage.getItem("serviceId");
    const [quickSite, setquickSite] = useState(null);
    const service = JSON.parse(localStorage.getItem("service"));
    useEffect(() => {
        if (service.serviceTypeName === "Repro") {
            apis.getQuickSiteDashboardUrl({ serviceId: serviceID }).then((res) => {
                setquickSite(res.embedUrl);
            });
        } else {
            apis.getQuickSiteUrl({ serviceId: serviceID }).then((res) => {
                setquickSite(res.embedUrl);
            });
        }
    }, []);

    return quickSite ? (
        <div className="w-full h-full">
            <div className="flex justify-content-between align-items-center">
                <h3>QuickSight</h3>
            </div>
            <iframe title="Quicksight" src={quickSite} className="w-full h-full" />
        </div>
    ) : null;
};

export default QuickSight;
