import axios from "axios"

const getHourlyData = async (startTime,mid, endTime, serviceId) => {
    const payload = {
        operation: "aggregate",
        aggregate: [
            {
                $match: {
                    date: { $gte: startTime, $lte: endTime },
                },
            },
            {
                $group: {
                    _id: {
                        thing: "$thingName",
                        hr: "$hr"
                    },
                    today: { $push: { $cond: [{ $eq: ["$date", endTime] }, { avg2_5: "$avgpm25", avgpub2_5: "$avgpubpm25", avgpm10: "$avgpm10", avgpub10: "$avgpubpm10" }, "$REMOVE"] } },
                    yesterday: { $push: { $cond: [{ $eq: ["$date", mid] }, { avg2_5: "$avgpm25", avgpub2_5: "$avgpubpm25", avgpm10: "$avgpm10", avgpub10: "$avgpubpm10" }, "$REMOVE"] } },
                    dayBefore: { $push: { $cond: [{ $eq: ["$date", startTime] }, { avg2_5: "$avgpm25", avgpub2_5: "$avgpubpm25", avgpm10: "$avgpm10", avgpub10: "$avgpubpm10" }, "$REMOVE"] } }
                }
            },
            { $sort: { "_id.thing": 1, "_id.hr": 1 } }

        ],
    };
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-aqi-history", payload);
    return res.data;
};

const getThingsData = async (serviceId) => {
    const payload = {
        projection: { "sensorIdentification.itemId": 1, "installationParameters.type": 1, _id: 0 }
    }
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-config-sensors", payload);
    return res.data;
}
const getThingDetails = async (serviceId) => {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-spaces", {});
    return res.data;
};

const getAqiData = async (serviceId,arr) =>{
    let payload = {
        projection:{
        _id:0,
        pm10 : 1,
        pm2_5 : 1,
        pub_pm10 : 1,
        pub_pm2_5 : 1,
        thingName:1
        }
    }
    if(arr && arr.length>0){
        payload={
            ...payload,
            filter:{
                "thingName": { $in: arr }
            }
        }
    }
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-aqi-meta", payload)
    return res.data
}

const getDayChartDetails = async (today, serviceId,arr) => {
    let payload = {
        operation: "aggregate",
        aggregate: [{
            $match: {
                $and: [{ date: today }],
            },
        },
        {
            $project: {
                _id: 0,
                hr: 1,
                avgpm10: 1,
                avgpm25: 1,
                thingName: 1
            }
        },
        { $sort: { "hr": 1 } }
        ]
    }
    if(arr && arr.length>0){
        payload.aggregate=[{
            $match: {
                "thingName": { $in: arr }
            }
        },...payload.aggregate]
    }
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-aqi-history", payload)
    return res.data
}
const aqDownloadData = async (startTime, endTime, thingName, serviceId) =>{
    let payload = {
        operation : "aggregate",
        "aggregate" : [
            {
                $match:{
                    $and:[{  date: {$gte:startTime, $lte: endTime}, thingName: thingName}],
                   
                }
            },
            {
                    $group:{
                        _id:{
                            hr:"$hr",
                            date:"$date"
                        },
                        avgpm2_5: {"$first": "$avgpm25"},
                        avgpm10: {"$first": "$avgpm10"},
                        minpm2_5: {"$first": "$minpm25"},
                        minpm10: {"$first": "$minpm10"},
                        maxpm2_5: {"$first": "$maxpm25"},
                        maxpm10: {"$first": "$maxpm10"},
                    }
                },{
                    $sort:{"_id.date":1, "_id.hr":1}
                }
         ]
    }
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-aqi-history", payload);
    return res.data;
}
let aqiService = {
    getThingDetails,
    getThingsData,
    getHourlyData,
    getDayChartDetails,
    getAqiData,
    aqDownloadData
}

export default aqiService