import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import moment from "moment";
import Highcharts, { color, getOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Dropdown } from "primereact/dropdown";
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";
import aqiService from "./AirQualityService";
import Subscribe from "./Subscribe";
import service from "../../service/dbService";
import { useSelector } from 'react-redux';
import HighChartsExport from "highcharts/modules/exporting"
HighChartsExport(Highcharts)

const aqiLimitdata = [
    {
        sl: 1,
        aqiCategory: "Good",
        "pm2_5": "0 - 30",
        "pm_10": "0 - 50",
        "pub2_5": "0 - 30",
        "pub_10": "0 - 50",
        color: "#2CAD00",
    }, {
        sl: 2,
        aqiCategory: "Satisfactory",
        "pm2_5": "30 - 60",
        "pm_10": "50 - 100",
        "pub2_5": "30 - 60",
        "pub_10": "50 - 100",
        color: "#30CB71",
    }, {
        sl: 3,
        aqiCategory: "Moderately Polluted",
        "pm2_5": "60 - 90",
        "pm_10": "100 - 250",
        "pub2_5": "60 - 90",
        "pub_10": "100 - 250",
        color: "#F7E65A",
    }, {
        sl: 4,
        aqiCategory: "Poor",
        "pm2_5": "90 - 120",
        "pm_10": "250 - 350",
        "pub2_5": "90 - 120",
        "pub_10": "250 - 350",
        color: "#FFB500",
    }, {
        sl: 5,
        aqiCategory: "Very Poor",
        "pm2_5": "120 - 250",
        "pm_10": "350 - 430",
        "pub2_5": "120 - 250",
        "pub_10": "350 - 430",
        color: "#FF8700",
    }, {
        sl: 6,
        aqiCategory: "Severe",
        "pm2_5": "250+",
        "pm_10": "430+",
        color: "#DE3B00",
    }
];

const style = {
    border: { border: "1px", borderStyle: "solid", borderColor: "#e4e4e4" }
}

const AirQuality = () => {
    const [date, setDate] = useState(null);
    const [hourlyData, setHourlyData] = useState([])
    const [thingsData, setThingsData] = useState(null)
    const [allThingsData, setAllThingsData] = useState(null)
    const [optedService, setOptedService] = useState(false)
    const [load, setLoader] = useState(true)
    const [thingName, setThingName] = useState(null)
    const thingsNameMap = useRef({})
    const serviceId = localStorage.getItem("serviceId")
    const thingNames = useSelector(state => state.thingNameContainer);
    let arr=thingNames.length > 0?thingNames[thingNames.length-1]: [];

    useLayoutEffect(()=>{
        if (thingName) {
            let filteredThings
            if (thingName.code === "all_sites") {
                filteredThings = JSON.parse(JSON.stringify(allThingsData.filter((item) => item.code !== thingName.code)))
            }
            else {
                filteredThings = JSON.parse(JSON.stringify(allThingsData.filter((item) => item.code === thingName.code)))
                filteredThings[0].data[0].chart.height = '320px'
            }
            setThingsData(filteredThings)
        }
        else {
            setThingsData(allThingsData)
        }
    },[thingName])

    useEffect(() => {
        const today = moment().format("YYYY-MM-DD");
        function getAqi() {
            let promiseArr=[aqiService.getThingsData(serviceId), aqiService.getAqiData(serviceId,arr), aqiService.getDayChartDetails(today, serviceId,arr)];
            if(arr.length===0){
                promiseArr=[service.getThingNames(serviceId),...promiseArr];
            }
            Promise.all(promiseArr).then((res) => {
                let first=[];
                if(arr.length>0){
                    thingNames.map((item)=>{
                        if(!Array.isArray(item)){
                           first.push(item); 
                        }
                    })
                }
                let _thingDetails = arr.length>0?first:res[0];
                let thingsInfo =arr.length>0?res[0]:res[1]
                let aqiData =arr.length>0?res[1]:res[2] 
                let chartData =arr.length>0?res[2]:res[3]
                let thingsMap = new Map()
                // to show only those spaces whose data is present.
                setLoader(false)
                thingsInfo.forEach((item) => {
                    let index = _thingDetails.findIndex((elm) =>(( elm.spaceName === item.sensorIdentification.itemId) && (item.installationParameters.type === "aqisensor" || item.installationParameters.type === "noiseaqisensor")));
                    if (index > -1) {
                        thingsMap.set(item.sensorIdentification.itemId, {
                            name: _thingDetails[index].alias || _thingDetails[index].description ,
                            code: item.sensorIdentification.itemId,
                            meta: [],
                            data: []
                        })
                        thingsNameMap.current[item.sensorIdentification.itemId] = _thingDetails[index].alias || _thingDetails[index].description
                    }
                });
                if(thingsMap.size){
                    setOptedService(true)
                }
                aqiData.map((item) => {
                    let thingData = thingsMap.has(item.thingName)
                    if (thingData) {
                        let value = thingsMap.get(item.thingName)
                        value.meta.push(item)
                        thingsMap.set(item.thingName, value)
                    }
                })
                for (let [key, value] of thingsMap.entries()) {
                    let categories = []
                    let pm2_5 = []
                    let pm10 = []
                    chartData.map((item) => {
                        if (item.thingName === key) {
                            let timeString = item.hr + ":" + "00"
                            categories.push(moment(timeString, "HH:mm").format("hh:mm a"))
                            pm2_5.push(item.avgpm25)
                            pm10.push(item.avgpm10)
                        }
                    })
                    const chart = getChartOPtion(`${value.name} - ${moment(today).format("Do, MMM YYYY")}`, categories, pm2_5, pm10, thingsMap.size, ["pm 2.5", "pm 10"])
                    value.data.push(chart)
                    thingsMap.set(key, value)
                }
                setThingsData(Array.from(thingsMap.values()))
                let allThings = JSON.parse(JSON.stringify(Array.from(thingsMap.values())))
                let allSitesObj = {
                    name: "All Sites",
                    code : "all_sites",
                    meta : [],
                    data : []
                }
                allThings.push(allSitesObj)
                setAllThingsData(allThings)
            });
        }
        getAqi()
        let interval = setInterval(() => {
            getAqi()
        }, 900000)
        setDate(today);
        return (() => {
            clearInterval(interval)
        })
    }, [])

    useEffect(() => {
        if (thingsData && Object.keys(thingsData).length && date) {
            const startTime = moment(date).subtract(2, "days").format("YYYY-MM-DD");
            const mid = moment(date).subtract(1, 'days').format("YYYY-MM-DD")
            const endTime = moment(date).format("YYYY-MM-DD");
            initReport(startTime, mid, endTime);
        }
    }, [thingsData, date])

    const initReport = (startTime, mid, endTime) => {
        aqiService.getHourlyData(startTime, mid, endTime, serviceId).then((res) => {
            let hourData = res
            let hdata = []
            if (hourData && hourData.length) {
                let hour = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
                for (let i = 0; i < thingsData.length; i++) {
                    let thing = thingsData[i].code
                    hour.map((h) => {
                        let res = hourData.find(item => (((item._id.thing === thing) && (item._id.hr === h))))
                        if (res) {
                            res._id.hr = moment(res._id.hr, "h").format("hh a") + " - " + moment(res._id.hr, "h").add(1, "h").format("hh a")
                            hdata.push(res)
                        }
                        else {
                            hdata.push({
                                _id: { thing: thing, hr: moment(h, "h").format("hh a") + " - " + moment(h, "h").add(1, "h").format("hh a") },
                                today: [],
                                yesterday: [],
                                dayBefore: []
                            })
                        }
                    })
                }
            }
            setHourlyData(hdata)
        })

    }
    const getChartOPtion = (name, categories, data1, data2, len, seriesname) => {
        return {
            chart: {
                height: (len > 1 ? len === 2 ? "228px" : "190px" : "320px"),
                type: "spline",
            },
            title: {
                text: name,
                style: chartHeadingStyle,
            },
            xAxis: {
                categories: categories,
                crosshair: true,
            },
            yAxis: {
                title: {
                    text: "AQI Level",
                },
                // plotLines: [
                //     {
                //         value: 55,
                //         zIndex: 2,
                //         width: 2,
                //         dashStyle: "Dash",
                //         color: "black",
                //     },
                //     {
                //         value: 45,
                //         zIndex: 2,
                //         width: 2,
                //         dashStyle: "Dash",
                //         color: "black",
                //     },
                // ],
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><th style="color:{series.color};padding:0;text-transform:uppercase">{series.name}: </th>' + '<th style="padding:0"><b>{point.y:.1f}</b></th></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            legend: {
                itemStyle: {
                    textTransform: "uppercase",
                },
            },
            series: [{
                name: seriesname[0],
                data: data1,
                color: "#E9C46A"
            },
            {
                name: seriesname[1],
                data: data2,
                color: "#2A9D8F"
            }],
            exporting :{
                buttons: {
                    contextButton: {
                        menuItems: ['viewFullscreen','downloadPNG', 'downloadJPEG', 'downloadPDF']
                    }
                }
            }
        };
    }
    
    function isNumberInRange(number, min, max) {
        return number >= min && number <= max;
    }
    const getAQIColor = (type, value, info) => {
        for (let i = 0; i < aqiLimitdata.length; i++) {
            if (value > 0) {

                if (((type === "pub_10" || type === "pm_10") && value > 430) || ((type === "pm2_5" || type === "pm2_5") && value > 250)) {
                    return info ? "Severe" : "#DE3B00"
                }
                else if (isNumberInRange(value, aqiLimitdata[i][type].split("-")[0], aqiLimitdata[i][type].split("-")[1])) {
                    return info ? aqiLimitdata[i].aqiCategory : aqiLimitdata[i].color
                }
            }
            else {
                return ""
            }
        }
    }

        return (
            !load ? optedService ? <>
                <div className="field col-12 lg:col-4">
                    <label htmlFor="minmax" className="block">
                        Select Location
                    </label>
                    <Dropdown value={thingName} options={allThingsData} onChange={(e) => setThingName(e.value)} className="bg-white w-full" optionLabel="name" placeholder="All Sites" />
                </div>
                {thingsData && thingsData.length && thingsData[0].meta && !thingsData[0].meta.length ? 
                    <>
                        <div className="col-12 " style={{ color: "#01579B" }}>
                            <div className="card flex flex-column align-items-center justify-content-center" style={{ height: "60vh", backgroundColor: "#B3E5FC" }}>
                                <span className="fa-solid fa-circle-info" style={{ fontSize: "3rem" }}></span>
                                <h3>No data received from the sensors</h3>
                            </div>
                        </div>
                    </> : 
                    <>
                        <div className="col-12 grid justify-content-between">
                            <div className='grid col-12 m-0 overflow-x-auto lg:col-6'>
                                <table className="w-full card">
                                    <thead>
                                        <tr>
                                            <th><h5 className="mb-1 text-xl">Location</h5></th>
                                            <th><h5 className="mb-1 text-xl">Particulate Matter 2.5</h5></th>
                                            <th><h5 className="mb-1 text-xl">Particulate Matter 10</h5></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {thingsData && thingsData.map((item, index) => {
                                            return <tr style={style.border} key={index} className="h-12rem min-h-12rem" >
                                                <td className="card text-center" style={{ width: "33%", height: 100 / thingsData?.length + "%" }} ><i style={{ color: "#EA4335" }} className="fa fa-solid fa-location-dot text-3xl" ></i><h5>{item.name}</h5></td>
                                                <td className="card text-center bg-primary" style={{ width: "33%", height: 100 / thingsData?.length + "%" }} ><h1 style={{ color: getAQIColor("pm2_5", item.meta[0]?.pm2_5) }} className="m-0 text-6xl">{item.meta.length ? item.meta[0].pm2_5 : "--"}</h1><p style={{ color: getAQIColor("pm2_5", item.meta[0]?.pm2_5) }} className="text-xl">{getAQIColor("pm2_5", item.meta[0]?.pm2_5, "category")}</p></td>
                                                <td className="card text-center bg-primary" style={{ width: "33%", height: 100 / thingsData?.length + "%" }} ><h1 style={{ color: getAQIColor("pm_10", item.meta[0]?.pm10) }} className="m-0 text-6xl">{item.meta.length ? item.meta[0].pm10 : "--"}</h1><p style={{ color: getAQIColor("pm_10", item.meta[0]?.pm10) }} className="text-xl">{getAQIColor("pm_10", item.meta[0]?.pm10, "category")}</p></td>
                                            </tr>
                                        })}
                                        <tr style={style.border} >
                                            <td className="card text-center" style={{ width: "33%", height: 100 / thingsData?.length + "%" }} ><h5>Public AQI</h5></td>
                                            <td className="card text-center bg-primary" style={{ width: "33%", height: 100 / thingsData?.length + "%" }} ><h1 style={{ color: getAQIColor("pm2_5", thingsData?.length && thingsData[0].meta[0].pub_pm2_5) }} className="m-0 text-6xl">{thingsData?.length ? thingsData[0].meta[0].pub_pm2_5 : "--"}</h1><p style={{ color: getAQIColor("pm2_5", thingsData?.length && thingsData[0].meta[0].pub_pm2_5, "") }} className="text-xl">{getAQIColor("pm2_5", thingsData?.length && thingsData[0].meta[0].pub_pm2_5, "category")}</p></td>
                                            <td className="card text-center bg-primary" style={{ width: "33%", height: 100 / thingsData?.length + "%" }} ><h1 style={{ color: getAQIColor("pm_10", thingsData?.length && thingsData[0].meta[0].pub_pm10) }} className="m-0 text-6xl">{thingsData?.length ? thingsData[0].meta[0].pub_pm10 : "--"}</h1><p style={{ color: getAQIColor("pm_10", thingsData?.length && thingsData[0].meta[0].pub_pm10) }} className="text-xl">{getAQIColor("pm_10", thingsData?.length && thingsData[0].meta[0].pub_pm10, "category")}</p></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="grid col-12 m-0 lg:col-6 p-0">
                                {thingsData && thingsData.length ? thingsData.map((item, index) => {
                                    return <div className="col-12" key={index}>
                                        <div className="card h-full">
                                            <HighchartsReact highcharts={Highcharts} options={item.data[0]} />
                                        </div>
                                    </div>
                                }) : null}
                            </div>
                        </div >

                        <div className="grid col-12">
                            <div className="col-12 lg:col-6">
                                <div className=" col-12 overflow-x-auto card">
                                    <h5>AQI Category Range</h5>
                                    <table style={{ borderCollapse: "collapse" }} className="w-full">
                                        <thead className="bg-primary">
                                            <tr>
                                                <th className="th">#</th>
                                                <th className="th">AQI Category</th>
                                                <th className="th">PM 2.5</th>
                                                <th className="th">PM 10</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {aqiLimitdata && aqiLimitdata.length ? aqiLimitdata.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="td" style={style.border} bgcolor={item.color}>{item.sl}</td>
                                                        <td className="td" style={style.border} bgcolor={item.color}>{item.aqiCategory}</td>
                                                        <td className="td" style={style.border} bgcolor={item.color}>{item.pm2_5}</td>
                                                        <td className="td" style={style.border} bgcolor={item.color}>{item.pm_10}</td>
                                                    </tr>)
                                            })
                                                : <p>NO Data</p>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        < div className="grid col-12" >
                            <div className=" col-12">
                                <div className="card overflow-x-auto overflow-y-auto h-30rem">
                                    <h5>Detailed Air Quality Report of 3 days</h5>
                                    <table style={{ borderCollapse: "collapse" }} className="w-full">
                                        <thead className="bg-primary">
                                            <tr>
                                                <th className="th" rowSpan={2}>Location</th>
                                                <th className="th" rowSpan={2} >Hours</th>
                                                <th className="th" colSpan={4}>{moment().format("Do, MMM YYYY")}</th>
                                                <th className="th" colSpan={4}>{moment().subtract(1, 'days').format("Do, MMM YYYY")}</th>
                                                <th className="th" colSpan={4}>{moment().subtract(2, 'days').format("Do, MMM YYYY")}</th>
                                            </tr>
                                            <tr>
                                                <th className="th">PM 2.5</th>
                                                <th className="th">Pub 2.5</th>
                                                <th className="th">PM 10</th>
                                                <th className="th">Pub 10</th>
                                                <th className="th">PM 2.5</th>
                                                <th className="th">Pub 2.5</th>
                                                <th className="th">PM 10</th>
                                                <th className="th">Pub 10</th>
                                                <th className="th">PM 2.5</th>
                                                <th className="th">Pub 2.5</th>
                                                <th className="th">PM 10</th>
                                                <th className="th">Pub 10</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {hourlyData && hourlyData.length ? hourlyData.map((item, index) => {
                                                return (<tr key={index}>
                                                    {index % 24 === 0 ? <td rowSpan={24} style={style.border} className="td">{thingsNameMap.current[item?._id?.thing]}</td> : null}
                                                    <td style={style.border} className="td">{item?._id?.hr}</td>
                                                    <td bgcolor={getAQIColor("pm2_5", item.today[0]?.avg2_5)} style={style.border} className="td">{item.today.length ? item.today[0].avg2_5 : "--"}</td>
                                                    <td bgcolor={getAQIColor("pm2_5", item.today[0]?.avgpub2_5)} style={style.border} className="td">{item.today.length ? item.today[0].avgpub2_5 : "--"}</td>
                                                    <td bgcolor={getAQIColor("pm_10", item.today[0]?.avgpm10)} style={style.border} className="td">{item.today.length ? item.today[0].avgpm10 : "--"}</td>
                                                    <td bgcolor={getAQIColor("pm_10", item.today[0]?.avgpub10)} style={style.border} className="td">{item.today.length ? item.today[0].avgpub10 : "--"}</td>
                                                    <td bgcolor={getAQIColor("pm2_5", item.yesterday[0]?.avg2_5)} style={style.border} className="td">{item.yesterday.length ? item.yesterday[0].avg2_5 : "--"}</td>
                                                    <td bgcolor={getAQIColor("pm2_5", item.yesterday[0]?.avgpub2_5)} style={style.border} className="td">{item.yesterday.length ? item.yesterday[0].avgpub2_5 : "--"}</td>
                                                    <td bgcolor={getAQIColor("pm_10", item.yesterday[0]?.avgpm10)} style={style.border} className="td">{item.yesterday.length ? item.yesterday[0].avgpm10 : "--"}</td>
                                                    <td bgcolor={getAQIColor("pm_10", item.yesterday[0]?.avgpub10)} style={style.border} className="td">{item.yesterday.length ? item.yesterday[0].avgpub10 : "--"}</td>
                                                    <td bgcolor={getAQIColor("pm2_5", item.dayBefore[0]?.avg2_5)} style={style.border} className="td">{item.dayBefore.length ? item.dayBefore[0].avg2_5 : "--"}</td>
                                                    <td bgcolor={getAQIColor("pm2_5", item.dayBefore[0]?.avgpub2_5)} style={style.border} className="td">{item.dayBefore.length ? item.dayBefore[0].avgpub2_5 : "--"}</td>
                                                    <td bgcolor={getAQIColor("pm_10", item.dayBefore[0]?.avgpm10)} style={style.border} className="td">{item.dayBefore.length ? item.dayBefore[0].avgpm10 : "--"}</td>
                                                    <td bgcolor={getAQIColor("pm_10", item.dayBefore[0]?.avgpub10)} style={style.border} className="td">{item.dayBefore.length ? item.dayBefore[0].avgpub10 : "--"}</td>
                                                </tr>)
                                            }) : <tr><td colSpan={4} className="pt-2 font-bold text-xl">No data Found</td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div >
                    </>}

            </> : <Subscribe serviceName="Air Quality" /> : <div style={{ width: "100%", textAlign: "center", height: "80vh", verticalAlign: "center", position: "relative" }}><i className="pi pi-spin pi-spinner" style={{ fontSize: '4rem', verticalAlign: "middle", position: "absolute", top: "45%", left: "45%" }}></i></div>)
}
export default AirQuality