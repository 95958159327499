import React, { Component } from "react";
import { Image } from "primereact/image";
import { mapKey } from "../../../shared/data/const";

class SiteReportComponent extends Component {
    mapRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            map: null,
            H: null,
        };
    }
    componentDidMount() {
        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(this.mapRef.current, defaultLayers.vector.normal.map, {
            center: { lat: this.props.data[0].siteDetails.location.latitude, lng: this.props.data[0].siteDetails.location.longitude },
            zoom: 8,
            pixelRatio: window.devicePixelRatio || 1,
        });

        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        H.ui.UI.createDefault(map, defaultLayers);

        this.setState(
            {
                H: H,
                map: map,
            },
            () => {
                this.props.data.forEach((elm) => {
                    this.addSVGMarkers(this.state.H, this.state.map, { lat: elm.siteDetails.location.latitude, lng: elm.siteDetails.location.longitude, customerSiteid: elm.customerSiteid });
                });
            }
        );
    }
    addSVGMarkers = (H, map, pos) => {
        let icon =
            '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path style="fill:#01010100; stroke:none;" d="M0 0L0 32L32 32L32 0L0 0z"/><path style="fill:#ef3d49; stroke:none;" d="M15 28L17 28C19.368 24.4869 21.95 20.9627 23.5208 17C31.413 -2.9091 1.1202 -3.05081 8.2716 16C9.88256 20.2915 12.4852 24.1989 15 28z"/><path style="fill:#ef2121; stroke:none;" d="M15 5L16 6L15 5z"/><path style="fill:#ffffff; stroke:none;" d="M13.7461 7.03241C10.2098 9.89313 14.9493 15.6823 18.3927 12.3781C21.696 9.20841 17.2399 4.20618 13.7461 7.03241z"/><path style="fill:#ff166b; stroke:none;" d="M12 7L13 8L12 7z"/><path style="fill:#36d1ba; stroke:none;" d="M5 27L5 29C11.8299 31.882 20.17 31.882 27 29L27 27L20 25L20 27L24 27L24 28L8 28L8 27L12 27L12 25L5 27z"/></svg>';

        var bearsIcon = new H.map.Icon(icon);
        const bearsMarker = new H.map.Marker({ lat: pos.lat, lng: pos.lng }, { icon: bearsIcon, data: pos }, { width: 35, height: 35 });
        bearsMarker.addEventListener("tap", this.onSVGMarkerClick);

        map.addObject(bearsMarker);
    };
    onSVGMarkerClick = (evt) => {
        let eventData = evt.target.getData();
        if (eventData.customerSiteid) this.props.onMarkerClick(eventData);
    };
    render = () => {
        return (
            <>
                <div className="col-12 lg:col-4">
                    {this.props.data.map((item, index) => (
                        <div className="card report_opt_card mb-3" key={"site-" + index} onClick={this.props.onSiteClick.bind(this, item.customerSiteid)} style={{ minHeight: "fit-content" }}>
                            <div className="flex">
                                {item.towerDetails.towerConfig.imageName ? (
                                    <div className="flex justify-content-center align-items-center mr-2">
                                        <img src={process.env.REACT_APP_IMAGE_STATIC_URL + "/"+ item.towerDetails.towerConfig.imageName} alt="Image" width="50" height="50" loading="lazy" />
                                    </div>
                                ) : (
                                    <div className="card_ioc_grid flex justify-content-center align-items-center mr-2">
                                        <i className="pi pi-map-marker" style={{ fontSize: "1.2em" }}></i>
                                    </div>
                                )}

                                <div className="flex-1">
                                    <p className="text-900 font-medium text-xl mb-0">
                                        {item.customerSiteid}{" "}
                                        <span className="text-xl" style={{ float: "right" }}>
                                            {"Type: " + (item.towerDetails.towerConfig.towerSubType !== item.towerDetails.towerConfig.towerType ? item.towerDetails.towerConfig.towerSubType + "/" : "") + item.towerDetails.towerConfig.towerType}
                                        </span>
                                    </p>
                                    <p className="text-500 text-lg mb-0">{item.siteDetails.location.address}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="col-12 lg:col-8">
                    <div className="card">
                        <div
                            ref={this.mapRef}
                            style={{
                                width: "100%",
                                height: "76vh",
                                overflow: "hidden",
                            }}
                        ></div>
                    </div>
                </div>
            </>
        );
    };
}

export default SiteReportComponent;
