import axios from "axios";

const getLiveData = async (serviceId, things) => {
    let payload = {
        projection: {
            _id: 0,
            
        }
    }
    if (things && things.length > 0) {
        payload = {
            ...payload,
            filter: {
                "thingName": { $in: things }
            }
        }
    }
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-raw-meta", payload)
    return res.data
}

const getDayData = async (serviceId, payload) => {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-daily-history", payload)
    return res.data
}

const getAggregateDayData = async (serviceId, payload) => {
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-raw-data", payload)
    return res.data
}

const service = {
    getLiveData,
    getDayData,
    getAggregateDayData
}

export default service;