import React from 'react'
import {useHistory} from 'react-router-dom'
import { Button } from 'primereact/button'

const Subscribe = ({serviceName}) => {
    const history = useHistory()
  return (
    <div className='mt-8'>

    <div className='flex flex-column justify-content-center align-items-center'>
        <i className='pi pi-lock text-8xl'></i>
    <h3 className='text-center' >{`Please Subscribe ${serviceName} service to see the dashboard`}</h3>
    </div>
    </div>
  )
}

export default Subscribe