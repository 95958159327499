import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import { towerData } from "./data";
import { mapKey } from "../../utilities/constant";
import { TiltService } from "../../service/TiltService";

class TiltMonitoring extends Component {
    mapRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            map: null,
            H: null,
            antennasList: [],
            objMarkers: [],
            selectedAntennaId: undefined,
            towerList: [],
            towers: null,
            selectedTower: null,
            networkError: false,
            selectedAddress: "",
        };
        this.tiltService = new TiltService();
        this.serviceID = "s8g3uz98gq";
        // this.serviceID = localStorage.getItem('serviceId');
    }

    componentDidMount() {
        let tiltData = [];
        this.tiltService
            .getThings(this.serviceID)
            .then((res) => {
                if (res.responseData.length > 0) {
                    const things = res.responseData;
                    Promise.all([this.tiltService.getTowers(things, this.serviceID), this.tiltService.getAntennaDetail(things, this.serviceID)]).then((res) => {
                        let towers1 = res[0];
                        let antennas = res[1];
                        towers1.forEach((item) => {
                            let location = item.location.lat;
                            let locations = location.split(",");
                            let lat = locations[0].trim();
                            let lng = locations[1].trim();
                            if (tiltData.length === 0) {
                                tiltData.push({
                                    id: item._id,
                                    name: item.location.siteName,
                                    siteId: item.location.siteId,
                                    height: item.size,
                                    address: item.location.address1 + "," + item.location.city + "," + item.location.state + "," + item.location.zip + "," + item.location.operatingCountry,
                                    pos: {
                                        lat: parseFloat(lat),
                                        lng: parseFloat(lng),
                                    },
                                });
                            } else if (!tiltData.some((el) => el.name === item.location.siteName)) {
                                tiltData.push({
                                    id: item._id,
                                    name: item.location.siteName,
                                    siteId: item.location.siteId,
                                    height: item.size,
                                    address: item.location.address1 + ", " + item.location.city + ", " + item.location.state + ", " + item.location.zip + ", " + item.location.operatingCountry,
                                    pos: {
                                        lat: parseFloat(lat),
                                        lng: parseFloat(lng),
                                    },
                                });
                            }
                        });

                        let filalData = tiltData.map((item) => {
                            let height = item.height;
                            let myAntennas = antennas.filter((elm) => elm.siteid === item.name);
                            let a = myAntennas.map((elm, index) => {
                                return {
                                    id: elm._id,
                                    name: elm.thingName,
                                    height: 40,
                                    down_tilt: elm.downtilt,
                                    inner_radius: parseFloat(elm.inRadius.toFixed(2)) * 100,
                                    outer_radius: parseFloat(elm.outRadius.toFixed(2)) * 100,
                                    coverage_area: parseFloat(elm.coverageArea.toFixed(2)),
                                    azimuth: 45 * index,
                                };
                            });
                            return { ...item, antennas: a };
                        });
                        const towerList = filalData;
                        let towers = towerList.map((item) => {
                            return { name: item.name, code: item.name };
                        });
                        const { H, map, antennasList } = this.initTiltReport(towerList);
                        this.setState({
                            towerList,
                            H,
                            map,
                            antennasList,
                            towers,
                            selectedTower: towers[0],
                            selectedAddress: towerList[0].address,
                        });
                    });
                }
            })
            .catch((e) => {
                this.setState({ networkError: true });
            });
    }

    initTiltReport = (towers) => {
        const towerPosition = towers[0].pos;
        const antennasList = towers[0].antennas;

        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(this.mapRef.current, defaultLayers.vector.normal.map, {
            center: towerPosition,
            zoom: 17,
            pixelRatio: window.devicePixelRatio || 1,
        });

        const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        const ui = H.ui.UI.createDefault(map, defaultLayers);

        this.addSVGMarkers(H, map, towerPosition);
        this.addCircle(H, map, antennasList, towerPosition);
        this.getMapObject(map);

        return { H, map, antennasList };
    };

    addSVGMarkers = (H, map, pos) => {
        map.removeObjects(map.getObjects());
        let icon =
            '<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="35.000000pt" height="35.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">' +
            '<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#0000ff" stroke="none">' +
            '<path d="M1312 5040 c-211 -244 -341 -529 -392 -865 -16 -102 -14 -354 4 -470 51 -329 196 -635 412 -868 l56 -60 111 111 110 111 -55 60 c-160 172 -284 433 -324 678 -18 113 -20 309 -4 409 43 270 149 496 334 709 l49 56 -104 105 c-57 57 -109 104 -116 104 -6 0 -43 -36 -81 -80z"/>' +
            '<path d="M3610 5015 l-104 -104 50 -58 c187 -216 291 -437 334 -707 15 -98 13 -299 -5 -410 -39 -244 -163 -505 -323 -677 l-55 -60 110 -111 111 -111 56 60 c219 237 369 559 416 891 14 105 15 344 0 442 -49 337 -179 624 -392 870 -38 44 -75 80 -81 80 -7 0 -59 -47 -117 -105z"/>' +
            '<path d="M1773 4657 c-146 -158 -243 -385 -261 -612 -24 -299 63 -565 259 -793 20 -23 40 -42 45 -42 12 0 214 202 214 214 0 4 -18 29 -41 54 -181 207 -222 534 -99 795 17 36 56 98 86 137 30 39 54 74 54 78 0 9 -203 212 -211 212 -4 0 -24 -19 -46 -43z"/>' +
            '<path d="M3192 4597 c-56 -56 -102 -106 -102 -110 0 -3 24 -38 54 -77 158 -204 201 -471 115 -710 -28 -77 -80 -167 -128 -222 -23 -25 -41 -50 -41 -54 0 -12 202 -214 214 -214 26 0 146 164 201 274 145 296 144 648 -4 946 -50 99 -176 270 -200 270 -3 0 -52 -46 -109 -103z"/>' +
            '<path d="M2483 4400 c-107 -16 -233 -96 -297 -189 -74 -107 -98 -269 -58 -397 33 -106 116 -210 204 -255 l26 -14 -519 -1515 c-285 -833 -535 -1561 -555 -1617 l-36 -103 -249 0 -249 0 0 -155 0 -155 1810 0 1810 0 0 155 0 155 -249 0 -249 0 -36 102 c-20 57 -270 785 -555 1618 l-519 1515 26 14 c88 45 171 149 204 255 40 128 16 290 -58 397 -67 97 -189 173 -306 189 -70 11 -72 11 -145 0z m159 -332 c78 -55 78 -174 0 -233 -19 -14 -43 -20 -82 -20 -86 0 -140 52 -140 135 0 67 44 127 105 143 32 8 87 -3 117 -25z m-8 -1111 c38 -111 71 -208 73 -214 4 -10 -29 -13 -147 -13 -118 0 -151 3 -147 13 2 6 35 103 73 214 38 112 71 203 74 203 3 0 36 -91 74 -203z m267 -779 l83 -242 -209 -203 c-115 -112 -212 -203 -215 -203 -3 0 -100 91 -215 203 l-209 203 83 242 83 242 258 0 258 0 83 -242z m-576 -871 c0 -9 -597 -588 -602 -584 -1 1 66 201 150 444 l151 441 151 -146 c82 -80 150 -150 150 -155z m918 -127 c80 -234 149 -434 153 -445 7 -19 -92 73 -451 418 l-160 154 155 150 c85 83 156 150 156 149 1 0 67 -192 147 -426z m-281 -477 l401 -388 -401 -3 c-221 -1 -583 -1 -804 0 l-401 3 399 387 c219 213 400 387 402 387 2 1 184 -173 404 -386z"/></g></svg>';

        var bearsIcon = new H.map.Icon(icon);
        const bearsMarker = new H.map.Marker(pos, { icon: bearsIcon }, { width: 50, height: 50 });
        map.addObject(bearsMarker);
        map.setCenter(pos);
    };

    addCircle = (H, map, antennasList, pos) => {
        antennasList.forEach((item, index) => {
            var circlePos = this.getCirclePosition(item.azimuth, item.outer_radius, pos);
            this.createCircle(H, map, circlePos, item.inner_radius, item.outer_radius, "circle-" + index);
        });
    };

    createCircle = (H, map, pos, innter_radius, outer_radius, name) => {
        let circle = new H.map.Circle(pos, outer_radius - innter_radius, {
            style: {
                strokeColor: "rgba(55, 85, 170, 0.6)",
                lineWidth: 2,
                fillColor: "rgba(0, 128, 0, 0.5)",
            },
        });
        circle.setData(name);
        map.addObject(circle);
    };

    getCirclePosition = (azimuth, distance, pos) => {
        const pi = Math.PI;
        const R = 6378.1;
        const brng = azimuth * (pi / 180);
        const d = distance / 1000;
        const currentPosition = { lat: pos.lat, lng: pos.lng };

        var lat1 = currentPosition.lat * (pi / 180);
        var lng1 = currentPosition.lng * (pi / 180);

        var lat2 = Math.asin(Math.sin(lat1) * Math.cos(d / R) + Math.cos(lat1) * Math.sin(d / R) * Math.cos(brng));
        var lng2 = lng1 + Math.atan2(Math.sin(brng) * Math.sin(d / R) * Math.cos(lat1), Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2));

        return { lat: lat2 * (180 / pi), lng: lng2 * (180 / pi) };
    };

    getMapObject = (map) => {
        let O = map.getObjects();
        this.setState({
            objMarkers: O.filter((elm) => elm.hasOwnProperty("data")),
        });
    };

    hilightMarker = (key, id) => {
        var name = "circle-" + key;
        this.setState({ selectedAntennaId: id });
        this.state.objMarkers.map((elm) => elm.setStyle({ strokeColor: "rgba(55, 85, 170, 0.6)", lineWidth: 2, fillColor: "rgba(0, 128, 0, 0.5)" }));
        var index = this.state.objMarkers.findIndex((e) => e.data === name);
        if (index > -1) {
            this.state.objMarkers[index].setStyle({ strokeColor: "rgba(55, 85, 170, 0.6)", lineWidth: 2, fillColor: "rgba(226, 245, 39, 0.48)" });
            //this.state.map.setCenter(this.state.objMarkers[index].N);
        }
    };

    handleChange = (val) => {
        var index = this.state.towerList.findIndex((item) => item.name === val.name);
        if (index > -1) {
            var towerPos = this.state.towerList[index].pos;
            var address = this.state.towerList[index].address;
            var antannas = this.state.towerList[index].antennas;
            this.setState({
                antennaList: antannas,
                selectedAddress: address,
            });
            this.addSVGMarkers(this.state.H, this.state.map, towerPos);
            this.addCircle(this.state.H, this.state.map, antannas, towerPos);
            this.getMapObject(this.state.map);
        }
    };

    onTowerChange = (event) => {
        this.setState({ selectedTower: event.target.value });
        this.handleChange(event.target.value);
    };

    render = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <div className="field">
                        <label htmlFor="selectTower">Select Site Name</label> <br />
                        <Dropdown value={this.state.selectedTower} options={this.state.towers} onChange={this.onTowerChange} optionLabel="name" placeholder="Select a Tower" style={{ width: "300px" }} />
                    </div>
                </div>
                <div className="col-12">
                    <div className="p-2 bg-blue-100">
                        <p className="font-medium capitalize">
                            <i className="pi pi-map-marker mr-2"></i> {this.state.selectedAddress}
                        </p>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-4">
                    <div className="antenna_grid">
                        {this.state.antennasList
                            ? this.state.antennasList.map((item, index) => (
                                  <div className="card mb-3 antenna_list" key={index} onMouseEnter={this.hilightMarker.bind(this, index.toString(), item.id)}>
                                      <div className="w-full flex align-items-center">
                                          <div className="tiltIconGrid">
                                              <i className="pi pi-wifi"></i>
                                          </div>
                                          <div className="flex-1">
                                              <p className="text-xl mb-0 text-blue-600">{item.name}</p>
                                              <span className="font-medium">
                                                  Azimuth: <span className="text-blue-600">{item.azimuth} deg</span>, Inner Radius: <span className="text-blue-600">{item.inner_radius / 100} m</span>
                                              </span>{" "}
                                              <br />
                                              <span className="font-medium">
                                                  Outer Radius: <span className="text-blue-600">{item.outer_radius / 100} m</span>, Height: <span className="text-blue-600">{item.height} m</span>
                                              </span>{" "}
                                              <br />
                                              <span className="font-medium">
                                                  Down Tilt: <span className="text-blue-600">{item.down_tilt} deg</span>, Area: <span className="text-blue-600">{item.coverage_area} Sq m</span>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : null}
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-8">
                    <div className="card">
                        <div
                            ref={this.mapRef}
                            style={{
                                height: "500px",
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        );
    };
}

export default TiltMonitoring;
