import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import ManageUsers from "./manageUsers";
import TrakingUsers from "./trakingUsers";
import MobileUser from "./mobileUser";

const AllUsers = () => {
    const serviceDetails = JSON.parse(localStorage.getItem("service"));

    return (
        <div className="grid">
            <div className="col-12">
                <TabView activeIndex={0}>
                    <TabPanel header="Web User" contentClassName="cust-tab-panel shadow-1">
                        <ManageUsers />
                    </TabPanel>
                    <TabPanel header="Mobile User">
                        <MobileUser />
                    </TabPanel>
                    {serviceDetails.serviceTypeName === "TeamTrac" && (
                        <TabPanel header="Tracking User" contentClassName="cust-tab-panel shadow-1">
                            <TrakingUsers />
                        </TabPanel>
                    )}
                </TabView>
            </div>
        </div>
    );
};

export default AllUsers;
