import React, { useEffect, useState } from 'react'
import service from '../service/dbService'
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { plazaServices } from './tollPlaza/plazaReportService';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { colorPalettes } from '../lib/constants';
import { chartHeadingStyle } from '../utilities/constant';
import AggressiveDriving from './ChartsComponents/AggressiveDriving';
export const ChargeTrends = () => {
    const [thingList, setThingList] = useState([])
    const [selectedThing, setSelectedThing] = useState();
    const [masterData, setMasterData] = useState({})
    const [charts, setCharts] = useState({})
    const [barchart, setBarchart] = useState(null)
    const serviceId = localStorage.getItem("serviceId");
    const LineChart = (type, title, xHeader, yAxis, sdata, categories) => {
        const lineChart = {
            chart: {
                type: `${type}`
            },
            title: {
                text: title,
                align: 'center',
                style: chartHeadingStyle
            },
            xAxis: {
                categories: categories,
                accessibility: {
                    rangeDescription: ''
                },
                title: {
                    text: xHeader
                }
            },
            yAxis: yAxis,
            legend: {
                enabled: false
            },
            showInLegend: false,
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                }
            },

            series: sdata,
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ['viewFullscreen', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
                    }
                }
            }
        };
        return lineChart;
    };
    const dataGatherer = async (ThingName) => {
        let matchObj = {};
        if (ThingName.value) matchObj["thingName"] = ThingName.value;
        else matchObj["thingName"] = ThingName;
        const payload = {
            filter: matchObj,
            projection: {

            }
        }
        plazaServices.general(serviceId, payload, "nq-cycle-count").then((res) => {
            if (res && res.length) {
                const dataToFormMap = {
                    cyc_count: [],
                    max_amb_temp: [],
                    holding_time: [],
                    effective_capacity: [],
                    charging_efficiency: [],
                    charging_overhead: [],
                    healthy_cell_count: [],
                    unhealthy_cell_count: []
                }
                res.forEach(item => {
                    Object.keys(dataToFormMap).forEach(key => {
                        dataToFormMap[key].push(item[key])
                    })
                });
                const chartsInfo = {
                    CapacityLine: {
                        yAxisCount: 1,
                        chartType: "line",
                        chartHeading: "Change in Effective AH with age (cycles)",
                        xAxisHeader: "No Of Cycles",
                        yAxisHeader: ["Effective Capacity"],
                        seriesName: ["Effective Capacity"],
                        series: [dataToFormMap.effective_capacity],
                        categories: dataToFormMap.cyc_count
                    },
                    Efficiencyline: {
                        yAxisCount: 1,
                        chartType: "line",
                        chartHeading: "Change in Charging efficiency with age",
                        xAxisHeader: "No Of Cycles",
                        yAxisHeader: ["Efficiency"],
                        seriesName: [],
                        series: [dataToFormMap.charging_efficiency],
                        categories: dataToFormMap.cyc_count
                    },
                    ChargingOverhead: {
                        yAxisCount: 2,
                        chartType: "line",
                        chartHeading: "Charging Overhead",
                        xAxisHeader: "No Of Cycles",
                        yAxisHeader: ["Charging Overhead", "Temperature"],
                        seriesName: ["Charging Overhead", "Temperature"],
                        series: [dataToFormMap.charging_overhead, dataToFormMap.max_amb_temp],
                        categories: dataToFormMap.cyc_count
                    },
                    HoldingTime: {
                        yAxisCount: 1,
                        chartType: "line",
                        chartHeading: "Holding Time",
                        xAxisHeader: "No Of Cycles",
                        yAxisHeader: ["HoldingTime"],
                        seriesName: ["HoldingTime"],
                        series: [dataToFormMap.effective_capacity],
                        categories: dataToFormMap.cyc_count
                    }
                }

                let barchart = {
                    yAxisTitle: "Cells Count",
                    title: `Cells Health`,
                    data: [
                        {
                            name: "Cells degrading fast (Below 3.2 V)",
                            data: dataToFormMap.unhealthy_cell_count,
                            color: colorPalettes.color5,
                        },
                        {
                            name: "Total cells working healthy (Above 3.2 V)",
                            data: dataToFormMap.healthy_cell_count,
                            color: colorPalettes.color2,
                        },
                        
                    ],
                    category: dataToFormMap.cyc_count,
                }
                const charts = {}
                Object.keys(chartsInfo).forEach(chart => {
                    const YAxisAndSeriesDatares = returnYAxisAndSeriesData(chartsInfo[chart].yAxisCount, chartsInfo[chart].yAxisHeader, chartsInfo[chart].series, chartsInfo[chart].seriesName)
                    if (typeof (YAxisAndSeriesDatares) === "object") {
                        const { yAxis, seriesData } = YAxisAndSeriesDatares
                        charts[chart] = LineChart(chartsInfo[chart].chartType, chartsInfo[chart].chartHeading, chartsInfo[chart].xAxisHeader, yAxis, seriesData, chartsInfo[chart].categories)
                    }
                })
                setBarchart(barchart)
                setCharts(charts)
                setMasterData(res)
            }
        })
    }
    const populate = async () => {
        let projection = { thingName: 1, "location.siteName": 1 }
        let res = await service.getThingNames(serviceId, projection);
        let tempArray = [];
        res.map((item) => {
            item.value = item.thingName
            item.name = item.location.siteName

        })
        setSelectedThing(res[0].value)
        setThingList(res)
        dataGatherer(res[0])
    }
    useEffect(() => {
        populate();
    }, [])

    const returnYAxisAndSeriesData = (noOfYAxis, yAxisHeaders = [], series = [], seriesNames = []) => {
        const colors = ["color2", "color5", "color3"]
        if (noOfYAxis > 2) return "YAxis cannot be greater than 2"
        if (noOfYAxis !== yAxisHeaders.length && noOfYAxis !== series.length && noOfYAxis !== seriesNames.length) return "Y Axis count and header/data miss match"
        const yAxis = []
        const seriesData = []
        for (let i = 0; i < noOfYAxis; i++) {
            const yAxisTemplate = {
                title: {
                    text: yAxisHeaders[i] ?? "",
                    style: {
                        color: colorPalettes[colors[i]]   // Match color of Series 1
                    }
                },
                labels: {
                    format: '{value}',
                    style: {
                        color: colorPalettes[colors[i]]   // Match color of Series 1
                    }
                },
                opposite: i % 2 === 0 ? false : true  // This axis will be on the left side
            }
            const seriesTemplate = {
                name: seriesNames[i] ?? "",
                data: series[i],
                yAxis: i,
                color: colorPalettes[colors[i]]
            }
            yAxis.push(yAxisTemplate)
            seriesData.push(seriesTemplate)
        }
        return { yAxis, seriesData }
    }

    return (
        <div className='col-12 grid justify-content-center ml-1 lg:ml-0'>
            <div className='col-12 grid lg:mr-4 mr-0'>
                {thingList && thingList.length ? <div className='lg:col-3 col-12'><Dropdown
                    id="tollDropdown"
                    value={selectedThing}
                    options={thingList}
                    onChange={(e) => {
                        setSelectedThing(e.value)
                        dataGatherer(e.value)
                    }}
                    optionLabel="name"
                    placeholder={
                        <span className="flex align-items-center" style={{ color: '#0C6291' }}>
                            <i className="" style={{ marginRight: '8px' }}></i>
                            Select a Site
                        </span>
                    }

                    className="p-dropdown flex align-items-center bg-white"
                    style={{
                        borderRadius: '20px',
                        border: '2px solid #0C6291',
                        width: "100%"
                    }}
                /></div> : null}
            </div>
            <div className='col-12 flex flex-wrap  justify-content-around  card  lg:ml-0 borderStyle3' style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <h3 className='col-12 flex justify-content-center align-items-center font-bold text-2xl text-gray-800 text-uppercase mb-3'>Change in effective capacity and charging efficiency with age</h3>
                <div className='lg:col-4 col-12   '>
                    <DataTable value={masterData} selectionMode='single' rows={10} style={{ marginTop: "0" }} scrollable className='mt-1' onRowSelect={(row) => { }}>
                        <Column headerClassName='color2-bg font-medium' header="Cycle Count" bodyClassName='bg-white font-medium  surface-300 bg-gray-200' field="cyc_count"></Column>
                        <Column headerClassName='color2-bg' header="Effective Capacity" field="effective_capacity" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 col-1' ></Column>
                        <Column headerClassName='color2-bg' header="Charging Efficiency" field="charging_efficiency" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 col-1' ></Column>
                    </DataTable>
                </div>
                <div className='lg:col-8 col-12'>
                    <HighchartsReact highcharts={Highcharts} options={charts["CapacityLine"]} />
                </div>
                <div className=' col-12'>
                    <HighchartsReact highcharts={Highcharts} options={charts["Efficiencyline"]} />
                </div>
            </div>
            <div className='col-12 card borderStyle2'>
                <HighchartsReact highcharts={Highcharts} options={charts["ChargingOverhead"]} />
            </div>
            <div className='col-12 card borderStyle1'>
                <HighchartsReact highcharts={Highcharts} options={charts["HoldingTime"]} />
            </div>
            <div className='col-12 card borderStyle4'>
                <div className='col-12 grid'>
                    <div className='col-12 lg:col-4'>
                        <DataTable value={masterData} selectionMode='single' rows={10} style={{ marginTop: "0" }} scrollable className='mt-1' onRowSelect={(row) => { }}>
                            <Column headerClassName='color2-bg font-medium' header="Cycle Count" bodyClassName='bg-white font-medium  surface-300 bg-gray-200' field="cyc_count"></Column>
                            <Column headerClassName='color2-bg' header="Total cells working healthy " field="healthy_cell_count" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 col-1' ></Column>
                            <Column headerClassName='color2-bg' header="Total cells degrading " field="unhealthy_cell_count" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 col-1' ></Column>
                        </DataTable>
                    </div>
                    <div className='col-12 lg:col-8'>
                        {barchart ? <AggressiveDriving chartData={barchart} /> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
