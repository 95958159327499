import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { useState } from "react";
import { useEffect } from "react";
import * as XLSX from "xlsx";
import { VehicleSrvice } from "../../service/VehicleService";
import { convertValue } from "../../utilities/unitConversion";
import { secondsToHm } from "../../utilities/utillFunction";
import { FuelMonitoringService } from "../../service/FuelMonitoringService";
import { useSelector } from 'react-redux';
import { stationaryVehicle } from "../../lib/constants";

const AllVehicles = () => {
    const serviceID = localStorage.getItem("serviceId");
    const serviceDetails = JSON.parse(localStorage.getItem("service"));
    // const { region } = serviceDetails;
    const [dateRange, setDateRange] = useState(null);
    const [monthRange, setmonthRange] = useState(null);
    const [radioButton, setRadioButton] = useState("Summery");
    const [data, setData] = useState(null);
    const [duplicateData, setDuplicateData] = useState(null);
    const [origine, setOrigine] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [monthPicker, setMonthPicker] = useState(new Date());
    const [monthlyReport, setmonthlyReport] = useState(null);
    const [duplicatemonthlyReport, setduplicatemonthlyReport] = useState(null);
    const vehicleService = new VehicleSrvice();
    const fuelService = new FuelMonitoringService();
    const thingNames = useSelector(state => state.thingNameContainer);
    let arr=thingNames.length > 0?thingNames[thingNames.length-1]: [];
    useEffect(() => {
        const startDate = moment().subtract(1, "d").format("MM-DD-YYYY");
        const endDate = moment().subtract(1, "d").format("MM-DD-YYYY");

        const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
        const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

        let dateRange = [new Date(startDate), new Date(endDate)];
        let monthRange = [startOfMonth, endOfMonth];
        setDateRange(dateRange);
        setmonthRange(monthRange);
    }, []);

    useEffect(() => {
        const initReport = () => {
            let from_date = moment(dateRange[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss");
            let to_date = moment(dateRange[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss");
            const payload = {
                operation : "aggregate",
                aggregate: [{
                    $match: {
                    startTime: {
                        $gte: from_date,
                        $lte: to_date,
                    }
                },
                }, {
                    $sort : {
                        startTime : 1
                    }
                }],
            };
            const payload2 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            date: {
                                $gte: moment(from_date).format("YYYY-MM-DD"),
                                $lte: moment(to_date).format("YYYY-MM-DD"),
                            },
                        },
                    },
                    {
                        $sort : {
                            date : 1
                        }
                    },{
                        $project: {
                            _id: 0, avgAqi10: 0, avgAqi25: 0, avgHumidity: 0, avgTemp: 0, city: 0, state: 0, zipcode: 0, lastodometer: 0, NumberofRecords: 0
                        }
                    }
                ],
            };
            if (arr.length > 0 && serviceDetails.serviceTypeName === "Fuel Tracking") {
                let temp = {
                    $match: {
                        "thingName": { $in: arr }
                    }
                }
                payload2.aggregate = [temp, ...payload2.aggregate];
            }
            if (serviceDetails.serviceTypeName === "Vehicle tracking with Fuel") {
                Promise.all([vehicleService.getVehicleGreenDivingAvgScore(serviceID, payload), vehicleService.getVehicleNumber(serviceID), fuelService.getFuelData(serviceID, payload2)])
                    .then((res) => {
                        const vehicleData = res[0];
                        const allVehicleDetails = res[1];
                        const fuelData = res[2];
                        let tableData = [];
                        let origineData = [];
                        vehicleData.forEach((item) => {
                            let tempObj,
                                tempObj2,
                                hmr,
                                fuelEfficiency = 0;
                            let index = allVehicleDetails.findIndex((elm) => item.thingName === elm.fleetName);
                            let index2 = fuelData.findIndex((elm) => elm.thingName === item.thingName && moment(elm.date).isSame(item.date, "day"));
                            if (index > -1) {
                                tempObj = {
                                    thingName: item.thingName,
                                    licencePlateNo: allVehicleDetails[index].vehicle.vehicleNumber,
                                    make: allVehicleDetails[index].vehicle.make,
                                    model: allVehicleDetails[index].vehicle.model,
                                    state: item.state,
                                    city: item.city,
                                    pincode: item.pincode,
                                    date: item.date,
                                    totalTravelTime: item.totalTravelTime,
                                    totalStoppageTime: item.totalStoppageDuration,
                                    totalDistanceTravelled: item.totalDistanceTravelled,
                                    speedavg: item.avgspeed,
                                    speedmax: item.maxspeed,
                                    stoppagePoints: item.stoppagePoints.length,
                                    totalidletime: item.totalidletime,
                                };
                                tempObj2 = {
                                    licencePlateNo: allVehicleDetails[index].vehicle.vehicleNumber,
                                    make: allVehicleDetails[index].vehicle.make,
                                    model: allVehicleDetails[index].vehicle.model,
                                    ...item,
                                };
                            }
                            if (index2 > -1) {
                                if (stationaryVehicle.includes(fuelData[index2].type) ) {
                                    fuelEfficiency = fuelData[index2].totalrunhours !== 0 ? (fuelData[index2].fuelConsumed / (fuelData[index2].totalrunhours / 3600)).toFixed(2) + "L/hr" : "--";
                                    hmr = fuelData[index2].hmr > 0 ? fuelData[index2].hmr.toFixed(1) : "--";
                                } else {
                                    fuelEfficiency = fuelData[index2].fuelConsumed !== 0 ? (item.totalDistanceTravelled / fuelData[index2].fuelConsumed).toFixed(2) + "Kmpl" : "--";
                                    hmr = fuelData[index2].hmrodo > 0 ? (fuelData[index2].hmrodo / 1000).toFixed(1) : "--";
                                }
                                origineData.push({
                                    ...tempObj2,
                                    openingfuel: fuelData[index2].openingfuel,
                                    totalrunhours: secondsToHm(fuelData[index2].totalrunhours),
                                    endingfuel: fuelData[index2].endingfuel,
                                    fuelConsumed: fuelData[index2].fuelConsumed.toFixed(2),
                                    fuelConsumedCost: fuelData[index2].fuelConsumedCost.toFixed(2),
                                    fuellingAmount: fuelData[index2].fuellingAmount,
                                    defuellingAmount: fuelData[index2].defuellingAmount,
                                    fuelEfficiency: fuelEfficiency,
                                    ignitionStartTime: fuelData[index2].ignitionStartTime !== "" ? moment(fuelData[index2].ignitionStartTime).format("hh:mm a") : "--",
                                    ignitionEndTime: fuelData[index2].ignitionEndTime !== "" ? moment(fuelData[index2].ignitionEndTime).format("hh:mm a") : "--",
                                    hmr: hmr,
                                });
                                tableData.push({
                                    ...tempObj,
                                    openingfuel: fuelData[index2].openingfuel,
                                    totalrunhours: secondsToHm(fuelData[index2].totalrunhours),
                                    endingfuel: fuelData[index2].endingfuel,
                                    fuelConsumed: fuelData[index2].fuelConsumed.toFixed(2),
                                    fuelConsumedCost: fuelData[index2].fuelConsumedCost.toFixed(2),
                                    fuellingAmount: fuelData[index2].fuellingAmount,
                                    defuellingAmount: fuelData[index2].defuellingAmount,
                                    fuelEfficiency: fuelEfficiency,
                                    ignitionStartTime: fuelData[index2].ignitionStartTime !== "" ? moment(fuelData[index2].ignitionStartTime).format("hh:mm a") : "--",
                                    ignitionEndTime: fuelData[index2].ignitionEndTime !== "" ? moment(fuelData[index2].ignitionEndTime).format("hh:mm a") : "--",
                                    hmr: hmr,
                                });
                            } else {
                                origineData.push(tempObj2);
                                tableData.push(tempObj);
                            }
                        });
                        setData(tableData);
                        setDuplicateData(tableData);
                        setOrigine(origineData);
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            } 
            else if (serviceDetails.serviceTypeName  === "Fuel Tracking"){
                fuelService.getFuelData(serviceID, payload2).then((res)=>{
                    let tableData = [];
                    res.forEach((item)=>{
                        tableData.push(
                            {
                                date : item.date,
                                thingName : item.thingName,
                                sitename : item.sitename,
                                openingfuel: item.openingfuel,
                                totalrunhours: secondsToHm(item.totalrunhours),
                                endingfuel: item.endingfuel,
                                fuelConsumed: item.fuelConsumed.toFixed(2),
                                fuelConsumedCost: item.fuelConsumedCost.toFixed(2),
                                fuellingAmount: item.fuellingAmount,
                                defuellingAmount: item.defuellingAmount,
                                fuelEfficiency: item.fuelEff_lphr ?? "--",
                                ignitionStartTime: item.ignitionStartTime !== "" ? moment(item.ignitionStartTime).format("hh:mm a") : "--",
                                ignitionEndTime: item.ignitionEndTime !== "" ? moment(item.ignitionEndTime).format("hh:mm a") : "--",
                            }
                        )
                    })
                    setData(tableData)
                    setDuplicateData(tableData);
                    setOrigine(tableData)
                })
            }else {
                Promise.all([vehicleService.getVehicleGreenDivingAvgScore(serviceID, payload), vehicleService.getVehicleNumber(serviceID)])
                    .then((res) => {
                        const vehicleData = res[0];
                        const allVehicleDetails = res[1];
                        let tableData = [];
                        let origineData = [];
                        vehicleData.forEach((item) => {
                            let tempObj, tempObj2;
                            let index = allVehicleDetails.findIndex((elm) => item.thingName === elm.fleetName);
                            if (index > -1) {
                                tempObj = {
                                    thingName: item.thingName,
                                    licencePlateNo: allVehicleDetails[index].vehicle.vehicleNumber,
                                    make: allVehicleDetails[index].vehicle.make,
                                    model: allVehicleDetails[index].vehicle.model,
                                    state: item.state,
                                    city: item.city,
                                    pincode: item.pincode,
                                    date: item.date,
                                    totalTravelTime: item.totalTravelTime,
                                    totalStoppageTime: item.totalStoppageTime,
                                    totalDistanceTravelled: item.totalDistanceTravelled,
                                    speedavg: item.avgspeed,
                                    speedmax: item.maxspeed,
                                    stoppagePoints: item.stoppagePoints.length,
                                };
                                tempObj2 = { licencePlateNo: allVehicleDetails[index].vehicle.vehicleNumber, ...item };
                            }
                            origineData.push(tempObj2);
                            tableData.push(tempObj);
                        });
                        setData(tableData);
                        setOrigine(origineData);
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            }
        };
        if (dateRange) {
            if (dateRange[1] !== null) initReport();
        }

        
    }, [dateRange]);

    useEffect(() => {
        const initMonthlyReport = async(range) => {
            const payload = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            date: {
                                $gte: range[0],
                                $lte: range[1],
                            },
                        },
                    },
                    {
                        $group: {
                            _id: {
                                _id: "$thingName",
                                type: "$type",
                            },
                            totalFuelConsumed: { $sum: "$fuelConsumed" },
                            totalFuelConsumedCost: { $sum: "$fuelConsumedCost" },
                            totalFuellingAmount: { $sum: "$fuellingAmount" },
                            totalDefuellingAmount: { $sum: "$defuellingAmount" },
                            totalrunhours: { $sum: "$totalrunhours" },
                        },
                    },
                ],
            };
            const payload2 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            date: {
                                $gte: range[0],
                                $lte: range[1],
                            },
                        },
                    },
                    {
                        $group: {
                            _id: "$thingName",
                            totalDistanceTravelled: { $sum: "$totalDistanceTravelled" },
                        },
                    },
                ],
            };
            const payload3 = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            date: {
                                $gte: range[0],
                                $lte: range[1],
                            },
                        },
                    },
                    {
                        $group: {
                            _id: "$thingName",
                            totalFuelConsumed: { $sum: "$fuelConsumed" },
                            totalFuelConsumedCost: { $sum: "$fuelConsumedCost" },
                            totalFuellingAmount: { $sum: "$fuellingAmount" },
                            totalDefuellingAmount: { $sum: "$defuellingAmount" },
                            totalrunhours: { $sum: "$totalrunhours" }                        },
                    },
                ],
            };
            if(arr.length>0){
                payload3.aggregate=[{
                    $match: {
                        "thingName": { $in: arr }
                    }
                },...payload3.aggregate]
            }
            if(serviceDetails.serviceTypeName === "Fuel Tracking"){
                fuelService.getFuelData(serviceID, payload3).then((res)=>{
                    let monthlyData = []
                    let thingNameArr=[];
                    let aliasMap=new Map();
                    res.forEach((item)=>{
                        thingNameArr.push(item._id)
                        let temp = {
                            month: moment(range[0]).format("MMM"),
                            thingName : item._id, //to discuss
                            totalrunhours: secondsToHm(item.totalrunhours),
                            fuelConsumed: item.totalFuelConsumed.toFixed(2),
                            fuelConsumedCost: item.totalFuelConsumedCost.toFixed(2),
                            fuelAdded: item.totalFuellingAmount.toFixed(2),
                            fuelRemoved: item.totalDefuellingAmount.toFixed(2),
                            fuelEfficiency: item.totalFuelConsumed && item.totalrunhours && item.totalrunhours>0 ? (item.totalFuelConsumed/(item.totalrunhours/3600)).toFixed(2) : "--"
                        };
                        monthlyData.push(temp)
                    })
                    const aliasPayload= {
                        filter: {
                            "thingName":{
                                $in:thingNameArr
                            }
                        },
                        projection: {
                            "thingName":1,
                            "alias": 1,
                        }
                    }
                    fuelService.getThings(serviceID,aliasPayload).then((res)=>{
                        res.map((item)=>{
                            aliasMap.set(item.thingName,item.alias);
                        })
                        for(let i=0;i<monthlyData.length;i++){
                            monthlyData[i]={
                                ...monthlyData[i],
                                alias:aliasMap.get(monthlyData[i].thingName)
                            }
                        }
                        setmonthlyReport(monthlyData);
                        setduplicatemonthlyReport(monthlyData);
                    });
                    
                })
            }
            else{
            Promise.all([fuelService.getFuelData(serviceID, payload), vehicleService.getAggregateHistory(serviceID, payload2), vehicleService.getVehicleNumber(serviceID)]).then((res) => {
                const montelyFuelData = res[0];
                const montelyObdData = res[1];
                const vehicles = res[2];
    
                let monthelyVehicleData = [];
    
                montelyFuelData.forEach((item) => {
                    let i = montelyObdData.findIndex((elm) => elm._id === item._id._id);
                    let j = vehicles.findIndex((elm) => elm.fleetName === item._id._id);
    
                    if (i > -1 && j > -1) {
                        let fuelEfficiency = 0;
    
                        if (stationaryVehicle.includes(item._id.type)) {
                            fuelEfficiency =item.totalrunhours>0? (item.totalFuelConsumed / (item.totalrunhours / 3600)).toFixed(2) + "L/hr" :"-";
                        } else {
                            fuelEfficiency =item.totalFuelConsumed? (montelyObdData[i].totalDistanceTravelled / item.totalFuelConsumed).toFixed(2) + "Kmpl":"-";
                        }
    
                        let temp = {
                            month: moment(range[0]).format("MMM"),
                            licencePlateNo: vehicles[j].vehicle.vehicleNumber,
                            make: vehicles[j].vehicle.make,
                            model: vehicles[j].vehicle.model,
                            totalDistanceTravelled: serviceDetails.region.includes("us") ? convertValue(montelyObdData[i].totalDistanceTravelled, "distance", "FPS", "miles", true).toFixed(2) : convertValue(montelyObdData[i].totalDistanceTravelled, "distance", "MKS", "Km", true).toFixed(2),
                            totalrunhours: secondsToHm(item.totalrunhours),
                            fuelConsumed: item.totalFuelConsumed.toFixed(2),
                            fuelConsumedCost: item.totalFuelConsumedCost.toFixed(2),
                            fuelAdded: item.totalFuellingAmount.toFixed(2),
                            fuelRemoved: item.totalDefuellingAmount.toFixed(2),
                            fuelEfficiency: fuelEfficiency,
                        };
                        monthelyVehicleData.push(temp);
                    }
                });
                setmonthlyReport(monthelyVehicleData);
                setduplicatemonthlyReport(monthelyVehicleData);
            });
        }
        };
        monthRange && initMonthlyReport(monthRange);
    }, [monthRange]);

    useEffect(() => {
        if (monthPicker) {
            let fromDate = moment(monthPicker).startOf("month").format("YYYY-MM-DD");
            let toDate = moment(monthPicker).endOf("month").format("YYYY-MM-DD");
            let monthRange = [fromDate, toDate];
            setmonthRange(monthRange);
        }
    }, [monthPicker]);

    const handleDateChange = (e) => {
        setDateRange(e.value);
    };

    const onDownload = () => {
        let data = [];
        let filename = "";
        if (serviceDetails.serviceTypeName === "Fuel Tracking") {
             data = origine.map((item)=>({
                 "Date": item.date,
                 "Thing Name": item.thingName,
                 "Site name": item.sitename,
                 "Ignition Start Time": item.ignitionStartTime !== "" ? item.ignitionStartTime : "--",
                 "Ignition End Time": item.ignitionEndTime !== "" ? item.ignitionEndTime : "--",
                 "Total run hours": item.totalrunhours,
                 "Opening fuel (L)": item.openingfuel,
                 "Ending fuel (L)": item.endingfuel,
                 "Fuel Consumed (L)": item.fuelConsumed,
                 "Fuel Consumed Cost": item.fuelConsumedCost,
                 "Fueling Amount (L)": item.fuellingAmount,
                 "Defueling Amount (L)": item.defuellingAmount,
                 "Fuel Efficiency (L/hr)": item.fuelEfficiency ?? "--"                                 ,
                 
            }))
            filename = "Detailed_Fuel_Data.xlsx"
        }
        else {
            if (radioButton === "Summery") {
                if (serviceDetails.region.includes("us")) {
                    data = origine.map((item) => ({
                        date: moment(item.date, "YYYY-MM-DD").format("DD-MMM-YYYY"),
                        "Licence Plate No": item.licencePlateNo,
                        Make: item.make,
                        Model: item.model,
                        "Total Distance Travelled (miles)": convertValue(item.totalDistanceTravelled, "distance", "FPS", "miles", true).toFixed(2),
                        "Total Run Hours": item.totalrunhours,
                        "Stoppage Time": secondsToHm(item.totalStoppageDuration),
                        "Start Time": item.ignitionStartTime,
                        "End Time": item.ignitionEndTime,
                        "Travel Time": secondsToHm(item.totalTravelTime),
                        "Stoppage Points": item.stoppagePoints.length,
                        "Maximum Speed": convertValue(item.maxspeed, "speed", "FPS", "mph"),
                        "Average Speed": convertValue(item.avgspeed, "speed", "FPS", "mph"),
                        "Opening Fuel Level": item.openingfuel,
                        "Ending Fuel Level": item.endingfuel,
                        "Fuel Consumed": item.fuelConsumed,
                        "Fuel Consumed Cost (USD)": item.fuelConsumedCost,
                        "Fuel Added": item.fuellingAmount,
                        "Fuel Removed": item.defuellingAmount,
                        "Fuel Efficiency": item.fuelEfficiency,
                        "HMR / Odometer": item.hmr,
                    }));
                } else {
                    data = origine.map((item) => ({
                        date: moment(item.date, "YYYY-MM-DD").format("DD-MMM-YYYY"),
                        "Licence Plate No": item.licencePlateNo,
                        Make: item.make,
                        Model: item.model,
                        "Total Distance Travelled (Km)": convertValue(item.totalDistanceTravelled, "distance", "MKS", "Km", true).toFixed(2),
                        "Total Run Hours": item.totalrunhours,
                        "Stoppage Time": secondsToHm(item.totalStoppageDuration),
                        "Start Time": item.ignitionStartTime,
                        "End Time": item.ignitionEndTime,
                        "Travel Time": secondsToHm(item.totalTravelTime),
                        "Stoppage Points": item.stoppagePoints.length,
                        "Maximum Speed": convertValue(item.maxspeed, "speed", "MKS", "kmps"),
                        "Average Speed": convertValue(item.avgspeed, "speed", "MKS", "kmps"),
                        "Opening Fuel Level": item.openingfuel,
                        "Ending Fuel Level": item.endingfuel,
                        "Fuel Consumed": item.fuelConsumed,
                        "Fuel Consumed Cost (INR)": item.fuelConsumedCost,
                        "Fuel Added": item.fuellingAmount,
                        "Fuel Removed": item.defuellingAmount,
                        "Fuel Efficiency": item.fuelEfficiency,
                        "HMR / Odometer": item.hmr,
                    }));
                }

                filename = "Vehicle_Summary.xlsx";
            } else {
                origine.forEach((item) => {
                    let tempObj = {};

                    if (serviceDetails.region.includes("us")) {
                        tempObj = {
                            "Licence Plate No": item.licencePlateNo,
                            "Total Distance Travelled (miles)": convertValue(item.totalDistanceTravelled, "distance", "FPS", "miles", true).toFixed(2),
                            "Total Stoppage Duration": secondsToHm(item.totalStoppageDuration),
                            "Total Travel Time": secondsToHm(item.totalTravelTime),
                            "Maximum Speed": convertValue(item.maxspeed, "speed", "FPS", "mph"),
                            "Average Speed": convertValue(item.avgspeed, "speed", "FPS", "mph"),
                            Make: item.make,
                            Model: item.model,
                        };
                        item.stoppagePoints.forEach((elm) => {
                            let obj = {
                                ...tempObj,
                                Date: moment(elm.ts).format("Do MMM, YYYY HH:mm a"),
                                // "Stoppage Location": elm.address,
                                "Stoppage Duration": secondsToHm(elm.stoppageDuration),
                                "Stoppage Type": elm.type,
                                "Stoppage Location": elm.address,
                            };
                            data.push(obj);
                        });
                    } else {
                        tempObj = {
                            "Licence Plate No": item.licencePlateNo,
                            "Total Distance Travelled (Km)": convertValue(item.totalDistanceTravelled, "distance", "MKS", "Km", true).toFixed(2),
                            "Total Stoppage Duration": secondsToHm(item.totalStoppageDuration),
                            "Total Travel Time": secondsToHm(item.totalTravelTime),
                            "Maximum Speed": convertValue(item.maxspeed, "speed", "MKS", "kmps"),
                            "Average Speed": convertValue(item.avgspeed, "speed", "MKS", "kmps"),
                            Make: item.make,
                            Model: item.model,
                        };
                        item.stoppagePoints.forEach((elm) => {
                            let obj = {
                                ...tempObj,
                                Date: moment(elm.ts).format("Do MMM, YYYY HH:mm a"),
                                // "Stoppage Location": elm.address,
                                "Stoppage Duration": secondsToHm(elm.stoppageDuration),
                                "Stoppage Type": elm.type,
                                "Stoppage Location": elm.address,
                            };
                            data.push(obj);
                        });
                    }
                });
                filename = "Vehicle_Daily_Stoppage_Details.xlsx";
            }
        }

        let ws = XLSX.utils.json_to_sheet(data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, filename);
    };

    const onMonthelyDownload = () => {
        let data = []
        let fileName = ""
        if(serviceDetails.serviceTypeName === "Fuel Tracking"){
            data = monthlyReport.map(item=>( {
                "Month": item.month,
                "Thing Name" : item.thingName,
                "Alias":item.alias,
                "Total run hours": item.totalrunhours ?? "--",
                "Fuel Consumed (L)": item.fuelConsumed,
                "Fuel Consumed Cost": item.fuelConsumedCost,
                "Fuel Added (L)": item.fuelAdded,
                "Fuel Removed (L)": item.fuelRemoved,
                "Fuel Efficiency (L/hr)": item.fuelEfficiency ?? "--"
            }))
            fileName ="Fuel_Monthly_report.xlsx"
        }
        else{
           data = monthlyReport.map((item) => ({
            Month: item.month,
            "Licence Plate No": item.licencePlateNo,
            Make: item.make,
            Model: item.model,
            "Total Distance Travelled (Km)": item.totalDistanceTravelled,
            "Total Run Hours": item.totalrunhours,
            "Fuel Consumed (L)": item.fuelConsumed,
            "Fuel Consumed Cost(INR)": item.fuelConsumedCost,
            "Fuel Added (L)": item.fuelAdded,
            "Fuel Removed (L)": item.fuelRemoved,
            "Fuel Efficiency": item.fuelEfficiency,
        }));
        fileName = "Vehicle_Monthly_report.xlsx"
    }
        let ws = XLSX.utils.json_to_sheet(data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, fileName);
    };

    const tableHeader = (
        <ColumnGroup>
            <Row>
                <Column header="Date" className="bg-primary"></Column>
                <Column header="Licence Plate No." className="bg-primary"></Column>
                <Column header="Make" className="bg-primary"></Column>
                <Column header="Model" className="bg-primary"></Column>
                <Column header="Distance Travelled" className="bg-primary"></Column>
                <Column header="Total Run Hours" className="bg-primary"></Column>
                <Column header="Stoppage Time" className="bg-primary"></Column>
                <Column header="Travel Time" className="bg-primary"></Column>
                <Column header="Stoppage Points" className="bg-primary"></Column>
                <Column header="Avg Speed" className="bg-primary"></Column>
                <Column header="Max Speed" className="bg-primary"></Column>
            </Row>
        </ColumnGroup>
    );

    const tableHeader2 = (
        <ColumnGroup>
            <Row>
                <Column header="Date" className="bg-primary"></Column>
                <Column header="Licence Plate No." className="bg-primary"></Column>
                <Column header="Make" className="bg-primary"></Column>
                <Column header="Model" className="bg-primary"></Column>
                <Column header="Distance Travelled" className="bg-primary"></Column>
                <Column header="Total Run Hours" className="bg-primary"></Column>
                <Column header="Total Idle Time" className="bg-primary"></Column>
                <Column header="Stoppage Time" className="bg-primary"></Column>
                <Column header="Start Time" className="bg-primary"></Column>
                <Column header="End Time" className="bg-primary"></Column>
                <Column header="Travel Time" className="bg-primary"></Column>
                <Column header="Stoppage Points" className="bg-primary"></Column>
                <Column header="Avg Speed" className="bg-primary"></Column>
                <Column header="Max Speed" className="bg-primary"></Column>
                <Column header="Opening Fuel Level(L)" className="bg-primary"></Column>
                <Column header="Ending Fuel Level(L)" className="bg-primary"></Column>
                <Column header="Fuel Consumed(L)" className="bg-primary"></Column>
                <Column header="Fuel Consumed Cost(INR)" className="bg-primary"></Column>
                <Column header="Fuel Added" className="bg-primary"></Column>
                <Column header="Fuel Removed" className="bg-primary"></Column>
                <Column header="Fuel Efficiency" className="bg-primary"></Column>
                <Column header="HMR / Odometer" className="bg-primary"></Column>
            </Row>
        </ColumnGroup>
    );

    const dateTemplate = (item) => {
        if (serviceDetails.region.includes("us")) {
            return moment(item.date, "YYYY-MM-DD").format("MMM DD");
        } else {
            return moment(item.date, "YYYY-MM-DD").format("Do MMM");
        }
    };

    const distanceTravelled = (item) => {
        if (serviceDetails.region.includes("us")) {
            return convertValue(item.totalDistanceTravelled, "distance", "FPS");
        } else {
            return convertValue(item.totalDistanceTravelled, "distance", "MKS");
        }
    };
    const stoppageTime = (item) => {
        return secondsToHm(item.totalStoppageTime);
    };
    const idleTime = (item) => {
        return secondsToHm(item.totalidletime);
    };
    const totalTravelTime = (item) => {
        return secondsToHm(item.totalTravelTime);
    };
    const avgSpeed = (item) => {
        if (serviceDetails.region.includes("us")) {
            return convertValue(item.speedavg, "speed", "FPS", "ft/sec");
        } else {
            return convertValue(item.speedavg, "speed", "MKS", "kmph");
        }
    };
    const maxSpeed = (item) => {
        if (serviceDetails.region.includes("us")) {
            return convertValue(item.speedmax, "speed", "FPS", "ft/sec");
        } else {
            return convertValue(item.speedmax, "speed", "MKS", "kmph");
        }
    };

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
        // const filterValue = data && data.filter((item) => item.licencePlateNo === e.target.value);
        if (searchValue !== "") {
            const filteredData = duplicateData.filter((item) => {
                return Object.values(item).join("").toLowerCase().includes(searchValue.toLowerCase());
            });
            setData(filteredData);
        } else {
            setData(data);
        }
    };

    const handleMonthlySearch = (e) => {
        if (e.target.value!== "") {
            const filteredData = duplicatemonthlyReport.filter((item) => {
                return Object.values(item).join("").toLowerCase().includes(e.target.value.toLowerCase());
            });
            setmonthlyReport(filteredData);
        } else {
            setmonthlyReport(data);
        }
    };

    //Monthly Report .............................

    const monthlyTableHeader = (
        <ColumnGroup>
            <Row>
                <Column header="Month" className="bg-primary"></Column>
                <Column header="Licence Plate No." className="bg-primary"></Column>
                <Column header="Make" className="bg-primary"></Column>
                <Column header="Model" className="bg-primary"></Column>
                <Column header="Total Distance Travelled (Km)" className="bg-primary"></Column>
                <Column header="Total Run hours" className="bg-primary"></Column>
                <Column header="Fuel Consumed(L)" className="bg-primary"></Column>
                <Column header="Fuel Consumed Cost(INR)" className="bg-primary"></Column>
                <Column header="Fuel Added(L)" className="bg-primary"></Column>
                <Column header="Fuel Removed(L)" className="bg-primary"></Column>
                <Column header="Fuel Efficiency" className="bg-primary"></Column>
            </Row>
        </ColumnGroup>
    );

    const fuelMonthlyTableHeader = (
        <ColumnGroup>
            <Row>
                <Column header="Month" className="bg-primary"></Column>
                <Column header= "Thing name" className="bg-primary"></Column>
                <Column header= "Alias" className="bg-primary"></Column>
                <Column header="Total Run hours" className="bg-primary"></Column>
                <Column header="Fuel Consumed(L)" className="bg-primary"></Column>
                <Column header="Fuel Consumed Cost(INR)" className="bg-primary"></Column>
                <Column header="Fuel Added(L)" className="bg-primary"></Column>
                <Column header="Fuel Removed(L)" className="bg-primary"></Column>
                <Column header="Fuel Efficiency(L/hr)" className="bg-primary"></Column>
            </Row>
        </ColumnGroup>
    );
    const fuelDailyTableHeader = (
        <ColumnGroup>
            <Row>
                <Column header="Date" className="bg-primary"></Column>
                <Column header="Thing Name" className="bg-primary"></Column>
                <Column header="Site Name" className="bg-primary"></Column>
                <Column header="Total Run Hours" className="bg-primary"></Column>
                <Column header="Start Time" className="bg-primary"></Column>
                <Column header="End Time" className="bg-primary"></Column>
                <Column header="Opening Fuel Level(L)" className="bg-primary"></Column>
                <Column header="Ending Fuel Level(L)" className="bg-primary"></Column>
                <Column header="Fuel Consumed(L)" className="bg-primary"></Column>
                <Column header="Fuel Consumed Cost(INR)" className="bg-primary"></Column>
                <Column header="Fuel Added" className="bg-primary"></Column>
                <Column header="Fuel Removed" className="bg-primary"></Column>
                <Column header="Fuel Efficiency(L/hr)" className="bg-primary"></Column>
            </Row>
        </ColumnGroup>
    );

    return (serviceDetails.serviceTypeName === "Vehicle tracking with Fuel" || serviceDetails.serviceTypeName === "Fuel Tracking") ? (
        <div className="grid">
            <div className="col-12">
                <h4 className="flex justify-content-center">Detailed Report</h4>
            </div>
            <div className="col-12">
                <TabView>
                    <TabPanel header="Daily Report">
                        <div className="grid">
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12 md:col-6">
                                        <div className="grid">
                                            <div className="col-12 md:col-6 p-input-icon-right">
                                                <InputText className="bg-white w-full" placeholder="Search" onChange={handleSearch} value={searchValue} />
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <Calendar id="range" value={dateRange} maxDate={new Date(moment().subtract(1, "d").format("MM-DD-YYYY"))} onChange={handleDateChange} selectionMode="range" showIcon readOnlyInput className="bg-white w-full" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="col-12 md:col-9">
                                            <div className="grid">
                                               {serviceDetails.serviceTypeName !== "Fuel Tracking" && <> <div className="col-3 field-radiobutton ">
                                                    <RadioButton inputId="city1" name="city" value="Summery" onChange={(e) => setRadioButton(e.value)} checked={radioButton === "Summery"} />
                                                    <label htmlFor="Summery">Summary</label>
                                                </div>
                                                <div className="col-3 field-radiobutton ">
                                                    <RadioButton inputId="city2" name="city" value="Details" onChange={(e) => setRadioButton(e.value)} checked={radioButton === "Details"} />
                                                    <label htmlFor="Details">Details</label>
                                                </div> </>}
                                                <div className="col-3 field-radiobutton ">
                                                    <Button icon="pi pi-download" className="p-button-rounded " aria-label="Submit" onClick={onDownload} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="datatable-selection-demo mt-4">
                                    <div className="card">
                                        {data ? serviceDetails.serviceTypeName === "Fuel Tracking" ? <>

                                            <DataTable value={data} showGridlines responsiveLayout="scroll" headerColumnGroup={fuelDailyTableHeader} rows={10} paginator>
                                                <Column body={dateTemplate} bodyClassName="white-space-nowrap"></Column>
                                                <Column field="thingName"></Column>
                                                <Column field="sitename"></Column>
                                                <Column field="totalrunhours"></Column>
                                                <Column field="ignitionStartTime" bodyClassName="white-space-nowrap"></Column>
                                                <Column field="ignitionEndTime" bodyClassName="white-space-nowrap"></Column>
                                                <Column field="openingfuel"></Column>
                                                <Column field="endingfuel"></Column>
                                                <Column field="fuelConsumed"></Column>
                                                <Column field="fuelConsumedCost"></Column>
                                                <Column field="fuellingAmount"></Column>
                                                <Column field="defuellingAmount"></Column>
                                                <Column field="fuelEfficiency"></Column>
                                            </DataTable>

                                        </> : (
                                            <DataTable value={data} showGridlines responsiveLayout="scroll" headerColumnGroup={tableHeader2} rows={10} paginator>
                                                <Column body={dateTemplate} bodyClassName="white-space-nowrap"></Column>
                                                <Column field="licencePlateNo" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="make" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="model" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column body={distanceTravelled} bodyClassName="white-space-nowrap"></Column>
                                                <Column field="totalrunhours"></Column>
                                                <Column body={idleTime} bodyClassName="white-space-nowrap"></Column>
                                                <Column body={stoppageTime} bodyClassName="white-space-nowrap"></Column>
                                                <Column field="ignitionStartTime" bodyClassName="white-space-nowrap"></Column>
                                                <Column field="ignitionEndTime" bodyClassName="white-space-nowrap"></Column>
                                                <Column body={totalTravelTime} bodyClassName="white-space-nowrap"></Column>
                                                <Column field="stoppagePoints"></Column>
                                                <Column field="speedavg" body={avgSpeed}></Column>
                                                <Column field="speedmax" body={maxSpeed}></Column>
                                                <Column field="openingfuel"></Column>
                                                <Column field="endingfuel"></Column>
                                                <Column field="fuelConsumed"></Column>
                                                <Column field="fuelConsumedCost"></Column>
                                                <Column field="fuellingAmount"></Column>
                                                <Column field="defuellingAmount"></Column>
                                                <Column field="fuelEfficiency"></Column>
                                                <Column field="hmr"></Column>
                                            </DataTable>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Monthly Report">
                        <div className="grid">
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12 md:col-6">
                                        <div className="grid">
                                            <div className="col-12 md:col-6 p-input-icon-right">
                                                <InputText className="bg-white w-full" placeholder="Search" onChange={(e)=>{
                                                    handleMonthlySearch(e)
                                                }} />
                                            </div>
                                            <div className="col-12 md:col-6">
                                                {/* <Calendar id="range" value={dateRange} onChange={handleDateChange} selectionMode="range" showIcon readOnlyInput className="bg-white w-full" /> */}
                                                <Calendar
                                                    id="monthpicker"
                                                    value={monthPicker}
                                                    onChange={(e) => {
                                                        setMonthPicker(e.value);
                                                    }}
                                                    view="month"
                                                    dateFormat="mm/yy"
                                                    placeholder="Select Month"
                                                    showIcon
                                                    className="bg-white w-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="col-12 md:col-9">
                                            <div className="grid">
                                                <div className="col-3 field-radiobutton ">
                                                    <Button icon="pi pi-download" className="p-button-rounded " aria-label="Submit" onClick={onMonthelyDownload} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="datatable-selection-demo mt-4">
                                    <div className="card">
                                        {data ? serviceDetails.serviceTypeName === "Fuel Tracking" ? <>
                                        <DataTable value={monthlyReport} showGridlines responsiveLayout="scroll" headerColumnGroup={fuelMonthlyTableHeader} rows={10} paginator>
                                                <Column field="month" bodyClassName="white-space-nowrap"></Column>
                                                <Column field="thingName" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="alias" className="first-letter-cap" ></Column>
                                                <Column field="totalrunhours" bodyClassName="white-space-nowrap"></Column>
                                                <Column field="fuelConsumed" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="fuelConsumedCost" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="fuelAdded" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="fuelRemoved" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="fuelEfficiency" bodyClassName="white-space-nowrap"></Column>
                                            </DataTable>
                                            
                                        </> : (
                                            <DataTable value={monthlyReport} showGridlines responsiveLayout="scroll" headerColumnGroup={monthlyTableHeader} rows={10} paginator>
                                                <Column field="month" bodyClassName="white-space-nowrap"></Column>
                                                <Column field="licencePlateNo" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="make" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="model" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="totalDistanceTravelled" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="totalrunhours" bodyClassName="white-space-nowrap"></Column>
                                                <Column field="fuelConsumed" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="fuelConsumedCost" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="fuelAdded" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="fuelRemoved" bodyClassName="white-space-nowrap uppercase"></Column>
                                                <Column field="fuelEfficiency" bodyClassName="white-space-nowrap"></Column>
                                            </DataTable>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    ) : (
        <div className="grid">
            <div className="col-12">
                <h4 className="flex justify-content-center">Detailed Tracking Report</h4>
            </div>
            <div className="col-12">
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="grid">
                            <div className="col-12 md:col-6 p-input-icon-right">
                                <InputText className="bg-white w-full" placeholder="Search" onChange={handleSearch} />
                            </div>
                            <div className="col-12 md:col-6">
                                <Calendar id="range" value={dateRange} maxDate={new Date(moment().subtract(1, "d").format("MM-DD-YYYY"))} onChange={handleDateChange} selectionMode="range" showIcon readOnlyInput className="bg-white w-full" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="col-12 md:col-9">
                            <div className="grid">
                                <div className="col-3 field-radiobutton ">
                                    <RadioButton inputId="city1" name="city" value="Summery" onChange={(e) => setRadioButton(e.value)} checked={radioButton === "Summery"} />
                                    <label htmlFor="Summery">Summary</label>
                                </div>
                                <div className="col-3 field-radiobutton ">
                                    <RadioButton inputId="city2" name="city" value="Details" onChange={(e) => setRadioButton(e.value)} checked={radioButton === "Details"} />
                                    <label htmlFor="Details">Details</label>
                                </div>
                                <div className="col-3 field-radiobutton ">
                                    <Button icon="pi pi-download" className="p-button-rounded " aria-label="Submit" onClick={onDownload} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="datatable-selection-demo mt-4">
                    <div className="card">
                        {data ? (
                            <DataTable value={data} showGridlines responsiveLayout="scroll" headerColumnGroup={tableHeader} rows={10} paginator>
                                <Column body={dateTemplate} bodyClassName="white-space-nowrap"></Column>
                                <Column field="licencePlateNo" bodyClassName="white-space-nowrap uppercase"></Column>
                                <Column field="make" bodyClassName="white-space-nowrap uppercase"></Column>
                                <Column field="model" bodyClassName="white-space-nowrap uppercase"></Column>
                                <Column body={distanceTravelled} bodyClassName="white-space-nowrap"></Column>
                                <Column field="totalrunhours" bodyClassName="white-space-nowrap"></Column>
                                <Column body={stoppageTime} bodyClassName="white-space-nowrap"></Column>
                                <Column body={totalTravelTime} bodyClassName="white-space-nowrap"></Column>
                                <Column field="stoppagePoints"></Column>
                                <Column field="speedavg" body={avgSpeed}></Column>
                                <Column field="speedmax" body={maxSpeed}></Column>
                            </DataTable>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AllVehicles;