import React from 'react'
import { useEffect } from 'react'
import aqiService from './AirQualityService'
import { useHistory } from 'react-router-dom';

const SiteMonitoring = () => {
    const serviceId  = localStorage.getItem("serviceId")
	const history = useHistory();

    useEffect(()=>{
        Promise.all([aqiService.getThingsData(serviceId), aqiService.getThingDetails(serviceId)]).then((res)=>{
            let _tempThingArr = []
            let _thingDetails = res[1];
            res[0].forEach((item) => {
                let index = _thingDetails.findIndex((elm) =>(( elm.spaceName === item.sensorIdentification.itemId) && (item.installationParameters.type === "aqisensor" || item.installationParameters.type === "noiseaqisensor")));
                if (index > -1) {
                    _tempThingArr.push({
                        name: _thingDetails[index].description,
                        code: item.sensorIdentification.itemId,
                    });
                }
            });
            if(_tempThingArr.length){
                history.push("/air-quality")
            }
            else{
                history.push("/noise-report")
            }
        })
        
    },[])

  return (
    <>
    </>
  )
}

export default SiteMonitoring