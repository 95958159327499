import React, { useEffect, useState, useRef } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import tenantService from "../../service/tenant";
import { validateEmail } from "../../utilities/utillFunction";
import { RadioButton } from "primereact/radiobutton";
import { accessTitle } from "../../lib/constants";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { parseResponse, convertCamelCase } from "../../utilities/utillFunction";
import ConfigService from "../../service/configService";
import { Messages } from "primereact/messages";
import { Skeleton } from "primereact/skeleton";
import PhoneInput, { isValidPhoneNumber, getCountryCallingCode, parsePhoneNumber } from "react-phone-number-input";
import service from "../../service/dbService";
import { serviceTypeName, userTypeDropdown } from "../../utilities/constant"
import { tollUserType } from "../../utilities/constant"
import { plazaServices } from "../tollPlaza/plazaReportService";

var citytimerId, timerId;

const InviteUser = (props) => {
    var userData = props.selectedUser;
    const serviceId = localStorage.getItem("serviceId");
    const serviceData = JSON.parse(localStorage.getItem("service"))
    const isTollFS = ["Toll Dashboard", "File Sharing"].find(item => item === serviceData.serviceTypeName)

    const [name, setName] = useState(userData ? userData.user.name : null);
    const [email, setEmail] = useState(userData ? userData.user.email : "");
    const [mob, setMob] = useState(userData ? userData.user.mobile : "");
    const [isEdit] = useState(userData && Object.keys(userData).length ? true : false);
    const [unblock, setUnblock] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [errMsg, setErrorMsg] = useState(null);
    const [image, setImage] = useState(null);
    const [disabledInviteBtn, setDisabledinviteBtn] = useState(false);
    const [radioValue, setRadioValue] = useState(userData && userData.isSiteRestricted ? "Restricted" : null);
    const [selCountry, setSelectedCountry] = useState(userData && userData.country ? userData.country : null);
    const [permissions, setpermissions] = useState(new Set());
    const [accessTitles] = useState(JSON.parse(JSON.stringify(accessTitle)));
    const [stateList, setStateList] = useState(null);
    const [selectedStates, setSelectedStates] = useState(userData && userData.states ? userData.states : null);
    const [cities, setCitiesList] = useState(null);
    const [selectedCities, setSelectedCities] = useState(userData && userData.cities ? userData.cities : null);
    const [pincodes, setPincodes] = useState(null);
    const [selectedPincodes, setSelectedPincodes] = useState(userData && userData.zipcodes ? userData.zipcodes : null);
    const [dropDownValue, setDropDownValue] = useState(null);
    const [dropDownData, setDropDownData] = useState([]);
    const [specificRepro, setSpecificRepro] = useState(userData && JSON.stringify(userData.psmUpload) ? userData.psmUpload : null);
    const [plazaList, setPlazaList] = useState([])
    const [lcixReproUser, setLcixReproUser] = useState(null);
    const message = useRef();
    const defcountryCode = {
        "india": "IN",
        "mexico": "MX",
        "canada": "CA",
        "usa": "US"
    }
    let defCode = serviceData.location && serviceData.location.operatingCountry ? defcountryCode[serviceData.location.operatingCountry] : serviceData.customer && serviceData.customer.region ? serviceData.customer.region.toUpperCase() : "IN";
    const showPlaza = () => {
        let filterPayload = {
            filter: {
            },
            projection: { "PlazaCode": 1, "PlazaName": 1, "zip": 1 }
        }
        Promise.all([plazaServices.general(serviceId, filterPayload, "nq-plaza-master")]).then((res) => {
            let temp = [];
            res[0].map((item) => {
                let nametemp = item.PlazaName + "_" + item.PlazaCode
                let tempObj = {
                    name: nametemp, value: item.zip
                }
                temp.push(tempObj)
            })
            setPlazaList(temp);
        })
    }

    useEffect(() => {
        switch (serviceData.serviceTypeName) {
            case "File Sharing":
                let dropDownValues = []
                userTypeDropdown.forEach((item) => {
                    dropDownValues.push({
                        type: serviceData.userAccessKeysDescription ? serviceData.userAccessKeysDescription[item.type] : item.type,
                        value: item.value
                    })
                })
                setDropDownData(dropDownValues)
                break;
            case "Repro":
                service
                    .getQuickSightId(serviceId)
                    .then((result) => {
                        if (userData && userData.quickSightId) {
                            let value = result.find((item) => item.quickSightId === userData.quickSightId)
                            setDropDownValue(value)
                        }
                        setDropDownData(result);
                    }).catch((err) => { console.error(err); });
                break;
            case "Toll Dashboard":
                setDropDownData(tollUserType)
                showPlaza()
                break;
        }
    }, []);

    useEffect(() => {
        if (userData && userData.image) {
            setImageUrl(process.env.REACT_APP_IMAGE_STATIC_URL + "/" + userData.image);
        }

        if (userData && userData.permissions && userData.permissions.length) {
            userData.permissions.forEach((p) => {
                let permsn = accessTitles.findIndex((a) => a.hasOwnProperty(p));
                if (permsn > -1) {
                    accessTitles[permsn][p] = true;
                    accessTitles[permsn].unblock = true;
                }
                permissions.add(p);
            });
            setpermissions(permissions);
        }
    }, []);

    const handleImageUpload = (value) => {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(value);
        fileReader.onload = (event) => {
            setImageUrl(event.target.result);
            setImage(value);
        };
    };

    const manageModuleAccess = (access, type) => {
        let permission = accessTitles.findIndex((a) => a.name === type);
        if (access === "block") {
            accessTitles[permission][type + ".READ"] = false;
            accessTitles[permission].unblock = false;
            accessTitles[permission][type + ".WRITE"] = false;

            permissions.delete(type + ".READ");
            permissions.delete(type + ".WRITE");
        }
        if (access === "read") {
            accessTitles[permission][type + ".READ"] = true;
            accessTitles[permission].unblock = true;
            permissions.add(type + ".READ");
        }
        if (access === "write") {
            permissions.add(type + ".READ");
            permissions.add(type + ".WRITE");
            accessTitles[permission][type + ".READ"] = true;
            accessTitles[permission].unblock = true;
            accessTitles[permission][type + ".WRITE"] = true;
        }

        if (unblock) setUnblock(false);
        else setUnblock(true);
    };

    const onInviteUser = () => {
        let res = validateInput();
        if (typeof res === "string") {
            return setErrorMsg(res);
        } else {
            let formData = new FormData();
            formData.append("serviceId", serviceId);
            formData.append("name", name);
            formData.append("email", email);
            formData.append("mobile", mob);
            if (serviceData.serviceTypeName === "International Publisher Service"){
                let typeUser=lcixReproUser==="Repro User"?true:false;
                formData.append("typeUser", typeUser);
            }
            let countryCode
            if (mob) {
                let mobileCountry = parsePhoneNumber(mob)
                if (mobileCountry) {
                    countryCode = getCountryCallingCode(mobileCountry.country)
                }
            }
            formData.append("countryCode", "+" + countryCode)
            if (dropDownData.length && dropDownValue && serviceData.serviceTypeName === "Repro") {
                formData.append("quickSightId", dropDownValue.quickSightId);
                formData.append("psmUpload", specificRepro);
            }

            if (image) formData.append("image", image);
            let isSiteRestricted = false;
            if (radioValue) {
                if (radioValue === "All") isSiteRestricted = false;
                else isSiteRestricted = true;
            }
            formData.append("isSiteRestricted", isSiteRestricted);
            let permisssionArray = [...permissions];
            if (dropDownData.length && dropDownValue && serviceData.serviceTypeName === "Toll Dashboard") formData.append("role", dropDownValue);
            formData.append("permissions", JSON.stringify(permisssionArray));

            setDisabledinviteBtn(true);
            if (isEdit) {
                if (selCountry) formData.append("country", selCountry);
                if (selectedStates && selectedStates.length) formData.append("states", JSON.stringify(selectedStates));
                if (selectedCities && selectedCities.length) formData.append("cities", JSON.stringify(selectedCities));
                if (selectedPincodes && selectedPincodes.length) formData.append("zipcodes", JSON.stringify(selectedPincodes));

                if (userData && userData.user && userData.user.cognitoId) formData.append("cognitoId", userData.user.cognitoId);
                tenantService
                    .updateUserPermission(formData)
                    .then((res) => {
                        if (res && res.message) {
                            let msg;
                            if (res.responseCode === 200) {
                                msg = "User updated successfully";
                            }
                            setErrorMsg(res.message);
                            props.onSuccess(false, msg);
                        }
                    })
                    .catch((e) => {
                        setDisabledinviteBtn(false);
                        setErrorMsg(e.message);
                        console.error(e);
                    });
            } else {
                if (selCountry) formData.append("country", selCountry);
                if (selectedStates && selectedStates.length) formData.append("states", JSON.stringify(selectedStates));
                if (selectedCities && selectedCities.length) formData.append("cities", JSON.stringify(selectedCities));
                if (selectedPincodes && selectedPincodes.length) formData.append("zipcodes", JSON.stringify(selectedPincodes));

                tenantService
                    .addUserByCustomer(formData)
                    .then((res) => {
                        if (res && res.message) {
                            let msg;
                            if (res.responseCode === 200) {
                                msg = "User with mobile no " + mob + " added successfully";
                            }
                            setErrorMsg(res.message);
                            props.onSuccess(false, msg);
                        }
                    })
                    .catch((e) => {
                        setDisabledinviteBtn(false);
                        setErrorMsg(e.message);
                    });
            }
        }
    };

    const validateMobile = (mob) => {
        if (mob.length > 6 && mob.length < 16) return true;
        else return false;
    };

    const validateInput = () => {
        if (!name) return "Please enter the name";
        if (!JSON.stringify(specificRepro) && serviceData.serviceTypeName === "Repro") return "Please select the Inventory Alert"
        if (!isEdit && (!email || !validateEmail(email))) return "Please enter the valid email";
        if (!mob || !validateMobile(mob)) return "Please enter the valid mobile number";
        if (!image && !imageUrl) return "Please upload the image";
        if (radioValue && radioValue === "Restricted" && !(selectedStates && selectedStates.length)) return "Please select the restricted area";
        if (!dropDownValue && dropDownData.length) return "Please select all fields";
        if (isEdit && !(userData && userData.user && userData.user.cognitoId)) {
            console.error("CognitoId is not present in the user permissions");
            return "Server Error";
        }
        if (serviceData.serviceTypeName === "International Publisher Service" && !lcixReproUser)return "Please select the type of user";
            return true;
    };

    const onCountryChange = (e) => {
        setSelectedCountry(e.value);
        setStateList([]);
        setSelectedStates(null);
        getStateList(e.value);
    };

    useEffect(() => {
        if (userData && userData.country) {
            setDisabledinviteBtn(true);
            getStateList(userData.country);
        }
    }, []);

    const getStateList = async (country) => {
        if (country) {
            let [error, stateList] = await parseResponse(ConfigService.getStateList({ country: country, serviceId }));
            if (error) {
                message.current.show({ severity: "error", content: "Something wents wrong" });
                return;
            }
            let states = [];
            stateList.forEach((s) => {
                states.push({ name: convertCamelCase(s), value: s });
            });
            setStateList(states);
            if (userData && !(userData.states && userData.states.length)) setDisabledinviteBtn(false);
        }
    };

    const onStateSelection = (e) => {
        setSelectedStates(e.value);
        setCitiesList([]);
        setPincodes([]);
        setSelectedCities(null);
        setSelectedPincodes(null);
    };

    useEffect(() => {
        cityDebounceFunction(getCityList, 2000, selectedStates);
    }, [selectedStates && selectedStates.length]);

    const cityDebounceFunction = (func, delay, params) => {
        clearTimeout(citytimerId);
        citytimerId = setTimeout(() => {
            func(params);
        }, delay);
    };

    const zipcodeDebounceFunction = (func, delay, params) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            func(params);
        }, delay);
    };

    const getCityList = async (states) => {
        if (states && states.length) {
            let [error, cityList] = await parseResponse(ConfigService.getCities({ states: states, serviceId }));
            if (error) {
                message.current.show({ severity: "error", content: "Something wents wrong" });
                return;
            }

            let cities = [];
            cityList.forEach((s) => {
                cities.push({ name: convertCamelCase(s), value: s });
            });

            setCitiesList(cities);
            if (userData && !(userData.cities && userData.cities.length)) setDisabledinviteBtn(false);
        }
    };

    useEffect(() => {
        zipcodeDebounceFunction(getPincodeList, 2000, selectedCities);
    }, [cities && selectedCities && selectedCities.length]);

    const getPincodeList = async (cities) => {
        if (cities && cities.length) {
            let [error, pincideList] = await parseResponse(ConfigService.getPincodes({ cities: cities, serviceId }));
            if (error) {
                message.current.show({ severity: "error", content: "Something wents wrong" });
                return;
            }

            let pincodes = [];
            pincideList.forEach((p) => {
                pincodes.push({ name: p, value: p });
            });
            setPincodes(pincodes);
            setDisabledinviteBtn(false);
        }
    };

    const onCitySelection = (e) => {
        setSelectedCities(e.value);
        setPincodes([]);
        setSelectedPincodes(null);
    };

    const onPincodeSelection = (e) => {
        setSelectedPincodes(e.value);
    };

    const RoleSelector = (role) => {
        useEffect(() => {
            if (role === "superAdmin") {
                setSelectedPincodes([]);
            }
        }, [role]);

        if (role === "Manager" || role === "Users") {
            return (
                <>
                    <label htmlFor="mob" className="block">
                        Select Plaza
                    </label>
                    <Dropdown
                        value={selectedPincodes}
                        onChange={(e) => {
                            setSelectedPincodes(e.value);
                        }}
                        options={plazaList}
                        optionLabel="name"
                        placeholder="Select Plaza"
                        maxSelectedLabels={3}
                        className="w-full"
                    />
                </>
            );
        }

        if (role === "Revenue Manager") {
            return (
                <>
                    <label htmlFor="mob" className="block">
                        Select Plaza
                    </label>
                    <MultiSelect
                        value={selectedPincodes}
                        onChange={(e) => {
                            setSelectedPincodes(e.value);
                        }}
                        options={plazaList}
                        optionLabel="name"
                        placeholder="Select Plaza"
                        maxSelectedLabels={3}
                        className="w-full"
                    />
                </>
            );
        }

        return null;
    };
    return (
        <div className="grid">
            <div className="col-12  ">
                <label htmlFor="invite-image" style={{ cursor: "pointer" }}>
                    {!imageUrl ? (
                        <div style={{ textAlign: "center" }}>
                            <img height="40px" width="40px" alt="upload" src={"/images/upload.svg"} required />
                            <div>Upload Image</div>
                        </div>
                    ) : (
                        <div style={{ textAlign: "center" }}>
                            <img height="100px" alt="change" width="100px" src={imageUrl} />
                            <div>Change Image</div>
                        </div>
                    )}
                </label>
                <input style={{ display: "none" }} id="invite-image" type="file" accept="image/jpg, image/jpeg, image/png" onChange={(e) => handleImageUpload(e.target.files[0])} />
            </div>
            <div className="col-12">
                <label htmlFor="name" className="block">
                    Name
                </label>
                <InputText type="text" name="name" id="name" className="w-full" defaultValue={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="col-6">
                <label htmlFor="email" className="block">
                    Email
                </label>
                <InputText type="email" name="email" id="email" className="w-full" disabled={isEdit ? true : false} defaultValue={email} onChange={(e) => setEmail(e.target.value)} />
            </div>

            <div className="col-6">
                <label htmlFor="mob" className="block">
                    Mobile
                </label>
                <PhoneInput placeholder="Enter phone number" className="p-inputtext p-component p-filled w-full" value={mob} onChange={setMob} international defaultCountry={defCode} error={mob ? (isValidPhoneNumber(mob) ? undefined : "Invalid phone number") : "Phone number required"} />
                {/* <InputText type="text" name="mob" id="mob" className="w-full" defaultValue={mob} onChange={(e) => setMob(e.target.value)} /> */}
            </div>
            {dropDownData.length ?
                isTollFS ? (<div className="col-12">
                    <label htmlFor="userType">Select User Role</label>
                    <Dropdown id="userType" value={dropDownValue} className="w-full" required onChange={(e) => {
                        setPincodes(null)
                        setDropDownValue(e.value)
                    }} options={dropDownData} optionLabel="type" placeholder="Select" />{" "}
                </div>) :
                    (<div className="col-12">
                        <label htmlFor="qsid">Select Quicksight id</label>
                        <Dropdown id="qsid" value={dropDownValue} defaultValue={dropDownValue} className="w-full" required onChange={(e) => setDropDownValue(e.value)} options={dropDownData} optionLabel="segment" placeholder="Select" />{" "}
                        {serviceData.serviceTypeName === "Repro" && <div className="  flex-col my-3">
                            <label> Inventory Alert</label>
                            <div className="flex my-2">

                                <RadioButton inputId="ingredient1" name="select" onChange={(e) => setSpecificRepro(true)} checked={specificRepro === true} />
                                <label htmlFor="ingredient1" className="ml-1 mr-4">Yes</label>

                                <RadioButton className="ml-5" inputId="ingredient2" name="select" onChange={(e) => setSpecificRepro(false)} checked={specificRepro === false} />
                                <label htmlFor="ingredient2" className="ml-1">No</label>

                            </div>
                        </div>}
                    </div>)
                : null}
            {serviceData.serviceTypeName === "Toll Dashboard" && <div className="col-12">
                {RoleSelector(dropDownValue)}
            </div>}
            <div className="col-12 ">
                {serviceData.serviceTypeName === "International Publisher Service" ? <div className="field-radiobutton ">
                    <RadioButton inputId="option2" name="option" value="Repro User" checked={lcixReproUser === "Repro User"} onChange={(e) => setLcixReproUser(e.value)} />
                    <label htmlFor="option2 " className="m-0 mr-3 ml-1">Repro User</label>
                    <RadioButton inputId="option3" name="option" value="Lcix User" checked={lcixReproUser === "Lcix User"} onChange={(e) => setLcixReproUser(e.value)} />
                    <label htmlFor="option3" className="m-0 ml-1">Lcix User</label>
                </div>
                    : null}
            </div>

            <div className="col-12">
                <TabView className="tabSize">
                    <TabPanel header="Module Access">
                        <div>
                            <h5>Module Name</h5>
                            {accessTitles.map((a, i) => {
                                return (
                                    <div key={i} className="flex col-12" style={{ fontSize: "16px", padding: 0 }}>
                                        <div className="col-7">{a.title}</div>
                                        <div className="col-5" style={{ float: "right", padding: 0 }}>
                                            <Button icon="pi pi-lock" className="p-button-rounded mr-2 mb-2" style={{ background: a.unblock ? "#d3d3d3" : "red" }} onClick={() => manageModuleAccess("block", a.name)} />
                                            <Button icon="pi pi-eye" className="p-button-rounded mr-2 mb-2" style={{ background: a[a.name + ".READ"] ? "blue" : "#d3d3d3" }} onClick={() => manageModuleAccess("read", a.name)} />
                                            <Button icon="pi pi-pencil" className="p-button-rounded mr-2 mb-2" style={{ background: a[a.name + ".WRITE"] ? "green" : "#d3d3d3" }} onClick={() => manageModuleAccess("write", a.name)} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </TabPanel>

                    {serviceData.serviceTypeName !== "Toll Dashboard" && <TabPanel header="Location Access">
                        <div className="grid">
                            <div className="col-12">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option1" name="option" value="All" checked={radioValue === "All"} onChange={(e) => setRadioValue(e.value)} />
                                    <label htmlFor="option1">All</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option2" name="option" value="Restricted" checked={radioValue === "Restricted"} onChange={(e) => setRadioValue(e.value)} />
                                    <label htmlFor="option2">Restricted</label>
                                </div>
                            </div>
                            {radioValue === "Restricted" && props.countryList && props.countryList.length ? (
                                <div className="col-12">
                                    <Dropdown className="col-12 w-full" value={selCountry} options={props.countryList} onChange={onCountryChange} optionLabel="name" placeholder="Select Country" />
                                </div>
                            ) : null}

                            {selCountry ? (
                                <div className="col-12">
                                    {stateList && stateList.length ? <MultiSelect className="col-12" value={selectedStates} onChange={(e) => onStateSelection(e)} options={stateList} optionLabel="name" placeholder="Select States" filter /> : <Skeleton height="50px" className="mb-2"></Skeleton>}
                                </div>
                            ) : null}

                            {selectedStates && selectedStates.length ? (
                                <div className="col-12">
                                    {cities && cities.length ? <MultiSelect className="col-12" value={selectedCities} onChange={(e) => onCitySelection(e)} options={cities} optionLabel="name" placeholder="Select City" filter /> : <Skeleton height="50px" className="mb-2"></Skeleton>}
                                </div>
                            ) : null}
                            {selectedCities && selectedCities.length ? (
                                <div className="col-12">
                                    {pincodes && pincodes.length ? <MultiSelect className="col-12" value={selectedPincodes} onChange={(e) => onPincodeSelection(e)} options={pincodes} optionLabel="name" placeholder="Select Zip Codes" filter /> : <Skeleton height="50px" className="mb-2"></Skeleton>}
                                </div>
                            ) : null}
                        </div>
                    </TabPanel>}
                </TabView>
            </div>
            <div className="col-12">
                <Messages ref={message} />
            </div>
            <div className="col-12">
                {errMsg ? (
                    <label className="errorstyle" style={{ color: "red" }}>
                        {errMsg}
                    </label>
                ) : null}
            </div>
            

            <div className="col-12">
                <Button className="col-12" disabled={disabledInviteBtn} style={{ textAlign: "center", padding: "1rem" }} onClick={() => onInviteUser()}>
                    {isEdit ? "Update User" : "Invite user"}
                </Button>
            </div>
        </div>
    );
};

export default InviteUser;