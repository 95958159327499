
import React, { Component } from "react";
export default class CustomReportComponent extends Component {
    render(){
        return(
            <div>
                <iframe src={`${encodeURI( process.env.REACT_APP_IMAGE_STATIC_URL + "/" + this.props.site.customReport)}#view=fitH`} style={{height:"80vh", width: "100%"}} />
            </div>
        )
    }
}