import React, { Component } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import Report from "./components/Report";
import Dashboard from "./components/Dashboard";
import { WaterQualityService } from "../../service/WaterQualityService";


class WaterQuality extends Component {
    constructor(props) {
        super(props);
        this.state = {
            things: null,
        };
        this.waterQualityService = new WaterQualityService();
        this.serviceID = localStorage.getItem('serviceId');
    }

    componentDidMount = () => {
        this.waterQualityService.getThings(this.serviceID).then((res) => {
            let things = [];
            res.responseData.forEach((item) => {
                things.push({ name: item, code: item });
            });
            this.setState({ things });
        });
    };

    render = () =>
        this.state.things ? (
            <TabView>
                <TabPanel header="Live" className="px-3">
                    <Report things={this.state.things} />
                </TabPanel>
                <TabPanel header="History" className="px-3">
                    <Dashboard things={this.state.things} />
                </TabPanel>
            </TabView>
        ) : null;
}

export default WaterQuality;
