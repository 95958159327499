export function convertValue(value, type, outputMetrix, outputUnit, onlyValue, onlyUnit, toChange){
    var res = "";
    if(outputMetrix === "MKS"){
        switch(type){
            case "height":
            case "width":
            case "length":
            case "breadth": 
                res = onlyUnit ? " m" : value > 0 ?  (onlyValue ? value : (parseFloat(value).toFixed(1)  + " m")) : value;
                break;
            case "distance":
                res = onlyUnit ? " km" : value > 0 ?  (onlyValue ? (toChange ?  parseFloat((value*1.6).toFixed(1)) : value ) : (parseFloat(value).toFixed(1)  + " km")) : value;
                break;
            case "weight":
                res = onlyUnit ? " kg" : value > 0 ?  (onlyValue ? value : (parseFloat(value).toFixed(1)  + " kg")) : value;
                break;
            case "temperature":
                res = onlyUnit ? " \xB0C." :  onlyValue ? toChange ? parseFloat(((value - 32) * 5 / 9).toFixed(1)) : value.toFixed(1) : (parseFloat(value).toFixed(1)  + " \xB0C");
                break;
            case "area" :
                res = onlyUnit ? " Sq m" : value > 0 ?  (onlyValue ? value : (parseFloat(value).toFixed(1)  + " Sq m")) : value;
                break;
            case "precipitation":
                res = onlyUnit ? " mm" : value > 0 ?  (onlyValue ? (toChange ?  parseFloat((value*25.4).toFixed(1)) : value ) : (parseFloat(value).toFixed(1)  + " mm")) : value;
                break; 
            case "speed": 
                if(outputUnit === "m/sec") res = onlyUnit ? " m/sec" : value > 0 ?  (onlyValue ? value : (parseFloat(value).toFixed(1)  + " m/sec")) : value;
                else res = onlyUnit ? " kmph" : value > 0 ?  (onlyValue ? toChange ? parseFloat((value*1.60934).toFixed(1)) : value : (parseFloat(value).toFixed(1)  + " kmph")) : value;
                break;
            case "humidity":
                res = onlyUnit ? " %" : value > 0 ?  (onlyValue ? value : (parseFloat(value).toFixed(1)  + " %")) : value;
                break;
            case "snow":
                res = onlyUnit ? " cm" : value > 0 ?  (onlyValue ? (toChange ?  parseFloat((value*2.54).toFixed(1)) : value ) : (parseFloat(value).toFixed(1)  + " cm")) : value;
                break; 
            default: 
                res = value;
                break;
        }
    }else if(outputMetrix === "FPS" ) {
        switch(type){
            case "height":
            case "width":
            case "length":
            case "breadth": 
                res = onlyUnit ? " ft" : value > 0 ?  (onlyValue ? (value * 3.281) : ((value * 3.281).toFixed(1)  + " ft")) : value;
                break;
            case "distance":
                res = onlyUnit ? " miles" : value > 0 ?  (onlyValue ? (value *  0.621) : ((value *  0.621).toFixed(1)  + " miles")) : value;
                break;
            case "weight":
                res = onlyUnit ? " lbs" : value > 0 ?  (onlyValue ? (value * 2.205) : ((value * 2.205).toFixed(1)  + " lbs")) : value;
                break;
            case "temperature":
                res = onlyUnit ? " \xB0F" : onlyValue ? ((value * 9/5) +32).toFixed(1) : (((value * 9/5) +32).toFixed(1) + " \xB0F");
                break;
            case "area" :
                res = onlyUnit ? " Sq ft" : value > 0 ?  (onlyValue ? (value * 10.764) : ((value * 10.764).toFixed(1)  + " Sq ft")) : value;
                break;
            case "precipitation":
                res = onlyUnit ? " inches" : value > 0 ?  (onlyValue ? (value / 25.4) : ((value / 25.4).toFixed(1)  + " inches")) : value;
                break;    
            case "speed": 
                if(outputUnit === "feet/sec") res = onlyUnit ? " ft/sec" : value > 0 ?  (onlyValue ? (value * 3.281) : ((value * 3.281).toFixed(1)  + " ft/sec")) : value;
                else res = onlyUnit ? " mph" : value > 0 ?  (onlyValue ? (value * 0.621) : ((value * 0.621).toFixed(1)  + " mph")) : value;
                break;
            case "humidity":
                res = onlyUnit ? " %" : value > 0 ?  (onlyValue ? value : (parseFloat(value).toFixed(1)  + " %")) : value;
                break;
            case "snow":
                res = onlyUnit ? " inches" : value > 0 ?  (onlyValue ? (value/2.54) : (parseFloat(value/2.54).toFixed(1)  + " cm")) : value;
                break; 
            default: 
                res = value;
                break;
        }
    }
    return res;
}