import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { Panel } from "primereact/panel";
import { Employee } from "../../service/Employee";
import EmployeeReport from "./EmployeeReport";
import * as XLSX from "xlsx";
import { secondsToHm } from "../../utilities/utillFunction";
import { convertValue } from "../../utilities/unitConversion";
import { Message } from 'primereact/message';
import { Ripple } from "primereact/ripple";

let filterObj = {}
function EmployeeList() {
    const [empData, setEmpData] = useState([]);
    const [empDataDuplicate, setEmpDataDuplicate] = useState([]);
    const [rowSelect, setRowSelect] = useState(null);
    const [search, setSearch] = useState("");
    const [dateRange, setDateRange] = useState(null);
    const [radioButton, setRadioButton] = useState("Summery");
    const [country, setCountry] = useState([]);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [circle, setCircle] = useState(null);
    const [department, setDepartment] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedCircle, setSelectedCircle] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [isShowReport, setIsShowReport] = useState(false);
    const [reportData, setReportData] = useState(null);
    const [empMasterData, setMasterData] = useState(null);
    const [missingData, setMissingData] = useState(null);
    const [notRecived, setNotRecived] = useState([]);
    const [empTicketsReachedData, setEmpTicketsReached] = useState([])
    const serviceDetails = JSON.parse(localStorage.getItem("service"));
    const serviceID = localStorage.getItem("serviceId");
    const employee = new Employee();
    const startDate = moment().subtract(1, "d").format("MM/DD/YYYY");
    const endDate = moment().subtract(1, "d").format("MM/DD/YYYY");

    useEffect(() => {
        let dateRange = [new Date(startDate), new Date(endDate)];
        setDateRange(dateRange);
    }, []);
    const handleFilter = (ftype, value) => {
        if(ftype !== "search") filterObj[ftype] = value.code
        else filterObj[ftype] = value

        if (ftype === 'search') setSearch(value);
        if (ftype === 'country') setSelectedCountry(value)
        if (ftype === 'state') setSelectedState(value)
        if (ftype === 'city') setSelectedCity(value)
        if (ftype === 'circle') setSelectedCircle(value)
        if (ftype === 'department') setSelectedDepartment(value)
        let filterArr = [], search;
        Object.keys(filterObj).forEach(f => {
            if(f === 'search') search = filterObj[f]
            else filterArr.push([f, filterObj[f]])
        })
        let resp = filterItems(empDataDuplicate, filterArr, search)
        setEmpData(resp)
    }

    const filterItems = (data, filters, search) => {
        return data.filter(function (emp) {
            for (var i = 0; i < filters.length; i++) {
                if ((emp[filters[i][0]] !== filters[i][1])) return false;
            }
            if (search) {
                let srch = search.toLowerCase()
                if (emp.name.toLowerCase().includes(srch) || emp.manager.includes(srch) ||emp.emailID.includes(srch) || emp.employeeID.includes(srch) || emp.workGroup === srch || emp.shift === srch) return true
                else return false
            }
            return true;
        })
    }

    const template = (options) => {
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";

        return (
            <div className={className}>
                <span className={titleClassName}>
                    <i className="pi-pw pi pi-filter"></i> Filter
                </span>
                <Message style={{background: "none"}} text="Search filter applied on name, employee Id, emailID, work group, and shift" />
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        );
    };

    const getStartTimeFormat = (value) => {
        return serviceDetails.region === "us-east-1" || serviceDetails.region === "us" || serviceDetails.region === "US" ? moment(value.startTime).format("hh:mm a") : moment(value.startTime).format("hh:mm a");
    };
    const getEndTimeFormat = (value) => {
        return serviceDetails.region === "us-east-1" || serviceDetails.region === "us" || serviceDetails.region === "US" ? moment(value.endTime).format("hh:mm a") : moment(value.endTime).format("hh:mm a");
    };
    const totalTravelTime = (value) => {
        return secondsToHm(value.totalTravelTime);
    };
    const distanceTravelled = (value) => {
        let distance = parseFloat(value.distanceTravelled);
        if (serviceDetails.region === "us-east-1" || serviceDetails.region === "us" || serviceDetails.region === "US") {
            return convertValue(distance, "distance", "FPS");
        } else {
            return convertValue(distance, "distance", "MKS");
        }
    };

    const onEmployeeSelect = (e) => {
        if(e.originalEvent.target.parentNode.id === "tickets_travelled") return
        setRowSelect(e.value);
        let NotRecived=missingData[e.value.thingName];
        if(NotRecived && NotRecived.timeStamp ){
            let netRecived = NotRecived.timeStamp.map((item) => {
                if (e.value.startTime <= item[0] && e.value.endTime>=item[1]) {
                    return {
                        "start_ts": item[0],
                        "end_ts": item[1],
                        "session_sec":secondsToHm(item[2]),
                        "reasons": item[3]
                    }
                }
            })
            setNotRecived(netRecived);
        }
        let index = empMasterData.findIndex((elm) => elm.thingName === e.value.thingName && elm.startTime === e.value.startTime);
        if (index > -1) {
            setIsShowReport(true);
            setReportData({ name: e.value.name, ...empMasterData[index] });
        }
        if(e.value.tickets_travelled !== "--" && e.value.tickets_travelled && e.value.tickets_travelled.length){
           getReachedTicketDetails(e.value).then((res => {
            (res && res.length) && res.forEach((item, index) =>{
                if(item.gf_lat && item.gf_lon){
                    res[index].siteLatLong = {"lat": item.gf_lat,"lng": item.gf_lon}  
                }
            })
            setEmpTicketsReached(res)
           }))
        }
        setEmpTicketsReached([])
    };

    const onBack = () => {
        setIsShowReport(false);
        setReportData(null);
        setRowSelect(null);
    };

    const onDownload = () => {
        let data = [];
        if (radioButton === "Summery") {
            data = empData.map((item) => ({
                "Employee Id": item.employeeID,
                Circle: item.circle,
                City: item.city,
                Department: item.department,
                State: item.state,
                Country: item.country,
                "User Email": item.emailID,
                "User Name": item.name,
                "Total Distance Travelled": serviceDetails.region === "us-east-1" || serviceDetails.region === "us" || serviceDetails.region === "US" ? convertValue(item.distanceTravelled, "distance", "FPS") : item.distanceTravelled,
                "Total Travel Time": secondsToHm(item.totalTravelTime),
                "Start Time": moment(item.startTime).format("Do MMM, YYYY HH:mm a"),
                "Start Location": item.startLocation.address,
                "End Time": moment(item.endTime).format("Do MMM, YYYY HH:mm a"),
                "End Location": item.endLocation.address,
                "Total Stoppage Duration": secondsToHm(item.totalStoppageDuration),
                "Designated Stoppages": item.designatedStoppage,
                "Undesignated Stoppages": item.undesignatedStoppage,
                "Tickets Travelled" : item.tickets_travelled,
                "Assigned Ticket" : item.assigned_ticket,
                "Closed Ticket" : item.closed_ticket,
                "Employee Tracking Downtime With Reasons":missing(item, "Report")
            }));
        } else {
            empMasterData.forEach((item) => {
                let tempObj = {};
                let index = empDataDuplicate.findIndex((element) => element.thingName === item.thingName);
                if (index > -1) {
                    tempObj = {
                        "Employee Id": empDataDuplicate[index].employeeID,
                        Circle: empDataDuplicate[index].circle,
                        City: empDataDuplicate[index].city,
                        Department: empDataDuplicate[index].department,
                        State: empDataDuplicate[index].state,
                        Country: empDataDuplicate[index].country,
                        "User Email": empDataDuplicate[index].emailID,
                        "User Name": empDataDuplicate[index].name,
                        "Stoppage Number": item.stoppagePoints.length,
                        "Employee Tracking Downtime With Reasons": missing(item, "Report")
                    };
                }
                item.stoppagePoints.forEach((elm) => {
                    let obj = {
                        ...tempObj,
                        Date: moment(elm.start_time).format("Do MMM, YYYY"),
                        "Stoppage Location": elm.address,
                        "Stoppage Duration": secondsToHm(elm.stoppageDuration),
                        "Stoppage Type": elm.type,
                        "Latitude": elm.lat,
                        "Longitude": elm.lng
                    };
                    data.push(obj);
                });
            });
        }
        let filename = "workforce_location_traking_" + radioButton + ".xlsx";
        let ws = XLSX.utils.json_to_sheet(data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, filename);
    };

    const initReport = (formDate, toDate) => {
        let thingEmpIdMap = {},
            employeeDetails = [];

        employee.getAllEmployee(serviceID).then((res) => {
            if (res.responseData && res.responseData.length)
                res.responseData.forEach((item) => {
                    if (item !== null) {
                        thingEmpIdMap[item.thingName] = item
                        employeeDetails.push(item);
                    }
                });
            let payload = {
                filter: {
                    thingName: {
                        $in: Object.keys(thingEmpIdMap),
                    },
                    startTime: {
                        $gte: formDate,
                        $lte: toDate,
                    },
                }
            };
            employee.getLocationData(serviceID, payload)
                .then((res) => {
                    let empIds = []
                    let employeeLocationData = res;
                    let employeeData = [];
                    employeeLocationData && employeeLocationData.forEach((item) => {
                        if (item.thingName in thingEmpIdMap) {
                            empIds.push(thingEmpIdMap[item.thingName].empId)
                        }
                    })
                    // get all time assigned tickets count 
                    let payload2 = {
                        operation: "aggregate",
                        aggregate: [{ $match: { TechnicianID: { $in: empIds }, StatusName: { $nin: ["Closed"] } } }
                            , {
                            $group: {
                                _id: "$TechnicianID",
                                assignedCount: { $sum: 1 }
                            }
                        }
                        ]
                    }
                    //collection : nq-tickets-technicianwise / to get the today closed ticket count
                    let date1 = moment(formDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
                    let date2 = moment(toDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
                    let payload3 = {
                        filter: { TechnicianID: { $in: empIds }, date: { $gte: date1, $lte: date2 } },
                        projection: { noofticketsclosedtoday: 1, TechnicianID: 1, date: 1, _id: 0 }
                    }
                    //get ticketno of tickets travelled on a date
                    let payload4 = {
                        operation: "aggregate",
                        aggregate: [{
                            $match: {
                                TechnicianID: { $in: empIds },
                                date: { $gte: date1, $lte: date2 }
                            },
                        }, {
                            $addFields: { TicketNo: "$visits.TicketNo" }
                        },
                        {
                            $project: {
                                TechnicianID: 1, date: 1, facId: "$visits.FACID",
                                TicketNo: {
                                    $reduce: {
                                        input: "$TicketNo", // Specify the field to process
                                        initialValue: [], // Initialize an empty array
                                        in: { $concatArrays: ["$$value", "$$this"] } // Concatenate arrays
                                    }
                                }
                            }
                        }]
                    }

                    let payload5 = {
                        filter: {
                            $and: [
                                {
                                    "end_ts": {
                                        $lte: toDate
                                    }
                                },
                                {
                                    "start_ts": {
                                        $gte: formDate
                                    }
                                },
                                {
                                    thingName: {
                                        $in: Object.keys(thingEmpIdMap),
                                    },
                                }
                            ]
                        },
                        projection: {
                            "date": 1, "thingName": 1, "reasons": 1, "session_sec": 1, "start_ts": 1, "end_ts": 1
                        }
                    }

                    Promise.all([employee.getAssignedTicketTillDate(serviceID, payload2), employee.getTodayClosedTicket(serviceID, payload3), employee.getTicketsTravelled(serviceID, payload4), employee.getMissingSessions(serviceID, payload5)]).then((res) => {
                        let missingDataMap = {};
                        res[3].map((item) => {
                            if (item.reasons.length > 0) {
                                let checkObj = {
                                    send: []
                                };
                                for (let i = 0; i < item.reasons.length; i++) {
                                    if (checkObj[item.reasons[i]] === undefined) {
                                        checkObj.send.push(item.reasons[i]);
                                        checkObj[item.reasons[i]] = 1;
                                    }
                                }
                                if (missingDataMap[item.thingName] !== undefined) {
                                    let notRecivedTime = [...missingDataMap[item.thingName].timeStamp, [item.start_ts, item.end_ts, item.session_sec, checkObj.send, item.date]];
                                    missingDataMap[item.thingName] = {
                                        ...missingDataMap[item.thingName],
                                        timeStamp: notRecivedTime,
                                    }
                                }
                                else {
                                    missingDataMap[item.thingName] = {
                                        timeStamp: [[item.start_ts, item.end_ts, item.session_sec, checkObj.send, item.date]],
                                    }

                                }
                            }
                        })
                        setMissingData(missingDataMap);
                        let assignedTicketMap = {}, closedTicketMap = {}, travelledTicketsMap = {}

                        res[0]?.length && res[0].forEach((item) => {
                            assignedTicketMap[item._id] = item
                        })
                        res[1]?.length && res[1].forEach((item) => {
                            closedTicketMap[item.TechnicianID + "_" + item.date] = item
                        })
                        res[2]?.length && res[2].forEach((item) => {
                            travelledTicketsMap[item.TechnicianID + "_" + item.date] = item
                        })

                        employeeLocationData.forEach((item) => {
                            let designatedStoppage = item.stoppagePoints.filter((elm) => elm.type === "Designated");
                            let undesignatedStoppage = item.stoppagePoints.filter((elm) => elm.type === "Undesignated")
                            let employeeId = thingEmpIdMap[item.thingName].empId
                            employeeData.push({
                                name: thingEmpIdMap[item.thingName].name,
                                thingName: thingEmpIdMap[item.thingName].thingName,
                                employeeID: employeeId,
                                emailID: thingEmpIdMap[item.thingName].email,
                                department: thingEmpIdMap[item.thingName].department,
                                workGroup: thingEmpIdMap[item.thingName].workGroup,
                                shift: thingEmpIdMap[item.thingName].shift,
                                manager: thingEmpIdMap[item.thingName].reportingManagerEmailId,
                                circle: thingEmpIdMap[item.thingName].circle,
                                city: thingEmpIdMap[item.thingName].city,
                                state: thingEmpIdMap[item.thingName].state,
                                country: thingEmpIdMap[item.thingName].country,
                                startTime: item.startTime,
                                endTime: item.endTime,
                                totalTravelTime: item.totalTravelTime,
                                distanceTravelled: (item.totalDistanceTravelled / 1000).toFixed(2),
                                designatedStoppage: designatedStoppage.length,
                                undesignatedStoppage: undesignatedStoppage.length,
                                endLocation: item.endLocation,
                                startLocation: item.startLocation,
                                totalStoppageDuration: item.totalStoppageDuration,
                                date: item.date,
                                tickets_travelled: travelledTicketsMap[employeeId + "_" + item.date] ? travelledTicketsMap[employeeId + "_" + item.date].TicketNo : "--",
                                assigned_ticket: assignedTicketMap[employeeId]?.assignedCount ?? "--",
                                closed_ticket: closedTicketMap[employeeId + "_" + item.date]?.noofticketsclosedtoday ?? "--"
                            });
                        })
                        let country = [];
                        let city = [];
                        let state = [];
                        let circle = [];
                        let department = [];

                        employeeData.forEach((elm) => {
                            if (elm.country && !country.some((a) => a.name === elm.country)) {
                                country.push({ name: elm.country, code: elm.country });
                            }
                            if (elm.city && !city.some((a) => a.name === elm.city)) {
                                city.push({ name: elm.city, code: elm.city });
                            }
                            if (elm.state && !state.some((a) => a.name === elm.state)) {
                                state.push({ name: elm.state, code: elm.state });
                            }
                            if (elm.circle && !circle.some((a) => a.name === elm.circle)) {
                                circle.push({ name: elm.circle, code: elm.circle });
                            }
                            if (elm.department && !department.some((a) => a.name === elm.department)) {
                                department.push({ name: elm.department, code: elm.department });
                            }
                        });

                        let sessionData = employeeData.sort((a, b) => {
                            return new Date(b.startTime) - new Date(a.startTime);
                        });
                        setCountry(country);
                        setCity(city);
                        setState(state);
                        setCircle(circle);
                        setDepartment(department);
                        setEmpData(sessionData);
                        setEmpDataDuplicate(employeeData);
                        setMasterData(employeeLocationData);
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        });
    };

    const handleDateChange = (e) => {
        setDateRange(e.value);
    };

    const dateTemplate = (item) => {
        if (serviceDetails.region.includes("us")) {
            return moment(item.date, "YYYY-MM-DD").format("MMM DD");
        } else {
            return moment(item.date, "YYYY-MM-DD").format("Do MMM");
        }
    };

    const missing = (row, cond) => {
        let totalInactive = 0;
        if (cond === "Report") totalInactive = "";
        if (missingData && missingData[row.thingName] && missingData[row.thingName].timeStamp) {
            for (let i = 0; i < missingData[row.thingName].timeStamp.length; i++) {
                if (row.date === missingData[row.thingName].timeStamp[i][4] && row.startTime <= missingData[row.thingName].timeStamp[i][0] && row.endTime >= missingData[row.thingName].timeStamp[i][1]) {
                    if (cond === "Report") totalInactive +=`${moment(missingData[row.thingName].timeStamp[i][0]).format("HH:mm a")} - ${moment(missingData[row.thingName].timeStamp[i][1]).format("HH:mm a")}   ${missingData[row.thingName].timeStamp[i][3].join(",")}\n  `;
                    else totalInactive += missingData[row.thingName].timeStamp[i][2];
                }
            }

        }
        if (cond === "Report") return totalInactive;
        return secondsToHm(totalInactive);
    }

    const reasonsPatch = (row) => {
        let oneReasons = "";
        let checkObj={};
        if (missingData[row.thingName] && missingData[row.thingName].timeStamp) {
            missingData[row.thingName].timeStamp.map((item) => {
                if (row.date === item[4] && row.startTime <= item[0] && row.endTime >= item[1]){
                    item[3].map((item)=>{
                        if(checkObj[item]===undefined){
                            checkObj[item]=1;
                            oneReasons=oneReasons+item+","
                        }
                    })
                }
            })
        }
        if (oneReasons.length > 0) {
            oneReasons = oneReasons.slice(0, -1);
        }
        else oneReasons="-"
        return oneReasons;

    }

    useEffect(() => {
        if (dateRange) {
            if (dateRange[1] !== null) {
                let from = moment(dateRange[0]).format("YYYY-MM-DD");
                let to = moment(dateRange[1]).format("YYYY-MM-DD");
                let formDate = moment(from).startOf("day").format("YYYY-MM-DD HH:mm:ss");
                let toDate = moment(to).endOf("day").format("YYYY-MM-DD HH:mm:ss");
                initReport(formDate, toDate);
            }
        }
    }, [dateRange]);
    const onReset = () => {
        filterObj = {};
        let dateRange = [new Date(startDate), new Date(endDate)];
        setDateRange(dateRange);
        setRadioButton('Summery')
        setSearch("")
        setSelectedCountry(null)
        setSelectedState(null)
        setSelectedCity(null)
        setSelectedCircle(null)
        setSelectedDepartment(null)
    }

    const ticketsTravelledTemplate = (e) => {
        if (e.tickets_travelled === "--" || !e.tickets_travelled.length) return <span>--</span>
        else if (e.tickets_travelled.length < 2) return <span>{e.tickets_travelled[0]}</span>
        else {
            let summaryText = e.tickets_travelled[0]
            let restTicket = e.tickets_travelled.slice(1, e.tickets_travelled.length)
            return (<details id="tickets_travelled">
                <summary className="mb-1">{"1) " + summaryText}</summary>
                <>
                    {restTicket.map((item, i) => {
                        return <>
                            <p className="m-0"><span className="w-full">{i + 2 + ")"}</span> {item}</p>
                        </>
                    })}
                </>
            </details>)
        }
    }

    const getReachedTicketDetails = async (empInfo) =>{
        let payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        "TechnicianID": empInfo.employeeID,
                        "timestamp": { "$gte": `${empInfo.date} 00:00:00`, "$lte": `${empInfo.date} 23:59:59` },
                        "reached_loc": { "$exists": true },
                    }
                },
                { "$lookup": { "from": "nq-geo-fences", "foreignField": "name", "localField": "FACID", "as": "site" } },
                { "$unwind": { "path": "$site" } },
                { "$project": { "_id": 0, "FACID": 1,"TicketNo":1, "gf_lat": "$site.lat", "gf_lon": "$site.lon", "reached_loc": 1, "distanceatreached": 1, "Reached_ts": 1, "SiteAddress":1, "SiteCity":1,"State":1, "SiteName":1} }
            ]
        }
        let res = await employee.getAssignedTicketTillDate(serviceID, payload)
        return res
    }

    return isShowReport ? (
        <EmployeeReport data={reportData} back={onBack} NotRecived={notRecived} reachedTickets={empTicketsReachedData} />
    ) : (
        <>
            <div className="col-12">
                <h4 className="flex justify-content-center">Workforce Location Tracking</h4>
            </div>
            <div className="grid">
                <div className="col-12 md:col-3">
                    <div className="grid">
                        {/* <div className="col-12 md:col-6 p-input-icon-right">
                            <InputText value={search} onChange={(e) => handleSearch(e.target.value)} className="bg-white w-full" placeholder="Search" />
                        </div> */}
                        <div className="col-12">
                            <Calendar id="range" value={dateRange} onChange={handleDateChange} selectionMode="range" showIcon readOnlyInput className="bg-white w-full" />
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6">
                    <div className="col-12 md:col-12">
                        <div className="grid" style={{ flexWrap: "nowrap", justifyContent: "space-between" }}>
                            <div className="col-3 field-radiobutton ">
                                <RadioButton inputId="city1" name="city" value="Summery" onChange={(e) => setRadioButton(e.value)} checked={radioButton === "Summery"} />
                                <label htmlFor="Summery">Summary</label>
                            </div>
                            <div className="col-3 field-radiobutton ">
                                <RadioButton inputId="city2" name="city" value="Details" onChange={(e) => setRadioButton(e.value)} checked={radioButton === "Details"} />
                                <label htmlFor="Details">Details</label>
                            </div>
                            <div className="col-3 field-radiobutton ">
                                <Button icon="pi pi-download" className="p-button-rounded " aria-label="Submit" onClick={onDownload} />
                            </div>
                            <div className="col-3 mb-0 pt-0 ">
                                <Button label="Reset" className=" p-button-danger m-2" onClick={onReset} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Panel headerTemplate={template} toggleable collapsed="true">
                <div className="grid">
                    <div className="col-12 md:col-2 pt-3">
                        <InputText value={search} onChange={(e) => handleFilter('search', e.target.value)} className="bg-white w-full" placeholder="Search" />
                    </div>
                    <div className="col-12 md:col-2">
                        <Dropdown value={selectedCountry} options={country} onChange={(e) => handleFilter('country', e.target.value)} optionLabel="name" placeholder="Country" className="m-2 bg-white w-full" />
                    </div>
                    <div className="col-12 md:col-2">
                        <Dropdown value={selectedState} options={state} onChange={(e) => handleFilter('state', e.target.value)} optionLabel="name" placeholder="State" className="m-2 bg-white w-full" />
                    </div>
                    <div className="col-12 md:col-2">
                        <Dropdown value={selectedCity} options={city} onChange={(e) => handleFilter('city', e.target.value)} optionLabel="name" placeholder="City" className="m-2 bg-white w-full" />
                    </div>
                    <div className="col-12 md:col-2">
                        <Dropdown value={selectedCircle} options={circle} onChange={(e) => handleFilter('circle', e.target.value)} optionLabel="name" placeholder="Circle" className="m-2 bg-white w-full" />
                    </div>
                    <div className="col-12 md:col-2">
                        <Dropdown value={selectedDepartment} options={department} onChange={(e) => handleFilter('department', e.target.value)} optionLabel="name" placeholder="Department" className="m-2 bg-white w-full" />
                    </div>
                </div>
            </Panel>

            <div className="datatable-selection-demo mt-4">
                <div className="card">
                    <DataTable value={empData} selectionMode="single" selection={rowSelect} onSelectionChange={(e) => onEmployeeSelect(e)} dataKey="startTime" showGridlines responsiveLayout="scroll" rows={10} paginator>
                        <Column header="Date" headerClassName="bg-primary" body={dateTemplate} bodyClassName="white-space-nowrap"></Column>
                        <Column header="Employee Id" headerClassName="bg-primary" field="employeeID"></Column>
                        <Column header="User Name" headerClassName="bg-primary" field="name" bodyClassName="white-space-nowrap"></Column>
                        <Column header="Department" headerClassName="bg-primary" field="department"></Column>
                        <Column header="Work Group" headerClassName="bg-primary" field="workGroup"></Column>
                        <Column header="Shift" headerClassName="bg-primary" field="shift"></Column>
                        <Column header="Start Time" headerClassName="bg-primary" body={getStartTimeFormat} bodyClassName="white-space-nowrap"></Column>
                        <Column header="End Time" headerClassName="bg-primary" body={getEndTimeFormat} bodyClassName="white-space-nowrap"></Column>
                        <Column header="Total Travel Time" headerClassName="bg-primary" body={totalTravelTime} bodyClassName="white-space-nowrap"></Column>
                        <Column header="Distance Treavelled" headerClassName="bg-primary" body={distanceTravelled}></Column>
                        <Column header="Designated Stoppage" headerClassName="bg-primary" field="designatedStoppage"></Column>
                        <Column header="Undesignated Stoppage" headerClassName="bg-primary" field="undesignatedStoppage"></Column>
                        <Column header="User Email" headerClassName="bg-primary" field="emailID"></Column>
                        <Column header="Manager" headerClassName="bg-primary" field="manager"></Column>
                        <Column header="Circle" headerClassName="bg-primary" field="circle"></Column>
                        <Column header="City" headerClassName="bg-primary" field="city"></Column>
                        <Column header="State" headerClassName="bg-primary" field="state"></Column>
                        <Column header="Country" headerClassName="bg-primary" field="country"></Column>
                        <Column header=" Tickets_Travelled " headerClassName="bg-primary" field="tickets_travelled" body={ticketsTravelledTemplate}></Column>
                        <Column header="Unclosed Tickets" headerClassName="bg-primary" field="assigned_ticket"></Column>
                        <Column header="Closed Tickets" headerClassName="bg-primary" field="closed_ticket"></Column>
                        <Column header="Employee Tracking Downtime" headerClassName="bg-primary col-12" body={missing} className="col-12"></Column>
                        <Column header="Tracking Discrepancy Causes" headerClassName="bg-primary col-12" body={reasonsPatch} className="col-12"></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
}

export default EmployeeList;
