import React, { Component } from "react";
import { connect } from "react-redux";
import { dispatchAction } from "../../../utilities";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { Galleria } from "primereact/galleria";
import { ColumnGroup } from "primereact/columngroup";
import "./GalleriaAdvancedDemo.css";
import { convertValue } from "../../../utilities/unitConversion";
import { colorPalettes } from "../../../lib/constants";
import { convertCamelCase } from "../../../utilities";
class DefeactsComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            images: null,
        };
        this.template = this.template.bind(this);

        this.itemTemplate = this.itemTemplate.bind(this);
        this.thumbnailTemplate = this.thumbnailTemplate.bind(this);
        this.heightTemplate = this.heightTemplate.bind(this);
    }
    componentDidMount() {
        let image_url = process.env.REACT_APP_IMAGE_STATIC_URL + "/";
        let images = [];
        if (this.props.tower.towerDefects.rustArea && this.props.tower.towerDefects.rustArea.length > 0) {
            this.props.tower.towerDefects.rustArea.forEach((elm) => {
                if (elm.imageName) {
                    images.push({
                        itemImageSrc: image_url + elm.imageName,
                        thumbnailImageSrc: image_url + elm.imageName,
                        alt: "Rust Area",
                        title: elm.location,
                    });
                }
            });
        }

        if ( this.props.tower.towerDefects.vegetation && this.props.tower.towerDefects.vegetation.length > 0) {
            this.props.tower.towerDefects.vegetation.forEach((elm) => {
                if (elm.imageName) {
                    images.push({
                        itemImageSrc: image_url + elm.imageName,
                        thumbnailImageSrc: image_url + elm.imageName,
                        alt: "Vegetation",
                        title: elm.vegetationType,
                    });
                }
            });
        }
        this.setState({ images });
        //console.log("Data: ", this.props.tower);
    }

    itemTemplate(item) {
        return <img src={item.itemImageSrc} alt={item.alt} height={400} width={400} style={{objectFit: "contain"}} />;
    }

    thumbnailTemplate(item) {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ width: "100px", height: "80px" }} />;
    }

    template(options) {
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
                <span className={titleClassName}>{options.props.title}</span>
            </div>
        );
    }
    heightTemplate(item) {
        return convertValue(item.heightfrombase, "height", this.props.unit);
    }

    render = () => {
        let header = (
            <ColumnGroup>
                <Row>
                    <Column className="text-0" style={{ background: colorPalettes.color1 }} header="Location" />
                    <Column className="text-0" style={{ background: colorPalettes.color1 }} header="Area" />
                    <Column className="text-0" style={{ background: colorPalettes.color1 }} header="Severity" />
                </Row>
            </ColumnGroup>
        );
        let header2 = (
            <ColumnGroup>
                <Row>
                    <Column className="text-0" style={{ background: colorPalettes.color1 }} header="Location" />
                    <Column className="text-0" style={{ background: colorPalettes.color1 }} header="Height From Base" />
                </Row>
            </ColumnGroup>
        );

        return (
            <div className="grid">
                { !this.props.tower.towerDetails.totalSiteDefects && !this.props.tower.towerDetails.totalTowerDefects ? (
                    <div className="empty_placeholder flex flex-column align-items-center justify-content-center mx-3">
                        <h1>
                            <i className="pi pi-search mb-2" style={{ fontSize: "1em" }}></i>
                        </h1>
                        <h3>No Defects</h3>
                    </div>
                ) : (
                    <>
                        <div className="col-12 lg:col-6">
                            {this.props.tower.towerDefects.rustArea.length === 0 && this.props.tower.towerDefects.rustedFasteners.length === 0 && this.props.tower.siteDefects.rustingMissingStepsOnStairs.length === 0 ? null : (
                                <Panel headerTemplate={this.template} title="Rust" toggleable className="mb-3">
                                    <div className="p-4">
                                        {this.props.tower.towerDefects.rustArea.length === 0 ? (
                                            <>
                                                <p className="my-3 text-800 flex justify-content-between align-items-center">
                                                    <strong>Rust Area: </strong>
                                                    <span className="text-green-500 text-2xl">NO</span>
                                                </p>
                                                <Divider />
                                            </>
                                        ) : (
                                            <>
                                                <h6>Rust Details</h6>
                                                <DataTable value={this.props.tower.towerDefects.rustArea} responsiveLayout="scroll" headerColumnGroup={header} stripedRows>
                                                    <Column field="location" bodyClassName="color2-bg"></Column>
                                                    <Column field="area" bodyClassName="color2-bg"></Column>
                                                    <Column field="severity" bodyClassName="color2-bg"></Column>
                                                </DataTable>
                                            </>
                                        )}
                                        {this.props.tower.towerDefects.rustedFasteners.length === 0 ? null : null}
                                        {this.props.tower.siteDefects.rustingMissingStepsOnStairs.length === 0 ? null : null}
                                    </div>
                                </Panel>
                            )}

                            <Panel headerTemplate={this.template} title="Tower Defects" toggleable className="mb-3">
                                <div className="p-4">
                                    {Object.keys(this.props.tower.towerDefects).map((t) => {
                                        return (
                                            <>
                                                {t !== "rustArea" && Array.isArray(this.props.tower.towerDefects[t]) ? (
                                                    this.props.tower.towerDefects[t].length ? (
                                                        <>
                                                            <h6>{convertCamelCase(t)}</h6>
                                                            <DataTable value={this.props.tower.towerDefects[t]} responsiveLayout="scroll" stripedRows>
                                                                {Object.keys(this.props.tower.towerDefects[t][0]).map((elm, index) => (elm !== "imageName" ? <Column headerClassName="color1-bg" bodyClassName="color2-bg" field={elm} header={convertCamelCase(elm)} key={elm + index}></Column> : null))}
                                                            </DataTable>
                                                        </>
                                                    ) : null
                                                ) : typeof this.props.tower.towerDefects[t] === "string" ? (
                                                    this.props.tower.towerDefects[t] === "Yes" ? (
                                                        <p className="my-3 text-800 flex justify-content-between align-items-center">
                                                            <strong>{convertCamelCase(t)}</strong>
                                                            <span className="text-pink-500 text-2xl">YES</span>
                                                        </p>
                                                    ) : null
                                                ) : Object.keys(this.props.tower.towerDefects[t]).length && this.props.tower.towerDefects[t].present === "Yes" ? (
                                                    <p className="my-3 text-800 flex justify-content-between align-items-center">
                                                        <strong>{convertCamelCase(t)}</strong>
                                                        <span className="text-pink-500 text-2xl">YES</span>
                                                    </p>
                                                ) : null}
                                            </>
                                        );
                                    })}
                                </div>
                            </Panel>

                            <Panel headerTemplate={this.template} title="Site Defects" toggleable>
                                {Object.keys(this.props.tower.siteDefects).map((item) => {
                                    return (
                                        <>
                                            {Array.isArray(this.props.tower.siteDefects[item]) ? (
                                                this.props.tower.siteDefects[item].length > 0 ? (
                                                    <>
                                                        <h6>{convertCamelCase(item)}</h6>
                                                        <DataTable value={this.props.tower.siteDefects[item]} responsiveLayout="scroll" stripedRows>
                                                            {Object.keys(this.props.tower.siteDefects[item][0]).map((elm, index) => (elm !== "imageName" ? <Column headerClassName="color1-bg" bodyClassName="color2-bg" field={elm} header={convertCamelCase(elm)} key={elm + index}></Column> : null))}
                                                        </DataTable>
                                                    </>
                                                ) : null
                                            ) : typeof this.props.tower.siteDefects[item] === "string" ? (
                                                this.props.tower.siteDefects[item] === "Yes" ? (
                                                    <p className="my-3 text-800 flex justify-content-between align-items-center">
                                                        <strong>{convertCamelCase(item)}</strong>
                                                        <span className="text-pink-500 text-2xl">YES</span>
                                                    </p>
                                                ) : null
                                            ) : Object.keys(this.props.tower.siteDefects[item]).length && this.props.tower.siteDefects[item].present === "Yes" ? (
                                                <p className="my-3 text-800 flex justify-content-between align-items-center">
                                                    <strong>{convertCamelCase(item)}</strong>
                                                    <span className="text-pink-500 text-2xl">YES</span>
                                                </p>
                                            ) : null}
                                        </>
                                    );
                                })}
                            </Panel>
                        </div>
                        <div className="col-12 lg:col-6">
                            {this.state.images && this.state.images.length > 0 ? (
                                <div className="card">
                                    <Galleria value={this.state.images} responsiveOptions={this.responsiveOptions} numVisible={3} style={{ maxWidth: "100%" }} item={this.itemTemplate} thumbnail={this.thumbnailTemplate} />
                                </div>
                            ) : null}
                        </div>
                    </>
                )}
            </div>
        );
    };
}
const mapStateToProps = (state) => {
    return { unit: state.changeUnit };
};
export default connect(mapStateToProps, { dispatchAction })(DefeactsComponent);
