import axios from "axios"

const getThingsData = async (serviceId) => {
    const payload = {
        projection: { "sensorIdentification.itemId": 1, "installationParameters.type": 1, _id: 0 }
    }
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-config-sensors", payload);
    return res.data;
}

const getIndoorData = async (serviceId,arr) =>{
    let payload = {
        projection:{
        _id:0,
        temp : 1,
        humidity : 1,
        thingName:1
        }
    }
    if(arr && arr.length>0){
        payload={
            ...payload,
            filter:{
                "thingName": { $in: arr }
            }
        }
    }
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-raw-meta", payload)
    return res.data
}

const getDayChartDetails = async (today, serviceId,arr) => {
    let payload = {
        operation: "aggregate",
        aggregate: [{
            $match: {
                $and: [{ date: today }],
            },
        },
        {
            $project: {
                _id: 0,
                hr: 1,
                avg_humidity: 1,
                avg_temp: 1,
                thingName: 1
            }
        },
        { $sort: { "hr": 1 } }
        ]
    }
    if(arr && arr.length>0){
        payload.aggregate=[{
            $match: {
                "thingName": { $in: arr }
            }
        },...payload.aggregate]
    }
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-hourly-history", payload)
    return res.data
}

const getPieChartDetails = async (startTime, endTime, serviceId, thing) => {
    let payload = {
        operation : "aggregate",
        aggregate : [{
            "$match" :{
                "date" : {"$gte" : startTime, "$lte" : endTime},
                ...(thing && {"thingName" : thing})
                }
            },
            {
                "$group" :{
                    "_id" : {
                        "thing" : "$thingName",
                        "date" : "$date"
                    },
                    "humidity_less_20"   : {"$sum" : "$humidity_less_then_twenty"},
                    "humidity_bet_20_40" : {"$sum" : "$humidity_bet_twenty_and_fourty"},
                    "humidity_bet_40_60" : {"$sum" : "$humidity_bet_fourty_and_sixty"},
                    "humidity_bet_60_80" : {"$sum" : "$humidity_bet_sixty_and_eighty"},
                    "humidity_above_80"  : {"$sum" :  "$humidity_greater_then_eighty"}
                }
        }]
    }
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-hourly-history", payload)
    return res.data
}

const getDownloadData = async (startTime, endTime,thingName, serviceId) => {
    let payload = {
        operation : "aggregate",
        aggregate :  [{
            "$match":{
                 "date" : {"$gte": startTime, "$lte" : endTime},
                 "thingName" : thingName
             },
         },{
             "$project" : {
                 "date":1,
                 "hr" : 1,
                 "avg_humidity" :1,
                 "max_humidity":1,
                 "min_humidity":1,
                 "_id":0
             }
         },{
             "$sort" : {"date":1, "hr":1}
             }]
        }
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-hourly-history", payload);
    return res.data;
}

let indoorService = {
    getThingsData,
    getDayChartDetails,
    getIndoorData,
    getPieChartDetails,
    getDownloadData
}

export default indoorService