import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment/moment";
import { VehicleSrvice } from "../../service/VehicleService";

import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";
import { connect } from "react-redux";
import { dispatchAction } from "../../utilities/utillFunction";
import { convertValue } from "../../utilities/unitConversion";
class VehicleSummery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalVehicle: null,
            onMove: null,
            stationary: null,
            Offline: null,
            pieOption: null,
            mixChart: null,
            vehicleOBDDataDTC: null,
            vehicleOBDDataMIL: null,
            unitSystem: this.props.unit,
            checked1: false,
            drivingScoreChart: null,
            ratingDriverChart: null,
            weeklyDrivingReport: null,
            aggressiveDriver: null,
            forConditionThingName: [],
            forConditionAggresiveDriver: [],
            forConditionDrivingScore: [],
        };

        this.serviceID = localStorage.getItem("serviceId");
        this.service = JSON.parse(localStorage.getItem("service"));
        this.unitSys = this.service.region === "us" ? "FPS" : "MKS";
        this.vehicleService = new VehicleSrvice();
    }

    componentDidMount = () => {
        this.reportInit();
    };

    handelSwitch = (value) => {
        let unit = process.env.REACT_APP_DISPLAY_TYPE;
        if (value === true) {
            unit = "MKS";
        }
        this.setState({ checked1: value, unitSystem: unit }, () => this.reportInit());
    };

    reportInit = () => {
        const to_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        const from_date = moment().subtract(30, "days").format("YYYY-MM-DD");

        this.vehicleService.getAllOBDVehicle(this.serviceID).then((response) => {
            let thingNames = [];
            if (response && response.length > 0) {
                let vehicles = response;
                const totalVehicle = vehicles.length;
                let onMove = 0,
                    stationary = 0,
                    Offline = 0;

                let vehicleOBDDataDTC = [];
                let vehicleOBDDataMIL = [];

                vehicles.forEach((item) => {
                    thingNames.push(item.thingName);
                    if (item.ignition === 1) {
                        onMove += 1;
                    } else if (item.ignition === 0) {
                        stationary += 1;
                    }
                });

                const payload = {
                    operation: "aggregate",
                    aggregate: [
                        {
                            $match: {
                                startTime: {
                                    $gte: from_date,
                                    $lte: to_date,
                                },
                                thingName: {
                                    $in: thingNames,
                                },
                            },
                        },
                        {
                            $group: {
                                _id: "$thingName",
                                maxDistanceInDay: {
                                    $max: "$totalDistanceTravelled",
                                },
                                noOfDays: {
                                    $sum: 1,
                                },
                                avgDailyDistance: {
                                    $avg: "$totalDistanceTravelled",
                                },
                                totalDistance: {
                                    $sum: "$totalDistanceTravelled",
                                },
                            },
                        },
                    ],
                };

                this.vehicleService.getAggregateHistory(this.serviceID, payload).then((response) => {
                    let vehicles = response;
                    let avgDailyDistance = [],
                        maxDistanceInDay = [],
                        // totalDistance = [],
                        totalDays = [],
                        mixThing = [];
                    // let totaDistance = 0;
                    vehicles.forEach((item) => {
                        let avgDD = convertValue(item.avgDailyDistance, "distance", this.unitSys);
                        let maxDD = convertValue(item.maxDistanceInDay, "distance", this.unitSys);
                        avgDailyDistance.push(parseFloat(avgDD));
                        maxDistanceInDay.push(parseFloat(maxDD));
                        // totalDistance.push(parseFloat(item.totalDistance.toFixed(2)));
                        totalDays.push(item.noOfDays);
                        mixThing.push(item._id);
                        // totaDistance += item.totalDistance;
                    });

                    // let avgDistance = [];
                    // thingNames.forEach((item) => avgDistance.push(parseFloat((totaDistance / 30).toFixed(2))));

                    let payload1 = {
                        filter: {
                            date: moment().subtract(1, "days").format("YYYY-MM-DD"),
                        },
                    };

                    let payload2 = {
                        operation: "aggregate",
                        aggregate: [
                            { $match: { startTime: { $gte: moment().subtract(7, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss"), $lte: moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD HH:mm:ss") } } },
                            { $group: { _id: { date: "$date" }, avgMilage: { $avg: "$totalDistanceTravelled" } } },
                            { $sort: { "_id.date": -1 } },
                        ],
                    };

                    let payload3 = {
                        operation: "aggregate",
                        aggregate: [
                            {
                                $match: {
                                    startTime: {
                                        $gte: moment(from_date).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                                        $lte: moment(to_date).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                                    },
                                },
                            },
                            {
                                $group: {
                                    _id: {
                                        thingName: "$thingName",
                                    },
                                    total_harse_acc: {
                                        $sum: "$greendriving.harsh_acc",
                                    },
                                    total_harsh_brak: {
                                        $sum: "$greendriving.harsh_brak",
                                    },
                                    total_harsh_corn: {
                                        $sum: "$greendriving.harsh_corn",
                                    },
                                    total_harsh_speeding: {
                                        $sum: "$greendriving.harsh_speeding",
                                    },
                                },
                            },
                            {
                                $sort: {
                                    "_id.greendriving.driveroverallscore": -1,
                                },
                            },
                        ],
                    };

                    Promise.all([this.vehicleService.getHistory(this.serviceID, payload1), this.vehicleService.getAggregateHistory(this.serviceID, payload2), this.vehicleService.getAggregateHistory(this.serviceID, payload3)]).then((response) => {
                        let avgDrivingScore = [];
                        let thingName = [];

                        let less50 = 0;
                        let less60 = 0;
                        let less75 = 0;
                        let less90 = 0;
                        let less100 = 0;
                        response[0].forEach((item) => {
                            avgDrivingScore.push(Number(item.greendriving.driveroverallscore.toFixed(2)));
                            thingName.push(item.thingName);

                            if (item.greendriving.driveroverallscore < 50) {
                                less50 += 1;
                            } else if (item.greendriving.driveroverallscore >= 50 && item.greendriving.driveroverallscore < 60) {
                                less60 += 1;
                            } else if (item.greendriving.driveroverallscore >= 60 && item.greendriving.driveroverallscore < 75) {
                                less75 += 1;
                            } else if (item.greendriving.driveroverallscore >= 75 && item.greendriving.driveroverallscore < 90) {
                                less90 += 1;
                            } else {
                                less100 += 1;
                            }
                        });
                        let aggressiveDriver_categories = [],
                            harse_acc = [],
                            harsh_brak = [],
                            harsh_corn = [];

                        response[2].forEach((item) => {
                            aggressiveDriver_categories.push(item._id.thingName);
                            harse_acc.push(item.total_harse_acc);
                            harsh_brak.push(item.total_harsh_brak);
                            harsh_corn.push(item.total_harsh_corn);
                        });

                        let date = [];
                        let avgMilage = [];
                        let newArray = response[1].map((item) => {
                            let date = item._id.date.split("-");
                            let newDate = `${date[1]}-${date[0]}-${date[2]}`;
                            return {
                                date: newDate,
                                avgMilage: convertValue(item.avgMilage, "distance", this.unitSys, true, false),
                            };
                        });
                        newArray.sort((a, b) => {
                            return new Date(b.date).getTime() - new Date(a.date).getTime();
                        });
                        newArray.forEach((item) => {
                            date.push(moment(item.date, "MM-YYYY-DD").format("MMM DD"));
                            avgMilage.push(parseFloat(item.avgMilage));
                        });

                        let ratingDriverChart = {
                            chart: {
                                type: "pie",
                            },
                            title: {
                                text: "Driving Score Distribution",
                                align: "left",
                                style: chartHeadingStyle,
                            },

                            accessibility: {
                                announceNewData: {
                                    enabled: true,
                                },
                                point: {
                                    valueSuffix: "",
                                },
                            },

                            plotOptions: {
                                series: {
                                    dataLabels: {
                                        enabled: true,
                                        format: "{point.name}: {point.y:.1f}",
                                    },
                                },
                                pie: {
                                    allowPointSelect: true,
                                    cursor: "pointer",
                                    dataLabels: {
                                        enabled: true,
                                        format: "<b>{point.name}</b>: {point.y}",
                                        style: {
                                            fontSize: "14px",
                                        },
                                    },
                                    showInLegend: true,
                                },
                            },

                            tooltip: {
                                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                                pointFormat: '<span style="color:{point.color}">{point.name}</span> <b>{point.y:.2f}</b> of total<br/>',
                            },

                            series: [
                                {
                                    name: "",
                                    colorByPoint: true,
                                    data: [
                                        {
                                            name: "< 50",
                                            y: less50,
                                            drilldown: "< 50",
                                            color: colorPalettes.color1,
                                        },
                                        {
                                            name: "50-60",
                                            y: less60,
                                            drilldown: "50-60",
                                            color: colorPalettes.color2,
                                        },
                                        {
                                            name: "60-75",
                                            y: less75,
                                            drilldown: "60-75",
                                            color: colorPalettes.color3,
                                        },
                                        {
                                            name: "75-90",
                                            y: less90,
                                            drilldown: "75-90",
                                            color: colorPalettes.color4,
                                        },
                                        {
                                            name: "90-100",
                                            y: less100,
                                            drilldown: "90-100",
                                            color: colorPalettes.color5,
                                        },
                                    ],
                                },
                            ],
                            drilldown: {
                                series: [
                                    {
                                        name: "< 50",
                                        id: "< 50",
                                        data: [less50],
                                        color: colorPalettes.color1,
                                    },
                                    {
                                        name: "50-60",
                                        id: "50-60",
                                        data: [less60],
                                        color: colorPalettes.color2,
                                    },
                                    {
                                        name: "60-75",
                                        id: "60-75",
                                        data: [less75],
                                        color: colorPalettes.color3,
                                    },
                                    {
                                        name: "75-90",
                                        id: "75-90",
                                        data: [less90],
                                        color: colorPalettes.color4,
                                    },
                                    {
                                        name: "90-100",
                                        id: "90-100",
                                        data: [less100],
                                        color: colorPalettes.color5,
                                    },
                                ],
                            },
                        };

                        let drivingScoreChart = {
                            chart: {
                                type: "column",
                            },
                            title: {
                                text: "Best driven Vehicles",
                                style: chartHeadingStyle,
                            },
                            xAxis: {
                                categories: thingName,
                                crosshair: true,
                            },

                            yAxis: {
                                min: 0,
                                title: {
                                    text: "Driving Score",
                                },
                            },

                            tooltip: {
                                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                                footerFormat: "</table>",
                                shared: true,
                                useHTML: true,
                            },
                            plotOptions: {
                                column: {
                                    pointPadding: 0.2,
                                    borderWidth: 0,
                                    dataLabels: {
                                        enabled: true,
                                    },
                                },
                                series: {
                                    pointWidth: 40,
                                },
                            },
                            series: [
                                {
                                    name: "Vehicle",
                                    data: avgDrivingScore,
                                    color: colorPalettes.color1,
                                },
                            ],
                        };

                        let weeklyDrivingReport = {
                            chart: {
                                type: "bar",
                            },
                            title: {
                                text: "Fleet Mileage (Last 7 Days)",
                                align: "left",
                                style: chartHeadingStyle,
                            },

                            xAxis: {
                                categories: date,
                                title: {
                                    text: null,
                                },
                            },
                            yAxis: {
                                title: {
                                    text: `Distance(${this.unitSys === "FPS" ? "mile" : "Km"})`,
                                },
                            },
                            tooltip: {
                                valueSuffix: `${this.unitSys === "FPS" ? "mile" : "Km"}`,
                            },

                            plotOptions: {
                                bar: {
                                    dataLabels: {
                                        enabled: true,
                                    },
                                },
                            },
                            credits: {
                                enabled: false,
                            },
                            series: [
                                {
                                    name: "Mileage",
                                    data: avgMilage,
                                    color: colorPalettes.color4,
                                },
                            ],
                        };

                        let aggressiveDriver = {
                            chart: {
                                type: "column",
                            },
                            title: {
                                text: "Aggressively Driven vehicles",
                                style: chartHeadingStyle,
                            },
                            xAxis: {
                                categories: aggressiveDriver_categories,
                            },
                            yAxis: {
                                min: 0,
                                title: {
                                    text: "Driving Events",
                                },
                            },
                            tooltip: {
                                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                                shared: true,
                            },
                            plotOptions: {
                                column: {
                                    stacking: "normal",
                                    dataLabels: {
                                        enabled: true,
                                    },
                                },

                                series: {
                                    pointWidth: 40,
                                },
                            },
                            series: [
                                {
                                    name: "Harsh Braking",
                                    data: harsh_brak,
                                    color: colorPalettes.color2,
                                },
                                {
                                    name: "Harsh Cornering",
                                    data: harsh_corn,
                                    color: colorPalettes.color5,
                                },
                                {
                                    name: "Harsh Acceleration",
                                    data: harse_acc,
                                    color: colorPalettes.color3,
                                },
                            ],
                        };

                        this.setState({
                            drivingScoreChart: drivingScoreChart,
                            ratingDriverChart: ratingDriverChart,
                            weeklyDrivingReport: weeklyDrivingReport,
                            aggressiveDriver: aggressiveDriver,
                            forConditionThingName: thingName,
                            forConditionAggresiveDriver: aggressiveDriver_categories,
                            forConditionDrivingScore: avgDrivingScore,
                        });
                    });

                    let pieOption = {
                        chart: {
                            plotBackgroundColor: null,
                            plotBorderWidth: null,
                            plotShadow: false,
                            type: "pie",
                        },
                        title: {
                            text: `Current Status - (Total ${totalVehicle})`,
                            style: chartHeadingStyle,
                        },
                        tooltip: {
                            pointFormat: "{series.name}: <b>{point.y}</b>",
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: "pointer",
                                dataLabels: {
                                    enabled: true,
                                    format: "<b>{point.name}</b>: {point.y}",
                                    style: {
                                        fontSize: "14px",
                                    },
                                },
                                showInLegend: true,
                            },
                        },
                        series: [
                            {
                                name: "Total",
                                colorByPoint: true,
                                data: [
                                    {
                                        name: "Moving",
                                        y: onMove,
                                        color: colorPalettes.color1,
                                    },
                                    {
                                        name: "Stationary",
                                        y: stationary,
                                        color: colorPalettes.color5,
                                    },
                                    {
                                        name: "Offline",
                                        y: Offline,
                                        color: colorPalettes.color2,
                                    },
                                ],
                            },
                        ],
                    };

                    let mixChart = {
                        chart: {
                            zoomType: "xy",
                        },
                        title: {
                            text: `Usage Summary for Last 30 Days(${moment(from_date, "YYYY-MM-DD").format("Do MMM")} - ${moment(to_date, "YYYY-MM-DD").format("Do MMM")})`,
                            align: "center",
                            style: chartHeadingStyle,
                        },
                        xAxis: [
                            {
                                categories: mixThing,
                                crosshair: true,
                            },
                        ],
                        yAxis: [
                            {
                                // Primary yAxis
                                labels: {
                                    format: `{value} ${this.unitSys === "FPS" ? "miles" : "Km"}`,
                                    style: {
                                        color: Highcharts.getOptions().colors[1],
                                    },
                                },
                                title: {
                                    text: "Distance",
                                    style: {
                                        color: Highcharts.getOptions().colors[1],
                                    },
                                },
                            },
                            {
                                // Secondary yAxis
                                title: {
                                    text: "Days",
                                    style: {
                                        color: colorPalettes.color1,
                                    },
                                },
                                labels: {
                                    format: "{value}",
                                    style: {
                                        color: colorPalettes.color1,
                                    },
                                },
                                opposite: true,
                            },
                        ],
                        tooltip: {
                            shared: true,
                        },
                        plotOptions: {
                            column: {
                                pointPadding: 0.2,
                                borderWidth: 0,
                            },
                            series: {
                                pointWidth: 40,
                            },
                        },
                        series: [
                            {
                                name: "Avg. Daily Distance",
                                type: "column",
                                data: avgDailyDistance,
                                color: colorPalettes.color2,
                                tooltip: {
                                    valueSuffix: this.unitSys === "FPS" ? " miles" : " Km",
                                },
                            },
                            {
                                name: "Max Distance in Day",
                                type: "column",
                                data: maxDistanceInDay,
                                color: colorPalettes.color4,
                                tooltip: {
                                    valueSuffix: this.unitSys === "FPS" ? " miles" : " Km",
                                },
                            },
                            // {
                            //     name: "Avg. Daily Distance(Fleet)",
                            //     type: "line",
                            //     data: avgDistance,
                            //     color: colorPalettes.color3,
                            //     tooltip: {
                            //         valueSuffix: this.unitSys === "FPS" ? " miles" : " Km",
                            //     },
                            // },
                            {
                                name: "Days Travelled",
                                type: "line",
                                yAxis: 1,
                                data: totalDays,
                                color: colorPalettes.color5,
                            },
                        ],
                    };

                    this.setState({ totalVehicle, onMove, stationary, Offline, pieOption, mixChart, vehicleOBDDataDTC, vehicleOBDDataMIL });
                });
            }
        });
    };

    render = () => {
        let headerGroup1 = (
            <ColumnGroup>
                <Row>
                    <Column header="Vehicle" className="bg-primary"></Column>
                    <Column header="DTC" className="bg-primary"></Column>
                    <Column header="DTC as on" className="bg-primary"></Column>
                </Row>
            </ColumnGroup>
        );
        let headerGroup2 = (
            <ColumnGroup>
                <Row>
                    <Column header="Vehicle" className="bg-primary"></Column>
                    <Column header="MIL" className="bg-primary"></Column>
                    <Column header="MIL as on" className="bg-primary"></Column>
                    <Column header="Distance Since MIL On" className="bg-primary"></Column>
                </Row>
            </ColumnGroup>
        );

        return (
            <div className="grid">
                <div className="col-12">
                    <h3 style={{ margin: "0", textAlign: "center", padding: "10px 0" }}>Vehicle Summary</h3>
                </div>
                <div className="col-12 lg:col-4">
                    {this.state.pieOption ? (
                        <div className="card" style={{ height: "100%" }}>
                            <HighchartsReact highcharts={Highcharts} options={this.state.pieOption} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-8">
                    {this.state.mixChart ? (
                        <div className="card" style={{ height: "100%" }}>
                            <HighchartsReact highcharts={Highcharts} options={this.state.mixChart} />
                        </div>
                    ) : null}
                </div>

                <div className="col-12 lg:col-12">
                    <div className="grid">
                        <div className="col-12 lg:col-6">
                            {this.state.weeklyDrivingReport ? (
                                <div className="card" style={{ height: "100%" }}>
                                    <HighchartsReact highcharts={Highcharts} options={this.state.weeklyDrivingReport} />
                                </div>
                            ) : null}
                        </div>
                        {this.state.forConditionDrivingScore.length !== 0 ? (
                            <div className="col-12 lg:col-6">
                                {this.state.ratingDriverChart ? (
                                    <div className="card" style={{ height: "100%" }}>
                                        <HighchartsReact highcharts={Highcharts} options={this.state.ratingDriverChart} />
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>
                {this.state.forConditionThingName.length !== 0 ? (
                    <div className="col-12 lg:col-12">
                        {this.state.drivingScoreChart ? (
                            <div className="card" style={{ height: "100%" }}>
                                <HighchartsReact highcharts={Highcharts} options={this.state.drivingScoreChart} />
                            </div>
                        ) : null}
                    </div>
                ) : null}
                {this.state.forConditionAggresiveDriver.length !== 0 ? (
                    <div className="col-12 lg:col-12">
                        {this.state.aggressiveDriver ? (
                            <div className="card" style={{ height: "100%" }}>
                                <HighchartsReact highcharts={Highcharts} options={this.state.aggressiveDriver} />
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {this.state.vehicleOBDDataDTC && this.state.vehicleOBDDataDTC.length > 0 ? (
                    <div className="col-12 lg:col-6">
                        <div className="card" style={{ height: "100%" }}>
                            <DataTable headerColumnGroup={headerGroup1} value={this.state.vehicleOBDDataDTC} responsiveLayout="scroll" stripedRows>
                                <Column field="name"></Column>
                                <Column field="dtc"></Column>
                                <Column field="dtc_on"></Column>
                            </DataTable>
                        </div>
                    </div>
                ) : null}
                {this.state.vehicleOBDDataMIL && this.state.vehicleOBDDataMIL.length > 0 ? (
                    <div className="col-12 lg:col-6">
                        <div className="card" style={{ height: "100%" }}>
                            <DataTable headerColumnGroup={headerGroup2} value={this.state.vehicleOBDDataMIL} responsiveLayout="scroll" stripedRows>
                                <Column field="name"></Column>
                                <Column field="mil"></Column>
                                <Column field="mil_on"></Column>
                                <Column field="distance"></Column>
                            </DataTable>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    };
}
const mapStateToProps = (state) => {
    return { unit: state.changeUnit };
};
export default connect(mapStateToProps, { dispatchAction })(VehicleSummery);
