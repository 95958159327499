import React from "react";
import { Button } from "primereact/button";
import fileSaver from "file-saver";
import { useHistory } from "react-router-dom";

const DownloadFile = () => {
    const history = useHistory();
    return (
        <div className="grid">
            <div className="col">
                <div className="flex w-5 mx-auto justify-content-center">
                    <div className="mr-8">
                        <div className="mx-auto doc active">
                            <i className="pi pi-download"></i>
                        </div>
                        <span className="mt-3 block">Download Template</span>
                    </div>

                    <div className="ml-8" onClick={() => history.push("/upload-file")}>
                        <div className="mx-auto doc">
                            <i className="pi pi-upload"></i>
                        </div>
                        <span className="mt-3 block">Upload Config</span>
                    </div>
                </div>
                <div className="flex flex-column w-5 h-13rem mt-3 mx-auto border-1 border-dashed border-400 border-round-md justify-content-center align-items-center">
                    <div className="flex mb-3 flex-column align-items-center">
                        <div className="text-6xl">
                            <i className="pi pi-download text-4xl"></i>
                        </div>
                        <span className="mt-3 block">Download the Config File Template</span>
                    </div>
                    <Button
                        label="Download Template"
                        aria-label="download-template"
                        onClick={() => {
                            fileSaver.saveAs("/configFile.zip", "Config-File");
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default DownloadFile;
