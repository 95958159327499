import React, { useEffect, useState } from "react";
import { mapKey } from "../../utilities/constant";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Slider } from "primereact/slider";
import axios from "axios";
import { AutoComplete } from "primereact/autocomplete";

const svgMarkup =
    '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path style="fill:#01010100; stroke:none;" d="M0 0L0 32L32 32L32 0L0 0z"/><path style="fill:#ef3d49; stroke:none;" d="M15 28L17 28C19.368 24.4869 21.95 20.9627 23.5208 17C31.413 -2.9091 1.1202 -3.05081 8.2716 16C9.88256 20.2915 12.4852 24.1989 15 28z"/><path style="fill:#ef2121; stroke:none;" d="M15 5L16 6L15 5z"/><path style="fill:#ffffff; stroke:none;" d="M13.7461 7.03241C10.2098 9.89313 14.9493 15.6823 18.3927 12.3781C21.696 9.20841 17.2399 4.20618 13.7461 7.03241z"/><path style="fill:#ff166b; stroke:none;" d="M12 7L13 8L12 7z"/><path style="fill:#36d1ba; stroke:none;" d="M5 27L5 29C11.8299 31.882 20.17 31.882 27 29L27 27L20 25L20 27L24 27L24 28L8 28L8 27L12 27L12 25L5 27z"/></svg>';

function LocationStore(props) {
    const initialState = {
        name: "",
        category: "",
        abbr: "",
        lat: 0,
        lon: 0,
    };
    const mapRef = React.createRef();
    const [maps, setMaps] = useState(null);
    const [coord, setCoord] = useState({ lat: 0, lng: 0 });
    const [myposition, setMyposition] = useState(null);
    const [radiusValue, setRadiusValue] = useState(0);
    const [data, setData] = useState(initialState);
    const [stepper, setStepper] = useState(50);
    const [search, setSearch] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [suggessions, setSuggessions] = useState(null);
    const serviceDetails = JSON.parse(localStorage.getItem("service"));

    const H = window.H;
    const platform = new H.service.Platform({
        apikey: mapKey,
    });
    const defaultLayers = platform.createDefaultLayers();

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }, []);
    useEffect(() => {
        if (myposition) {
            const map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
                center: { lat: myposition.lat, lng: myposition.lng },
                zoom: 12,
                pixelRatio: window.devicePixelRatio || 1,
            });
            window.addEventListener("resize", () => map.getViewPort().resize());
            setMaps(map);
            const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
            const ui = H.ui.UI.createDefault(map, defaultLayers);
            map.addEventListener("tap", function (evt) {
                map.removeObjects(map.getObjects());
                let coord = map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);
                let bearsIcon = new H.map.Icon(svgMarkup),
                    bearsMarker = new H.map.Marker(coord, { icon: bearsIcon });
                map.addObject(bearsMarker);
                setData((prv) => {
                    return {
                        ...prv,
                        lat: parseFloat(coord.lat.toFixed(5)),
                        lon: parseFloat(coord.lng.toFixed(5)),
                    };
                });
            });
        }
    }, [myposition]);

    function successCallback(position) {
        const myLat = position.coords.latitude;
        const myLng = position.coords.longitude;
        let myPos = {
            lat: myLat,
            lng: myLng,
        };
        setMyposition(myPos);
    }

    function errorCallback(error) {
        let zip = serviceDetails.location.zip;
        axios.get("https://geocode.search.hereapi.com/v1/geocode?q=" + zip + "&apiKey=" + mapKey).then((res) => {
            setMyposition(res.data.items[0].position);
        });
    }

    const handleSubmit = () => {
        let radius;
        if (serviceDetails.region === "us-east-1" || serviceDetails.region === "us" || serviceDetails.region === "US") {
            radius = Math.round(stepper / 3.281);
        } else {
            radius = stepper;
        }
        maps.removeObjects(maps.getObjects());
        let circle = new H.map.Circle({ lat: data.lat, lng: data.lon }, radius);
        maps.addObject(circle);

        let allData = { ...data, category: data.category.name, radius: radius };
        props.saveGeoFence(allData);
    };

    const handelChange = (e) => {
        setData((prv) => {
            return {
                ...prv,
                [e.target.name]: e.target.value,
            };
        });
    };

    const cities = [
        { name: "Office", code: "Office" },
        { name: "Home", code: "Home" },
        { name: "Customer Location", code: "Customer Location" },
        { name: "Project Location", code: "Project Location" },
        { name: "Other", code: "Other" },
    ];

    useEffect(() => {
        if (searchValue) {
            axios.get("https://geocode.search.hereapi.com/v1/geocode?q=" + searchValue + "&apiKey=" + mapKey).then((res) => {
                setData({ ...data, lat: res.data.items[0].position.lat, lon: res.data.items[0].position.lng });
                maps.setCenter({ lat: res.data.items[0].position.lat, lng: res.data.items[0].position.lng });
                let bearsIcon = new H.map.Icon(svgMarkup),
                    bearsMarker = new H.map.Marker({ lat: res.data.items[0].position.lat, lng: res.data.items[0].position.lng }, { icon: bearsIcon });
                maps.addObject(bearsMarker);
            });
        }
    }, [searchValue]);
    const searchCountry = () => {
        setTimeout(() => {
            axios.get("https://geocode.search.hereapi.com/v1/autocomplete?q=" + search + "&apiKey=" + mapKey).then((res) => {
                let data = [];
                res.data.items.forEach((item) => {
                    data.push({ ...item, name: item.title, code: item.title });
                });
                setSuggessions(data);
            });
        }, 2000);
    };

    return (
        <div className="col-12">
            <div className="col-12">
                <AutoComplete value={search} suggestions={suggessions} completeMethod={searchCountry} field="name" onChange={(e) => setSearch(e.target.value)} onSelect={(e) => setSearchValue(e.value.title)} aria-label="Searc" dropdownAriaLabel="Select Field" placeholder="Search" />
            </div>
            <div className="grid">
                <div className="col-12 lg:col-8">
                    <div className="card p-0">
                        <div
                            ref={mapRef}
                            style={{
                                width: "100%",
                                height: "76vh",
                                overflow: "hidden",
                            }}
                        ></div>
                    </div>
                </div>
                <div className="col-12 lg:col-4">
                    <div className="grid">
                        <div className="col-12">
                            <h4>GeoFence Details</h4>
                        </div>
                        <div className="col-12">
                            <label htmlFor="name">Name</label>
                            <InputText value={data.name} className="w-full mt-1" name="name" onChange={handelChange} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="category">Category</label>
                            <Dropdown value={data.category} options={cities} name="category" onChange={handelChange} optionLabel="name" placeholder="GeoFence Category" className="w-full mt-1" />
                        </div>
                        <div className="col-12">
                            <label htmlFor="abbr">Alias(This would be displayed on map)</label>
                            <InputText value={data.abbr} className="w-full mt-1" name="abbr" onChange={handelChange} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="Lat">Latitude</label>
                            <InputText value={data.lat} disabled className="w-full mt-1" name="lat" />
                        </div>
                        <div className="col-12">
                            <label htmlFor="lon">Longitude</label>
                            <InputText value={data.lon} disabled className="w-full mt-1" name="lon" />
                        </div>
                        {serviceDetails.region === "us-east-1" || serviceDetails.region === "us" || serviceDetails.region === "US" ? (
                            <div className="col-12">
                                <h5>Radius: {stepper} ft</h5>
                                <Slider value={stepper} onChange={(e) => setStepper(e.value)} step={50} max={5280} min={50} />
                            </div>
                        ) : (
                            <div className="col-12">
                                <h5>Radius: {stepper} m</h5>
                                <Slider value={stepper} onChange={(e) => setStepper(e.value)} step={50} max={1000} min={50} />
                            </div>
                        )}
                    </div>
                    <div className="col-12 lg:col-12 flex justify-content-end ">
                        {data.name !== "" && data.radius !== 0 && data.abbr !== "" && data.lat !== 0 && data.lng !== 0 ? <Button label="Submit" aria-label="Save" className="ml-3" onClick={handleSubmit} /> : <Button label="Submit" aria-label="Save" className="ml-3" disabled onClick={handleSubmit} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LocationStore;
