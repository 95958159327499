import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { secondsToHm } from "../../../../utilities/utillFunction";

export default class HistoryAcceleration extends Component {
    constructor(props) {
        super(props);
    }

    timeFormat = (value) => {
        return <>{moment(value.ts).format("Do MMM, YYYY HH:mma")}</>;
    };
    timeConvert = (value) => {
        return (
            <>
                <p>{secondsToHm(value.greendrivingeventduration)}</p>
            </>
        );
    };
    render() {
        return (
            <div>
                <div className="col-12">
                    {this.props.value ? (
                        <DataTable value={this.props.value} responsiveLayout="scroll" paginator rows={7} showGridlines>
                            <Column field="index" header="Sl." headerClassName="bg-primary"></Column>
                            <Column field="greendrivingvalue" header="Value(m/s²)" headerClassName="bg-primary"></Column>
                            <Column field="adderss" header="Location" headerClassName="bg-primary"></Column>
                            <Column body={this.timeConvert} header="Duration" headerClassName="bg-primary"></Column>
                            <Column body={this.timeFormat} header="Date & Time" headerClassName="bg-primary"></Column>
                        </DataTable>
                    ) : null}
                </div>
            </div>
        );
    }
}
