import { useEffect, useState, useRef } from "react";
import dbservices from "../../service/dbService";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { fileEventStatus, userFSAccess } from "../../utilities/constant";
import { Toast } from "primereact/toast";
import Unauth from "./components/Unauth";
import { userRoleMap } from "../../utilities/utillFunction";

const MyEvents = () => {
    const toast = useRef(null);
    const menu = useRef(null);
    const [access, setAccess] = useState(null);
    const [myRole, setMyRole] = useState(null);
    const [showProject, setShowProject] = useState(false);
    const [path, setPath] = useState(null);
    const [userType, setUserType] = useState(null);
    const [eventsdata, setEventsData] = useState(null);
    const [eventsdata1, setEventsData1] = useState(null);
    const [eventDetails, setEventDetails] = useState(null);
    const [userComment, setUserComment] = useState("");
    const [state, setState] = useState(fileEventStatus[0]);
    const [param, setParam] = useState("");
    const [show, setShow] = useState(false);
    const [unauthorized, setUnauthorized] = useState(false);
    const service = JSON.parse(localStorage.getItem("service"));
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const serviceId = localStorage.getItem("serviceId");
    const userName = localStorage.getItem("username");
    const userid = localStorage.getItem("uid")

    const items = [
        {
            label: <Tag className="mr-2" style={{backgroundColor: fileEventStatus[0].color}} value="New content uploaded"></Tag>,
            command: () => {
                setState(fileEventStatus[0]);
                changeStatus(fileEventStatus[0]);
            },
        },
        {
            label: <Tag className="mr-2" style={{backgroundColor: fileEventStatus[1].color}} value="1st draft approved/feedback"></Tag>,
            command: () => {
                setState(fileEventStatus[1]);
                changeStatus(fileEventStatus[1]);
            },
        },
        {
            label: <Tag className="mr-2" style={{backgroundColor: fileEventStatus[2].color}} value="2nd draft approved/feedback"></Tag>,
            command: () => {
                setState(fileEventStatus[2]);
                changeStatus(fileEventStatus[2]);
            },
        },
        {
            label: <Tag className="mr-2" style={{backgroundColor: fileEventStatus[3].color}} value="Final version approved/feedback"></Tag>,
            command: () => {
                setState(fileEventStatus[3]);
                changeStatus(fileEventStatus[3]);
            },
        },
        {
            label: <Tag className="mr-2" style={{backgroundColor: fileEventStatus[4].color}} value="1st draft uploaded for review"></Tag>,
            command: () => {
                setState(fileEventStatus[4]);
                changeStatus(fileEventStatus[4]);
            },
        },
        {
            label: <Tag className="mr-2" style={{backgroundColor: fileEventStatus[5].color}} value="2nd draft uploaded for review"></Tag>,
            command: () => {
                setState(fileEventStatus[5]);
                changeStatus(fileEventStatus[5]);
            },
        },
        {
            label: <Tag className="mr-2" style={{backgroundColor: fileEventStatus[6].color}} value="Final version uploaded"></Tag>,
            command: () => {
                setState(fileEventStatus[6]);
                changeStatus(fileEventStatus[6]);
            },
        }
    ];

    useEffect(() => {
        let res = permissions.filter((item) => {
            return userFSAccess.includes(item);
        });

        setAccess(res);
        if (res.length) {
            setMyRole(res[0].split("_")[0]);
            setUserType(res[0].split("_")[0]);
        } else {
            // setMyRole("Admin");
            // setUserType("Admin");
            setUnauthorized(true);
        }

        getEvents();
    }, []);

    useEffect(() => {
        if (param !== "") {
            setEventsData((prv) => {
                return eventsdata1.filter((item) => item.projectName.includes(param));
            });
        }
    }, [param]);

    const getEvents = () => {
        dbservices.getEventsData(serviceId).then((res) => {
            let data = [];
            res.forEach((item) => {
                if (item.hasOwnProperty("projectName")) {
                    data.push(item);
                }
            });

            setEventsData(data);
            setEventsData1(data);
        });
    };

    const getEventDetails = (id, projectName) => {
        dbservices
            .getEventComments(serviceId, userType, projectName)
            .then((res) => {
                let comment = [];
                if (res.length) {
                    res.forEach((item) => {
                        if (item.comment.length) {
                            let temp = item.comment.map((elm) => ({ ...elm, fileName: item.fileName, fs_url: item.fs_url }));
                            comment = [...comment, ...temp];
                        }
                    });
                    
                    let event = eventsdata.find((item) => item._id === id);
                    let data = { ...event, comment: comment.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()) };
                    
                    let status = fileEventStatus.find((item) => item.code === data.action);
                    if(!status) status = fileEventStatus[0]
                    setState(status);
                    setShow(true);

                    setEventDetails(data);
                }
            })
            .catch((error) => toast.current.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 }));
    };
    function changeStatus(status) {
        let payLoad = {
            serviceId: serviceId,
            eventId: eventDetails._id,
            user: userName,
            actor: userType,
            time: moment().format("YYYY-MM-DD HH:mm:ss"),
            action: status.name,
            userId: userid ?? ""
            // comment: userComment,
        };
        dbservices
            .eventUpdate(payLoad)
            .then((res) => {
                // getEvents();
                setEventsData((prv) => {
                    return prv.map((item) => (item._id === eventDetails._id ? { ...item, action: status.name } : item));
                });
            })
            .catch((error) => toast.current.show({ severity: "error", summary: "Somthing went wrong, please try again", detail: "Message Content", life: 3000 }));
    }
    const addComments = () => {
        if (userComment.length) {
            let payLoad = {
                serviceId: serviceId,
                eventId: eventDetails._id,
                user: userName,
                actor: userType,
                time: moment().format("YYYY-MM-DD HH:mm:ss"),
                action: state.name,
                comment: userComment,
                userId: userid ?? ""
            };
            dbservices
                .eventUpdate(payLoad)
                .then((res) => {
                    if (res.status === "ok") {
                        setUserComment("");
                        let com_message = eventDetails.comment;
                        com_message.push({ user: userName, actor: userType, time: moment().format("YYYY-MM-DD HH:mm:ss"), comment: userComment });
                        setEventDetails((prv) => ({
                            ...prv,
                            comment: com_message.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()),
                        }));
                        setEventsData((prv) => {
                            return prv.map((item) => (item._id === eventDetails._id ? { ...item, action: state.name } : item));
                        });
                    }
                })
                .catch((error) => toast.current.show({ severity: "error", summary: "Somthing went wrong, please try again", detail: "Message Content", life: 3000 }));
        } else {
            toast.current.show({ severity: "error", summary: "Comment can't be empty", detail: "Message Content", life: 3000 });
        }
    };

    const openFileLocation = (eventDetails) => {
        if (myRole === "Admin") {
            if (eventDetails.to === "Publisher") {
                setPath(eventDetails.fs_url + service.links[`Publisher_WRITE`]);
            } else if (eventDetails.to === "Artwork") {
                setPath(eventDetails.fs_url + service.links[`Artwork_READ`]);
            } else {
                setPath(eventDetails.fs_url + service.links[`${eventDetails.from}_READ`]);
            }
        } else {
            if (eventDetails.to === myRole) {
                setPath(eventDetails.fs_url + service.links[`${myRole}_READ`]);
            } else {
                setPath(eventDetails.fs_url + service.links[`${myRole}_WRITE`]);
            }
        }

        setShowProject(true);
    };

    const template = (options) => {
        // const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        const className = `${options.className} bg-primary justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <span className={titleClassName}>Event Details</span>
                <button className={`${options.togglerClassName} text-0`} onClick={() => setShow(false)}>
                    <span className="pi pi-times"></span>
                    <Ripple />
                </button>
            </div>
        );
    };

    const commentFormat = (item) => <p>{item.projectName}</p>;
    const dateFormat = (item) => moment(item.modified_at).format("Do, MMM YYYY hh:mm a");
    const statusFormat = (item) => {
        switch (item.action) {
            case "New content uploaded":
                return (
                    <span className="p-tag p-component mr-2" style={{backgroundColor: fileEventStatus[0].color.color}}>
                        <span className="p-tag-value">New content uploaded</span>
                        <span></span>
                    </span>
                );

            case "1st draft approved/feedback":
                return (
                    <span className="p-tag p-component mr-2" style={{backgroundColor: fileEventStatus[1].color.color}}>
                        <span className="p-tag-value">1st draft approved/feedback</span>
                        <span></span>
                    </span>
                );

            case "2nd draft approved/feedback":
                return (
                    <span className="p-tag p-component mr-2" style={{backgroundColor: fileEventStatus[2].color}}>
                        <span className="p-tag-value">2nd draft approved/feedback</span>
                        <span></span>
                    </span>
                );

            case "Final version approved/feedback":
                return (
                    <span className="p-tag p-component mr-2" style={{backgroundColor: fileEventStatus[3].color}}>
                        <span className="p-tag-value">Final version approved/feedback</span>
                        <span></span>
                    </span>
                );

            case "1st draft uploaded for review":
                return (
                    <span className="p-tag p-component mr-2" style={{backgroundColor: fileEventStatus[4].color}}>
                        <span className="p-tag-value">1st draft uploaded for review</span>
                        <span></span>
                    </span>
                );

            case "2nd draft uploaded for review":
                return (
                    <span className="p-tag p-component mr-2" style={{backgroundColor: fileEventStatus[5].color}}>
                        <span className="p-tag-value">2nd draft uploaded for review</span>
                        <span></span>
                    </span>
                );
            
            case "Final version uploaded":
                return (
                    <span className="p-tag p-component mr-2" style={{backgroundColor: fileEventStatus[6].color}}>
                        <span className="p-tag-value">Final version uploaded</span>
                        <span></span>
                    </span>
                );

            default:
                return (
                    <span className="p-tag p-component mr-2" style={{backgroundColor: fileEventStatus[0].color}}>
                        <span className="p-tag-value">New content uploaded</span>
                        <span></span>
                    </span>
                );
        }
    };
    const actionFormat = (item) => <Button icon="pi pi-info-circle" onClick={() => getEventDetails(item._id, item.projectName)} aria-label="Filter" />;

    const tableFileFormat = (item) => {
        if (item.fileName === "") return null;
        return <Button label={item.fileName} className="p-button-info" icon="pi pi-paperclip" onClick={() => openFileLocation(item)}></Button>;
    };


    return !unauthorized ? (
        <>
            <div className="grid">
                <Toast ref={toast} />
                <Dialog header="Project" visible={showProject} style={{ width: "70vw" }} onHide={() => setShowProject(false)}>
                    <div className="grid">
                        <div className="col-12">
                            <iframe title="filestash" src={path} style={{ width: "100%", height: "80vh" }}></iframe>
                        </div>
                    </div>
                </Dialog>
                <div className="col-12">
                    <div className="flex flex-wrap">
                        <h2 className="mb-0">Events({userRoleMap(service, myRole)})</h2>
                        <span className="event-search">
                            <i className="pi pi-search"></i>
                            <input className="search" type="text" value={param} placeholder="Search by project name" onChange={(e) => setParam(e.target.value)} />
                        </span>
                    </div>
                </div>
            </div>
            <div className="grid flex-row-reverse">
                {show && eventDetails && (
                    <div className="md:col-4 col-12">
                        <Panel headerTemplate={template} toggleable>
                            <div className="grid">
                                <div className="col-12">
                                    <div className="event-details-grid">
                                        <Tag className="mr-2" icon="pi pi-user" severity="success" value={userRoleMap(service, eventDetails.from)}></Tag>
                                        <h3 className="event-title">{eventDetails.projectName}</h3>
                                        <div className="flex align-items-center justify-content-between">
                                            {eventDetails.fileName && eventDetails.fileName !== "" ? (
                                                <div className="attach-file flex-1" onClick={() => openFileLocation(eventDetails)}>
                                                    <div className="file">
                                                        <span className="pi pi-paperclip mr-3"></span>
                                                        <span className="title">{eventDetails.fileName}</span>
                                                    </div>
                                                    <span className="pi pi-download download text-primary"></span>
                                                </div>
                                            ) : (
                                                <div className="attach-file flex-1" onClick={() => openFileLocation(eventDetails)}>
                                                    <div className="file">
                                                        <span className="pi pi-folder mr-3"></span>
                                                        <span className="title">{eventDetails.projectName}</span>
                                                    </div>
                                                    <span className="pi pi-download download text-primary"></span>
                                                </div>
                                            )}
                                            <Menu model={items} popup ref={menu} id="popup_menu" />
                                            <Button label={state.name} iconPos="right" icon="pi pi-angle-down" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" style={{ backgroundColor: state.color, color: "#FFFFFF" }} aria-haspopup />
                                        </div>
                                        {/* <div className="event-description my-2">
                                        <p className="mb-0">Description: </p>
                                        <div className="description surface-200 p-2">
                                            <ol>
                                                <li>Voluptate pariatur cupidatat laboris qui tempor</li>
                                                <li>Pariatur cupidatat laboris qui tempor</li>
                                                <li>Laboris qui tempor</li>
                                            </ol>
                                        </div>
                                    </div> */}
                                        <hr />
                                        <div className="event-comments my-2">
                                            <h5>Comments({eventDetails.comment.length})</h5>
                                            <div className="comment-grid">
                                                {eventDetails.comment.length ? (
                                                    <>
                                                        {eventDetails.comment.map((item, index) => {
                                                            if (userType.toLowerCase() === item.actor.toLowerCase()) {
                                                                return (
                                                                    <div className="message outgoing" key={`outgoing-${index}`}>
                                                                        <span className="text-sm">{moment(item.time).format("Do MMM, hh:mm a")}</span>
                                                                        <div className="content">
                                                                            <div className="msg-header flex justify-content-between align-items-start mb-1">
                                                                                <div className="flex align-items-center">
                                                                                    <Avatar label={item.user.charAt(0)} className="mr-2" style={{ backgroundColor: "#2196F3", color: "#ffffff" }} shape="circle" />
                                                                                    <div className="header-label text-left">
                                                                                        <p className="mb-0 text-sm line-height-1 font-medium">{item.user}</p>
                                                                                        <span className="text-xs">{userRoleMap(service, item.actor)}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <span className="text-sm cmt-filename" data-pr-tooltip={item.fileName}>
                                                                                    {item.fileName}
                                                                                </span>
                                                                                <Tooltip target=".cmt-filename" mouseTrack mouseTrackLeft={10} />
                                                                            </div>
                                                                            <div className="msg">{item.comment}</div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return (
                                                                    <div className="message incomming" key={`incomming-${index}`}>
                                                                        <span className="text-sm">{moment(item.time).format("Do MMM, hh:mm a")}</span>
                                                                        <div className="content">
                                                                            <div className="msg-header flex justify-content-between align-items-start mb-1">
                                                                                <div className="flex align-items-center">
                                                                                    <Avatar label={item.user.charAt(0)} className="mr-2" style={{ backgroundColor: "#2196F3", color: "#ffffff" }} shape="circle" />
                                                                                    <div className="header-label text-left">
                                                                                        <p className="mb-0 text-sm line-height-1 font-medium">{item.user}</p>
                                                                                        <span className="text-xs">{userRoleMap(service, item.actor)}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <span className="text-sm cmt-filename" data-pr-tooltip={item.fileName}>
                                                                                    {item.fileName}
                                                                                </span>
                                                                                <Tooltip target=".cmt-filename" mouseTrack mouseTrackLeft={10} />
                                                                            </div>
                                                                            <div className="msg">{item.comment}</div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                    </>
                                                ) : (
                                                    <div className="empty-msg">
                                                        <span className="pi pi-comments text-5xl"></span>
                                                        <p>Comments not added yet.</p>
                                                    </div>
                                                )}
                                            </div>
                                            <hr />
                                            <div className="add-comment">
                                                <textarea cols="30" rows="1" onChange={(e) => setUserComment(e.target.value)} placeholder="Add your comment..." value={userComment}></textarea>
                                                <Button icon="pi pi-send" onClick={addComments} className="p-button-rounded p-button" aria-label="Submit" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </div>
                )}
                <div className={show ? "md:col-8 col-12" : "col-12"}>
                    {eventsdata ? (
                        <DataTable value={eventsdata} paginator={true} rows={10} responsiveLayout="scroll">
                            <column headerClassName="color1-bg" header="To" field="to" body={(row)=>userRoleMap(service, row.to)}></column>
                            <column headerClassName="color1-bg" header="From" field="from" body={(row)=>userRoleMap(service, row.from)}></column>
                            <column headerClassName="color1-bg" header="Project Name" field="comment" body={commentFormat}></column>
                            <column headerClassName="color1-bg" header="File Name" field="fileName" body={tableFileFormat}></column>
                            <column headerClassName="color1-bg" className="white-space-nowrap" header="Time" field="timestamp" body={dateFormat}></column>
                            <column headerClassName="color1-bg" header="Status" body={statusFormat}></column>
                            <column headerClassName="color1-bg" header="Action" field="" body={actionFormat}></column>
                        </DataTable>
                    ) : null}
                </div>
            </div>
        </>
    ) : (
        <Unauth />
    );
};

export default MyEvents;
