import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Toast } from "primereact/toast"
import moment from 'moment'
import aqiService from './AirQualityService'
import { NoiseService } from '../../service/NoiseService'
import { TabPanel, TabView } from 'primereact/tabview'
import writeXlsxFile from 'write-excel-file'
import service from "../../service/dbService";
import { useSelector } from 'react-redux';

const Report = () => {
    const [thingName, setThingName] = useState()
    const [things, setThings] = useState()
    const [dateRange, setDateRange] = useState()
    const [noiseData, setnoiseData] = useState(null)
    const [aqdata, setAQData] = useState(null)
    const [activeTabIndex, setTabIndex] = useState(0)

    const serviceId = localStorage.getItem('serviceId')
    const noiseService = new NoiseService()
    
    let thingsDoc = useRef({})
    let aqiDateCount = useRef({})
    let noiseDateCount = useRef({})
    let fetchedaqi = useRef(false)
    let fetchednoise = useRef(false)
    const toast = useRef()
    const thingNames = useSelector(state => state.thingNameContainer);

    const downloadReport = async () => {
        let header = []
        let arr = []
        let validationRes = validateInput()
        if(validationRes){
            toast.current.show({ severity: 'warn', summary: 'Missing Field', detail: validationRes })
            return
        }
        if (activeTabIndex == 0) {
            header = [[
                {
                    value: 'Date',
                    fontWeight: 'bold'
                },
                {
                    value: 'Time',
                    fontWeight: 'bold'
                },
                {
                    value: 'Minimum (DB)',
                    fontWeight: 'bold'
                },
                {
                    value: 'Maximum (DB)',
                    fontWeight: 'bold'
                },
                {
                    value: 'Average (DB)',
                    fontWeight: 'bold'
                },
                {
                    value: 'Location',
                    fontWeight: 'bold'
                },

            ]
            ]
            noiseData?.forEach((item, index) => {
                let temp = []
                let addLoc = true
                if (index > 0) {
                    let cond = item._id.date === noiseData[index - 1]._id.date
                    if (!cond) {
                        temp.push({ value: item._id.date,  alignVertical:"center",  rowSpan: noiseDateCount.current[item._id.date] })
                    }
                    else {
                        temp.push(null)
                        addLoc =  false
                    }
                }
                else if (index === 0) {
                    temp.push({
                        value: item._id.date,  alignVertical:"center", rowSpan: noiseDateCount.current[item._id.date]
                    })
                }
                temp.push({
                    value: hourTemplate(item)
                }, {
                    value: item.min
                }, {
                    value: item.max
                }, {
                    value: item.avg.toFixed(1)
                })
                addLoc ? temp.push({value: thingName.name, alignVertical:"center", rowSpan: noiseDateCount.current[item._id.date]}) : temp.push(null)
                arr.push(temp)
            })

        }
        else {
            header = [[
                {
                    value: 'Date',
                    fontWeight: 'bold',
                    rowSpan: 2
                },
                {
                    value: 'Time ',
                    fontWeight: 'bold',
                    rowSpan: 2
                },
                {
                    value: 'Pm 2.5',
                    fontWeight: 'bold',
                    span: 3
                },
                null,
                null,
                {
                    value: 'Pm 10',
                    fontWeight: 'bold',
                    span: 3
                },
                null,
                null,
                {
                    value: 'Location',
                    fontWeight: 'bold',
                    rowSpan: 2
                }
            ]
                ,
            [
                null,
                null,
                {
                    value: 'Average',
                    fontWeight: 'bold'
                },
                {
                    value: 'Minimum',
                    fontWeight: 'bold'
                },
                {
                    value: 'Maximum',
                    fontWeight: 'bold'
                },
                {
                    value: 'Average',
                    fontWeight: 'bold'
                },
                {
                    value: 'Minimum',
                    fontWeight: 'bold'
                },
                {
                    value: 'Maximum',
                    fontWeight: 'bold'
                },
                null
            ]
            ]

            aqdata?.forEach((item, index) => {
                let temp = []
                let addLoc = true
                if (index > 0) {
                    let cond = item._id.date === aqdata[index - 1]._id.date
                    if (!cond) {
                        temp.push({ value: item._id.date, alignVertical:"center", rowSpan: aqiDateCount.current[item._id.date] })
                    }
                    else {
                        temp.push(null)
                        addLoc = false
                    }
                }
                else if (index === 0) {
                    temp.push({
                        value: item._id.date, alignVertical:"center", rowSpan: aqiDateCount.current[item._id.date]
                    })
                }
                temp.push({
                    value: hourTemplate(item)
                }, {
                    value: item.avgpm2_5
                }, {
                    value: item.minpm2_5
                }, {
                    value: item.maxpm2_5
                }, {
                    value: item.avgpm10
                }, {
                    value: item.minpm10
                }, {
                    value: item.maxpm10
                })
                addLoc ? temp.push({value: thingName.name, alignVertical:"center", rowSpan: aqiDateCount.current[item._id.date]}) : temp.push(null)
                arr.push(temp)
            })
        }
        let data = [
            ...header,
            ...arr
        ]
        if (!arr.length) {
            toast.current.show({ severity: 'info', summary: 'No Data', detail: "No Data found" });
            return
        }
        await writeXlsxFile(data, {
            //columns, // (optional) column widths, etc.
            fileName: `site_report.xlsx`
        })
    }
    const handleDateChange = (e) => {
        setDateRange(e.value)
        fetchedaqi.current = false
        fetchednoise.current = false
    }

    useEffect(() => {
        let _aqiSpaces = [];
        let _noiseSpaces = []
        let promiseArr=[aqiService.getThingsData(serviceId)];
        if(thingNames.length ===0){
            promiseArr=[aqiService.getThingsData(serviceId),service.getThingNames(serviceId)];
        }

        Promise.all(promiseArr).then((res)=>{
           if(thingNames.length>0){
            let first=[];
                thingNames.map((item)=>{
                    if(!Array.isArray(item)){
                       first.push(item); 
                    }
                })
                res.push(first);
            
           }
            let _thingDetails = res[1];
            res[0].forEach((item) => {
                let index = _thingDetails.findIndex((elm) =>(( elm.spaceName === item.sensorIdentification.itemId) && (item.installationParameters.type === "aqisensor" || item.installationParameters.type === "noiseaqisensor")));
                let index2 = _thingDetails.findIndex((elm) =>(( elm.spaceName === item.sensorIdentification.itemId) && (item.installationParameters.type === "noisesensor" || item.installationParameters.type === "noiseaqisensor")));
                if (index > -1) {
                    _aqiSpaces.push({
                        name: _thingDetails[index].alias || _thingDetails[index].description,
                        code: item.sensorIdentification.itemId,
                    });
                }
                if(index2 > -1){
                    _noiseSpaces.push({
                        name: _thingDetails[index2].alias || _thingDetails[index2].description,
                        code: item.sensorIdentification.itemId,
                    });
                }
            });
            thingsDoc.current = {"noise": _noiseSpaces, "aqi" : _aqiSpaces}
            setThings(_noiseSpaces)
        })
    }, [])

    useEffect(()=>{
        if(activeTabIndex === 0){
            setThings(thingsDoc.current.noise)
        }
        else{
            setThings(thingsDoc.current.aqi)
        }

    },[activeTabIndex])

    useEffect(() => {
        if (dateRange && dateRange[1] && thingName) {
            if (activeTabIndex === 0 && !fetchednoise.current) {
                let startTime = moment(dateRange[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss")
                let endTime = moment(dateRange[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss")
                noiseService.getDownloadData(startTime, endTime, thingName.code, serviceId).then((res) => {
                    countDates(res)
                    setnoiseData(res)
                    fetchednoise.current = true
                })
            }
            else if (activeTabIndex === 1 && !fetchedaqi.current) {
                let startTime = moment(dateRange[0]).startOf("day").format("YYYY-MM-DD")
                let endTime = moment(dateRange[1]).endOf("day").format("YYYY-MM-DD")
                aqiService.aqDownloadData(startTime, endTime, thingName.code, serviceId).then((res) => {
                    countDates(res)
                    setAQData(res)
                    fetchedaqi.current = true
                })
            }
            function countDates(res) {
                aqiDateCount.current = noiseDateCount.current = {}
                res.forEach((item) => {
                    if (activeTabIndex === 1) {
                        if (item._id.date in aqiDateCount.current) {
                            aqiDateCount.current[item._id.date] = aqiDateCount.current[item._id.date] + 1
                        }
                        else {
                            aqiDateCount.current[item._id.date] = 1
                        }

                    }
                    else if (activeTabIndex === 0) {
                        if (item._id.date in noiseDateCount.current) {
                            noiseDateCount.current[item._id.date] = noiseDateCount.current[item._id.date] + 1
                        }
                        else {
                            noiseDateCount.current[item._id.date] = 1
                        }
                    }
                })
            }
        }
    }, [dateRange, thingName, activeTabIndex])

    const hourTemplate = (item) => {
        return moment(item._id.hr, "h").format("hh a") + " - " + moment(item._id.hr, "h").add(1, "h").format("hh a");

    }
    const handleTabChange = (e) => {
        setTabIndex(e.index)
    }

    const handleThingChange = (e) => {
        fetchedaqi.current = false
        fetchednoise.current = false
        setThingName(e.value)
    }
    const validateInput = () =>{
        if(!dateRange || (dateRange && !dateRange[0] || !dateRange[1])) return  "Please Select Date Range"
        if(!thingName) return "Please Select Location"
    }

    const customHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="bg-primary" header="Date" rowSpan={2} className="text-center"></Column>
                <Column headerClassName="bg-primary" header="Hours" rowSpan={2} className="text-center"></Column>
                <Column headerClassName="bg-primary" header="PM 2.5" className="text-center" colSpan={3}></Column>
                <Column headerClassName="bg-primary" header="PM 10" className="text-center" colSpan={3}></Column>
            </Row>
            <Row>
                <Column headerClassName="bg-primary" header="Average" className="text-center" ></Column>
                <Column headerClassName="bg-primary" header="Minimum" className="text-center" ></Column>
                <Column headerClassName="bg-primary" header="Maximum" className="text-center" ></Column>
                <Column headerClassName="bg-primary" header="Average" className="text-center" ></Column>
                <Column headerClassName="bg-primary" header="Minimum" className="text-center" ></Column>
                <Column headerClassName="bg-primary" header="Maximum" className="text-center" ></Column>

            </Row>
        </ColumnGroup>
    )
    return (
        <div className="col-12 grid">
            <Toast ref={toast} />
            <div className='col-12'>
                <TabView activeIndex={activeTabIndex} onTabChange={handleTabChange}>
                    <TabPanel header="Noise Monitoring" >
                     <div className="col-12 grid align-items-center">
                            <div className="field col-12 lg:col-4">
                                <label htmlFor="minmax" className="block">
                                    Select Date Range
                                </label>
                                <Calendar value={dateRange} onChange={(e) => handleDateChange(e)} minDate={new Date(moment().subtract(3, 'months').format("YYYY-MM-DD"))} maxDate={new Date(moment().format("YYYY-MM-DD"))} dateFormat="dd/mm/yy" showIcon selectionMode='range'  className="bg-white w-full" placeholder='Select Date Range'/>
                            </div>
                            <div className="field col-12 lg:col-4">
                                <label htmlFor="minmax" className="block">
                                    Select Location
                                </label>
                                <Dropdown value={thingName} options={things} onChange={(e) => handleThingChange(e)} className="bg-white w-full" optionLabel="name" placeholder="Select a Location" />
                            </div> 
                            <div className="col-4 text-right">
                                <Button label="Download" disabled={things && !things.length} onClick={() => downloadReport()} icon="pi pi-download" />
                            </div>                       
                        </div>
                        
                        <div className='col-12 grid'>
                            <div className="card w-full ">
                                <DataTable value={noiseData} showGridlines responsiveLayout='scroll' rows={10} paginator>
                                    <Column field='_id.date' header="Date" headerClassName='bg-primary' body={(rowData, { rowIndex }) => {
                                        if (rowIndex === 0 || rowData._id.date !== noiseData[rowIndex - 1]._id.date) {
                                            return (
                                                <>{rowData._id.date}</>
                                            );
                                        }
                                        return null;
                                    }}></Column>
                                    <Column field='_id.hr' header="Time" body={(item) => hourTemplate(item)} headerClassName='bg-primary'></Column>
                                    <Column field='min' header="Minimum (DB)" headerClassName='bg-primary'></Column>
                                    <Column field='max' header="Maximum (DB)" headerClassName='bg-primary'></Column>
                                    <Column field='avg' header="Average (DB)" body={(item) => item.avg.toFixed(1)} headerClassName='bg-primary'></Column>
                                </DataTable>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Air Quality Monitoring">
                        <div className="col-12 grid align-items-center">
                            <div className="field col-12 lg:col-4">
                                <label htmlFor="minmax" className="block">
                                    Select Date Range
                                </label>
                                <Calendar value={dateRange} onChange={(e) => handleDateChange(e)} minDate={new Date(moment().subtract(3, 'months').format("YYYY-MM-DD"))} maxDate={new Date(moment().format("YYYY-MM-DD"))} dateFormat="dd/mm/yy" showIcon selectionMode='range' className="bg-white w-full" placeholder="Select Date Range"/>
                            </div>
                            <div className="field col-12 lg:col-4">
                                <label htmlFor="minmax" className="block">
                                    Select Location
                                </label>
                                <Dropdown value={thingName} options={things} onChange={(e) => handleThingChange(e)} className="bg-white w-full" optionLabel="name" placeholder="Select a Location" />
                            </div>
                            <div className="col-4 text-right">
                                <Button label="Download" disabled={things && !things.length} onClick={() => downloadReport()} icon="pi pi-download" />
                            </div>  
                        </div>
                        <div className='col-12 grid'>
                            <div className="card w-full ">
                                <DataTable value={aqdata} showGridlines responsiveLayout='scroll' rows={10} headerColumnGroup={customHeader} paginator>
                                    <Column headerClassName="bg-primary" className="text-center" field='_id.date' body={(rowData, { rowIndex }) => {
                                        if (rowIndex === 0 || rowData._id.date !== aqdata[rowIndex - 1]._id.date) {
                                            return (
                                                <>{rowData._id.date}</>
                                            );
                                        }
                                        return null;
                                    }}
                                    ></Column>
                                    <Column headerClassName="bg-primary" className="text-center" field='_id.hr' body={(item) => hourTemplate(item)}></Column>
                                    <Column headerClassName="bg-primary" className="text-center" field='avgpm2_5'></Column>
                                    <Column headerClassName="bg-primary" className="text-center" field='minpm2_5'></Column>
                                    <Column headerClassName="bg-primary" className="text-center" field='maxpm2_5'></Column>
                                    <Column headerClassName="bg-primary" className="text-center" field='avgpm10'></Column>
                                    <Column headerClassName="bg-primary" className="text-center" field='minpm10'></Column>
                                    <Column headerClassName="bg-primary" className="text-center" field='maxpm10'></Column>
                                </DataTable>
                            </div></div>
                    </TabPanel>
                </TabView></div>

        </div>
    )
}

export default Report