import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Toast } from "primereact/toast"
import moment from 'moment'
import indoorService from './indoorService'
import * as XLSX from "xlsx";
import service from "../../service/dbService";
import { useSelector } from 'react-redux';

const IndoorReport = () => {
    const [thingName, setThingName] = useState()
    const [things, setThings] = useState()
    const [dateRange, setDateRange] = useState()
    const [humidityData, setHumidityData] = useState([])
    const [disableBtn, setDisableBtn] = useState(false)
    const toast = useRef()
    const thingNames = useSelector(state => state.thingNameContainer);
    const serviceId = localStorage.getItem('serviceId')

    const downloadReport = () => {
        setDisableBtn(true)
        let validationRes = validateInput()
        if(validationRes){
            toast.current.show({ severity: 'warn', summary: 'Missing Field', detail: validationRes })
            setDisableBtn(false)
            return
        }
        if(humidityData.length){
            const humidDownloadData = humidityData.map((item)=>{
                return {
                    "Date" : item.date,
                    "Time" : hourTemplate(item),
                    "Minimum" : item.min_humidity,
                    "Maximum" : item.max_humidity,
                    "Average" : item.avg_humidity
                }
            })
            let ws = XLSX.utils.json_to_sheet(humidDownloadData);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, "humidity_report.xlsx");
        }
        else{
            toast.current.show({ severity: 'info', summary: 'No Data', detail: "No Data found" });
        }
        setDisableBtn(false)
    }

    useEffect(() => {
        let _humiditySpaces = []
        let promiseArr = [indoorService.getThingsData(serviceId)];
        if (thingNames.length === 0) {
            promiseArr.push(service.getThingNames(serviceId));
        }
        Promise.all(promiseArr).then((res) => {
            if (thingNames.length > 0) {
                let first = [];
                thingNames.map((item) => {
                    if (!Array.isArray(item)) {
                        first.push(item);
                    }
                })
                res.push(first);

            }
            let _thingDetails = res[1];
            res[0].forEach((item) => {
                let index = _thingDetails.findIndex((elm) =>(( elm.spaceName === item.sensorIdentification.itemId) && (item.installationParameters.type === "temperature_and_humidity_sensor")));
                if (index > -1) {
                    _humiditySpaces.push({
                        name: _thingDetails[index].alias || _thingDetails[index].description,
                        code: item.sensorIdentification.itemId,
                    });
                }
            })
            setThings(_humiditySpaces)
        })
    }, [])

        useEffect(() => {
            if (dateRange && dateRange[1] && thingName) {
            let startTime = moment(dateRange[0]).startOf("day").format("YYYY-MM-DD")
            let endTime = moment(dateRange[1]).endOf("day").format("YYYY-MM-DD")
            indoorService.getDownloadData(startTime, endTime, thingName.code, serviceId).then((res) => {
                setHumidityData(res)
            })
        }
        }, [dateRange, thingName])

        const hourTemplate = (item) => {
            return moment(item.hr, "h").format("hh a") + " - " + moment(item.hr, "h").add(1, "h").format("hh a");

        }
        const validateInput = () => {
            if (!dateRange || (dateRange && !dateRange[0] || !dateRange[1])) return "Please Select Date Range"
            if (!thingName) return "Please Select Location"
        }
        return (
            <div className="col-12 grid">
                <Toast ref={toast} />
                <div className='col-12'>
                    <div className="col-12 grid align-items-center">
                        <div className="field col-12 lg:col-4">
                            <label htmlFor="minmax" className="block">
                                Select Date Range
                            </label>
                            <Calendar value={dateRange} onChange={(e) => setDateRange(e.value)} minDate={new Date(moment().subtract(3, 'months').format("YYYY-MM-DD"))} maxDate={new Date(moment().format("YYYY-MM-DD"))} dateFormat="dd/mm/yy" showIcon selectionMode='range' className="bg-white w-full" placeholder='Select Date Range' />
                        </div>
                        <div className="field col-12 lg:col-4">
                            <label htmlFor="minmax" className="block">
                                Select Location
                            </label>
                            <Dropdown value={thingName} options={things} onChange={(e) => setThingName(e.value)} className="bg-white w-full" optionLabel="name" placeholder="Select a Location" />
                        </div>
                        <div className="col-4 text-right">
                            <Button label="Download" disabled={disableBtn} onClick={() => downloadReport()} icon="pi pi-download" />
                        </div>
                    </div>

                    <div className='col-12 grid'>
                        <div className="card w-full ">
                            <DataTable value={humidityData} showGridlines responsiveLayout='scroll' rows={10} paginator>
                                <Column field='date' header="Date" headerClassName='bg-primary' body={(rowData, { rowIndex }) => {
                                    if (rowIndex === 0 || rowData.date !== humidityData[rowIndex - 1].date) {
                                        return (
                                            <>{rowData.date}</>
                                        );
                                    }
                                    return null;
                                }}></Column>
                                <Column field='hr' header="Time" body={(item) => hourTemplate(item)} headerClassName='bg-primary'></Column>
                                <Column field='min_humidity' header="Minimum" headerClassName='bg-primary'></Column>
                                <Column field='max_humidity' header="Maximum" headerClassName='bg-primary'></Column>
                                <Column field='avg_humidity' header="Average" body={(item) => item.avg_humidity.toFixed(1)} headerClassName='bg-primary'></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

export default IndoorReport