import moment from "moment";
import React, { Component } from "react";
import { Image } from "primereact/image";
import { ToggleButton } from "primereact/togglebutton";
import { VehicleSrvice } from "../../service/VehicleService";
import { mapKey } from "../../utilities/constant";
import VehicleDetailsObd from "./VehicleDetailsObd";
import { convertValue } from "../../utilities/unitConversion";
import { connect } from "react-redux";
import { dispatchAction } from "../../utilities/utillFunction";
import { InputText } from "primereact/inputtext";
const svgMarkup =
    '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path style="fill:#01010100; stroke:none;" d="M0 0L0 32L32 32L32 0L0 0z"/><path style="fill:#e84d3d; stroke:none;" d="M15 26L17 26C19.6885 21.918 23.6112 17.9434 24.5918 13C26.4889 3.43692 13.1676 -2.78313 8.17901 6.1088C4.21305 13.1779 11.2793 20.6202 15 26z"/><path style="fill:#ffffff; stroke:none;" d="M15.2593 8.05941C12.4857 8.60038 13.1027 13.3731 15.9954 12.963C19.1715 12.5127 18.4131 7.44428 15.2593 8.05941z"/><path style="fill:#f48221; stroke:none;" d="M24 7L25 8L24 7M10 19L11 20L10 19M19 22L20 23L19 22z"/><path style="fill:#c0a7a5; stroke:none;" d="M9 23L10 24L9 23z"/><path style="fill:#bcc5c800; stroke:none;" d="M17 27L15 27C12.816 23.4744 3.67966 22.9556 6.17593 27.6242C8.18558 31.3828 25.24 31.7858 26.0787 26.9051C27.0422 21.2976 18.5828 24.1389 17 27z"/><path style="fill:#c0a7a5; stroke:none;" d="M13 23L14 24L13 23M18 23L19 24L18 23z"/><path style="fill:#f48221; stroke:none;" d="M14 24L15 25L14 24M17 24L18 25L17 24z"/><path style="fill:#c0a7a500; stroke:none;" d="M14 25L15 26L14 25M4 26L5 27L4 26M15.6667 26.3333L16.3333 26.6667L15.6667 26.3333M27 26L28 27L27 26z"/></svg>';

class VehicleOBDList extends Component {
    mapRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            isWeatherShow: false,
            H: null,
            map: null,
            markers: null,
            vehicles: null,
            duplicateVehicles: null,
            detailsReport: false,
            make: null,
            model: null,
            thingName: null,
            date: null,
            geofences: [],
            group: null,
            greenDrivingScore: null,
            weatherGroup: null,
            weatherBubbles: null,
            region: null,
            value1: "",
        };
        this.vehicleNumberData = [];
        this.serviceID = localStorage.getItem("serviceId");
        this.vehicleService = new VehicleSrvice();
        this.ws = new WebSocket(process.env.REACT_APP_META_WEB_SOCKET + "/" + this.serviceID);
        this.ws.onmessage = this.onmessage;
        this.serviceDetails = JSON.parse(localStorage.getItem("service"));
        this.unitSys = this.serviceDetails.region.includes("us") ? "FPS" : "MKS";
        this.toggleWeather = this.toggleWeather.bind(this);
    }

    componentWillUnmount = () => {
        this.ws.close();
    };

    onmessage = async (evt) => {
        let evtData = JSON.parse(evt.data);
        let thingName = [];
        evtData.forEach((item) => {
            thingName.push(item.thingName);
        });

        if (!(this.vehicleNumberData && this.vehicleNumberData.length)) {
            this.vehicleNumberData = await this.vehicleService.getVehicleNumber(this.serviceID);
        }
        evtData = evtData.map((elm) => {
            let index = this.vehicleNumberData.findIndex((item) => item.fleetName === elm.thingName);
            return {
                ...elm,
                vehicleNumber: this.vehicleNumberData[index]?.vehicle?.vehicleNumber,
            };
        });
        let evtLength = evtData.length;
        if (evtData && evtData.length) {
            evtData.sort((a,b)=> {if(a.timestamp < b.timestamp) return 1; else if(a.timestamp > b.timestamp) return -1; else return 0} )
            if (this.state.map) {
                let objects = this.state.group.getObjects();

                evtData.forEach((e) => {
                    if (e.thingName) {
                        let objectIndex = objects.findIndex((o) => o.data.thingName === e.thingName);

                        if (objectIndex > -1) {
                            objects[objectIndex].setGeometry({ lat: e.latitude, lng: e.longitude });

                            let boundObj = { bounds: this.state.group.getBoundingBox() };
                            if (evtLength === 1) boundObj.zoom = 12;
                            this.state.map.getViewModel().setLookAtData(boundObj);
                            this.setState({
                                vehicles: evtData,
                                duplicateVehicles: evtData,
                            });
                        }
                    }
                });
            } else {
                this.getGeofences(evtData);
            }
        }
    };

    getGeofences = (evtData) => {
        this.vehicleService.getGeoFences().then((res) => {
            this.setState(
                {
                    vehicles: evtData,
                    geofences: res,
                    duplicateVehicles: evtData,
                },
                () => {
                    this.getMap(evtData);
                }
            );
        });
    };

    getMap = async (vehicles) => {
        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(this.mapRef.current, defaultLayers.vector.normal.map, {
            center: { lat: 37.0902, lng: -95.7129 },
            zoom: 5,
            pixelRatio: window.devicePixelRatio || 1,
        });
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        const ui = H.ui.UI.createDefault(map, defaultLayers);
        window.addEventListener("resize", () => map.getViewPort().resize());

        let markers = [];
        let group = new H.map.Group();
        // weatherGroup = new H.map.Group();
        for (let i = 0; i < vehicles.length; i++) {
            let mark;
            let station = { lat: vehicles[i].latitude, lng: vehicles[i].longitude, ts: vehicles[i].ts, speed: vehicles[i].speed, ignition: vehicles[i].ignition };
            mark = this.getStrengthMarker(H, map, station, ui, vehicles[i], this.unitSys);
            markers.push(mark);

            // let weather = await this.vehicleService.getCurrentWeather({ lat: vehicles[i].latitude, lng: vehicles[i].longitude }, weatherUnitSys);
            // console.log(weather);
            // let weatherMapMarks = this.getWeatherMarkrt(H, station, ui, weather);
            // weatherMarker.push({ ...weather, pos: { lat: vehicles[i].latitude, lng: vehicles[i].longitude } });
        }
        this.addGeoFencesToMap(H, group);

        group.addObjects(markers);
        // weatherGroup.addObjects(weatherMarker);
        map.addObject(group);
        // map.addObject(weatherGroup);
        // get geo bounding box for the group and set it to the map
        map.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
            // zoom: 12
        });
        this.setState({ H, map, group, weatherGroup: [] });
    };

    addGeoFencesToMap = (H, group) => {
        if (this.state.geofences && this.state.geofences.length) {
            this.state.geofences.forEach((g) => {
                let polygon = H.util.wkt.toGeometry(g.data);
                group.addObject(new H.map.Polygon(polygon, { style: { strokeColor: "rgba(128, 0, 0, 0.6)" }, data: g.name }));
            });
        }
    };

    getStrengthMarker = (H, map, station, ui, data, unit) => {
        let bearsIcon = new H.map.Icon(svgMarkup);
        let marker = new H.map.Marker(station, { icon: bearsIcon, data: data });
        let bubble;

        marker.addEventListener(
            "tap",
            function (evt) {
                let locData = evt.target.getData();
                bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                    content: `<div id="vehicle-content"><div id="vehicle-info"><div id="info">${locData.vehicleNumber.toUpperCase()} </div><div id="info">${"Speed"}: ${convertValue(locData.speed, "speed", unit)} </div></div></div>`,
                });
                ui.addBubble(bubble);
            },
            false
        );
        return marker;
    };

    hendelVehicleClick = (item) => {
        this.state.map.setCenter({ lat: item.latitude, lng: item.longitude });
        this.state.map.setZoom(8);
    };

    showVehicleDetail = (item) => {
        this.setState({
            detailsReport: true,
            make: item.make,
            model: item.model,
            thingName: item.thingName,
            vehicleNumber: item.vehicleNumber,
            date: moment(item.ts, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
        });
    };

    reportBack = () => {
        this.setState({ detailsReport: false }, () => {
            this.getMap(this.state.vehicles);
        });
    };

    toggleWeather = async (value) => {
        const { region } = this.serviceDetails;
        let weatherUnitSys = region === "us" ? "Imperial" : "Metric";
        const vehicles = this.state.vehicles;

        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();
        const ui = H.ui.UI.createDefault(this.state.map, defaultLayers);
        let bubbles = [];
        let bubble;
        if (value) {
            for (let i = 0; i < vehicles.length; i++) {
                let weather = await this.vehicleService.getCurrentWeather({ lat: vehicles[i].latitude, lng: vehicles[i].longitude }, weatherUnitSys);
                if (weather && weather.weather.length > 0) {
                    bubble = new H.ui.InfoBubble(
                        { lat: vehicles[i].latitude, lng: vehicles[i].longitude },
                        {
                            content: `<div id="vehicle-content" style="cursor: pointer; width: 160px"><div class="iconimgbox">
                                ${
                                    weather.weather[0].main === "Thunderstorm"
                                        ? `<img src="images/storm.png" />`
                                        : weather.weather[0].main === "Drizzle"
                                        ? `<img src="images/drizzle.png" />`
                                        : weather.weather[0].main === "Rain"
                                        ? `<img src="images/heavy-rain.png" />`
                                        : weather.weather[0].main === "Snow"
                                        ? `<img src="images/snowflake.png" />`
                                        : weather.weather[0].main === "Clouds"
                                        ? `<img src="images/cloudy.png" />`
                                        : weather.weather[0].main === "Clear"
                                        ? `<img src="images/sun.png" />`
                                        : weather.weather[0].main === "Mist"
                                        ? `<img src="images/mist.png" />`
                                        : weather.weather[0].main === "Mist"
                                        ? `<img src="images/smoke.png" />`
                                        : weather.weather[0].main === "Haze"
                                        ? `<img src="images/haze.png" />`
                                        : weather.weather[0].main === "Dust"
                                        ? `<img src="images/sand.png" />`
                                        : weather.weather[0].main === "Fog"
                                        ? `<img src="images/haze.png" />`
                                        : weather.weather[0].main === "Sand"
                                        ? `<img src="images/sand.png" />`
                                        : weather.weather[0].main === "Tornado"
                                        ? `<img src="images/hurricane.png" />`
                                        : `<img src="images/haze.png" />`
                                }
                            </div><div class="wathervalue"><p class="cloudbold"><span>${weather.weather[0].main} (${weather.weather[0].description})</span></p><p class="m-0"><span style="font-size: 18px">${weather.main.temp}${
                                region === "us" ? " °F" : " °C"
                            }</span></p><p class="m-0" style="font-size: 11px">Feels like ${weather.main.feels_like} ${region === "us" ? " °F" : " °C"}.</p><p class="m-0" style="margin-top: 5px"><span class="flex justify-content-center align-items-center"><img src="images/thermometer.png" />${
                                weather.main.humidity
                            }%, <img src="images/wind.png" />${weather.main.humidity}${region === "us" ? " mi/h" : " m/s"}</span></p></div></div>`,
                        }
                    );
                    bubbles.push(bubble);
                } else {
                    bubble = new H.ui.InfoBubble(
                        { lat: vehicles[i].latitude, lng: vehicles[i].longitude },
                        {
                            content: `<div id="vehicle-content" style="cursor: pointer; width: 160px">
                            <div class="wathervalue"><p class="cloudbold"><span>No weather data found.</span></p></div></div>`,
                        }
                    );
                    bubbles.push(bubble);
                }
            }
            bubbles.forEach((bubble) => {
                ui.addBubble(bubble);
            });
            this.setState({ weatherBubbles: bubbles });
        } else {
            this.state.weatherBubbles.forEach((bubble) => {
                bubble.close();
            });
        }
        this.setState({ isWeatherShow: value });
    };

    handleSeaarch = (e) => {
        let originalData = this.state.vehicles;
        if (e.target.value !== "" || e.target.value !== null) {
            let filterData = this.state.duplicateVehicles.filter((item) => {
                return Object.values(item).join("").toLowerCase().includes(e.target.value.toLowerCase());
            });
            this.setState({
                vehicles: filterData,
                value1: e.target.value
            });
        } else {
            this.setState({
                vehicles: originalData,
                value1: e.target.value
            });
        }
    };

    render = () => {
        return this.state.detailsReport ? (
            <VehicleDetailsObd make={this.state.make} model={this.state.model} vehicleNumber={this.state.vehicleNumber} thingName={this.state.thingName} date={this.state.date} reportBack={this.reportBack} geofences={this.state.geofences} {...this.props} />
        ) : (
            <div className="grid flex-row-reverse m-0">
                <div className="col-12 flex justify-content-between flex-wrap">
                    <h3 style={{ margin: "0", textAlign: "center", padding: "10px 0" }}>Current Status & Location</h3>
                    <ToggleButton checked={this.state.isWeatherShow} onChange={(e) => this.toggleWeather(e.value)} onLabel="Hide Weather" offLabel="Show Weather" className="w-full sm:w-10rem w-10rem" aria-label="Confirmation" />
                </div>
                <div className="col-12 md:col-6 lg:col-8">
                    <div className="card p-0" style={{ position: "relative" }}>
                        <div ref={this.mapRef} className="list_map_grid"></div>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-4">
                    <span className="p-input-icon-left w-12">
                        <i className="pi pi-search" />
                        <InputText value={this.state.value1} onChange={(e) => this.handleSeaarch(e)} placeholder="search" className="w-12 mb-2" />
                    </span>
                    <div className="vehicle_list_grid">
                        {this.state.vehicles
                            ? this.state.vehicles.map((item) => (
                                  <div className="card report_opt_card mb-3" key={item.thingName}>
                                      <div className="flex">
                                          <div className="">
                                              <img className="mr-2" src={process.env.REACT_APP_IMAGE_STATIC_URL + "/" + this.serviceID + "/" + item.thingName + ".jpg"} onError={e=>{e.target.src="/images/car-solid.svg" ;e.onerror=null}} alt="Image" width="65" height="65" preview />
                                          </div>
                                          <div className="flex-1" style={{ fontSize: "1.1rem" }} onClick={() => this.showVehicleDetail(item)}>
                                              <p className="font-medium mb-0 ">
                                                  License Plate No: <font className="text-blue-500">{item.vehicleNumber?.toUpperCase()} </font>
                                              </p>
                                              <p className="text-md font-medium mb-0">
                                                  Make: <span className="text-blue-500 mr-3 uppercase">{item.make}</span>
                                                  Model: <span className="text-blue-500 mr-3 uppercase">{item.model}</span>
                                              </p>
                                              <p className="text-md font-medium mb-0">
                                                  Ignition: <span className={`${item.ignition === 1 ? "text-green-500" : "text-pink-600"} mr-3`}>{item.ignition === 1 ? "ON" : "OFF"}</span>
                                                  Speed: <span className="text-green-500">{convertValue(item.speed, "speed", this.unitSys)}</span>
                                                  {/* {this.props.fuel ? <span className="ml-3">Fuel Level: <span className="text-green-500">{90.45}</span></span>: null} */}
                                              </p>
                                              {this.props.fuel ? (
                                                  <p className="text-md font-medium mb-0">
                                                      Fuel Level: <span className="text-green-500">{item.fuellevel + " Ltr"}</span>
                                                  </p>
                                              ) : null}
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : null}
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return { unit: state.changeUnit };
};
export default connect(mapStateToProps, { dispatchAction })(VehicleOBDList);
