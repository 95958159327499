import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { chartHeadingStyle } from "../../utilities/constant";

const ScoreDitribution = ({ chartData }) => {
    const [state, setState] = useState(null);

    useEffect(() => {
        const option = {
            chart: {
                type: "pie",
            },
            title: {
                text: "",
                align: "left",
                style: chartHeadingStyle,
            },
            accessibility: {
                announceNewData: {
                    enabled: true,
                },
                point: {
                    valueSuffix: "",
                },
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: "{point.name}: {point.y:.1f}",
                    },
                },
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b>: {point.y}",
                        style: {
                            fontSize: "14px",
                        },
                    },
                    showInLegend: true,
                },
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span> <b>{point.y:.2f}</b> of total<br/>',
            },
            series: [
                {
                    name: "",
                    colorByPoint: true,
                    data: [],
                },
            ],
            drilldown: {
                series: [],
            },
        };
        option.title.text = chartData.title;
        option.series[0].data = chartData.seriesData;
        option.drilldown.series = chartData.drilldownData;
        setState(option);
    }, []);

    return state ? <HighchartsReact highcharts={Highcharts} options={state} /> : null;
};

export default ScoreDitribution;
