import React, { useState, useEffect,useRef } from 'react'
import { Button } from 'primereact/button'
import axios from "axios"
import Upload from '../../components/Upload'
import ShowPlazaDetails from '../../components/ShowPlazaDetails'
import { Dropdown } from 'primereact/dropdown'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { plazaServices } from './plazaReportService'

const PlazaFileUpload = () => {
    const [file, setFile] = useState()
    const [fileName, setFileName] = useState([])
    const [infoMsg, setInfoMsg] = useState()
    const [disableBtn, setDisableBtn] = useState(true)
    const [plazas, setPlazas] = useState([])
    const [newAddedFile, setNewAddedFile] = useState({})
    const [selectedPlaza, setSelectedPlaza] = useState(null)
    const [fileUploadMsg, setFileUploadMsg] = useState("")
    const thingNames = useSelector(state => state.thingNameContainer);
    let thingsArr = thingNames.length > 0 ? thingNames[thingNames.length-1]: [];

    const permissions = JSON.parse(localStorage.getItem("permissions"))
    const isBankUser = permissions.some((item)=> (item === "AXIS" || item === "ICICI"))

    const serviceId = localStorage.getItem("serviceId")
    const userId = localStorage.getItem("uid")
    const user = localStorage.getItem("email")
    const acceptedFormat = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD")
    const axisPlazas = useRef([])
    const jwtToken = localStorage.getItem("token");
    
    useEffect(()=> {
        let payload = {
            filter: {
                uploadDate : yesterday,
                //user: localStorage.getItem("email"),
                ...(thingsArr.length && {plazaId: {$in : thingsArr}})
            }
        }
        let filterPayload = {
            filter: {
            },
            
        }
        let promiseArr = []
        if(!thingsArr.length) promiseArr.push(axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId +"/nq-spaces", {}))

        Promise.all([axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId +"/nq-plaza-file-updates",payload), ...promiseArr,plazaServices.general(serviceId, filterPayload, "nq-plaza-master")]).then((res)=>{
            let filteredThings = thingsArr.length ? thingNames.slice(0,thingNames.length-1) : res[1].data;
            let zipArr=[];
            filteredThings.map((item)=>{
                zipArr.push(item.location.zip)
            })
            let tempFilter=[];
            res[res.length-1].map((item)=>{
                if(zipArr.includes(item.zip))tempFilter.push(item);
            })
            let a=filteredThings;
            filteredThings=tempFilter;
            filteredThings.map((item, index) => {
                if(item.CCH ===  "Axis"){
                    axisPlazas.current.push(item.PlazaCode)
                }
                filteredThings[index].showName = item.PlazaName + " " + item.PlazaCode
            })
            if(filteredThings &&  filteredThings.length === 1)  {
                setSelectedPlaza(filteredThings[0])
            }
            if(!thingsArr.length){
                filteredThings.forEach((item)=>{
                    thingsArr.push(item.spaceName)
                })
            }

            setPlazas(filteredThings)
            
            if ((res[0].data && res[0].data.length)) {
                    let messageSet = false
                    thingsArr.forEach((thing) => {
                        if (!messageSet) {
                            let findRes = res[0].data.filter(item => item.plazaId === thing)
                            if (!findRes.length && !isBankUser) {
                                messageSet = true
                                setFileUploadMsg("File Upload Incomplete")
                            }
                            else {
                                if (axisPlazas.current.includes(thing) && !(findRes.length === 2)) {
                                    let tollMissing = findRes[0] ? findRes[0].fileType === "bank" : ""
                                    if(tollMissing){
                                        !isBankUser && setFileUploadMsg(`Toll File missing`)
                                    }
                                    else{
                                        let text = isBankUser ? `File missing` : `Bank File missing`
                                        setFileUploadMsg(text)  
                                    } 
                                }
                            }
                        }
                    })
                    if(messageSet && isUploadNotOnTime()) setFileUploadMsg("File For Today Not Uploaded in Given Time")
            }
            else{
                if(isUploadNotOnTime()) setFileUploadMsg("File For Today Not Uploaded in Given Time")
                else setFileUploadMsg("File Upload Incomplete")
            }
            })
    },[])


    const handleFileUpload = async () =>{
        
        let validateInputRes = validateInput()
        if(validateInputRes){
            setInfoMsg(validateInputRes)
            return 
        }
        setDisableBtn(true)
        let formData = new FormData()
        isBankUser ? formData.append("fileType", "bank") : formData.append("fileType", "toll")
        formData.append("serviceId", serviceId);
        formData.append("file", file)
        formData.append("plazaId", selectedPlaza.PlazaCode)
        formData.append("userId", userId)
        formData.append("user", user)

        let headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + jwtToken
        };
        let uploadRes = await axios.post(process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL + "/upload-plaza-file", formData,{ headers: headers})
        uploadRes =  uploadRes.data
        if (uploadRes && uploadRes.success && uploadRes.responseCode === 200) {
            let object = {};
            formData.forEach(function (value, key) {
                object[key] = value;
            });
            object.uploadDate = yesterday
            setNewAddedFile(object)
            setInfoMsg("File uploaded succesfully");
            setFile(null)
            setFileName(null)
        } else {
            let errMsg =  (uploadRes && uploadRes.message) ? uploadRes.message : "Something went wrong"
            setInfoMsg(errMsg);
            setDisableBtn(false)
        }
    }
    const validateInput = () =>{
        if((!selectedPlaza && !selectedPlaza?.PlazaCode)) return "Please Select Plaza"
    }
    const handleChange = (e) =>{
        if(e.target.files.length > 1){
            let files = []
            let fileNames = []
            Array.from(e.target.files).map(item=> {
                files.push(item)
                fileNames.push(item.name)
            })
            setFileName(fileNames)
            setFile(files)
        }
        else{
            setFile(e.target.files[0])
            setFileName([e.target.files[0].name])
        }
        let todisableres = isUploadNotOnTime()
        setDisableBtn(todisableres)
    }

    const isUploadNotOnTime = () =>{
        let startTime = moment().startOf("day").add(5,"hours").format("YYYY-MM-DD HH:mm:ss")
        let endTime = moment().startOf("day").add(8,"hours").format("YYYY-MM-DD HH:mm:ss")
        let currentTime = moment().format("YYYY-MM-DD HH:mm:ss")
        /*if((currentTime > endTime) || (currentTime < startTime)){
            return true
        }
        else*/ return false
    }
  return (
      <>
          <h2 style={{ textAlign: "center" }}>{isBankUser ? "Bank" : "Toll Plaza"} File Upload</h2>
          {fileUploadMsg && <div className='p-3 shadow-5 mb-3 flex align-items-center' style={{width : "100%", backgroundColor:"#ed542e", color : "white"}}><i className="pi pi-info-circle mr-2"></i><p>{isBankUser ? "Bank " : "Toll Plaza " } {fileUploadMsg}</p></div>}
          <div className='col-12 lg:col-3'>
              <label className='text-lg'>Select Plaza</label>
              <Dropdown className="w-full mt-1 surface-0" value={selectedPlaza} onChange={(e) => { setSelectedPlaza(e.value) }} options={plazas} disabled={plazas.length < 2} optionLabel="showName" placeholder="Select Plaza" />
          </div>
          <Upload handleUpload={handleFileUpload} accept={acceptedFormat} onchange={handleChange} fileName={fileName && fileName.join(", ")} error={infoMsg}>
              <Button className='text-xl' label="Upload" onClick={handleFileUpload} disabled={disableBtn} />
          </Upload>
          <ShowPlazaDetails plazas={plazas} plazaAccess={thingsArr} newAddedFile={newAddedFile}/>
      </>
  )
}

export default PlazaFileUpload