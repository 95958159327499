import axios from "axios";
async function getTotalCumulativeSalesAndRevenue(serviceID) {
    const query = {
        operation: "aggregate",
        aggregate: [
            {
                $group: {
                    _id: null,
                    totalSalesINR: { $sum: "$salesINR" },
                    totalSalesUSD: { $sum: "$salesUSD" },
                    totalCommINR: { $sum: "$commINR" },
                    totalCommUSD: { $sum: "$commUSD" },
                },
            },
        ],
    };

    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/orders", query);
    return res.data;
}

async function getTotalRevenueShare(serviceID) {
    const query = {
        operation: "aggregate",
        aggregate: [
            {
                $group: {
                    _id: null,
                    totalCommINR: { $sum: "$commINR" },
                    totalCommUSD: { $sum: "$commUSD" },
                },
            },
        ],
    };
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/orders", query);
    return res.data;
}

async function getSalesForRangeUSD(serviceID, start, end) {
    const query = {
        operation: "aggregate",
        aggregate: [
            {
                $match: {
                    nqDt: {
                        $gte: start,
                        $lte: end,
                    },
                },
            },
            {
                $group: {
                    _id: null,
                    totalSalesINR: { $sum: "$salesINR" },
                    totalCommINR: { $sum: "$commINR" },
                    totalSalesUSD: { $sum: "$salesUSD" },
                    totalCommUSD: { $sum: "$commUSD" },
                    totalQty: {$sum: "$totalQuantity"}

                },
            },
        ],
    };
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/orders", query);
    return res.data;
};

async function getRangeReport(serviceID, start, end) {
    const query = {
        operation: "aggregate",
        aggregate: [
            {
                $match: {
                    nqDt: {
                        $gte: start,
                        $lte: end,
                    },
                },
            },
            {
                $group: {
                    _id: "$isbn", channel: { "$addToSet": "$channel"},
                    totalSalesUSD: { $sum: "$salesUSD" },
                    qtySold: { $sum: "$totalQuantity" },
                    totalMsrpINR: { $sum: "$salesINR" },
                    totalMsrpUSD: { $sum: "$salesUSD" },
                    revenueShareUSD: { $sum: "$commUSD" },
                    totalCreditsUSD: {$sum: "$totalCreditUSD"}
                },
            },
        ],
    };
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/orders", query);
    return res.data;
}

async function getISBNWiseTotalCumulativeSales(serviceID) {
    const query = {
        operation: "aggregate",
        aggregate: [{ "$group": { "_id": "$isbn", totalQty: { "$sum": "$totalQuantity" }, "salesUSD": { "$sum": "$salesUSD" }, "commUSD": { "$sum": "$commUSD" } } }]
    };
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/orders", query);
    return res.data;
}

async function getListedIsbns(serviceID){
    const query = {
        operation: "aggregate",
        aggregate: [{ "$group": { "_id": null, total: { "$sum": 1 }, listed: {$sum: { $cond: [{ $eq: ["$listed", true] }, 1, 0] }} } }]
    };
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/isbn-masters", query);
    return res.data;
}

async function getChannelWiseData(serviceID){
    let pipeline = [{$group: {"_id": "$channel", count: {"$sum": 1}}}];
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/orders", {operation: "aggregate", aggregate: pipeline});
    return res.data;
}

async function getAllIsbns(serviceID){
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/isbn-masters", {});
    return res.data;
}

async function getISBNOrdersData(serviceID,isbn){
    const query ={
        "filter": {"isbn": isbn },
        "projection" : {"_id":0, "orderNo": 1, "orderDt":1, "salesINR": 1,"salesUSD": 1, "totalQuantity" :1,"status": 1,"channel":1}
    }
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/orders", query);
        return res.data
}

async function getRefreshDate(serviceID){
    const query = {
        operation : "aggregate",
        aggregate : [{ $sort: {ts: -1}},{$limit: 1}]
    }
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/orders-fetched-dates", query)
    return res.data
}

const service = {
    getTotalCumulativeSalesAndRevenue,
    getTotalRevenueShare,
    getSalesForRangeUSD,
    getRangeReport,
    getISBNWiseTotalCumulativeSales,
    getListedIsbns,
    getChannelWiseData,
    getISBNOrdersData,
    getAllIsbns,
    getRefreshDate
}
export default service;
