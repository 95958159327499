import axios from "axios"

const uploadISBN = async (payload) =>{
    const headers = {
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${localStorage.getItem('token')}`
    }
   
    const res = await axios.post( process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL + "/repro-upload", payload, {headers: headers})
    return res.data
}


const service = {
    uploadISBN
}

export default service;
