import React from "react";
import { useEffect, useState, useReducer, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import CountWidgets from "./countWidgets";
import DBService from "../../service/dbService";
import TowerService from "../../service/tower";
import moment from "moment";
import { Toast } from "primereact/toast";
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';

function reducer(state, action) {
    switch (action.type) {
        case "state":
            return {
                ...state,
                states: action.payload,
            };
        case "city":
            return {
                ...state,
                cities: action.payload,
            };
        case "zip":
            return {
                ...state,
                zipCodes: action.payload,
            };
        case "site":
            return {
                ...state,
                siteIds: action.payload,
            };
        case "stateChanges":
            return {
                ...state,
                selectedState: action.payload,
            };
        case "cityChanges":
            return {
                ...state,
                selectedCities: action.payload,
            };
        case "zipChanges":
            return {
                ...state,
                selectedZips: action.payload,
            };
        case "siteChange":
            return {
                ...state,
                selectedSite: action.payload,
            };
        default:
            return state;
    }
}

var timer, timer2;
const SiteReport = () => {
    const toast = useRef(null);
    const initialState = {
        states: [],
        cities: [],
        zipCodes: [],
        siteIds: [],
        selectedState: null,
        selectedCities: null,
        selectedZips: null,
        selectedSite: null,
    };
    const [reports, setReports] = useState([]);
    const [loader, setLoader] = useState(false);
    const [tableloader, settableloader] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [time, setTime] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null);
    const myStates = JSON.parse(localStorage.getItem("states"));
    const myCities = JSON.parse(localStorage.getItem("cities"));
    const myZipcodes = JSON.parse(localStorage.getItem("zipcodes"));
    const uid = localStorage.getItem("uid");
    const serviceId = localStorage.getItem("serviceId");
    const [criteria, setCriteria] = useState('Generate By upload Time');
    const debounceFunction = (func, delay, params) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(params);
        }, delay);
    };
    const debounceFunction2 = (fun, delay) => {
        clearTimeout(timer2);
        timer2 = setTimeout(() => {
            fun();
        }, delay);
    };
    const debounceFunction3 = (fun, delay, params1, param2) => {
        clearTimeout(timer2);
        timer2 = setTimeout(() => {
            fun(params1, param2);
        }, delay);
    };

    useEffect(() => {
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        uid,
                        totalCount: {
                            $gt: 0
                        }

                    },
                },
                {
                    $project: {
                        totalCount: 1, uploadingTime: 1, "Okay.count": 1
                    }
                },

                { $sort: { uploadingTime: -1 } }

            ],
        };
        Promise.all([TowerService.getUploadReport(payload, serviceId)]).then((res) => {
            let time = [];
            res[0].map((item) => {
                let temp = {
                    name: timeTemplate(item.uploadingTime),
                    orignalTime: item.uploadingTime,
                };
                time.push(temp);
            })
            setTime(time);
        })

    }, [])
    const handleTimeChange = (e) => {
        setSelectedTime(e.target.value);
    }
    useEffect(() => {
        let query = { projection: { latlongs: 0, midlat: 0, midlong: 0, count: 0 } };
        if (myStates && myStates.length > 0) query = { filter: { st: { $in: myStates } }, ...query };
        DBService.getStateWiseSites(query)
            .then((res) => {
                dispatch({ type: "state", payload: res });
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);
    //On State Changes
    useEffect(() => {
        if (state.selectedState && state.selectedState.length) {
            debounceFunction(getCitiesFromDb, 2000, state.selectedState);
            debounceFunction3(getZipsFromDb, 2000, state.selectedState, []);
            // debounceFunction2(getSitesFromDb, 2000);
        }
    }, [state.selectedState]);
    //on City Changes
    useEffect(() => {
        if (state.selectedCities && state.selectedCities.length) {
            debounceFunction3(getZipsFromDb, 2000, state.selectedState, state.selectedCities);
            // debounceFunction2(getSitesFromDb, 2000);
        }
    }, [state.selectedCities]);
    //On Zip Changes
    useEffect(() => {
        if (state.selectedZips && state.selectedZips.length) {
            debounceFunction2(getSitesFromDb, 2000);
        }
    }, [state.selectedZips]);

    const getCitiesFromDb = (states) => {
        if (states && states.length) {
            let st = state.selectedState.map((s) => s.st);
            let query = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            st: {
                                $in: st,
                            },
                        },
                    },
                    {
                        $project: { ct: 1, _id: 0 },
                    },
                    { $sort: { ct: 1 } },
                ],
            };
            DBService.getCitiesGropingState(serviceId, query).then((res) => {
                if (myCities.length) {
                    let cities = [];
                    res.forEach((item) => {
                        if (myCities.includes(item.ct)) cities.push(item);
                    });
                    dispatch({ type: "city", payload: cities });
                } else {
                    dispatch({ type: "city", payload: res });
                }
            });
        }
    };
    const getZipsFromDb = (states, cities) => {
        let st = [],
            ct = [];

        let query = {
            operation: "aggregate",
            aggregate: [],
        };

        if ((states && states.length) || (cities && cities.length)) {
            if (states && states.length) {
                st = state.selectedState.map((s) => s.st);
                query = {
                    ...query,
                    aggregate: [
                        {
                            $match: {
                                st: {
                                    $in: st,
                                },
                            },
                        },
                        { $project: { zip: 1, _id: 0 } },
                    ],
                };
            }
            if (cities && cities.length) {
                ct = state.selectedCities.map((s) => s.ct);
                query = {
                    ...query,
                    aggregate: [
                        {
                            $match: {
                                ct: {
                                    $in: ct,
                                },
                            },
                        },
                        { $project: { zip: 1, _id: 0 } },
                    ],
                };
            }
        }

        if (states && states.length && cities && cities.length) {
            st = state.selectedState.map((s) => s.st);
            ct = state.selectedCities.map((s) => s.ct);
            query = {
                ...query,
                aggregate: [
                    {
                        $match: {
                            $and: [
                                {
                                    st: {
                                        $in: st,
                                    },
                                },
                                {
                                    ct: {
                                        $in: ct,
                                    },
                                },
                            ],
                        },
                    },
                    { $project: { zip: 1, _id: 0 } },
                ],
            };
        }
        // console.log(query);
        DBService.getZipsGroupingCity(serviceId, query).then((res) => {
            if (myZipcodes.length) {
                let zip = [];
                res.forEach((elm) => {
                    if (myZipcodes.includes(elm.zip)) zip.push(elm);
                });
                dispatch({ type: "zip", payload: zip });
            } else {
                dispatch({ type: "zip", payload: res });
            }
        });
    };

    const getSitesFromDb = () => {
        if (state.selectedZips && state.selectedZips.length) {
            let zip = state.selectedZips.map((z) => z.zip);
            let query = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            zip: { $in: zip },
                        },
                    },
                    {
                        $project: { "latlongs.sid": 1, _id: 0 },
                    },
                ],
            };
            DBService.getZipsGroupingCity(serviceId, query).then((res) => {
                if (res) {
                    let sites = [];
                    res.forEach((item) => (sites = [...sites, ...item.latlongs]));
                    dispatch({ type: "site", payload: sites });
                }
            });
        }
    };

    const onDownload = async (params) => {
        if (params.filename && params.bucketname) {
            let fnArr = params.filename.split("/");
            let ls = fnArr[fnArr.length - 1];
            fnArr.splice(-1);
            let url = process.env.REACT_APP_ATC_SITE_S3 + params.bucketname + "/" + fnArr.join("/") + "/" + encodeURIComponent(ls);
            var element = document.createElement("a");
            element.setAttribute("href", url);
            element.setAttribute("download", "report" + Date.now() + ".csv");
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    };

    const getReports = () => {
        settableloader(true);
        DBService.getSortedSiteReport(serviceId, uid)
            .then((data) => {
                if (data && data.length) {
                    for (let i = 0; i < data.length; i++) {
                        data[i].ts = timeTemplate(data[i].ts);
                    }
                    setReports(data);
                }
            })
            .finally(() => settableloader(false));
    };

    useEffect(() => {
        getReports();
    }, []);

    const generateReport = () => {
        setLoader(true);
        let query = {
            serviceId: serviceId,
            reportName: "DataExport",
            uid: uid,
            st: [],
        };

        if (selectedTime !== null) {
            query = {
                ...query,
                uploadingTime: selectedTime.orignalTime
            }
        }

        if (state.selectedState && state.selectedState.length) {
            let st = state.selectedState.map((s) => s.st);
            query = {
                ...query,
                st: st,
            };
        }

        if (state.selectedCities && state.selectedCities.length) {
            let allLength = state.cities.length;
            // state.cities.forEach((item) => {
            //     allLength += item.st.length;
            // });
            if (allLength === state.selectedCities.length && myCities.length) {
                query = {
                    ...query,
                    ct: myCities,
                };
            } else if (allLength !== state.selectedCities.length) {
                let ct = state.selectedCities.map((c) => c.ct);
                query = {
                    ...query,
                    ct: ct,
                };
            }
        } else if (myCities.length) {
            query = {
                ...query,
                ct: myCities,
            };
        }

        if (state.selectedZips && state.selectedZips.length) {
            let allLength = state.zipCodes.length;
            // state.zipCodes.forEach((elm) => {
            //     allLength += elm.items.length;
            // });
            if (allLength === state.selectedZips.length && myZipcodes.length) {
                query = {
                    ...query,
                    zip: myZipcodes,
                };
            } else if (allLength !== state.selectedZips.length) {
                let zip = state.selectedZips.map((z) => z.zip);
                query = {
                    ...query,
                    zip: zip,
                };
            }
        } else if (myZipcodes.length) {
            query = {
                ...query,
                zip: myZipcodes,
            };
        }

        if (state.selectedSite && state.selectedSite.length) {
            if (state.siteIds.length !== state.selectedSite.length) {
                let sid = state.selectedSite.map((s) => s.sid);
                query = {
                    ...query,
                    sid: sid,
                };
            }
        }
        TowerService.dowloadReport(query).then((res) => {
            if (res) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Your report will generated soon", life: 10000 });
                setLoader(false);
                reSet();
                getReports();
            }
        });
    };

    const reSet = () => {
        dispatch({ type: "stateChanges", payload: initialState.selectedState });
        dispatch({ type: "cityChanges", payload: initialState.selectedCities });
        dispatch({ type: "zipChanges", payload: initialState.selectedZips });
        dispatch({ type: "siteChange", payload: initialState.selectedSite });
        dispatch({ type: "city", payload: initialState.cities });
        dispatch({ type: "zip", payload: initialState.zipCodes });
        dispatch({ type: "site", payload: initialState.siteIds });
        setSelectedTime(null);
    };

    const timeTemplate = (data) => {
        return moment(data).format("Do MMM, YY HH:mm:ss");
    };
    const buttonTemplate = (d) => {
        if (d.status === "Pending") {
            return null;
        } else {
            return <Button label="Download" onClick={() => onDownload(d)}></Button>;
        }
    };

    return (
        <div className="col-12">
            <Toast ref={toast} />
            <CountWidgets></CountWidgets>
            {/* {
                reports && reports.length ? */}

            <div className="grid">

                <div className="col-12 ">
                    <h3>Generate & download report</h3>
                    <div className="flex flex-wrap   align-items-center " >
                        <label htmlFor="ingredient2" className="col-12 text-xl">Generate Report By :</label>
                        <div className="flex  mr-3 mt-3" >
                            <RadioButton inputId="ingredient2" name="pizza" className="ml-2" value="Generate By upload Time" onChange={(e) => setCriteria(e.value)} checked={criteria === 'Generate By upload Time'} />
                            <label htmlFor="ingredient2" className="ml-2">Upload Time</label>
                        </div>
                        <div className="flex mt-3" >
                            <RadioButton inputId="ingredient1" name="pizza" value="Generate By state" onChange={(e) => setCriteria(e.value)} checked={criteria === 'Generate By state'} />
                            <label htmlFor="ingredient1" className="ml-2">State</label></div>
                    </div>
                </div>
                <div className="col-12 md:col-3">
                    {criteria === "Generate By state" ? <MultiSelect value={state.selectedState} options={state.states} onChange={(e) => dispatch({ type: "stateChanges", payload: e.value })} optionLabel="st" placeholder=" Select State" className="m-2 bg-white w-full" filter />
                        : < Dropdown value={selectedTime} onChange={(e) => {
                            handleTimeChange(e)
                        }} options={time} optionLabel="name"
                            placeholder={!time || !selectedTime || !selectedTime.name ? "Select a Time" : selectedTime.name} className="m-2 bg-white w-full" />}
                </div>
                {criteria === "Generate By state" && state.cities && state.cities.length > 0 ? (
                    <div className="col-12 md:col-2">
                        <MultiSelect value={state.selectedCities} onChange={(e) => dispatch({ type: "cityChanges", payload: e.value })} options={state.cities} optionLabel="ct" className="m-2 bg-white w-full" placeholder="Select Cities" filter />
                    </div>
                ) : null}

                {criteria === "Generate By state" && state.zipCodes && state.zipCodes.length > 0 ? (
                    <div className="col-12 md:col-2">
                        <MultiSelect value={state.selectedZips} onChange={(e) => dispatch({ type: "zipChanges", payload: e.value })} options={state.zipCodes} optionLabel="zip" className="m-2 bg-white w-full" placeholder="Select Zipcodes" filter />
                    </div>
                ) : null}
                {criteria === "Generate By state" && state.siteIds && state.siteIds.length > 0 ? (
                    <div className="col-12 md:col-2">
                        <MultiSelect value={state.selectedSite} options={state.siteIds} onChange={(e) => dispatch({ type: "siteChange", payload: e.value })} optionLabel="sid" placeholder=" Select Sites" className="m-2 bg-white w-full" filter />
                    </div>
                ) : null}
                <div className="col-12 md:col-3">
                    <Button label="Generate Report" className="m-2" loading={loader} disabled={state.selectedState && state.selectedState.length > 0 || selectedTime ? false : true} onClick={generateReport} />
                    <Button label="Reset" className="p-button-danger m-2" onClick={reSet} />
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="col-12 md:col-3">
                    </div>
                    <Button label="Reload Table" loading={tableloader} onClick={getReports} />
                    <DataTable value={reports} paginator={true} rows={10} responsiveLayout="scroll">
                        <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Report Generation Time" style={{ width: "100px" }} field={"ts"}></Column>
                        <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Report Type" style={{ width: "100px" }} field={"reportType"}></Column>
                        <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Status" style={{ width: "100px" }} field={"status"}></Column>
                        <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Download Report" body={buttonTemplate}></Column>
                    </DataTable>
                </div>
            </div>

            {/* : null

            } */}
        </div>
    );
};

export default SiteReport;
