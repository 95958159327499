import React from 'react'
import {Avatar} from "primereact/avatar"
const UploadInstruction = ({instruction={}, hasTemplate=false}) => {
    const serviceId=localStorage.getItem("serviceId")
    return (
        <div className='col-12'>
        <div className='grid card'>
            <div className='w-full'>
                <h4 style={{ color: "#2D2D2D" }}>Steps<span style={{ color: "red" }}> *</span></h4>
                <div className='grid col-12 justify-content-between flex-wrap'>
                    <div className='col-12 lg:col-4'>
                        <div className='displayCard lg:h-8rem border-noround col-12 lg:col-11' style={{ "backgroundColor": "#F3F3F3" }}>
                            <Avatar label="1" className="mr-2 border-solid border-2" shape="circle"></Avatar>
                            <div>
                                <h6 className='mt-2' style={{ letterSpacing: "0.1rem" }}> {instruction.message1}. {hasTemplate && <a className='text-xl' href={process.env.REACT_APP_IMAGE_STATIC_URL + `/${serviceId}/list_template.xlsx`} download>
                                    Click here
                                </a>}</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 lg:col-4'>
                        <div className='displayCard lg:h-8rem border-noround col-12 lg:col-11' style={{ "backgroundColor": "#F3F3F3" }}>
                            <Avatar label="2" className="mr-2 border-solid border-2" shape="circle"></Avatar>
                            <div>
                                <h6 className='mt-2' style={{ letterSpacing: "0.1rem" }}> {instruction.message2} </h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 lg:col-4'>
                        <div className='displayCard lg:h-8rem border-noround col-12 lg:col-11' style={{ "backgroundColor": "#F3F3F3" }}>
                            <Avatar label="3" className="mr-2 border-solid border-2" shape="circle"></Avatar>
                            <div>
                                <h6 className='mt-2' style={{ letterSpacing: "0.1rem" }}> {instruction.message3} </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}
export default UploadInstruction