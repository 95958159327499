import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { chartHeadingStyle } from "../../utilities/constant";

const GroupBarChart = ({ chartData }) => {
    const [state, setState] = useState(null);

    useEffect(() => {
        const option = {
            chart: {
                type: "column",
            },
            title: {
                text: "",
                style: chartHeadingStyle,
                align: "center",
            },
            xAxis: [
                {
                    categories: [],
                    crosshair: true,
                },
            ],
            yAxis: [],
            tooltip: {},
            plotOptions: {
                series: {
                    pointWidth: 15,
                },
            },
            series: [],
        };
        option.series = chartData.data;
        // option.tooltip = chartData.tooltip;
        option.xAxis[0].categories = chartData.categories;
        option.yAxis = chartData.yAxis;
        option.title.text = chartData.title;
        setState(option);
    }, [chartData]);

    return state ? <HighchartsReact highcharts={Highcharts} options={state} /> : null;
};

export default GroupBarChart;
