import React, { Component } from "react";
import GetwayWiseReport from "./components/GetwayWiseReport";

class ServiceObservability extends Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <GetwayWiseReport />
                </div>
            </div>
        );
    };
}

export default ServiceObservability;
