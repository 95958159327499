import React, { Component } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import FuelLiveReport from "./FuelLiveReport";
import FuelHistoricalReport from "./FuelHistoricalReport";

class FuelReport extends Component {
    render = () => {
        return (
            <TabView>
                <TabPanel header="Live" className="px-3">
                    <FuelLiveReport />
                </TabPanel>
                <TabPanel header="History" className="px-3">
                    <FuelHistoricalReport />
                </TabPanel>
            </TabView>
        );
    }
}

export default FuelReport;