
export const dispatchAction = (type, data) => {
    return dispatch => dispatch({ type, data });
};

export const convertCamelCase = (str) => {
    if(str === str.toUpperCase()) return str;
    else if(str.includes("/")) return str;
    let res = str.replace(/[A-Z]/g, ' $&').trim();
	let output = res.charAt(0).toUpperCase() + res.slice(1);
    return output;
}

export function getPercentageChange(oldNumber, newNumber){
    var decreaseValue = oldNumber - newNumber;
    return ((decreaseValue / oldNumber) * 100).toFixed(2);
}