import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { userFSAccess } from "../../utilities/constant";
import Unauth from "./components/Unauth";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import dbservice from "../../service/dbService";

const FileShare = () => {
    const [state, setState] = useState(null);
    const [unauthorized, setUnauthorized] = useState(false);
    // const [eventsdata, setEventsData] = useState(null);

    useEffect(async () => {
        const data = JSON.parse(localStorage.getItem("service"));
        const { links } = data;
        let res = JSON.parse(localStorage.getItem("permissions")).filter((item) => {
            return userFSAccess.includes(item);
        });

        if (res.length === 0) {
            setUnauthorized(true);
        } else if (JSON.stringify(res) === JSON.stringify(["Admin_READ", "Admin_WRITE"]) || res.length === 0) {
            setState(links);
        } else {
            let filteredLinks = {};
            for (let i = 0; i < res.length; i++) {
                filteredLinks[res[i]] = links[res[i]];
            }
            setState(filteredLinks);
        }
        // let eventsRes = await dbservice.getEventsData(localStorage.getItem("serviceId"), res[0].split("_")[0]);
        // if (eventsRes && eventsRes.length) {
        //     setEventsData(eventsRes);
        // }
    }, []);

    const getTemplate = (options) => {
        let title = getTitle(options.titleElement.props.children);
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                {title}
            </button>
        );
    };

    const getTitle = (item) => {
        switch (item) {
            case "Publisher_READ":
                return "Publisher Download";

            case "Publisher_WRITE":
                return "Publisher Upload";

            case "Artwork_READ":
                return "Artwork Download";

            case "Artwork_WRITE":
                return "Artwork Upload";

            case "Printer_READ":
                return "Printer Download";

            default:
                return null;
        }
    };

    return !unauthorized ? (
        <>
            <div className="w-100">
                {state ? (
                    <TabView>
                        {/* <TabPanel header={"Events"}>
                        <DataTable value={eventsdata} paginator={true} rows={10} responsiveLayout="scroll">
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="SL" style={{ width: "50px" }} field={"sl"}></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Filename" style={{ width: "100px" }} field={"fileName"}></Column>
                            <Column
                                headerClassName="color1-bg"
                                bodyClassName="color2-bg"
                                header="URL"
                                style={{ width: "100px", wordWrap: "break-word" }}
                                body={(rowData) => (
                                    <a download={rowData.fileName} style={{ backgroundColor: "#264653", padding: "3px", color: "white", cursor: "pointer", textDecoration: "none" }} href={rowData.url}>
                                        Download
                                    </a>
                                )}
                            ></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Action" style={{ width: "100px" }} field="action"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Comment" style={{ width: "100px" }} field="comment"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Time" style={{ width: "100px" }} field="timestamp"></Column>
                        </DataTable>
                    </TabPanel> */}
                        {Object.keys(state).map((item, index) => (
                            <TabPanel header={getTitle(item)} key={index}>
                                <iframe title="filestash" src={`${process.env.REACT_APP_FILE_SHARE_BASE_URL + state[item]}`} style={{ width: "100%", height: "80vh" }}></iframe>
                            </TabPanel>
                        ))}
                    </TabView>
                ) : null}
            </div>
        </>
    ) : (
        <Unauth />
    );
};

export default FileShare;
