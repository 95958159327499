import React, { Component } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { connect } from "react-redux";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { mapKey } from "../../utilities/constant";
import { Badge } from "primereact/badge";
import { VehicleSrvice } from "../../service/VehicleService";
import moment from "moment";
import { convertValue } from "../../utilities/unitConversion";
import { Card } from "primereact/card";
import { Timeline } from "primereact/timeline";
import { classNames } from "primereact/utils";
import { secondsToHm, dispatchAction } from "../../utilities/utillFunction";
import { Toast } from "primereact/toast";
import { Employee } from "../../service/Employee";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
const map_start_icon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#00A42F; stroke:none;" d="M31 60L33 60C38.9485 49.275 51.7089 35.8509 51.8164 23C51.9872 2.56881 22.1996 -3.31481 13.9699 15C7.39209 29.6386 23.7505 48.1572 31 60z"/><path style="fill:#f73a3a; stroke:none;" d="M21 6L22 7L21 6M42 6L43 7L42 6z"/><path style="fill:#FFFFFF; stroke:none;" d="M28.1088 16.0278C20.0658 20.5369 26.7819 33.892 34.956 29.8056C44.4446 25.0619 37.288 10.8817 28.1088 16.0278z"/><path style="fill:#ff0101; stroke:none;" d="M24 25L25 26L24 25M39 25L40 26L39 25M49 31L50 32L49 31M48 33L49 34L48 33M47 35L48 36L47 35z"/><path style="fill:#f73a3a; stroke:none;" d="M19 40L20 41L19 40z"/><path style="fill:#ff0101; stroke:none;" d="M43 42L44 43L43 42z"/><path style="fill:#e3641d; stroke:none;" d="M22 45L23 46L22 45z"/><path style="fill:#ff0101; stroke:none;" d="M38 50L39 51L38 50z"/><path style="fill:#e3641d; stroke:none;" d="M33 58L34 59L33 58z"/></svg>';
const map_end_icon = '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill: #F4511E;stroke:none;" d="M31 60L33 60C38.9485 49.275 51.7089 35.8509 51.8164 23C51.9872 2.56881 22.1996 -3.31481 13.9699 15C7.39209 29.6386 23.7505 48.1572 31 60z"/><path style="fill:#f73a3a; stroke:none;" d="M21 6L22 7L21 6M42 6L43 7L42 6z"/><path style="fill:#FFFFFF; stroke:none;" d="M28.1088 16.0278C20.0658 20.5369 26.7819 33.892 34.956 29.8056C44.4446 25.0619 37.288 10.8817 28.1088 16.0278z"/><path style="fill:#ff0101; stroke:none;" d="M24 25L25 26L24 25M39 25L40 26L39 25M49 31L50 32L49 31M48 33L49 34L48 33M47 35L48 36L47 35z"/><path style="fill:#f73a3a; stroke:none;" d="M19 40L20 41L19 40z"/><path style="fill:#ff0101; stroke:none;" d="M43 42L44 43L43 42z"/><path style="fill:#e3641d; stroke:none;" d="M22 45L23 46L22 45z"/><path style="fill:#ff0101; stroke:none;" d="M38 50L39 51L38 50z"/><path style="fill:#e3641d; stroke:none;" d="M33 58L34 59L33 58z"/></svg>'
class EmployeeLiveReport extends Component {
    mapRefInst = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            H: null,
            map: null,
            vehicleDetails: null,
            empData: this.props.emp,
            liveData: null,
            checked1: false,
            showData: true,
            error: ""
        };
        this.serviceID = localStorage.getItem("serviceId");
        this.serviceDetails = localStorage.getItem('service');
        this.from_date = moment(this.props.emp.ts.split(" ")[0], "YYYY-MM-DD").startOf("day").format("YYYY-MM-DD");
        this.employeeService = new Employee();
        this.vehicleService = new VehicleSrvice();
        this.showWarn = this.showWarn.bind(this);
        this.sampleGeoEvents = [
            { thingname: "cb-chevywht1", state: "InsideGF", name: "GeoFence_1" },
            { thingname: "cb-chevywht1", state: "OutsideGF", name: "GeoFence_1" },
        ];
    }
    showWarn() {
        this.toast.show();
    }

    componentWillUnmount = () => {
        window.clearTimeout(this.timer);
    };

    componentDidMount = () => {
        this.getTodaysEmpData();
    };

    getTodaysEmpData = () => {
        Promise.all([this.employeeService.getEmployeeLocationHistory(this.serviceID, { filter: { thingName: this.state.empData.thingName, date: { "$gte": this.from_date } } }),  this.employeeService.getGeoEvents(this.serviceID, {filter: {thingname: this.state.empData.thingName, ts: { $gte: this.from_date } }})]).then((res) => {
            let liveData = res[0];
            let geo_events = res[1];
            if (liveData && liveData.length) {
                // if (liveData[0].totalDistanceTravelled && liveData[0].totalTravelTime) {
                //     liveData[0].avgSpeed = ((liveData[0].totalDistanceTravelled / liveData[0].totalTravelTime) * 3.6).toFixed(2);
                // }

                if (this.timer) window.clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.getTodaysEmpData();
                }, 900000)

                if(geo_events && geo_events.length){
                    this.processGeofenceData(geo_events)
                }
                if (this.state.map && this.state.liveData) {
                    let prevData = JSON.parse(JSON.stringify(this.state.liveData));
                    if (prevData.endLocation && prevData.endLocation.lat && liveData[0].endLocation && liveData[0].endLocation.lat && prevData.endLocation.lat !== liveData[0].endLocation.lat) {

                        this.setState({
                            showData: false,
                            liveData: liveData[0],
                        }, () => {
                            this.setState({
                                showData: true
                            }, () => {
                                this.getRealTimeDetailedMap(liveData[0]);
                            });
                        })
                    }
                } else {
                    this.setState({
                        liveData: liveData[0],
                    }, () => {
                        this.getRealTimeDetailedMap(liveData[0])
                    });
                }
            }
            else{
                this.setState({
                    error: "Data not found"
                })
            }
        }).catch(e => {
            console.error(e);
        })
    }

    getStrengthMarker = (H, map, station, icon, ui, address, stoppageNo) => {
        let marker = new H.map.Marker({ lat: station.lat, lng: station.lng }, { icon: icon, data: station });
        let bubble;

        marker.addEventListener(
            "tap",
            function (evt) {
                bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                    content: '<div id="vehicle-content"> <div id="vehicle-info"><div id="info">' + ( station.type ? station.type : (station.name === "startMarker" ? "Start" : "End")) +  (stoppageNo ? '<div id="info">Time :' +moment(station.start_time, "YYYY-MM-DD HH:mm:ss").format("h:mm A") +'</div> <div id="info">Stop Point Number : '+ stoppageNo +'</div>'   :  (" Address: " + address) ) + '</div></div></div>',
                });
                ui.addBubble(bubble);
            },
            false
        );

        return marker;
    }

    getRealTimeDetailedMap = (data) => {
        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();
        data.startLocation.name = "startMarker";
        data.endLocation.name = "endMarker";
        let startLocation = data.startLocation;
        let endLocation = data.endLocation;

        const map = new H.Map(this.mapRefInst.current, defaultLayers.vector.normal.map, {
            center: { lat: endLocation.lat, lng: endLocation.lng },
            zoom: 9,
            pixelRatio: window.devicePixelRatio || 1,
        });
        

        window.addEventListener("resize", () => map.getViewPort().resize());
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        const ui = H.ui.UI.createDefault(map, defaultLayers);
        let group = new H.map.Group();

        let markers = [];
        const startIcon = new H.map.Icon(map_start_icon, { size: { w: 32, h: 32 } });
        const endIcon = new H.map.Icon(map_end_icon, { size: { w: 32, h: 32 } });
        const scheduledIcon = new H.map.Icon("/images/Scheduled.svg", { size: { w: 15, h: 15 }});
        const unScheduledIcon = new H.map.Icon("/images/Unscheduled.svg", {size: { w: 15, h: 15 }});

        let startMarker = this.getStrengthMarker(H, map, startLocation, startIcon, ui, startLocation.address);
        markers.push(startMarker);

        let endMarker = this.getStrengthMarker(H, map, endLocation, endIcon, ui, endLocation.address);
        markers.push(endMarker);

        let wayPoints = [], allPoints = [];

        if(data.stoppagePoints && data.stoppagePoints.length){
            data.stoppagePoints.forEach((s, j )=> {
                let stopIcon = s.type === "Undesignated" ? unScheduledIcon : scheduledIcon
                let stopMarker = this.getStrengthMarker(H, map, s, stopIcon , ui, null, j+1);
                markers.push(stopMarker);
            })
            allPoints = JSON.parse(JSON.stringify(data.stoppagePoints));
        }

        if (data.wayPoints && data.wayPoints.length) {
            allPoints = allPoints.concat(...data.wayPoints);   
            if (startLocation && (!startLocation.lat || !startLocation.lng)) {
                startLocation = data.wayPoints[0];
            }
            if (endLocation && (!endLocation.lat || !endLocation.lng)) {
                endLocation = data.wayPoints[data.wayPoints.length - 1];
            }
        }
        if(allPoints.length){
            allPoints = allPoints.sort((a, b) => {
                const date1 = new Date(a.datetime);
                const date2 = new Date(b.datetime);
                return date1 - date2;
            });
            for (let i = 0; i < allPoints.length; i++) {
                wayPoints.push(`via=${allPoints[i].lat},${allPoints[i].lng}`);
            }
        }


        group.addObjects(markers);
        // this.addGeoFencesToMap(H, map);
        
        this.vehicleService
            .getRoute(`${startLocation.lat},${startLocation.lng}`, `${endLocation.lat},${endLocation.lng}`, wayPoints.length ? wayPoints.join("&") : null)
            .then((response) => {
                this.addRouteShapeToMap(response.routes[0], H, map, group);
            })
            .catch((reason) => {
                console.error(reason);
            });
    }

    addRouteShapeToMap(route, H, map, group) {
        route.sections.forEach((section, i) => {
            let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
            let polyline = new H.map.Polyline(linestring, {
                style: {
                    lineWidth: 5,
                    fillColor: "white",
                    strokeColor: "rgba(10, 3, 241, 1)",
                    lineDash: [0, 0],
                    //lineTailCap: "arrow-tail",
                    //lineHeadCap: "arrow-head",
                },
            });
            group.addObject(polyline);
        });
        map.addObject(group);
        map.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
        });

        this.setState({ H, map, group });
    }

    addManueversToMap = (route, H, map) => {
        var svgMarkup = '<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="5" fill="#1b468d" stroke="white" stroke-width="1" /></svg>',
            dotIcon = new H.map.Icon(svgMarkup, { anchor: { x: 6, y: 6 } }),
            group = new H.map.Group(),
            i;

        route.sections.forEach((section) => {
            let poly = H.geo.LineString.fromFlexiblePolyline(section.polyline).getLatLngAltArray();

            let actions = section.actions;
            // Add a marker for each maneuver
            for (i = 0; i < actions.length; i += 1) {
                let action = actions[i];
                var marker = new H.map.Marker(
                    {
                        lat: poly[action.offset * 3],
                        lng: poly[action.offset * 3 + 1],
                    },
                    { icon: dotIcon }
                );
                group.addObject(marker);
            }
            // Add the maneuvers group to the map
            map.addObject(group);
        });
    }

    addGeoFencesToMap = (H, map, ui) => {
        if (this.props.geofences && this.props.geofences.length) {
            this.props.geofences.forEach((g) => {
                let polygon = H.util.wkt.toGeometry(g.data);
                let geoFence = new H.map.Polygon(polygon, { style: { strokeColor: "rgba(128, 0, 0, 0.6)" }, data: g })
                geoFence.addEventListener("tap", function (evt) {
                    let data = evt.target.getData();
                    let bubble = new H.ui.InfoBubble({lat: data.lat, lng: data.lon}, {
                        content: `<div id="vehicle-content"><div id="vehicle-info"><div id="info">${data.name} </div></div></div>`,
                    });
                    ui.addBubble(bubble);
                })
                map.addObject(geoFence);
            });
        }
    }
    processGeofenceData = (events) => {
        let stateObj = { geoEvents: events };
        if (events && events.length) {
            events = events.sort((a, b) => {
                const date1 = new Date(a.ts);
                const date2 = new Date(b.ts);
                return date1 - date2;
            });
            let entryCnt = events.filter((e) => e.state === "InsideGF");
            let exitCnt = events.filter((e) => e.state === "OutsideGF");
            stateObj.entryCnt = entryCnt.length;
            stateObj.exitCnt = exitCnt.length;
        }
        this.setState(stateObj);
    }
    marker = (item) => {
        return (
            <span className="custom-marker shadow-2 p-2" style={{ backgroundColor: item.state === "OutsideGF" ? "#628c3f" : "#0c6291" }}>
                <i className={classNames("marker-icon", "fa-solid fa-right-" + (item.state === "OutsideGF" ? "from" : "to") + "-bracket")} style={{ transform: item.state === "OutsideGF" ? "rotate(180deg)" : "none" }}></i>
            </span>
        );
    }

    content = (item) => {
        return (
            <div onClick={() => this.geoEventClick(item)} style={{ cursor: "pointer" }}>
                <Card className="mb-3">
                    <div style={{ fontSize: "1.3rem", fontWeight: "bold", padding: "0" }}>
                        {(item.state === "OutsideGF" ? "Exit " : "Entered ") + item.name}
                        {item.ts ? <span style={{ paddingLeft: "2vh", fontSize: "1rem", color: "#00000099" }}>{item.ts}</span> : null}
                    </div>
                </Card>
            </div>
        );
    }

    geoEventClick = (event) => {
        // let objects = this.state.group.getObjects();
        let objects = this.state.map.getObjects();
        if (event.name !== this.state.selectedGeoName) {
            let objectIndex = objects.findIndex((o) => (o.data && o.data.name === event.name));
            let prevObjIndex = objects.findIndex((o) =>  (o.data && o.data.name === this.state.selectedGeoName));

            if (objectIndex > -1) {
                objects[objectIndex].setStyle({ fillColor: "rgba(226, 245, 39, 0.48)", strokeColor: "rgba(128, 0, 0, 0.6)" });
               if(prevObjIndex > -1) objects[prevObjIndex].setStyle({ fillColor: "rgba(0, 85, 170, 0.4)", strokeColor: "rgba(128, 0, 0, 0.6)" });

                this.setState({
                    selectedGeoName: event.name,
                });
            }
        }
    }

    handStoppage = (item) => {
        return item.address;
    }
    handleduration = (item) => {
        return secondsToHm(item.stoppageDuration);
    }

    handelts = (item) => {
        return <>{this.serviceDetails.region === "us-east-1" || this.serviceDetails.region === "us" || this.serviceDetails.region === "US" ? moment(item.start_time).format("MMM DD,YYYY hh:mm:ss") : moment(item.start_time).format("Do MMM, YYYY hh:mm:ss")}</>;
    }

    render = () => {
        const leftContents = (
            <React.Fragment>
                <Button icon="pi pi-arrow-left" className="mr-2" onClick={this.props.reportBack} />
            </React.Fragment>
        );

        const rightContents = (
            <React.Fragment>
                <div className="text-center">
                    <p className="font-bold  mb-1 text-lg">
                        <span className="text-blue-700">
                            <font style={{ color: "black" }}>Name: </font> {this.state.empData.name}&nbsp;&nbsp;
                            <font style={{ color: "black" }}>Email: </font>{this.state.empData.email}
                        </span>
                    </p>
                </div>
            </React.Fragment>
        );

        let headerGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Stoppage" className="bg-primary"></Column>
                    <Column header="Time" className="bg-primary"></Column>
                    <Column header="Duration" className="bg-primary"></Column>
                </Row>
            </ColumnGroup>
        );
        return (

            <div className="grid">
                <Toast ref={(el) => (this.toast = el)} />
                <div className="col-12 md:col-12 lg:col-12">
                    {

                        this.state.error ?

                            <div className="grid">
                                <div className="col-12">
                                    <Toolbar left={leftContents} right={rightContents} />
                                </div>
                                <div className="col-12 " style={{color: "#01579B"}}>
                                    <div className="card flex flex-column align-items-center justify-content-center" style={{ height: "60vh", backgroundColor: "#B3E5FC" }}>
                                        <span className="fa-solid fa-circle-info" style={{ fontSize: "3rem" }}></span>
                                        <h3>No data found for the day.For the history record please refer the tracking report</h3>
                                    </div>
                                </div>
                            </div>
                            :
                            this.state.liveData ? (
                                <>
                                    <div className="grid">
                                        <div className="col-12">
                                            <Toolbar left={leftContents} right={rightContents} />
                                        </div>
                                    </div>
                                    <div className="grid mb-3">
                                        <div className="col-12 lg:col-4">
                                            <div className="card vehicle_info">
                                                <div className="font-semibold text-900" style={{ marginBottom: 0, padding: "10px 0", borderBottom: "1px solid var(--surface-300)" }}>
                                                    Last Location
                                                    <br />
                                                    <div style={{ color: "var(--blue-600)" }}> {typeof(this.state.liveData.endLocation.address) === "string" ? this.state.liveData.endLocation.address : ""}</div>
                                                </div>

                                                <p className="font-bold text-900">
                                                    Start Time:
                                                    <span> {this.state.liveData["startTime"] ? moment(this.state.liveData["startTime"], "YYYY-MM-DD HH:mm:ss").format("ddd, h:mm A") : "--"}</span>
                                                </p>
                                                <p className="font-bold text-900">
                                                    End Time:
                                                    <span> {this.state.liveData["endTime"] ? moment(this.state.liveData["endTime"], "YYYY-MM-DD HH:mm:ss").format("ddd, h:mm A") : "--"}</span>
                                                </p>
                                                <p className="font-semibold text-900">
                                                    Total Distance Travelled
                                                    <span>{convertValue(this.state.liveData.totalDistanceTravelled / 1000, "distance", this.props.unit)}</span>
                                                </p>
                                                <p className="font-semibold text-900">
                                                    Total Time travelled
                                                    <span> {this.state.liveData.totalTravelTime ? secondsToHm(this.state.liveData.totalTravelTime) : "--"}</span>
                                                </p>

                                                {/* <p className="font-semibold text-900">
                                                    Avg Speed
                                                    <span>{this.state.liveData.avgSpeed ? convertValue(this.state.liveData.avgSpeed, "speed", this.props.unit) : "--"}</span>
                                                </p> */}

                                                <p className="font-bold text-900">
                                                    Stoppages:
                                                    <span> {this.state.liveData.stoppagePoints ? this.state.liveData.stoppagePoints.length : "0"}</span>
                                                </p>

                                                {
                                                    this.state.liveData.totalStoppageDuration ?
                                                        <p className="font-bold text-900">
                                                            Total Stoppage Period:
                                                            <span> {secondsToHm(this.state.liveData.totalStoppageDuration)}</span>
                                                        </p>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        {
                                            this.state.showData ?
                                                <div className="col-12 lg:col-8">
                                                    <div className="card p-0">
                                                        <div
                                                            ref={this.mapRefInst}
                                                            style={{
                                                                width: "100%",
                                                                height: "400px",
                                                                overflow: "hidden",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div> : null
                                        }

                                    </div>
                                    <Accordion
                                    >
                                        {this.props.geofences && this.props.geofences.length ? (
                                            this.state.geoEvents && this.state.geoEvents.length ? (
                                                <AccordionTab
                                                    header={
                                                        <div>
                                                            <span className="geofenceTitle">Geo-fence Events</span>
                                                            <span style={{ float: "right", fontSize: "1.2rem" }}>
                                                                <span>
                                                                    {" "}
                                                                    {"Total "} <Badge value={this.state.geoEvents.length} style={{ fontSize: "1rem", background: "#57624e" }} className="mr-2"></Badge>
                                                                </span>
                                                                <span>
                                                                    {" "}
                                                                    {"Entry "} <Badge value={this.state.entryCnt || "0"} style={{ fontSize: "1rem", background: "#0c6291" }} className="mr-2"></Badge>
                                                                </span>
                                                                <span>
                                                                    {" "}
                                                                    {"Exit "} <Badge value={this.state.exitCnt || "0"} style={{ fontSize: "1rem", background: "#628c3f" }} className="mr-2"></Badge>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    }
                                                    style={{ maxHeight: "30vh", overflowY: "auto" }}
                                                >
                                                    <div className="widget-timeline">
                                                        <Timeline value={this.state.geoEvents} className="customized-timeline" marker={this.marker} content={this.content} />
                                                    </div>
                                                </AccordionTab>
                                            ) : null
                                        ) : (
                                            <AccordionTab
                                                disabled={true}
                                                header={
                                                    <div>
                                                        <div className="grid">
                                                            <span className="geofenceTitle">Geo-fence Events</span>
                                                            <span style={{ fontSize: "1.2rem" }}>
                                                                <span>
                                                                    {" "}
                                                                    {"Total "} <Badge value={"0"} style={{ fontSize: "1rem", background: "#57624e" }} className="mr-2"></Badge>
                                                                </span>
                                                                <span>
                                                                    {" "}
                                                                    {"Entry "} <Badge value={"0"} style={{ fontSize: "1rem", background: "#0c6291" }} className="mr-2"></Badge>
                                                                </span>
                                                                <span>
                                                                    {" "}
                                                                    {"Exit "} <Badge value={"0"} style={{ fontSize: "1rem", background: "#628c3f" }} className="mr-2"></Badge>
                                                                </span>
                                                            </span>
                                                            <span style={{ float: "right", color: "orange" }}>
                                                                Not Configured
                                                                <object data={"/images/no-configuration-setting-icon.svg"} width="16" height="16">
                                                                    {" "}
                                                                </object>
                                                            </span>
                                                        </div>
                                                        <div style={{ marginTop: "2vh" }}>
                                                            <div className="widget-timeline">
                                                                <Timeline value={this.sampleGeoEvents} className="customized-timeline" marker={this.marker} content={this.content} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                style={{ overflowY: "auto" }}
                                            ></AccordionTab>
                                        )}
                                        <AccordionTab header="Stoppage Details">
                                            <div className="grid">
                                                <div className="col-12">
                                                    <div className="card">
                                                        <DataTable headerColumnGroup={headerGroup} value={this.state.liveData.stoppagePoints} responsiveLayout="scroll">
                                                            {/* <Column header="Sl.No." field="index"></Column> */}
                                                            <Column header="Stoppage" body={this.handStoppage}></Column>
                                                            <Column header="Stoppage Time" body={this.handelts}></Column>
                                                            <Column header="Stoppage Duration" body={this.handleduration}></Column>
                                                        </DataTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionTab>
                                    </Accordion>
                                </>
                            ) : (
                                <div className="grid">
                                    <div className="col-12">
                                        <Skeleton height="50px" className="mb-2"></Skeleton>
                                    </div>
                                    <div className="col-12 lg:col-4">
                                        <Skeleton height="322px" className="mb-2"></Skeleton>
                                    </div>
                                    <div className="col-12 lg:col-8">
                                        <Skeleton height="322px" className="mb-2"></Skeleton>
                                    </div>
                                    <div className="col-12">
                                        <Skeleton height="50px" className="mb-2"></Skeleton>
                                    </div>
                                </div>
                            )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { unit: state.changeUnit };
};
export default connect(mapStateToProps, { dispatchAction })(EmployeeLiveReport);