import React, { useEffect, useState, useRef } from 'react'
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import * as XLSX from "xlsx";
import moment from 'moment';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { plazaServices } from '../tollPlaza/plazaReportService';
import { Dialog } from 'primereact/dialog';
import { useHistory } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import axios from 'axios';

export const TittleListingStatus = () => {
    const [dateRange, setDateRange] = useState([]);
    const [data, setdata] = useState([])
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState();
    const [metaData, setMetaData] = useState([]);
    const [update, setUpdate] = useState(null);
    const [first, setFirst] = useState(0);
    const [skip, setSkip] = useState(0);
    const [searchIsbn, setSearchIsbn] = useState(null);
    const [showDescription, setShowDescription] = useState(false);
    const [updateShow, setUpdateShow] = useState(false);
    const [isbnPriceHandler, setIsbnPriceHandler] = useState([])
    const [downloadInfoMsg,setDownloadInfoMsg] = useState(false)
    const history = useHistory();
    const location = useLocation();
    const state = location.state;
    const debounceTimer = useRef(null);
    const toast = useRef(null);
    const initReport = () => {
        const payload = {
            filter: {},
            projection: {
                _id: 0,
                bisacSubjectCodes: 0,
                bookFormat: 0,
                inputFileName: 0,
            },
            "sort": [["createdDate", -1]]
        }
        if (dateRange[1]) {
            payload.filter["createdDate"] = {
                $gte: dateRange[0],
                $lte: dateRange[1]
            }
        }
        payload["skip"] = data.length;
        payload["limit"] = 16;
        if (status && status !== "All") {
            payload.filter["walmart.listingStatus"] = status;
        }
        if (searchIsbn && typeof (searchIsbn) === "string") {
            let temp = searchIsbn.split(",")
            temp = temp.map((item) => {
                return parseInt(item);
            })
            payload.filter["isbn13"] = { $in: temp };
        }
        Promise.all([plazaServices.general(serviceId, payload, "nq-processing-status")]).then((res) => {
            if (res[0].length) {
                res[0].map((row, ind) => {
                    let listPrice = 0;
                    if (row.printCost) listPrice += row.printCost;
                    if (row.distCost) listPrice += row.distCost;
                    if (row.freight) listPrice += row.freight
                    res[0][ind]["listPrice"] = `$ ${listPrice}`;
                    if(!listPrice) res[0][ind]["listPrice"] = `-`;
                    res[0][ind]["netMargin"] = `$ ${row.price - listPrice}`
                })
            }
            if (res[0].length < 16) setSkip(-1);
            setdata([...data, ...res[0]]);
        })

    }
    useEffect(() => {
        if (state) setStatus(state.message)
    }, [])
    useEffect(() => {
        if (!searchIsbn && !status && !dateRange.length && !state) initReport()
        else {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
            debounceTimer.current = setTimeout(() => {
                initReport();
            }, 500);
        }
        return () => {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
        };
    }, [searchIsbn, status, dateRange[1]]);
    const serviceId = localStorage.getItem("serviceId");
    const pStyle = {
        fontFamily: '"Roboto", sans-serif',
        letterSpacing: '0.03125rem',
        fontSize: "1rem",
        color: '#8d9aa5',
        overflowWrap: "break-word",
    }
    const dialogStyle = {
        fontFamily: '"Merriweather", serif',
        letterSpacing: '0.0625rem',
        color: '#333333',
        fontWeight: 'bolder'
    }

    const isbnCheckUtility = (row) => {
        const changeHandler = () => {
            const updateIsbnChecked = { ...isbnPriceHandler, [row.isbn13]: true }
            setIsbnPriceHandler(updateIsbnChecked)
        }
        return <Checkbox onChange={changeHandler} checked={row.isbn13 in isbnPriceHandler}></Checkbox>
    }

    const addUpdate = (bool) => {
        const data = { uploadTypeList: bool };
        history.push({
            pathname: '/wm-upload',
            state: { data }
        });
    }

    const onSubmit = async () => {
        let formData = new FormData()
        const data = [["isbn13"]];
        if (update === "update") data[0].push("price")
        for (const key in isbnPriceHandler) {
            if (update === "update") {
                if (!isbnPriceHandler[key].length) {
                    toast.current.show({ severity: 'info', summary: 'Price field must not be empty', life: 7000 });
                    return
                }
                data.push([key, isbnPriceHandler[key]])
            }
            else data.push([key])
        }
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const output = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([output], { type: 'application/octet-stream' });
        const file = new File([blob], 'isbn_price.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        formData.append("file", file);
        formData.append("fileTypeList", false);
        formData.append("reason", update)
        formData.append("serviceId", serviceId);
        if (update) {
            let res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/inputFeedFile", formData);
            if (res.data.status === 200) toast.current.show({ severity: 'success', summary: 'Update Request Sent', life: 7000 });
            else toast.current.show({ severity: 'info', summary: '', life: 7000 });
        }
        else toast.current.show({ severity: 'info', summary: 'Fill all fields', life: 7000 });
        setIsbnPriceHandler({})
        setUpdateShow(false);
        setUpdate(null)
    }

    const handleDateChange = (e) => {
        setSkip(0);
        setFirst(0)
        setdata([])
        const [startDate, endDate] = e.value;
        let formattedStartDate = null;
        let formattedEndDate = null;
        if (startDate !== null) {
            formattedStartDate = moment(startDate).startOf('day').toDate();
        }
        if (endDate !== null) {
            formattedEndDate = moment(endDate).endOf('day').toDate();
        }
        const temp = [formattedStartDate, formattedEndDate];
        setDateRange(temp);
    };

    const StyledCalendar = () => {
        return (
            <div className="p-field  w-full">
                <Calendar
                    id="range"
                    value={dateRange}
                    placeholder="Select Date"
                    onChange={handleDateChange}
                    selectionMode="range"
                    showIcon
                    readOnlyInput
                    className="p-calendar flex align-items-center bg-white"
                    style={{
                        borderRadius: '17px',
                        border: '2px solid #0C6291',
                    }}
                    format="dd-mm-yy"
                />
            </div>
        );
    };

    const descriptionShow = (data, maxLength = 100) => {
        const shouldShowMore = data.length > maxLength;
        const displayedText = shouldShowMore && !showDescription ? data.slice(0, maxLength) + '...' : data;
        const setDesc = () => {
            setShowDescription(!showDescription);
        }
        const ButtonShow = (angle) => {
            return (
                <Button className='bg-white m-0 p-0 ml-2' onClick={setDesc}>
                    <i className={`pi ${angle} p-0 mb-1`} style={{ color: 'black' }}></i>
                </Button>
            );
        }
        return (
            <>
                <p className="m-0 text-lg font-light w-30rem mt-2" style={pStyle}>
                    {displayedText}
                </p>
                {shouldShowMore && (
                    <p className="m-0 text-lg font-light w-30rem mt-1" style={pStyle}>
                        <span>{showDescription ? 'show less' : 'show more'}</span>
                        {showDescription ? ButtonShow("pi-angle-up") : ButtonShow("pi-angle-down")}
                    </p>
                )}
            </>
        );
    }

    const metadataShow = () => {
        return (
            <Dialog visible={visible} className="col-12 lg:col-6" header={<div style={dialogStyle}>Details for ISBN {metaData.isbn13}</div>} onHide={() => {
                setVisible(false)
                setShowDescription(false)
            }}>
                <div className='grid'>
                    <div className='flex flex-wrap lg:col-4 col-12 mr-6'>
                        <img src={metaData.mainImageUrl} className='col-12 border-round-lg shadow-3' style={{
                            objectFit: 'cover',
                            background: 'linear-gradient(145deg, #cacaca, #f0f0f0)'
                        }} alt="Book cover" />
                    </div>
                    <div className='flex flex-column justify-content-start flex-wrap lg:col-6 col-12'>
                        <h2 className="m-0 text-3xl font-bold uppercase" style={dialogStyle}>{metaData.productName}</h2>
                        <p className="m-0 text-xl font-normal italic" style={{
                            fontFamily: '"Roboto", sans-serif',
                            letterSpacing: '0.03125rem',
                            color: '#555555'
                        }}>by {metaData.author}</p>
                        {metaData.shortDescription && descriptionShow(metaData.shortDescription)}
                        <div className='flex flex-wrap justify-content-between col-12 p-0 mt-2'>
                            <p className='text-xl p-0 ' style={pStyle}>${metaData.price}</p>
                            <p className='text-xl p-0 ' style={pStyle}>{metaData.publisher}</p>
                        </div>
                        <div className='flex flex-wrap justify-content-between col-12 p-0 mt-2'>
                            <p className='text-xl p-0 ' style={{
                                fontFamily: '"Roboto", sans-serif',
                                letterSpacing: '0.03125rem',
                                fontSize: "1rem",
                                color: '#8d9aa5'
                            }}>{metaData.numberOfPages} pages</p>
                            <p className='text-xl p-0 ' style={pStyle}>Original Language:{`${metaData.originalLanguages}`}</p>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }

    const priceUpdate = (row) => {
        if (update && update === "update") {
            return <InputText
                className=" bg-white "
                style={{

                }}
                onChange={(e) => {
                    const value = e.target.value;
                    const validNumber = /^[0-9]*[.,]?[0-9]*$/.test(value);
                    if (validNumber) {
                        const formattedValue = value.replace(/,/g, '.');
                        let temp = { ...isbnPriceHandler, [row.isbn13]: formattedValue };
                        setIsbnPriceHandler(temp);
                    }
                }
                }
                value={row.isbn13 in isbnPriceHandler ? isbnPriceHandler[row.isbn13] : row.price}
                type="text"
            ></InputText>
        }
        return `$ ${row.price}`
    }

    const setMeta = (e) => {
        e.data.author = e.data.author.split('|').join(', ');
        const tempElement = document.createElement('div');
        tempElement.innerHTML = e.data.shortDescription;
        e.data.shortDescription = tempElement.textContent || tempElement.innerText;
        setMetaData(e.data);
        setVisible(true)
    }

    const serachBox = (Func, placeHolder, val) => {
        return (
            <div className="p-field  w-12">
                <InputText
                    className="p-calendar flex align-items-center bg-white w-12"
                    style={{
                        borderRadius: '17px',
                        border: '2px solid #0C6291',
                    }}
                    onChange={(e) => {
                        Func(e.target.value)
                        if (placeHolder !== "Price") {
                            setSkip(0);
                            setFirst(0)
                            setdata([])
                        }
                    }}
                    placeholder={placeHolder}
                    value={val ?? ""}
                ></InputText>
            </div>
        );
    }

    const onDownload = async () => {
        setDownloadInfoMsg(true)
        let res = await Filter()
        let Data = [];
        let filename = "listingStatus.xlsx";
        const toDownloadKeys = ["isbn13", "price", "msrp", "modifiedOn", "createdOn", ]
        res.map((item) => {
            let temp = {};
            temp["listedOn"] = item.walmart?.listingDate ?? ""
            for(const key of toDownloadKeys){
                temp[key] = item[key]
            }
            temp["title"] = item.productName
            temp["listingStatus"] = item.walmart?.listingStatus ?? ""
            temp["listingError"] = item.walmart?.listingErrors?.msg ?? ""
            Data.push(temp);
        })
        let ws = XLSX.utils.json_to_sheet(Data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, filename);
        setDownloadInfoMsg(false)
    };

    const generalButton2 = (icon, text, Func, bool, bgColour, none) => {
        return (
            <div className={none ? "" : `ml-1 mr-4 mb-2 `}>
                <Button className="text-white" style={{ backgroundColor: `${bgColour}` }} onClick={() => Func(bool)} label={text} >
                    <i className={`pi ${icon} ml-2`} style={{ color: '#FFFFFF' }} ></i>
                </Button>
            </div>
        );
    };

    const statusList = [
        { label: "All", value: "All" },
        { label: "Success", value: "Success" },
        { label: "Failure", value: "Failed" },
        { label: "Submitted", value: "Submitted" }
    ]

    const updateList = [
        { label: "Update Price", value: "update" },
        { label: "Suspend Listing", value: "suspendListing" },
        { label: "Retire Listing", value: "deleteListing" }
    ]

    const TollSelector = (specific, list, setFunc, placeHolder) => {
        const customTemplate = (option) => (
            <div className="flex align-items-center">
                <i className="" style={{ color: '#0C6291' }}></i>
                <span>{option.label}</span>
            </div>
        );
        return (
            <div className=" ">
                <Dropdown
                    id="tollDropdown"
                    value={specific}
                    options={list}
                    onChange={(e) => {
                        setFunc(e.value)
                        setIsbnPriceHandler({})
                        if (list !== updateList) {
                            setSkip(0);
                            setFirst(0)
                            setdata([])
                        }
                    }}
                    placeholder={
                        <span className="flex align-items-center" style={{ color: '#0C6291' }}>
                            <i className="" style={{ marginRight: '8px' }}></i>
                            {placeHolder}
                        </span>
                    }
                    itemTemplate={customTemplate}
                    className=" flex align-items-center bg-white  mb-2"
                    style={{
                        borderRadius: '20px',
                        border: '2px solid #0C6291'
                    }}
                />
            </div>
        );
    };

    const Filter = async () => {
        let payload = {
            filter: {},
            sort : [["walmart.listingDate",-1]],
            projection:{
                "isbn13":1,
                "productName":1,
                price:1,
                msrp:1,
                printCost:1,
                distCost:1,
                freight:1,
                listPrice:1,
                modifiedOn:1,
                createdOn:1,
                "walmart.listingErrors.msg":1,
                "walmart.listingStatus":1,
                "walmart.listingDate":1
            }

        };
        if (dateRange.length > 0) {
            payload.filter["listingDate"] = {
                $gte: dateRange[0],
                $lte: dateRange[1]
            }
        }
        if (status && status!=="All") payload.filter["walmart.listingStatus"] = status;
        if (searchIsbn) payload.filter["isbn13"] = parseInt(searchIsbn);
        let response = await plazaServices.apiGeneralFunction(serviceId, payload, "nq-processing-status");
        return response;
    }
    const dateShow = (item) => {
        let temp = moment(item.createdOn, 'YYYY-MM-DD,h:mm').format('MMM, Do YYYY,h:mm');
        return <span>{temp}</span>
    }

    const listingDate = (item) => {
        let temp = moment(item.walmart.listingDate, 'YYYY-MM-DD,HH:mm').format('MMM, Do YYYY,HH:mm');
        return <span>{temp}</span>
    }

    const dateDiff = (item) => {
        const convertMillisecondsToHoursMinutes = (milliseconds) => {
            const totalMinutes = Math.floor(milliseconds / 60000);
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return { hours, minutes };
        };
        let date1 = moment(item.createdOn)
        if (item.reason) date1 = moment(item.modifiedOn);
        let date2 = moment(item.walmart.listingDate)
        const durationInMilliseconds = parseInt(date2.diff(date1));
        const { hours, minutes } = convertMillisecondsToHoursMinutes(durationInMilliseconds);
        return <span>{hours ? `${hours} hours` : ""} {minutes ? `${minutes} minutes` : ""}</span>
    }

    const statusShow = (row) => {
        let rung = "";
        if (row.walmart.listingStatus === "Success") rung = 'text-green-600';
        else rung = 'text-red-700'
        return <span className={rung}>{row.walmart.listingStatus}</span>
    }

    const onReset = () => {
        setDateRange([]);
        setSearchIsbn(null);
        setStatus(null)
        setdata([])
    }

    return (
        <div className='grid flex col-12 mt-2 justify-content-center ml-1'>
            <Toast ref={toast} />
            <div className='col-12 flex flex-wrap sm:flex  justfy-content-between align-items-center'>
                <div className='flex mb-1 col-12  lg:col-3'>{serachBox(setSearchIsbn, "Search By ISBN", searchIsbn)}</div>
                <div className='flex mb-1 col-12 lg:col-3'>{StyledCalendar()}</div>
                <div className='flex mb-1 mt-2 col-12 lg:col-5 flex align-items-center'>
                    {TollSelector(status, statusList, setStatus, "Select Status")}
                    {generalButton2("", "Reset", onReset, true, "#B22222")}
                </div>
                <div className="col-12 lg:col-3 ">

                </div>
            </div>
            <div className='col-12 card flex flex-wrap justify-content-end'>
                <div className='col-12 flex flex-column justify-content-center mt-0'>
                    <div className='grid  col-12 lg:justify-content-end align-items-center justify-content-between '>
                        {downloadInfoMsg && <p  className="text-2xl lg:mr-4 mt-1" style={{color:"blue"}}>Processing Download Request ...</p>}
                        {generalButton2("pi-plus", "", addUpdate, true, "#0C6291")}
                        {generalButton2("", "Bulk Update", addUpdate, false, "#0C6291")}
                        {generalButton2("pi-download", "Download", onDownload, "", "#0C6291")}
                        {!updateShow ? generalButton2("pi-update", "Update", () => setUpdateShow(!updateShow), "", "#0C6291") : generalButton2("pi-update", "Cancel Update", () => { setUpdateShow(!updateShow); setUpdate(null) }, "", "#0C6291")}
                        {updateShow && TollSelector(update, updateList, setUpdate, "Select Update")}
                        {updateShow && generalButton2("pi-update", "Submit", onSubmit, "", "#489487")}
                    </div>
                    <DataTable value={data} selectionMode='single' responsiveLayout="scroll" rows={15} style={{ marginTop: "0" }} paginator className='datatable-box bifarcation-box' onRowSelect={(row) => {
                        setVisible(true)
                        setMeta(row)
                    }}
                        first={first} onPage={(e) => {
                            setFirst(e.first)
                            if (e.first > first && skip !== -1) {
                                let pre = skip + 11;
                                setSkip(pre);
                                initReport()
                            };
                        }}>
                        {updateShow && update && update !== "update" && <Column className=" " body={isbnCheckUtility}></Column>}
                        <Column headerClassName='bg-cyan-500 text-white font-medium' header="Isbn" bodyClassName='bg-white font-medium  surface-300 bg-gray-200' field="isbn13"></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Title" field="productName" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 col-5' style={{minWidth:"260px"}}></Column>
                        <Column headerClassName='bg-cyan-500 text-white' body={listingDate} header="Listed On" bodyClassName='bg-white font-medium  surface-300 bg-gray-200'></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Price" body={priceUpdate} bodyClassName='bg-white font-medium  surface-300 bg-gray-200'></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Maximum Retail Price"  bodyClassName='bg-white font-medium  surface-300 bg-gray-200' body={(row)=>{return`$ ${row.msrp}`}}></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Print Cost"  bodyClassName='bg-white font-medium  surface-300 bg-gray-200' body={(row)=>{return row.printCost?`$ ${row.printCost}`:"-"}}></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Dist Cost"  bodyClassName='bg-white font-medium  surface-300 bg-gray-200' body={(row)=>{return row.distCost?`$ ${row.distCost}`:"-"}}></Column>
                        <Column header="Freight" headerClassName='bg-cyan-500 text-white'  bodyClassName='bg-white font-medium  surface-300 bg-gray-200' body={(row)=>{return row.freight?`$ ${row.freight}`:"-"}}></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Landing Cost" field='listPrice' bodyClassName='bg-white font-medium  surface-300 bg-gray-200'></Column>
                        <Column header="Net Margin" headerClassName='bg-cyan-500 text-white' field="netMargin" bodyClassName='bg-white font-medium  surface-300 bg-gray-200' sortable ></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Modified On" body={(row) => { return moment(row.modifiedOn, 'YYYY-MM-DD,HH:mm').format('MMM, Do YYYY,HH:mm') }} bodyClassName='bg-white font-medium  surface-300 bg-gray-200'></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Submitted On" body={dateShow} bodyClassName='bg-white font-medium  surface-300 bg-gray-200'></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Time Elapsed" body={dateDiff} bodyClassName='bg-white font-medium  surface-300 bg-gray-200'></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Listing Partner" body={() => { return "walmart" }} bodyClassName='bg-white font-medium  surface-300 bg-gray-200'></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Status" bodyClassName='bg-white font-medium  surface-300 bg-gray-200' body={statusShow}  ></Column>
                        <Column header="Error" headerClassName='bg-cyan-500 text-white' bodyClassName="bg-white font-medium  surface-300 bg-gray-200" field="walmart.listingErrors.msg"></Column>
                    </DataTable>
                </div>
            </div>
            {metadataShow()}
        </div>
    )
}