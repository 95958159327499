import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "prismjs/themes/prism-coy.css";
import { Provider } from "react-redux";
import store from "./store";
import AppWrapper from "./AppWrapper";
import Context from "./utilities/context/context";
ReactDOM.render(
    <Context>
        <Provider store={store}>
            <BrowserRouter>
                <AppWrapper />
            </BrowserRouter>
        </Provider>
    </Context>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
