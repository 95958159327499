import React, { Component } from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Skeleton } from 'primereact/skeleton';
import FuelTrend from "./components/FuelTrend";
import DgBatteryTrend from "./components/DgBatteryTrend";
import FuelConsumption from "./components/FuelConsumption";
import Session from "./components/Session";
import FuellingEvent from "./components/FuellingEvent";
import { FuelSrvice } from "../../service/FuelService";


class FuelHistoricalReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            things: null,
            serviceId: null
        };
        this.fuelService = new FuelSrvice();
    }

    componentDidMount = () => {
        const serviceID = localStorage.getItem('serviceId');
        this.fuelService.getThings(serviceID).then(res => {
            let things = [];
            res.responseData.forEach(item => {
                things.push({name: item, code: item });
            });

            this.setState({ 
                things: things, 
                serviceId: serviceID
            });
        }).catch(reason => {
            console.log(reason);
        })
    }

    render = () => this.state.things && this.state.serviceId ? (
        <div className="grid">
                <div className="col-12">
                    <Accordion activeIndex={0}>
                        <AccordionTab header="Fuel Consumption Report">
                            <FuelConsumption 
                                serviceId={this.state.serviceId} 
                                things={this.state.things} 
                            />
                        </AccordionTab>
                        <AccordionTab header="Session Report">
                            <Session 
                                serviceId={this.state.serviceId} 
                                things={this.state.things} 
                            />
                        </AccordionTab>
                        <AccordionTab header="Fuelling Events">
                            <FuellingEvent 
                                serviceId={this.state.serviceId} 
                                things={this.state.things} 
                            />
                        </AccordionTab>
                        <AccordionTab header="Fuel Trend">
                            <FuelTrend 
                                serviceId={this.state.serviceId} 
                                things={this.state.things} 
                            />
                        </AccordionTab>
                        <AccordionTab header="DG Battery Trend">
                            <DgBatteryTrend 
                                serviceId={this.state.serviceId} 
                                things={this.state.things} 
                            />
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
    ) : (
        <div className="grid">
            <div className="col-12">
                <Skeleton className="mb-2"></Skeleton> 
            </div>
            <div className="col-12">
                <Skeleton className="mb-2"></Skeleton> 
            </div>
            <div className="col-12">
                <Skeleton className="mb-2"></Skeleton> 
            </div>
            <div className="col-12">
                <Skeleton className="mb-2"></Skeleton> 
            </div>
            <div className="col-12">
                <Skeleton className="mb-2"></Skeleton> 
            </div>
        </div>
    )
}

export default FuelHistoricalReport;