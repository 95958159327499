import React, { useState, useEffect, useRef, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber, getCountryCallingCode } from "react-phone-number-input";
import TenantService from "../service/tenant";
import { StateContext } from '../utilities/context/context';

const domainsMap = {
	"in.nextqore.com": "IN",
	"us.nextqore.com": "US"
}

const Login = () => {
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [errMsg, setErrMsg] = useState(null);
	const [disableBtn, setBtnDisabled] = useState(false);
	const [passwordType, setPasswordType] = useState("password");
	const [showPhoneInput, setShowPhoneInput] = useState(false)
	const {countryCode, countryCodeVal, fetchUserCountryCode,setCountryCodeVal}  = useContext(StateContext)
	const history = useHistory();
	const inputTypeNumber = useRef(false)
	useEffect(() => {
		if (localStorage.getItem('token')) history.push("/services");

		fetchUserCountryCode();
	}, [])

	const userLogin = async (e) => {
		e.preventDefault();
		let validationRes = validateInputs();
		if (typeof validationRes === "string") {
			setErrMsg(validationRes);
		}
		else {
			setBtnDisabled(true);
			let loginDetails = {
				username: isNumber(email) ? countryCodeVal + email.toLowerCase() : email.toLowerCase(),
				password: password,
			};
			let loginResponse = await TenantService.loginUser(loginDetails);
			if (!loginResponse.success || !loginResponse.responseData) {
				setBtnDisabled(false);
				if (loginResponse.message) {
					setErrMsg(loginResponse.message);
				}
				return
			}
			if (!loginResponse || !loginResponse.responseData || !loginResponse.responseData.idToken || !loginResponse.responseData.idToken.jwtToken || !loginResponse.responseData.idToken.payload.sub) return;
			else {
				localStorage.setItem("token", loginResponse.responseData.idToken.jwtToken);
				localStorage.setItem("username", loginResponse.responseData.idToken.payload.name);
				localStorage.setItem("exp", loginResponse.responseData.idToken.payload.exp);
				localStorage.setItem("email",(loginResponse.responseData.idToken.payload.phone_number? loginResponse.responseData.idToken.payload.phone_number: loginResponse.responseData.idToken.payload.email || "" ));

				if(loginResponse.responseData.idToken.payload.email &&  (loginResponse.responseData.idToken.payload.email == "support@nextqore.com")){
					localStorage.setItem("isSupport", true)
				}
				history.push("/services");
			}
		}
	}

	const togglePassword = (e) => {
		if (passwordType === "password") {
			setPasswordType("text")
			return;
		}
		setPasswordType("password")
	}

	const onforgotPassword = () => {
		history.push("/forgot-password");
	}
	const validateInputs = () => {
		if (!email) return "Please enter email/mobile";
		if (!password) return "please enter password";
		if (isNumber(email) && !isValidPhoneNumber(countryCodeVal + email)) return "Enter valid mobile no"
		if (email && !isNumber(email)) {
			let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			let res = emailRegex.test(email);
			if (!res) return "enter valid email";
		}
	}

	function isNumber(n) {
		if (n == "" || n == null || n == undefined) return false
		return Number(n) == n
	}

	const handleChange = (value) => {
		setEmail(value)
		if (!value.trim()) {
			setShowPhoneInput(false)
			inputTypeNumber.current = false
			return
		}
		let valIsNo = isNumber(value)
		if (valIsNo && inputTypeNumber.current === false) {
			setShowPhoneInput(true)
			inputTypeNumber.current = valIsNo
		}
		else if (!valIsNo && inputTypeNumber.current === true) {
			setShowPhoneInput(false)
			inputTypeNumber.current = false
		}
	}
	return (
		<div className="pages-body flex flex-column login-img">
			<div className="align-self-center mt-auto mb-auto">
				<div className="pages-panel card flex flex-column">
					<div className="Row-fixing">
						<img alt="" src="./images/nexLogo.svg" className="logo-fixing" /><br /><br />
					</div>
					<div className="pages-detail mb-6 px-6">Sign in to your Nextqore account</div>
					<form onSubmit={userLogin}>
						<div className="input-panel flex flex-column px-3">
							<div className="p-inputgroup">
								{!showPhoneInput && <span className="p-inputgroup-addon">
									<i className="pi pi-envelope"></i>
								</span>}
								<span className="p-float-label">
									{showPhoneInput && <PhoneInput id="userPhoneInput" style={{ borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px", borderTopRightRadius: 0, borderBottomRightRadius: 0, maxWidth: "7em" }} className="p-inputtext p-component p-filled w-full" value={countryCodeVal} onChange={setCountryCodeVal} international defaultCountry={countryCode} />}
									<InputText style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} type="text" id="inputgroup1" autoFocus={true} onChange={(e) => handleChange(e.target.value)} />
								</span>
							</div>
							<div className="p-inputgroup mt-3 mb-3">
								<span className="p-inputgroup-addon">
									<i className="pi pi-lock"></i>
								</span>
								<span className="p-float-label">
									<InputText style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} type={passwordType} id="inputgroup2" onChange={(e) => setPassword(e.target.value)} />

									<div className="p-inputgroup-addon" onClick={togglePassword}>
										{passwordType === "password" ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
									</div>
								</span>
							</div>
							<div>
								<div className='blue-text mb-2' style={{ float: "right", color: "#0c6291" }} onClick={() => onforgotPassword()}>Forgot Password ?</div>
							</div>
							<div className="errorstyle mb-1" style={{ color: "red" }}>{errMsg}</div>

						</div>
						<Button className="login-button mb-3 px-3" label="LOGIN" disabled={disableBtn} onClick={userLogin}></Button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default Login;
