import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import * as XLSX from "xlsx";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { NoiseService } from "../../service/NoiseService";
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";
import Subscribe from "../airQuality/Subscribe";
import service from "../../service/dbService";
import { useSelector } from 'react-redux';

const NoiseDashbord = () => {
    const [things, setThing] = useState(null);
    const [thingName, setThingName] = useState(null);
    const [noiseData, setNOiseData] = useState([]);
    const [dayNoiseData, setDayNoiseData] = useState([]);
    const [nightNoiseData, setNightNoiseData] = useState([]);
    const [date, setDate] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [optedService, setOptedService] = useState(false)
    const [loader, setLoader] = useState(true)

    const noiseService = new NoiseService();
    const serviceId = localStorage.getItem("serviceId");
    const houre = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    const analytics = false;
    const thingNames = useSelector(state => state.thingNameContainer);
    useEffect(() => {
        // const today = moment().subtract(1, "day").format("YYYY-MM-DD");
        const today = moment().format("YYYY-MM-DD");
        let promiseArr=[noiseService.getThingsData(serviceId)];
        if(thingNames.length ===0){
            promiseArr=[service.getThingNames(serviceId), noiseService.getThingsData(serviceId)];
        }
        Promise.all(promiseArr).then((res) => {
            if(thingNames.length>0){
                let first=[];
                thingNames.map((item)=>{
                    if(!Array.isArray(item)){
                       first.push(item); 
                    }
                })
                res=[first,...res];   
            }
            let _tempThingArr = [];
            if(res[1].length){
                setOptedService(true)
            }
            setLoader(false)
            res[0].forEach((item) => {
                _tempThingArr.push({
                    name: item.alias || item.description,
                    code: item.spaceName,
                });
            });
            setThing(_tempThingArr);
            setThingName(_tempThingArr[0]);
        });
        setDate(today);
    }, []);

    useEffect(() => {
        if (date && thingName) {
            const startTime = moment(date).startOf("day").format("YYYY-MM-DD HH:mm:ss");
            const endTime = moment(date).endOf("day").format("YYYY-MM-DD HH:mm:ss");
            initReport(startTime, endTime, thingName);
        }
    }, [date, thingName]);

    const initReport = (startTime, endTime, thingName) => {
        let max = [],
            avg = [],
            min = [],
            cat = [];
        let noiseData = [],
            noiseChartData = [];
        Promise.all([noiseService.getHourelyNoiseData(serviceId, startTime, endTime, thingName.code), noiseService.getMunitInterVelNoiseData(serviceId, startTime, endTime, thingName.code)]).then((res) => {
            if (res && res[0].length > 0) {
                noiseData = res[0].sort((a, b) => a._id - b._id);
            }
            if (res && res[1].length > 0) {
                noiseChartData = res[1];
                noiseChartData.forEach((item) => {
                    let timeH = item._id.hr;
                    let munitRange = item._id.min_range.split("-");
                    let timeString = timeH + ":" + munitRange[1];
                    cat.push(moment(timeString, "h:m").format("HH:mm a"));
                    max.push(item.maxNoise);
                    avg.push(parseFloat(item.avgNoise.toFixed(2)));
                    min.push(item.minNoise);
                });
            }
            let data = [];
            houre.forEach((elm) => {
                let a = noiseData.find((l) => l._id === elm);
                if (a) {
                    data.push({ ...a });
                }
            });
            const dayNoiseData = data.filter((item) => item._id >= 0 && item._id <= 11);
            const nightNoiseData = data.filter((x) => !dayNoiseData.includes(x));

            setDayNoiseData(dayNoiseData);
            setNightNoiseData(nightNoiseData);
            setNOiseData(noiseData);

            const chartData = getChartOPtion();
            chartData.series = [
                {
                    name: "Max",
                    data: max,
                    color: colorPalettes.color4,
                },
                {
                    name: "Avg",
                    data: avg,
                    color: colorPalettes.color3,
                },
                {
                    name: "Min",
                    data: min,
                    color: colorPalettes.color2,
                },
            ];
            chartData.xAxis.categories = cat;
            setChartData(chartData);
        });
    };

    const getChartOPtion = () => {
        return {
            chart: {
                type: "spline",
            },
            title: {
                text: `Noise Trend Chart(${moment(date).format("Do, MMM YYYY")})`,
                style: chartHeadingStyle,
            },
            xAxis: {
                categories: [],
                crosshair: true,
            },
            yAxis: {
                title: {
                    text: "Noise Level (db)",
                },
                plotLines: [
                    {
                        value: 55,
                        zIndex: 2,
                        width: 2,
                        dashStyle: "Dash",
                        color: "black",
                    },
                    {
                        value: 45,
                        zIndex: 2,
                        width: 2,
                        dashStyle: "Dash",
                        color: "black",
                    },
                ],
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0;text-transform:uppercase">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} db</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            legend: {
                itemStyle: {
                    textTransform: "uppercase",
                },
            },
            series: [],
        };
    };

    const dateTamplate = (item) => {
        return moment(item._id, "h").format("hh a") + " - " + moment(item._id, "h").add(1, "h").format("hh a");
    };

    const maxbodyTemplate = (item) => {
        if (item.maxNoise === "--") {
            return "--";
        }
        if (item._id >= 6 && item._id <= 10) {
            return item.maxNoise > 55 ? <span className="custome-table-cell bg-yellow-400">{item.maxNoise}</span> : <span className="custome-table-cell bg-green-400">{item.maxNoise}</span>;
        } else {
            return item.maxNoise >= 45 ? <span className="custome-table-cell bg-yellow-400">{item.maxNoise}</span> : <span className="custome-table-cell bg-green-400">{item.maxNoise}</span>;
        }
    };

    const avgBodyTemplate = (item) => {
        if (item.avgNoise === "--") {
            return "--";
        }
        if (item._id >= 6 && item._id <= 10) {
            return item.avgNoise > 55 ? <span className="custome-table-cell bg-yellow-400">{item.avgNoise.toFixed(2)}</span> : <span className="custome-table-cell bg-green-400">{item.avgNoise.toFixed(2)}</span>;
        } else {
            return item.avgNoise >= 45 ? <span className="custome-table-cell bg-yellow-400">{item.avgNoise.toFixed(2)}</span> : <span className="custome-table-cell bg-green-400">{item.avgNoise.toFixed(2)}</span>;
        }
    };

    const minBodyTemplate = (item) => {
        if (item.minNoise === "--") {
            return "--";
        }
        if (item._id >= 6 && item._id <= 10) {
            return item.minNoise >= 55 ? <span className="custome-table-cell bg-yellow-400">{item.minNoise}</span> : <span className="custome-table-cell bg-green-400">{item.minNoise}</span>;
        } else {
            return item.minNoise >= 45 ? <span className="custome-table-cell bg-yellow-400">{item.minNoise}</span> : <span className="custome-table-cell bg-green-400">{item.minNoise}</span>;
        }
    };

    const aboveNoisLevelTemp = (item) => {
        let jsx = "--";
        if (item.AboveLimitCnt === "--") {
            return "--";
        }
        if (item.AboveLimitCnt > 150) {
            jsx = <span className="custome-table-cell bg-orange-400">{item.AboveLimitCnt}</span>;
        } else if (item.AboveLimitCnt > 50 && item.AboveLimitCnt < 150) {
            jsx = <span className="custome-table-cell bg-yellow-400">{item.AboveLimitCnt}</span>;
        } else {
            jsx = <span className="custome-table-cell bg-green-400">{item.AboveLimitCnt}</span>;
        }
        return jsx;
    };

    const yellowTemp = (item) => {
        return <span className="custome-table-cell bg-yellow-400">{item.greaterLimit}</span>;
    };
    const greenTemp = (item) => {
        return <span className="custome-table-cell bg-green-400">{item.lessThanLimit}</span>;
    };

    const limitTemp = (item) => {
        if (item.instanceThresold === "0-50") {
            return <span className="custome-table-cell bg-green-400">{item.instanceThresold}</span>;
        } else if (item.instanceThresold === "51-150") {
            return <span className="custome-table-cell bg-yellow-400">{item.instanceThresold}</span>;
        } else {
            return <span className="custome-table-cell bg-orange-400">{item.instanceThresold}</span>;
        }
    };

    const noiseLimitData = [
        {
            sl: 1,
            time: "Day Time (6am - 10pm)",
            noiseLimit: 55,
            lessThanLimit: "Green",
            greaterLimit: "Yellow",
        },
        {
            sl: 2,
            time: "Night Time (10pm - 6am)",
            noiseLimit: 45,
            lessThanLimit: "Green",
            greaterLimit: "Yellow",
        },
    ];
    const noiseRangeData = [
        {
            sl: 1,
            category: "Satisfactory",
            instanceThresold: "0-50",
        },
        {
            sl: 2,
            category: "Poor",
            instanceThresold: "51-150",
        },
        {
            sl: 3,
            category: "Severe",
            instanceThresold: "> 150",
        },
    ];

    // const downloadReport = () => {
    //     let dayData = [],
    //         nightData = [];

    //     dayData = dayNoiseData.map((item) => {
    //         if (analytics) {
    //             return {
    //                 "Date & Time": moment(date).format("Do MMM, YYYY") + " " + moment(item._id, "h").format("hha") + " - " + moment(item._id, "h").add(1, "h").format("hha"),
    //                 "Maximum (db)": item.maxNoise,
    //                 "Average (db)": item.avgNoise.toFixed(2),
    //                 "Minimum (db)": item.minNoise,
    //                 "Instances above Limit": item.AboveLimitCnt,
    //                 Location: thingName.name,
    //             };
    //         } else {
    //             return {
    //                 "Date & Time": moment(date).format("Do MMM, YYYY") + " " + moment(item._id, "h").format("hha") + " - " + moment(item._id, "h").add(1, "h").format("hha"),
    //                 "Maximum (db)": item.maxNoise,
    //                 "Average (db)": item.avgNoise.toFixed(2),
    //                 "Minimum (db)": item.minNoise,
    //                 // "Instances above Limit": item.AboveLimitCnt,
    //                 Location: thingName.name,
    //             };
    //         }
    //     });
    //     nightData = nightNoiseData.map((item) => {
    //         if (analytics) {
    //             return {
    //                 "Date & Time": moment(date).format("Do MMM, YYYY") + " " + moment(item._id, "h").format("hha") + " - " + moment(item._id, "h").add(1, "h").format("hha"),
    //                 "Maximum (db)": item.maxNoise,
    //                 "Average (db)": item.avgNoise.toFixed(2),
    //                 "Minimum (db)": item.minNoise,
    //                 "Instances above Limit": item.AboveLimitCnt,
    //                 Location: thingName.name,
    //             };
    //         } else {
    //             return {
    //                 "Date & Time": moment(date).format("Do MMM, YYYY") + " " + moment(item._id, "h").format("hha") + " - " + moment(item._id, "h").add(1, "h").format("hha"),
    //                 "Maximum (db)": item.maxNoise,
    //                 "Average (db)": item.avgNoise.toFixed(2),
    //                 "Minimum (db)": item.minNoise,
    //                 // "Instances above Limit": item.AboveLimitCnt,
    //                 Location: thingName.name,
    //             };
    //         }
    //     });
    //     let ws1 = XLSX.utils.json_to_sheet(dayData);
    //     let ws2 = XLSX.utils.json_to_sheet(nightData);
    //     let wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, ws1, "Noise Levels for 12am-12pm");
    //     XLSX.utils.book_append_sheet(wb, ws2, "Noise Levels for 12pm-12am");
    //     XLSX.writeFile(wb, "daily_noise_report.xlsx");
    // };

    return (
       !loader ? optedService ?
        <div className="grid">
            <div className="col-6 grid">
                <div className="field col-12 lg:col-6">
                    <label htmlFor="minmax" className="block">
                        Select Date
                    </label>
                    <Calendar value={new Date(date)} onChange={(e) => setDate(moment(e.value).format("YYYY-MM-DD"))} maxDate={new Date(moment().format("YYYY-MM-DD"))} dateFormat="dd/mm/yy" className="bg-white w-full" showIcon style={{ maxWidth: "300px" }} />
                </div>
                <div className="field col-12 lg:col-6">
                    <label htmlFor="minmax" className="block">
                        Select Location
                    </label>
                    <Dropdown value={thingName} options={things} onChange={(e) => setThingName(e.value)} className="bg-white w-full" optionLabel="name" placeholder="Select a City" />
                </div>
            </div>
            {/* <div className="col-6 text-right">
                <Button label="Download Report" onClick={() => downloadReport()} className="mt-5" icon="pi pi-download" />
            </div> */}
            <div className="col-12">
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="card">
                            <h5>Noise Limits - Residental Area</h5>
                            <DataTable value={noiseLimitData} showGridlines responsiveLayout="scroll">
                                <Column header="#" field="sl" headerClassName="bg-primary"></Column>
                                <Column header="Time" field="time" headerClassName="bg-primary"></Column>
                                <Column header="Noise Limit (db)" field="noiseLimit" headerClassName="bg-primary"></Column>
                                <Column header="<= Limit" field="lessThanLimit" body={greenTemp} headerClassName="bg-primary" bodyClassName="p-0"></Column>
                                <Column header="> Limit" field="greaterLimit" body={yellowTemp} headerClassName="bg-primary" bodyClassName="p-0"></Column>
                            </DataTable>
                        </div>
                    </div>
                    <div className="col-12 md:col-6">
                        {analytics && (
                            <div className="card">
                                {" "}
                                <h5>Noise Category Range</h5>
                                <DataTable value={noiseRangeData} showGridlines responsiveLayout="scroll">
                                    <Column header="#" field="sl" headerClassName="bg-primary"></Column>
                                    <Column header="Category" field="category" headerClassName="bg-primary"></Column>
                                    <Column header="Instances above Threshold" field="instanceThresold" body={limitTemp} headerClassName="bg-primary" bodyClassName="p-0"></Column>
                                </DataTable>
                            </div>
                        )}
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="card">
                            <h5>Noise Levels - Hourly Min, Max and Avg (12am-12pm)</h5>
                            <DataTable value={dayNoiseData} showGridlines responsiveLayout="scroll">
                                <Column header="Time" body={dateTamplate} field="_id" headerClassName="bg-primary" bodyClassName="white-space-nowrap"></Column>
                                <Column header="Maximum (db)" field="maxNoise" body={maxbodyTemplate} headerClassName="bg-primary" bodyClassName="p-0 text-center"></Column>
                                <Column header="Average (db)" body={avgBodyTemplate} field="avgNoise" headerClassName="bg-primary" bodyClassName="p-0 text-center"></Column>
                                <Column header="Minimum (db)" field="minNoise" body={minBodyTemplate} headerClassName="bg-primary" bodyClassName="p-0 text-center"></Column>
                                {analytics && <Column header="Instances above Limit" field="AboveLimitCnt" body={aboveNoisLevelTemp} headerClassName="bg-primary" bodyClassName="p-0 text-center"></Column>}
                            </DataTable>
                        </div>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="card">
                            <h5>Noise Levels - Hourly Min, Max and Avg (12pm-12am)</h5>
                            <DataTable value={nightNoiseData} showGridlines responsiveLayout="scroll">
                                <Column header="Time" body={dateTamplate} field="_id" headerClassName="bg-primary" bodyClassName="white-space-nowrap"></Column>
                                <Column header="Maximum (db)" field="maxNoise" body={maxbodyTemplate} headerClassName="bg-primary" bodyClassName="p-0 text-center"></Column>
                                <Column header="Average (db)" body={avgBodyTemplate} field="avgNoise" headerClassName="bg-primary" bodyClassName="p-0 text-center"></Column>
                                <Column header="Minimum (db)" field="minNoise" body={minBodyTemplate} headerClassName="bg-primary" bodyClassName="p-0 text-center"></Column>
                                {analytics && <Column header="Instances above Limit" field="AboveLimitCnt" body={aboveNoisLevelTemp} headerClassName="bg-primary" bodyClassName="p-0 text-center"></Column>}
                            </DataTable>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <HighchartsReact highcharts={Highcharts} options={chartData} />
                </div>
            </div>
        </div>
    : <Subscribe serviceName="Noise Monitoring"/> : <div style={{ width: "100%", textAlign: "center", height: "80vh", verticalAlign: "center", position: "relative" }}><i className="pi pi-spin pi-spinner" style={{ fontSize: '4rem', verticalAlign: "middle",position:"absolute", top: "45%" , left: "45%" }}></i></div>);
};

export default NoiseDashbord;
