import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { Skeleton } from "primereact/skeleton";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { secondsToHms, dispatchAction, secondsToHm } from "../../utilities/utillFunction";
import { mapKey } from "../../utilities/constant";
import { VehicleSrvice } from "../../service/VehicleService";
import { convertValue } from "../../utilities/unitConversion";
import { Badge } from "primereact/badge";
import { Card } from "primereact/card";
import { Timeline } from "primereact/timeline";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { Rating } from "primereact/rating";
import { Tooltip } from "primereact/tooltip";
import HistoryAcceleration from "./Dialogue/History/HistoryAcceleration";
import HistoryBraking from "./Dialogue/History/HistoryBraking";
import HistoryCornering from "./Dialogue/History/HistoryCornering";
import HistorySpeeding from "./Dialogue/History/HistorySpeeding";
import { FuelSrvice } from "../../service/FuelService";
import { customizedMarker, customizedContent, loader } from "../../utilities/UICommonFunc";
import { backOff } from "exponential-backoff";
const map_start_icon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#00A42F; stroke:none;" d="M31 60L33 60C38.9485 49.275 51.7089 35.8509 51.8164 23C51.9872 2.56881 22.1996 -3.31481 13.9699 15C7.39209 29.6386 23.7505 48.1572 31 60z"/><path style="fill:#f73a3a; stroke:none;" d="M21 6L22 7L21 6M42 6L43 7L42 6z"/><path style="fill:#FFFFFF; stroke:none;" d="M28.1088 16.0278C20.0658 20.5369 26.7819 33.892 34.956 29.8056C44.4446 25.0619 37.288 10.8817 28.1088 16.0278z"/><path style="fill:#ff0101; stroke:none;" d="M24 25L25 26L24 25M39 25L40 26L39 25M49 31L50 32L49 31M48 33L49 34L48 33M47 35L48 36L47 35z"/><path style="fill:#f73a3a; stroke:none;" d="M19 40L20 41L19 40z"/><path style="fill:#ff0101; stroke:none;" d="M43 42L44 43L43 42z"/><path style="fill:#e3641d; stroke:none;" d="M22 45L23 46L22 45z"/><path style="fill:#ff0101; stroke:none;" d="M38 50L39 51L38 50z"/><path style="fill:#e3641d; stroke:none;" d="M33 58L34 59L33 58z"/></svg>';

const map_end_icon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#F14003; stroke:none;" d="M31 60L33 60C38.9485 49.275 51.7089 35.8509 51.8164 23C51.9872 2.56881 22.1996 -3.31481 13.9699 15C7.39209 29.6386 23.7505 48.1572 31 60z"/><path style="fill:#f73a3a; stroke:none;" d="M21 6L22 7L21 6M42 6L43 7L42 6z"/><path style="fill:#FFFFFF; stroke:none;" d="M28.1088 16.0278C20.0658 20.5369 26.7819 33.892 34.956 29.8056C44.4446 25.0619 37.288 10.8817 28.1088 16.0278z"/><path style="fill:#ff0101; stroke:none;" d="M24 25L25 26L24 25M39 25L40 26L39 25M49 31L50 32L49 31M48 33L49 34L48 33M47 35L48 36L47 35z"/><path style="fill:#f73a3a; stroke:none;" d="M19 40L20 41L19 40z"/><path style="fill:#ff0101; stroke:none;" d="M43 42L44 43L43 42z"/><path style="fill:#e3641d; stroke:none;" d="M22 45L23 46L22 45z"/><path style="fill:#ff0101; stroke:none;" d="M38 50L39 51L38 50z"/><path style="fill:#e3641d; stroke:none;" d="M33 58L34 59L33 58z"/></svg>';

const getLineChartOptions = () => {
    const textColor = "rgba(0, 0, 0, 0.87)";
    const gridLinesColor = "rgba(160, 167, 181, .3)";

    return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    color: textColor,
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: textColor,
                },
                grid: {
                    color: gridLinesColor,
                },
            },
            y: {
                ticks: {
                    color: textColor,
                },
                grid: {
                    color: gridLinesColor,
                },
            },
        },
    };
};

class VehicleOBDHistory extends Component {
    mapRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            make: this.props.make,
            model: this.props.model,
            thingName: this.props.name,
            vehicleData: null,
            reportData: null,
            stoppagePoints: null,
            geoArray: null,
            selectedThingName: null,
            date4: new Date(moment().subtract(1, "days").format("YYYY-MM-DD")),
            checked1: false,
            isError: false,
            aceleration: null,
            braking: null,
            cornering: null,
            speeding: null,
            displayBasic: false,
            displayBasic1: false,
            displayBasic2: false,
            displayBasic3: false,
            greenDrivingScore: null,
        };
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.serviceId = localStorage.getItem("serviceId");
        this.vehicleService = new VehicleSrvice();
        this.fuelService = new FuelSrvice();
        this.handStoppage = this.handStoppage.bind(this);
        this.handleduration = this.handleduration.bind(this);
        this.handleIdling = this.handleIdling.bind(this);
        this.handelts = this.handelts.bind(this);

        this.lineChartOptions = getLineChartOptions();
        this.sampleGeoEvents = [
            { thingname: "cb-chevywht1", state: "InsideGF", name: "GeoFence_1" },
            { thingname: "cb-chevywht1", state: "OutsideGF", name: "GeoFence_1" },
        ];
        addLocale("es", {
            firstDayOfWeek: 1,
            dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
            dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
            dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
            monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
            monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
            today: "Hoy",
            clear: "Limpiar",
        });
    }

    onClick(name, position) {
        let state = {
            [`${name}`]: true,
        };

        if (position) {
            state = {
                ...state,
                position,
            };
        }

        this.setState(state);
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false,
        });
    }

    componentDidMount = () => {
        let query = {
            operation: "aggregate",
            aggregate: [{ $match: { thingName: this.state.thingName, date:{"$lt": this.props.date } } }, { $sort: { startTime: -1 } }, { $limit: 1 }],
        };

        Promise.all([this.vehicleService.getAggregateHistory(this.serviceId, query)]).then((res) => {
            if (res && res[0].length > 0) {
                this.setState({ isError: false });
                const vehicleData = res[0][0];

                let greenDrivingScore = 0;
                if (vehicleData.greendriving) {
                    greenDrivingScore = vehicleData.greendriving.driveroverallscore;
                }
                this.maxDate = new Date(moment(vehicleData.date));

                this.setState({ greenDrivingScore, vehicleData, date4: new Date(moment(vehicleData.date, "YYYY-MM-DD").format("YYYY-MM-DD")) }, () => {
                    this.getData(vehicleData.date);
                });
            } else {
                this.setState({ isError: true });
            }
        });
    };

    initReport = async (date) => {
        let query = {
            filter: {
                date,
                thingName: this.state.thingName,
            },
        };
        let greenDrivingScore;
        let vehicleData = await this.vehicleService.getHistory(this.serviceId, query);

        if (vehicleData && vehicleData.length) {
            if (vehicleData[0].greendriving) greenDrivingScore = vehicleData[0].greendriving.driveroverallscore;

            this.setState({ isError: false, greenDrivingScore, vehicleData: vehicleData[0] }, () => {
                this.getData(date);
            });
        } else {
            this.setState({ isError: true });
        }
    };

    getData = (date) => {
        let start = moment(date).startOf("day").format("YYYY-MM-DD HH:mm:ss");
        let end = moment(date).endOf("day").format("YYYY-MM-DD HH:mm:ss");

        let geoEventQuery = { ts: { $gte: start, $lte: end }, thingname: this.state.thingName };
        let gdEventsQuery = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        thingName: this.state.thingName,
                        ts: { $gte: start, $lte: end },
                        greendrivingtype: {
                            $gt: 0,
                        },
                    },
                },
                {
                    $group: {
                        _id: "$greendrivingtype",
                        data: {
                            $push: {
                                greendrivingtype: "$greendrivingtype",
                                greendrivingvalue: "$greendrivingvalue",
                                greendrivingeventduration: "$greendrivingeventduration",
                                overspeeding: "$overspeeding",
                                longitude: "$longitude",
                                latitude: "$latitude",
                                ts: "$ts",
                            },
                        },
                    },
                },
            ],
            allowDiskUse: true,
        };

        let fuelConsumedQuery = { filter: { thingName: this.state.thingName, date } };

        Promise.all([this.vehicleService.getGeoEvents(geoEventQuery), this.vehicleService.getVehicleDivingData(this.serviceId, gdEventsQuery), this.fuelService.getFuelConsumedData(this.serviceId, fuelConsumedQuery)]).then((result) => {
            if (result[0]) this.processGeofenceData(result[0]);

            let aceleration, braking, cornering, speeding;
            if (result[1] && result[1].length) {
                result[1].forEach((item) => {
                    let stoppege_point = item.data;
                    stoppege_point.forEach((item, index) => {
                        let geoPoint = `${item.latitude},${item.longitude}`;
                        backOff(() => this.vehicleService.getLatLongDetails(geoPoint).then((r)=>{
                            item.index = index + 1;
                            item.adderss = r.items[0].address.label;
                        }), {
                            retry: (event, attemptNumber) => {
                                let retrial = true;
                                if (attemptNumber >= 5) {
                                    retrial = false
                                }
                                return true
                            }
                        })
                    });

                    if (item._id === 1) {
                        aceleration = stoppege_point;
                    } else if (item._id === 2) {
                        braking = stoppege_point;
                    } else if (item._id === 3) {
                        cornering = stoppege_point;
                    } else if (item._id === 4) {
                        speeding = stoppege_point;
                    }
                });
            }

            let fuelConsumed;
            if (result[2] && result[2].length) {
                fuelConsumed = result[2][0].fuelConsumed;
            }

            this.setState({ aceleration, braking, cornering, speeding, fuelConsumed }, () => {
                this.handleRoute(this.state.vehicleData);
            });
        });
    };

    geoEventClick = (event) => {
        let objects = this.state.group.getObjects();
        if (event.name !== this.state.selectedGeoName) {
            let objectIndex = objects.findIndex((o) => o.data === event.name);
            let prevObjIndex = objects.findIndex((o) => o.data === this.state.selectedGeoName);

            if (objectIndex > -1) {
                objects[objectIndex].setStyle({ fillColor: "rgba(226, 245, 39, 0.48)", strokeColor: "rgba(128, 0, 0, 0.6)" });
                if (prevObjIndex > -1) objects[prevObjIndex].setStyle({ fillColor: "rgba(0, 85, 170, 0.4)", strokeColor: "rgba(128, 0, 0, 0.6)" });

                this.setState({
                    selectedGeoName: event.name,
                });
            }
        }
    };

    processGeofenceData = (events) => {
        let stateObj = { geoEvents: events };
        if (events && events.length) {
            let entryCnt = events.filter((e) => e.state === "InsideGF");
            let exitCnt = events.filter((e) => e.state === "OutsideGF");
            stateObj.entryCnt = entryCnt.length;
            stateObj.exitCnt = exitCnt.length;
        }
        this.setState(stateObj);
    };

    marker = (item) => {
        return (
            <span className="custom-marker shadow-2 p-2" style={{ backgroundColor: item.state === "OutsideGF" ? "#628c3f" : "#0c6291" }}>
                <i className={classNames("marker-icon", "fa-solid fa-right-" + (item.state === "OutsideGF" ? "from" : "to") + "-bracket")} style={{ transform: item.state === "OutsideGF" ? "rotate(180deg)" : "none" }}></i>
            </span>
        );
    };

    content = (item) => {
        return (
            <div onClick={() => this.geoEventClick(item)}>
                <Card className="mb-3">
                    <div style={{ fontSize: "1.3rem", fontWeight: "bold", padding: "0" }}>
                        {(item.state === "OutsideGF" ? "Exit " : "Entered ") + item.name}
                        <span style={{ paddingLeft: "2vh", fontSize: "1rem", color: "#00000099" }}>{item.ts}</span>
                    </div>
                </Card>
            </div>
        );
    };

    addGeoFencesToMap = (H, group) => {
        if (this.props.geofences && this.props.geofences.length) {
            this.props.geofences.forEach((g) => {
                let polygon = H.util.wkt.toGeometry(g.data);
                group.addObject(new H.map.Polygon(polygon, { style: { strokeColor: "rgba(128, 0, 0, 0.6)" }, data: g.name }));
            });
        }
    };

    getStrengthMarker = (H, map, station, contentString, defaultLayers, icon, ui, size) => {
        // let group = new H.map.Group();
        // map.addObject(group);
        // let ui = H.ui.UI.createDefault(map, defaultLayers);
        let marker = new H.map.Marker(station, icon, size);
        let bubble;

        marker.addEventListener(
            "tap",
            function (evt) {
                bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                    content: evt.target.getData(),
                });
                ui.addBubble(bubble);
            },
            false
        );
        // marker.addEventListener(
        // 	'pointerleave',
        // 	function (evt) {
        // 		bubble.close();
        // 	},
        // 	false
        // );
        marker.setData(contentString);

        // group.addObject(marker);
        return marker;
    };

    handleRoute = (data) => {
        let geoMapArray = [];
        let declutterPoints = data["stoppagePoints"] ? JSON.parse(JSON.stringify(data["stoppagePoints"])) : [];
        for (let index = 0; index < declutterPoints.length; index++) {
            let lat = Number(typeof declutterPoints[index].latitude === "object" ? Number(declutterPoints[index].latitude.double) : declutterPoints[index].latitude),
                lon = Number(declutterPoints[index].longitude?.double ? declutterPoints[index].longitude?.double : declutterPoints[index].longitude);
            geoMapArray.push({
                lat: lat,
                lng: lon,
                type: "undesignated",
                time: declutterPoints[index].start_time,
            });
        }
        if (data.endLocation && data.startLocation) {
            geoMapArray.unshift({ lat: data.startLocation.lat, lng: data.startLocation.lng, type: "waypoint", time: data.startLocation.ts });
            geoMapArray.push({ lat: data.endLocation.lat, lng: data.endLocation.lng, type: "waypoint", time: data.endLocation.ts });
        }

        if (data["stoppagePoints"].length) {
            data["stoppagePoints"].forEach((item, index) => {
                item.index = index + 1;
            });
        }
        if (data.wayPoints && data.wayPoints.length) {
            data.wayPoints.forEach((w) => {
                geoMapArray.push({
                    lat: w.lat,
                    lng: w.lng,
                    type: "waypoint",
                    time: w.ts,
                });
            });
        }
        geoMapArray = geoMapArray.sort((a, b) => {
            const date1 = new Date(a.ts);
            const date2 = new Date(b.ts);
            return date1 - date2;
        });
        this.setState(
            {
                reportData: data,
                stoppagePoints: data["stoppagePoints"],
                geoArray: geoMapArray,
                selectedThingName: this.props.thingName,
            },
            () => {
                this.getVehicleMap(this.state.geoArray);
            }
        );
    };

    getVehicleMap = (stations) => {
        if (!stations || !stations.length) {
            return;
        }
        let H = window.H;
        let scheduledIcon = new H.map.Icon("/images/Scheduled.svg", {
            size: { w: 15, h: 15 },
        });
        let unScheduledIcon = new H.map.Icon("/images/Unscheduled.svg", {
            size: { w: 15, h: 15 },
        });
        let startIcon = new H.map.Icon(map_start_icon, { size: { w: 32, h: 32 } });
        let endIcon = new H.map.Icon(map_end_icon, { size: { w: 32, h: 32 } });

        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(this.mapRef.current, defaultLayers.vector.normal.map, {
            center: { lat: stations[stations.length - 1].lat, lng: stations[stations.length - 1].lng },
            zoom: 9,
            pixelRatio: window.devicePixelRatio || 1,
        });

        window.addEventListener("resize", () => map.getViewPort().resize());

        let ui = H.ui.UI.createDefault(map, defaultLayers);
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        let group = new H.map.Group();

        let markerLabel = 1;
        let markers = [];
        for (let i = 0; i < stations.length; i++) {
            let mark;
            let contentString =
                '<div id="vehicle-content">' +
                '<div id="vehicle-info">' +
                `<div id=${stations[i].type === "undesignated" ? "fc-red" : stations[i].type === "waypoint" ? "waypoint" : "fc-green"} >` +
                `${i === 0 ? "Start" : i === stations.length - 1 ? "End" : stations[i].type}` +
                "</div>" +
                '<div id="info">' +
                `${i === 0 ? "Start Time" : i === stations.length - 1 ? "End Time" : "Time"} : ` +
                `${stations[i].time ? moment(stations[i].time, "YYYY-MM-DD HH:mm:ss").format("h:mm A") : ""}` +
                "</div>" +
                `${!(i === 0 || i === stations.length - 1) ? `<div id="info">Stop Point Number : ${markerLabel}</div>` : ""}` +
                "</div>" +
                "</div>";

            if (i === 0) {
                mark = this.getStrengthMarker(H, map, stations[i], contentString, defaultLayers, { icon: startIcon }, ui, { h: 10, w: 10 });
            } else if (i === stations.length - 1) {
                mark = this.getStrengthMarker(H, map, stations[i], contentString, defaultLayers, { icon: endIcon }, ui, { h: 10, w: 10 });
            } else if ((i !== stations.length - 1 || 0) && stations[i].type === "undesignated") {
                mark = this.getStrengthMarker(H, map, stations[i], contentString, defaultLayers, { icon: unScheduledIcon }, ui, { h: 10, w: 10 });
                markerLabel += 1;
            } else if ((i !== stations.length - 1 || 0) && stations[i].type === "designated") {
                mark = this.getStrengthMarker(H, map, stations[i], contentString, defaultLayers, { icon: scheduledIcon }, ui, { h: 10, w: 10 });
                markerLabel += 1;
            }
            if (mark) group.addObject(mark);
            markers.push(mark);
        }
        this.addGeoFencesToMap(H, group);
        //this.setState({ markers });
        for (let index = 0; index < stations.length - 1; index++) {
            this.addRouteFromAtoB(platform, stations[index].lat + "," + stations[index].lng, stations[index + 1].lat + "," + stations[index + 1].lng, H, map, group);
        }
    };

    addRouteFromAtoB = (platform, origin, destination, H, map, group) => {
            this.calculateRouteFromAtoB(platform, origin, destination, H, map).then((route) => {
                this.addRouteShapeToMap(route, H, map, group);
        });
    };

    calculateRouteFromAtoB(platform, origin, destination, H, map) {
        return new Promise((resolve, reject) => {
            var router = platform.getRoutingService(null, 8),
                routeRequestParams = {
                    routingMode: "fast",
                    transportMode: "car",
                    origin: origin,
                    destination: destination,
                    return: "polyline,summary",
                };

            router.calculateRoute(routeRequestParams, (res, err) => {
                if (err) reject();
                resolve(res.routes[0]);
            });
        });
    }

    addRouteShapeToMap(route, H, map, group) {
        route.sections.forEach((section) => {
            let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
            let polyline = new H.map.Polyline(linestring, {
                style: {
                    lineWidth: 5,
                    fillColor: "white",
                    strokeColor: "rgba(10, 3, 241, 1)",
                    lineDash: [0, 2],
                    lineTailCap: "arrow-tail",
                    lineHeadCap: "arrow-head",
                },
            });
            group.addObject(polyline);
        });
        map.addObject(group);
        map.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
            // zoom: 10,
        });
        this.setState({ map, group });
    }

    handStoppage = (item) => {
        return typeof(item.address) !== "string" ? "" : item.address ;
    };
    handleduration = (item) => {
        return secondsToHm(item.stoppageDuration);
    };
    handleIdling = (item) => {
        return item.ignitionOnTime ? secondsToHms(item.ignitionOnTime) : "00:00:00";
    };
    handelts = (item) => {
        return moment(item.start_time).format("HH:mm:ss");
    };

    handleDateChenge = (e) => {
        let day = moment(e.value).format("YYYY-MM-DD");
        this.setState({ reportData: null, date4: e.value }, () => {
            this.initReport(day);
        });
    };

    location = (value) => {
        return (
            <>
                <p>
                    {value.latitude.toFixed(5)}/{value.longitude.toFixed(5)}
                </p>
            </>
        );
    };

    timeFormat = (value) => {
        return <>{moment(value.ts).format("Do MMM, YY HH:mm")}</>;
    };

    handleTabOpen = async (e) => {
        if (e.index === 3) {
            //Fuellling/Defuleing event click
            let query = {
                thingName: this.state.thingName,
                date: moment(this.state.date4).format("YYYY-MM-DD"),
            };
            let res = await this.fuelService.getFuelingEvents(this.serviceId, { filter: query });
            if (res && res.length) {
                this.setState({ fuellingEvents: res });
            } else {
                this.setState({ fuellingEvents: [], fuellingMsg: "Events not available for this day" });
            }
        }
    };

    render = () => {
        const leftContents = (
            <React.Fragment>
                <Button icon="pi pi-arrow-left" className="mr-2" onClick={this.props.reportBack} />
            </React.Fragment>
        );

        const rightContents = (
            <React.Fragment>
                <div className="text-center flex-1">
                    <p className="font-bold  mb-1 text-lg ">
                        <span className="text-blue-700">
                            <font style={{ color: "black" }}>Vehicle: </font> {this.state.make.toUpperCase()} {this.state.model.toUpperCase()}&nbsp;&nbsp;
                            <font style={{ color: "black" }}>License Plate No: </font>
                            {this.props.vehicleNumber.toUpperCase()}
                        </span>
                    </p>
                </div>
                <Calendar id="minmax" value={this.state.date4} maxDate={this.maxDate} onChange={(e) => this.handleDateChenge(e)} showIcon readOnlyInput style={{ width: "150px", marginRight: "10px" }} />
            </React.Fragment>
        );
        let headerGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Stoppage" className="bg-primary"></Column>
                    <Column header="Time" className="bg-primary"></Column>
                    <Column header="Duration" className="bg-primary"></Column>
                    {/* <Column header="Idling Duration" className="bg-primary"></Column> */}
                </Row>
            </ColumnGroup>
        );
        if (this.state.isError) {
            return (
                <div className="grid">
                    <div className="col-12">
                        <Toolbar left={leftContents} right={rightContents} />
                    </div>
                    <div className="col-12">
                        <div className="card flex flex-column align-items-center justify-content-center" style={{ height: "60vh" }}>
                            <span className="pi pi-search" style={{ fontSize: "3rem" }}></span>
                            <h3>No Data Found</h3>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.reportData) {
            return (
                <>
                    <div className="grid">
                        <div className="col-12">
                            <Toolbar left={leftContents} right={rightContents} />
                        </div>
                        <div className="col-6"></div>
                    </div>
                    <div className="grid">
                        <div className="col-12 lg:col-4">
                            <div className="card mb-3 vehicle_info">
                                <p className="font-bold text-900">
                                    Start Time:
                                    <span> {this.state.reportData["startTime"] ? moment(this.state.reportData["startTime"], "YYYY-MM-DD HH:mm:ss").format("ddd, h:mm A") : "--"}</span>
                                </p>
                                <p className="font-bold text-900">
                                    End Time:
                                    <span> {this.state.reportData["endTime"] ? moment(this.state.reportData["endTime"], "YYYY-MM-DD HH:mm:ss").format("ddd, h:mm A") : "--"}</span>
                                </p>
                                <p className="font-bold text-900">
                                    Total Travel Time:
                                    <span> {secondsToHm(this.state.reportData["totalTravelTime"])}</span>
                                </p>
                                <p className="font-bold text-900">
                                    Distance Travelled:
                                    <span>{convertValue(this.state.reportData["totalDistanceTravelled"], "distance", this.props.unit)}</span>
                                </p>
                                <p className="font-bold text-900">
                                    Stoppages:
                                    <span> {this.state.reportData["stoppagePoints"].length}</span>
                                </p>
                                <p className="font-bold text-900">
                                    Total Stoppage Period:
                                    <span> {secondsToHm(this.state.reportData["totalStoppageDuration"])}</span>
                                </p>
                                {this.state.fuelConsumed && this.props.fuel ? (
                                    <p className="font-bold text-900">
                                        Fuel Consumed:
                                        <span> {this.state.fuelConsumed.toFixed(2) + " L"}</span>
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-12 lg:col-8">
                            <div className="card p-0">
                                <div ref={this.mapRef} style={{ width: "100%", height: "380px", overflow: "hidden" }}></div>
                            </div>
                        </div>
                        <div className="col-12">
                            <Accordion onTabOpen={this.handleTabOpen}>
                                {this.props.geofences && this.props.geofences.length ? (
                                    this.state.geoEvents && this.state.geoEvents.length ? (
                                        <AccordionTab
                                            header={
                                                <div>
                                                    <span style={{ float: "left", paddingRight: "10vh" }}>Geo-fence Events</span>
                                                    <span style={{ float: "right", fontSize: "1.2rem" }}>
                                                        <span>
                                                            {" "}
                                                            {"Total "} <Badge value={this.state.geoEvents.length} style={{ fontSize: "1rem", background: "#57624e" }} className="mr-2"></Badge>
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            {"Entry "} <Badge value={this.state.entryCnt || "0"} style={{ fontSize: "1rem", background: "#0c6291" }} className="mr-2"></Badge>
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            {"Exit "} <Badge value={this.state.exitCnt || "0"} style={{ fontSize: "1rem", background: "#628c3f" }} className="mr-2"></Badge>
                                                        </span>
                                                    </span>
                                                </div>
                                            }
                                            style={{ maxHeight: "30vh", overflowY: "auto" }}
                                        >
                                            <div className="widget-timeline">
                                                <Timeline value={this.state.geoEvents} className="customized-timeline" marker={this.marker} content={this.content} />
                                            </div>
                                        </AccordionTab>
                                    ) : null
                                ) : (
                                    <AccordionTab
                                        disabled={true}
                                        header={
                                            <div>
                                                <div className="grid">
                                                    <span className="geofenceTitle">Geo-fence Events</span>
                                                    <span style={{ fontSize: "1.2rem" }}>
                                                        <span>
                                                            {" "}
                                                            {"Total "} <Badge value={"0"} style={{ fontSize: "1rem", background: "#57624e" }} className="mr-2"></Badge>
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            {"Entry "} <Badge value={"0"} style={{ fontSize: "1rem", background: "#0c6291" }} className="mr-2"></Badge>
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            {"Exit "} <Badge value={"0"} style={{ fontSize: "1rem", background: "#628c3f" }} className="mr-2"></Badge>
                                                        </span>
                                                    </span>
                                                    <span style={{ float: "right", color: "orange" }}>
                                                        Not Configured
                                                        <object data={"/images/no-configuration-setting-icon.svg"} width="16" height="16">
                                                            {" "}
                                                        </object>
                                                    </span>
                                                </div>

                                                <div style={{ marginTop: "2vh" }}>
                                                    <div className="widget-timeline">
                                                        <Timeline value={this.sampleGeoEvents} className="customized-timeline" marker={this.marker} content={this.content} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        style={{ overflowY: "auto" }}
                                    ></AccordionTab>
                                )}
                                <AccordionTab header="Stoppage Details">
                                    <div className="grid">
                                        <div className="col-12 lg:col-8">
                                            <div className="card">
                                                <DataTable headerColumnGroup={headerGroup} value={this.state.stoppagePoints} responsiveLayout="scroll">
                                                    {/* <Column header="Sl.No." field="index"></Column> */}
                                                    <Column header="Stoppage" body={this.handStoppage}></Column>
                                                    <Column header="Stoppage Time" body={this.handelts}></Column>
                                                    <Column header="Stoppage Duration" body={this.handleduration}></Column>
                                                    {/* <Column header="Idling Duration" body={this.handleIdling}></Column> */}
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionTab>
                                <AccordionTab
                                    disabled={!this.state.vehicleData.hasOwnProperty("greendriving") ? true : false}
                                    header={
                                        !this.state.vehicleData.hasOwnProperty("greendriving") ? (
                                            <div className="grid">
                                                <span className="geofenceTitle">Driving Behaviour</span>
                                                <span style={{ float: "right", color: "orange" }}>
                                                    No Data / Not Configured
                                                    <object data={"/images/no-configuration-setting-icon.svg"} width="16" height="16">
                                                        {" "}
                                                    </object>
                                                </span>
                                            </div>
                                        ) : (
                                            <span className="geofenceTitle">Driving Behaviour</span>
                                        )
                                    }
                                    style={{ overflowY: "auto" }}
                                >
                                    <div className="grid">
                                        {this.state.greenDrivingScore === undefined || this.state.aceleration === null ? (
                                            <div className="col-12 lg:col-12">
                                                <Card className="card customCard" style={{ background: "#eee" }}>
                                                    <div className="greenDriving">
                                                        <h4>0</h4>
                                                        <p>Driving Score</p>
                                                    </div>
                                                </Card>
                                            </div>
                                        ) : (
                                            <div className="col-12 lg:col-12">
                                                <Card className="card customCard newcustomcard" style={{ background: "#eee" }}>
                                                    <div className="greenDriving">
                                                        <p>Driving Score : </p>
                                                        {this.state.greenDrivingScore < 50 ? (
                                                            <>
                                                                <h4 className="five">{parseFloat(this.state.greenDrivingScore).toFixed(0)}</h4>
                                                                <Rating value={1} readOnly stars={5} cancel={false} />
                                                            </>
                                                        ) : this.state.greenDrivingScore >= 50 && this.state.greenDrivingScore < 60 ? (
                                                            <>
                                                                <h4 className="four">{parseFloat(this.state.greenDrivingScore).toFixed(0)}</h4>
                                                                <Rating value={2} readOnly stars={5} cancel={false} />
                                                            </>
                                                        ) : this.state.greenDrivingScore >= 60 && this.state.greenDrivingScore < 75 ? (
                                                            <>
                                                                <h4 className="three">{parseFloat(this.state.greenDrivingScore).toFixed(0)}</h4>
                                                                <Rating value={3} readOnly stars={5} cancel={false} />
                                                            </>
                                                        ) : this.state.greenDrivingScore >= 75 && this.state.greenDrivingScore < 90 ? (
                                                            <>
                                                                <h4 className="two">{parseFloat(this.state.greenDrivingScore).toFixed(0)}</h4>
                                                                <Rating value={4} readOnly stars={5} cancel={false} />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <h4 className="one">{parseFloat(this.state.greenDrivingScore).toFixed(0)}</h4>
                                                                <Rating value={5} readOnly stars={5} cancel={false} />
                                                            </>
                                                        )}

                                                        <p className="tooltipParent">
                                                            <i className="pi pi-info-circle"></i>
                                                        </p>
                                                        <Tooltip target=".tooltipParent" tooltipOptions={{ className: "cyan-tooltip", position: "top" }}>
                                                            <ul className="customTooltip">
                                                                <li>
                                                                    <p>90-100</p>
                                                                    <Rating value={5} readOnly stars={5} cancel={false} />
                                                                </li>
                                                                <li>
                                                                    <p>75-90</p>
                                                                    <Rating value={4} readOnly stars={5} cancel={false} />
                                                                </li>
                                                                <li>
                                                                    <p>60-75</p>
                                                                    <Rating value={3} readOnly stars={5} cancel={false} />
                                                                </li>
                                                                <li>
                                                                    <p>50-60</p>
                                                                    <Rating value={2} readOnly stars={5} cancel={false} />
                                                                </li>
                                                                <li>
                                                                    <p>{"<50"}</p>
                                                                    <Rating value={1} readOnly stars={5} cancel={false} />
                                                                </li>
                                                            </ul>
                                                        </Tooltip>
                                                    </div>
                                                </Card>
                                            </div>
                                        )}
                                        <div className="col-12">
                                            <h2>Aggressive Driving Events</h2>
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 lg:col-3">
                                            <Card className="card customCard">
                                                <div className="greenDriving" onClick={() => this.onClick("displayBasic")}>
                                                    <h4>{this.state.vehicleData.greendriving.harsh_acc}</h4>
                                                    <p>Harsh Acceleration</p>
                                                </div>
                                            </Card>
                                        </div>
                                        <Dialog header="Harsh Acceleration" visible={this.state.displayBasic} style={{ width: "50vw" }} onHide={() => this.onHide("displayBasic")}>
                                            <HistoryAcceleration value={this.state.aceleration} />
                                        </Dialog>

                                        <div className="col-12 lg:col-3">
                                            <Card className="card customCard">
                                                <div className="greenDriving" onClick={() => this.onClick("displayBasic1")}>
                                                    <h4>{this.state.vehicleData.greendriving.harsh_brak}</h4>
                                                    <p>Harsh Braking</p>
                                                </div>
                                            </Card>
                                        </div>
                                        <Dialog header="Harsh Braking" visible={this.state.displayBasic1} style={{ width: "50vw" }} onHide={() => this.onHide("displayBasic1")}>
                                            <HistoryBraking value={this.state.braking} />
                                        </Dialog>

                                        <div className="col-12 lg:col-3">
                                            <Card className="card customCard">
                                                <div className="greenDriving" onClick={() => this.onClick("displayBasic2")}>
                                                    <h4>{this.state.vehicleData.greendriving.harsh_corn}</h4>
                                                    <p>Harsh Cornering</p>
                                                </div>
                                            </Card>
                                        </div>
                                        <Dialog header="Harsh Cornering" visible={this.state.displayBasic2} style={{ width: "50vw" }} onHide={() => this.onHide("displayBasic2")}>
                                            <HistoryCornering value={this.state.cornering} />
                                        </Dialog>

                                        <div className="col-12 lg:col-3">
                                            <Card className="card customCard">
                                                <div className="greenDriving" onClick={() => this.onClick("displayBasic3")}>
                                                    <h4>{this.state.vehicleData.greendriving.harsh_speeding}</h4>
                                                    <p>Speeding</p>
                                                </div>
                                            </Card>
                                        </div>
                                        <Dialog header="Speeding" visible={this.state.displayBasic3} style={{ width: "50vw" }} onHide={() => this.onHide("displayBasic3")}>
                                            <HistorySpeeding value={this.state.speeding} />
                                        </Dialog>
                                    </div>
                                </AccordionTab>
                                {this.props.fuel ? (
                                    <AccordionTab id="fuel" header="Fuelling/Defuelling Events" className="timeline-demo">
                                        {this.state.fuellingEvents && this.state.fuellingEvents.length ? (
                                            <div style={{ height: "40vh", overflowY: "auto" }}>
                                                <Timeline value={this.state.fuellingEvents} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
                                            </div>
                                        ) : this.state.fuellingMsg ? (
                                            <div className="col-12">
                                                <div className="card flex flex-column align-items-center justify-content-center" style={{ height: "40vh" }}>
                                                    <span className="pi pi-info-circle" style={{ fontSize: "3rem" }}></span>
                                                    <h3>{this.state.fuellingMsg}</h3>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>{loader(80, 80, "#264653")}</div>
                                        )}
                                    </AccordionTab>
                                ) : null}
                            </Accordion>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <div className="grid">
                    <div className="col-12">
                        <Skeleton height="50px" className="mb-2"></Skeleton>
                    </div>
                    <div className="col-12 lg:col-4">
                        <Skeleton height="322px" className="mb-2"></Skeleton>
                    </div>
                    <div className="col-12 lg:col-8">
                        <Skeleton height="322px" className="mb-2"></Skeleton>
                    </div>
                    <div className="col-12">
                        <Skeleton height="50px" className="mb-2"></Skeleton>
                        <Skeleton height="50px" className="mb-2"></Skeleton>
                        <Skeleton height="50px" className="mb-2"></Skeleton>
                        <Skeleton height="50px" className="mb-2"></Skeleton>
                    </div>
                </div>
            );
        }
    };
}

const mapStateToProps = (state) => {
    return { unit: state.changeUnit };
};
export default connect(mapStateToProps, { dispatchAction })(VehicleOBDHistory);
