import axios from 'axios';
import { weatherApiKey } from '../utilities/constant';

export class WindSpeedService {

    getThings = async (serviceID) => {
        const token = localStorage.getItem("token");
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        const res = await axios.post(process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL+'/get-things/'+serviceID, {}, { headers: headers });
        return res.data;
    } 

    getWindSpeedDirection = async (payload) => {
        const res = await axios.post(process.env.REACT_APP_ANALYTICS_SERVICE_URL+"/get-current-wsddata", payload);
        return res.data;
    }

    getWeatherData = async (lat, lng) => {
        const key = weatherApiKey;
        const res = await axios.get('https://api.openweathermap.org/data/2.5/weather?lat='+lat+'&lon='+lng+'&appid='+ key+'&units=metric');
        return res.data;
    }

    getHistoricalData = async (serviceId, thingName, start, end) => {
        const payload = {
            "serviceId": serviceId,
            "thingName": thingName,
            "startDate": start,
            "endDate": end
        };
        const res = await axios.post(process.env.REACT_APP_ANALYTICS_SERVICE_URL+"/get-wsd-data", payload)
        return res.data;
    }

    getThingDetails = async (thingName, serviceID) => {
        const payload = {
            "filter": {
                "spaceName" : thingName
            }
        };
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + '/' + serviceID + '/nq-spaces', payload)
        return res.data;
    }
}    