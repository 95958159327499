import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import moment from "moment";
import weatherService from "../../service/weatherServcie";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { Dropdown } from "primereact/dropdown";
import { httpConstants } from "../../lib/constants";
import { MultiSelect } from "primereact/multiselect";
import DBService from "../../service/dbService";
import { useSelector } from "react-redux";
import { convertValue } from "../../utilities/unitConversion";
import { Dialog } from "primereact/dialog";
var citytimerId;
const today = moment().format("YYYY-MM-DD");
const next7Day = moment().add(6, "days").format("YYYY-MM-DD");

const filterDefaultRange = {
    windOperator: { name: "Less Than Equal", code: "$lte" },
    wind: 20,
    precipitation: 2,
    precipitationOperator: { name: "Less Than Equal", code: "$lte" },
    snow: 2,
    snowOperator: { name: "Less Than Equal", code: "$lte" },
    humidity: 83,
    humidityOperator: { name: "Less Than Equal", code: "$lte" },
    visibility: 3,
    visibilityOperator: { name: "Greater Than Equal", code: "$gte" },
}
const filterArr = [
    { name: "Wind Speed", category: 'speed', value: "wind", operator: "windOperator", key: "maxwind_kph", icon: "/images/wind.svg" },
    { name: "Precipitation", category: 'precipitation', value: 'precipitation', operator: "precipitationOperator", key: "totalprecip_mm", icon: "/images/precipitation.svg" },
    { name: "Snowfall", category: 'snow', value: 'snow', operator: "snowOperator", key: "totalsnow_cm", icon: "/images/snow_flake.svg" },
    { name: "Humidity", category: 'humidity', value: 'humidity', operator: "humidityOperator", key: "avghumidity", icon: "/images/humidity.svg" },
    { name: "Visibility", category: 'distance', value: 'visibility', operator: "visibilityOperator", key: "avgvis_km", icon: "" }
]

const WeatherForecast = () => {
    const serviceId = localStorage.getItem("serviceId");
    const myStates = JSON.parse(localStorage.getItem("states"));
    const unit = useSelector(state => state.changeUnit)
    const [btnLoading, setBtnLoading] = useState(false);
    const [filter, setFilter] = useState(filterDefaultRange)
    const [sitesData, setSitesData] = useState()
    const [dateArr, setDateArr] = useState([])
    const [loader, setLoader] = useState(false)
    const [errMsg, setErrMsg] = useState()
    const [callReset, setCallReset] = useState(false)
    const [states, setStates] = useState(null)
    const [cities, setCities] = useState(null)
    const [selectedState, setSelectedState] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)
    const [infoMsg, setInfoMsg] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState(null)
    const template = (options) => {
        const className = `${options.className} justify-content-between`;
        return (
            <div className={className}>
            </div>
        );
    }
    useEffect(() => {

        filterArr.forEach(f => {
            f.unit = convertValue(null, f.category, unit, null, null, true)
        })

        const getStateWiseCount = async () => {
            let query = {
                projection: { latlongs: 0, _id: 0, midlat: 0, midlong: 0, count: 0 },
            };
            if (myStates.length > 0) {
                query = { filter: { st: { $in: myStates } }, ...query }
            }
            DBService.getStateWiseSites(query).then((res) => {
                if (res && res instanceof Array && res.length) setStates(res);
                else setInfoMsg("Sites upload pending");
            });
        };
        getStateWiseCount();

        const dateArr = [today]
        for (let i = 1; i <= 6; i++) {
            dateArr.push(moment().add(i, "days").format("YYYY-MM-DD"))
        }
        setDateArr(dateArr)
    }, [])
    useEffect(() => {
        if (dateArr.length) {
            onFilterSubmit()
        }
    }, [dateArr])
    const handleFilter = (ftype, value) => {
        let f = JSON.parse(JSON.stringify(filter))
        f[ftype] = value
        if (!ftype.includes('Operator')) {
            f[ftype] = value ? parseFloat(value) : value
        }
        setFilter(f)
    }
    const onReset = () => {
        setFilter(filterDefaultRange)
        setCities([])
        setSelectedState(null)
        setSelectedCity(null)

        setLoader(false)
        setBtnLoading(false)
        setErrMsg(null)
        setCallReset(true)
    }
    useEffect(() => {
        if (callReset) onFilterSubmit()
    }, [callReset])
    const onFilterSubmit = async () => {
        let value_category_map = {}
        filterArr.forEach(f => {
            value_category_map[f.value] = f.category
        })

        let matchQuery = {
            date: { $gte: today, $lte: next7Day }
        }
        matchQuery["maxwind_kph"] = { [filter.windOperator.code]: convertValue(filter.wind, value_category_map['wind'], "MKS", null, true, null, true) }
        matchQuery["totalprecip_mm"] = { [filter.precipitationOperator.code]: convertValue(filter.precipitation, value_category_map['precipitation'], "MKS", null, true, null, true) }
        matchQuery["totalsnow_cm"] = { [filter.snowOperator.code]: convertValue(filter.snow, value_category_map['snow'], "MKS", null, true, null, true) }
        matchQuery["avghumidity"] = { [filter.humidityOperator.code]: convertValue(filter.humidity, value_category_map['humidity'], "MKS", null, true, null, true) }
        matchQuery["avgvis_km"] = { [filter.visibilityOperator.code]: convertValue(filter.visibility, value_category_map['visibility'], "MKS", null, true, null, true) }

        if (selectedState && selectedState.length) {
            if (selectedCity && selectedCity.length) {
                let cities = selectedCity.map(c => c.ct)
                matchQuery.ct = { "$in": cities }
            } else {
                let states = selectedState.map(s => s.st)
                matchQuery.st = { "$in": states }
            }
        }

        let payload = {
            operation: "aggregate",
            aggregate: [
                { $match: matchQuery },
                { $project: { _id: 0, ct: 1, st: 1, sid: 1, maxwind_kph: 1, totalprecip_mm: 1, totalsnow_cm: 1, avghumidity: 1, avgvis_km: 1, date: 1 } },
                { $group: { _id: '$sid', ct: { "$first": "$ct" }, st: { "$first": "$st" }, data: { $push: "$$ROOT" } } }
            ]
        }
        setCallReset(false)
        setErrMsg(null)
        setLoader(true)
        setBtnLoading(true)
        let res = await weatherService.getAggregatedWeatherForecast(serviceId, payload)
        if (res && res.length) {
            res.forEach(r => {
                dateArr.forEach(d => {
                    r[d] = false
                })
                r.data.forEach(d => {
                    r[d.date] = true
                })

            })
        } else {
            setErrMsg("None of the sites match the mentioned weather conditions.")
        }
        setSitesData(res)
        setLoader(false)
        setBtnLoading(false)
    }
    const showColor = (row, date) => {
        return <div style={{ backgroundColor: (row[date] ? "green" : '#ed542e'), color: "white", padding: "0.5rem" }} onClick={(e) => showDayDetails(e, row, date)}></div>
    }
    const showDayDetails = async (e, row, date) => {
        let payload = {
            filter: { date: date, sid: row._id },
            projection: { _id: 0, ct: 1, st: 1, sid: 1, maxwind_kph: 1, totalprecip_mm: 1, totalsnow_cm: 1, avghumidity: 1, avgvis_km: 1, date: 1 }
        }

        let res = await weatherService.getWeatherForecast(serviceId, payload)
        if (res && res.length) {
            setModalData(res)
            setShowModal(true)
        }
    }
    const onStateChange = (e) => {
        setCities([]);
        setSelectedCity(null);
        setSelectedState(e.value);
    }
    useEffect(() => {
        cityDebounceFunction(getCitiesFromDb, 2000, selectedState);
    }, [selectedState]);
    const cityDebounceFunction = (func, delay, params) => {
        clearTimeout(citytimerId);
        citytimerId = setTimeout(() => {
            func(params);
        }, delay);
    }
    const getCitiesFromDb = async (states) => {
        if (states && states.length) {
            let st = selectedState.map((s) => s.st);
            let query = { filter: { st: { $in: st } }, projection: { _id: 0, latlongs: 0, midlat: 0, midlong: 0, count: 0, st: 0 } };
            let cities = await DBService.getSitesCities(query)
            setCities(cities);
            // setBtnLoading(false);
        }
    }
    const onCityChange = (e) => {
        setSelectedCity(e.value);
    }

    const showValue = (e, f) => {
        let color = "red"
        let conValue = convertValue(e[f.key], f.category, unit, null, true)
        switch (filter[f.operator]['code']) {
            case '$eq':
                if (conValue === filter[f.value]) color = 'green'
                break;
            case '$gt':
                if (conValue > filter[f.value]) color = 'green'
                break;
            case '$lt':
                if (conValue < filter[f.value]) color = 'green'
                break;
            case '$lte':
                if (conValue <= filter[f.value]) color = 'green'
                break;
            case '$gte':
                if (conValue >= filter[f.value]) color = 'green'
                break;
        }
        return (
            e ? <div style={{ color: color }}>{convertValue(e[f.key], f.category, unit, true)}</div> : <div>--</div>
        )
    }

    const getDialog = () => {
        return (
            <Dialog header={"Day Details"} visible={showModal} modal style={{ width: '50vw' }} onHide={() => onCancel()} >
                <DataTable className='color1-bg' value={modalData} responsiveLayout="scroll">
                    <Column headerClassName="color1-bg" bodyClassName="first-letter-cap" style={{ width: "100px" }} search header="Site Id" field="sid"></Column>
                    <Column headerClassName="color1-bg" bodyClassName="first-letter-cap" style={{ width: "100px" }} search header="Date" field="date"></Column>
                    {
                        filterArr.map(f => {
                            return (<Column key={f.name} headerClassName="color1-bg" bodyClassName="first-letter-cap" style={{ width: "100px" }} header={f.name} field={(e) => showValue(e, f)}></Column>)
                        })
                    }

                </DataTable>
            </Dialog>
        )
    }
    const onCancel = () => {
        setShowModal(false)
    }

    return (
        <>
            {getDialog()}
            <Panel headerTemplate={template} collapsed={false}>
                {
                    states && states.length ?
                        <>
                            <div className="grid">
                                <div className="col-12 lg:col-3 mr-1">
                                    <MultiSelect value={selectedState} options={states} onChange={onStateChange} optionLabel="st" placeholder=" Select State" className="m-2 bg-white w-full" filter />
                                </div>
                                {cities && cities.length ? (
                                    <div className="col-12 lg:col-3">
                                        <MultiSelect value={selectedCity} onChange={onCityChange} options={cities} optionLabel="ct" className="m-2 bg-white w-full" placeholder="Select Cities" filter />
                                    </div>
                                ) : null}
                            </div>
                            <hr />
                        </>
                        : null
                }

                <div className="grid">
                    {
                        filterArr.map((f, i) => {
                            return (
                                <div key={i} className="col-12 md:col-6 sm:col-12 xs:col-12 ">
                                    <div className="col-12 grid pt-0 pb-0 ">
                                        <div className="col-10 lg:col-3 sm:col-12 xs:col-12 pb-0 ">
                                            <h5>{f.name + "     "}
                                                <object data={f.icon} width="20" height="22">
                                                    {" "}
                                                </object></h5>
                                        </div>
                                        <div className="col-6 lg:col-4 sm:col-6 xs:col-6">
                                            <Dropdown name="operator" value={filter[f.operator]} onChange={(e) => handleFilter(f.operator, e.target.value)} options={httpConstants.for_numeric_operator} optionLabel="name" placeholder="Select condition" className="flex" />
                                        </div>
                                        <div className="col-3 lg:col-2 sm:col-2 xs:col-2">
                                            <input type='number' value={filter[f.value]} onChange={(e) => handleFilter(f.value, e.target.value)} className=" inputNumber bg-white w-full" />
                                        </div>
                                        <div className="col-2 lg:col-1 sm:col-6 xs:col-6 mt-2"><h6>{f.unit}</h6></div>

                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="col-12 md:col-6">
                        <Button label="Search" className="m-2" loading={btnLoading} disabled={infoMsg? true: false} onClick={onFilterSubmit} />
                        <Button label="Reset" className=" p-button-danger m-2" disabled={infoMsg? true: false} onClick={onReset} />
                        <div className="col-12">{errMsg ? <span style={{ color: "red" }}>{errMsg}</span> : null}</div>
                    </div>
                </div>
            </Panel >
            {
                sitesData && sitesData.length ?
                    <div className="card mt-3">
                        <DataTable value={sitesData} paginator={true} rows={10} responsiveLayout="scroll">
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Site Id" field="_id"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="City" field="ct"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="State" field="st"></Column>
                            {
                                dateArr.map(d => {
                                    return (
                                        <Column key={d} headerClassName="color1-bg" style={{ width: "60px" }} header={moment(d).format('MMM DD-YYYY')} field={(e) => showColor(e, d)}></Column>
                                    )
                                })
                            }
                        </DataTable>
                    </div>
                    : loader ?
                        <div className="mt-3"><Skeleton height="32rem"></Skeleton></div>
                        : infoMsg ?
                            <div className="col-12">
                                <div className="card flex flex-column align-items-center justify-content-center color3-bg " style={{ height: "60vh" }}>
                                    <span className="pi pi-info-circle" style={{ fontSize: "3rem" }}></span>
                                    <h3>{infoMsg}</h3>
                                </div>
                            </div>
                            : null
            }
        </>

    );
};

export default WeatherForecast;
