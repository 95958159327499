import React from "react";
import SwaggerUI from "swagger-ui-react"

const SwaggerUIComponent = () => {
    return(
        <div>
            <SwaggerUI url="https://tower.nextqore.io:6001/openapi.json" />
        </div>
    )
}

export default SwaggerUIComponent;