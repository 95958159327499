import React, { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import configService from "../service/configService";

const Support = () => {
    const [data, setData] = useState(null);
    const serviceDetails = JSON.parse(localStorage.getItem("service"));
    useEffect(async () => {
        let steps = [];
        const payload = {
            filter: {
                type: {
                    $in: ["All", serviceDetails.serviceTypeName],
                },
            },
        };
        let res = await configService.getSupportDetails(payload);

        res.forEach((item) => {
            item.features.forEach((elm) => {
                steps.push(elm);
            });
        });
        setData(steps);
    }, []);

    return (
        <div className="grid">
            <div className="col-12">
                {data ? (
                    <Accordion activeIndex="0" multiple>
                        {data.map((item, i) => (
                            <AccordionTab header={`${i + 1}. ${item.name}`} key={`name-${item.type}`}>
                                <ol type="I">
                                    {item.steps.map((el, k) => (
                                        <li className="my-2 text-900" key={`step-${k}`}>
                                            <p dangerouslySetInnerHTML={{ __html: el.title }} />
                                            {el.steps ? (
                                                <ol type="a">
                                                    {el.steps.map((step, l) => (
                                                        <li className="my-2 text-700" key={`sub-steps-${l}`}>
                                                            <p dangerouslySetInnerHTML={{ __html: step.title }} />
                                                        </li>
                                                    ))}
                                                </ol>
                                            ) : null}
                                        </li>
                                    ))}
                                </ol>
                            </AccordionTab>
                        ))}
                    </Accordion>
                ) : null}
            </div>
        </div>
    );
};

export default Support;
