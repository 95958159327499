import React,{useState,useEffect} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { plazaServices } from './tollPlaza/plazaReportService';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import service from '../service/dbService';

export const Charging = () => {
    const [barType, setBarType] = useState()
    const [dischargingBarType, setDischargingBarType] = useState();
    const [dischargeLine, setDischargeLine] = useState()
    const [chargingLine, setChargingLineType] = useState()
    const [selectedDate, setSelectedDate] = useState(new Date(moment().subtract(1, "days")))
    const [thingList, setThingList] = useState([])
    const [selectedThing, setSelectedThing] = useState(thingList && thingList.length > 0 ? thingList[0] : null)
    const [masterData, setMasterData] = useState({
        charging: [],
        discharging: []
    })
    const serviceID = localStorage.getItem("serviceId")
    const LineChart = (type, data, xAxisHeader, lineData) => {
        let seriesData = [];
        let xAxisData = [];
        data.map((item) => {
            seriesData.push(Number(item.time));
            if (type === "line") xAxisData.push(item.cycle);
            else xAxisData.push(item.soc);
        });
        const lineChart = {
            chart: {
                type: `${type}`
            },
            title: {
                text: '',
                align: 'left'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            xAxis: {
                categories: xAxisData,
                accessibility: {
                    rangeDescription: ''
                },
                title: {
                    text: `${lineData ? lineData : ""}`
                }
            },
            yAxis: {
                title: {
                    text: `${xAxisHeader}`
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                }
            },

            series: [{
                name: 'minutes',
                data: seriesData
            }],
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ['viewFullscreen', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
                    }
                }
            }
        };
        return lineChart;
    };
    const handleDateChange = async (e) => {
        setSelectedDate(e.target.value)
        let Date = moment(e.target.value).format("YYYY-MM-DD")
        const payload = {
            filter: {
                thingName: selectedThing,
                date: Date
            },
            projection: { total_chrg_time: 1, total_dischrg_time: 1, chrg_0_20: 1, chrg_20_40: 1, chrg_40_60: 1, chrg_60_80: 1, chrg_80_100: 1, dischrg_80_100: 1, dischrg_60_80: 1, dischrg_40_60: 1, dischrg_20_40: 1, dischrg_0_20: 1, cyc_count: 1 }
        }
        let res = await plazaServices.general(serviceID, payload, "nq-daily-history");
        let charge = [];
        let discharge = [];
        if (res.length) {
            for (const key in res[0]) {
                const value = (res[0][key] / 60).toFixed(2);
                if (key.startsWith('chrg')) {
                    let socRange = key.replace('chrg_', '').replace('_', '-');
                    charge.push({ soc: `${socRange}%`, time: value });
                } else if (key.startsWith('dischrg')) {
                    let socRange = key.replace('dischrg_', '').replace('_', '-');
                    discharge.push({ soc: `${socRange.split("-").reverse().join("-")}%`, time: value });
                }
            }
        }
        let tempMasterData = {
            ...masterData,
            charging: charge,
            discharging: discharge.reverse(),
        }
        let barCharging = LineChart('bar', tempMasterData.charging, "Charging Time (Minutes)", "SOC");
        let tempDischargeBar = LineChart("bar", tempMasterData.discharging, "Discharging Time  (Minutes)", "SOC")
        setBarType(barCharging);
        setDischargingBarType(tempDischargeBar)
        setMasterData(tempMasterData);
    };

    let populate = (thingDetails) => {
        let matchObj = {}
        let Date = moment(selectedDate).format("YYYY-MM-DD")
        if (thingDetails.value) matchObj["thingName"] = thingDetails.value;
        else matchObj["thingName"] = thingDetails
        const payload = {
            filter: {
                thingName: matchObj["thingName"],
                date: Date
            },
            projection: { total_chrg_time: 1, total_dischrg_time: 1, chrg_0_20: 1, chrg_20_40: 1, chrg_40_60: 1, chrg_60_80: 1, chrg_80_100: 1, dischrg_80_100: 1, dischrg_60_80: 1, dischrg_40_60: 1, dischrg_20_40: 1, dischrg_0_20: 1, cyc_count: 1 }
        }
        const cyclePayload = {
            filter: { thingName: matchObj["thingName"] },
            projection: { total_chrg_time: 1, cyc_count: 1, total_dischrg_time: 1, date: 1 }
        }

        Promise.all([plazaServices.general(serviceID, payload, "nq-daily-history"), plazaServices.general(serviceID, cyclePayload, "nq-cycle-count")]).then((res) => {
            let charge = [];
            let discharge = [];
            let cycleCharge = [];
            let cycleDischarge = [];

            if (res[0]) {
                for (const key in res[0][0]) {
                    const value = (res[0][0][key] / 60).toFixed(2);
                    if (key.startsWith('chrg')) {
                        let socRange = key.replace('chrg_', '').replace('_', '-');
                        charge.push({ soc: `${socRange}%`, time: value });
                    } else if (key.startsWith('dischrg')) {
                        let socRange = key.replace('dischrg_', '').replace('_', '-');
                        discharge.push({ soc: `${socRange.split("-").reverse().join("-")}%`, time: value });
                    }
                }
            }
            if (res[1]) {
                for (let i = 0; i < res[1].length; i++) {
                    cycleCharge.push({ cycle: res[1][i].cyc_count, time: (res[1][i].total_chrg_time / 60).toFixed(2), date: res[1][i].date })
                    cycleDischarge.push({ cycle: res[1][i].cyc_count, time: (res[1][i].total_dischrg_time / 60).toFixed(2), date: res[1][i].date })
                }

            }
            let tempMasterData = {
                charging: charge,
                discharging: discharge.reverse(),
                cycleCharging: cycleCharge,
                cycleDischarging: cycleDischarge
            }
            setMasterData(tempMasterData);
            let temp = LineChart('bar', tempMasterData.charging, "Charging Time (Minutes)", "SOC");
            let tempLineCharge = LineChart('line', tempMasterData.cycleCharging, "Charging Time (Minutes)", "Number of Cycles")
            let tempDischargeBar = LineChart("bar", tempMasterData.discharging, "Discharging Time (Minutes)", "SOC")
            let tempDischargeLine = LineChart('line', tempMasterData.cycleDischarging, "Discharging Time (Minutes)", "Number of Cycles")
            setChargingLineType(tempLineCharge);
            setDischargeLine(tempDischargeLine);
            setDischargingBarType(tempDischargeBar)
            setBarType(temp);
        })

    }

    useEffect(() => {
        let projection = { thingName: 1, alias: 1, 'location.siteName': 1 }
        Promise.all([service.getThingNames(serviceID, projection)]).then((res) => {
            let tempArray = []
            res[0].map((item) => {
                let temp = {
                    label: item.location.siteName,
                    value: item.thingName
                }
                tempArray.push(temp)
            })
            setSelectedThing(tempArray[0].value)
            setThingList(tempArray)
            populate(tempArray[0])

        })
    }, [])

    return (
        <div className='grid col-12 justify-content-center align-items-center ml-1 lg:ml-0'>
            <div className='col-12 grid lg:mr-4 mr-0'>
                <div className='lg:col-3 col-12'><Calendar
                    id="range"
                    value={selectedDate}
                    placeholder="Select Date"
                    onChange={handleDateChange}
                    showIcon
                    readOnlyInput
                    className="p-calendar flex align-items-center bg-white"
                    style={{
                        borderRadius: '17px',
                        border: '2px solid #0C6291',

                    }}
                    dateFormat="dd-mm-yy"
                /></div>
                <div className='lg:col-3 col-12'><Dropdown
                    id="tollDropdown"
                    value={selectedThing}
                    options={thingList}
                    onChange={(e) => {
                        setSelectedThing(e.value)
                        populate(e.value)
                    }}
                    placeholder={
                        <span className="flex align-items-center" style={{ color: '#0C6291' }}>
                            <i className="" style={{ marginRight: '8px' }}></i>
                            Select a Site
                        </span>
                    }

                    className="p-dropdown flex align-items-center bg-white"
                    style={{
                        borderRadius: '20px',
                        border: '2px solid #0C6291',
                        width: "100%"
                    }}
                /></div>
            </div>
            <div className='col-12 flex flex-wrap  justify-content-around  card  lg:ml-0 borderStyle3' style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <h3 className='col-12 flex justify-content-center align-items-center font-bold text-2xl text-gray-800 text-uppercase mb-3'>Charging time</h3>
                <div className='lg:col-4 col-12   '>
                    <DataTable value={masterData.charging} selectionMode='single' rows={10} style={{ marginTop: "0" }} scrollable className='mt-1' onRowSelect={(row) => { }}>
                        <Column headerClassName='bg-cyan-500 text-white font-medium' header="Soc" bodyClassName='bg-white font-medium  surface-300 bg-gray-200' field="soc"></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Time taken for Charging (Minutes)" field="time" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 col-1' ></Column>
                    </DataTable>
                </div>
                <div className='lg:col-8 col-12'>
                    <HighchartsReact highcharts={Highcharts} options={barType} />
                </div>
            </div>
            <div className='col-12 flex flex-wrap  justify-content-around  card  lg:ml-0 borderStyle1 ' style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                <h3 className="col-12 flex justify-content-center align-items-center font-bold text-2xl text-gray-800 text-uppercase mb-3">
                    Charging time variation with cycles
                </h3>
                <div className='lg:col-4 col-12 '>
                    <DataTable value={masterData.cycleCharging} selectionMode='single' rows={10} scrollable style={{ marginTop: "0" }} className='' onRowSelect={(row) => { }}>
                        <Column headerClassName='bg-cyan-500 text-white font-medium' header="Cycles" bodyClassName='bg-white font-medium  surface-300 bg-gray-200' field="cycle"></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Charging Time (Minutes)" field="time" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 col-1' ></Column>
                    </DataTable>
                </div>
                <div className='lg:col-8 col-12'>
                    <HighchartsReact highcharts={Highcharts} options={chargingLine} />
                </div>
            </div>
            <div className='col-12 flex flex-wrap  justify-content-around  card  lg:ml-0 borderStyle3' style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <h3 className='col-12 flex justify-content-center align-items-center font-bold text-2xl text-gray-800 text-uppercase mb-3'>Discharge time</h3>
                <div className='lg:col-4 col-12   '>
                    <DataTable value={masterData.discharging} selectionMode='single' rows={10} scrollable style={{ marginTop: "0" }} className='mt-1' onRowSelect={(row) => { }}>
                        <Column headerClassName='bg-cyan-500 text-white font-medium' header="BB Discharge Capacity(AH)" bodyClassName='bg-white font-medium  surface-300 bg-gray-200' field="soc"></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Discharge Time(Minutes)" field="time" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 ' ></Column>
                    </DataTable>
                </div>
                <div className='lg:col-8 col-12'>
                    <HighchartsReact highcharts={Highcharts} options={dischargingBarType} />
                </div>
            </div>
            <div className='col-12 flex flex-wrap  justify-content-around  card  lg:ml-0 borderStyle1 ' style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                <h3 className='col-12 flex justify-content-center align-items-center font-bold text-2xl text-gray-800 text-uppercase mb-3'>Discharge time variation with cycles</h3>
                <div className='lg:col-4 col-12 '>
                    <DataTable value={masterData.cycleDischarging} selectionMode='single' rows={10} scrollable style={{ marginTop: "0" }} className='' onRowSelect={(row) => { }}>
                        <Column headerClassName='bg-cyan-500 text-white font-medium' header="Cycles" bodyClassName='bg-white font-medium  surface-300 bg-gray-200' field="cycle"></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Discharge Time (Minutes)" field="time" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 ' ></Column>
                    </DataTable>
                </div>
                <div className='lg:col-8 col-12'>
                    <HighchartsReact highcharts={Highcharts} options={dischargeLine} />
                </div>
            </div>
        </div>
    )
}
