import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { Component } from "react";
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import moment from "moment";

export default class ReportingEmployeesSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeDistanceChart: null,
            employeeTravelTimeChart: null,
            allEmployeeDetails: null,
            selectEmployee: null,
            employeeList: null,
            employeeData: null,
            date: null,
            totalDistance: null,
            totalTime: null,
            employeeAvgTimeChart: null,
            employeeAvgStop: null,
            allEmployeeAttendance: null,
        };

        this.onEmployeeChange = this.onEmployeeChange.bind(this);
    }

    componentDidMount = () => {
        axios.post(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${localStorage.getItem("serviceId")}/nq-location-history`, {}).then((res) => {
            let employeeData = res.data;

            let employeeName = [];
            let empName = [];
            let avgDistanceArray = [];
            let maxDistance = [];
            let avgTotalTime = [];
            let avgNumberOfStop = [];
            employeeData.forEach((item) => {
                employeeName.push({ name: item.thingName, code: item.thingName });
                empName.push(item.thingName);
            });

            empName.forEach((item) => {
                let filterData = employeeData.filter((data) => data.thingName === item);
                let avgDistance = 0;
                let minMax = [];
                let totalTimeArray = 0;
                let numberOfStop = 0;
                filterData.forEach((a) => {
                    avgDistance += parseFloat((a.totalDistanceTravelled / 1000 / 2).toFixed(2));
                    minMax.push(parseFloat((a.totalDistanceTravelled / 1000).toFixed(2)));
                    let end = moment(a.endTime);
                    let start = moment(a.startTime);
                    let duration = moment.duration(end.diff(start)).as("hours");
                    totalTimeArray += duration;

                    a.stoppagePoints.forEach((data) => {
                        if (data.stoppageDuration >= 1800) {
                            numberOfStop += 1;
                        }
                    });
                });

                avgNumberOfStop.push(parseFloat((numberOfStop / 2).toFixed(2)));
                avgTotalTime.push(parseFloat((totalTimeArray / 2).toFixed(2)));
                avgDistanceArray.push(avgDistance);

                maxDistance.push(Math.max(...minMax));
            });

            let employeeAvgStop = {
                chart: {
                    type: "column",
                },
                title: {
                    text: `Avg Number of Stop`,
                    style: chartHeadingStyle,
                    align: "center",
                },
                xAxis: [
                    {
                        categories: empName,
                        crosshair: true,
                    },
                ],
                yAxis: {
                    title: {
                        text: "Stop",
                    },
                },
                tooltip: {
                    shared: true,
                },
                plotOptions: {
                    series: {
                        pointWidth: 20,
                    },
                },
                series: [
                    {
                        name: "Avg Number of Stop",
                        data: avgNumberOfStop,
                        color: colorPalettes.color2,
                    },
                ],
            };

            let employeeAvgTimeChart = {
                chart: {
                    type: "column",
                },
                title: {
                    text: `Avg Time`,
                    style: chartHeadingStyle,
                    align: "center",
                },
                xAxis: [
                    {
                        categories: empName,
                        crosshair: true,
                    },
                ],
                yAxis: {
                    title: {
                        text: "Time (Hour)",
                    },
                },
                tooltip: {
                    shared: true,
                },
                plotOptions: {
                    series: {
                        pointWidth: 20,
                    },
                },
                series: [
                    {
                        name: "Avg Total Time",
                        data: avgTotalTime,
                        color: colorPalettes.color2,
                    },
                ],
            };

            let employeeDistanceChart = {
                chart: {
                    type: "column",
                },
                title: {
                    text: `Daily Avg Distance`,
                    style: chartHeadingStyle,
                    align: "center",
                },
                xAxis: [
                    {
                        categories: empName,
                        crosshair: true,
                    },
                ],
                yAxis: {
                    title: {
                        text: "Distance (km)",
                    },
                },
                tooltip: {
                    shared: true,
                },
                plotOptions: {
                    series: {
                        pointWidth: 20,
                    },
                },
                series: [
                    {
                        name: "Daily Avg Distance",
                        data: avgDistanceArray,
                        color: colorPalettes.color2,
                    },
                    {
                        name: "Max Distance in a Day",
                        data: maxDistance,
                        color: colorPalettes.color4,
                    },
                ],
            };
            this.setState({ employeeDistanceChart: employeeDistanceChart, employeeAvgTimeChart: employeeAvgTimeChart, employeeAvgStop: employeeAvgStop });

            this.setState({ employeeList: employeeName, employeeData: employeeData, selectEmployee: employeeName[0] }, () => {
                this.initEmployeeTravelTime(this.state.selectEmployee);
            });
        });

        let allEmployeeDetails = {
            chart: {
                type: "pie",
                options3d: {
                    enabled: true,
                    alpha: 45,
                    beta: 0,
                },
            },
            title: {
                text: "Employees in Hierarchy",
                align: "center",
                style: chartHeadingStyle,
            },

            accessibility: {
                point: {
                    valueSuffix: "%",
                },
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    depth: 35,
                    dataLabels: {
                        enabled: true,
                        format: "{point.name}",
                    },
                },
            },
            series: [
                {
                    type: "pie",
                    name: "Employee",
                    data: [
                        ["Unregistered", 23],
                        ["Irregular", 18],
                        {
                            name: "Regular",
                            y: 12,
                            // sliced: true,
                            // selected: true,
                        },
                        ["Registered but never logged in", 9],
                    ],
                },
            ],
        };

        let allEmployeeAttendance = {
            chart: {
                type: "pie",
                options3d: {
                    enabled: true,
                    alpha: 45,
                    beta: 0,
                },
            },
            title: {
                text: "Employees Attendance in Hierarchy",
                align: "center",
                style: chartHeadingStyle,
            },

            accessibility: {
                point: {
                    valueSuffix: "%",
                },
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    depth: 35,
                    dataLabels: {
                        enabled: true,
                        format: "{point.name}",
                    },
                },
            },
            series: [
                {
                    type: "pie",
                    name: "Employee",
                    data: [
                        ["Not logged in yet", 18],
                        ["On Leave", 9],
                        ["Logged in", 23],
                    ],
                },
            ],
        };

        this.setState({ allEmployeeDetails: allEmployeeDetails, allEmployeeAttendance: allEmployeeAttendance });
    };

    onEmployeeChange(e) {
        this.setState({ selectEmployee: e.value }, () => {
            this.initEmployeeTravelTime(this.state.selectEmployee);
        });
    }

    initEmployeeTravelTime = (selectEmployee) => {
        let date = [];
        let totalDistance = [];
        let totalTime = [];
        this.state.employeeData.forEach((item) => {
            if (item.thingName === selectEmployee.name) {
                date.push(moment(item.date).format("Do MMM, YYYY"));
                totalDistance.push(parseFloat((item.totalDistanceTravelled / 1000).toFixed(2)));
                let end = moment(item.endTime);
                let start = moment(item.startTime);
                let duration = moment.duration(end.diff(start)).as("hours");
                totalTime.push(parseFloat(duration.toFixed(2)));
            }
        });

        this.setState({ date: date, totalDistance: totalDistance, totalTime: totalTime }, () => {
            let employeeTravelTimeChart = {
                chart: {
                    zoomType: "xy",
                },
                title: {
                    text: `Travelled Time & Distance`,
                    align: "center",
                    style: chartHeadingStyle,
                },
                xAxis: [
                    {
                        categories: this.state.date,
                        crosshair: true,
                    },
                ],
                yAxis: [
                    {
                        // Primary yAxis
                        labels: {
                            format: "{value}",
                            style: {
                                color: Highcharts.getOptions().colors[1],
                            },
                        },
                        title: {
                            text: "Distance",
                            style: {
                                color: Highcharts.getOptions().colors[1],
                            },
                        },
                    },
                    {
                        // Secondary yAxis
                        title: {
                            text: "Time",
                            style: {
                                color: colorPalettes.color1,
                            },
                        },
                        labels: {
                            format: "{value}",
                            style: {
                                color: colorPalettes.color1,
                            },
                        },
                        opposite: true,
                    },
                ],
                tooltip: {
                    shared: true,
                },

                series: [
                    {
                        name: "Daily Distance",
                        type: "column",
                        data: this.state.totalDistance,
                        color: colorPalettes.color2,
                        tooltip: {
                            valueSuffix: " Km",
                        },
                    },
                    {
                        name: "Daily Travel Time",
                        type: "line",
                        data: this.state.totalTime,
                        color: colorPalettes.color4,
                        yAxis: 1,
                        tooltip: {
                            valueSuffix: "hour",
                        },
                    },
                ],
            };
            this.setState({ employeeTravelTimeChart: employeeTravelTimeChart });
        });
    };

    render() {
        return (
            <>
                <div className="grid">
                    <div className="col-12 lg:col-6">
                        <HighchartsReact highcharts={Highcharts} options={this.state.allEmployeeDetails} />
                    </div>
                    <div className="col-12 lg:col-6">
                        <HighchartsReact highcharts={Highcharts} options={this.state.allEmployeeAttendance} />
                    </div>
                </div>
                <div className="col-12 lg:col-12">
                    <HighchartsReact highcharts={Highcharts} options={this.state.employeeDistanceChart} />
                </div>
                <div className="col-12 lg:col-12">
                    {/* <div className="card">
                        <div className="flex justify-content-end">
                            <Dropdown value={this.state.selectEmployee} options={this.state.employeeList} onChange={this.onEmployeeChange} optionLabel="name" placeholder="Select a Employee" />
                        </div>
                        <HighchartsReact highcharts={Highcharts} options={this.state.employeeTravelTimeChart} />
                    </div> */}
                </div>
                <div className="col-12 lg:col-12">
                    <HighchartsReact highcharts={Highcharts} options={this.state.employeeAvgTimeChart} />
                </div>
                <div className="col-12 lg:col-12">
                    <HighchartsReact highcharts={Highcharts} options={this.state.employeeAvgStop} />
                </div>
            </>
        );
    }
}
