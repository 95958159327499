import React from "react";
import moment from "moment";

const ProjectList = ({ data, showDetails }) => {
    return (
        <div className="project-list-grid">
            <div className="list-header">
                <span className="sl text-lg font-bold">Sl</span>
                <span style={{ width: "30px" }}></span>
                <span className="title text-lg font-bold ml-3">Name</span>
                <span className="type text-lg font-bold">Type</span>
                <span className="datetime text-lg font-bold">Modified On</span>
                <span className="size text-lg font-bold">Size</span>
            </div>
            <div className="project-body">
                {data.map((item, index) => {
                    return (
                        <div className="projects" key={index} onDoubleClick={(ev) => showDetails(`${process.env.REACT_APP_FILE_SHARE_BASE_URL}${item.fsid}`)}>
                            <span className="sl text-lg text-bold">{index + 1}.</span>
                            <img src="images/folder_icon.png" alt="Project name" />
                            <span className="title text-lg text-bold ml-3">{item.name}</span>
                            {/* <span className="type text-500">{item.type}</span>
                            <span className="datetime text-500">{moment(item.time).format("DD-MM-YYYY hh:mm A")}</span>
                            <span className="size text-500">{item.size} Kb</span> */}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ProjectList;
