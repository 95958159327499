import React, { useState, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";

function Users() {
    // const [products, setProducts] = useState([]);

    const products = [
        {
            id: 1,
            name: "Leanne Graham",
            username: "Bret",
            email: "Sincere@april.biz",
            address: {
                street: "Kulas Light",
                suite: "Apt. 556",
                city: "Gwenborough",
                zipcode: "92998-3874",
                geo: {
                    lat: "-37.3159",
                    lng: "81.1496",
                },
            },
            phone: "17707368031",
            website: "hildegard.org",
            company: {
                name: "Romaguera-Crona",
                catchPhrase: "Multi-layered client-server neural-net",
                bs: "harness real-time e-markets",
            },
        },
        {
            id: 2,
            name: "Ervin Howell",
            username: "Antonette",
            email: "Shanna@melissa.tv",
            address: {
                street: "Victor Plains",
                suite: "Suite 879",
                city: "Wisokyburgh",
                zipcode: "90566-7771",
                geo: {
                    lat: "-43.9509",
                    lng: "-34.4618",
                },
            },
            phone: "0106926593",
            website: "anastasia.net",
            company: {
                name: "Deckow-Crist",
                catchPhrase: "Proactive didactic contingency",
                bs: "synergize scalable supply-chains",
            },
        },
        {
            id: 3,
            name: "Clementine Bauch",
            username: "Samantha",
            email: "Nathan@yesenia.net",
            address: {
                street: "Douglas Extension",
                suite: "Suite 847",
                city: "McKenziehaven",
                zipcode: "59590-4157",
                geo: {
                    lat: "-68.6102",
                    lng: "-47.0653",
                },
            },
            phone: "14631234447",
            website: "ramiro.info",
            company: {
                name: "Romaguera-Jacobson",
                catchPhrase: "Face to face bifurcated interface",
                bs: "e-enable strategic applications",
            },
        },
        {
            id: 4,
            name: "Patricia Lebsack",
            username: "Karianne",
            email: "Julianne.OConner@kory.org",
            address: {
                street: "Hoeger Mall",
                suite: "Apt. 692",
                city: "South Elvis",
                zipcode: "53919-4257",
                geo: {
                    lat: "29.4572",
                    lng: "-164.2990",
                },
            },
            phone: "4931709623",
            website: "kale.biz",
            company: {
                name: "Robel-Corkery",
                catchPhrase: "Multi-tiered zero tolerance productivity",
                bs: "transition cutting-edge web services",
            },
        },
        {
            id: 5,
            name: "Chelsey Dietrich",
            username: "Kamren",
            email: "Lucio_Hettinger@annie.ca",
            address: {
                street: "Skiles Walks",
                suite: "Suite 351",
                city: "Roscoeview",
                zipcode: "33263",
                geo: {
                    lat: "-31.8129",
                    lng: "62.5342",
                },
            },
            phone: "2549541289",
            website: "demarco.info",
            company: {
                name: "Keebler LLC",
                catchPhrase: "User-centric fault-tolerant solution",
                bs: "revolutionize end-to-end systems",
            },
        },
        {
            id: 6,
            name: "Mrs. Dennis Schulist",
            username: "Leopoldo_Corkery",
            email: "Karley_Dach@jasper.info",
            address: {
                street: "Norberto Crossing",
                suite: "Apt. 950",
                city: "South Christy",
                zipcode: "23505-1337",
                geo: {
                    lat: "-71.4197",
                    lng: "71.7478",
                },
            },
            phone: "14779358478",
            website: "ola.org",
            company: {
                name: "Considine-Lockman",
                catchPhrase: "Synchronised bottom-line interface",
                bs: "e-enable innovative applications",
            },
        },
        {
            id: 7,
            name: "Kurtis Weissnat",
            username: "Elwyn.Skiles",
            email: "Telly.Hoeger@billy.biz",
            address: {
                street: "Rex Trail",
                suite: "Suite 280",
                city: "Howemouth",
                zipcode: "58804-1099",
                geo: {
                    lat: "24.8918",
                    lng: "21.8984",
                },
            },
            phone: "2100676132",
            website: "elvis.io",
            company: {
                name: "Johns Group",
                catchPhrase: "Configurable multimedia task-force",
                bs: "generate enterprise e-tailers",
            },
        },
        {
            id: 8,
            name: "Nicholas Runolfsdottir V",
            username: "Maxime_Nienow",
            email: "Sherwood@rosamond.me",
            address: {
                street: "Ellsworth Summit",
                suite: "Suite 729",
                city: "Aliyaview",
                zipcode: "45169",
                geo: {
                    lat: "-14.3990",
                    lng: "-120.7677",
                },
            },
            phone: "5864936943",
            website: "jacynthe.com",
            company: {
                name: "Abernathy Group",
                catchPhrase: "Implemented secondary concept",
                bs: "e-enable extensible e-tailers",
            },
        },
        {
            id: 9,
            name: "Glenna Reichert",
            username: "Delphine",
            email: "Chaim_McDermott@dana.io",
            address: {
                street: "Dayna Park",
                suite: "Suite 449",
                city: "Bartholomebury",
                zipcode: "76495-3109",
                geo: {
                    lat: "24.6463",
                    lng: "-168.8889",
                },
            },
            phone: "7759766794",
            website: "conrad.com",
            company: {
                name: "Yost and Sons",
                catchPhrase: "Switchable contextually-based project",
                bs: "aggregate real-time technologies",
            },
        },
        {
            id: 10,
            name: "Clementina DuBuque",
            username: "Moriah.Stanton",
            email: "Rey.Padberg@karina.biz",
            address: {
                street: "Kattie Turnpike",
                suite: "Suite 198",
                city: "Lebsackbury",
                zipcode: "31428-2261",
                geo: {
                    lat: "-38.2386",
                    lng: "57.2232",
                },
            },
            phone: "0246483804",
            website: "ambrose.net",
            company: {
                name: "Hoeger LLC",
                catchPhrase: "Centralized empowering task-force",
                bs: "target end-to-end models",
            },
        },
    ];

    const headerGroup1 = (
        <ColumnGroup>
            <Row>
                <Column header="Photo" className="bg-primary"></Column>
                <Column header="User Name" className="bg-primary"></Column>
                <Column header="Email" className="bg-primary"></Column>
                <Column header="Mobile" className="bg-primary"></Column>
                <Column header="Module Access" className="bg-primary"></Column>
                <Column header="Location Access" className="bg-primary"></Column>
                <Column header="Category" className="bg-primary"></Column>
                <Column header="Shift" className="bg-primary"></Column>
                <Column header="Action" className="bg-primary"></Column>
            </Row>
        </ColumnGroup>
    );

    const headerGroup2 = (
        <ColumnGroup>
            <Row>
                <Column header="Email" className="bg-primary"></Column>
                <Column header="Domain Name" className="bg-primary"></Column>
                <Column header="Location Traking Allowed" className="bg-primary"></Column>
                <Column header="Self Registration Allowed" className="bg-primary"></Column>
                <Column header="Notification Allowed" className="bg-primary"></Column>
                <Column header="Action" className="bg-primary"></Column>
            </Row>
        </ColumnGroup>
    );

    const actionDetails = (value) => {
        return (
            <>
                <Button label="Edit" className="p-button-rounded p-button-info" />
                <Button label="Delete" className="p-button-rounded p-button-info" style={{ backgroundColor: "red" }} />
            </>
        );
    };
    return (
        <>
            <div>
                <TabView>
                    <TabPanel header="Manage Web Users">
                        <div className="card">
                            <div className="flex justify-content-between">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText value={[]} onChange={[]} placeholder="Search User" />
                                </span>
                                <Button type="button" label="Add USer" className="p-button-outlined" onClick={[]} />
                            </div>
                            <DataTable value={products} headerColumnGroup={headerGroup1} responsiveLayout="scroll" className="mt-5">
                                <Column field="code" className="font-semibold"></Column>
                                <Column field="username" className="font-semibold"></Column>
                                <Column field="email" className="font-semibold"></Column>
                                <Column field="phone" className="font-semibold"></Column>
                                <Column field="" className="font-semibold"></Column>
                                <Column field="" className="font-semibold"></Column>
                                <Column field="" className="font-semibold"></Column>
                                <Column field="" className="font-semibold"></Column>
                                <Column body={actionDetails} className="font-semibold"></Column>
                            </DataTable>
                        </div>
                    </TabPanel>
                    <TabPanel header="Manage Mobile Users">
                        <div className="card">
                            <div className="col-12 md:col-12">
                                <h3>Allowed Users List</h3>
                            </div>
                            <div className="flex justify-content-between">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText value={[]} onChange={[]} placeholder="Search" />
                                </span>
                                <Button type="button" icon="pi pi-plus" label="IMPORT USERS" className="p-button-outlined p-button-secondary" onClick={[]} />
                            </div>
                            <DataTable value={products} responsiveLayout="scroll" headerColumnGroup={headerGroup2} className="mt-5">
                                <Column field="email" className="font-semibold"></Column>
                                <Column field="website" className="font-semibold"></Column>
                                <Column field="category" className="font-semibold"></Column>
                                <Column field="quantity" className="font-semibold"></Column>
                                <Column field="name" className="font-semibold"></Column>
                                <Column field="action" className="font-semibold"></Column>
                            </DataTable>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </>
    );
}

export default Users;
