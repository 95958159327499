import React from "react";
import { TowerService } from "../../service/TowerService";
import { DataTable } from "primereact/datatable";
import { Column } from 'primereact/column';
import moment from 'moment-timezone';

export default class RecaptureForecast extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: []
        }
        this.towerAddressMap = {};
        this.towerService = new TowerService();
    }

    componentDidMount() {
        let to_data = moment().format("YYYY-MM-DD");
	    let from_date = moment().subtract(1, 'years').format("YYYY-MM-DD");
        let conditions = ["Ice pellets", "Torrential rain shower", "Heavy snow", "Blowing snow", "Moderate or heavy freezing rain", "Light freezing rain", "Heavy rain at times"]
        if(localStorage.getItem("region").toLocaleLowerCase() === "in" || localStorage.getItem("region").toLocaleLowerCase() === "india")  conditions = ["Moderate rain",  "Heavy rain", "Moderate or heavy rain shower",  "Torrential rain shower"];
        Promise.all([this.towerService.getWeatherEventWiseCount(from_date,to_data,  conditions), this.towerService.getTowerQuery({},{_id: 0, "siteDetails.location.resolvedAddress.address": 1, "myxTowerid": 1, "customerSiteid": 1})])
        .then(resp => {
            let weatherEvents = resp[0];
            let towersAddress = resp[1];
            if(towersAddress && towersAddress.length){
                towersAddress.forEach(t => {
                    if( t.siteDetails.location && t.siteDetails.location.resolvedAddress && t.siteDetails.location.resolvedAddress.address) this.towerAddressMap[t.myxTowerid] = {address: t.siteDetails.location.resolvedAddress.address, customerSiteid: t.customerSiteid };
                })
            }
            let weatherData = [];
            weatherEvents.forEach(r => {  
                if(this.towerAddressMap[r._id]) {
                    let newObj = {"Tower Id": this.towerAddressMap[r._id].customerSiteid, "Address":  this.towerAddressMap[r._id].address}
                    delete r._id;
                    delete r.count;
    
                    Object.assign(newObj, r);
                    weatherData.push(newObj);
                }
            })
            this.setState({
                events: weatherData
            });

        }).catch(e => {
            console.error(e);
        })
    }

    render = () => {
        return (
            <div>
                <h2>Recapture Forecaster</h2>
                {/* <h4>We have initiated the process of building a model for forecasting when to go for a recapture and research paper submitted to NSF. Analysed the degitized towers to identify the factors that influence the decision - </h4> */}

                <h3>1. Weather Impact</h3>
                <h4>Extreme Weather events and the number of instances of it that the tower site was exposed to -</h4>

                {
                    this.state.events && this.state.events.length ?
                        <div className="card">

                            <DataTable value={this.state.events} paginator={true} rows={6}>
                                {
                                    Object.keys(this.state.events[0]).map(k => {
                                        return (
                                            <Column key={k} headerClassName='color1-bg' bodyClassName="color2-bg" style={{width: k === ("Tower Id" || "Address")? "7rem":"4rem"}} header={k} field={k}></Column>
                                        )
                                    })
                                }
                            </DataTable>
                        </div> : null
                }
                 <h3>2. Changes in Tenancy and loading of the tower</h3>
                <h4>Master data needed for performing this analysis</h4>
                {/* <ul style={{paddingTop: "5vh"}}> 
                    <li  ><h4>Weather condition impact since last capture</h4></li>
                    <li style={{paddingTop: "3vh"}}><h4>Extreme weather events</h4></li>
                    <li style={{paddingTop: "3vh"}} ><h4>Tenancy change</h4></li>
                </ul> */}
            </div>
        )
    }
}