import React, { useEffect, useState} from 'react'
import { Button } from 'primereact/button'
import { Avatar } from 'primereact/avatar';
import { read, utils } from "xlsx";
import ReproService from "../repro/ReproService"
import DBservice from "../../service/dbService";

const ReproUpload = () => {
    const [fileName, setFileName] = useState()
    const [file, setFile] = useState(null);
    const [disableBtn, setBtnDisabled] = useState(true);
    const [infoMsg, setInfoMsg] = useState("");
    const username = localStorage.getItem("email")
    const serviceId = localStorage.getItem("serviceId")
    const [psmUploadAccess, setpsmUploadAccess] = useState(false)
    const [loader, setLoader] = useState(true)

    useEffect(()=>{
        DBservice.getUserPermissionInfo(serviceId, username).then((res)=>{
            res = res[0]
            if(res && res.psmUpload){
                setpsmUploadAccess(true)
            }
            setLoader(false)
        })     
    },[])
    const handleFile = (e) => {
        if (e.target.files && e.target.files.length) {
            setInfoMsg("");
            setBtnDisabled(false);
            setFileName(e.target.files[0].name);
            setFile(e.target.files[0]);
        }
    }

    const handleSubmit = async () => {
        setBtnDisabled(true);
        const formData = new FormData();
        const extension = fileName.split(".").pop();
        formData.append("username", username)
        formData.append("serviceId", serviceId);
        if (extension === "csv") {
            formData.append("file", file);
        } else {
            const workbook = read(await file.arrayBuffer());
            const csv = utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
            const data = new Blob([csv], { type: "text/csv" });
            formData.append("file", data, "increffs_upload" + Date.now() + ".csv");
        }
        let uploadRes = await ReproService.uploadISBN(formData)
        if (uploadRes && uploadRes.success && uploadRes.responseCode === 200) {
            setInfoMsg("File uploaded succesfully");
            setFile(null)
            setFileName(null)
        } else {
            let errMsg =  (uploadRes && uploadRes.message) ? uploadRes.message : "Something went wrong"
            setInfoMsg(errMsg);
            setBtnDisabled(false)
        }

    }
    return loader ? <div style={{ width: "100%", textAlign: "center", height: "80vh", verticalAlign: "center", position: "relative" }}><i className="pi pi-spin pi-spinner" style={{ fontSize: '4rem', verticalAlign: "middle", position: "absolute", top: "45%", left: "45%" }}></i></div>
         :  (!psmUploadAccess) ? <>
        <div className="col-12 " style={{ color: "#01579B" }}>
            <div className="card flex flex-column align-items-center justify-content-center" style={{ height: "60vh", backgroundColor: "#B3E5FC" }}>
                <span className="fa-solid fa-circle-info" style={{ fontSize: "3rem" }}></span>
                <h3>You don't have access to upload PSM Inventory</h3>
            </div>
        </div>
    </> : (
        <div className='col-12'> 
        <h2 style={{textAlign: "center"}}>PSM Inventory Alert</h2>
            <div className='grid card'>
                <div className='w-full'>
                    <h4 style={{ color: "#2D2D2D" }}>Instructions<span style={{ color: "red" }}> *</span></h4>
                    <div className='grid col-12 justify-content-between flex-wrap'>
                        <div className='col-12 lg:col-4'>
                            <div className='displayCard lg:h-8rem border-noround col-12 lg:col-11' style={{ "backgroundColor": "#F3F3F3" }}>
                                <Avatar label="1" className="mr-2 border-solid border-2" shape="circle"></Avatar>
                                <div>
                                    <h6 className='mt-2' style={{ letterSpacing: "0.1rem" }}> Download sample Template for PSM Inventory Alert. <a className='text-xl' href={process.env.REACT_APP_IMAGE_STATIC_URL + "/ReproUpload/Template.csv"} download>
                                        Click here
                                    </a></h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 lg:col-4'>
                            <div className='displayCard lg:h-8rem border-noround col-12 lg:col-11' style={{ "backgroundColor": "#F3F3F3" }}>
                                <Avatar label="2" className="mr-2 border-solid border-2" shape="circle"></Avatar>
                                <div>
                                    <h6 className='mt-2' style={{ letterSpacing: "0.1rem" }}> Add ISBN and threshold for each of the ISBN’s</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 lg:col-4'>
                            <div className='displayCard lg:h-8rem border-noround col-12 lg:col-11' style={{ "backgroundColor": "#F3F3F3" }}>
                                <Avatar label="3" className="mr-2 border-solid border-2" shape="circle"></Avatar>
                                <div>
                                    <h6 className='mt-2' style={{ letterSpacing: "0.1rem" }}> Upload the file </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='grid card justify-content-center'>
            <div className="flex m-3 flex-column align-items-center border-1 border-dashed border-400 border-round-md" style={{ padding: "1rem" }}>
                <div className="fileUpload">
                    <div className="text-6xl">
                        <i className="pi pi-upload" style={{ fontSize: "2rem", color: "#009688" }}></i>
                    </div>
                    <span>{fileName}</span>
                    <span className="mt-3 mb-3 block text-xl">Upload File</span>
                    <input type="file" className="upload" onChange={(e) => handleFile(e)} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                </div>
                {<Button className='text-xl' label="Upload" onClick={handleSubmit} disabled={disableBtn} />}
                {infoMsg ? (
                    <>
                        <br />
                        <span className="color2">{infoMsg}</span>
                    </>
                ) : null}
                <br />
                <a className='text-xl' href={process.env.REACT_APP_IMAGE_STATIC_URL + "/ReproUpload/Template.csv"} download>
                    Download Template
                </a>
            </div>
        </div>
        </div>
    )
}

export default ReproUpload