import React, { useEffect, useState } from "react";
import { Route, withRouter, useLocation } from "react-router-dom";
import App from "./App";
import Login from "./pages/Login";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";
import Landing from "./pages/Landing";
import AuthorizedRoutes from "./routes";
import ServiceList from "./pages/serviceList";
import { useDispatch } from "react-redux";
import Admin from "./pages/Admin";
import ConfirmSignup from "./pages/confirmSignup";
import ChangePassword from "./pages/changePassword";
import ForgotPassword from "./pages/forgotPassword";
import TenantService from "./service/tenant"
import { SetUserServiceDataInLocalStorage } from "./utilities/utillFunction";
import DeleteAccount from "./pages/DeleteAccount";
import TrackingInfo from "./pages/TrackingInfo";
const AppWrapper = (props) => {
    let location = useLocation();
    const [serviceData, setservice] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    document.body.style.backgroundColor = "#f7f7f7";
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const serviceId = params.get("serviceId");
    const fromMobile = params.get("mobile");

    if (token) localStorage.setItem("token", token);
    if (serviceId) localStorage.setItem("serviceId", serviceId);
    if (fromMobile) localStorage.setItem("mobile", fromMobile);

    useEffect(() => {
        const getServiceData = async () => {
            const res = await TenantService.getPermissions();
            if (res && res.length) {
                let serviceData = res.filter(r => r.serviceId == serviceId);
                let response = serviceData[0];
                await SetUserServiceDataInLocalStorage(response, dispatch)
            }
            setservice(res);
        };
        if (fromMobile === "true") {
            getServiceData(); // run it
        }
    }, []);

    switch (props.location.pathname) {
        case "/login":
            return <Route path="/login" component={Login} />;
        case "/error":
            return <Route path="/error" component={Error} />;
        case "/notfound":
            return <Route path="/notfound" component={NotFound} />;
        case "/access":
            return <Route path="/access" component={Access} />;
        case "/landing":
            return <Route path="/landing" component={Landing} />;
        case "/services":
            return <Route path="/services" component={ServiceList} />;
        case "/admin":
            return <Route path="/admin" component={Admin} />;
        case "/confirm-signup":
            return <Route path="/confirm-signup" component={ConfirmSignup} />;
        case "/change-password":
            return <Route path="/change-password" component={ChangePassword} />;
        case "/forgot-password":
            return <Route path="/forgot-password" component={ForgotPassword} />;
        case "/delete-account":
             return <Route path="/delete-account" component={DeleteAccount} />;
        case props.location.pathname.indexOf("/trackingstatus") > -1 && props.location.pathname:
            let id = props.location.pathname.split("/")[2]
            if(id) return <Route path="/trackingstatus/:id" component={TrackingInfo} />
            else return <Route component={NotFound} />
        default:
            return localStorage.getItem("mobile") && localStorage.getItem("token") ? serviceData ? <AuthorizedRoutes /> : <></> : <App />;
    }
};

export default withRouter(AppWrapper);
