import React, { Component } from "react";
import { connect } from 'react-redux';
import { Chart } from 'primereact/chart';
import { Dialog } from 'primereact/dialog';
import { TowerService } from "../../service/TowerService";
import TowerTransitionComponent from "./TowerTransitionComponent";
import { dispatchAction } from '../../utilities'; 
import { colorPalettes, barChartColors, pieChartColors } from "../../lib/constants";

const TowerTypes = {
	"GBT": {"towerDetails.towerConfig.towerType": {"$eq": "GBT"}},
	"RTT": {"towerDetails.towerConfig.towerType": {"$eq": "RTT"}},
	"GBM": {"towerDetails.towerConfig.towerType": {"$eq": "GBM"}},
	"RTP": {"towerDetails.towerConfig.towerType": {"$eq": "RTP"}}
}

const barChartOption = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    barThickness: 20,
    plugins: {
        legend: {
            position: "bottom",
            labels: {
                // usePointStyle: true,
                // pointStyle: "circle",
                color: "#000000",
            },
        }
    },
    scales: {
        x: {
            ticks: {
                color: "#000000",
            },
            grid: {
                color: "#FFFFFF",
            },
        },
        y: {
            ticks: {
                precision: 0,
                color: "#000000",
            },
            grid: {
                color: "#dedede",
            },
        },
    },
};

const pieOptions = {
	plugins: {
        legend: {
            position: 'bottom',
            labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                color: "#495057",
            },
        }
	},
};

const getTenancyWiseOption = (barClick) => {
    return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        barThickness: 20,
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    // usePointStyle: true,
                    // pointStyle: "circle",
                    color: "#000000",
                },
            }
        },
        onClick: barClick.bind(this),
        scales: {
            x: {
                ticks: {
                    color: "#000000",
                },
                grid: {
                    color: "#FFFFFF",
                },
            },
            y: {
                ticks: {
                    precision: 0,
                    color: "#000000",
                },
                grid: {
                    color: "#dedede",
                },
            },
        },
    }
}

class Tenancy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            averageTenancy: null,
            tenancyPie: pieOptions,
            tenancyPieData: null,
            TenancywisechartData: null,
            TenancNameywisechartData: null,
            loadLevelchartData: null, 
            TenancywisechartOptions: barChartOption,
            detailedViewClick: false,
            details: null,
            title: '',
            unit: this.props.unit
        }
        this.towerServices = new TowerService();
    }

    componentDidMount = () => {
        this.componentInit();
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.unit !== this.props.unit) {
            this.setState({   
                averageTenancy: null,
                tenancyPie: pieOptions,
                tenancyPieData: null,
                TenancywisechartData: null,
                TenancNameywisechartData: null,
                loadLevelchartData: null, 
                TenancywisechartOptions: barChartOption,
                //detailedViewClick: false,
                details: null,
            }, () => this.componentInit())
        }
    }

    componentInit = () => {
        Promise.all([this.towerServices.getTenancyCount(), this.towerServices.getOccupancyByTenants(), this.towerServices.getAllTowers()]).then(response => {

            let avgTenancy = response[0][0].avgTenancy;
            let data = response[0][0];
            let tOccupancyCnt = response[1];
            let allTowers = response[2];

            let no_ten_GBT = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'GBT' && elm.towerDetails.tenancyCount === 0));
            let single_ten_GBT = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'GBT' && elm.towerDetails.tenancyCount === 1));
            let dubble_ten_GBT = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'GBT' && elm.towerDetails.tenancyCount === 2));
            let multi_ten_GBT = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'GBT' && elm.towerDetails.tenancyCount > 2));

            let no_ten_RTT = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'RTT' && elm.towerDetails.tenancyCount === 0));
            let single_ten_RTT = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'RTT' && elm.towerDetails.tenancyCount === 1));
            let dubble_ten_RTT = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'RTT' && elm.towerDetails.tenancyCount === 2));
            let multi_ten_RTT = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'RTT' && elm.towerDetails.tenancyCount > 2));

            let no_ten_GBM = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'GBM' && elm.towerDetails.tenancyCount === 0));
            let single_ten_GBM = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'GBM' && elm.towerDetails.tenancyCount === 1));
            let dubble_ten_GBM = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'GBM' && elm.towerDetails.tenancyCount === 2));
            let multi_ten_GBM = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'GBM' && elm.towerDetails.tenancyCount > 2));

            let no_ten_RTP = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'RTP' && elm.towerDetails.tenancyCount === 0));
            let single_ten_RTP = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'RTP' && elm.towerDetails.tenancyCount === 1));
            let dubble_ten_RTP = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'RTP' && elm.towerDetails.tenancyCount === 2));
            let multi_ten_RTP = allTowers.filter(elm => (elm.towerDetails.towerConfig.towerType === 'RTP' && elm.towerDetails.tenancyCount > 2));

            let barData = {
                labels: ['GBT', 'RTT', 'GBM', 'RTP'],
                datasets: [
                    {
                        label: 'Total',
                        backgroundColor: barChartColors[0],
                        data: [(no_ten_GBT.length+single_ten_GBT.length+dubble_ten_GBT.length+multi_ten_GBT.length), (no_ten_RTT.length+single_ten_RTT.length+dubble_ten_RTT.length+multi_ten_RTT.length), (no_ten_GBM.length+single_ten_GBM.length+dubble_ten_GBM.length+multi_ten_GBM.length), (no_ten_RTP.length+single_ten_RTP.length+dubble_ten_RTP.length+multi_ten_RTP.length)]
                    },
                    {
                        label: 'NO Tenancy',
                        backgroundColor: barChartColors[1],
                        data: [no_ten_GBT.length, no_ten_RTT.length, no_ten_GBM.length, no_ten_RTP.length]
                    },
                    {
                        label: 'Single Tenancy',
                        backgroundColor: barChartColors[2],
                        data: [single_ten_GBT.length, single_ten_RTT.length, single_ten_GBM.length, single_ten_RTP.length]
                    },
                    {
                        label: 'Double Tenancy',
                        backgroundColor: barChartColors[3],
                        data: [dubble_ten_GBT.length, dubble_ten_RTT.length, dubble_ten_GBM.length, dubble_ten_RTP.length]
                    },
                    {
                        label: 'Multi Tenancy',
                        backgroundColor: barChartColors[4],
                        data: [multi_ten_GBT.length, multi_ten_RTT.length, multi_ten_GBM.length, multi_ten_RTP.length]
                    },
                    
                ]
            };
            //Tenancy wise count
            let tenancyPieData = {
                labels: ['Zero Tenancy', 'Single Tenancy', 'Double Tenancy', 'Multi Tenancy'],
                datasets: [
                    {
                        data: [data.noTenancy, data.singleTenancy, data.doubleTenancy, data.multiTenancy],
                        backgroundColor: pieChartColors,
                    },
                ],
            };
            //Tenancy name wise
            let tenantNm = [], tenantDataset = [], tenantObj = {};
            tOccupancyCnt.forEach((t, i) => {
                Object.keys(t).forEach(k => {
                    if (k !== "_id") {
                        if (tenantObj[k] && tenantObj[k].length) tenantObj[k].push(t[k]);
                        else tenantObj[k] = [t[k]];
                    }
                });
                tenantNm.push(t._id);
                // TenantNmQuery[t._id] = { 'tenants.antennas': { "$elemMatch": { "tennantName": t._id } } };
            });

            Object.keys(tenantObj).forEach((t, i) => {
                const isAllZero = tenantObj[t].every(item => item === 0);
                if (!isAllZero) tenantDataset.push({ label: t, fill: true, borderColor: colorPalettes.color3, backgroundColor: colorPalettes.color3, data: tenantObj[t] })
            })
            let tenancyNamewisechartData;
            if (tenantNm.length) tenancyNamewisechartData = { labels: tenantNm, datasets: tenantDataset }


            let loadArr = [];
            allTowers.forEach(item => {
                let load = 0;
                if (this.props.unit === "FPS") {
                    if(item.towerDetails.capacity.usedLoadCapacityWithWindLoad) load = Math.floor(item.towerDetails.capacity.usedLoadCapacityWithWindLoad * 2.205);
                } else if(item.towerDetails.capacity.usedLoadCapacityWithWindLoad) load = Math.floor(item.towerDetails.capacity.usedLoadCapacityWithWindLoad)

                loadArr.push(load);
            });
            //Prepare load ranges
            loadArr.sort((a, b) => a - b);
            let min = loadArr[0];
            let max = loadArr[loadArr.length - 1]
            if (min % 10) min = Math.floor(min / 10) * 10;
            if (max % 10) max = max + (10 - max % 10);
            var bucketList = this.bucket([min, max], 4);
            let t_load_count = [];
            bucketList.forEach((item, index) => {
                let t_l_from = item[0];
                let t_l_to = item[1];
                let t_load_length = [];
                if(this.props.unit === "FPS") {
                    t_load_length = allTowers.filter(elm => (elm.towerDetails.capacity.usedLoadCapacityWithWindLoad * 2.205) >= t_l_from && (elm.towerDetails.capacity.usedLoadCapacityWithWindLoad * 2.205) <= t_l_to);
                } else {
                    t_load_length = allTowers.filter(elm => elm.towerDetails.capacity.usedLoadCapacityWithWindLoad >= t_l_from && elm.towerDetails.capacity.usedLoadCapacityWithWindLoad <= t_l_to);
                }

                let tower_GBT = t_load_length.filter(tower => tower.towerDetails.towerConfig.towerType === 'GBT');
                let tower_RTT = t_load_length.filter(tower => tower.towerDetails.towerConfig.towerType === 'RTT');
                let tower_GBM = t_load_length.filter(tower => tower.towerDetails.towerConfig.towerType === 'GBM');
                let tower_RTP = t_load_length.filter(tower => tower.towerDetails.towerConfig.towerType === 'RTP');

                t_load_count.push({
                    labels: t_l_from + " - " + t_l_to,
                    GBT: tower_GBT.length,
                    RTT: tower_RTT.length,
                    GBM: tower_GBM.length,
                    RTP: tower_RTP.length,
                })
                
            });

            //Tenancy name wise
            let loadLabels = [];
            let loadGBTData = [];
            let loadRTTData = [];
            let loadGBMData = [];
            let loadRTPData = [];
            t_load_count.forEach(element => {
                loadLabels.push(element.labels + ((this.props.unit === "FPS") ? " lbs" : " Kg"));
                loadGBTData.push(element.GBT);
                loadRTTData.push(element.RTT);
                loadGBMData.push(element.GBM);
                loadRTPData.push(element.RTP);
            });

            let loadLevelchartData = {
                labels: loadLabels,
                datasets: [
                    {
                        label: 'Total',
                        backgroundColor: barChartColors[0],
                        data: [(loadGBTData[0]+loadRTTData[0]+loadGBMData[0]+loadRTPData[0]), (loadGBTData[1]+loadRTTData[1]+loadGBMData[1]+loadRTPData[1]), (loadGBTData[2]+loadRTTData[2]+loadGBMData[2]+loadRTPData[2]), (loadGBTData[3]+loadRTTData[3]+loadGBMData[3]+loadRTPData[3])]
                    },
                    {
                        label: 'GBT',
                        backgroundColor: barChartColors[1],
                        data: loadGBTData
                    },
                    {
                        label: 'RTT',
                        backgroundColor: barChartColors[2],
                        data: loadRTTData
                    },
                    {
                        label: 'GBM',
                        backgroundColor: barChartColors[3],
                        data: loadGBMData
                    },
                    {
                        label: 'RTP',
                        backgroundColor: barChartColors[4],
                        data: loadRTPData
                    }
                ]
            }

            this.setState({ 
                averageTenancy: avgTenancy, 
                TenancywisechartData: barData, 
                tenancyPieData: tenancyPieData, 
                TenancNameywisechartData : tenancyNamewisechartData,
                loadLevelchartData: loadLevelchartData
            });
        });
    }

    bucket = ([min, max], bucketsNumber) => {
		return Array.from({ length: bucketsNumber }).map((el, idx, arr, step = Math.floor((max - min) / bucketsNumber)) => {
			return [min + idx * step, min + (idx + 1) * step]
		})
	}

    onBarClick = (evt, element) => {

		if (element.length > 0) {
			let fountType = Object.keys(TowerTypes).find((e, i) => i === element[0].index);
			this.towerServices.getTowerQuery(TowerTypes[fountType]).then(data => {
                this.setState({
                    detailedViewClick: true,
                    details: data,
                    title: 'Tenancy'
                })
            })
		}
	}

    onTenancyBarClick = (evt, element) => {
        console.log("Element: ",element);
    }

    handleBack = () => {
		this.setState({
			detailedViewClick: false,
			details: null
		})
	}

    onInfoClick = (id) => {
		this.setState({
			displayInfo: true,
			infoText: "Calculated using formula from engineeringtoolbox.com Assumption : Air Density - 1.29 Kg/cubic meter, wind speed: max wind speed at the location in the last 1 year"
		});
	}

    render = () => {
        return (
            this.state.detailedViewClick ? (<TowerTransitionComponent title={this.state.title} towers={this.state.details} hadelBack={this.handleBack} />) : (
                <div className="grid">
                    <Dialog header="Info" visible={this.state.displayInfo} style={{ width: '35vw' }} modal onHide={() => this.setState({ displayInfo: false, infoText: null })}>
						<p>{this.state.infoText}</p>
					</Dialog>
                    <div className="col-12 md:col-6 lg:col-4">
                        <div className="card">
                            <h5 className="centerText">Tenancy wise chart</h5>
                            <div className="flex justify-content-center" style={{ position: 'relative' }}>
                                {
                                    this.state.averageTenancy ? (
                                        <div style={{position: 'absolute', zIndex: '0', textAlign: 'center', top: '28%', maxWidth: '118px'}}>
                                            <p className="mb-0 text-3xl font-bold">{ parseFloat(this.state.averageTenancy).toFixed(2) }</p>
                                            <p className="mb-0 text-lg">Average Tenancy</p>
                                        </div>
                                    ) : null
                                }
                                
                                <Chart style={{ position: 'relative', height: '300px' }} type="doughnut" data={this.state.tenancyPieData} options={this.state.tenancyPie} ></Chart>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-8">
                        <div className="card">
                            <h5 className="centerText">Tenancy wise chart</h5>
                            <div className="flex justify-content-center">
                                <Chart style={{ position: 'relative', width: '100%', height: '300px' }} type="bar" data={this.state.TenancywisechartData} options={getTenancyWiseOption(this.onBarClick)} ></Chart>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="card">
                            <h5 className="centerText">Occupancy by Tenancy</h5>
                            <div className="flex justify-content-center">
                                <Chart style={{ position: 'relative', width: '100%', height: '300px' }} type="bar" data={this.state.TenancNameywisechartData} options={getTenancyWiseOption(this.onTenancyBarClick)}></Chart>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="card" style={{ position: "relative" }}>
                            <h5 className="centerText">Occupancy by Weight (Wind Load adjusted)</h5>
                            <span className="card_info" onClick={() => this.onInfoClick()}>
                                <i className="pi pi-info-circle"></i>
                            </span>
                            <div className="flex justify-content-center">
                                <Chart style={{ position: 'relative', width: '100%', height: '300px' }} type="bar" data={this.state.loadLevelchartData} options={this.state.TenancywisechartOptions} ></Chart>
                            </div>
                        </div>
                    </div>
                </div>
            )
            
        )
    }

}

const mapStateToProps = (state) => {
	return { unit: state.changeUnit }
};
export default connect(mapStateToProps, { dispatchAction })(Tenancy);