import axios from "axios";
async function getAggregatedWeatherForecast(serviceId, payload){
    const res = await axios.put(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-sites-weather-forecast`, payload);
    return res.data;
}

async function getWeatherForecast(serviceId, payload){
    const res = await axios.post(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-sites-weather-forecast`, payload);
    return res.data;
}

const service = {
    getAggregatedWeatherForecast,
    getWeatherForecast
}
export default service