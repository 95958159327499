import React, { Component } from "react";
import { Image } from 'primereact/image';

class Compass extends Component {
    constructor(props) {
        super(props);
        this.imgUrl = `${process.env.REACT_APP_IMAGE_STATIC_URL}/images/${this.props.serviceID}/${this.props.thingName}.jpg`;
    }
    render = () => {
        return (
            
            <div className="img-grid">
                <Image src={this.imgUrl} alt="Image" width="100%" />
                <div className="compass-overlay">
                    <div className="arrow-holder"></div>
                    <div className="compass-arrow" style={{ transform: `rotate(${this.props.direction}deg)` }}></div>
                </div>
            </div>
        );
    }
}

export default Compass;