import React, { useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";

const UseageSummery = ({ chartData, unit }) => {
    const [state, setState] = useState(null);
    useEffect(() => {
        const option = {
            chart: {
                zoomType: "xy",
            },
            title: {
                text: "",
                align: "center",
                style: chartHeadingStyle,
            },
            xAxis: [
                {
                    categories: [],
                    crosshair: true,
                },
            ],
            yAxis: [
                {
                    // Primary yAxis
                    labels: {
                        format: `{value} ${unit === "FPS" ? "miles" : "Km"}`,
                        style: {
                            color: Highcharts.getOptions().colors[1],
                        },
                    },
                    title: {
                        text: "Distance",
                        style: {
                            color: Highcharts.getOptions().colors[1],
                        },
                    },
                },
                {
                    // Secondary yAxis
                    title: {
                        text: "Days",
                        style: {
                            color: colorPalettes.color1,
                        },
                    },
                    labels: {
                        format: "{value}",
                        style: {
                            color: colorPalettes.color1,
                        },
                    },
                    opposite: true,
                },
            ],
            tooltip: {
                shared: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
                series: {
                    pointWidth: 15,
                },
            },
            series: [],
        };
        option.xAxis[0].categories = chartData.category;
        option.series = chartData.data;
        option.title.text = chartData.title;
        setState(option);
    }, []);

    return state ? <HighchartsReact highcharts={Highcharts} options={state} /> : null;
};

export default UseageSummery;
