import React, { Component } from "react";
import { AssetService } from "../../service/AssetService";
import StateReportComponent from "./components/StateReportComponent";

class DgTransitionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            things: null,
            assets: null,
            stateData: null,
            position: null,
            setReport: false,
            stateLevel: false,
            cityLevel: false,
            siteLevel: false,
        };
        this.serviceID = localStorage.getItem("serviceId");
        this.assetsService = new AssetService();
    }

    componentDidMount = () => {
        let states = [];
        this.assetsService.getThings(this.serviceID).then((res) => {
            let things = res.responseData;
            const payload = {
                filter: {
                    thingName: { $in: things },
                },
            };
            this.assetsService.getAssets(this.serviceID, payload).then((res) => {
                res.forEach((item) => {
                    if (!states.includes(item.location.state)) {
                        states.push(item.location.state);
                    }
                });
                let dg_state = [],
                    position = [];
                states.forEach((item) => {
                    let dg_statewise = res.filter((elm) => item === elm.location.state);
                    let dg_position = [];
                    dg_statewise.map((dg) => {
                        if (dg.location.lat !== "") {
                            let location = dg.location.lat.split(",");
                            if (location.length > 1) {
                                let lat = parseFloat(location[0].replace(/[^0-9\.]+/g, ""));
                                let lng = parseFloat(location[1].replace(/[^0-9\.]+/g, ""));

                                dg_position.push({
                                    thingName: dg.thingName,
                                    pos: {
                                        lat: lat,
                                        lng: lng,
                                    },
                                });
                                position.push({
                                    thingName: dg.thingName,
                                    pos: {
                                        lat: lat,
                                        lng: lng,
                                    },
                                });
                            }
                        }
                    });

                    if (dg_statewise.length > 0) {
                        dg_state.push({
                            name: item,
                            total: dg_statewise.length,
                            position: dg_position,
                            details: dg_statewise,
                        });
                    }
                });
                this.setState({
                    assets: res,
                    stateData: dg_state,
                    position: position,
                    setReport: true,
                    stateLevel: true,
                    cityLevel: false,
                    siteLevel: false,
                });
            });
        });
    };

    render = () =>
        !this.state.setReport && this.state.assets === null ? null : (
            <div className="grid">
                {/* <div className="col-12">
                <h5>{this.props.title}</h5>
            </div>
            <div className="col-12" style={{position: 'relative'}}>
                {this.props.dgoverview ? null: <Button label="Back" onClick={this.props.hadelBack} className="mr-2 mb-2" style={{position: 'absolute', right: '2px', top: '9px'}} />}
                <nav className="app-breadcrumb p-breadcrumb p-component" aria-label="Breadcrumb"></nav>
            </div>  */}
                {this.state.stateLevel ? <StateReportComponent data={this.state.stateData} position={this.state.position} dgoverview={this.props.dgoverview} /> : null}
            </div>
        );
}

export default DgTransitionComponent;
