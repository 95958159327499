import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import service from "../../service/configService";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom";
import { read, utils } from "xlsx";
import * as XLSX from "xlsx";


const TrakingUsers = () => {
    const toast = useRef(null);
    const SERVICE_ID = localStorage.getItem("serviceId");
    const [userDialog, setuserDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [filterDialog, setfilterDialog] = useState(false);
    const [importUserDialog, setimportUserDialog] = useState(false);
    const [changeShiftDialog, setChangeShiftDialog] = useState(false);
    const [selectedShift, setSelectedShift] = useState(null);
    const [employeeData, setEmployeeData] = useState(null);
    const [copyemployeeData, setcopyemployeeData] = useState(null);
    const [stateValue, setStateValue] = useState(null);
    const [cityValue, setCityValue] = useState(null);
    const [filterValue, setFilterValue] = useState({
        country: "",
        state: "",
        circle: "",
        city: "",
        category: "",
    });
    const [employeeDataUpdate, setEmployeeDataUpdate] = useState({
        serviceId: SERVICE_ID,
        empId: "",
        name: "",
        department: "",
        email: "",
        circle: "",
        state: "",
        city: "",
        category: "",
        shift: "",
        country: "",
    });
    const [dCity, setDCity] = useState(null);
    const [dCountry, setDCountry] = useState(null);
    const [dCategory, setDCategory] = useState(null);
    const [dState, setDState] = useState(null);
    const [dCircle, setDCircle] = useState(null);

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [loading, setLoading] = useState(false);
    const [duplicate, setDuplicate] = useState([]);
    
    const handelFile = (e) => {
        setFileName(e.target.files[0].name);
        setFile(e.target.files[0]);
    };
    const handleSubmit = async () => {
        const formData = new FormData();
        let name = file.name.split(".");
        const extension = name[name.length - 1];

        if (extension === "csv") {
            formData.append("file", file);
        } else {
            const workbook = read(await file.arrayBuffer());
            const csv = utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
            const data = new Blob([csv], { type: "text/csv" });
            formData.append("file", data, "site_upload" + Date.now() + ".csv");
        }
        setLoading(true);

        service
            .uploadTrackingUser(formData, SERVICE_ID)
            .then((res) => {
                if (res.success) {
                    initPage();
                    toast.current.show({ severity: "success", summary: "Success", detail: res.message, life: 3000 });
                    setimportUserDialog(false);
                    setFileName("");
                    setFile(null);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: res.message, life: 3000 });
                    setLoading(false);
                }
            })
            .catch((e) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Something Went Wrong", life: 3000 });
                setimportUserDialog(false);
                setFileName("");
                setFile(null);
                setLoading(false);
            });
    };
    useEffect(() => {
        initPage();
    }, []);

    const initPage = () => {
        service.getEmployeeData(SERVICE_ID).then((res) => {
            setEmployeeData(res.responseData);
            setDuplicate(res.responseData);
            setcopyemployeeData(res.responseData);

            let cities = [];
            let countries = [];
            let states = [];
            let categories = [];
            let circles = [];

            res.responseData.forEach((element) => {
                if (!cities.some((item) => item.name === element.city)) {
                    cities.push({ name: element.city, code: element.city });
                }
                if (!countries.some((item) => item.name === element.country)) {
                    countries.push({ name: element.country, code: element.country });
                }
                if (!states.some((item) => item.name === element.state)) {
                    states.push({ name: element.state, code: element.state });
                }
                if (!categories.some((item) => item.name === element.category)) {
                    categories.push({ name: element.category, code: element.category });
                }
                if (!circles.some((item) => item.name === element.circle)) {
                    circles.push({ name: element.circle, code: element.circle });
                }
            });

            setDCity(cities);
            setDCountry(countries);
            setDCategory(categories);
            setDState(states);
            setDCircle(circles);
        });
    };

    const onEditClick = (item) => {
        setuserDialog(true);
        let payload = { country: item.country.toLowerCase(), serviceId: SERVICE_ID };
        service.getStateList(payload).then((data) => {
            let states = [];
            data.forEach((a) => {
                states.push({ name: a, code: a });
            });
            setStateValue(states);
        });

        setEmployeeDataUpdate({
            name: item.name,
            empId: item.empId,
            email: item.email,
            department: item.department,
            city: { name: item.city, code: item.city },
            country: { name: item.country, code: item.country },
            circle: { name: item.circle, code: item.circle },
            state: { name: item.state, code: item.state },
            category: { name: item.category, code: item.category },
            shift: { name: item.shift, code: item.shift },
        });
    };

    const onFilterClick = (item) => {
        setfilterDialog(true);
    };

    const onImportUser = () => {
        setimportUserDialog(true);
    };
    const onChangeShiftClick = (item) => {
        setChangeShiftDialog(true);
    };

    const onShiftChange = (value) => {
        setSelectedShift(value);
    };
    const showActionButtons = (row) => {
        return <Button icon="pi pi-user-edit" className="p-button-rounded mr-2 mb-2" onClick={(e) => onEditClick(row)} />;
    };

    const handleChange = (e) => {
        setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
    };

    const handleSearch = () => {
        // let filterCircle = copyemployeeData.filter((item) => item.circle === filterValue.circle.name);
        // let filterState = copyemployeeData.filter((item) => item.state === filterValue.state.name);
        // let filterCity = copyemployeeData.filter((item) => item.city === filterValue.city.name);

        // setEmployeeData(filterCategory);
        // setfilterDialog(false);
    };

    const handelStateChange = (e) => {
        const payload1 = { states: [e.value.name.toLowerCase()], serviceId: SERVICE_ID };

        service.getCities(payload1).then((data) => {
            let citys = [];
            data.forEach((a) => {
                citys.push({ name: a, code: a });
            });
            setCityValue(citys);
        });
        handleChangeUpdate(e);
    };

    const handleChangeUpdate = (e) => {
        setEmployeeDataUpdate((prvState) => {
            return {
                ...prvState,
                [e.target.name]: e.target.value,
            };
        });
    };

    const handleUpdate = () => {
        let payload3 = {
            name: employeeDataUpdate.name,
            empId: employeeDataUpdate.empId,
            email: employeeDataUpdate.email,
            department: employeeDataUpdate.department,
            city: employeeDataUpdate.city.name.toLowerCase(),
            country: employeeDataUpdate.country.name.toLowerCase(),
            circle: employeeDataUpdate.circle.name.toLowerCase(),
            state: employeeDataUpdate.state.name.toLowerCase(),
            category: employeeDataUpdate.category.name,
            shift: employeeDataUpdate.shift.name,
        };

        service.getEmployeeDataUpdate(payload3).then((res) => {
            if (res === 200) {
                toast.current.show({ severity: "success", summary: "Success Message", detail: res.message, life: 3000 });
            } else {
                toast.current.show({ severity: "error", summary: "Error Message", detail: res.message, life: 3000 });
            }
        });
    };

    const shiftOption = [
        { name: "Morning", code: "Morning" },
        { name: "Afternoon", code: "Afternoon" },
        { name: "Evening", code: "Evening" },
        { name: "Night", code: "Night" },
    ];

    const handleSearc = (query) => {
        let filteredReport =employeeData.filter((item) => {
            if ( item.email && item.email.includes(query)||item.mobile && item.mobile.includes(query)||item.name.toLowerCase() && item.name.includes(query.toLowerCase())) {
                return true;  
            }
        })
        setDuplicate(filteredReport);
    }


      const Download = () => {
        let Data=[];
        duplicate.map((item)=>{
            let temp={};
            for(const key in item){
               if(key!=="_V")temp[key.toUpperCase()]=item[key];
            }
            Data.push(temp);
        })
        let filename = "Tracking Users.xlsx";
        let ws = XLSX.utils.json_to_sheet(Data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, filename);
      }


    return (
        <div className="grid">
            <Toast ref={toast} />
            <div className="col-12">
                <div className="grid">
                    <div className="col-12">
                        <div className="w-full flex justify-content-between align-items-center">
                            <h3>Workforce Location Tracking</h3>
                            <div>
                                <Button label="Filter" icon="pi pi-filter-fill" className="mr-2 mb-2" onClick={(e) => onFilterClick()} />
                                <Button label="Add User" icon="pi pi-user-plus" style={{ cursor: "pointer" }} className="mr-2 mb-2" onClick={onImportUser}></Button>
                                {selectedUser && selectedUser.length > 0 ? <Button label="Change Shift" style={{ cursor: "pointer" }} className="mr-2 mb-2" onClick={(e) => onChangeShiftClick()}></Button> : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 ">
                        <div className="col-12 flex flex-row">
                        <InputText className="h-3rem border-solid border-primary-500  bg-black" placeholder="Search" onChange={(e) =>{ handleSearc(e.target.value)}} />
                                <div className="col-3 field-radiobutton ">
                                <Button icon="pi pi-download" className="p-button-rounded " aria-label="Submit" onClick={Download} />
                                </div>
                            </div>
                        <DataTable value={duplicate} paginator={true} rows={10} responsiveLayout="scroll" showGridlines={true} selectionMode="checkbox" selection={selectedUser} onSelectionChange={(e) => setSelectedUser(e.value)} dataKey="_id" selectionPageOnly>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Emp ID" field="empId"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Email" field="email"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Name" field="name"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Mobile Number" field="mobile"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Department" field="department"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Work Group" field="workGroup"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Circle" field="circle"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="City" field="city"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="State" field="state"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Country" field="country"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Category" field="category"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Shift" field="shift"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Reporting Manager" field="reportingManagerEmailId"></Column>
                            <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Action" body={showActionButtons}></Column>
                        </DataTable>
                    </div>
                    <Dialog header="Import User" visible={importUserDialog} style={{ width: "50vw" }} breakpoints={{ "960px": "75vw", "640px": "95vw" }} onHide={() => setimportUserDialog(false)}>
                        <div className="flex flex-column w-5 h-13rem mt-3 mx-auto border-1 border-dashed border-400 border-round-md justify-content-center align-items-center">
                            <div className="flex mb-3 flex-column align-items-center">
                                <div className="fileUpload">
                                    <div className="text-6xl">
                                        <i className="pi pi-upload text-4xl"></i>
                                    </div>
                                    <span>{fileName}</span>
                                    <span className="mt-3 mb-3 block">Upload the File</span>
                                    <input type="file" className="upload" onChange={(e) => handelFile(e)} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                </div>
                                {fileName !== "" ? <Button label="Upload" onClick={handleSubmit} loading={loading} /> : <Button label="Upload" onClick={handleSubmit} disabled />}
                                {}
                            </div>
                        </div>
                    </Dialog>
                    <Dialog header="Edit User" visible={userDialog} style={{ width: "50vw" }} breakpoints={{ "960px": "75vw", "640px": "95vw" }} onHide={() => setuserDialog(false)}>
                        <div className="grid">
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="empId" className="block">
                                    Employee Id
                                </label>
                                <InputText type="text" id="empId" name="empId" value={employeeDataUpdate.empId} aria-describedby="empId-help" onChange={handleChangeUpdate} className="block w-full" />
                            </div>
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="username" className="block">
                                    User Name
                                </label>
                                <InputText type="text" id="username" name="name" value={employeeDataUpdate.name} aria-describedby="username-help" onChange={handleChangeUpdate} className="block w-full" />
                            </div>
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="dept" className="block">
                                    Depertment
                                </label>
                                <InputText type="text" id="dept" name="department" value={employeeDataUpdate.department} aria-describedby="dept-help" onChange={handleChangeUpdate} className="block w-full" />
                            </div>
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="country" className="block">
                                    Country
                                </label>
                                <Dropdown optionLabel="name" name="country" value={employeeDataUpdate.country} options={dCountry} onChange={handleChangeUpdate} placeholder="Select a Circle" className="w-full" disabled />
                            </div>
                            {/* <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="circle" className="block">
                                    Circle
                                </label>
                                <Dropdown optionLabel="name" name="circle" value={employeeDataUpdate.circle} options={circleData} onChange={handleChangeUpdate} placeholder="Select a Circle" className="w-full" />
                            </div> */}
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="state" className="block">
                                    State
                                </label>
                                <Dropdown optionLabel="name" name="state" value={employeeDataUpdate.state} options={stateValue} onChange={handelStateChange} placeholder="Select a State" className="w-full" />
                            </div>
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="city" className="block">
                                    City
                                </label>
                                <Dropdown optionLabel="name" name="city" value={employeeDataUpdate.city} options={cityValue} onChange={handleChangeUpdate} placeholder="Select a City" className="w-full" />
                            </div>
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="category" className="block">
                                    Category
                                </label>
                                <Dropdown optionLabel="name" name="category" value={employeeDataUpdate.category} options={dCategory} onChange={handleChangeUpdate} placeholder="Select a Catogery" className="w-full" />
                            </div>
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="shift" className="block">
                                    Shift
                                </label>
                                <Dropdown optionLabel="name" name="shift" value={employeeDataUpdate.shift} options={shiftOption} onChange={handleChangeUpdate} placeholder="Select shift" className="w-full" />
                            </div>
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="email" className="block">
                                    Email
                                </label>
                                <InputText type="text" id="email" name="email" value={employeeDataUpdate.email} aria-describedby="email-help" onChange={handleChangeUpdate} className="block w-full" disabled />
                            </div>
                            <div className="col-12">
                                <div className="flex w-full justify-content-end">
                                    <Button label="Cancel" className="p-button-danger mr-3" onClick={() => setuserDialog(false)} />
                                    <Button label="Update" onClick={handleUpdate} />
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    {/* filter dialog */}
                    <Dialog header="Filter User" visible={filterDialog} style={{ width: "50vw" }} breakpoints={{ "960px": "75vw", "640px": "95vw" }} onHide={() => setfilterDialog(false)}>
                        <div className="grid">
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="country" className="block">
                                    Country
                                </label>
                                <Dropdown optionLabel="name" value={filterValue.country} name="country" options={dCountry} onChange={handleChange} placeholder="Select a Circle" className="w-full" />
                            </div>
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="circle" className="block">
                                    Circle
                                </label>
                                <Dropdown optionLabel="name" value={filterValue.circle} name="circle" options={dCircle} onChange={handleChange} placeholder="Select a Circle" className="w-full" />
                            </div>
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="state" className="block">
                                    State
                                </label>
                                <Dropdown optionLabel="name" value={filterValue.state} name="state" options={dState} onChange={handleChange} placeholder="Select a State" className="w-full" />
                            </div>
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="city" className="block">
                                    City
                                </label>
                                <Dropdown optionLabel="name" value={filterValue.city} name="city" options={dCity} onChange={handleChange} placeholder="Select a City" className="w-full" />
                            </div>
                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="category" className="block">
                                    Category
                                </label>
                                <Dropdown optionLabel="name" value={filterValue.category} name="category" options={dCategory} onChange={handleChange} placeholder="Select a City" className="w-full" />
                            </div>

                            <div className="col-12">
                                <div className="flex w-full justify-content-end">
                                    <Button label="Cancel" className="p-button-danger mr-3" onClick={() => setfilterDialog(false)} />
                                    <Button label="Search" onClick={handleSearch} />
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog header="Change Shift" visible={changeShiftDialog} style={{ width: "50vw" }} breakpoints={{ "960px": "75vw", "640px": "95vw" }} onHide={() => setChangeShiftDialog(false)}>
                        <div className="grid">
                            <div className="md:col-6 col-12">
                                <div className={selectedShift === "A" ? "cardBox activeCard" : "cardBox"} onClick={(e) => onShiftChange("A")}>
                                    <Card>
                                        <h3>
                                            <span>A</span> - Shift
                                        </h3>
                                        <p>
                                            <span>Morning</span> - 6.00AM - 12.00PM
                                        </p>
                                    </Card>
                                </div>
                            </div>
                            <div className="md:col-6 col-12">
                                <div className={selectedShift === "B" ? "cardBox activeCard" : "cardBox"} onClick={(e) => onShiftChange("B")}>
                                    <Card>
                                        <h3>
                                            <span>B</span> - Shift
                                        </h3>
                                        <p>
                                            <span>Morning</span> - 6.00AM - 12.00PM
                                        </p>
                                    </Card>
                                </div>
                            </div>
                            <div className="md:col-6 col-12">
                                <div className={selectedShift === "C" ? "cardBox activeCard" : "cardBox"} onClick={(e) => onShiftChange("C")}>
                                    <Card>
                                        <h3>
                                            <span>C</span> - Shift
                                        </h3>
                                        <p>
                                            <span>Morning</span> - 6.00AM - 12.00PM
                                        </p>
                                    </Card>
                                </div>
                            </div>
                            <div className="md:col-6 col-12">
                                <div className={selectedShift === "D" ? "cardBox activeCard" : "cardBox"} onClick={(e) => onShiftChange("D")}>
                                    <Card>
                                        <h3>
                                            <span>D</span> - Shift
                                        </h3>
                                        <p>
                                            <span>Morning</span> - 6.00AM - 12.00PM
                                        </p>
                                    </Card>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="flex w-full justify-content-end">
                                    <Button label="Cancel" className="p-button-danger mr-3" onClick={() => setfilterDialog(false)} />
                                    <Button label="Update" />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default TrakingUsers;
