import React, { useState, useRef, useEffect } from "react";
import { Steps } from "primereact/steps";

import AddCustomer from "./AddCustomer";
import AddCustomerAdmin from "./AddCustomerAdmin";
import AddCustomerLogo from "./AddCustomerLogo";
import configService from "../service/configService";
import { Toast } from "primereact/toast";

function Admin() {
    const toast = useRef(null);
    const [customerId, setCustomerId] = useState();
    const [serviceId, setServiceId] = useState();
    const [serviceTypeNames, setserviceTypeNames] = useState([]);
    // const assestService = new AssetService();
    const [activeIndex, setActiveIndex] = useState(0);

    const items = [
        {
            label: "Add a customer",
        },
        {
            label: "Add a customer admin",
        },
        {
            label: "Add customer logo",
        },
    ];

    useEffect(() => {
        configService
            .getServiceTypeNames()
            .then((res) => {
                let typeNames = res.map((item) => {
                    return { name: item.serviceTypeName, code: item.serviceTypeName, id: item.id };
                });
                setserviceTypeNames(typeNames);
            })
            .catch((e) => {});
    }, []);

    const handelAddCustomer = (formData) => {
        configService.createServiceId(formData).then((response) => {
            if (response.responseCode === 200) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Customer added successfully", life: 3000 });
                setCustomerId(response.responseData.addCustomer.customerId);
                setServiceId(response.responseData.addCustomer.serviceId);
                setActiveIndex(activeIndex + 1);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: response.message, life: 3000 });
            }
        });
    };

    const handelAddCustomerAdmin = (formData) => {
        configService.registerUser(formData).then((response) => {
            if (response.responseCode === 200) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Customer admin added successfully", life: 3000 });
                setActiveIndex(activeIndex + 1);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: response.message, life: 3000 });
            }
        });
    };

    const handelAddCustomerLogo = (formData) => {
        configService.updateCustomerImage(formData).then((response) => {
            if (response.responseCode === 200) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Image added successfully", life: 3000 });
                setActiveIndex(0);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: response.message, life: 3000 });
            }
        });
    };
    const handleBackCustomer = () => {
        setActiveIndex(activeIndex - 1);
    };

    const nextPage = () => {
        setActiveIndex(activeIndex + 1);
    };

    return (
        <div>
            <div className="w-8 mx-auto">
                <Toast ref={toast} />
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
            </div>
            {activeIndex === 0 ? (
                <AddCustomer handelAddCustomer={handelAddCustomer} serviceTypeNames={serviceTypeNames} nextPage={nextPage} />
            ) : activeIndex === 1 ? (
                <AddCustomerAdmin handelAddCustomerAdmin={handelAddCustomerAdmin} handleBackCustomer={handleBackCustomer} nextPage={nextPage} serviceId={serviceId} />
            ) : activeIndex === 2 ? (
                <AddCustomerLogo handelAddCustomerLogo={handelAddCustomerLogo} handleBackCustomer={handleBackCustomer} customerId={customerId} />
            ) : null}
        </div>
    );
}

export default Admin;
