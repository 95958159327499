import React, { Component } from "react";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { AssetService } from "../../service/AssetService";

class SiteDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thingName: null,
            dateRange: null,
            startDay: null,
            endDay: null,
            DGChart: null,
            combo_one: null,
            combo_two: null,
            combo_three: null,
            dg_battery_chart: null,
            dg_RYB_PhaseKw: null,
            alternator_volt_chart: null,
            state: null,
            city: null,
            make: null,
            model: null,
            zipcode: null,
        };
        this.serviceID = localStorage.getItem("serviceId");
        this.assetService = new AssetService();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.thingName !== prevProps.match.params.thingName) {
            this.fetchdata();
        }
    }

    componentDidMount = () => {
        this.fetchdata();
    };

    fetchdata() {
        const { thingName } = this.props.match.params;
        const endDay = moment().subtract(1, "days").endOf("day").format("YYYY-MM-DD HH:mm:ss");
        const startDay = moment().subtract(30, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.setState({ thingName, startDay, endDay, dateRange: [new Date(startDay), new Date(endDay)] }, () => {
            this.initReport(this.state.thingName, this.state.startDay, this.state.endDay);
        });
    }

    initReport = (thing, startDay, endDay) => {
        const payload = {
            filter: {
                thingName: thing,
                ts: { $gte: startDay, $lte: endDay },
            },
        };
        this.assetService.getParmTrendsData(this.serviceID, payload).then((res) => {
            res = res.sort((a, b) => {
                const date1 = new Date(a.ts);
                const date2 = new Date(b.ts);
                return date1 - date2;
            });

            let dgFreq = [];
            let LubeOilPressure = [],
                EngineWaterTemp = [],
                DGTotalKWH = [];
            let R_PhaLoadCurrent = [],
                Y_PhaLoadCurrentVariation = [],
                B_PhaLoadCurrentVariation = [];
            let EB_R_PhaseVolt = [],
                EB_Y_PhaseVolt = [],
                EB_B_PhaseVolt = [];
            let DGBattery = [];
            let DG_R_PhaseKW = [],
                DG_Y_PhaseKW = [],
                DG_B_PhaseKW = [],
                chart_time = [];
            let AlternatorVolt = [],
                dataLable = [];
            res.forEach((item) => {
                dataLable.push(moment(item.ts).format("MMM Do, HH:mm"));
                dgFreq.push(item.DGFrequency);
                LubeOilPressure.push(item.LubeOilPressure);
                EngineWaterTemp.push(item.EngineWaterTemp);
                DGTotalKWH.push(item.DGTotalKWH);
                R_PhaLoadCurrent.push(item["R-PhaLoadCurrent"] ? item["R-PhaLoadCurrent"] : null);
                Y_PhaLoadCurrentVariation.push(item["Y-PhaLoadCurrentVariation"] ? item["Y-PhaLoadCurrentVariation"] : null);
                B_PhaLoadCurrentVariation.push(item["B-PhaLoadCurrentVariation"] ? item["B-PhaLoadCurrentVariation"] : null);
                EB_R_PhaseVolt.push(item["EB-R-PhaseVolt"]);
                EB_Y_PhaseVolt.push(item["EB-Y-PhaseVolt"]);
                EB_B_PhaseVolt.push(item["EB-B-PhaseVolt"]);
                DGBattery.push(item.DGBattery);
                chart_time.push(moment(item.ts).format("Do MMM YY, HH:mma"));
                DG_R_PhaseKW.push(item["DG-R-PhaseKW"]);
                DG_Y_PhaseKW.push(item["DG-Y-PhaseKW"]);
                DG_B_PhaseKW.push(item["DG-B-PhaseKW"]);
                AlternatorVolt.push(item["AlternatorVolt"]);
            });

            let DGChart = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                },
                title: {
                    text: "DG Frequency Pattern",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: dataLable,
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        borderRadius: 5,
                        pointWidth: 9,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "DG Frequency",
                        data: dgFreq,
                        color: "#EFC000",
                    },
                ],
            };
            let combo_one = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                },
                title: {
                    text: "Variation of LubeOil presser, Engine water temperature & Total KWH over time",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: dataLable,
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        borderRadius: 5,
                        pointWidth: 9,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "Lube Oil Pressure",
                        data: LubeOilPressure,
                        color: "#0070EF",
                    },
                    {
                        name: "Engine Water Temp",
                        data: EngineWaterTemp,
                        color: "#00AB31",
                    },
                    {
                        name: "DG Total KWH",
                        data: DGTotalKWH,
                        color: "#AB000B",
                    },
                ],
            };
            let combo_two = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                },
                title: {
                    text: "Y phase and B phase current variation",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: dataLable,
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        borderRadius: 5,
                        pointWidth: 9,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "R-PhaLoadCurrent",
                        data: R_PhaLoadCurrent,
                        color: "#0070EF",
                    },
                    {
                        name: "Y-PhaLoadCurrentVariation",
                        data: Y_PhaLoadCurrentVariation,
                        color: "#00AB31",
                    },
                    {
                        name: "B-PhaLoadCurrentVariation",
                        data: B_PhaLoadCurrentVariation,
                        color: "#AB000B",
                    },
                ],
            };
            let combo_three = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                },
                title: {
                    text: "EB R, Y and B phase voltages",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: dataLable,
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        borderRadius: 5,
                        pointWidth: 9,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "EB-R-PhaseVolt",
                        data: EB_R_PhaseVolt,
                        color: "#0070EF",
                    },
                    {
                        name: "EB-Y-PhaseVolt",
                        data: EB_Y_PhaseVolt,
                        color: "#00AB31",
                    },
                    {
                        name: "EB-B-PhaseVolt",
                        data: EB_B_PhaseVolt,
                        color: "#AB000B",
                    },
                ],
            };
            let dg_battery_chart = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                },
                title: {
                    text: "DG Battery",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: dataLable,
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        borderRadius: 5,
                        pointWidth: 9,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "DGBattery",
                        data: DGBattery,
                        color: "#00AB31",
                    },
                ],
            };
            let dg_RYB_PhaseKw = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                    type: "column",
                },
                title: {
                    text: "DG R, Y, B phase KW",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: chart_time,
                    crosshair: true,
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "DG-R-PhaseKW",
                        data: DG_R_PhaseKW,
                        color: "#0070EF",
                    },
                    {
                        name: "DG-Y-PhaseKW",
                        data: DG_Y_PhaseKW,
                        color: "#00AB31",
                    },
                    {
                        name: "DG-B-PhaseKW",
                        data: DG_B_PhaseKW,
                        color: "#AB000B",
                    },
                ],
            };
            let alternator_volt_chart = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                },
                title: {
                    text: "Alternator Volt",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: dataLable,
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "Alternator Volt",
                        data: AlternatorVolt,
                        color: "#0070EF",
                    },
                ],
            };
            this.setState({
                DGChart,
                combo_one,
                combo_two,
                combo_three,
                dg_battery_chart,
                dg_RYB_PhaseKw,
                alternator_volt_chart,
                state: res[0]?.state,
                city: res[0]?.city,
                make: res[0]?.make,
                model: res[0]?.model,
                zipcode: res[0]?.zipcode,
            });
        });
    };

    onDateChange = (value) => {
        this.setState({ dateRange: value }, () => {
            if (this.state.dateRange[1] !== null) {
                let startDay = moment(this.state.dateRange[0]).format("YYYY-MM-DD HH:mm:ss");
                let endDay = moment(this.state.dateRange[1]).format("YYYY-MM-DD HH:mm:ss");
                this.setState({ startDay, endDay }, () => {
                    this.initReport(this.state.thingName, this.state.startDay, this.state.endDay);
                });
            }
        });
    };

    render = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <div className="grid px-3">
                        <div className="col-12 lg:col-9">
                            {this.state.state ? (
                                <>
                                    <div className="flex flex-wrap my-3 align-items-end">
                                        <p className="mb-2 mr-2 font-medium uppercase text-xl">{this.state.thingName}</p>
                                        <p className="mb-2">
                                            (Make: <span className="uppercase text-primary font-medium">{this.state.make}</span>, Model: <span className="uppercase text-primary font-medium">{this.state.model}</span>)
                                        </p>
                                    </div>
                                    <p className="mb-2">
                                        City: <span className="uppercase text-primary font-medium">{this.state.city}</span>, State: <span className="uppercase text-primary font-medium">{this.state.state}</span>, Zip: <span className="uppercase text-primary font-medium">{this.state.zipcode}</span>
                                    </p>
                                </>
                            ) : null}
                        </div>
                        <div className="field col-12 md:col-6 lg:col-3">
                            <label htmlFor="date">Select Date Range</label>
                            <Calendar id="range" value={this.state.dateRange} onChange={(e) => this.onDateChange(e.value)} selectionMode="range" showTime readOnlyInput showIcon className="w-full bg-white" />
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    {this.state.DGChart ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.DGChart} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-6">
                    {this.state.combo_one ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.combo_one} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12">
                    {this.state.combo_two ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.combo_two} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12">
                    {this.state.combo_three ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.combo_three} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-6">
                    {this.state.dg_battery_chart ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.dg_battery_chart} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-6">
                    {this.state.alternator_volt_chart ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.alternator_volt_chart} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12">
                    {this.state.dg_RYB_PhaseKw ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.dg_RYB_PhaseKw} />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };
}

export default SiteDetails;
