import React, { useEffect } from "react";
import { mapKey } from "../../shared/data/const";

const TowersComponent = () => {
    useEffect(() => {
        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(document.getElementById("mapView"), defaultLayers.vector.normal.map, {
            center: { lat: 18.5746539, lng: 73.8170748 },
            zoom: 10,
            pixelRatio: window.devicePixelRatio || 1,
        });

        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        H.ui.UI.createDefault(map, defaultLayers);
    }, []);

    return (
        <div className="grid">
            <div className="col-4">
                <div className="card">
                    <span className="block text-500 font-medium mb-1">California</span>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <div className="text-900 font-medium text-xl mb-3">3560</div>
                            <span className="block text-500 font-medium">GBT</span>
                        </div>
                        <div>
                            <div className="text-900 font-medium text-xl mb-3">1060</div>
                            <span className="block text-500 font-medium">RTT</span>
                        </div>
                        <div>
                            <div className="text-900 font-medium text-xl mb-3">280</div>
                            <span className="block text-500 font-medium">RTP</span>
                        </div>
                        <div>
                            <div className="text-900 font-medium text-xl mb-3">100</div>
                            <span className="block text-500 font-medium">GBM</span>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <span className="block text-500 font-medium mb-1">Alaska</span>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <div className="text-900 font-medium text-xl mb-3">3560</div>
                            <span className="block text-500 font-medium">GBT</span>
                        </div>
                        <div>
                            <div className="text-900 font-medium text-xl mb-3">1060</div>
                            <span className="block text-500 font-medium">RTT</span>
                        </div>
                        <div>
                            <div className="text-900 font-medium text-xl mb-3">280</div>
                            <span className="block text-500 font-medium">RTP</span>
                        </div>
                        <div>
                            <div className="text-900 font-medium text-xl mb-3">100</div>
                            <span className="block text-500 font-medium">GBM</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-8">
                <div className="card">
                    <div
                        id="mapView"
                        style={{
                            width: "100%",
                            height: "545px",
                            overflow: "hidden",
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default TowersComponent;
