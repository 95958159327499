import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { Badge } from "primereact/badge";
import { Image } from "primereact/image";
import { AssetService } from "../../service/AssetService";
import moment from "moment/moment";

class AssetLiveState extends Component {
    constructor(props) {
        super(props);
        this.state = {
            states: null,
            cities: null,
            dCities: null,
            zipcodes: null,
            dZipcodes: null,
            makes: null,
            models: null,
            sizes: null,
            seties: null,
            dSeties: null,
            selectedState: null,
            selectedCity: null,
            selectedZip: null,
            selectedSite: null,
            selectedMake: null,
            selectedModel: null,
            selectedSize: null,
            liveData: null,
            assetCurrentStatus: null,
            assetInfo: null,
        };
        this.serviceID = localStorage.getItem("serviceId");
        this.assetService = new AssetService();
    }

    componentDidMount = () => {
        this.assetService.getThings(this.serviceID).then((response) => {
            if (response.success && response.responseCode === 200) {
                this.assetService.getAllSites(this.serviceID, response.responseData).then((res) => {
                    let states = [],
                        cities = [],
                        zipcodes = [],
                        makes = [],
                        models = [],
                        sizes = [],
                        seties = [];
                    res.forEach((item) => {
                        if (!states.some((elm) => elm.name === item.location.state)) {
                            states.push({ name: item.location.state, code: item.location.state });
                        }
                        if (!cities.some((elm) => elm.name === item.location.city)) {
                            cities.push({ name: item.location.city, code: item.location.city, state: item.location.state });
                        }
                        if (!zipcodes.some((elm) => elm.name === item.location.zip)) {
                            zipcodes.push({ name: item.location.zip, code: item.location.zip, city: item.location.city, state: item.location.state });
                        }
                        if (!makes.some((elm) => elm.name === item.make)) {
                            makes.push({ name: item.make, code: item.make });
                        }
                        if (!models.some((elm) => elm.name === item.model)) {
                            models.push({ name: item.model, code: item.model });
                        }
                        if (!sizes.some((elm) => elm.name === item.size)) {
                            sizes.push({ name: item.size, code: item.size });
                        }
                        if (!seties.some((elm) => elm.name === item.thingName)) {
                            seties.push({
                                name: item.thingName,
                                code: item.thingName,
                                state: item.location.state,
                                city: item.location.city,
                                zipcode: item.location.zip,
                                make: item.make,
                                model: item.model,
                                size: item.size,
                            });
                        }
                    });
                    let index = seties.findIndex((a) => a.name === "dg9cjldhngrmb005");
                    this.setState({ states, cities, dCities: cities, zipcodes, dZipcodes: zipcodes, makes, models, sizes, seties, dSeties: seties, selectedSite: seties[index] }, () => {
                        this.initReport(this.state.selectedSite.name);
                    });
                });
            }
        });
    };

    onStateChange = (value) => {
        let cities = this.state.dCities.filter((item) => item.state === value.name);
        let zips = this.state.dZipcodes.filter((item) => item.state === value.name);
        let sites = this.state.dSeties.filter((item) => item.state === value.name);
        this.setState({ selectedState: value, cities: cities, zipcodes: zips, seties: sites, selectedSite: sites[0] }, () => {
            this.initReport(this.state.selectedSite.name);
        });
    };
    onCityChange = (value) => {
        let zips = this.state.dZipcodes.filter((item) => item.city === value.name);
        let sites = this.state.dSeties.filter((item) => item.city === value.name);
        this.setState({ selectedCity: value, zipcodes: zips, seties: sites, selectedSite: sites[0] }, () => {
            this.initReport(this.state.selectedSite.name);
        });
    };
    onZipChange = (value) => {
        let sites = this.state.dSeties.filter((item) => item.zipcode === value.name);
        this.setState({ selectedZip: value, seties: sites, selectedSite: sites[0] }, () => {
            this.initReport(this.state.selectedSite.name);
        });
    };
    onSiteChange = (value) => {
        this.setState({ selectedSite: value }, () => {
            this.initReport(this.state.selectedSite.name);
        });
    };
    onMakeChange = (value) => {
        let sites = this.state.dSeties.filter((item) => item.make === value.name);
        this.setState({ selectedMake: value, seties: sites, selectedSite: sites[0] }, () => {
            this.initReport(this.state.selectedSite.name);
        });
    };
    onModelChange = (value) => {
        let sites = this.state.dSeties.filter((item) => item.model === value.name);
        this.setState({ selectedModel: value, seties: sites, selectedSite: sites[0] }, () => {
            this.initReport(this.state.selectedSite.name);
        });
    };
    onSizeChange = (value) => {
        let sites = this.state.dSeties.filter((item) => item.size === value.name);
        this.setState({ selectedSize: value, seties: sites, selectedSite: sites[0] }, () => {
            this.initReport(this.state.selectedSite.name);
        });
    };

    initReport = (thingName) => {
        const payload = {
            filter: {
                thingName: thingName,
            },
        };
        const serviceDetails = localStorage.getItem("service");
        const serviceClass = JSON.parse(serviceDetails).serviceBlock;
        Promise.all([this.assetService.getLatestData(this.serviceID, thingName), this.assetService.getServiceObserSen(this.serviceID, serviceClass), this.assetService.getAssets(this.serviceID, payload)]).then((res) => {
            let tempData = [];
            res[1].forEach((item) => {
                let n = item.ids.map((elm) => {
                    let t = elm.id.split(":");
                    let dg = t[1];
                    return {
                        thingName: dg,
                        status: item._id.fsmstate,
                    };
                });
                tempData = [...tempData, ...n];
            });
            let detaStatus = tempData.filter((item) => item.thingName === thingName);

            this.setState({
                liveData: res[0][0],
                assetCurrentStatus: { ...detaStatus[0] },
                assetInfo: res[2],
            });
        });
    };

    template(options) {
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <span className={titleClassName}>
                    <i className="pi-pw pi pi-filter"></i> Filter
                </span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        );
    }

    render = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <Panel headerTemplate={this.template} toggleable collapsed="true">
                        <div className="grid">
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="State">State</label>
                                <Dropdown id="State" value={this.state.selectedState} options={this.state.states} onChange={(e) => this.onStateChange(e.value)} optionLabel="name" placeholder="Select a State" className="w-full" />
                            </div>
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="City">City</label>
                                <Dropdown id="City" value={this.state.selectedCity} options={this.state.cities} onChange={(e) => this.onCityChange(e.value)} optionLabel="name" placeholder="Select a City" className="w-full" />
                            </div>
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="zipCode">Zip Code</label>
                                <Dropdown id="zipCode" value={this.state.selectedZip} options={this.state.zipcodes} onChange={(e) => this.onZipChange(e.value)} optionLabel="name" placeholder="Select a Zip Code" className="w-full" />
                            </div>
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="make">Make</label>
                                <Dropdown id="make" value={this.state.selectedMake} options={this.state.makes} onChange={(e) => this.onMakeChange(e.value)} optionLabel="name" placeholder="Select a Make" className="w-full" />
                            </div>
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="model">Model</label>
                                <Dropdown id="model" value={this.state.selectedModel} options={this.state.models} onChange={(e) => this.onModelChange(e.value)} optionLabel="name" placeholder="Select a Model" className="w-full" />
                            </div>
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="size">Size</label>
                                <Dropdown id="size" value={this.state.selectedSize} options={this.state.sizes} onChange={(e) => this.onSizeChange(e.value)} optionLabel="name" placeholder="Select a Size" className="w-full" />
                            </div>
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="Site">Site</label>
                                <Dropdown id="Site" value={this.state.selectedSite} options={this.state.seties} onChange={(e) => this.onSiteChange(e.value)} optionLabel="name" placeholder="Select a Site" className="w-full" />
                            </div>
                        </div>
                    </Panel>
                </div>
                {this.state.liveData ? (
                    <div className="col-12">
                        <div className="grid">
                            <div className="col-12 lg:col-3">
                                <div className="card mb-3">
                                    <Image src={`https://s3.ap-south-1.amazonaws.com/nq.aws.in.mum.shared1.nextqore-images/` + this.state.assetInfo[0].image} alt="Image" width="100%" preview />
                                </div>
                                <div className="card">
                                    <h3>Asset Info</h3>
                                    <hr />
                                    <div className="flex w-full justify-content-between">
                                        <span className="text-primary font-bold">Thing Name</span>
                                        <span className="text-right">{this.state.assetCurrentStatus.thingName}</span>
                                    </div>
                                    <hr />
                                    <div className="flex w-full justify-content-between">
                                        <span className="text-primary font-bold">Site ID</span>
                                        <span className="text-right">{this.state.assetInfo[0].location.siteId}</span>
                                    </div>
                                    <hr />
                                    <div className="flex w-full justify-content-between">
                                        <span className="text-primary font-bold">Site Name</span>
                                        <span className="text-right">{this.state.assetInfo[0].location.siteName}</span>
                                    </div>
                                    <hr />
                                    <div className="flex w-full justify-content-between">
                                        <span className="text-primary font-bold">Connectivity Status</span>
                                        <span className="text-right">{this.state.assetCurrentStatus.status}</span>
                                    </div>
                                    <hr />
                                    <div className="flex w-full justify-content-between">
                                        <span className="text-primary font-bold">DG Status</span>
                                        <span className="text-right">{this.state.liveData["DGStatus"] === 1 ? <Badge value="ON" className="mr-2" severity="success"></Badge> : <Badge value="OFF" className="mr-2" severity="warning"></Badge>}</span>
                                    </div>
                                    <hr />
                                    <div className="flex w-full justify-content-between">
                                        <span className="text-primary font-bold">Make</span>
                                        <span className="text-right">{this.state.assetInfo[0].make}</span>
                                    </div>
                                    <hr />
                                    <div className="flex w-full justify-content-between">
                                        <span className="text-primary font-bold">Model</span>
                                        <span className="text-right">{this.state.assetInfo[0].model}</span>
                                    </div>
                                    <hr />
                                    <div className="flex w-full justify-content-between">
                                        <span className="text-primary font-bold">Size</span>
                                        <span className="text-right">{this.state.assetInfo[0].size}</span>
                                    </div>
                                    <hr />
                                    <div className="flex w-full justify-content-between">
                                        <span className="text-primary font-bold">Address</span>
                                        <span className="text-right">{this.state.assetInfo[0].location.address1 + "," + this.state.assetInfo[0].location.address2}</span>
                                    </div>
                                    <hr />
                                    <div className="flex w-full justify-content-between">
                                        <span className="text-primary font-bold">State</span>
                                        <span className="text-right">{this.state.assetInfo[0].location.state}</span>
                                    </div>
                                    <hr />
                                    <div className="flex w-full justify-content-between">
                                        <span className="text-primary font-bold">City</span>
                                        <span className="text-right">{this.state.assetInfo[0].location.city}</span>
                                    </div>
                                    <hr />
                                    <div className="flex w-full justify-content-between">
                                        <span className="text-primary font-bold">Zipcode</span>
                                        <span className="text-right">{this.state.assetInfo[0].location.zip}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 lg:col-9">
                                {this.state.liveData ? (
                                    <div className="card">
                                        <h3>Asset Parameters ( As on {moment(this.state.liveData.ts).format("Do MMM, YYYY HH:mm A")} )</h3>
                                        <hr />
                                        <div className="grid">
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">Alternator Volt</span>
                                                    <span className="text-right">{this.state.liveData.AlternatorVolt + " V"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">R Phase Load Current</span>
                                                    <span className="text-right">{this.state.liveData["R-PhaLoadCurrent"] + " amp"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">Y Phase Load Current</span>
                                                    <span className="text-right">{this.state.liveData["Y-PhaLoadCurrent"] + " amp"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">Y Pha. Load Curr. Variation</span>
                                                    <span className="text-right">{this.state.liveData["Y-PhaLoadCurrentVariation"] + " amp"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">B Phase Load Current</span>
                                                    <span className="text-right">{this.state.liveData["B-PhaLoadCurrent"] + " amp"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">B Phase Load Curr. Variation</span>
                                                    <span className="text-right">{this.state.liveData["B-PhaLoadCurrentVariation"] + " amp"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">DG R Phase AC Volt</span>
                                                    <span className="text-right">{this.state.liveData["DG-R-PhaseACVolt"] + " V"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">DG R Phase KW</span>
                                                    <span className="text-right">{this.state.liveData["DG-R-PhaseKW"] + " KW"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">DG Y Phase AC Volt</span>
                                                    <span className="text-right">{this.state.liveData["DG-Y-PhaseACVolt"] + " V"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">DG Y Phase KW</span>
                                                    <span className="text-right">{this.state.liveData["DG-Y-PhaseKW"] + " KW"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">DG B Phase AC Volt</span>
                                                    <span className="text-right">{this.state.liveData["DG-B-PhaseACVolt"] + " V"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">DG B Phase KW</span>
                                                    <span className="text-right">{this.state.liveData["DG-B-PhaseKW"] + " KW"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">DG Battery</span>
                                                    <span className="text-right">{this.state.liveData["DGBattery"] + " V"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">DG Frequency</span>
                                                    <span className="text-right">{this.state.liveData["DGFrequency"] + " Hz"}</span>
                                                </div>
                                            </div>

                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">DG Total KWH</span>
                                                    <span className="text-right">{this.state.liveData["DGTotalKWH"] + " KWH"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">EB R Phase KW</span>
                                                    <span className="text-right">{this.state.liveData["EB-R-PhaseKW"] + " KW"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">EB R Phase Volt</span>
                                                    <span className="text-right">{this.state.liveData["EB-R-PhaseVolt"] + " V"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">EB Y Phase KW</span>
                                                    <span className="text-right">{this.state.liveData["EB-Y-PhaseKW"] + " KW"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">EB Y Phase Volt</span>
                                                    <span className="text-right">{this.state.liveData["EB-Y-PhaseVolt"] + " V"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">EB B Phase KW</span>
                                                    <span className="text-right">{this.state.liveData["EB-B-PhaseKW"] + " KW"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">EB B Phase Volt</span>
                                                    <span className="text-right">{this.state.liveData["EB-B-PhaseVolt"] + " V"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">EB Total KWH</span>
                                                    <span className="text-right">{this.state.liveData["EBTotalKWH"] + " KWH"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">Engine Water Temp</span>
                                                    <span className="text-right">{this.state.liveData["EngineWaterTemp"] + " °C"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">Fuel Level</span>
                                                    <span className="text-right">{this.state.liveData["FuelLevel"] + " L"}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:col-4">
                                                <div className="flex w-full border-round-md border-1 border-solid border-400 justify-content-between p-3">
                                                    <span className="text-primary font-bold">Lube Oil Pressure</span>
                                                    <span className="text-right">{this.state.liveData["LubeOilPressure"] + " Pa"}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ textAlign: "center", margin: "auto", marginTop: "100px" }}>
                        <h2>Currently OFFLINE</h2>
                    </div>
                )}
            </div>
        );
    };
}

export default AssetLiveState;
