import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { chartHeadingStyle } from "../../utilities/constant";

const FleetMilage = ({ chartData, unit }) => {
    const [state, setState] = useState(null);

    useEffect(() => {
        const option = {
            chart: {
                type: "bar",
            },
            title: {
                text: "",
                align: "left",
                style: chartHeadingStyle,
            },
            xAxis: {
                categories: [],
                title: {
                    text: null,
                },
            },
            yAxis: {
                title: {
                    text: `Distance(${unit === "FPS" ? "mile" : "Km"})`,
                },
            },
            tooltip: {
                valueSuffix: `${unit === "FPS" ? "mile" : "Km"}`,
            },

            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [],
        };
        option.series = chartData.data;
        option.xAxis.categories = chartData.category;
        option.title.text = chartData.title;
        setState(option);
    }, []);

    return state ? <HighchartsReact highcharts={Highcharts} options={state} /> : null;
};

export default FleetMilage;
