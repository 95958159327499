import React, {useState, useEffect} from 'react'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import {InputTextarea} from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown';
import visaService from "./visaService"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import dbService from "../../service/dbService"


const VisaApi = () => {
    const [formData , setFormData] = useState({})
    const [errMsg, setErrMsg] = useState(null);
	const [disableBtn, setBtnDisabled] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({})
    const [details, setDetails]  = useState(null)
    const [countryMap, setCountryMap] = useState(null)

    useEffect(()=>{
            dbService.getCountryISOCodes().then((res)=>{
                setCountryMap(res)
            })        
    },[])

    const handleChange = (e) =>{
        let name = e.target.name
        let value;
        if(name === "supplierCountryCode"){
            setSelectedCountry(e.value)
            value = e.value.PhoneCallingCode
        }
        else value = e.target.value
        setFormData((prev)=>({...prev, [name]: value }))
    }


    const handleSubmit = async (e) =>{
        e.preventDefault();
        setBtnDisabled(true)
        setErrMsg("")

        let validationRes = validateInputs()
        if (typeof (validationRes) === "string") {
            setErrMsg(validationRes);
            setBtnDisabled(false)
        } else {
            let resData = await visaService.visaSupplierMatching(formData).catch((err)=>{
                console.error("err",err)
            })
            if(resData && resData.status.statusCode === 200){
                setDetails(resData)
            }
            else{
                setDetails("No Data Found")
            }
            setBtnDisabled(false)

        }
    }
    const validateInputs = () =>{
        if(!formData.supplierName) return "Please enter supplier name"
        if(!formData.supplierCountryCode) return "Please enter country code"
    }


  return (
    <>  
          {!details && <div className='col-12 flex justify-content-center align-items-center'>
              <div className="col-12 md:col-6 lg:col-5 card p-fluid px-3 pt-4">
                 <div className='grid justify-content-center' ><h2>Supplier Details</h2></div>
                 <form onSubmit={(e)=>handleSubmit(e)}>
                  <div className="field">
                      <label htmlFor="name1">Supplier Name</label><span style={{ color: "red" }}> *</span>
                      <InputText value={formData.supplierName || ""} name="supplierName" onChange={(e)=>handleChange(e)} id="name1" type="text" />
                  </div>
                  <div className="formgrid grid">
                      <div className="field col">
                          <label htmlFor="spc">Supplier Country Code</label><span style={{ color: "red" }}> *</span>
                          <Dropdown name="supplierCountryCode" onChange={(e)=>handleChange(e)} id="spc"  value={selectedCountry || {}} options={countryMap ? countryMap : []} optionLabel='CountryName' ></Dropdown>
                      </div>
                      <div className="field col">
                          <label htmlFor="supphone">Supplier Phone Number</label>
                          <InputText value={formData.supplierPhoneNumber} name="supplierPhoneNumber" onChange={(e)=>handleChange(e)} id="supphone" type="text" />
                      </div>
                  </div>
                  <div className="field">
                      <label htmlFor="sppTaxId">Supplier Tax ID</label>
                      <InputText value={formData.supplierTaxId || ""} name="supplierTaxId" onChange={(e)=>handleChange(e)} id="sppTaxId" type="text" />
                  </div>
                  <div className="formgrid grid">
                      <div className="field col">
                          <label htmlFor="suppState">Supplier State</label>
                          <InputText value={formData.supplierState || ""} name="supplierState" onChange={(e)=>handleChange(e)} id="suppState" type="text" />
                      </div>
                      <div className="field col">
                          <label htmlFor="suppCity">Supplier City</label>
                          <InputText value={formData.supplierCity} name="supplierCity" onChange={(e)=>handleChange(e)} id="suppCity" type="text" />
                      </div>
                  </div>
                  <div className="field">
                      <label htmlFor="suppPostalCode">Supplier Postal Code</label>
                      <InputText value={formData.supplierPostalCode || ""} name="supplierPostalCode" onChange={(e)=>handleChange(e)} id="suppPostalCode" type="text" />
                  </div>
                  <div className="field">
                      <label htmlFor="suppAdd">Supplier Street Address</label>
                      <InputTextarea value={formData.supplierStreetAddress || ""} name="supplierStreetAddress" onChange={(e)=>handleChange(e)} id="suppAdd" autoResize={true} rows="2" />
                  </div>
                  <div className="errorstyle mb-1 text-center" style={{ color: "red" }}>{errMsg}</div>
                  <Button className="login-button mb-3 px-3" label="Submit" disabled={disableBtn} onClick={(e)=>handleSubmit(e)}></Button>
                  </form>
              </div>
          </div>}

          <div className='card w-full'>
          <span className="">
                      <Button label="Go back" className="mb-4" onClick={()=>setDetails(null)} />
                  </span>
              {(details && typeof (details) !== "string") ?
                  <> < div ><span className='font-bold text-xl'>Match Confidence : </span>  <span className='font-semibold text-lg'>{details.matchConfidence}</span></div><br></br>
                      <div><span className='font-bold text-xl'>Match Details : </span> <DataTable className='w-6' showGridlines value={[details.matchDetails]}>
                          <Column headerClassName='bg-primary' field='fleetInd' header="fleetInd"></Column>
                          <Column headerClassName='bg-primary' field="l2" header="l2"></Column>
                          <Column headerClassName='bg-primary' field="l3li" header="l3li"></Column>
                          <Column headerClassName='bg-primary' field="l3s" header="l3s"></Column>
                          <Column headerClassName='bg-primary' field="mcc" header="mcc"></Column>
                      </DataTable>
                      </div><br></br>
                      <div><span className='font-bold text-xl'>Match Status : </span> <span className='font-semibold text-lg'>{details.matchStatus}</span></div></>

                  : details && <div className="col-12 " style={{ color: "#01579B" }}>
                      <div className="flex flex-column align-items-center justify-content-center" style={{ height: "60vh", }}>
                          <h3>{details}</h3>
                      </div>
                  </div>}
        </div>

    </>
  )
}

export default VisaApi