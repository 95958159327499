import React, { useEffect, useState, useRef } from "react";
import tenantService from "../../service/tenant";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import InviteUser from "./inviteUser";
import { convertCamelCase, parseResponse } from "../../utilities/utillFunction";
import ConfigService from "../../service/configService";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import * as XLSX from "xlsx";

const ManageUsers = () => {
    const serviceId = localStorage.getItem("serviceId");
    let nqpermissions = JSON.parse(localStorage.getItem("permissions"));
    let writeAccess = nqpermissions.find((p) => p === "UserManagement.WRITE");
    const [users, setUsers] = useState(null);
    const [displayDailog, setDialogDisplay] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [countryList, setCountryList] = useState([]);
    const [duplicate, setDuplicate] = useState([]);
    // const [errMsg, setErrMsg] = useState(null);
    const message = useRef();
    const toast = useRef();
    const serviceData=JSON.parse(localStorage.getItem("service"));
    const imageBodyTemplate = (thing) => {
        return <img alt="" src={process.env.REACT_APP_IMAGE_STATIC_URL + "/" + thing.image} onError={(e) => (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")} style={{ maxWidth: "100%", maxHeight: "30px" }} />;
    };

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        let [err, users] = await parseResponse(tenantService.getUsers({ serviceId: serviceId }));
        if (err) {
            message.current.show({ severity: "error", content: "Something wents wrong" });
            return;
        }
        setUsers(users);
        setDuplicate(users)
        await getCountryList();
    };

    const getCountryList = async () => {
        let [error, countryList] = await parseResponse(ConfigService.getCountryList());
        if (error) {
            message.current.show({ severity: "error", content: "Something wents wrong" });
            return;
        }

        let countries = [];
        countryList.forEach((c) => {
            countries.push({ name: convertCamelCase(c), value: c });
        });

        setCountryList(countries);
    };

    const showPermissions = (row) => {
        return (
            <div className="color2-bg">
                {row.permissions && row.permissions.length
                    ? row.permissions.map((p, i) => {
                        let splitArray = p.split(".");
                        return <Chip key={i} style={{ backgroundColor: "#657380", color: "white" }} label={p} icon={"pi pi-" + (splitArray[1] === "READ" ? "eye" : "pencil")} className="mr-2 mb-2" />;
                    })
                    : null}
            </div>
        );
    };

    const onAddUser = () => {
        setDialogDisplay(true);
    };

    const showActionButtons = (row) => {
        return (
            <div>
                <Button icon="pi pi-user-edit" className="p-button-rounded mr-2 mb-2" onClick={(e) => onEditClick(row)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded mr-2 mb-2" onClick={(e) => onDeleteClick(row)} /> */}
            </div>
        );
    };

    const onEditClick = (e) => {
        setDialogDisplay(true);
        setSelectedUser(e);
    };

    const onSideBarHide = () => {
        setDialogDisplay(false);
        setSelectedUser(null);
    };

    const onSuccess = (dialogDisplay, msg) => {
        if (msg) toast.current.show({ severity: "success", content: msg });
        setDialogDisplay(dialogDisplay);
        getUsers();
    };


    const handleSearch = (query) => {
        let filteredReport = users.filter((item) => {
            let user = item.user
            if (user && user.email && user.email.toLowerCase().includes(query.toLowerCase())||user && user.mobile && user.mobile.includes(query)||user && user.name && user.name.toLowerCase().includes(query.toLowerCase())) {
              return true
            }
        })
        setDuplicate(filteredReport);
    }
    const onDownload = (Data) => {
        let data=[];
        duplicate.map((item)=>{
            data.push({
                Name: item.user.name,
                Email: item.user.email,
                Mobile: item.user.mobile,
                "Module Acess":item.permissions.join(",")
              } )
        })
        let filename = "Webapp Users.xlsx";
        let ws = XLSX.utils.json_to_sheet(data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, filename);
      }
    
    return (
        <div className="grid">
            <div className="col-12">
                <Toast ref={toast} />
                <div className="grid">
                    <div className="col-12">
                        <div className="w-full flex justify-content-between align-items-center">
                            <h3>{"All Users"} </h3>
                            <Button label="Add User" icon="pi pi-user-plus" style={{ cursor: "pointer" }} className="mr-2 mb-2" onClick={() => onAddUser()}></Button>
                        </div>
                    </div>
                    {users && users.length ? (
                        <div className="col-12">
                            <div className="flex flex-row ">
                                <InputText  className="h-3rem border-round border-primary-500" placeholder="Search" onChange={(e) =>{handleSearch(e.target.value)}} />
                                <div className="col-3 field-radiobutton ">
                                <Button icon="pi pi-download" className="p-button-rounded " aria-label="Submit" onClick={onDownload} />
                            </div>
                            </div>
                            <DataTable value={duplicate} paginator={true} rows={6} responsiveLayout="scroll" showGridlines={true}>
                                <Column headerClassName="color1-bg" bodyClassName="color2-bg" style={{ width: "100px" }} header="Image" body={imageBodyTemplate}></Column>
                                <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="User Name" style={{ width: "100px" }} field="user.name"></Column>
                                <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Email" style={{ width: "100px" }} field="user.email"></Column>
                                <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Mobile" style={{ width: "150px" }} field="user.mobile"></Column>
                                <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Module access" style={{ width: "150px" }} body={showPermissions}></Column>
                                {serviceData.serviceTypeName==="Toll Dashboard"&&<Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Role" style={{ width: "150px" }} field="user.role"></Column>}
                                {/* <Column headerClassName='color1-bg' bodyClassName="color2-bg" header="Location Access" field="loc"></Column> */}
                                {writeAccess ? <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Action" body={showActionButtons}></Column> : null}
                            </DataTable>
                        </div>
                    ) : (
                        <Messages ref={message} />
                    )}
                </div>
                <Sidebar visible={displayDailog} style={{ width: "50vh" }} onHide={() => onSideBarHide()} baseZIndex={1000} position="right">
                    <h5>{(selectedUser ? "Edit" : "Invite") + " User"}</h5>
                    <hr />
                    {displayDailog ? <InviteUser selectedUser={selectedUser} onSuccess={(dialogDisplay, msg) => onSuccess(dialogDisplay, msg)} countryList={countryList} /> : null}
                </Sidebar>
            </div>
        </div>
    );
};
export default ManageUsers;
