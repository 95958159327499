import React, { useEffect, useState, useRef } from 'react'
import { plazaServices } from '../tollPlaza/plazaReportService'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import "./Lcix.scss"
import moment from 'moment';
import * as XLSX from "xlsx";

export const LabelPrinting = () => {
    const [data, setData] = useState([]);
    const [modalData, setModaldata] = useState([])
    const [visible, setVisible] = useState(false);
    const [trackingId, setTrackingId] = useState(null);
    const [emailCode, setEmailCode] = useState(null);
    const [delPartners, setDelPartners] = useState(null);
    const [delSpecific, setDelSpecific] = useState(null)
    const [search, setSearch] = useState();
    const [customerId, setCustomerId] = useState();
    const [expandedRows, setExpandedRowData] = useState({})
    const [ordersInfoVisible, setOrdersInfoVisible] = useState(false);
    const [ordersInfo, setOrdersInfo] = useState([])
    const [trackingurl, setTrackingurl] = useState("")
    const serviceId = localStorage.getItem("serviceId")
    const toast = useRef();
    const debounceTimer = useRef(null);
    let jwtToken = localStorage.getItem("token")
    const Header = (
        <ColumnGroup className="bg-cyan-500 text-white card monthly-details p-0" style={{ padding: "0" }}>
            <Row>
                <Column header="" className="bg-cyan-500 text-white"></Column>
                <Column header="Batch Id" className="bg-cyan-500 text-white"></Column>
                <Column header="Repro Sent Date" className="bg-cyan-500 text-white"></Column>
                <Column header="Total Orders" className="bg-cyan-500 text-white"></Column>
                <Column header="Total Items" className="bg-cyan-500 text-white"></Column>
                <Column header="Status" className="bg-cyan-500 text-white"></Column>
                <Column header="" className="bg-cyan-500 text-white"></Column>
                <Column header="" className="bg-cyan-500 text-white"></Column>
            </Row>
        </ColumnGroup>
    );

    useEffect(() => {
        const delPayload = {
            filter: {}
        }
        Promise.all([plazaServices.general(serviceId, delPayload, "nq-delivery-partners")]).then((res) => {
            let temp = [];
            res[0].map((item) => {
                let tempObj = {
                    label: item.name,
                    value: item.baseurl
                }
                temp.push(tempObj)
            })
            setDelPartners(temp);
        })
    }, [])

    const initReport = () => {
        let matchObj = {
            reproBatchId: { $exists: true },
            "statusLog.status" : "SentToRepro"
        }
        if (search && search.length) matchObj["reproBatchId"] = search
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: matchObj
                },
                {
                    "$group": {
                        "_id": "$reproBatchId",
                        "num_unique_orders": {
                            "$addToSet": "$customerOrderId"
                        },
                        "order_items": {
                            "$addToSet": "$purchaseOrderId"
                        },
                        "statuses": {
                            "$push": "$statusLog"
                        },
                        "statusLog_unwind": { "$last": "$statusLog" }
                    }
                },
                {
                  "$unwind": "$statusLog_unwind"
                },
                {
                  "$match": {
                    "statusLog_unwind.status": "SentToRepro"
                  }
                },
                {
                  "$project": {
                    "SentToRepro_dt": "$statusLog_unwind.dt",
                    "email_code": "$_id",
                    "totalOrder": {"$size": "$num_unique_orders"},
                    "printPartner": 1,
                    "parcel": 1,
                    "statuses": 1,
                    "order_items": {"$size": "$order_items"},
                  }
                },
                {
                  "$sort": {
                    "SentToRepro_dt": -1
                  }
                }
            ]
        }
        plazaServices.general(serviceId, payload, "nq-order-items").then((res) => {
            if(res){
                if(res.length){
                    res.forEach((item,index) => {
                        if(item.statuses && item.statuses.length){
                            let shipInfo = 0 
                            item.statuses.forEach((s,i)=>{
                                let canOrShipToUS = false
                                s.forEach((elem,index) => {
                                    if(["Cancelled", "ShippedToUS"].includes(elem.status)){
                                        canOrShipToUS = true
                                    }
                                })
                                if(canOrShipToUS) shipInfo++
                            })
                            if(shipInfo === 0) res[index]["shipInfo"] = 0
                            else if(shipInfo !== item.statuses.length) res[index]["shipInfo"] = 1
                            else res[index]["shipInfo"] = 2
                        }
                    })
                }
                res = res.sort((a,b)=> (a.shipInfo - b.shipInfo))
                setData(res);
            }
        })
    }

    useEffect(() => {
        if (!search) initReport()
        else {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
            debounceTimer.current = setTimeout(() => {
                initReport();
            }, 1000);
        }
        return () => {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
        }
    }, [search])

    const edidButton = (row) => {
        if (row.shipInfo < 2) {
            const show = () => {
                setEmailCode(row.email_code)
                setVisible(true)
            }
            return generalButton2("pi-file-pdf", "Enter Tracking Id and Tracking Url", show, "", "#0C6291", true, "underline")
        }
    }

    const edidButton2 = (row) => {
        let flag = false;
        row.statusLog.map((item) => {
            if (item.status === "Cancelled" || item.status === "ShippedToUS") flag = true;
        })
        if (flag) return null;
        const show = () => {
            setCustomerId(row.customer_id)
            setVisible(true)
        }
        return generalButton2("pi-file-pdf", "Enter Tracking Id and Tracking Url", show, "", "#0C6291", true, "underline")
    }

    const labelPrinting = (row) => {
        let getLabel = () => {
            const a = document.createElement('a');
            a.href = row.labelurl;
            a.download = `${row.labelurl}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        return <>
            {generalButton2("pi-file-pdf", "Get Label", getLabel, "", "#0C6291", true, "underline")}
            <a href={row.labelurl} target="_blank" rel="noopener noreferrer" style={{ display: 'none' }}>
                Anchor Tag
            </a>
        </>
    }

    const updateOperation = async () => {
        let header = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + jwtToken
        };
        const delPartner = delPartners.find((item) => item.value === delSpecific)
        let matchObj = {
            trackingId: trackingId,
            trackingUrl: trackingurl,
            serviceId: serviceId,
            deliveryPartner: delPartner ? delPartner.label : null,
        }
        if (emailCode) matchObj["reproBatchId"] = emailCode;
        else matchObj["customerOrderId"] = customerId;
        if (trackingId && trackingurl && matchObj["deliveryPartner"]) {
            let res = await axios({
                method: "post",
                url: `${process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL}/aramex-tracking-update`,
                data: matchObj,
                headers: header,
            });
            if (res.data.responseCode === 200) {
                try {
                    axios({
                        method: "post",
                        url: `${process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL}/shipped-walmart-orders`,
                        data: { serviceId: serviceId },
                        headers: header,
                    });
                } catch (error) {
                    console.error(error)
                }
                toast.current.show({ severity: 'success', summary: 'Tracking id and url Inserted ', life: 3000 });
                setVisible(false);
            }
        }
        else toast.current.show({ severity: 'info', summary: 'Fill all fields', life: 3000 });

    }

    const handleOrdersInfo = async (row) => {
        let matchObj = {
            customerOrderId: row.data.customer_id
        }
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: matchObj
                },
                {
                    $project: {
                        productName: 1,
                        sku: 1,
                        qty: 1,
                        "charge.amount": 1,
                        lastStatus: 1
                    }
                },
            ]
        }
        let res = await plazaServices.general(serviceId, payload, "nq-order-items");
        setOrdersInfo(res);
        setOrdersInfoVisible(true)

    }

    const TollSelector = (specific, list, setFunc, placeHolder) => {
        return (
            <div className="p-field col-12 grid align-items-center">
                <label htmlFor="name" className=' m-0 text-xl col-12 lg:col-5'>{placeHolder}:</label>
                <Dropdown
                    value={specific}
                    options={list}
                    onChange={(e) => { setFunc(e.value) }}
                    placeholder={placeHolder}
                    className=" bg-white col-12 lg:col-6"
                    style={{
                        borderRadius: '1.6rem',
                        padding: "0.2rem",
                        border: '2px solid #0C6291',
                    }}
                />
            </div>
        );
    };

    const generalButton2 = (icon, text, Func, bool, bgColour, Text, underline) => {
        let tcol = "text-white";
        let remainStyle = "col-5 text-xl"
        if (Text) {
            bgColour = "#FFFFFF"
            tcol = " text-primary ";
            remainStyle = ""
        }
        return (
            <div className="ml-1 mr-4 grid  mt-2 col-12 flex justify-content-start" >
                <Button className={`${tcol} ${remainStyle} ${underline}`} style={{ backgroundColor: `${bgColour}` }} onClick={() => Func(bool)} label={text}>
                </Button>
            </div>
        );
    };

    const rowExpansionTemplate = (row) => {
        return (
            <div className='col-12'>
                {modalData[row.email_code] ? <><h5>Orders in {row.email_code} batch</h5>
                    <DataTable value={modalData[row.email_code] ?? []} paginator={true} selectionMode='single' onRowSelect={(row) => { handleOrdersInfo(row) }} rows={10} responsiveLayout="scroll" >
                        <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="Customer Orderd Id" field="customer_id"></Column>
                        <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="Total Isbn" field="count"></Column>
                        <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="Order Value" body={(row) => { return "$" + (row.total_price?.toFixed(2) ?? 0) }}></Column>
                        <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="Quantity" field="total_qty"></Column>
                        <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="Shipment Tracking Id" field="trackingId"></Column>
                        <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="" body={labelPrinting}></Column>
                        <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="" body={edidButton2}></Column>
                    </DataTable></> : null}
            </div>
        )
    }

    const serachBox = (setFunc, placeHolder, label) => {
        return (
            <div className="p-field col-12 grid mt-1 ">
                {label && <label htmlFor="name " className=' m-0 text-xl  col-12 lg:col-5'>{label}:</label>}
                <InputText
                    className="p-calendar flex align-items-center bg-white col-12 lg:col-6"
                    style={{
                        borderRadius: '1.6rem',
                        padding: "1rem",
                        border: '2px solid #0C6291',
                    }}
                    onChange={(e) => {
                        setFunc(e.target.value)
                    }}
                    id="name"
                    placeholder={placeHolder}
                ></InputText>
            </div>
        );
    }

    const onRowExpand = async (event) => {
        setEmailCode(event.data.email_code)
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        "reproBatchId": event.data.email_code,
                        "lastStatus" : {"$ne": "Cancelled"}
                    }
                },
                {
                    $group: {
                        _id: {
                            customer_id: "$customerOrderId",
                            labelurl: "$parcelInfo.shippingLabelUrl"
                        },
                        total_qty: { $sum: { $toInt: "$qty" } },
                        total_price: { $sum: "$charge.amount" },
                        count: { $sum: 1 },
                        statusLog: { $last: "$statusLog" },
                        trackingId: { $first: "$indiaShipment.shipmentId"},
                    }
                },
                {
                    $project: {
                        customer_id: "$_id.customer_id",
                        labelurl: "$_id.labelurl",
                        total_qty: 1,
                        total_price: 1,
                        count: 1,
                        statusLog: 1,
                        trackingId: 1
                    }
                }
            ]
        }
        let res = await plazaServices.general(serviceId, payload, "nq-order-items");
        setModaldata({ ...modalData, [event.data.email_code]: res });
    };

    const statusShow = (row) => {
        switch(row.shipInfo){
            case 0:
                return "Not Shipped"
                break;
            case 1:
                return "Partially Shipped"
                break;
            case 2:
                return "Fully Shipped"
                break;
        }
    }
    const onDeliveryPartnerChange = (e) => {
        setDelSpecific(e)
        setTrackingurl(e)
    }
    const handletrackingidChange = (value) => {
        if (delSpecific) {
            let newurl = delSpecific
            if (value != "") newurl = newurl.replace("tid", value)
            setTrackingurl(newurl)
        }
        setTrackingId(value)
    }
    const handleBatchDataDownload = async (e,row) => {
        const payload = {
            filter : {
                "reproBatchId" : row.email_code
            },
            projection :{
                "BatchId" : "$reproBatchId",
                "OrderId" : "$customerOrderId",
                "ISBN" : "$sku",
                "Title" : "$productName",
                "Qty" : "$qty",
                "Amount" : "$charge.amount",
                "CustomerName" : "$shippingInfo.postalAddress.name",
                "Line Address 1" : "$shippingInfo.postalAddress.address1",
                "Line Address 2" : "$shippingInfo.postalAddress.address2",
                "Line Address 3" : "$shippingInfo.postalAddress.address3",
                "City" :"$shippingInfo.postalAddress.city",
                "State" : "$shippingInfo.postalAddress.state",
                "Zip" : "$shippingInfo.postalAddress.postalCode",
                "Country" : "$shippingInfo.postalAddress.country",
                "Email" : "$shippingInfo.email",
                "Phone" : "$shippingInfo.phone",
            }
        }
        let res = await plazaServices.general(serviceId, payload, "nq-order-items");

        let downloadData = []
        if(res && res.length){
            res.forEach((item, index) => {
                let temp = {}
                temp["S.no."] = index + 1
                for(const key in item){
                    if(key === "_id") continue
                    temp[key] = item[key]
                }
                downloadData.push(temp)
            })
        }
        if(downloadData.length){
            let ws = XLSX.utils.json_to_sheet(downloadData);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, `${row.email_code}.xlsx`);
        }
        else toast.current.show({ severity: 'info', summary: 'No Data', detail: "No Data found" });

    }
    return (
        <div className='col-12 card'>
            <Toast ref={toast} />
            <div className='col-12'>
                <div className='col-8'>{serachBox(setSearch, "Enter Repro Batch Id", null)}</div>
                <DataTable
                    value={data}
                    expandedRows={expandedRows}
                    onRowToggle={(event) => { setExpandedRowData(event.data) }}
                    rowExpansionMode="single"
                    onRowExpand={(e) => { onRowExpand(e) }}
                    dataKey="_id"
                    paginator rows={10}
                    responsiveLayout="scroll"
                    rowExpansionTemplate={rowExpansionTemplate}
                    headerColumnGroup={Header}
                    id="orderTable"
                >
                    <Column expander style={{ width: '3em' }} />
                    <Column field="email_code" className="bg-white font-medium surface-300 bg-gray-200" />
                    <Column body={(row) => { return moment(row.SentToRepro_dt).format('MMM, DD HH:mm') }} className="bg-white font-medium surface-300 bg-gray-200" />
                    <Column field="totalOrder" className="bg-white font-medium surface-300 bg-gray-200" />
                    <Column field="order_items" className="bg-white font-medium surface-300 bg-gray-200" />
                    <Column body={statusShow} className="bg-white font-medium surface-300 bg-gray-200" />
                    <Column body={edidButton} className="bg-white font-medium surface-300 bg-gray-200" />
                    <Column field="" bodyClassName='ml-2 p-3 text-lg' body={(row) => <i id="down" style={{ color: "#5295E2" }} className='pi pi-cloud-download text-3xl cursor-pointer' onClick={(e) => handleBatchDataDownload(e, row)}></i>}></Column>
                </DataTable>
            </div>
            <div className='col-12'>
                <Dialog header="Enter Tracking Details" visible={visible} modal className='col-12 lg:col-6' id="trackingDataTable" onHide={() => {
                    setVisible(false)
                    setTrackingId(null);
                    setDelSpecific(null);
                    setEmailCode(null);
                    setCustomerId(null);
                    setTrackingurl("")
                }}>
                    <div className="col-12 grid ">
                        {TollSelector(delSpecific, delPartners, onDeliveryPartnerChange, "Select Delivery Partners")}
                        {serachBox(handletrackingidChange, "Enter Tracking Id", "Tracking Id")}
                         <div className='col-12 flex flex-wrap'>
                            <p className=' m-0 text-xl col-12 lg:col-5'>Tracking Url</p>
                            <p className="font-bold col-12 lg:col-5" style={{wordBreak: "break-all", letterSpacing:"0.1rem"}}>{trackingurl}</p>
                         </div>
                        {generalButton2("pi-refresh", "Submit", updateOperation, "", "#0C6291", false)}
                    </div>
                </Dialog>
            </div>
                <Dialog header="Details about the order" visible={ordersInfoVisible} modal className='col-12 lg:col-6' id="trackingDataTable" onHide={() => {
                    setOrdersInfo([]);
                    setOrdersInfoVisible(false);
                }}>
                    <div className="col-12 grid justify-content-center ">
                        <DataTable
                            value={ordersInfo}
                            paginator rows={10}
                            responsiveLayout="scroll"
                            id="orderTable"
                        >
                            <Column header="SKU" headerClassName="bg-cyan-500 text-white" field='sku' bodyClassName="bg-white font-medium surface-300 bg-gray-200" />
                            <Column header="Title" headerClassName="bg-cyan-500 text-white" field='productName' bodyClassName="bg-white font-medium surface-300 bg-gray-200" />
                            <Column header="Quantity" headerClassName="bg-cyan-500 text-white" field='qty' bodyClassName="bg-white font-medium surface-300 bg-gray-200" />
                            <Column header="Price" headerClassName="bg-cyan-500 text-white" field='charge.amount' bodyClassName="bg-white font-medium surface-300 bg-gray-200" body={(row)=> ("$" + (row?.charge?.amount ?? 0))} />
                        </DataTable>
                    </div>
                </Dialog>
        </div>
    )
}