import { VehicleSrvice } from "../../service/VehicleService";
import { useState, useEffect } from "react";
import { Image } from "primereact/image";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { convertValue } from "../../utilities/unitConversion";

const VehListForPerformanceAndDaignostics = (props) => {
    const vehicleService = new VehicleSrvice();
    const serviceId = localStorage.getItem("serviceId");
    const history = useHistory();
    const unit = useSelector((state) => state.changeUnit);

    const [things, setThingsData] = useState(null);

    const getThingData = () => {
        Promise.all([vehicleService.getThingData(), vehicleService.getVehicleNumber(serviceId)]).then((res) => {
            let vehicleNumberData = res[1];

            let vehicleNumbers = res[0].map((elm) => {
                let index = vehicleNumberData.findIndex((item) => item.fleetName === elm.thingName);
                return {
                    ...elm,
                    vehicleNumber: vehicleNumberData[index].vehicle.vehicleNumber,
                };
            });
            setThingsData(vehicleNumbers);
        });
    };
    useEffect(() => {
        getThingData();
    }, []);

    const onVehicleClick = (item) => {
        history.push("/performance?thingName=" + item.thingName + "&vehicleNumber=" + item.vehicleNumber);
    };
    return (
        <div className="col-12">
            <div className="vehicle_list_grid card" style={{ overflowY: "auto", height: "85vh" }}>
                <div>
                    <div className="col-12">
                        <h3 style={{ margin: "0", textAlign: "center", padding: "10px 0" }}>Performance and Diagnostics</h3>
                    </div>
                    {things && things.length
                        ? things.map((item) => (
                              <div className="card report_opt_card mb-3" key={item.thingName}>
                                  <div className="flex">
                                      <div className="">
                                          <Image className="mr-2" src={process.env.REACT_APP_IMAGE_STATIC_URL + "/" + serviceId + "/" + item.thingName + ".jpg"} alt="Image" width="69" height="69" preview />
                                      </div>
                                      <div className="flex-1" style={{ fontSize: "1.1rem" }} onClick={() => onVehicleClick(item)}>
                                          <p className="font-medium mb-0 ">
                                              License Plate No: <font className="text-blue-500">{item.vehicleNumber.toUpperCase()} </font>
                                          </p>
                                          <p className="text-md font-medium mb-0">
                                              Make: <span className="text-blue-500 mr-3 uppercase">{item.make}</span>
                                              Model: <span className="text-blue-500 mr-3 uppercase">{item.model}</span>
                                          </p>
                                          <p className="text-md font-medium mb-0">
                                              Ignition: <span className={`${item.ignition === 1 ? "text-green-500" : "text-pink-600"} mr-3`}>{item.ignition === 1 ? "ON" : "OFF"}</span>
                                              Speed: <span className="text-green-500">{convertValue(item.speed, "speed", unit)}</span>
                                          </p>
                                          {/* <p className="text-md font-medium mb-0">
                                            Ignition: <span className={`${item.ignition === 1 ? "text-green-500" : "text-pink-600"} mr-3`}>{item.ignition === 1 ? "ON" : "OFF"}</span>
                                            Speed: <span className="text-green-500">{convertValue(item.speed, "speed", process.env.REACT_APP_DISPLAY_TYPE)}</span>
                                        </p> */}
                                      </div>
                                  </div>
                              </div>
                          ))
                        : null}
                </div>
            </div>
        </div>
    );
};
export default VehListForPerformanceAndDaignostics;
