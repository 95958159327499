import moment from "moment";
import { Badge } from "primereact/badge";
import { InputText } from "primereact/inputtext";
import React, { useState} from "react";

const CardList = ({ data, onSelectItem, selectedItem, isVehicle }) => {
    const [dataList, setDataList] = useState(data);
    const duplicateData = data;
    const [value, setValue] = useState("");
    const handleSeaarch = (e) => {
        setValue(e.target.value);
        if (value !== "") {
            const filterData = duplicateData.filter((item) => {
                return Object.values(item).join("").toLowerCase().includes(value.toLowerCase());
            });
            setDataList(filterData);
        } else {
            setDataList(data);
        }
    };
    return (
        <>
            <div className="col-12 md:col-12">
                <InputText value={value} onChange={(e) => handleSeaarch(e)} placeholder="search" className="w-full" />
            </div>
            <div className="app-list-grid cardSubHeader">
                {dataList &&
                    dataList.map((item, index) => (
                        <div className={selectedItem === item.thingName ? `card app-list-item font-semibold cursor-pointer bg-blue-100` : `card app-list-item font-semibold cursor-pointer`} style={{ height: '150px' , fontSize:null }} id="card" key={"card-lst-" + index} onClick={() => onSelectItem(item)}>
                            <h5 className="capitalize" id="cardHeader">
                               {isVehicle ? <>{item.make}({item.model}) <br />
                                <small className="uppercase">{item.hasOwnProperty("plateNo") ? item.plateNo : null}</small></> :
                               (item.alias || item.sitename) }
                            </h5>
                            <div className="flex flex-column cardSubHeader">
                                <div className="flex">
                                    <p>
                                        Fuel Level: <span className="ml-1 text-primary">{ isVehicle ? item.fuellevel: item.fuelLevelCalibrated} L</span>
                                    </p>
                                </div>
                                <div className="flex">
                                    <p>
                                        Last Data Received: <span className="ml-1 text-primary">{moment(item.ts).format("MMM DD, YYYY")}</span>
                                    </p>
                                </div>
                            </div>
                            <p  style={{ fontSize:null }}>
                                {item.ignition === 1 ? (
                                    <>
                                        Ignition Status: <Badge value="ON" severity="success" className="mr-2"></Badge>
                                    </>
                                ) : (
                                    <>
                                        Ignition Status: <Badge value="OFF" severity="danger" className="mr-2"></Badge>
                                    </>
                                )}
                            </p>
                        </div>
                    ))}
            </div>
        </>
    );
};

export default CardList;
