import { httpService } from "./httpService";
import { httpConstants } from "../lib/constants";
import axios from "axios";

function getConfigServiceUrl() {
    return process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL;
    // return 'http://localhost:9091';
}
async function uploadConfigFile(requestData, serviceid) {
    let url = getConfigServiceUrl() + httpConstants.API_END_POINT.UPLOAD_CONFIG_FILE + "/" + serviceid;
    // let url = "http://localhost:3001" + httpConstants.API_END_POINT.UPLOAD_CONFIG_FILE + `/${sessionManager.getDataFromCookies(cookiesConstants.SELECTED_SERVICE_ID)}`;

    let header = new Headers();
    header.append("Authorization", "Bearer " + localStorage.getItem("token"));

    let requestOptions = {
        method: "POST",
        body: requestData,
        headers: header,
        redirect: "follow",
    };
    return new Promise((resolve, reject) => {
        fetch(url, requestOptions)
            .then(function handleResponse(response) {
                if (!response || !response.ok) reject("Unable to fetch data");

                return response.text().then((responseText) => {
                    if (!responseText) reject(responseText);

                    let data;
                    try {
                        data = JSON.parse(responseText);
                        if (data && !data.success) return reject(data);
                    } catch (err) {
                        return Promise.reject(err);
                    }
                    resolve(data);
                });
            })
            .catch(function (err) {
                return Promise.reject(err);
            });
    });
}

async function getListOfThings(serviceBlock) {
    let apiPath = "getListOf";
    if (serviceBlock === "fleet") apiPath += "Fleet";
    else if (serviceBlock === "space") apiPath += "Space";
    else if (serviceBlock === "item") apiPath += "Items";
    else if (serviceBlock === "object") apiPath += "Object";

    let params = { serviceId: localStorage.getItem("serviceId") };

    return httpService(httpConstants.METHOD_TYPE.POST, params, getConfigServiceUrl() + "/" + apiPath)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0) return Promise.reject();
            return Promise.resolve(response.responseData);
        })
        .catch(function (err) {
            return Promise.reject(err);
        });
}

async function uploadImage(requestData, serviceId) {
    let url = getConfigServiceUrl() + "/upload-image/" + serviceId;
    let jwtToken = localStorage.getItem("token");
    let header = new Headers();
    header.append("Authorization", "Bearer " + jwtToken);

    let requestOptions = {
        method: "POST",
        body: requestData,
        headers: header,
        redirect: "follow",
    };

    return new Promise((resolve, reject) => {
        fetch(url, requestOptions)
            .then(function handleResponse(response) {
                if (!response || !response.ok) reject("Unable to fetch data");

                return response.text().then((responseText) => {
                    if (!responseText) reject(responseText);

                    let data;
                    try {
                        data = JSON.parse(responseText);
                        if (data && !data.success) return reject(data);
                    } catch (err) {
                        return Promise.reject(err);
                    }
                    resolve(data);
                });
            })
            .catch(function (err) {
                return Promise.reject(err);
            });
    });
}

function APICall(method, requestData, url) {
    return httpService(method, requestData, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0) return Promise.resolve([]);
            return Promise.resolve(response.responseData);
        })
        .catch(function (err) {
            return Promise.reject(err);
        });
}

async function getCountryList(requestData) {
    let url = getConfigServiceUrl() + httpConstants.API_END_POINT.GET_COUNTRY_LIST;
    return APICall(httpConstants.METHOD_TYPE.GET, requestData, url);
}

async function getStateList(requestData) {
    let url = getConfigServiceUrl() + httpConstants.API_END_POINT.GET_STATES_LIST;
    return APICall(httpConstants.METHOD_TYPE.POST, requestData, url);
}

async function getStateListrow(requestData) {
    let url = getConfigServiceUrl() + "/getStates";
    return APICall(httpConstants.METHOD_TYPE.POST, requestData, url);
}

async function getCities(requestData) {
    let url = getConfigServiceUrl() + httpConstants.API_END_POINT.GET_LIST_OF_CITIES;
    return APICall(httpConstants.METHOD_TYPE.POST, requestData, url);
}

async function getCitiesListrow(requestData) {
    let url = getConfigServiceUrl() + "/getCities";
    return APICall(httpConstants.METHOD_TYPE.POST, requestData, url);
}

async function getPincodes(requestData) {
    let url = getConfigServiceUrl() + httpConstants.API_END_POINT.GET_LIST_OF_PINCODES;
    return APICall(httpConstants.METHOD_TYPE.POST, requestData, url);
}

async function getPincodesrow(requestData) {
    let url = getConfigServiceUrl() + "/getPincodes";
    return APICall(httpConstants.METHOD_TYPE.POST, requestData, url);
}

async function createServiceId(requestData) {
    const url = process.env.REACT_APP_TENANT_SERVICE_URL + "/create-serviceId";
    let response = await axios({
        method: "post",
        url: url,
        data: requestData,
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
}

async function getSupportDetails(requestData) {
    const url = process.env.REACT_APP_DOCDB_SERVICE_URL + "/nextqore/nq-help";
    let response = await axios({
        method: "post",
        url: url,
        data: requestData,
    });
    return response.data;
}

async function registerUser(requestData) {
    const url = process.env.REACT_APP_TENANT_SERVICE_URL + "/register-user";
    let response = await axios({
        method: "post",
        url: url,
        data: requestData,
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
}

async function uploadTrackingUser(requestData, serviceId) {
    const url = process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL + "/upload-new-employee-hierarchy/" + serviceId;
    let response = await axios({
        method: "post",
        url: url,
        data: requestData,
        headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
}

async function updateCustomerImage(requestData) {
    const url = process.env.REACT_APP_TENANT_SERVICE_URL + "/update-customer-image";
    let response = await axios({
        method: "post",
        url: url,
        data: requestData,
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
}

async function getRegion(requestData) {
    const res = await axios.post(process.env.REACT_APP_TENANT_SERVICE_URL + "/get-regions");
    return res.data;
}

async function getServiceTypeNames() {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/nextqore/nq-service-types", {});
    return res.data;
}

async function getEmployeeData(SERVICE_ID) {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const payload = { serviceId: SERVICE_ID };
    const res = await axios.post(process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL + "/get-employee-metadata", payload, { headers: headers });
    return res.data;
}

async function getEmployeeDataUpdate(requestData) {
    const url = process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL + "/update-employee-metadata";

    let response = await axios({
        method: "post",
        url: url,
        data: requestData,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
    return response.data;
}

async function getAllowedUser(payload) {
    const url = `${process.env.REACT_APP_DOCDB_SERVICE_URL}/nextqore/nq-allowedusers`;

    let response = await axios({
        method: "post",
        url: url,
        data: payload,
        headers: {
            "Content-Type": "application/json",
        },
    });
    return response.data;
}

async function uploadCalibration(payload) {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    const res = await axios.post(getConfigServiceUrl() + "/upload-calibration", payload, { headers: headers });
    return res.data;
}

async function addImeiInThing(payload) {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    const res = await axios.post(getConfigServiceUrl() + "/add-imei", payload, { headers: headers });
    return res.data;
}
async function getEmpMetaData(serviceId){
    const headers = {
        "Content-Type": "application/json",
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    let payload = {
        serviceId: serviceId
    }
    const res = await axios.post(getConfigServiceUrl() + "/get-emp-meta", payload, {headers: headers})
    return res.data
}
async function addSiteImageInfo(payload){
    const headers = {
        "Content-Type": "application/json",
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    const res = await axios.post(getConfigServiceUrl() + "/site-img", payload, {headers: headers})
    return res.data
}

const service = {
    getSupportDetails,
    uploadConfigFile,
    getListOfThings,
    uploadImage,
    getCountryList,
    getStateList,
    getStateListrow,
    getCities,
    getCitiesListrow,
    getPincodes,
    getPincodesrow,
    createServiceId,
    registerUser,
    updateCustomerImage,
    getRegion,
    getServiceTypeNames,
    getEmployeeData,
    getEmployeeDataUpdate,
    uploadTrackingUser,
    getAllowedUser,
    uploadCalibration,
    addImeiInThing,
    getEmpMetaData,
    addSiteImageInfo
};

export default service;
