import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { BreadCrumb } from 'primereact/breadcrumb';
import { useHistory } from "react-router-dom";

import { TowerService } from '../../../service/TowerService';
import { mapKey } from "../../../shared/data/const";

const InsightReportComponent = () => {

    const { condition } = useParams();
    const [towers, setTowers] = useState([]);
    const [reportTowers, setReportTowers] = useState(null);
    const [cityLevelTowerReport, setCityLevelTowerReport] = useState(null);
    const [sites, setSites] = useState(null);
    const [isReport, setReport] = useState(true);
    const [selectedState, setSelectedState] = useState(null);
    const [SelectedCity, setSelectedCity] = useState(null);
    const [breadcrumb, setBreadcrumb] = useState(null)

    const tower = new TowerService();
    let history = useHistory();

    let pageTitle = '';
    const home = { icon: 'pi pi-home', url: 'http://localhost:3000/#/tower-report/' + condition,  }

    useEffect(() => { initPage() }, []);

    const initPage = () => {
        tower.getAllTowers().then((allTowers) => {
            //for Rusting
            if(condition === 'withrust') {
                pageTitle = 'Rust Report';
                const tower_with_rust = allTowers.filter(item => item.towerDefects.rustArea.length > 0);
    
                if(tower_with_rust.length === 0) {
                    setReport(false)
                } else {
                    //getting tower types and state
                    initReport(tower_with_rust);
                    
                } 
            }
            setTowers(allTowers);
        });
        setSelectedState(null);
    }

    const initReport = (towers) => {
        let t_state = [];
        towers.map((item) => {
            if (!t_state.includes(item.siteDetails.location.state)) {
                t_state.push(item.siteDetails.location.state);
            }
        });

        let t_circle_count = [];
        let t_position = [];
        t_state.map((item) => {
            let details = [];
            let t_circle_length = towers.filter((elm) => elm.siteDetails.location.state === item);

            t_circle_length.map((tower) => {
                t_position.push({
                    customerSiteid: tower.customerSiteid,
                    pos: {
                        lat: tower.siteDetails.location.latitude,
                        lng: tower.siteDetails.location.longitude,
                    },
                });
            });

            let t_RTT_type_length = towers.filter((elm) => elm.siteDetails.location.state === item && elm.towerDetails.towerConfig.towerType === "RTT");
            if (t_RTT_type_length.length > 0) details.push({ type: "RTT", total: t_RTT_type_length.length });

            let t_GBT_type_length = towers.filter((elm) => elm.siteDetails.location.state === item && elm.towerDetails.towerConfig.towerType === "GBT");
            if (t_GBT_type_length.length > 0) details.push({ type: "GBT", total: t_GBT_type_length.length });

            let t_RTP_type_length = towers.filter((elm) => elm.siteDetails.location.state === item && elm.towerDetails.towerConfig.towerType === "RTP");
            if (t_RTP_type_length.length > 0) details.push({ type: "RTP", total: t_RTP_type_length.length });

            let t_GBM_type_length = towers.filter((elm) => elm.siteDetails.location.state === item && elm.towerDetails.towerConfig.towerType === "GBM");
            if (t_GBM_type_length.length > 0) details.push({ type: "GBM", total: t_GBM_type_length.length });

            t_circle_count.push({
                circleName: item,
                totalSite: t_circle_length.length,
                details: [...details],
                position: [...t_position],
            });
        });
        setReportTowers(t_circle_count);
        setSites(null)
        setCityLevelTowerReport(null);
        getMap(t_circle_count, 4);
    };

    const getCity = (stateName) => {
        let siteIndex = reportTowers.findIndex(item => item.circleName === stateName);
        let siteIds = []
        if(siteIndex > -1) {
            let sight = reportTowers[siteIndex];
            sight.position.map(item => {
                siteIds.push(item.customerSiteid);
            });
        }
        let filterTowers = []
        siteIds.map(item => {
            let towerIndex = towers.findIndex(elm => elm.customerSiteid === item);
            if(towerIndex > -1) {
                filterTowers.push(towers[towerIndex])
            }
            
        });

        let t_city = [];
        filterTowers.map(item => {
			if(!t_city.includes(item.siteDetails.location.city)) {
				t_city.push(item.siteDetails.location.city);
			}
		});

        //prepare city wise dataset
		let t_city_count = [];
        
		t_city.map(item => {
            let details = [];
            let t_position = [];
			let t_city_length = filterTowers.filter(elm => elm.siteDetails.location.city === item);

            t_city_length.map(tower => {
                t_position.push({
                    customerSiteid: tower.customerSiteid,
                    pos: {
                        lat: tower.siteDetails.location.latitude,
                        lng: tower.siteDetails.location.longitude
                    }
                })
            });

            let t_RTT_type_length = t_city_length.filter(elm => (elm.siteDetails.location.city === item && elm.towerDetails.towerConfig.towerType === 'RTT'));
            if(t_RTT_type_length.length > 0) details.push({ type: 'RTT', total: t_RTT_type_length.length});
    
            let t_GBT_type_length = t_city_length.filter(elm => (elm.siteDetails.location.city === item && elm.towerDetails.towerConfig.towerType === 'GBT'));	
            if(t_GBT_type_length.length > 0) details.push({ type: 'GBT', total: t_GBT_type_length.length});
    
            let t_RTP_type_length = t_city_length.filter(elm => (elm.siteDetails.location.city === item && elm.towerDetails.towerConfig.towerType === 'RTP'));	
            if(t_RTP_type_length.length > 0) details.push({ type: 'RTP', total: t_RTP_type_length.length});
    
            let t_GBM_type_length = t_city_length.filter(elm => (elm.siteDetails.location.city === item && elm.towerDetails.towerConfig.towerType === 'GBM'));
            if(t_GBM_type_length.length > 0) details.push({ type: 'GBM', total: t_GBM_type_length.length});
			
			t_city_count.push({
				circleName: item,
				totalSite: t_city_length.length,
				details: [...details],
                position: [...t_position]
			});
		});

        let bradecrum = [];
        bradecrum.push({label: stateName});
        setBreadcrumb(bradecrum);
        setReportTowers(null);
        setCityLevelTowerReport(t_city_count);
        setSelectedState(stateName);
        getMap(t_city_count, 10);
    }

    const getSites = (cityName) => {
        let sites = towers.filter(item => item.siteDetails.location.city === cityName);
        let bread = breadcrumb;
        bread.push({ label: cityName })
        setSites(sites);
        setSelectedCity(cityName);
        setReportTowers(null);
        setCityLevelTowerReport(null);
        setBreadcrumb(bread);
    }

    const goToSite = (customerSiteid) => {
        history.push("/"+selectedState+"/"+SelectedCity+"/"+customerSiteid);
    }

    const getMap = (towers, zoom) => {
        let pos = [];
        towers.map(item => {
            pos = [...pos, ...item.position];
        });

        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey
        });
        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(
            document.getElementById("mapView"),
            defaultLayers.vector.normal.map,
            {
                center: {lat: pos[0].pos.lat, lng: pos[0].pos.lng}, 
                zoom: zoom,
                pixelRatio: window.devicePixelRatio || 1
            }
        );

        const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        const ui = H.ui.UI.createDefault(map, defaultLayers);

        startClustering(map, pos);
    }


    const startClustering = (map, pos) => {
        const H = window.H;
        let dataPoints = pos.map((item) => {
            return new H.clustering.DataPoint(item.pos.lat, item.pos.lng);
        });
        let clusteredDataProvider = new H.clustering.Provider(dataPoints, {
            clusteringOptions: {
                eps: 32,
                minWeight: 2
            }
        });
        clusteredDataProvider.addEventListener('tap', (e) => {
            var position = e.target.getGeometry();
            map.setCenter(position, true);
        } );
        let clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);
        map.addLayer(clusteringLayer);
    }
    
    return isReport ? (
        <div className="grid">
            <div className="col-12">
                <h4>{pageTitle}</h4>
            </div>

            <div className="col-12 md:col-6 lg:col-4">
                {selectedState ? (
                    <BreadCrumb model={breadcrumb} home={home} />
                ) : null}
                {reportTowers ? (
                    <>
                        {reportTowers.map((item, index) => (
                            <div className="card report_opt_card mb-3" key={"state-" + index} onClick={() => getCity(item.circleName)}>
                                <div className="flex">
                                    <div className="card_ioc_grid flex justify-content-center align-items-center mr-2">
                                        <i className="pi pi-map-marker" style={{ fontSize: "1.2em" }}></i>
                                    </div>
                                    <div className="flex-1">
                                        <p className="text-900 font-medium text-xl mb-0">
                                            {item.circleName}{" "}
                                            <span className="text-sm" style={{ float: "right" }}>
                                                Total: {item.totalSite}
                                            </span>
                                        </p>
                                        {item.details.map((elm, index) => (
                                            <span className="mr-2" key={"state-tower-type-" + index}>
                                                <b>{elm.type}</b>: {elm.total}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                ) : null}
                {cityLevelTowerReport ? (
                    <>
                        {cityLevelTowerReport.map((item, index) => (
                            <div className="card report_opt_card mb-3" key={"city-" + index} onClick={() => getSites(item.circleName)}>
                                <div className="flex">
                                    <div className="card_ioc_grid flex justify-content-center align-items-center mr-2">
                                        <i className="pi pi-map-marker" style={{ fontSize: "1.2em" }}></i>
                                    </div>
                                    <div className="flex-1">
                                        <p className="text-900 font-medium text-xl mb-0">
                                            {item.circleName}{" "}
                                            <span className="text-sm" style={{ float: "right" }}>
                                                Total: {item.totalSite}
                                            </span>
                                        </p>
                                        {item.details.map((elm, index) => (
                                            <span className="mr-2" key={"state-tower-type-" + index}>
                                                <b>{elm.type}</b>: {elm.total}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                ): null}
                {sites ? (
                    <>
                        {sites.map((item, index) => (
                            <div className="card report_opt_card mb-3" key={"site-" + index} onClick={() => goToSite(item.customerSiteid)}>
                                <div className="flex">
                                    <div className="card_ioc_grid flex justify-content-center align-items-center mr-2">
                                        <i className="pi pi-map-marker" style={{ fontSize: "1.2em" }}></i>
                                    </div>
                                    <div className="flex-1">
                                        <p className="text-900 font-medium text-xl mb-0">
                                            {item.customerSiteid}{" "}
                                            <span className="text-sm" style={{ float: "right" }}>
                                                Type: {item.towerDetails.towerConfig.towerType}
                                            </span>
                                        </p>
                                        <p className="text-500 mb-0">
                                            {item.siteDetails.location.address}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                ): null}
            </div>
            <div className="col-12 md:col-6 lg:col-8">
                <div className="card">
                    <div
                        id="mapView"
                        style={{
                            width: "100%",
                            height: "76vh",
                            overflow: "hidden",
                        }}
                    ></div>
                </div>
            </div>
        </div>
    ) : (
        <>
            <div className="empty_placeholder flex flex-column align-items-center justify-content-center">
                <h1>
                    <i className="pi pi-search mb-2" style={{ fontSize: "1em" }}></i>
                </h1>
                <h3>No result found</h3>
                <Link to="/towerinsight" className="text-primary">
                <i class="fa-solid fa-reply"></i> Go back
                </Link>
            </div>
        </>
    );
}

export default InsightReportComponent;