import React from "react";

const Unauth = () => {
    return (
        <div className="grid w-100 justify-content-center align-items-center" style={{ height: "80vh" }}>
            <div className="text-center d-block">
                <h1>401</h1>
                <p>You don't have access to view this page.</p>
            </div>
        </div>
    );
};

export default Unauth;
