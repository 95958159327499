import axios from "axios";

export class AssetService {
    // createServiceId = async (requestData) => {
    //     const url = process.env.REACT_APP_TENANT_SERVICE_URL + "/create-serviceId";
    //     let response = await axios({
    //         method: "post",
    //         url: url,
    //         data: requestData,
    //         headers: { "Content-Type": "multipart/form-data" },
    //     });
    //     return response.data;
    // };

    // registerUser = async (requestData) => {
    //     const url = process.env.REACT_APP_TENANT_SERVICE_URL + "/register-user";
    //     let response = await axios({
    //         method: "post",
    //         url: url,
    //         data: requestData,
    //         headers: { "Content-Type": "multipart/form-data" },
    //     });
    //     return response.data;
    // };

    // updateCustomerImage = async (requestData) => {
    //     const url = process.env.REACT_APP_TENANT_SERVICE_URL + "/update-customer-image";
    //     let response = await axios({
    //         method: "post",
    //         url: url,
    //         data: requestData,
    //         headers: { "Content-Type": "multipart/form-data" },
    //     });
    //     return response.data;
    // };

    // getRegion = async (requestData) => {
    //     const res = await axios.post(process.env.REACT_APP_TENANT_SERVICE_URL + "/get-regions");
    //     return res.data;
    // };

    getServiceObserGetway = async (serviceID, serviceClass) => {
        if(serviceClass === "item") serviceClass = serviceClass+ "s";
        const payload = {
            serviceId: serviceID,
            serviceClass: serviceClass,
        };

        const res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/rogwstats", payload);
        return res.data;
    };

    getServiceObserSen = async (serviceID, serviceClass) => {
        if(serviceClass === "item") serviceClass = serviceClass+ "s";
        const payload = {
            serviceId: serviceID,
            serviceClass: serviceClass,
        };

        const res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/rostats", payload);
        return res.data;
    };

    getThings = async (serviceID) => {
        const token = localStorage.getItem("token");
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        const res = await axios.post(process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL + "/get-things/" + serviceID, {}, { headers: headers });
        return res.data;
    };

    getAssets = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-items", payload);
        return res.data;
    };

    getDgsModelWise = async (serviceID) => {
        const aggregateQuery = [
            {
                $group: {
                    _id: {
                        make: "$make",
                        model: "$model",
                        type: "$type",
                    },
                    count: {
                        $sum: 1,
                    },
                    dgs: {
                        $addToSet: {
                            thingName: "$thingName",
                        },
                    },
                },
            },
        ];
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-items", { operation: "aggregate", aggregate: aggregateQuery, allowDiskUse: true });
        return res.data;
    };

    getDgSizeWise = async (serviceID) => {
        const aggregateQuery = [
            {
                $group: {
                    _id: {
                        size: "$size",
                    },
                    count: {
                        $sum: 1,
                    },
                    dgs: {
                        $addToSet: {
                            thingName: "$thingName",
                        },
                    },
                },
            },
        ];
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-items", { operation: "aggregate", aggregate: aggregateQuery, allowDiskUse: true });
        return res.data;
    };

    getData = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-history", payload);
        return res.data;
    };
    getParmTrendsData = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-data", payload);
        return res.data;
    };
    getAllSites = async (serviceID, things) => {
        let query = {
            filter: {
                thingName: { $in: things },
            },
        };
        let res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-items", query);
        return res.data;
        // let aggregateQuery = [{ $match: { thingName: { $in: things } } }, { $group: { _id: "$state" } }, { $project: { state: 1 } }];
        // let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-history", { operation: "aggregate", aggregate: aggregateQuery });
        // return res.data;
    };
    getCities = async (serviceID, things) => {
        let aggregateQuery = [{ $match: { thingName: { $in: things } } }, { $group: { _id: { state: "$state", city: "$city" } } }, { $project: { city: 1 } }];
        let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-history", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };
    getZipcodes = async (serviceID, things) => {
        let aggregateQuery = [{ $match: { thingName: { $in: things } } }, { $group: { _id: { state: "$state", city: "$city", zipcode: "$zipcode" } } }, { $project: { zipcode: 1 } }];
        let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-history", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };
    getMakes = async (serviceID, things) => {
        let aggregateQuery = [{ $match: { thingName: { $in: things } } }, { $group: { _id: "$make" } }, { $project: { make: 1 } }];
        let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-history", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };
    getModels = async (serviceID, things) => {
        let aggregateQuery = [{ $match: { thingName: { $in: things } } }, { $group: { _id: "$model" } }, { $project: { model: 1 } }];
        let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-history", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };
    getSizes = async (serviceID, things) => {
        let aggregateQuery = [{ $match: { thingName: { $in: things } } }, { $group: { _id: "$size" } }, { $project: { size: 1 } }];
        let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-history", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };
    getSites = async (serviceID, things) => {
        let aggregateQuery = [{ $match: { thingName: { $in: things } } }, { $group: { _id: { thingName: "$thingName", state: "$state", city: "$city", zipcode: "$zipcode", make: "$make", model: "$model", size: "$size" } } }, { $project: { thingName: 1 } }];
        let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-history", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getDGStatus = async (serviceID) => {
        let aggregateQuery = [{ $group: { _id: "overall", On: { $sum: "$DGStatus" }, Total: { $sum: 1 } } }, { $addFields: { Off: { $subtract: ["$Total", "$On"] } } }];
        let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-meta", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getDGRunHourMaxAvg = async (serviceID, startDay, endDay) => {
        let aggregateQuery = [
            { $match: { date: { $gte: new Date(startDay), $lte: new Date(endDay) } } },
            { $group: { _id: { thingName: "$thingName" }, avgRunHour: { $avg: "$RunHours" }, maxRunHour: { $max: "$RunHours" }, totalRunHour: { $sum: "$RunHours" } } },
            { $sort: { "_id.date": 1, "_id.thingName": 1 } },
        ];
        let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-history-daily", { operation: "aggregate", aggregate: aggregateQuery, allowDiskUse: true });
        return res.data;
    };

    getDgTotalRunHr = async (serviceID) => {
        let aggregateQuery = [{ $match: { ts: { $gte: "2022-11-01 00:00:00", $lte: "2022-11-23 23:59:59" } } }, { $group: { _id: { date: { $substr: ["$ts", 0, 10] }, thingName: "$thingName" }, totalRunHour: { $sum: "$DGRunHour" } } }, { $sort: { "_id.date": 1, "_id.thingName": 1 } }];
        let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-history", { operation: "aggregate", aggregate: aggregateQuery, allowDiskUse: true });
        return res.data;
    };

    getROEvents = async (payload) => {
        const res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/roevents", payload);
        return res.data;
    };

    getDGDailyData = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-history-daily", payload);
        return res.data;
    };

    getLatestData = async (serviceID, thingName) => {
        let query = {
            filter: {
                thingName: thingName,
            },
        };
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-asset-meta", query);
        return res.data;
    };
}
