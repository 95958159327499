import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import TenantService from "../service/tenant";
import PhoneInput, { isValidPhoneNumber, getCountryCallingCode } from "react-phone-number-input";

const ForgotPassword = () => {
    const [email, setEmail] = useState(null);
    const [code, setCode] = useState(null);
    const [codeSent, setCodeSent] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);
    const [newPasswordType, setNewPasswordType] = useState("password");
    const [newPassword, setNewPassword] = useState(null);
    const [cognitoUser, setCognitoUser] = useState(null);
    const [disableChangePasswordBtn, setdisableChangePasswordBtn] = useState(false);
    const [countryCodeVal, setCountryCodeVal] = useState("+" + getCountryCallingCode(localStorage.getItem("defaultCountryCode")) || "")
    const [showPhoneInput, setShowPhoneInput] = useState(false)
    const history = useHistory();
    const toast = useRef();
    const inputTypeNumber = useRef(false)
    let defaultCountryCode = localStorage.getItem("defaultCountryCode")

    const  validateInputs = () => {
        if (!email) return "Please enter email/mobile";
        if (isNumber(email) && !isValidPhoneNumber(countryCodeVal + email)) return "Enter valid mobile no"
        if (email && !isNumber(email)) {
            let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let res = emailRegex.test(email);
            if (!res) return "enter valid email";
        }
    }

    const onContinue = async (e) => {
        setErrMsg("")
        e.preventDefault();
        let validationRes = validateInputs();

        if (typeof validationRes === "string") {
            setErrMsg(validationRes);
        }
        else {
            setDisableBtn(true);
            let payload = {
                username: isNumber(email) ? countryCodeVal + email.toLowerCase() : email.toLowerCase()
            }
            let codeSentRes = await TenantService.forgotSendCode(payload)
            if (!codeSentRes || !codeSentRes.success || !codeSentRes.responseData || !codeSentRes.responseData.cognitoUser || !codeSentRes.responseData.codeSent) {
                setDisableBtn(false);
                if (codeSentRes.message) {
                    setErrMsg(codeSentRes.message);
                }
                return
            }
            setCodeSent(true)
            setCognitoUser(codeSentRes.responseData.cognitoUser)
        }
    }
    const toggleNewPassword = (e) => {
        if (newPasswordType === "password") {
            setNewPasswordType("text")
            return;
        }
        setNewPasswordType("password")
    }

    const validateChangePasswordInputs = () => {
        if (!code) return "Please enter verification code";
        if (!newPassword) return "Please enter the new password";
    }

    const onChangePassword = async (e) => {
        e.preventDefault();
        let validationRes = validateChangePasswordInputs();

        if (typeof validationRes === "string") {
            setErrMsg(validationRes);
        }
        else {
            setdisableChangePasswordBtn(true);
            try {
                const payload = {
                    code: code,
                    newPassword: newPassword,
                    username : isNumber(email) ? countryCodeVal + email.toLowerCase() : email.toLowerCase()
                }

                let forgotCNFPswdRes = await TenantService.forgotCNFPswd(payload)
                if (!forgotCNFPswdRes || !forgotCNFPswdRes.success) {
                    setdisableChangePasswordBtn(false);
                    if (forgotCNFPswdRes.message) {
                        setErrMsg(forgotCNFPswdRes.message);
                    }
                    return
                }
                toast.current.show({ severity: 'success', content: "Your Password has been Updated" });
                setTimeout(() => {
                    history.push("/login");
                }, 2000)


            }
            catch (err) {
                setErrMsg("Error occured while updating the password");
                setdisableChangePasswordBtn(false);
                console.error(err);
            }
        }
    }
    function isNumber(n) {
        if (n == "" || n == null || n == undefined) return false
        return Number(n) == n
    }

    const handleChange = (value) => {
        setEmail(value)
        if (!value.trim()) {
            setShowPhoneInput(false)
            inputTypeNumber.current = false
            return
        }
        let valIsNo = isNumber(value)
        if (valIsNo && inputTypeNumber.current === false) {
            setShowPhoneInput(true)
            inputTypeNumber.current = valIsNo
        }
        else if (!valIsNo && inputTypeNumber.current === true) {
            setShowPhoneInput(false)
            inputTypeNumber.current = false
        }
    }

    return (
        <div className="pages-body flex flex-column login-img">
            <Toast ref={toast} />

            <div className=" align-self-center  mt-auto mb-auto">
                <div className=" card flex flex-column col-12" style={{ color: "#616161" }}>
                    <div className="align-self-center">
                        <img alt="" src="./images/nexLogo.svg" className="logo-fixing" /><br />
                    </div>
                    {
                        !codeSent ?
                            <>
                                <form >
                                    <div className="mb-3 px-3 font-bold" style={{ textAlign: "center", fontSize: "1.2rem" }}>Enter Your Email Or Phone No</div>
                                    <div className="mb-1 px-3">
                                        <label>Email / Phone</label>
                                    </div>
                                    <div className="mb-3 px-3">
                                        <div className="p-inputgroup">
                                            <span className="p-float-label">
                                                {showPhoneInput && <PhoneInput id="userPhoneInput" style={{ borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px", borderTopRightRadius: 0, borderBottomRightRadius: 0, maxWidth: "7em" }} className="p-inputtext p-component p-filled w-full" value={countryCodeVal} onChange={setCountryCodeVal} international defaultCountry={defaultCountryCode} />}
                                                <InputText  type="text" id="inputgroup1" autoFocus={true} onChange={(e) => handleChange(e.target.value)} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="text-center pt-0 errorstyle mb-1" style={{ color: "red" }}>{errMsg}</div>
                                    <div className="mb-3 px-3">
                                        <Button className="login-button mb-3 px-3 col-12" style={{ padding: "0.75rem 0.75rem" }} label="Continue" disabled={disableBtn} onClick={onContinue}></Button>
                                    </div>
                                </form>
                            </>
                            :
                            <>
                                <form>
                                    <div className="mb-3 px-3 font-bold align-self-center" style={{ fontSize: "1.2rem" }}>Enter verification code and new password</div>

                                    <div className=" mb-1 px-3">
                                        <label>Verification Code</label>
                                    </div>
                                    <div className="mb-3 px-3">
                                        <InputText className="col-12" style={{ padding: "0.75rem 0.75rem" }} type="text" id="code" onChange={(e) => setCode(e.target.value)} />

                                    </div>

                                    <div className="mb-1 px-3">
                                        <label>New Password</label>
                                    </div>
                                    <div className="p-inputgroup mb-3 px-3">

                                        <span className="p-float-label">
                                            <InputText style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} type={newPasswordType} id="newpassword" onChange={(e) => setNewPassword(e.target.value)} />

                                            <div className="p-inputgroup-addon" onClick={toggleNewPassword}>
                                                {newPasswordType === "password" ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
                                            </div>
                                        </span>
                                    </div>
                                    <div className="text-center mb-3 px-3" style={{ color: "red" }}>{errMsg}</div>
                                    <div className="mb-3 px-3">
                                        <Button className="login-button mb-3 px-3 col-12" label="Change Password" disabled={disableChangePasswordBtn} onClick={onChangePassword}></Button>

                                    </div>
                                </form>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;
