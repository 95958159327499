import axios from "axios";

export class FuelMonitoringService {
    getThings = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-items", payload);
        return res.data;
    };
    getDailySummeryData = async (serviceID, payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-daily-history", payload);
        return res.data;
    };
    getFuelLevelTrends = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-fuel-data", payload);
        return res.data;
    };
    getSessionsHistry = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-sessions-history", payload);
        return res.data;
    };
    getSessionData = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/docdb/" + serviceID + "/nq-sessions-history", payload);
        return res.data;
    };
    getCurrentFuelData = async (serviceID) => {
        const payload = { serviceId: serviceID };
        const res = await axios.post(process.env.REACT_APP_ANALYTICS_SERVICE_URL + "/get-current-fueldata", payload);
        return res.data;
    };
    getFuelingHistory = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-fuelling-history", payload);
        return res.data;
    };
    getFuelData = async (serviceId, payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-daily-history", payload);
        return res.data;
    };
}
