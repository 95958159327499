import axios from "axios"


const visaSupplierMatching = async (data) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    let res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/" + "visasuppliermatching", data, {headers: headers}).catch(err=> {return err}   )
    return res.data
}

const service = {
    visaSupplierMatching
}

export default service