import React, { Component } from "react";
import moment from "moment";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import { FuelSrvice } from "../../../service/FuelService";

const getLineChartOptions = () => {
    return {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    pointStyle: "circle",
                    color: "#000000",
                },
            }
        },
        scales: {
            x: {
                ticks: {
                    color: "#000000",
                },
                grid: {
                    color: "#FFFFFF",
                },
            },
            y: {
                ticks: {
                    precision: 0,
                    color: "#000000",
                },
                grid: {
                    color: "#dedede",
                },
            }
        }
    }
}
class FuelTrend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedThing: null, 
            data: {},
            dateRange: null,
        }
        this.fuelService = new FuelSrvice();
        this.lineChartOptions = getLineChartOptions();
    };
        
    componentDidMount = () => {
        let current_date = moment().format("YYYY-MM-DD");
        let previous_date = moment().subtract(6, "days").format("YYYY-MM-DD");
        
        this.setState({ 
            selectedThing: this.props.things[0]
        }, () => this.setReport(this.state.selectedThing, current_date, previous_date));
    }

    setReport = (thingName, current_date, previous_date) => {
        let chartData;
        this.fuelService.getFuelTrend(this.props.serviceId, thingName, current_date, previous_date).then((res) => {
            let data = res.sort((a, b) => new Date(a.ts) - new Date(b.ts));
            chartData = {
                chart: {
                    zoomType: "x",
                    backgroundColor: 'transparent',
                },
                title: {
                    text: "Fuel Level (Ltrs) Trend",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    type: "datetime",
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: '#86969F'
                        }
                    }
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        borderRadius: 5,
                        pointWidth: 9,
                        borderWidth: 0,
                    },
                },
            };
            let fuelLevelData = [];
            let siteTempData = [];
            let pubApiTempData = [];
            let ignitionData = [];
            data.forEach((item) => {
                fuelLevelData.push([moment(item.ts).valueOf(), item.fuelLevelCalibrated]);
                siteTempData.push([moment(item.ts).valueOf(), item.lls1temperature]);
                pubApiTempData.push([moment(item.ts).valueOf(), item.lls2fuellevel]);
                ignitionData.push([moment(item.ts).valueOf(), item.ignition * 100]);
            });
            chartData.series = [
                {
                    name: "Fuel Level",
                    data: fuelLevelData,
                    color: '#EFC000'
                },
                {
                    name: "Site Temperature",
                    data: siteTempData,
                    color: '#0070EF'
                },
                {
                    name: "Public API Temperature",
                    data: pubApiTempData,
                    color: '#00AB31'
                },
                {
                    name: "Ignition Status",
                    data: ignitionData,
                    color: '#AB000B'
                }
            ];
            this.setState({ data: chartData, dateRange: [new Date(previous_date), new Date(current_date)] })
        });
    }

    handelDateChange = (e) => {
        this.setState({ dateRange: e.value }, () => {
            if(this.state.dateRange[1] !== null) {
                this.setReport(this.state.selectedThing.name, moment(this.state.dateRange[1]).format("YYYY-MM-DD"), moment(this.state.dateRange[0]).format("YYYY-MM-DD"));
            }
        })
    }

    handelThingChange = (e) => {
        let current_date = moment(this.state.dateRange[1]).format("YYYY-MM-DD");
        let previous_date = moment(this.state.dateRange[0]).format("YYYY-MM-DD");
        this.setState({ 
            selectedThing: e.value 
        }, () => this.setReport(this.state.selectedThing.name, current_date, previous_date));
    }

    render = () => {
        return (
            <div className="grid">
                <div className="col-12 lg:col-3">
                    <Calendar id="range" value={this.state.dateRange} onChange={(e) => this.handelDateChange(e)} selectionMode="range" readOnlyInput className="w-full" />
                </div>
                <div className="col-12 lg:col-3">
                    <Dropdown value={this.state.selectedThing} options={this.props.things} onChange={this.handelThingChange} optionLabel="name" placeholder="Select a Thing" className="w-full" />
                </div>
                <div className="col-12">
                    <HighchartsReact highcharts={Highcharts} options={this.state.data} />
                </div>
            </div>
        )
    }
}

export default FuelTrend;