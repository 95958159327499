import React, { Component } from "react";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { FuelSrvice } from "../../service/FuelService";

class FuelingEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: null,
            selectedThing: null,
            things: null,
            siteIds: null,
            selectedSiteId: null,
            fuelingEventData: null,
            serviceId: null,
            eventTypes: null,
            selectedEventType: null,
        };
        this.fuelService = new FuelSrvice();
        this.handelFulingDate = this.handelFulingDate.bind(this);
        this.handelFuelVolume = this.handelFuelVolume.bind(this);
    }
    getDates = () => {
        if (this.state.dateRange[1] !== null) {
            let previous_date = moment(this.state.dateRange[0]).format("YYYY-MM-DD");
            let current_date = moment(this.state.dateRange[1]).format("YYYY-MM-DD");
            return {startDay: previous_date, endDay: current_date};
        }
        return {startDay: null, endDay: null};
    }

    getThings = () => {
        if(this.state.selectedThing) {
            return this.state.selectedThing.map((item) => item.name);
        }
        else return [];
    }

    getSites = () => {
        if(this.state.selectedSiteId) {
            return this.state.selectedSiteId.map((item) => item.code);
        }
        else return [];
    }
    
    getData = (values) => {
        if(values) {
            return values.map((item) => item.code);
        }
        else return [];
    }

    componentDidMount = () => {
        let current_date = moment().format("YYYY-MM-DD");
        let previous_date = moment().subtract(30, "days").format("YYYY-MM-DD");
       
        const serviceID = localStorage.getItem("serviceId");
        let eventTypes = [
            { name: "fuelling", code: "fuelling" },
            { name: "defuelling", code: "defuelling" },
        ];

        Promise.all([this.fuelService.getFuelAssetThings(serviceID), this.fuelService.getFuelAssetSiteId(serviceID)]).then((res) => {
           
            let things = [];
            let initialThings = [];
            let initialSiteIds = [];
            res[0].forEach((item) => {
                things.push({ name: item.thingName, code: item.thingName });
                initialThings.push(item.thingName);
            });
            let siteIds = [];
            res[1].forEach((item) => {
                siteIds.push({ name: item.location.siteName, code: item.location.siteId });

                initialSiteIds.push(item.location.siteId);
            });
            this.setState({ selectedThing: things, things, serviceId: serviceID, siteIds, selectedSiteId: siteIds, dateRange: [new Date(previous_date), new Date(current_date)],
            eventTypes, selectedEventType: eventTypes }, () => {
                
                const payload = {
                    filter: { time: { $gt: previous_date, $lt: current_date } },
                };
                this.getFuelData(serviceID, payload);
            });
        });

        this.getFuelData = (serviceID, payload) => {
            this.fuelService.getFuelingEvents(serviceID, payload).then((res) => {

                this.setState({ fuelingEventData: res });
            });
        };
    };
    handelDateChange = (e) => {
        this.setState({ dateRange: e.value }, () => {
            let { startDay, endDay} = this.getDates();
            
                const payload = {
                    filter: { thingName: { $in: this.getData(this.state.selectedThing) }, time: { $gt: startDay, $lt: endDay } },
                };
                this.getFuelData(this.state.serviceId, payload);
        });
    };

    handelThingChange = (e) => {
        let { startDay, endDay} = this.getDates();

        const payload = {
            filter: { thingName: { $in: this.getData(e.value) }, time: { $gt: startDay, $lt: endDay } },
        };
        this.setState({ selectedThing: e.value }, () => this.getFuelData(this.state.serviceId, payload));
    };

    handleSiteChange = (e) => {
        let { startDay, endDay} = this.getDates();

        const payload = {
            filter: { siteId: { $in: this.getData(e.value) }, time: { $gt: startDay, $lt: endDay } },
        };
        this.setState({ selectedSiteId: e.value }, () => this.getFuelData(this.state.serviceId, payload));
    };

    handelFuelVolume = (item) => {
        return parseFloat(item.volume).toFixed(2);
    };

    handelFulingDate = (item) => {
        return moment(item.time, "YYYY-MM-DD").format("MMM D, YYYY");
    };

    handleEventChange = (e) => {
        let { startDay, endDay} = this.getDates();
        
        const payload = {
            filter: { 'fuelling/defuelling': { $in: this.getData(e.value) }, 'thingName': {$in: this.getData(this.state.selectedThing)}, time: { $gt: startDay, $lt: endDay } },
        };
        this.setState({ selectedEventType: e.value }, () => this.getFuelData(this.state.serviceId, payload));
    }
    render = () => {
        let headerGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Date & Time" className=" bg-primary"></Column>
                    <Column header="Thing Name" className=" bg-primary"></Column>
                    <Column header="Site ID" className=" bg-primary"></Column>
                    <Column header="Site Name" className=" bg-primary"></Column>
                    <Column header="Fueling / De-Fueling" className=" bg-primary"></Column>
                    <Column header="Volume (Ltr)" className=" bg-primary"></Column>
                </Row>
            </ColumnGroup>
        );
        return (
            <div className="grid">
                <div className="card">
                    <div className="col-12">
                        <h5>Fuelling Events</h5>
                        <div className="grid">
                            <div className="col-12 lg:col-3">
                                <Calendar id="range" value={this.state.dateRange} onChange={(e) => this.handelDateChange(e)} selectionMode="range" readOnlyInput className="w-full" />
                            </div>
                            <div className="col-12 lg:col-3">
                                <MultiSelect
                                    className="w-full"
                                    value={this.state.selectedThing}
                                    options={this.state.things}
                                    onChange={(e) => this.handelThingChange(e)}
                                    optionLabel="name"
                                    placeholder="Select a Thing"
                                    // maxSelectedLabels={3}
                                />
                            </div>
                            <div className="col-12 lg:col-3">
                                <MultiSelect
                                    className="w-full"
                                    value={this.state.selectedSiteId}
                                    options={this.state.siteIds}
                                    onChange={(e) => this.handleSiteChange(e)}
                                    optionLabel="name"
                                    placeholder="Select a Site"
                                    // maxSelectedLabels={3}
                                />
                            </div>
                            <div className="col-12 lg:col-3">
                                <MultiSelect
                                    className="w-full"
                                    value={this.state.selectedEventType}
                                    options={this.state.eventTypes}
                                    onChange={(e) => this.handleEventChange(e)}
                                    optionLabel="name"
                                    placeholder="Select Fuel Event"
                                    // maxSelectedLabels={3}
                                />
                            </div>
                            <div className="col-12" style={{ maxHeight: "400px", overflowY: "auto" }}>
                                {this.state.fuelingEventData ? (
                                    <DataTable headerColumnGroup={headerGroup} value={this.state.fuelingEventData} responsiveLayout="scroll" showGridlines stripedRows>
                                        <Column body={this.handelFulingDate} className="white-space-nowrap"></Column>
                                        <Column field="thingName" className="white-space-nowrap"></Column>
                                        <Column field="siteId" className="white-space-nowrap"></Column>
                                        <Column field="siteName" className="white-space-nowrap"></Column>
                                        <Column field="fuelling/defuelling" className="white-space-nowrap"></Column>
                                        <Column body={this.handelFuelVolume} className="white-space-nowrap"></Column>
                                    </DataTable>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default FuelingEvents;
