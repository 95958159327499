import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import configService from "../service/configService";

function AddCustomer(props) {
    const [file, setFile] = useState();
    const [country, setCountry] = useState([]);
    const [oporatingCountry, setoporatingCountry] = useState([]);
    const [state, setstate] = useState([]);
    const [city, setcity] = useState([]);
    const [zip, setzip] = useState([]);
    const [formValue, setFormValue] = useState({
        CustomerId: "",
        SecretKey: "",
        Service: "",
        ServiceBlock: "",
        Region: "",
        Descriptor: "",
        Report: "",
        Domain: "",
        CustomerImage: "",
        GatewayMake: "",
        GatewayModel: "",
        SensorMake: "",
        SensorModel: "",
        SensorFrequency: 0.5,
        SensorDataAttribute: "Aggregate",
        SensorParameterName: "obd",
        Address: "",
        OperatingCountry: null,
        State: null,
        City: null,
        Zip: null,
    });
    const [fileName, setFileName] = useState("");

    useEffect(() => {
        Promise.all([configService.getCountryList(), configService.getRegion()]).then((res) => {
            let country = res[0].map((item) => {
                return {
                    name: item.toUpperCase(),
                    code: item,
                };
            });
            let region = res[1].responseData.map((item) => {
                return {
                    name: item.countryName,
                    code: item.country,
                };
            });
            setCountry(region);
            setoporatingCountry(country);
        });
    }, []);

    useEffect(() => {
        if (formValue.OperatingCountry) {
            let payload = { country: formValue.OperatingCountry.code };
            configService.getStateListrow(payload).then((res) => {
                let state = res.map((item) => {
                    return {
                        name: item.toUpperCase(),
                        code: item,
                    };
                });
                setstate(state);
            });
        }
    }, [formValue.OperatingCountry]);

    useEffect(() => {
        if (formValue.State) {
            let payload = { states: [formValue.State.code] };
            configService.getCitiesListrow(payload).then((res) => {
                let city = res.map((item) => {
                    return {
                        name: item.toUpperCase(),
                        code: item,
                    };
                });
                setcity(city);
            });
        }
    }, [formValue.State]);

    useEffect(() => {
        if (formValue.City) {
            let payload = {
                cities: [formValue.City.code],
            };
            configService.getPincodesrow(payload).then((res) => {
                let zip = res.map((item) => {
                    return {
                        name: item,
                        code: item,
                    };
                });
                setzip(zip);
            });
        }
    }, [formValue.City]);

    const serviceBlock = [
        { name: "Item", code: "Item" },
        { name: "Space", code: "Space" },
        { name: "Fleet", code: "Fleet" },
        { name: "Object", code: "Object" },
    ];

    const handleChange = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append("customerId", formValue.CustomerId);
        formData.append("serviceId", "");
        formData.append("region", formValue.Region.code.toLowerCase());
        formData.append("isDomainNameUsersAllowed", (formValue.Service.id === 5 ? true: false));
        formData.append("domainName", formValue.Domain);
        formData.append("secret_key", formValue.SecretKey);
        formData.append("descriptor", formValue.Descriptor);
        formData.append("reportType", formValue.Report);
        formData.append("serviceBlock", formValue.ServiceBlock.name.toLowerCase());
        formData.append("serviceTypeName", formValue.Service.name);
        formData.append("isAdmin", true);
        formData.append("images", file);
        formData.append(
            "location",
            JSON.stringify({
                zip: formValue.Zip.code,
                operatingCountry: formValue.OperatingCountry.code,
                city: formValue.City.code,
                state: formValue.State.code,
                address1: formValue.Address,
            })
        );

        if (formValue.ServiceBlock.name === "Fleet") {
            formData.append("gateway", JSON.stringify({ make: formValue.GatewayMake, model: formValue.GatewayModel }));

            formData.append(
                "sensors",
                JSON.stringify([
                    {
                        make: formValue.SensorMake,
                        model: formValue.SensorModel,
                        freq: formValue.SensorFrequency,
                        dataAttribute: formValue.SensorDataAttribute,
                        parameterName: formValue.SensorParameterName,
                    },
                ])
            );
            formData.append("type", "obd");
            formData.append("parserPort", 5003.0);
        }
        props.handelAddCustomer(formData);
    };

    const handleNext = () => {
        props.nextPage();
    };

    function showHideComponent() {
        switch (formValue.ServiceBlock.name) {
            case "Fleet":
                return (
                    <>
                        <div className="field m-0 md:col-6 col-12 mb-3">
                            <label htmlFor="Gateway Make" className="mt-1">
                                Gateway Make
                            </label>
                            <InputText id="username" className="w-full" placeholder="Gateway Make" name="GatewayMake" value={formValue.GatewayMake} onChange={handleChange} />
                        </div>
                        <div className="field m-0 md:col-6 col-12 mb-3">
                            <label htmlFor="Gateway Model" className="mt-1">
                                Gateway Model
                            </label>
                            <InputText id="username" className="w-full" placeholder="Gateway Model" name="GatewayModel" value={formValue.GatewayModel} onChange={handleChange} />
                        </div>
                        <div className="field m-0 md:col-6 col-12 mb-3">
                            <label htmlFor="Sensor Make" className="mt-1">
                                Sensor Make
                            </label>
                            <InputText id="username" className="w-full" placeholder="Sensor Make" name="SensorMake" value={formValue.SensorMake} onChange={handleChange} />
                        </div>
                        <div className="field m-0 md:col-6 col-12 mb-3">
                            <label htmlFor="Sensor Model" className="mt-1">
                                Sensor Model
                            </label>
                            <InputText id="username" className="w-full" placeholder="Sensor Model" name="SensorModel" value={formValue.SensorModel} onChange={handleChange} />
                        </div>
                        <div className="field m-0 md:col-6 col-12 mb-3">
                            <label htmlFor="Sensor Frequency" className="mt-1">
                                Sensor Frequency
                            </label>
                            <InputText id="username" className="w-full" placeholder="Sensor Frequency" name="SensorFrequency" value={formValue.SensorFrequency} onChange={handleChange} type="number" />
                        </div>
                        <div className="field m-0 md:col-6 col-12 mb-3">
                            <label htmlFor="Sensor Data Attribute" className="mt-1">
                                Sensor Data Attribute
                            </label>
                            <InputText id="username" className="w-full" placeholder="Sensor Data Attribute" name="SensorDataAttribute" value={formValue.SensorDataAttribute} onChange={handleChange} />
                        </div>
                        <div className="field m-0 md:col-6 col-12 mb-3">
                            <label htmlFor="sensor parameter name" className="mt-1">
                                Sensor Parameter Name
                            </label>
                            <InputText id="username" className="w-full" placeholder="Sensor Parameter Name" name="SensorParameterName" value={formValue.SensorParameterName} onChange={handleChange} />
                        </div>
                    </>
                );

            default:
                return null;
        }
    }

    const handleFile = (event) => {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    };

    return (
        <>
            <div className="grid m-0">
                <div className="md:col-6 col-offset-3 col-12">
                    <div className="card">
                        <div className="formgrid grid">
                            <div className="field m-0 md:col-6 col-12 mb-3">
                                <label htmlFor="Enter the customer Id" className="mt-1">
                                    Enter the customer Id*
                                </label>
                                <InputText id="username" className="w-full" placeholder="Customer Id" name="CustomerId" value={formValue.CustomerId} onChange={handleChange} />
                                <small id="username-help" style={{ color: "red" }}>
                                    Please enter customerId with 8 alphanumeric characters
                                </small>
                            </div>
                            <div className="field m-0 md:col-6 col-12 mb-3">
                                <label htmlFor="Enter the secret key" className="mt-1">
                                    Enter the secret key*
                                </label>
                                <InputText id="username" className="w-full" placeholder="Secret Key" name="SecretKey" value={formValue.SecretKey} onChange={handleChange} />
                            </div>
                            <div className="field mt-1 md:col-6 col-12 mb-3">
                                <label htmlFor="Service Name Type">Service Block*</label>
                                <Dropdown value={formValue.ServiceBlock} options={serviceBlock} onChange={handleChange} className="w-full" optionLabel="name" placeholder="Select Service Block" name="ServiceBlock" />
                            </div>

                            <div className="field m-0 md:col-6 col-12 mb-3">
                                <label htmlFor="Service Name Type" className="mt-1">
                                    Service Type Name*
                                </label>
                                <Dropdown value={formValue.Service} options={props.serviceTypeNames} onChange={handleChange} className="w-full" optionLabel="name" placeholder="Select Service" name="Service" />
                            </div>

                            <div className="field m-0 md:col-6 col-12 mb-3">
                                <label htmlFor="Select a region" className="mt-1">
                                    Select a region*
                                </label>
                                <Dropdown value={formValue.Region} options={country} onChange={handleChange} className="w-full" optionLabel="name" filter filterBy="name" placeholder="Select Region" name="Region" />
                            </div>
                            <div className="field m-0 md:col-6 col-12 mb-3">
                                <label htmlFor="Enter a serviceId descriptor" className="mt-1">
                                    Enter a serviceId descriptor
                                </label>
                                <InputText id="username" placeholder="Descriptor" className="w-full" name="Descriptor" value={formValue.Descriptor} onChange={handleChange} />
                            </div>
                            <div className="field m-0 md:col-6 col-12 mb-3">
                                <label htmlFor="Report Type" className="mt-1">
                                    Report Type
                                </label>
                                <InputText id="username" type="number" name="Report" className="w-full" value={formValue.Report} onChange={handleChange} />
                            </div>
                            <div className="field m-0 md:col-6 col-12 mb-3">
                                <label htmlFor="Enter a domain name" className="mt-1">
                                    Enter a domain name
                                </label>
                                <InputText id="username" placeholder="Domain Name" className="w-full" name="Domain" value={formValue.Domain} onChange={handleChange} />
                            </div>
                            <div className="field m-0 md:col-6 col-12 mb-3">
                                <label htmlFor="Address" className="mt-1">
                                    Address
                                </label>
                                <InputText id="username" className="w-full" placeholder="Address" name="Address" value={formValue.Address} onChange={handleChange} />
                            </div>
                            <div className="field m-0 md:col-6 col-12 mb-3">
                                <label htmlFor="Operating Country" className="mt-1">
                                    Operating Country
                                </label>
                                <Dropdown value={formValue.OperatingCountry} options={oporatingCountry} onChange={handleChange} className="w-full" optionLabel="name" placeholder="Select Country" name="OperatingCountry" />
                            </div>
                            <div className="field m-0 md:col-6 col-12 mb-3">
                                <label htmlFor="state" className="mt-1">
                                    State
                                </label>
                                <Dropdown value={formValue.State} options={state} onChange={handleChange} className="w-full" optionLabel="name" placeholder="Select State" name="State" />
                            </div>
                            <div className="field m-0 md:col-6 col-12 mb-3">
                                <label htmlFor="city" className="mt-1">
                                    City
                                </label>
                                <Dropdown value={formValue.City} options={city} onChange={handleChange} className="w-full" optionLabel="name" placeholder="Select City" name="City" />
                            </div>
                            <div className="field m-0 md:col-6 col-12 mb-3">
                                <label htmlFor="Zip" className="mt-1">
                                    ZipCode
                                </label>
                                <Dropdown value={formValue.Zip} options={zip} onChange={handleChange} className="w-full" optionLabel="name" placeholder="Select Zip" name="Zip" />
                            </div>
                            {showHideComponent()}
                            <div className="field m-0 md:col-6 col-12">
                                <label htmlFor="Select Customer Images" className="mt-1">
                                    Select Customer Images*
                                </label>
                                <InputText id="username" type="file" className="w-full" name="CustomerImage" onChange={handleFile} accept="image/png, image/jpeg, image/jpg" />
                            </div>
                            <div className="field m-0 md:col-12 col-12 flex justify-content-end">
                                <span className="mt-1">
                                    {fileName !== "" && formValue.CustomerId !== "" && formValue.SecretKey !== "" && formValue.ServiceBlock !== "" && formValue.Service !== "" && formValue.Region !== "" ? (
                                        <Button label="Create" className="p-button-rounded p-button-success" onClick={handleSubmit} />
                                    ) : (
                                        <Button label="Create" className="p-button-rounded p-button-success" onClick={handleSubmit} disabled />
                                    )}

                                    <Button label="Next" className="p-button-rounded p-button-warning" onClick={handleNext} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddCustomer;
