import React, { useEffect, useState, useRef } from 'react'
import writeXlsxFile from 'write-excel-file'
import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { VehicleSrvice } from "../../service/VehicleService"
import { Toast } from "primereact/toast"
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Image } from 'primereact/image'
import { FuelMonitoringService } from '../../service/FuelMonitoringService'
import { useSelector } from 'react-redux'

const reportType = [{
    name: "Day Wise Report",
    code: "dwr",
    previewImage : "day_wise_report.jpg"
}, {
    name: "Engine Report",
    code: "er",
    previewImage: "engine_report.jpg"
}, {
    name: "Events Report",
    code: "evtr",
    previewImage: "events_report.jpg"
}]

const VTFreport = ({serviceType, reportsAccess}) => {
    const [dateRange, setDateRange] = useState({})
    const [reportName, setReportName] = useState()
    const [vehicleName, setVehicleName] = useState({})
    const [disableBtn, setDisableBtn] = useState(false)
    const [reports, setReports] = useState(null)
    const vehicleService = new VehicleSrvice();
    const fuelMonService = new FuelMonitoringService()
    const serviceId = localStorage.getItem('serviceId')
    const fleetsMap = useRef({})
    const toast = useRef()
    const serviceBlock = JSON.parse(localStorage.getItem("service")).serviceBlock === "item" ? "thingName" : "fleetName"
    const thingNames = useSelector(state => state.thingNameContainer);
    let thingArr=thingNames.length > 0?thingNames[thingNames.length-1]: [];

    let maxDateMap = {
        "er" : moment(dateRange.start).add(1, "months").format("YYYY-MM-DD"),
        "evtr" : moment(dateRange.start).add(7, "days").format("YYYY-MM-DD"),
        "dwr" : moment(dateRange.start).add(1, "months").format("YYYY-MM-DD")
    }

    //TO DO : SHOULD ADD CHECK FOR DATA ALREADY FETCHED FOR A DATE RANGE AND REPORT TYPE
    
    useEffect(() => {
        let reportsArr
        if (reportsAccess) reportsArr = reportType.filter((item) => reportsAccess?.includes(item.code))
        else reportsArr = reportType

        setReports(reportsArr)
        setReportName(reportsArr[0])
        if(serviceType === "Fuel Tracking"){
            fuelMonService.getThings(serviceId, {}).then((res) => {
                res.forEach(item => {
                    fleetsMap.current[item.thingName] = item
                })
            })
        }
        else {
            vehicleService.getVehicleNumber(/*"hns8wlk53d_irise"*/ serviceId,{}).then((res) => {
                res.forEach(item => {
                    fleetsMap.current[item.fleetName] = item
                })
            })
        }
    }, [])

    const downloadReport = async () => {
        if(!dateRange.start || !dateRange.end || !reportName || (reportName.code === "evtr" && (serviceType !== "Fuel Tracking" && !vehicleName[serviceBlock]))){
            toast.current.show({ severity: 'warn', summary: 'Missing Field', detail: "Please Select All fields" });
            return    
        }
        let header = []
        let arr = []
        let srno = 1
        setDisableBtn(true)
        let reportData = await fetchReportData()
        if (reportData && reportData.length) {
            if (reportName.code === "dwr") {
                let occurCount = countOccurences(reportData)
                let valueObj = {}
                header = [[
                    {
                        value: 'General Parameters',
                        fontWeight: 'bold',
                        span: 4,
                        align: "center",
                        backgroundColor : "#C0C0C0",
                        borderColor : "#000000"
                    }, null, null, null, {
                        value: 'Movement and operation',
                        fontWeight: 'bold',
                        span: 7,
                        align: "center",
                        backgroundColor : "#C0C0C0",
                        borderColor : "#000000"
                    }, null, null, null, null, null, null, {
                        value: 'Fuel (main tank)',
                        fontWeight: 'bold',
                        span: 6,
                        align: "center",
                        backgroundColor : "#C0C0C0",
                        borderColor : "#000000"
                    }, null, null, null, null, null
                ], [{
                    value: 'Sr No',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Date',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Vehicle name',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Group of vehicles',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                },
                {
                    value: 'Last Odometer Reading',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                },
                {
                    value: 'HMR',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                },
                 {
                    value: 'Mileage, km',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                },
                 {
                    value: 'Engine operation time, hh:mm:ss',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Engine operation time, % of the report period',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Engine operation idle time, hh:mm:ss',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Engine operation idle time, % of the report period',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Time with engine switched off, hour:min:sec',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Time with engine switched off, % of the report period',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Initial volume, l',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Final volume, l',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Actual fuel consumption, l',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Refueling volume, l',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Draining volume, l',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Actual fuel consumption per one hour of engine operation, l',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }
                ]]
                reportData.forEach((item, index) => {
                    let temp = []

                    let last = occurCount[item.date] === 1 ? true : false

                    if (index > 0) {
                        let cond = item.date === reportData[index - 1].date
                        let cond1 = index + 1 == reportData.length || (item.date !== reportData[index + 1].date)

                        //condition for last item of same date
                        if (cond1 && occurCount[item.date] > 1) {
                            last = true
                        }
                        //condition for first item of a date
                        if (!cond) {
                            temp.push({ value: srno, alignVertical: "center", rowSpan: occurCount[item.date] + 1, borderColor:"#000000"},
                                { value: moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"), alignVertical: "center", rowSpan: occurCount[item.date] + 1, borderColor:"#000000" })
                            srno++
                        }
                        else {
                            temp.push(null, null)
                        }
                    }
                    else if (index === 0) {
                        temp.push({ value: srno, alignVertical: "center", rowSpan: occurCount[item.date] + 1, borderColor:"#000000" },
                            { value: moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"), alignVertical: "center", rowSpan: occurCount[item.date] + 1, borderColor:"#000000" })
                        srno++
                    }
                    temp.push({
                        value: fleetsMap.current[item.thingName].alias,
                        borderColor:"#000000"
                    }, {
                        value: fleetsMap.current[item.thingName].location.siteName,
                        borderColor:"#000000"
                    },
                    {
                        value: item.lastodometer ?? 0,
                        borderColor:"#000000"
                    },
                    {
                        value: item.hmr ?? 0,
                        borderColor:"#000000"
                    },
                     {
                        value: item.totalDistanceTravelled ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: timeTemplate(item.totalrunhours) ?? timeTemplate(0),
                        borderColor:"#000000"
                    }, {
                        value: item.runtime_percent ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: timeTemplate(item.totalidletime) ?? timeTemplate(0),
                        borderColor:"#000000"
                    }, {
                        value: item.idletime_percent ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: timeTemplate(item.offtime) ?? timeTemplate(0),
                        borderColor:"#000000"
                    }, {
                        value: item.offtime_percent ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: item.openingfuel ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: item.endingfuel ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: item.fuelConsumed ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: item.fuellingAmount ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: item.defuellingAmount ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: item.fuelEff_lphr ?? 0,
                        borderColor:"#000000"
                    })

                    valueObj = {
                        totalDistanceTravelled: (valueObj["totalDistanceTravelled"] || 0) + (item.totalDistanceTravelled ?? 0),
                        totalrunhours: (valueObj["totalrunhours"] || 0) + (item.totalrunhours ?? 0),
                        runtime_percent: (valueObj["runtime_percent"] || 0) + (item.runtime_percent ?? 0),
                        totalidletime: (valueObj["totalidletime"] || 0) + (item.totalidletime ?? 0),
                        idletime_percent: (valueObj["idletime_percent"] || 0) + (item.idletime_percent ?? 0),
                        offtime: (valueObj["offtime"] || 0) + (item.offtime ?? 0),
                        offtime_percent: (valueObj["offtime_percent"] || 0) + (item.offtime_percent ?? 0),
                        openingfuel: (valueObj["openingfuel"] || 0) + (item.openingfuel ?? 0),
                        endingfuel: (valueObj["endingfuel"] || 0) + (item.endingfuel ?? 0),
                        fuelConsumed: (valueObj["fuelConsumed"] || 0) + (item.fuelConsumed ?? 0),
                        fuellingAmount: (valueObj["fuellingAmount"] || 0) + (item.fuellingAmount ?? 0),
                        defuellingAmount: (valueObj["defuellingAmount"] || 0) + (item.defuellingAmount ?? 0),
                        fuelEff_lphr: (valueObj["fuelEff_lphr"] || 0) + (item.fuelEff_lphr ?? 0),
                    }
                    arr.push(temp)
                    if (last) {
                        arr.push([null, null, {
                            value: "Total", span: 2, backgroundColor:"#FFFF00",
                            borderColor:"#000000"
                        }, null,
                        {
                            value: "--", backgroundColor:"#FFFF00",
                            borderColor:"#000000"
                        },
                        {
                            value: "--", backgroundColor:"#FFFF00",
                            borderColor:"#000000"
                        },
                            {
                                value: valueObj.totalDistanceTravelled, backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: timeTemplate(valueObj.totalrunhours), backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: (valueObj.runtime_percent / occurCount[item.date]), backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: timeTemplate(valueObj.totalidletime), backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: (valueObj.idletime_percent / occurCount[item.date]), backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: timeTemplate(valueObj.offtime), backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: (valueObj.offtime_percent / occurCount[item.date]), backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: "--", backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: "--", backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: valueObj.fuelConsumed, backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: valueObj.fuellingAmount, backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: valueObj.defuellingAmount, backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: valueObj.fuelEff_lphr, backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            },
                             ])
                        valueObj = {}
                    }
                })
            }
            else if (reportName.code === "er") {
                let occurCount = countOccurences(reportData)
                let valueObj = {}
                header = [[
                    {
                        value: 'General Parameters',
                        fontWeight: 'bold',
                        span: 4,
                        align: "center",
                         backgroundColor : "#C0C0C0",
                         borderColor : "#000000"
                    }, null, null, null, {
                        value: 'Movement and operation',
                        fontWeight: 'bold',
                        span: 5,
                        align: "center",
                         backgroundColor : "#C0C0C0",
                         borderColor : "#000000"
                    }, null, null, null, null, {
                        value: 'Fuel (main tank)',
                        fontWeight: 'bold',
                        span: 5,
                        align: "center",
                         backgroundColor : "#C0C0C0",
                         borderColor : "#000000"
                    }, null, null, null, null
                ], [{
                    value: 'Sr No',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Vehicle name',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Date',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Group of vehicles',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Engine hours meter reading',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                },
                {
                    value: 'Last Odometer Reading',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                },
                {
                    value: 'HMR',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                },
                 {
                    value: 'Engine operation time, hh:mm:ss',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Engine operation time, % of the report period',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Time with engine switched off, hour:min:sec',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Time with engine switched off, % of the report period',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Initial volume, l',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Final volume, l',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Actual fuel consumption, l',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Refueling volume, l',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }, {
                    value: 'Draining volume, l',
                    fontWeight: 'bold',
                     backgroundColor : "#C0C0C0",
                     borderColor : "#000000"
                }
                ]]
                reportData.forEach((item, index) => {
                    let temp = []

                    let last = occurCount[item.thingName] === 1 ? true : false

                    if (index > 0) {
                        let cond = item.thingName === reportData[index - 1].thingName
                        let cond1 = index + 1 == reportData.length || (item.thingName !== reportData[index + 1].thingName)

                        //condition for last item of same date
                        if (cond1 && occurCount[item.thingName] > 1) {
                            last = true
                        }
                        //condition for first item of a date
                        if (!cond) {
                            temp.push({ value: srno, alignVertical: "center", rowSpan: occurCount[item.thingName] + 1, borderColor:"#000000" },
                                { value: fleetsMap.current[item.thingName].alias, alignVertical: "center", rowSpan: occurCount[item.thingName] + 1, borderColor:"#000000" })
                            srno++
                        }
                        else {
                            temp.push(null, null)
                        }
                    }
                    else if (index === 0) {
                        temp.push({ value: srno, alignVertical: "center", rowSpan: occurCount[item.thingName] + 1, borderColor:"#000000" },
                            { value: fleetsMap.current[item.thingName].alias, alignVertical: "center", rowSpan: occurCount[item.thingName] + 1, borderColor:"#000000" })
                        srno++
                    }
                    temp.push({
                        value: moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"),
                        borderColor:"#000000"
                    }, {
                        value: fleetsMap.current[item.thingName].location.siteName,
                        borderColor:"#000000"
                    }, {
                        value: item.totalDistanceTravelled ?? 0,
                        borderColor:"#000000"
                    },
                    {
                        value: item.lastodometer,
                        borderColor:"#000000"
                    },
                    {
                        value: item.hmr??0,
                        borderColor:"#000000"
                    }, {
                        value: timeTemplate(item.totalrunhours) ?? timeTemplate(0),
                        borderColor:"#000000"
                    }, {
                        value: item.runtime_percent ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: timeTemplate(item.offtime) ?? timeTemplate(0),
                        borderColor:"#000000"
                    }, {
                        value: item.offtime_percent ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: item.openingfuel ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: item.endingfuel ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: item.fuelConsumed ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: item.fuellingAmount ?? 0,
                        borderColor:"#000000"
                    }, {
                        value: item.defuellingAmount ?? 0,
                        borderColor:"#000000"
                    })

                    valueObj = {
                        totalodometer:item.lastodometer ,
                        totalrunhours: (valueObj["totalrunhours"] || 0) + (item.totalrunhours ?? 0),
                        runtime_percent: (valueObj["runtime_percent"] || 0) + (item.runtime_percent ?? 0),
                        offtime: (valueObj["offtime"] || 0) + (item.offtime ?? 0),
                        offtime_percent: (valueObj["offtime_percent"] || 0) + (item.offtime_percent ?? 0),
                        openingfuel: (valueObj["openingfuel"] || 0) + (item.openingfuel ?? 0),
                        endingfuel: (valueObj["endingfuel"] || 0) + (item.endingfuel ?? 0),
                        fuelConsumed: (valueObj["fuelConsumed"] || 0) + (item.fuelConsumed ?? 0),
                        fuellingAmount: (valueObj["fuellingAmount"] || 0) + (item.fuellingAmount ?? 0),
                        defuellingAmount: (valueObj["defuellingAmount"] || 0) + (item.defuellingAmount ?? 0),
                    }
                    arr.push(temp)
                    if (last) {
                        arr.push([null, null, {
                            value: "Total", span: 2, backgroundColor:"#FFFF00" ,
                            borderColor:"#000000"
                        }, null, {
                                value: "-", backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            },
                            {
                                value: valueObj.totalodometer, backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            },
                            {
                                value: "-", backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            },
                             {
                                value: timeTemplate(valueObj.totalrunhours), backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: (valueObj.runtime_percent / occurCount[item.thingName]), backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: timeTemplate(valueObj.offtime), backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: (valueObj.offtime_percent / occurCount[item.thingName]), backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: "--", backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: "--", backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: valueObj.fuelConsumed, backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: valueObj.fuellingAmount, backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }, {
                                value: valueObj.defuellingAmount, backgroundColor:"#FFFF00",
                                borderColor:"#000000"
                            }])
                        valueObj = {}
                    }
                })
            }
            else{
                header = [[
                    {
                        value: 'Sr No',
                        fontWeight: 'bold',
                        backgroundColor : "#C0C0C0",
                        borderColor : "#000000"
                    },{
                        value: serviceType === "Fuel Tracking" ? 'Site Name' : 'Vehicle Number',
                        fontWeight: 'bold',
                        backgroundColor : "#C0C0C0",
                        borderColor : "#000000"
                    },{
                        value: 'Date',
                        fontWeight: 'bold',
                        backgroundColor : "#C0C0C0",
                        borderColor : "#000000"
                    },{
                        value: 'Start time',
                        fontWeight: 'bold',
                        backgroundColor : "#C0C0C0",
                        borderColor : "#000000"
                    },
                    {
                        value: 'End time',
                        fontWeight: 'bold',
                        backgroundColor : "#C0C0C0",
                        borderColor : "#000000"
                    },
                    {
                        value: 'Run Time',
                        fontWeight: 'bold',
                        backgroundColor : "#C0C0C0",
                        borderColor : "#000000"
                    }
                ]]
                if(serviceType!=="Fuel Tracking"){
                    header[0].push({
                        value: 'Idle time',
                        fontWeight: 'bold',
                        backgroundColor : "#C0C0C0",
                        borderColor : "#000000"
                    })
                }
                reportData.forEach((item, index)=>{
                    arr.push([
                        {
                            value: index + 1,
                            borderColor:"#000000"
                        }, {
                            value: fleetsMap.current[item.thingName].alias,
                            borderColor:"#000000"
                        }, {
                            value: moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"),
                            borderColor:"#000000"
                        }, {
                            value: item.startTime,
                            borderColor:"#000000"
                        },{
                            value: item.endTime,
                            borderColor:"#000000"
                        },{
                            value: timeTemplate(item.runDuration),
                            borderColor:"#000000"
                        }
                    ])
                    if(serviceType!=="Fuel Tracking"){
                        arr[index].push({
                            value: timeTemplate(item.idle_time),
                            borderColor:"#000000"
                        })
                    }
                })
            }
        }
        if (!arr.length) {
            toast.current.show({ severity: 'info', summary: 'No Data', detail: "No Data found" });
            setDisableBtn(false)
            return
        }
        let data = [
            ...header,
            ...arr
        ]
        await writeXlsxFile(data, {
        //columns, // (optional) column widths, etc.
        fileName: `${reportName.name}.xlsx`
        })
        setDisableBtn(false)
    }
    const timeTemplate = (value) => {
        if(!value) value = 0 
        var duration = moment.duration(value, "seconds");
        const days = Math.floor(duration.asDays());
        const hours = String(duration.hours()).padStart(2,'0');
        const minutes = String(duration.minutes()).padStart(2,'0');
        const seconds = String(duration.seconds()).padStart(2,'0');
        let formattedTime = `${hours}:${minutes}:${seconds}`
        if(days > 0) formattedTime = `${days} days ${formattedTime}`
        return formattedTime;
    }
    const fetchReportData = async () => {
        if (dateRange.start && dateRange.end && reportName) {
            let startTime = moment(dateRange.start).startOf("day").format("YYYY-MM-DD")
            let endTime = moment(dateRange.end).endOf("day").format("YYYY-MM-DD")
            let sortBy = reportName.code === "dwr" ? {date: 1 } : {thingName: 1, date: 1} 
            let query = {
                operation: "aggregate",
                "aggregate" : [
                    {
                        $match : {
                            date: { $gte: startTime, $lte: endTime }
                        }
                    },
                    {
                        $sort : sortBy
                    }
                ]
            }
            
            let matchObj={
                date : { $gte: startTime, $lte: endTime }
            };
            if(thingArr.length){matchObj["thingName"]={$in:thingArr}}

            let query1 = {
                operation: "aggregate",
                "aggregate" : [
                    {
                        $match :matchObj
                    },
                    {
                        $sort : {
                            thingName : 1,
                            date : 1
                        }
                    },
                    {
                        $project : {
                            thingName: 1,
                            date: 1,
                            _id: 0,
                            startTime : 1,
                            endTime : 1,
                            runDuration : 1,
                            idle_time : 1
                        }
                    }
                ]
            }
            let payload = query;
            if(reportName.code === "evtr") payload = query1
            let res = await vehicleService.getReportData(/*"hns8wlk53d_irise"*/ serviceId, payload, reportName.code)
            return res
        }
    }

    const countOccurences = (res) => {
        let counts = {}
        res.forEach((item) => {
            if (reportName.code === "dwr") {
                if (item.date in counts) {
                    counts[item.date] = counts[item.date] + 1
                }
                else {
                    counts[item.date] = 1
                }
            }
            else if (reportName.code === "er") {
                if (item.thingName in counts) {
                    counts[item.thingName] = counts[item.thingName] + 1
                }
                else {
                    counts[item.thingName] = 1
                }
            }
        })
        return counts
    }

    const basicHeader = (ReportName) => {
        return (<div className="flex align-items-center">
            <i className="pi pi-bookmark fs-xlarge p-mr-3 pink-color"></i>
            <div className="flex flex-column">
                <h5 className="mb-0 ml-2">{ReportName}</h5>
            </div>
        </div>)
    }

    const handleReportNameChange = (e) =>{
        setDateRange({})
        setReportName(e.value)
    }

    return (
        <>
            <div className='col-12 grid justify-content-between align-items-center'>
                <Toast ref={toast}/>
                <div className="field grid col-12 lg:col-5">
                    <div className={(serviceType !== "Fuel Tracking" && reportName?.code === "evtr") ? "col-12 lg:col-6" : "col-12"}>
                        <label htmlFor="minmax" className="block mb-2">
                            Select Report Type
                        </label>
                        <Dropdown value={reportName} options={reports} onChange={(e) => handleReportNameChange(e)} className="bg-white w-full" optionLabel="name" placeholder="Select Report" />
                    </div>
                    {(serviceType !== "Fuel Tracking" && reportName?.code === "evtr") && <div className="lg:col-6 col-12 mt-4 lg:m-0">
                        <label htmlFor="minmax" className="block mb-2">
                            Select Vehicle
                        </label>
                        <Dropdown value={vehicleName} options={Object.values(fleetsMap.current)} onChange={(e) => setVehicleName(e.value)} className="bg-white w-full" optionLabel="alias" filter placeholder="Select Vehicle" />
                    </div>}
                </div>
                <div className="field col-12 lg:col-5 grid">
                    <div className='col-12 lg:col-6'>
                        <label htmlFor="minmax" className="block mb-2">
                            Select Start Date
                        </label>
                        <Calendar value={new Date(dateRange.start)} onChange={(e) => setDateRange({ "start": moment(e.value).format("YYYY-MM-DD") })} minDate={new Date(moment("2024-01-17", "YYYY-MM-DD"))} maxDate={new Date(moment().subtract(1, "days").format("YYYY-MM-DD"))} dateFormat="dd/mm/yy" showIcon className="bg-white w-full" placeholder='Start Date' />
                    </div>
                    <div className='col-12 lg:col-6 mt-3 lg:mt-0' style={{"display": dateRange.start ? "block" : "none" }}>
                        <label htmlFor="minmax" className="block  mb-2">
                            Select End Date
                        </label>
                        <Calendar value={new Date(dateRange.end)} onChange={(e) => setDateRange({ ...dateRange, "end": moment(e.value).format("YYYY-MM-DD") })} minDate={new Date(moment(dateRange.start, "YYYY-MM-DD"))} maxDate={moment(maxDateMap[reportName?.code]).isAfter(moment().subtract(1, "days").format("YYYY-MM-DD")) ? new Date(moment().subtract(1, "days").format("YYYY-MM-DD")) : new Date(maxDateMap[reportName?.code])} dateFormat="dd/mm/yy" showIcon className="bg-white w-full" placeholder='End Date' />
                    </div>
                </div>
                <div className="field col-12 lg:col-2">
                    <div className="col-12 text-right grid justify-content-center">
                        <Button className='lg:mt-5' label="Download" disabled={disableBtn} onClick={() => downloadReport()} icon="pi pi-download" />
                    </div>
                </div>
            </div>
            <div className='col-12 p-5'>
                <h2>Reports Preview</h2>
                {reports && reports.map((item,index) => (
                    <Accordion key={index} className={index !== 0 && "mt-3"}>
                        <AccordionTab header={basicHeader(item.name)}>
                            <div className='col-12 overflow-x-auto'>
                                <Image src={`${process.env.REACT_APP_IMAGE_STATIC_URL}/${serviceId}/${item.previewImage}`} alt={item.name} />
                            </div>
                        </AccordionTab>
                    </Accordion>
                ))}
            </div>
        </>
    )
}

export default VTFreport