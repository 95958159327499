import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { chartHeadingStyle } from "../../utilities/constant";

const WeatherChart = ({ chartData }) => {
    const option = {
        chart: {
            zoomType: "x",
            backgroundColor: "transparent",
        },
        title: {
            text: "",
            style: chartHeadingStyle,
        },
        accessibility: {
            screenReaderSection: {
                beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
            },
        },
        tooltip: {
            valueDecimals: 2,
        },
        xAxis: {
            categories: [],
            labels: {
                style: {
                    color: "#86969F",
                },
            },
        },
        yAxis: {
            min: 0,
            stackLabels: {
                enabled: false,
            },
            labels: {
                style: {
                    color: chartData.yAxixLabelColor || "#86969F",
                },
            },
            title: {
                text: chartData.yAxisTitle || "",
                style: {
                    color: chartData.yAxixLabelColor || "#86969F",
                },
            },
        },
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false,
                },
                pointStart: 0,
                borderRadius: 5,
                pointWidth: 9,
                borderWidth: 0,
            },
        },
        series: [],
    };
    option.series = chartData.data;
    option.xAxis.categories = chartData.category;
    option.title.text = chartData.title;

    return <HighchartsReact highcharts={Highcharts} options={option} />
};

export default WeatherChart;