import React from 'react'
import { Reports } from './reports';
export const DailyReport = () => {

    let daily = ["Vehicle having same tag_id passed under different lanes within 15 seconds", 
         "In TRS report for 'Cash transaction' double fare is charged", 
        "Where return fare is deducted check whether the vehicle is returning within 24hrs", 
         "Cases where vehicle is exempted at one toll and tollable at another toll on the same day", 
        "In TRS report  Single journey and return journey charges are levied basis toll rates", "Rates charged as per the rate list", 
        
        "Penalty check for overweight vehicles (if overweight is being charged)",
        "Vehicle passed under monthly discount pass & discount daily pass having same tag_id but different auditor class",
        "Status and Reason is Discount daily pass And Accepted amount is null or zero",
        "Identify cases where status and reason is accepted And Accepted amount is null or zero"
    ]

    let dailyQueryMap = {
        "Vehicle having same tag_id passed under different lanes within 15 seconds": {
            collection: "nq-sameTagIDvehNos_In15s_daily",
            payload: {
                operation: "aggregate",
                aggregate: [
                    { $match: {} },
                    {
                        $group: {
                            _id: {tagId:"$tagid",date:"$date"},
                            details: { $push: "$$ROOT" }
                        }
                    },
                    {
                        $project: {
                            _id: 0,
                            tagid: "$_id.tagId",
                            oldCategory: { $arrayElemAt: ["$details.vehno", 0] },
                            newCategory: { $arrayElemAt: ["$details.vehno", -1] },
                            oldtrans: { $arrayElemAt: ["$details.dt", 0] },
                            newtrans: { $arrayElemAt: ["$details.dt", -1] },
                            plaza: { $arrayElemAt: ["$details.plazacode", 0] },
                        }
                    },
                    {$sort:{newtrans:-1,tagid:-1}},{$skip:0},{$limit:30},
                ]
            },
            field: ["tagid", "plaza", "oldCategory", "oldtrans", "newCategory", "newtrans"],
            head: ["Tagid", "Plaza", "Vehicle Number1", "Old Time", "Vehicle Number2", "New Time"]
        },

        "In TRS report for 'Cash transaction' double fare is charged": {
            collection: "nq-doubleFareForCash_daily",
            payload: {
                filter: {
                   
                },
                projection: { _id: 0, mvc: 1, paymentmethod: 1, plazacode: 1, amountpaid: 1, vehno: 1, date: 1, transno: 1 },
                "sort":[["date",-1]]
            },
            field: ["vehno", "transno", "mvc", "paymentmethod", "plazacode", "amountpaid", "plazacode", "date"],
            head: ["Vehicle Number", "Transaction number", "Type", "Payment method", "Plaza Code", "Amount Paid", "Plaza", "Date"]
        },

        "Where return fare is deducted check whether the vehicle is returning within 24hrs": {
            collection: "nq-vehReturnFareAfter_24hour_daily",
            payload: {
                filter: {},
                projection: { _id: 0, sjpc: 1, vehno: 1, date: 1, sjdt: 1, rjdt: 1, },
                "sort":[["date",-1]]
            },
            field: ["sjpc", "vehno", "date", "sjdt", "rjdt"],
            head: ["Type", "Vehicle Number", "Date", "Starting Journey Time", "Return Journey Time"]
        },

        "Cases where vehicle is exempted at one toll and tollable at another toll on the same day": {
            collection: "nq-ExempToTollable_daily",
            payload: {
                operation: "aggregate",
                aggregate: [
                    { $match: {} },
                    {
                        $group: {
                            _id: {vehno:"$vehno",date:"$date"},
                            details: { $push: "$$ROOT" }
                        }
                    },
                    {
                        $project: {
                            _id: 0,
                            vehno: "$_id.vehno",
                            oldCategory: { $arrayElemAt: ["$details.plazacode", 0] },
                            newCategory: { $arrayElemAt: ["$details.plazacode", -1] },
                            mvc: { $arrayElemAt: ["$details.mvc", 0] },
                            transno: { $arrayElemAt: ["$details.transno", -1] },
                            oldamountpaid: { $arrayElemAt: ["$details.amountpaid", 0] },
                            newamountpaid: { $arrayElemAt: ["$details.amountpaid", -1] },
                            paym1: { $arrayElemAt: ["$details.paymentmethod", 0] },
                            paym2: { $arrayElemAt: ["$details.paymentmethod", -1] },
                            date: { $arrayElemAt: ["$details.date", -1] }
                        }
                    },
                    {$sort:{date:-1,vehno:-1}},{$skip:0},{$limit:30},
                ]
            },
            field: ["vehno", "transno", "mvc",  "oldCategory","oldamountpaid","paym1", "newCategory","newamountpaid","paym2", "date"],
            head: ["Vehicle Number", "Transaction Number", "Type",  "Old Toll","Old Amount Paid","Payment Method", "New Toll","New Amount Paid","Payment Method", "Date"]
        },

        "In TRS report  Single journey and return journey charges are levied basis toll rates": {
            collection: "nq-TrsChargeCompare_daily",
            payload: {
                filter: {},
                projection: { _id: 0, mvc: 1, vehno: 1, date: 1, plazacode: 1, acceptedamount: 1 },
                "sort":[["date",-1]]
            },
            field: ["vehno", "mvc", "date", "plazacode", "acceptedamount"],
            head: ["Vehicle Number", "Vehicle Class", "Date", "Plaza", "Amount Paid"]
        },

        "Rates charged as per the rate list": {
            collection: "nq-allChargedComparedWithRateMaster_daily",
            payload: {
                filter: {},
                projection: { _id: 0, mvc: 1, vehno: 1, date: 1, plazacode: 1, amountpaid: 1 },
                "sort":[["date",-1]]
            },
            field: ["vehno", "mvc", "date", "plazacode", "amountpaid"],
            head: ["Vehicle Number", "Vehicle Class", "Date", "Plaza", "Paid"]
        },
        "Vehicle passed under monthly discount pass & discount daily pass having same tag_id but different auditor class":{
            collection: "nq-sameTagIdDiffAudCls_daily",
            payload: {
                filter: {},
                projection: { _id: 0, mvc: 1, vehno: 1, date: 1, plazacode: 1,settledamount:1,reason:1 },
                "sort":[["date",-1]]
            },
            field: ["vehno", "mvc", "date", "plazacode", "settledamount","reason"],
            head: ["Vehicle number", "Vehicle class", "Date", "Plaza", "Amount","Reason"]

        },
        "Penalty check for overweight vehicles (if overweight is being charged)":{
            collection: "nq-penalty_for_overweight_veh",
            payload: {
                filter: {},
                projection: { _id: 0, vehicleclass: 1, vehregno: 1, date: 1, plazacode: 1, overloadpenalty20: 1 ,overloadpenalty40:1,overloadpenalty60:1},
                "sort":[["date",-1]]
            },
            field: ["vehregno", "vehicleclass", "date", "plazacode", "overloadpenalty20","overloadpenalty40"],
            head: ["Vehicle number", "Vehicle class", "Date", "Plaza", "Penalty20","Penalty40"]
        },
        "Identify cases where status and reason is accepted And Accepted amount is null or zero":{
            collection: "nq-status_accepted_settled_zero",
            payload: {
                filter: {},
                projection: { _id: 0, mvc: 1, vehno: 1, date: 1, plazacode: 1,settledamount:1 },
                "sort":[["date",-1]]
            },
            field: ["vehno", "mvc", "date", "plazacode", "settledamount"],
            head: ["Vehicle number", "Vehicle class", "Date", "Plaza", "Amount"]

        },
        "Status and Reason is Discount daily pass And Accepted amount is null or zero":{
            collection: "nq-statusReasonDDP_daily",
            payload: {
                filter: {},
                projection: { _id: 0, mvc: 1, vehno: 1, date: 1, plazacode: 1,settledamount:1 },
                "sort":[["date",-1]]
            },
            field: ["vehno", "mvc", "date", "plazacode", "settledamount"],
            head: ["Vehicle number", "Vehicle class", "Date", "Plaza", "Amount"]

        },
    }
    return (
        <div><Reports all={daily} queryMap={dailyQueryMap} type={"Daily"} background={"bg-cyan-500"} /></div>
    )
}
