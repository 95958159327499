import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { validateEmail, isPasswordValid } from "../utilities/utillFunction";

function AddCustomerAdmin(props) {
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [value, setValue] = useState("");
    const [formValue, setFormValue] = useState({
        ServiceId: props.serviceId,
        image: "",
        Name: "",
        Email: "",
        Password: "",
    });

    const handleChange = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const handleBack = () => {
        props.handleBackCustomer();
    };
    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("name", formValue.Name);
        formData.append("email", formValue.Email);
        formData.append("isSiteRestricted", false);
        formData.append("mobile", value);
        formData.append("serviceId", formValue.ServiceId);
        formData.append("image", file);
        formData.append("password", formValue.Password);

        props.handelAddCustomerAdmin(formData);
    };

    const handleNext = () => {
        props.nextPage();
    };

    const handleFile = (event) => {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    };

    const header = <h6>Pick a password</h6>;
    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    return (
        <>
            <div className="grid m-0">
                <div className="md:col-6 col-offset-3 col-12">
                    <div className="card">
                        <div className="formgrid grid">
                            <div className="field m-0 md:col-6 col-12">
                                <label htmlFor="ServiceId" className="mt-4">
                                    ServiceId*
                                </label>
                                <InputText id="username" placeholder="ServiceId" name="ServiceId" className="w-full" value={formValue.ServiceId} onChange={handleChange} />
                            </div>
                            <div className="field m-0 md:col-6 col-12">
                                <label htmlFor="Select a image" className="mt-4">
                                    Select a image*
                                </label>
                                <InputText id="username" type="file" name="image" className="w-full" onChange={handleFile} accept="image/png, image/jpeg, image/jpg" />
                            </div>
                            <div className="field m-0 md:col-6 col-12">
                                <label htmlFor="Name" className="mt-4">
                                    Name*
                                </label>
                                <InputText id="username" placeholder="Name" name="Name" className="w-full" value={formValue.Name} onChange={handleChange} />
                            </div>
                            <div className="field m-0 md:col-6 col-12">
                                <label htmlFor="Email" className="mt-4">
                                    Email*
                                </label>
                                <InputText id="email" type="email" name="Email" className="w-full" value={formValue.Email} onChange={handleChange} />
                            </div>
                            <div className="field m-0 md:col-6 col-12">
                                <label htmlFor="Mobile Number" className="mt-4">
                                    Mobile Number*
                                </label>
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    className="p-inputtext p-component p-filled w-full"
                                    value={value}
                                    onChange={setValue}
                                    international
                                    defaultCountry="IN"
                                    error={value ? (isValidPhoneNumber(value) ? undefined : "Invalid phone number") : "Phone number required"}
                                />
                            </div>
                            <div className="field m-0 md:col-6 col-12">
                                <label htmlFor="Password" className="mt-4">
                                    Password*
                                </label>
                                <Password placeholder="Password" name="Password" className="w-full" inputClassName="w-full" value={formValue.Password} onChange={handleChange} toggleMask header={header} footer={footer} />
                            </div>
                            <div className="field m-0 lg:col-12 col-12 flex justify-content-between ">
                                <span className="mt-4">
                                    <Button label="Back" className="p-button-rounded p-button-danger" onClick={handleBack} />
                                </span>
                                <span className="mt-4">
                                    {fileName !== "" && formValue.Name !== "" && validateEmail(formValue.Email) && isValidPhoneNumber(value) && isPasswordValid(formValue.Password) ? (
                                        <Button label="Create" className="p-button-rounded p-button-success" onClick={handleSubmit} />
                                    ) : (
                                        <Button label="Create" className="p-button-rounded p-button-success" onClick={handleSubmit} disabled />
                                    )}
                                    <Button label="Next" className="p-button-rounded p-button-warning" onClick={handleNext} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddCustomerAdmin;
