import moment from "moment";
import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Tooltip } from "primereact/tooltip";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { WindSpeedService } from "../../service/WindSpeedService";
import { colorPalettes } from "../../utilities/constant";
import Compass from "./Compass";

class WindSpeedDirection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thing: null,
            serviceId: null,
            siteName: null,
            siteAddress: null,
            direction: null,
            date: null,
            windspeed: null,
            directionText: null,
            weatherData: null,
            chartData: null,
            tableData: null,
            windChar: null,
        };

        this.colors = [colorPalettes.color1, colorPalettes.color1_1, colorPalettes.color2, colorPalettes.color2_1, colorPalettes.color3, colorPalettes.color4, colorPalettes.color5];
        this.serviceID = localStorage.getItem("serviceId");
        this.windSpeedService = new WindSpeedService();

        this.exportCSV = this.exportCSV.bind(this);
        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);

        this.cols = [
            { field: "date", header: "Date" },
            { field: "max", header: "Max Wind Speed(km/h)" },
            { field: "min", header: "Min Wind Speed(km/h)" },
        ];

        this.exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));
    }

    componentDidMount = () => {
        this.windSpeedService
            .getThings(this.serviceID)
            .then((res) => {
                let thing = res.responseData[0];
                const end_date = moment().subtract(1, "days");
                const start_date = moment(end_date).subtract(6, "days");
                let payload = {
                    serviceId: this.serviceID,
                    thingName: thing,
                };

                this.windSpeedService.getThingDetails(thing, this.serviceID).then((thingData) => {
                    let location = thingData[0].location.lat;
                    let siteName = thingData[0].location.siteName;
                    let address = thingData[0].location.address1 + ", " + thingData[0].location.city + ", Zipcode- " + thingData[0].location.zip;
                    let locGrp = location.split(",");

                    let lat = locGrp[0].trim();
                    let lng = locGrp[1].trim();

                    Promise.all([this.windSpeedService.getWindSpeedDirection(payload), this.windSpeedService.getWeatherData(lat, lng), this.windSpeedService.getHistoricalData(this.serviceID, thing, moment(start_date).format("YYYY-MM-DD"), moment(end_date).format("YYYY-MM-DD"))]).then((res) => {
                        let directionData = res[0];
                        let weatherData = res[1];
                        let historicalData = res[2].responseCode === 200 ? res[2].responseData[0].data : [];
                        const { chartOption, tableData } = this.initChart(historicalData);

                        if (directionData.responseData.length > 0) {
                            const directionText = this.noteTopIndicator(directionData.responseData[0].winddirection);
                            const windChar = this.getWindChar(directionData.responseData[0].windspeed);
                            this.setState({
                                directionText: directionText,
                                direction: directionData.responseData[0].winddirection,
                                date: directionData.responseData[0].date,
                                windspeed: directionData.responseData[0].windspeed,
                                weatherData: weatherData,
                                chartData: chartOption,
                                tableData: tableData,
                                windChar: windChar,
                                siteName: siteName,
                                siteAddress: address,
                                thing: thing,
                                serviceId: this.serviceID,
                            });
                        }
                    });
                });
            })
            .catch((reason) => {
                console.error(reason);
            });
    };

    noteTopIndicator = (direction) => {
        let d = direction / 45;
        var directionText = "";

        switch (d) {
            case 0:
                directionText = "North";
                break;
            case 1:
                directionText = "North East";
                break;
            case 2:
                directionText = "East";
                break;
            case 3:
                directionText = "South East";
                break;
            case 4:
                directionText = "South";
                break;
            case 5:
                directionText = "South West";
                break;
            case 6:
                directionText = "West";
                break;
            case 7:
                directionText = "North West";
                break;
            case 8:
                directionText = "North";
                break;
            default:
                directionText = "North";
                break;
        }
        return directionText;
    };

    getWindChar = (speed) => {
        let windCharText = "";
        if (speed < 1) {
            windCharText = "calm air";
        } else if (speed >= 1 || speed <= 5) {
            windCharText = "light air";
        } else if (speed >= 6 || speed <= 11) {
            windCharText = "light breeze";
        } else if (speed >= 12 || speed <= 19) {
            windCharText = "gentle breeze";
        } else if (speed >= 20 || speed <= 28) {
            windCharText = "moderate breeze";
        } else if (speed >= 29 || speed <= 38) {
            windCharText = "fresh breeze";
        } else if (speed >= 39 || speed <= 49) {
            windCharText = "strong breeze";
        } else if (speed >= 50 || speed <= 61) {
            windCharText = "a nearly gale";
        } else if (speed >= 62 || speed <= 74) {
            windCharText = "a gale is";
        } else if (speed >= 75 || speed <= 88) {
            windCharText = "a strong gale is";
        } else if (speed >= 89 || speed <= 102) {
            windCharText = "a storm is";
        } else if (speed >= 103 || speed <= 117) {
            windCharText = "violent storm";
        } else {
            windCharText = "hurricane";
        }
        return windCharText;
    };

    initChart = (historicalData) => {
        let responseData = historicalData;
        const reportData = [];
        let tableData = [];
        let dateArray = [];
        let dateGroupArray = [];
        let newDateGroupArr = [];

        responseData.forEach((item) => {
            if (!dateArray.includes(item.date)) {
                dateArray.push(item.date);
            }
        });

        dateArray.forEach((item) => {
            let a = responseData.filter((elm) => moment(elm.date).isSame(item));
            dateGroupArray.push(a);
        });

        for (let i = 0; i < dateGroupArray.length; i++) {
            if (dateGroupArray[i].length === 24) {
                newDateGroupArr.push(...dateGroupArray[i]);
            } else {
                let tempArr = [];
                for (let j = 0; j < 24; j++) {
                    let index = dateGroupArray[i].findIndex((item) => parseInt(item.hour) === j);
                    if (index > -1) {
                        tempArr.push(dateGroupArray[i][index]);
                    } else {
                        tempArr.push({
                            date: dateGroupArray[i][0].date,
                            hour: j.toString(),
                            windspeed: "",
                        });
                    }
                }
                newDateGroupArr.push(...tempArr);
            }
        }
        //Preparing Data for UI end
        dateArray.forEach((item) => {
            let speed = [];
            newDateGroupArr.forEach((elm) => {
                if (moment(elm.date).isSame(item)) {
                    elm.windspeed ? speed.push(parseInt(elm.windspeed)) : speed.push(null);
                }
            });
            reportData.push({
                name: moment(item, "YYYY-MM-DD").format("Do MMM"),
                speed: speed,
            });
        });

        let chartOption = {
            chart: {
                type: "line",
                backgroundColor: "transparent",
                height: 360,
            },
            title: {},
            subtitle: null,
            yAxis: {
                title: null,
            },
            xAxis: {
                title: null,
                accessibility: {
                    rangeDescription: "Range: 00hr to 23hr",
                },
                categories: ["00hr", "01hr", "02hr", "03hr", "04hr", "05hr", "06hr", "07hr", "08hr", "09hr", "10hr", "11hr", "12hr", "13hr", "14hr", "15hr", "16hr", "17hr", "18hr", "19hr", "20hr", "21hr", "22hr", "23hr"],
                labels: {
                    style: {
                        color: "#86969F",
                    },
                },
            },
            legend: {
                align: "center",
                verticalAlign: "bottom",
                x: 0,
                y: 0,
                floating: false,
                borderWidth: 0,
                backgroundColor: "transparent",
                shadow: false,
            },
            tooltip: {
                headerFormat: "<b>On: {point.x}</b><br/>",
                pointFormat: "Date: {series.name}<br/>Speed: {point.y} km/h",
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: true,
                    },
                    pointStart: 0,
                    borderRadius: 5,
                    pointWidth: 9,
                    borderWidth: 0,
                },
            },
            series: [],
        };
        chartOption.title = {
            text: `Wind Speed(km/h) for Last 7 days`,
        };
        let windSpeedSerise = [];
        reportData.forEach((item, index) => {
            let valArr = item.speed.filter((elm) => elm !== null);
            tableData.push({
                date: item.name,
                max: Math.max.apply(null, valArr),
                min: Math.min.apply(null, valArr),
            });
            windSpeedSerise.push({
                name: item.name,
                data: item.speed,
                color: this.colors[index],
            });
        });
        chartOption.series = windSpeedSerise;

        return { chartOption, tableData };
    };

    exportCSV(selectionOnly) {
        this.dt.exportCSV({ selectionOnly });
    }

    exportPdf() {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(this.exportColumns, this.state.tableData);
                doc.save("wind_speed_last_seven_days_report.pdf");
            });
        });
    }

    exportExcel() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.tableData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
            this.saveAsExcelFile(excelBuffer, "wind_speed_last_seven_days_report");
        });
    }

    saveAsExcelFile(buffer, fileName) {
        import("file-saver").then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                let EXCEL_EXTENSION = ".xlsx";
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE,
                });

                module.default.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    render = () => {
        let headerGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Date" className="bg-primary"></Column>
                    <Column header="Max Wind Speed(km/h)" className="bg-primary"></Column>
                    <Column header="Min Wind Speed(km/h)" className="bg-primary"></Column>
                </Row>
            </ColumnGroup>
        );
        const header = (
            <div className="flex align-items-center export-buttons">
                <Button type="button" icon="pi pi-file" onClick={() => this.exportCSV(false)} className="mr-2" data-pr-tooltip="Export as CSV" />
                <Button type="button" icon="pi pi-file-excel" onClick={this.exportExcel} className="p-button-success mr-2" data-pr-tooltip="Export as XLS" />
                <Button type="button" icon="pi pi-file-pdf" onClick={this.exportPdf} className="p-button-warning mr-2" data-pr-tooltip="Export as PDF" />
            </div>
        );
        return this.state.direction && this.state.date && this.state.windspeed && this.state.weatherData ? (
            <div className="grid">
                <Tooltip target=".export-buttons>button" position="bottom" />
                <div className="col-12 lg:col-4">
                    <div className="card mb-3">
                        <p className="text-xl font-bold capitalize">
                            {this.state.siteName}, {this.state.siteAddress}
                        </p>
                        <hr />
                        <p className="text-xl font-bold">
                            It's <span className="text-blue-500">{this.state.windChar}</span> blowing towords <span className="text-blue-500">{this.state.directionText}</span> with <span className="text-blue-500">{this.state.windspeed} km/hr</span> speed.
                        </p>
                        <hr />
                        <p className="flex align-items-center text-xl font-bold weather-info">
                            <svg viewBox="0 0 320 512" width="12" height="12">
                                <path d="M160 48c-35.3 0-64 28.7-64 64V273.9c0 14.5-5.7 27.1-12.8 36.6C71.1 326.5 64 346.4 64 368c0 53 43 96 96 96s96-43 96-96c0-21.6-7.1-41.5-19.2-57.5c-7.1-9.5-12.8-22.1-12.8-36.6V112c0-35.3-28.7-64-64-64zM48 112C48 50.2 98.1 0 160 0s112 50.1 112 112V273.9c0 1.7 .7 4.4 3.2 7.8c18.1 24.1 28.8 54 28.8 86.4c0 79.5-64.5 144-144 144S16 447.5 16 368c0-32.4 10.7-62.3 28.8-86.4c2.5-3.4 3.2-6.1 3.2-7.8V112zM208 368c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-20.9 13.4-38.7 32-45.3V200c0-8.8 7.2-16 16-16s16 7.2 16 16V322.7c18.6 6.6 32 24.4 32 45.3z" />
                            </svg>{" "}
                            <span className="text-blue-500">{this.state.weatherData.main.temp}°C</span>,{" "}
                            <svg fill="#000000" width="12" height="12" viewBox="0 0 328.611 328.611">
                                <g>
                                    <path
                                        d="M209.306,50.798c-2.452-3.337-7.147-4.055-10.485-1.602c-3.338,2.453-4.055,7.147-1.603,10.485
		c54.576,74.266,66.032,123.541,66.032,151.8c0,27.691-8.272,52.794-23.293,70.685c-17.519,20.866-42.972,31.446-75.651,31.446
		c-73.031,0-98.944-55.018-98.944-102.131c0-52.227,28.103-103.234,51.679-136.829c25.858-36.847,52.11-61.415,52.37-61.657
		c3.035-2.819,3.209-7.565,0.39-10.6c-2.819-3.034-7.565-3.209-10.599-0.39c-1.11,1.031-27.497,25.698-54.254,63.765
		c-24.901,35.428-54.586,89.465-54.586,145.71c0,31.062,9.673,59.599,27.236,80.353c20.361,24.061,50.345,36.779,86.708,36.779
		c36.794,0,66.926-12.726,87.139-36.801c17.286-20.588,26.806-49.117,26.806-80.33C278.25,156.216,240.758,93.597,209.306,50.798z"
                                    />
                                    <path
                                        d="M198.43,148.146l-95.162,95.162c-2.929,2.929-2.929,7.678,0,10.606c1.465,1.464,3.385,2.197,5.304,2.197
		s3.839-0.732,5.304-2.197l95.162-95.162c2.929-2.929,2.929-7.678,0-10.606C206.107,145.217,201.359,145.217,198.43,148.146z"
                                    />
                                    <path
                                        d="M191.965,207.899c-13.292,0-24.106,10.814-24.106,24.106s10.814,24.106,24.106,24.106s24.106-10.814,24.106-24.106
		S205.257,207.899,191.965,207.899z M191.965,241.111c-5.021,0-9.106-4.085-9.106-9.106s4.085-9.106,9.106-9.106
		s9.106,4.085,9.106,9.106S196.986,241.111,191.965,241.111z"
                                    />
                                    <path
                                        d="M125.178,194.162c13.292,0,24.106-10.814,24.106-24.106s-10.814-24.106-24.106-24.106s-24.106,10.814-24.106,24.106
		S111.886,194.162,125.178,194.162z M125.178,160.949c5.021,0,9.106,4.085,9.106,9.106s-4.085,9.106-9.106,9.106
		c-5.021,0-9.106-4.085-9.106-9.106S120.156,160.949,125.178,160.949z"
                                    />
                                </g>
                            </svg>{" "}
                            <span className="text-blue-500">{this.state.weatherData.main.humidity}%</span>, <i className="pi pi-eye"></i> <span className="text-blue-500">{this.state.weatherData.visibility / 1000}km</span>
                        </p>
                    </div>
                    <div className="card mb-3">
                        <Compass direction={this.state.direction} serviceID={this.state.serviceId} thingName={this.state.thing} />
                    </div>
                </div>
                <div className="col-12 lg:col-8">
                    <div className="card mb-3">
                        <HighchartsReact highcharts={Highcharts} options={this.state.chartData} />
                    </div>
                    {this.state.tableData ? (
                        <div className="card p-0">
                            {/* <DataTable header={header} headerColumnGroup={headerGroup} value={this.state.reportData} responsiveLayout="scroll" stripedRows>
                                <Column className="white-space-nowrap" body={this.hendelDate}></Column>
                                <Column className="white-space-nowrap" body={this.hendelMin}></Column>
                                <Column className="white-space-nowrap" body={this.hendelMax}></Column>
                                <Column className="white-space-nowrap" body={this.hendelRange}></Column>
                            </DataTable> */}

                            <DataTable
                                ref={(el) => {
                                    this.dt = el;
                                }}
                                value={this.state.tableData}
                                header={header}
                                dataKey="date"
                                responsiveLayout="scroll"
                                headerColumnGroup={headerGroup}
                            >
                                {this.cols.map((col, index) => (
                                    <Column key={index} field={col.field} header={col.header} />
                                ))}
                            </DataTable>
                        </div>
                    ) : null}
                </div>
            </div>
        ) : null;
    };
}

export default WindSpeedDirection;
