import React, { Component } from "react";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Dropdown } from "primereact/dropdown";
import { WaterQualityService } from "../../../service/WaterQualityService";

const serviceID = localStorage.getItem("serviceId");
const colors = ["#9400D3", "#4B0082", "#0872cf", "#02b526", "#edb409", "#de4f02", "#ba0404"];

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            things: null,
            selectedThing: null,
            weeks: null,
            selectedWeek: null,
            reportData: null,
            property: [
                { name: "EC", code: "ec" },
                { name: "PH", code: "ph" },
                { name: "Temperature", code: "temperature" },
                { name: "TDS", code: "tds" },
            ],
            propertyType: { name: "EC", code: "ec" },
            option: null,
        };
        this.waterQualityService = new WaterQualityService();
        this.hendelDate = this.hendelDate.bind(this);
        this.hendelMax = this.hendelMax.bind(this);
        this.hendelMin = this.hendelMin.bind(this);
        this.hendelRange = this.hendelRange.bind(this);
    }
    componentDidMount = () => {
        let weekDrpDown = [];
        let start = moment();
        for (let index = 0; index < 4; index++) {
            let end = moment(start).subtract(6, "days");
            weekDrpDown.push({ name: `${end.format("Do MMM, YY")} to ${start.format("Do MMM, YY")}`, code: `${end.format("YYYY-MM-DD")} to ${start.format("YYYY-MM-DD")}` });
            start = end.subtract(1, "days");
        }

        this.setState(
            {
                things: this.props.things,
                selectedThing: this.props.things[0],
                weeks: weekDrpDown,
                selectedWeek: weekDrpDown[0],
            },
            () => {
                this.createReport(serviceID, this.state.selectedThing, this.state.selectedWeek);
            }
        );
    };

    createReport = (id, thing, week) => {
        const payload = {
            serviceId: id,
            thingName: thing.code,
        };
        let dateRange = week.code.split("to");
        payload.startDate = dateRange[0].trim();
        payload.endDate = dateRange[1].trim();

        this.waterQualityService
            .getWaterQualityData(payload)
            .then((res) => {
                let responseData = res.responseData[0].data;
                const reportData = [];
                let dateArray = [];
                let dateGroupArray = [];
                let newDateGroupArr = [];

                responseData.forEach((item) => {
                    if (!dateArray.includes(item.date)) {
                        dateArray.push(item.date);
                    }
                });

                dateArray.forEach((item) => {
                    let a = responseData.filter((elm) => moment(elm.date).isSame(item));
                    dateGroupArray.push(a);
                });
                for (let i = 0; i < dateGroupArray.length; i++) {
                    if (dateGroupArray[i].length === 24) {
                        newDateGroupArr.push(...dateGroupArray[i]);
                    } else {
                        let tempArr = [];
                        for (let j = 0; j < 24; j++) {
                            let index = dateGroupArray[i].findIndex((item) => parseInt(item.hour) === j);
                            if (index > -1) {
                                tempArr.push(dateGroupArray[i][index]);
                            } else {
                                tempArr.push({
                                    date: dateGroupArray[i][0].date,
                                    hour: j.toString(),
                                    tds: "",
                                    ph: "",
                                    temp: "",
                                    ec: "",
                                    ctl: "",
                                });
                            }
                        }
                        newDateGroupArr.push(...tempArr);
                    }
                }
                //Preparing Data for UI end
                dateArray.forEach((item) => {
                    let tdsArr = [];
                    let phArr = [];
                    let tempArr = [];
                    let ecArr = [];
                    newDateGroupArr.forEach((elm) => {
                        if (moment(elm.date).isSame(item)) {
                            elm.tds ? tdsArr.push(parseInt(elm.tds)) : tdsArr.push(null);
                            elm.ph ? phArr.push(parseFloat(elm.ph)) : phArr.push(null);
                            elm.temp ? tempArr.push(parseFloat(elm.temp)) : tempArr.push(null);
                            elm.ec ? ecArr.push(parseInt(elm.ec)) : ecArr.push(null);
                        }
                    });
                    reportData.push({
                        name: moment(item, "YYYY-MM-DD").format("Do MMM"),
                        dataTDS: tdsArr,
                        dataPH: phArr,
                        dataTemp: tempArr,
                        dataEC: ecArr,
                    });
                });
                this.setState({ reportData }, () => {
                    this.initChart(this.state.propertyType);
                });
            })
            .catch((reason) => {
                console.error(reason);
            });
    };

    handelThingChange = (e) => {
        this.setState({ selectedThing: e.value }, () => this.createReport(serviceID, this.state.selectedThing, this.state.selectedWeek));
    };
    handelWeekChange = (e) => {
        this.setState({ selectedWeek: e.value }, () => this.createReport(serviceID, this.state.selectedThing, this.state.selectedWeek));
    };
    handelTypeChange = (e) => {
        this.setState({ propertyType: e.value }, () => this.initChart(this.state.propertyType));
    };
    initChart = (propType) => {
        let type = propType.code;

        let chartOption = {
            chart: {
                type: "line",
                backgroundColor: "transparent",
                height: 410,
            },
            title: {},
            subtitle: {
                text: "Water Quality",
            },
            yAxis: {
                title: null,
            },
            xAxis: {
                title: {
                    text: "Time(24 Hour)",
                },
                accessibility: {
                    rangeDescription: "Range: 00hr to 23hr",
                },
                categories: ["00hr", "01hr", "02hr", "03hr", "04hr", "05hr", "06hr", "07hr", "08hr", "09hr", "10hr", "11hr", "12hr", "13hr", "14hr", "15hr", "16hr", "17hr", "18hr", "19hr", "20hr", "21hr", "22hr", "23hr"],
                labels: {
                    style: {
                        color: "#86969F",
                    },
                },
            },
            legend: {
                align: "center",
                verticalAlign: "bottom",
                x: 0,
                y: 0,
                floating: false,
                borderWidth: 0,
                backgroundColor: "transparent",
                shadow: false,
            },
            tooltip: {},
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: true,
                    },
                    pointStart: 0,
                    borderRadius: 5,
                    pointWidth: 9,
                    borderWidth: 0,
                },
            },
            series: [],
        };

        switch (type) {
            case "ph":
                chartOption.title = {
                    text: `Water ${propType.name} report of ${this.state.selectedWeek.code}`,
                };
                // chartOption.yAxis = {
                //     title: {
                //         text: `PH`,
                //     },
                // };
                chartOption.tooltip = {
                    headerFormat: "<b>PH Value On: {point.x}</b><br/>",
                    pointFormat: "Date: {series.name}<br/>Value: {point.y}",
                };
                let serisePH = [];
                this.state.reportData.forEach((item, index) => {
                    serisePH.push({
                        name: item.name,
                        data: item.dataPH,
                        color: colors[index],
                    });
                });
                chartOption.series = serisePH;
                this.setState({ option: chartOption });
                break;

            case "temperature":
                chartOption.title = {
                    text: `Water ${propType.name} report of ${this.state.selectedWeek.code}`,
                };
                // chartOption.yAxis = {
                //     title: {
                //         text: `Temperature (°C)`,
                //     },
                // };
                chartOption.tooltip = {
                    headerFormat: "<b>Temperature On: {point.x}</b><br/>",
                    pointFormat: "Date: {series.name}<br/>Value: {point.y} °C",
                };
                let seriseTemp = [];
                this.state.reportData.forEach((item, index) => {
                    seriseTemp.push({
                        name: item.name,
                        data: item.dataTemp,
                        color: colors[index],
                    });
                });

                chartOption.series = seriseTemp;
                this.setState({ option: chartOption });
                break;

            case "ec":
                chartOption.title = {
                    text: `Water ${propType.name} report of ${this.state.selectedWeek.code}`,
                };
                // chartOption.yAxis = {
                //     title: {
                //         text: `EC`,
                //     },
                // };
                chartOption.tooltip = {
                    headerFormat: "<b>EC value On: {point.x}</b><br/>",
                    pointFormat: "Date: {series.name}<br/>Value: {point.y}",
                };

                let seriseEC = [];
                this.state.reportData.forEach((item, index) => {
                    seriseEC.push({
                        name: item.name,
                        data: item.dataEC,
                        color: colors[index],
                    });
                });
                chartOption.series = seriseEC;
                this.setState({ option: chartOption });
                break;

            case "tds":
                chartOption.title = {
                    text: `Water ${propType.name} report of ${this.state.selectedWeek.code}`,
                };
                // chartOption.yAxis = {
                //     title: {
                //         text: `TDS`,
                //     },
                // };
                chartOption.tooltip = {
                    headerFormat: "<b>TDS value On: {point.x}</b><br/>",
                    pointFormat: "Date: {series.name}<br/>Value: {point.y}",
                };

                let seriseTDS = [];
                this.state.reportData.forEach((item, index) => {
                    seriseTDS.push({
                        name: item.name,
                        data: item.dataTDS,
                        color: colors[index],
                    });
                });
                chartOption.series = seriseTDS;
                this.setState({ option: chartOption });
                break;

            default:
                this.setState({ option: null });
                break;
        }
    };

    hendelDate = (item) => {
        return item.name;
    };
    hendelMax = (item) => {
        if (this.state.propertyType.name === "EC") {
            let valArr = item.dataEC.filter((elm) => elm !== null);
            return Math.max.apply(null, valArr);
        }
        if (this.state.propertyType.name === "PH") {
            let valArr = item.dataPH.filter((elm) => elm !== null);
            return Math.max.apply(null, valArr);
        }
        if (this.state.propertyType.name === "Temperature") {
            let valArr = item.dataTemp.filter((elm) => elm !== null);
            return Math.max.apply(null, valArr);
        }
        if (this.state.propertyType.name === "TDS") {
            let valArr = item.dataTDS.filter((elm) => elm !== null);
            return Math.max.apply(null, valArr);
        }
    };
    hendelMin = (item) => {
        if (this.state.propertyType.name === "EC") {
            let valArr = item.dataEC.filter((elm) => elm !== null);
            return Math.min.apply(null, valArr);
        }
        if (this.state.propertyType.name === "PH") {
            let valArr = item.dataPH.filter((elm) => elm !== null);
            return Math.min.apply(null, valArr);
        }
        if (this.state.propertyType.name === "Temperature") {
            let valArr = item.dataTemp.filter((elm) => elm !== null);
            return Math.min.apply(null, valArr);
        }
        if (this.state.propertyType.name === "TDS") {
            let valArr = item.dataTDS.filter((elm) => elm !== null);
            return Math.min.apply(null, valArr);
        }
    };
    hendelRange = (item) => {
        if (this.state.propertyType.name === "EC") {
            let valArr = item.dataEC.filter((elm) => elm !== null);
            return (Math.max.apply(null, valArr) - Math.min.apply(null, valArr)).toFixed(1);
        }
        if (this.state.propertyType.name === "PH") {
            let valArr = item.dataPH.filter((elm) => elm !== null);
            return (Math.max.apply(null, valArr) - Math.min.apply(null, valArr)).toFixed(1);
        }
        if (this.state.propertyType.name === "Temperature") {
            let valArr = item.dataTemp.filter((elm) => elm !== null);
            return (Math.max.apply(null, valArr) - Math.min.apply(null, valArr)).toFixed(1);
        }
        if (this.state.propertyType.name === "TDS") {
            let valArr = item.dataTDS.filter((elm) => elm !== null);
            return (Math.max.apply(null, valArr) - Math.min.apply(null, valArr)).toFixed(1);
        }
    };

    render = () => {
        let headerGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Date" className="bg-primary"></Column>
                    <Column header="Min Value" className="bg-primary"></Column>
                    <Column header="Max Value" className="bg-primary"></Column>
                    <Column header="Range" className="bg-primary"></Column>
                </Row>
            </ColumnGroup>
        );
        return (
            <div className="grid">
                <div className="field col-6 lg:col-3">
                    <label htmlFor="basic">Select Type</label>
                    <Dropdown value={this.state.propertyType} options={this.state.property} onChange={this.handelTypeChange} optionLabel="name" placeholder="Select a Thing" className="w-full" />
                </div>
                <div className="field col-6 lg:col-3">
                    <label htmlFor="basic">Select Thing</label>
                    <Dropdown value={this.state.selectedThing} options={this.state.things} onChange={this.handelThingChange} optionLabel="name" placeholder="Select a Thing" className="w-full" />
                </div>
                <div className="field col-12 lg:col-3">
                    <label htmlFor="basic">Select Week</label>
                    <Dropdown value={this.state.selectedWeek} options={this.state.weeks} onChange={this.handelWeekChange} optionLabel="name" placeholder="Select a Thing" className="w-full" />
                </div>
                <div className="col-12 lg:col-8">
                    <div className="card">{this.state.option ? <HighchartsReact highcharts={Highcharts} options={this.state.option} /> : null}</div>
                </div>
                <div className="col-12 lg:col-4">
                    {this.state.reportData ? (
                        <div className="card p-0">
                            <DataTable headerColumnGroup={headerGroup} value={this.state.reportData} responsiveLayout="scroll" stripedRows>
                                <Column className="white-space-nowrap" body={this.hendelDate}></Column>
                                <Column className="white-space-nowrap" body={this.hendelMin}></Column>
                                <Column className="white-space-nowrap" body={this.hendelMax}></Column>
                                <Column className="white-space-nowrap" body={this.hendelRange}></Column>
                            </DataTable>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };
}
export default Report;
