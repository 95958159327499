import React from 'react'
import { Customer } from './customer'
import DesignerHome from './DesignerHome'

const RerouteIHUser = () => {
    const userpermissions = JSON.parse(localStorage.getItem("permissions"))
    const isAdmin = (userpermissions.includes("UserManagement.READ") && userpermissions.includes("UserManagement.WRITE"))
     
  return (
    isAdmin ? <Customer/> : <DesignerHome/>
  )
}

export default RerouteIHUser