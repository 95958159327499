import React, { useContext } from 'react';
import { RTLContext } from './App';

const AppFooter = (props) => {

    const isRTL = useContext(RTLContext);

    return <div className="layout-footer flex align-items-center px-4 py-2 shadow-2">
                <p className={!isRTL ? 'mr-auto' : 'ml-auto' }>{'Powered by Nextqore © ' + new Date().getFullYear() +  '.'}</p>
    </div>

}

export default AppFooter;
