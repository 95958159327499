import React, { Component } from "react";
import { mapKey } from "../../../shared/data/const";

class StateReportComponent extends Component {
    mapRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            map: null
        }
    }

    componentDidMount() {
        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey
        });
        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(
            this.mapRef.current,
            defaultLayers.vector.normal.map,
            {
                center: {lat: this.props.position[0].pos.lat, lng: this.props.position[0].pos.lng},
                zoom: this.props.siteoverview ? 3 : 8,
                pixelRatio: window.devicePixelRatio || 1
            }
        );
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        H.ui.UI.createDefault(map, defaultLayers);
        this.setState({map}, () => {
            if (this.props.siteoverview) {
                this.startClustering(H, map, this.props.position);

            }
            else this.addSVGMarkers(window.H, this.state.map, this.props.position);
        })
    }

    addSVGMarkers = (H, map, pos) => {

        var svgMarkup = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path style="fill:#01010100; stroke:none;" d="M0 0L0 32L32 32L32 0L0 0z"/><path style="fill:#ef3d49; stroke:none;" d="M15 28L17 28C19.368 24.4869 21.95 20.9627 23.5208 17C31.413 -2.9091 1.1202 -3.05081 8.2716 16C9.88256 20.2915 12.4852 24.1989 15 28z"/><path style="fill:#ef2121; stroke:none;" d="M15 5L16 6L15 5z"/><path style="fill:#ffffff; stroke:none;" d="M13.7461 7.03241C10.2098 9.89313 14.9493 15.6823 18.3927 12.3781C21.696 9.20841 17.2399 4.20618 13.7461 7.03241z"/><path style="fill:#ff166b; stroke:none;" d="M12 7L13 8L12 7z"/><path style="fill:#36d1ba; stroke:none;" d="M5 27L5 29C11.8299 31.882 20.17 31.882 27 29L27 27L20 25L20 27L24 27L24 28L8 28L8 27L12 27L12 25L5 27z"/></svg>';

        pos.forEach(item => {
            var bearsIcon = new H.map.Icon(svgMarkup),
            bearsMarker = new H.map.Marker({lat: item.pos.lat, lng: item.pos.lng },{ icon: bearsIcon, data: item }, { width: 40, height: 40 });

            bearsMarker.addEventListener('tap', this.onSVGMarkerClick);
            // bearsMarker.addEventListener('pointerenter', function(evt) {
            //     let eventData = evt.target.getData();
            //     bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
            //     content: eventData.customerSiteid
            //   });
            //   ui.addBubble(bubble);
            // }, false);
            // bearsMarker.addEventListener('pointerleave', function(evt) {
            //   bubble.close();
            // }, false);
            map.addObject(bearsMarker);
        });
    }
    onSVGMarkerClick = (evt) => {
        let eventData = evt.target.getData();
        if (eventData.customerSiteid) this.props.onMarkerClick(eventData);
    }

    startClustering = (H, map, position) => {

        let dataPoints = position.map((item) => {
            return new H.clustering.DataPoint(item.pos.lat, item.pos.lng, null, item);
        });
        var svgMarkup = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path style="fill:#01010100; stroke:none;" d="M0 0L0 32L32 32L32 0L0 0z"/><path style="fill:#ef3d49; stroke:none;" d="M15 28L17 28C19.368 24.4869 21.95 20.9627 23.5208 17C31.413 -2.9091 1.1202 -3.05081 8.2716 16C9.88256 20.2915 12.4852 24.1989 15 28z"/><path style="fill:#ef2121; stroke:none;" d="M15 5L16 6L15 5z"/><path style="fill:#ffffff; stroke:none;" d="M13.7461 7.03241C10.2098 9.89313 14.9493 15.6823 18.3927 12.3781C21.696 9.20841 17.2399 4.20618 13.7461 7.03241z"/><path style="fill:#ff166b; stroke:none;" d="M12 7L13 8L12 7z"/><path style="fill:#36d1ba; stroke:none;" d="M5 27L5 29C11.8299 31.882 20.17 31.882 27 29L27 27L20 25L20 27L24 27L24 28L8 28L8 27L12 27L12 25L5 27z"/></svg>';
        let clusteredDataProvider = new H.clustering.Provider(dataPoints, {
            clusteringOptions: {
                eps: 64,
                minWeight: 3,
            },
        });

        var defaultTheme = clusteredDataProvider.getTheme();
        var customTheme = {
            getClusterPresentation: function (cluster) {
                //Keep the default theme for clusters
                var clusterMarker = defaultTheme.getClusterPresentation.call(defaultTheme, cluster);
                return clusterMarker;
            },
            getNoisePresentation: function (noisePoint) {
                let noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
                    icon: new H.map.Icon(svgMarkup),
                    min: noisePoint.getMinZoom(),
                });
                noiseMarker.setData(noisePoint);
                return noiseMarker;
            }
        }
        clusteredDataProvider.setTheme(customTheme);

        var self = this;
        clusteredDataProvider.addEventListener('tap', function (evt) {           
            let cnt = evt.target.getData();

            // if those data contain a data object it was a marker to be clicked
            // mine has a string (not yet set in the code above) which I show inside an InfoBubble
            if (cnt.a.hasOwnProperty('data')) {
                self.props.onMarkerClick(cnt.a.data);
            } else {
                // otherwise it was a cluster icon which doesn't contain a data object
                // set the map center to the coordinates where the user clicked
                // "true" is to have a smooth transition
                map.setCenter(

                    map.screenToGeo(
                        evt.currentPointer.viewportX,
                        evt.currentPointer.viewportY
                    )
                );
                // increase the zoom level by an amount which fits your needs
                // again "true" is to have a smooth transition
                map.setZoom(map.getZoom() + 3, true);
            }
        }, false);
        let clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);
        map.addLayer(clusteringLayer);
    }


    render = () => {
        return (
            <>
                <div className="col-12 lg:col-4">
                    {
                        this.props.data.map((item, index) => (
                            <div className="card report_opt_card mb-3"
                                key={item.circleName + '-' + index}
                                onClick={this.props.onStateClick.bind(this, item.circleName)} style={{minHeight: 'fit-content'}}>
                                <div className="flex">
                                    <div className="card_ioc_grid flex justify-content-center align-items-center mr-2">
                                        <i className="pi pi-map-marker" style={{ fontSize: "1.2em" }}></i>
                                    </div>
                                    <div className="flex-1">
                                        <p className="text-900 font-medium text-xl mb-0">
                                            {item.circleName}{" "}
                                            <span className="text-xl" style={{ float: "right" }}>
                                                Total: {item.totalSite}
                                            </span>
                                        </p>
                                        {item.details.map((elm, index) => (
                                            <span className="mr-2" key={"state-tower-type-" + index}>
                                                <b>{elm.type}</b>: {elm.total}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="col-12 lg:col-8">
                    <div className="card">
                        <div
                            ref={this.mapRef}
                            style={{
                                width: "100%",
                                height: "76vh",
                                overflow: "hidden",
                            }}
                        ></div>
                    </div>
                </div>
            </>
        )
    }
}

export default StateReportComponent;