import React, { Component } from "react";
import moment from "moment";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Dialog } from 'primereact/dialog';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import { AssetService } from "../../service/AssetService";
import { colorPalettes } from "../../utilities/constant";

class PortfolioAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dgStatusChart: null, 
            dgRunningChart: null, 
            dgMaxAvg: null, 
            dataPopUp: false, 
            alertDataPopUp: false, 
            tableData: null, 
            title: '', 
            dgModelWiseChart: null, 
            dgSizeWiseChart: null, 
            malData: null, 
            alertData: null, 
            dgAlertChart: null
        };
        this.headerGroup = (<ColumnGroup>
            <Row>
                <Column header="DG Name" className="bg-primary"/>
                <Column header="State" className="bg-primary"/>
                <Column header="City" className="bg-primary"/>
                <Column header="Pincode" className="bg-primary"/>
                <Column header="Type of Alarm" className="bg-primary"/>
                <Column header="Date and Time" className="bg-primary"/>
            </Row>
        </ColumnGroup>);
        this.headerGroup2 = (<ColumnGroup>
            <Row>
                <Column header="Thing name" className="bg-primary"/>
                <Column header="State" className="bg-primary"/>
                <Column header="City" className="bg-primary"/>
                <Column header="Pincode" className="bg-primary"/>
                <Column header="SiteId" className="bg-primary"/>
                <Column header="Avg. Daily Runhours" className="bg-primary"/>
                <Column header="Max Runhour (Last 30 days)" className="bg-primary"/>
                <Column header="Total RunHour (last 30 days)" className="bg-primary"/>
            </Row>
        </ColumnGroup>);
        
        this.pieColor = [colorPalettes.color1, colorPalettes.color2, colorPalettes.color3, colorPalettes.color4, colorPalettes.color5];
        this.serviceID = localStorage.getItem('serviceId');
        this.assetsService = new AssetService();
        this.onChartClick = this.onChartClick.bind(this);
        this.onAlertChartClick = this.onAlertChartClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount = () => {
        const endDay = moment().subtract(1, 'days').endOf('day').format("YYYY-MM-DD HH:mm:ss");
        const startDay = moment().subtract(6, 'days').startOf('day').format("YYYY-MM-DD HH:mm:ss");
        const payload = {
            "filter": {
                "date": { "$gte": new Date(startDay),"$lte": new Date(endDay) }
            }
        };
        Promise.all([
            this.assetsService.getDGStatus(this.serviceID), 
            this.assetsService.getDGRunHourMaxAvg(this.serviceID, startDay, endDay), 
            this.assetsService.getDgsModelWise(this.serviceID), 
            this.assetsService.getDgSizeWise(this.serviceID), 
            this.assetsService.getDGDailyData(this.serviceID, payload)
        ]).then((res) => {
            let status = res[0];
            let dgMaxAvg = res[1].map(item => {
                return {
                    _id: {
                        date: item._id.date,
                        thingName: item._id.thingName
                    },
                    avgRunHour: parseFloat((item.avgRunHour / 3600).toFixed(2)),
                    maxRunHour: parseFloat((item.maxRunHour / 3600).toFixed(2)), 
                    totalRunHour: parseFloat((item.totalRunHour / 3600).toFixed(2))
                }
            });

            let modelWiseData = res[2];
            let sizeWiseData = res[3].map(item => {
                return {
                    _id: {
                        size: (item._id.size.trim()).replace(/\s/g, "")
                    },
                    count: item.count,
                    dgs: item.dgs
                }
            });

            let data = [];
            let lowLubOilPressure = res[4].filter(item => item.LowLubeOilPressureOOR_Count > 0);
            let engineWaterTemp = res[4].filter(item => item.EngineWaterTempOOR_Count > 0);
            let ebVoltDGOff = res[4].filter(item => item.EBVoltOOR_DGOFF_Count > 0);

            let alertData = [
                { name: "Low Lube Oil Pressure", y: lowLubOilPressure.length, color: colorPalettes.color2 }, 
                { name: "High Engine Water Temperature", y: engineWaterTemp.length, color: colorPalettes.color5 },  
                { name: "DG Off When EB Volt < 170 Or > 270 Volts", y: ebVoltDGOff.length, color: colorPalettes.color4 } 
            ];

            res[4].forEach(item => {

                if (item.LowLubeOilPressureOOR_Count > 0) {
                    data.push({
                        name: item.thingName, 
                        state: item.state, 
                        city: item.city, 
                        zip: item.zipcode, 
                        alarmType: "Low Lube Oil Pressure", 
                        date: moment(item.date, "YYYY-MM-DD").format("Do MMM, YYYY")
                    })
                } else if (item.EngineWaterTempOOR_Count > 0) {
                    data.push({
                        name: item.thingName, 
                        state: item.state, 
                        city: item.city, 
                        zip: item.zipcode, 
                        alarmType: "High Engine Water Temperature", 
                        date: moment(item.date, "YYYY-MM-DD").format("Do MMM, YYYY")
                    })
                } else if (item.EBVoltOOR_DGOFF_Count > 0) {
                    data.push({
                        name: item.thingName, 
                        state: item.state, 
                        city: item.city, 
                        zip: item.zipcode, 
                        alarmType: "DG Off When EB Volt < 170 Or > 270 Volts", 
                        date: moment(item.date, "YYYY-MM-DD").format("Do MMM, YYYY")
                    })
                }
            });

            let size = [];
            sizeWiseData.forEach(item => {
                if(!size.includes(item._id.size)) {
                    size.push({
                        size: item._id.size,
                        count: 0,
                        data: []
                    });
                }
            });
            sizeWiseData.forEach(item => {
                let index = size.findIndex(elm => elm.size === item._id.size);
                if(index > -1) {
                    size[index].count = size[index].count + item.count;
                    size[index].data = [...size[index].data, ...item.dgs]
                }
            });

            let finalSizeWiseData = size.filter(item => item.count !== 0);
            
            let dgStatusData = [];
            const toralDG = status[0]?.Total;
            dgStatusData.push({ name: "On", y: status[0]?.On, color: colorPalettes.color2 });
            dgStatusData.push({ name: "Off", y: status[0]?.Off, color: colorPalettes.color5 });

            let lowUsedDg = dgMaxAvg.filter(elm => elm.avgRunHour < 2);
            let moderateUsedDg = dgMaxAvg.filter(elm => elm.avgRunHour >= 2 && elm.avgRunHour <= 6);
            let highUsedDg = dgMaxAvg.filter(elm => elm.avgRunHour > 6);

            let dgs = [],
                maxRun = [],
                avgRun = [];
            dgMaxAvg.forEach((item) => {
                dgs.push(item._id.thingName);
                maxRun.push(item.maxRunHour);
                avgRun.push(item.avgRunHour);
            });

            let dgModelWiseData = [];
            modelWiseData.forEach((item, index) => {
                dgModelWiseData.push({ name: item._id.model, y: item.count, color: this.pieColor[index] });
            });

            let dgSizeWise = [];
            finalSizeWiseData.forEach((item, index) => {
                dgSizeWise.push({ name: item.size, y: item.count, color: this.pieColor[index] });
            })

            let pieOption = {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: `DG Status - (Total ${toralDG})`,
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y}</b>",
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format: "<b>{point.name}</b>: {point.y}",
                        },
                        showInLegend: true,
                    },
                },
                series: [
                    {
                        name: "Total",
                        colorByPoint: true,
                        data: dgStatusData,
                    },
                ],
            };

            let dgRunningChart = {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: `DG utilization (Avg. Daily RunHours)`,
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y}</b>",
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format: "<b>{point.name}</b>: {point.y}",
                        },
                        showInLegend: true,
                    },
                    series: {
                        point: {
                            events: {
                                click: this.onChartClick.bind(this)
                            }
                        }
                    }
                },
                series: [
                    {
                        name: "Total",
                        colorByPoint: true,
                        data: [
                            { name: "Low usage", y: lowUsedDg.length, color: colorPalettes.color2 },
                            { name: "Moderate usage", y: moderateUsedDg.length, color: colorPalettes.color3 },
                            { name: "High usage", y: highUsedDg.length, color: colorPalettes.color5 },
                        ],
                    },
                ],
            };

            let dgModelWiseChart = {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: `DG's Model Wise`,
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y}</b>",
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format: "<b>{point.name}</b>: {point.y}",
                        },
                        showInLegend: true,
                    },
                    series: {
                        point: {
                            events: {
                                click: this.onChartClick.bind(this)
                            }
                        }
                    }
                },
                series: [
                    {
                        name: "Total",
                        colorByPoint: true,
                        data: dgModelWiseData
                    },
                ],
            };

            let dgSizeWiseChart = {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: `DG's Size Wise`,
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y}</b>",
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format: "<b>{point.name}</b>: {point.y}",
                        },
                        showInLegend: true,
                    },
                    series: {
                        point: {
                            events: {
                                click: this.onChartClick.bind(this)
                            }
                        }
                    }
                },
                series: [
                    {
                        name: "Total",
                        colorByPoint: true,
                        data: dgSizeWise
                    },
                ],
            };

            let dgAlertChart = {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: `DG's Alert Wise`,
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y}</b>",
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format: "<b>{point.name}</b>: {point.y}",
                        },
                        showInLegend: true,
                    },
                    series: {
                        point: {
                            events: {
                                click: this.onAlertChartClick.bind(this),
                            },
                        },
                    }
                },
                series: [
                    {
                        name: "Total",
                        colorByPoint: true,
                        data: alertData
                    },
                ],
            };
            
            this.setState({ dgStatusChart: pieOption, dgRunningChart, dgMaxAvg, dgModelWiseChart, dgSizeWiseChart, alertData: data, dgAlertChart });
        });
    }

    onAlertChartClick = (alertType) => {
        let alertTableData = this.state.alertData.filter(item => item.alarmType === alertType.point.name);
        this.setState({title: "DG's with " + alertType.point.name, alertDataPopUp: true, malData: alertTableData});
    }

    onChartClick = (state) => {
        
        if(state.point.name === 'Low usage') {
            let dgs = this.state.dgMaxAvg.filter(item => item.avgRunHour < 2);
            let things = dgs.map(item => {
                return item._id.thingName
            });
            let payload = {
                "filter": {
                    "thingName": {
                        "$in": things
                    }
                }
            }
            this.assetsService.getAssets(this.serviceID, payload).then(res => {
                let tableData = res.map(item => {
                    let index = dgs.findIndex(elm => elm._id.thingName === item.thingName);
                    if (index > -1) {
                        return {
                            thingName: item.thingName,
                            state: item.location.state,
                            city: item.location.city,
                            pincode: item.location.zip,
                            siteId: item.location.siteId,
                            avgRunHr: dgs[index].avgRunHour,
                            maxRunHr: dgs[index].maxRunHour,
                            totalRunHr: dgs[index].totalRunHour,
                        }
                    }
                })
                let title = "Low usage DG's List"
                this.setState({ dataPopUp: true, tableData, title });
            });
        } else if (state.point.name === 'Moderate usage') {

            let dgs = this.state.dgMaxAvg.filter(item => item.avgRunHour >= 2 && item.avgRunHour <= 6);
            let things = dgs.map(item => {
                return item._id.thingName
            });
            let payload = {
                "filter": {
                    "thingName": {
                        "$in": things
                    }
                }
            }
            this.assetsService.getAssets(this.serviceID, payload).then(res => {
                let tableData = res.map(item => {
                    let index = dgs.findIndex(elm => elm._id.thingName === item.thingName);
                    if (index > -1) {
                        return {
                            thingName: item.thingName,
                            state: item.location.state,
                            city: item.location.city,
                            pincode: item.location.zip,
                            siteId: item.location.siteId,
                            avgRunHr: dgs[index].avgRunHour,
                            maxRunHr: dgs[index].maxRunHour,
                            totalRunHr: dgs[index].totalRunHour,
                        }
                    }
                })
                let title = "Moderate usage DG's List"
                this.setState({ dataPopUp: true, tableData, title });
            });
        } else if (state.point.name === 'High usage') {
            let dgs = this.state.dgMaxAvg.filter(item => item.avgRunHour > 6);
            let things = dgs.map(item => {
                return item._id.thingName
            });
            let payload = {
                "filter": {
                    "thingName": {
                        "$in": things
                    }
                }
            }
            this.assetsService.getAssets(this.serviceID, payload).then(res => {
                let tableData = res.map(item => {
                    let index = dgs.findIndex(elm => elm._id.thingName === item.thingName);
                    if (index > -1) {
                        return {
                            thingName: item.thingName,
                            state: item.location.state,
                            city: item.location.city,
                            pincode: item.location.zip,
                            siteId: item.location.siteId,
                            avgRunHr: dgs[index].avgRunHour,
                            maxRunHr: dgs[index].maxRunHour,
                            totalRunHr: dgs[index].totalRunHour,
                        }
                    }
                })
                let title = "High usage DG's List"
                this.setState({ dataPopUp: true, tableData, title });
            });
        }
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    render = () => {
        return (
            <div className="grid">
                <div className="col-12 md:col-6 lg:col-4">
                    {this.state.dgStatusChart ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.dgStatusChart} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12 md:col-6 lg:col-8">
                    <Dialog header={this.state.title} visible={this.state.dataPopUp} breakpoints={{'960px': '95vw'}} style={{width: '80vw'}} onHide={() => this.onHide('dataPopUp')}>
                        <DataTable value={this.state.tableData} headerColumnGroup={this.headerGroup2} responsiveLayout="scroll" paginator currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} stripedRows >
                            <Column field="thingName" className="font-semibold"></Column>
                            <Column field="state" className="white-space-nowrap"></Column>
                            <Column field="city" className="white-space-nowrap"></Column>
                            <Column field="pincode" className="white-space-nowrap"></Column>
                            <Column field="siteId" className="white-space-nowrap"></Column>
                            <Column field="avgRunHr" className="white-space-nowrap"></Column>
                            <Column field="maxRunHr" className="white-space-nowrap"></Column>
                            <Column field="totalRunHr" className="white-space-nowrap"></Column>
                        </DataTable>    
                    </Dialog>

                    <Dialog header={this.state.title} visible={this.state.alertDataPopUp} breakpoints={{'960px': '95vw'}} style={{width: '80vw'}} onHide={() => this.onHide('alertDataPopUp')}>
                        <DataTable 
                            value={this.state.malData} 
                            headerColumnGroup={this.headerGroup} 
                            paginator 
                            responsiveLayout="scroll" 
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                            rows={10} >
                            <Column field="name" className="font-semibold"></Column>
                            <Column field="state" className="white-space-nowrap"></Column>
                            <Column field="city" className="white-space-nowrap"></Column>
                            <Column field="zip" className="white-space-nowrap"></Column>
                            <Column field="alarmType" className="white-space-nowrap"></Column>
                            <Column field="date" className="white-space-nowrap"></Column>
                        </DataTable>    
                    </Dialog>

                    {this.state.dgRunningChart ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.dgRunningChart} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-6">
                    {
                        this.state.dgModelWiseChart ? (
                            <div className="card">
                                <HighchartsReact highcharts={Highcharts} options={this.state.dgModelWiseChart} />
                            </div>
                        ) : null
                    }
                </div>
                <div className="col-12 lg:col-6">
                    {
                        this.state.dgSizeWiseChart ? (
                            <div className="card">
                                <HighchartsReact highcharts={Highcharts} options={this.state.dgSizeWiseChart} />
                            </div>
                        ) : null
                    }
                </div>

                <div className="col-12">
                    {
                        this.state.dgAlertChart ? (
                            <div className="card">
                                <HighchartsReact highcharts={Highcharts} options={this.state.dgAlertChart} />
                            </div>
                        ) : null
                    }
                </div>
            </div>
        );
    }
}

export default PortfolioAnalysis;