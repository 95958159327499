import axios from "axios";

const config_url = process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL;
const doc_db_url = process.env.REACT_APP_DOCDB_SERVICE_URL;
export class TiltService {
    getThings = async (serviceID) => {
        const token = localStorage.getItem("token");
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        const res = await axios.post(config_url+'/get-things/'+serviceID, {}, { headers: headers });
        return res.data;
    }
    getTowers = async (things, serviceID) => {
        const payload = {
            "filter": {
                "thingName": { "$in": things } 
            }
        };
        const res = await axios.post(doc_db_url+'/'+serviceID+'/nq-items', payload);
        return res.data;
    }
    // getAntennaDetail = async (things, serviceID) => {
    //     const payload = {
    //         "filter": {
    //             "thingName": { "$in": things } 
    //         }
    //     };
    //     const aggregateQuery = [
    //         {
    //             $group: {
    //                 _id: "$thingName",
    //                 maxTs: { $max: "$timestamp" },
    //                 records: {
    //                     $push: "$$ROOT",
    //                 },
    //             },
    //         },
    //         {
    //             $project: {
    //                 items: {
    //                     $filter: {
    //                         input: "$records",
    //                         as: "records",
    //                         cond: {
    //                             $eq: ["$$records.timestamp", "$maxTs"],
    //                         },
    //                     },
    //                 },
    //             },
    //         },
    //         {
    //             $unwind: "$items",
    //         },
    //         { $project: { _id: 0, thingName: "$_id", data: "$items" } },
    //     ];
    //     const res = await axios.put(doc_db_url+'/'+serviceID+'/nq-tilt-data', { "operation": "aggregate", "aggregate": aggregateQuery });
    //     return res.data;
    // }

    getAntennaDetail = async (things, serviceID) => {
        const payload = {
            filter: {
                thingName: { $in: things },
            },
        };
        const res = await axios.post(doc_db_url+'/'+serviceID+'/nq-tilt-meta', payload);
        return res.data;
    }
}