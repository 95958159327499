import React, { useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import imagineHomeService from "./imagineHomeService"

const ListFiles = ({ data = [], headerTemplate, list }) => {
    const downloadUrlMap = useRef({})
    const serviceId = localStorage.getItem("serviceId")

    const handledown_preview = async (e, rowData) => {
        const fileKey = rowData.filekey
        let downloadurl = ""
        if (fileKey) {
            if (!(fileKey in downloadUrlMap.current)) {
                const formData = new FormData()
                formData.append("serviceId", serviceId)
                formData.append("fileKey", fileKey)

                const downloadURLRES = await imagineHomeService.fetchDownloadFileUrl(formData)
                downloadurl = downloadURLRES?.download_url ?? ""
            }
            else downloadurl = downloadUrlMap.current[fileKey]

            window.open(downloadurl, "_blank")
        }


    }
    return (
        <>
            <div className="col-12">
                <div className="card">
                    <DataTable value={data} rowGroupMode="subheader" groupRowsBy="room" emptyMessage="No Customer/Projects Found"
                        sortMode="single" sortField="room" sortOrder={1} scrollable scrollHeight="400px"
                        rowGroupHeaderTemplate={(e) => headerTemplate(e, list)}>
                        <Column field="" header="" style={{ maxWidth: '50px' }} bodyClassName='ml-2 p-3 text-lg charcoal' body={(row, column) => (row.filename && column.rowIndex + 1)}></Column>
                        <Column field="filename" header="" bodyClassName=' p-3 text-lg' style={{ minWidth: '50%' }} body={(row) => (row.filename ? <span className='charcoal'><i className='pi pi-file pr-2 text-lg'></i>{row.filename ?? ""}</span> : <span className='text-grey-700' style={{color: "#1D1B1C"}}>No Files Found For this Room</span>)}></Column>
                        {/* <Column field="" header="" bodyClassName='ml-2 p-3 text-lg' body={(row) => <i id="preview" style={{ color: "#5295E2" }} className='pi pi-eye text-2xl cursor-pointer' onClick={(e) => handledown_preview(e, row)}></i>}></Column> */}
                        <Column field="roomType" header="" bodyClassName=' p-3 text-lg charcoal' ></Column>
                        <Column field="" header="" bodyClassName='ml-2 p-3 text-lg' body={(row, column) => row.filename && <i id="down" style={{ color: "#5295E2" }} className='pi pi-cloud-download text-3xl cursor-pointer' onClick={(e) => handledown_preview(e, row)}></i>}></Column>

                    </DataTable>
                </div>
            </div>
        </>
    )
}

export default ListFiles