import { httpConstants } from "../lib/constants";

export const httpService = (method, data, url, contentHeader = true) => {
    let header = new Headers();
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    let token =  localStorage.getItem('token') ? localStorage.getItem('token') : urlParams.get("accessToken");
    if (contentHeader) {
        header.append("Content-Type", httpConstants.CONTENT_TYPE.APPLICATION_JSON);
    }
    header.append("Authorization", "Bearer " + token);

    const requestOptions = {
        method: method,
        headers: header
    };

    if (method !== httpConstants.METHOD_TYPE.GET && method !== httpConstants.METHOD_TYPE.DELETE)
       if(data) requestOptions.body = JSON.stringify(data);

    return fetch(url, requestOptions)
        .then(function handleResponse(response) {
            //in case API is down-
            if (!response || !response.ok)
                return Promise.reject("Unable to fetch data");

            return response.text().then(responseText => {

                if (!responseText)
                    return Promise.reject(responseText);

                let data;
                try {
                    data = typeof responseText === 'object' ? responseText : JSON.parse(responseText);
                    if (data && !data.success)
                        return Promise.reject((data && data.responseCode) === 404 ? data : (data && { message: data.message, responseCode: data.responseCode }) || response.statusText);

                } catch (err) {
                    console.error('Class: httpService, Function: fetch ==', err);
                    return Promise.reject(err)
                }
                return data;
            });
        }).catch(function (err) {
            console.error('Class: httpService, Function: fetch ==', err);
            return Promise.reject(err);
        })

};

export async function manageFormRequest(method, requestData, url) {
    let jwtToken = localStorage.getItem('token');

    let header = new Headers();
    header.append("Authorization", "Bearer " + jwtToken);

    let requestOptions = {
        method,
        body: requestData,
        headers: header,
        redirect: 'follow'
    };
    return new Promise(((resolve, reject) => {
        fetch(url, requestOptions).then(
            function handleResponse(response) {

                if (!response || !response.ok)
                    reject("Unable to fetch data");

                return response.text().then(responseText => {

                    if (!responseText)
                        reject(responseText);

                    let data;
                    try {
                        data = JSON.parse(responseText);
                        if (data && !data.success)
                            return reject(data);

                    } catch (err) {
                        return Promise.reject(err)
                    }
                    resolve(data)
                });
            }

        ).catch(function (err) {
            return Promise.reject(err);
        });
    }));
}