import React, { Component } from "react";
import moment from "moment";
import { AssetService } from "../../service/AssetService";
import { FuelSrvice } from "../../service/FuelService";
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";
import { FuelMonitoringService } from "../../service/FuelMonitoringService";
import AppChart from "../ChartsComponents/AppChart";
import { connect } from 'react-redux';
import service from "../../service/dbService";

class FuelPortfolioAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataPopUp: false,
            alertDataPopUp: false,
            tableData: null,
            title: "",
            dgModelWiseChart: null,
            dgSizeWiseChart: null,
            malData: null,
            alertData: null,
            dgAlertChart: null,
            dailyAvgMaxChart: null,
            totalRunHoursChart: null,
            monthlyVolAndRateChart: null,
            fuelInventoryVolAndRateChart: null,
            things: null,
            fuelingDefuelingDataSet: null,
            fuelingDefuelingChart: null,
            totalFuelVol: null,
            totalFuelCost: null,
        };

        this.pieColor = [colorPalettes.color1, colorPalettes.color2, colorPalettes.color3, colorPalettes.color4, colorPalettes.color5];
        this.serviceID = localStorage.getItem("serviceId");
        this.assetsService = new AssetService();
        this.fuelService = new FuelSrvice();
        this.fuelMonitoringService = new FuelMonitoringService();
        this.onChartClick = this.onChartClick.bind(this);
        this.groupBy = this.groupBy.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount = async () => {
        const endDay = moment().subtract(0, "days").endOf("day").format("YYYY-MM-DD");
        const startDay = moment().subtract(30, "days").startOf("day").format("YYYY-MM-DD");

        const payload1 = { filter: { date: { $gt: startDay, $lt: endDay } } };
        let things = [];
        let arr =this.props.thingName.thingNameContainer.length > 0?this.props.thingName.thingNameContainer[this.props.thingName.thingNameContainer.length-1]: [];
        if (arr.length > 0) {
            payload1.filter = {
                $and: [payload1.filter, { "thingName": { $in: arr } }],
            };
        }
        let fuelVolAndCurrencyPayload = {};
        if (arr.length > 0) fuelVolAndCurrencyPayload = {
            filter: {
                "thingName": { $in: arr }
            }
        };
        let promise = [this.fuelService.getFuelConsumption(this.serviceID,startDay, endDay, arr),
        this.fuelService.getFuelVolAndCurrency(this.serviceID, fuelVolAndCurrencyPayload),
        this.fuelService.getFuelingEvents(this.serviceID, payload1)]
        if (arr.length === 0) {
            promise = [service.getThingNames(this.serviceID), ...promise];
        }
        Promise.all(promise).then((temp) => {
            let res = [];
            if (arr.length === 0) {
                res = temp;
            }
            else {
               let first=[];
                this.props.thingName.thingNameContainer.map((item)=>{
                    if(!Array.isArray(item)){
                       first.push(item); 
                    }
                })
                res=[first,...temp];
            }
            res[3].forEach((element) => {
                if (!things.some((item) => item === element.thingName)) {
                    things.push(element.thingName);
                }
            });
            let fuelingDataSet = [];
            let deFuelingDataSet = [];
            let thingName_alias_map = {};
            res[0].forEach(r => {
                thingName_alias_map[r.itemName] = r.alias || ""
            })
            things.forEach((item) => {
                let result = res[3].filter((value) => value.thingName === item);
                let totalFueling = 0;
                let totalDefueling = 0;
                result.forEach((item) => {
                    if (item.type === "fuelling") {
                        totalFueling += item.amount;
                    } else {
                        totalDefueling += item.amount;
                    }
                });

                fuelingDataSet.push(totalFueling);
                deFuelingDataSet.push(totalDefueling);
            });

            let fuelingDefuelingChart = {
                chart: {
                    type: "column",
                },
                title: {
                    text: "Site Wise Fuelling/DeFuelling (Last 30 days)",
                    style: chartHeadingStyle,
                },
                xAxis: {
                    categories: things.map(t => thingName_alias_map[t] || t),
                    crosshair: true,
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: "Volume (Ltr)",
                    },
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} L</b></td></tr>',
                    footerFormat: "</table>",
                    shared: true,
                    useHTML: true,
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                    },
                },

                series: [
                    {
                        name: "Fueling",
                        data: fuelingDataSet,
                        color: colorPalettes.color2,
                    },
                    {
                        name: "Defueling",
                        data: deFuelingDataSet,
                        color: colorPalettes.color4,
                    },
                ],
            };

            let fuelConsumptionData = res[1];

            let fuelConsumptionVol = fuelConsumptionData.map((obj) => parseFloat(obj.fuelConsumed.toFixed(2)));
            let fuelConsumptionRate = fuelConsumptionData.map((obj) => parseFloat(obj.fuelConsumedCost.toFixed(2)));

            let dailyAvgRunHrs = fuelConsumptionData.map((obj) => parseFloat((obj.totalRunHours / 3600 / obj.noOfDays).toFixed(2)));

            let thingNames = fuelConsumptionData.map((obj) => { return thingName_alias_map[obj._id] ? thingName_alias_map[obj._id] : obj._id });
            let maxRunHrs = fuelConsumptionData.map((obj) => parseFloat((obj.maxRunHours / 3600).toFixed(2)));
            let totalRunHrs = fuelConsumptionData.map((obj) => parseFloat((obj.totalRunHours / 3600).toFixed(2)));

            let groupBySize = this.groupBy(res[0], "size");
            let groupByMake = this.groupBy(res[0], "make");

            let fuelInventoryData = res[2];

            // let fuelInventoryVol = fuelInventoryData.map((obj) => parseFloat(obj.fuelLevelCalibrated.toFixed(2)));
            let fuelInventoryVol = [],
                fuelInventoryVolCost = [],
                dgSiteName = [];

            fuelInventoryData.forEach((item) => {
                let today = moment().format("YYYY-MM-DD");
                let tsDate = moment(item.ts).format("YYYY-MM-DD");
                if (moment(tsDate, "YYYY-MM-DD").isSame(moment(today, "YYYY-MM-DD"))) {
                    dgSiteName.push(thingName_alias_map[item.thingName] || item.thingName);
                    fuelInventoryVol.push(item.fuelLevelCalibrated);
                    fuelInventoryVolCost.push(parseFloat((item.fuelrate * item.fuelLevelCalibrated).toFixed(2)));
                }
            });
            let fuelInventoryTotalVol = [],
                fuelInventoryVolTotalCost = [];
            fuelInventoryData.forEach((item) => {
                let today = moment().format("YYYY-MM-DD");
                let tsDate = moment(item.ts).format("YYYY-MM-DD");
                if (moment(tsDate, "YYYY-MM-DD").isSame(moment(today, "YYYY-MM-DD"))) {
                    fuelInventoryTotalVol.push(item.fuelLevelCalibrated);
                    fuelInventoryVolTotalCost.push(item.fuelrate * item.fuelLevelCalibrated);
                }
            });

            let totalFuelVol = 0;
            let totalFuelCost = 0;
            fuelInventoryTotalVol.forEach((item) => (totalFuelVol += item));
            fuelInventoryVolTotalCost.forEach((item) => (totalFuelCost += item));
            this.setState({ totalFuelVol: totalFuelVol.toFixed(2), totalFuelCost: totalFuelCost });

            let dgModelWiseData = [];
            Object.entries(groupByMake).forEach(([key, value], index) => {
                dgModelWiseData.push({ name: value[0].make, y: value.length, color: this.pieColor[index] });
            });

            let dgSizeWise = [];
            Object.entries(groupBySize).forEach(([key, value], index) => {
                dgSizeWise.push({ name: value[0].size, y: value.length, color: this.pieColor[index] });
            });

            let dgModelWiseChart = {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: `DG's Model Wise`,
                    style: chartHeadingStyle,
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y}</b>",
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format: "<b>{point.name}</b>: {point.y}",
                        },
                        showInLegend: true,
                    },
                    series: {
                        point: {
                            events: {
                                click: this.onChartClick.bind(this),
                            },
                        },
                    },
                },
                series: [
                    {
                        name: "Total",
                        colorByPoint: true,
                        data: dgModelWiseData,
                    },
                ],
            };

            let dgSizeWiseChart = {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: `DG's Size Wise`,
                    style: chartHeadingStyle,
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y}</b>",
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format: "<b>{point.name}</b>: {point.y}",
                        },
                        showInLegend: true,
                    },
                    series: {
                        point: {
                            events: {
                                click: this.onChartClick.bind(this),
                            },
                        },
                    },
                },
                series: [
                    {
                        name: "Total",
                        colorByPoint: true,
                        data: dgSizeWise,
                    },
                ],
            };

            let dailyAvgMaxChart = {
                chart: {
                    type: "column",
                },
                title: {
                    text: `Daily Average and Max RunHours (last 30 days)`,
                    style: chartHeadingStyle,
                    align: "center",
                },
                xAxis: [
                    {
                        categories: thingNames,
                        crosshair: true,
                    },
                ],
                yAxis: {
                    title: {
                        text: "Hours",
                    },
                },
                tooltip: {
                    shared: true,
                },
                plotOptions: {
                    series: {
                        pointWidth: 20,
                    },
                },
                series: [
                    {
                        name: "Daily Average Run Hrs",
                        data: dailyAvgRunHrs,
                        color: colorPalettes.color2,
                    },
                    {
                        name: "Max Run Hrs",
                        data: maxRunHrs,
                        color: colorPalettes.color4,
                    },
                ],
            };

            let totalRunHoursChart = {
                chart: {
                    type: "column",
                },
                title: {
                    text: `Total RunHours (last 30 days)`,
                    style: chartHeadingStyle,
                    align: "center",
                },
                xAxis: [
                    {
                        categories: thingNames,
                        crosshair: true,
                    },
                ],
                yAxis: {
                    title: {
                        text: "Hours",
                    },
                },
                tooltip: {
                    shared: true,
                },
                plotOptions: {
                    series: {
                        pointWidth: 20,
                    },
                },
                series: [
                    {
                        name: "Total Run Hrs",
                        data: totalRunHrs,
                        color: colorPalettes.color2,
                    },
                ],
            };

            let monthlyVolAndRateChart = {
                chart: {
                    type: "column",
                },
                title: {
                    text: `Monthly Fuel Consumption (Volume and Cost)`,
                    style: chartHeadingStyle,
                    align: "center",
                },
                xAxis: [
                    {
                        categories: thingNames,
                        crosshair: true,
                    },
                ],
                yAxis: [
                    {
                        title: {
                            text: "Consumption Volume in litres",
                        },
                    },
                    {
                        title: {
                            text: "Consumption Currency in Rs",
                        },
                        opposite: true,
                    },
                ],
                plotOptions: {
                    series: {
                        pointWidth: 20,
                    },
                },
                series: [
                    {
                        type: "column",
                        data: fuelConsumptionVol,
                        name: "Volume",
                        color: colorPalettes.color2,
                    },
                    {
                        type: "column",
                        data: fuelConsumptionRate,
                        name: "Cost",
                        yAxis: 1,
                        color: colorPalettes.color4,
                    },
                ],
            };

            let fuelInventoryVolAndRateChart = {
                chart: {
                    type: "column",
                },
                title: {
                    text: `Current Fuel Inventory (Volume and Cost)`,
                    style: chartHeadingStyle,
                    align: "center",
                },
                xAxis: [
                    {
                        categories: dgSiteName,
                        crosshair: true,
                    },
                ],
                yAxis: [
                    {
                        title: {
                            text: "Fuel Volume in litres",
                        },
                    },
                    {
                        title: {
                            text: "Fuel Currency in Rs",
                        },
                        opposite: true,
                    },
                ],
                plotOptions: {
                    series: {
                        pointWidth: 20,
                    },
                },
                series: [
                    {
                        type: "column",
                        data: fuelInventoryVol,
                        name: "Volume",
                        color: colorPalettes.color2,
                    },
                    {
                        type: "column",
                        data: fuelInventoryVolCost,
                        name: "Currency",
                        yAxis: 1,
                        color: colorPalettes.color4,
                    },
                ],
            };
            this.setState({ fuelingDefuelingChart, dgModelWiseChart, dgSizeWiseChart, dailyAvgMaxChart, totalRunHoursChart, monthlyVolAndRateChart, fuelInventoryVolAndRateChart });
        });
    };

    groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
        }, {});
    };
    // onAlertChartClick = (alertType) => {
    //     let alertTableData = this.state.alertData.filter(item => item.alarmType === alertType.point.name);
    //     this.setState({title: "DG's with " + alertType.point.name, alertDataPopUp: true, malData: alertTableData});
    // }

    onChartClick = (state) => {
        if (state.point.name === "Low usage") {
            let dgs = this.state.dgMaxAvg.filter((item) => item.avgRunHour < 2);
            let things = dgs.map((item) => {
                return item._id.thingName;
            });
            let payload = {
                filter: {
                    thingName: {
                        $in: things,
                    },
                },
            };
            this.assetsService.getAssets(this.serviceID, payload).then((res) => {
                let tableData = res.map((item) => {
                    let index = dgs.findIndex((elm) => elm._id.thingName === item.thingName);
                    if (index > -1) {
                        return {
                            thingName: item.thingName,
                            state: item.location.state,
                            city: item.location.city,
                            pincode: item.location.zip,
                            siteId: item.location.siteId,
                            avgRunHr: dgs[index].avgRunHour,
                            maxRunHr: dgs[index].maxRunHour,
                            totalRunHr: dgs[index].totalRunHour,
                        };
                    }
                });
                let title = "Low usage DG's List";
                this.setState({ dataPopUp: true, tableData, title });
            });
        } else if (state.point.name === "Moderate usage") {
            let dgs = this.state.dgMaxAvg.filter((item) => item.avgRunHour >= 2 && item.avgRunHour <= 6);
            let things = dgs.map((item) => {
                return item._id.thingName;
            });
            let payload = {
                filter: {
                    thingName: {
                        $in: things,
                    },
                },
            };
            this.assetsService.getAssets(this.serviceID, payload).then((res) => {
                let tableData = res.map((item) => {
                    let index = dgs.findIndex((elm) => elm._id.thingName === item.thingName);
                    if (index > -1) {
                        return {
                            thingName: item.thingName,
                            state: item.location.state,
                            city: item.location.city,
                            pincode: item.location.zip,
                            siteId: item.location.siteId,
                            avgRunHr: dgs[index].avgRunHour,
                            maxRunHr: dgs[index].maxRunHour,
                            totalRunHr: dgs[index].totalRunHour,
                        };
                    }
                });
                let title = "Moderate usage DG's List";
                this.setState({ dataPopUp: true, tableData, title });
            });
        } else if (state.point.name === "High usage") {
            let dgs = this.state.dgMaxAvg.filter((item) => item.avgRunHour > 6);
            let things = dgs.map((item) => {
                return item._id.thingName;
            });
            let payload = {
                filter: {
                    thingName: {
                        $in: things,
                    },
                },
            };
            this.assetsService.getAssets(this.serviceID, payload).then((res) => {
                let tableData = res.map((item) => {
                    let index = dgs.findIndex((elm) => elm._id.thingName === item.thingName);
                    if (index > -1) {
                        return {
                            thingName: item.thingName,
                            state: item.location.state,
                            city: item.location.city,
                            pincode: item.location.zip,
                            siteId: item.location.siteId,
                            avgRunHr: dgs[index].avgRunHour,
                            maxRunHr: dgs[index].maxRunHour,
                            totalRunHr: dgs[index].totalRunHour,
                        };
                    }
                });
                let title = "High usage DG's List";
                this.setState({ dataPopUp: true, tableData, title });
            });
        }
    };

    onHide(name) {
        this.setState({
            [`${name}`]: false,
        });
    }

    render = () => {
        return (
            <div className="grid">
                <div className="col-12 lg:col-6">
                    {this.state.dgModelWiseChart ? (
                        <div className="card">
                            <AppChart chartData={this.state.dgModelWiseChart} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-6">
                    {this.state.dgSizeWiseChart ? (
                        <div className="card">
                            <AppChart chartData={this.state.dgSizeWiseChart} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-6">{this.state.dailyAvgMaxChart ? <AppChart chartData={this.state.dailyAvgMaxChart} /> : null}</div>
                <div className="col-12 lg:col-6">{this.state.totalRunHoursChart ? <AppChart chartData={this.state.totalRunHoursChart} /> : null}</div>
                <div className="col-12 lg:col-6">{this.state.monthlyVolAndRateChart ? <AppChart chartData={this.state.monthlyVolAndRateChart} /> : null}</div>
                <div className="col-12 lg:col-6">{this.state.fuelingDefuelingChart ? <AppChart chartData={this.state.fuelingDefuelingChart} /> : null}</div>
                {/* <div className="col-12 lg:col-12"> */}
                <div className="col-12 lg:col-12 ">
                    <div className="grid card flex justify-content-evenly">
                        <div className="col-12 lg:col-8">{this.state.fuelInventoryVolAndRateChart ? <AppChart chartData={this.state.fuelInventoryVolAndRateChart} /> : null}</div>

                        <div className="col-12 lg:col-3  ">
                            <div className="border-solid border-green-50 p-6">
                                <p className="font-bold">Total Current Fuel Inventory</p>
                                <h3 className="text-primary">{this.state.totalFuelVol} L</h3>
                            </div>
                            <div className="border-solid border-green-50 mt-3 p-6">
                                <p className="font-bold">Total Current Fuel inventory Value</p>
                                <h3 className="text-primary">{new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(this.state.totalFuelCost)}</h3>
                            </div>
                        </div>
                    </div>

                    {/* </div> */}
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        thingName: state
    };
};


export default connect(mapStateToProps)(FuelPortfolioAnalysis);
