import service from "../service/dbService";
let initialState = [];
    if((localStorage.getItem("states") && JSON.parse(localStorage.getItem("states")).length>0) || (localStorage.getItem("cities")&& JSON.parse(localStorage.getItem("cities")).length>0) ||(localStorage.getItem("zipcodes")&& JSON.parse(localStorage.getItem("zipcodes")).length>0)){
        let res = await service.getThingNames(localStorage.getItem("serviceId"));
        let serviceBlock = JSON.parse(localStorage.getItem("service")).serviceBlock;
        let thingName=res.map((item)=>{
            return item[serviceBlock+"Name"];
        })
           initialState=[...res,thingName];
    }
const thingNameContainer = (state = initialState, action) => {
    switch (action.type) {
        case "SETTHINGNAME":
            return (state = action.payload);
        default:
            return state;
    }
};
export default thingNameContainer;