import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { chartHeadingStyle } from "../../utilities/constant";

const AggressiveDriving = ({ chartData }) => {
    const [state, setState] = useState(null);

    useEffect(() => {
        const option = {
            chart: {
                type: "column",
            },
            title: {
                text: "",
                style: chartHeadingStyle,
            },
            xAxis: {
                categories: [],
            },
            yAxis: {
                min: 0,
                title: {
                    text: chartData.yAxisTitle ||  "Driving Events",
                },
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                shared: true,
            },
            plotOptions: {
                column: {
                    stacking: "normal",
                    dataLabels: {
                        enabled: true,
                    },
                },

                series: {
                    pointWidth: 40,
                },
            },
            series: [],
        };
        option.series = chartData.data;
        option.xAxis.categories = chartData.category;
        option.title.text = chartData.title;
        setState(option);
    }, []);

    return state ? <HighchartsReact highcharts={Highcharts} options={state} /> : null;
};

export default AggressiveDriving;
