import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { chartHeadingStyle } from "../../utilities/constant";

const BestDrivenVehicle = ({ chartData }) => {
    const [state, setState] = useState(null);

    useEffect(() => {
        const option = {
            chart: {
                type: "column",
            },
            title: {
                text: "",
                style: chartHeadingStyle,
                align: "center",
            },
            xAxis: [
                {
                    categories: [],
                    crosshair: true,
                },
            ],
            yAxis: [
                {
                    title: {
                        text: "Driving Score",
                    },
                },
            ],
            plotOptions: {
                series: {
                    pointWidth: 20,
                },
            },
            series: [],
        };
        option.series = chartData.data;
        option.xAxis[0].categories = chartData.category;
        option.title.text = chartData.title;
        setState(option);
    }, []);

    return state ? <HighchartsReact highcharts={Highcharts} options={state} /> : null;
};

export default BestDrivenVehicle;
