import React, { Component } from "react";
import { Button } from "primereact/button";
import { TowerService } from "../../service/TowerService";
import StateReportComponent from "./CandedReports/StateReportComponent";
import CityReportComponent from "./CandedReports/CityReportComponent";
import SiteReportComponent from "./CandedReports/SiteReportComponent";
import SiteComponent from "./SiteComponents";
import { Link } from "react-router-dom";

class TowerTransitionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            towers: null,
            setReport: false,
            stateData: null,
            stateLevel: false,
            cityLevel: false,
            cityData: null,
            position: null,
            siteLevel: false,
            siteData: null,
            isSite: false,
            site: null,
            currentWeather: null,
            noData: false,
        };
        this.towerService = new TowerService();
        this.pageTitle = "";
        this.bradeCrum = [];
    }

    componentDidMount() {
        if (this.props.towers) this.initState(this.props.towers);
        else {
            this.towerService.getTowerQuery({}).then((data) => {
                if (data && data.length) {
                    this.initState(data);
                } else {
                    this.setState({ noData: true });
                }
            });
        }
    }

    initState = (towers) => {
        let t_state = [];
        towers.forEach((item) => {
            if (!t_state.includes(item.siteDetails.location.state)) {
                t_state.push(item.siteDetails.location.state);
            }
        });

        let t_state_count = [];
        let t_position = [];
        t_state.forEach((item) => {
            let details = [];
            let t_state_length = towers.filter((elm) => elm.siteDetails.location.state === item);
            let typeWiseCount = {};
            t_state_length.forEach((tower) => {
                t_position.push({
                    customerSiteid: tower.customerSiteid,
                    myxTowerid: tower.myxTowerid,
                    pos: {
                        lat: tower.siteDetails.location.latitude,
                        lng: tower.siteDetails.location.longitude,
                    },
                });

                if (tower.towerDetails.towerConfig.towerType) {
                    let keyNm = tower.towerDetails.towerConfig.towerType;

                    if (tower.towerDetails.towerConfig.towerSubType && tower.towerDetails.towerConfig.towerType !== tower.towerDetails.towerConfig.towerSubType) keyNm = tower.towerDetails.towerConfig.towerSubType + "/" + tower.towerDetails.towerConfig.towerType;
                    if (typeWiseCount[keyNm]) typeWiseCount[keyNm] += 1;
                    else typeWiseCount[keyNm] = 1;
                }
            });

            if (Object.keys(typeWiseCount).length) {
                Object.keys(typeWiseCount).forEach((t) => {
                    details.push({ type: t, total: typeWiseCount[t] });
                });
            }

            t_state_count.push({
                circleName: item,
                totalSite: t_state_length.length,
                details: [...details],
                position: [...t_position],
            });
        });
        this.setState({
            towers: towers,
            stateData: t_state_count,
            position: t_position,
            setReport: true,
            stateLevel: true,
            cityLevel: false,
            siteLevel: false,
        });
    };

    onStateClick = (state, changes) => {
        if (!(changes && changes.nobreadcrum)) this.bradeCrum.push({ label: state, type: "state" });
        let towers = this.state.towers.filter((item) => item.siteDetails.location.state === state);
        let t_city = [];
        towers.forEach((item) => {
            if (!t_city.includes(item.siteDetails.location.city)) {
                t_city.push(item.siteDetails.location.city);
            }
        });

        let t_city_count = [];
        let t_position = [];
        t_city.forEach((item) => {
            let details = [];
            let t_city_length = towers.filter((elm) => elm.siteDetails.location.city === item);
            let typeWiseCount = {};
            t_city_length.forEach((tower) => {
                t_position.push({
                    customerSiteid: tower.customerSiteid,
                    myxTowerid: tower.myxTowerid,
                    pos: {
                        lat: tower.siteDetails.location.latitude,
                        lng: tower.siteDetails.location.longitude,
                    },
                });

                if (tower.towerDetails.towerConfig.towerType) {
                    let keyNm = tower.towerDetails.towerConfig.towerType;

                    if (tower.towerDetails.towerConfig.towerSubType && tower.towerDetails.towerConfig.towerType !== tower.towerDetails.towerConfig.towerSubType) keyNm = tower.towerDetails.towerConfig.towerSubType + "/" + tower.towerDetails.towerConfig.towerType;
                    if (typeWiseCount[keyNm]) typeWiseCount[keyNm] += 1;
                    else typeWiseCount[keyNm] = 1;
                }
            });

            if (Object.keys(typeWiseCount).length) {
                Object.keys(typeWiseCount).forEach((t) => {
                    details.push({ type: t, total: typeWiseCount[t] });
                });
            }

            t_city_count.push({
                circleName: item,
                totalSite: t_city_length.length,
                details: [...details],
                position: [...t_position],
            });
        });

        let current_weather = t_city.map(async (item) => {
            let weather = await this.towerService.getWeatherByCityName(item);
            return weather;
        });

        Promise.all(current_weather)
            .then((response) => {
                this.setState({
                    towers: towers,
                    currentWeather: response,
                    cityData: t_city_count,
                    position: t_position,
                    stateLevel: false,
                    setReport: true,
                    cityLevel: true,
                    siteLevel: false,
                });
            })
            .catch((reason) => {
                this.setState({
                    towers: towers,
                    currentWeather: [],
                    cityData: t_city_count,
                    position: t_position,
                    stateLevel: false,
                    setReport: true,
                    cityLevel: true,
                    siteLevel: false,
                });
            });
    };

    onCityClick = (city) => {
        this.bradeCrum.push({ label: city, type: "city" });
        let t_city_level = this.state.towers.filter((item) => item.siteDetails.location.city === city);
        this.setState({
            setReport: true,
            cityLevel: false,
            siteLevel: true,
            siteData: t_city_level,
        });
    };
    onSiteClick = (id) => {
        this.bradeCrum.push({ label: id, type: "site" });
        let index = this.state.towers.findIndex((item) => item.customerSiteid === id);
        if (index > -1) {
            this.setState({
                setReport: true,
                cityLevel: false,
                siteLevel: false,
                isSite: true,
                site: this.state.towers[index],
            });
        }
    };
    bradeCrumClick = (type, name) => {
        if (type === "state") {
            if (this.state.cityData && this.state.cityData.length) {
                this.setState({
                    cityLevel: true,
                    siteLevel: false,
                    isSite: false,
                });
            } else this.onStateClick(name, { nobreadcrum: true });

            this.bradeCrum = [];
            this.bradeCrum.push({
                label: name,
                type: type,
            });
        }
        if (type === "city") {
            if (this.state.siteData && this.state.siteData.length) {
                this.setState({
                    cityLevel: false,
                    siteLevel: true,
                    isSite: false,
                });
            } else {
                let t_city_level = this.state.towers.filter((item) => item.siteDetails.location.city === name);
                this.setState({
                    setReport: true,
                    cityLevel: false,
                    siteLevel: true,
                    siteData: t_city_level,
                });
            }
            if (!this.state.siteLevel) {
                this.bradeCrum.splice(-1);
            }
        }
    };

    handelHomeClick = () => {
        // this.bradeCrum = [];
        // this.setState({
        //     stateLevel: true,
        //     cityLevel: false,
        //     siteLevel: false,
        //     isSite: false,
        // })
        window.location.href = "/";
    };

    onMarkerClick = (data) => {
        let towerData = this.state.towers.find((t) => t.customerSiteid === data.customerSiteid);
        this.bradeCrum = [
            { label: towerData.siteDetails.location.state, type: "state" },
            { label: towerData.siteDetails.location.city, type: "city" },
            { label: data.customerSiteid, type: "site" },
        ];
        this.setState({
            stateLevel: false,
            cityLevel: false,
            siteLevel: false,
            setReport: true,
            isSite: true,
            site: towerData,
        });
    };

    render = () =>
        this.state.noData ? (
            <div className="blank-grid">
                <span className="pi pi-search" style={{ fontSize: "40px", marginBottom: "1.5rem" }}></span>
                <span>No digitized site found at your assigned location</span>
            </div>
        ) : !this.state.setReport & (this.state.towers === null) ? null : (
            <div className="grid">
                <div className="col-12">
                    <h5>{this.props.title}</h5>
                </div>
                <div className="col-12" style={{ position: "relative" }}>
                    {this.props.siteoverview ? null : <Button label="Back" onClick={this.props.hadelBack} className="mr-2 mb-2" style={{ position: "absolute", right: "2px", top: "9px" }} />}
                    <nav className="app-breadcrumb p-breadcrumb p-component" aria-label="Breadcrumb">
                        <ul>
                            <li className="p-breadcrumb-home" onClick={this.handelHomeClick}>
                                <span className="p-menuitem-icon pi pi-home"></span>
                            </li>
                            {this.bradeCrum.map((elm) => (
                                <li className="" key={elm.type} onClick={this.bradeCrumClick.bind(this, elm.type, elm.label)}>
                                    <Link to="#" className="p-menuitem-link">
                                        <span className="p-menuitem-text">{elm.label}</span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
                {this.state.stateLevel ? <StateReportComponent data={this.state.stateData} position={this.state.position} onStateClick={this.onStateClick} onMarkerClick={this.onMarkerClick} siteoverview={this.props.siteoverview} /> : null}
                {this.state.cityLevel ? <CityReportComponent data={this.state.cityData} weather={this.state.currentWeather} position={this.state.position} onMarkerClick={this.onMarkerClick} onCityClick={this.onCityClick} /> : null}
                {this.state.siteLevel ? <SiteReportComponent data={this.state.siteData} onMarkerClick={this.onMarkerClick} onSiteClick={this.onSiteClick} /> : null}
                {this.state.isSite ? (
                    <div className="col-12">
                        <SiteComponent data={this.state.site} />
                    </div>
                ) : null}
            </div>
        );
}

export default TowerTransitionComponent;
