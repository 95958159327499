import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { chartHeadingStyle } from "../../utilities/constant";

const ItemCurrentStatus = ({ chartData }) => {
   
        const option = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            title: {
                text: "",
                style: chartHeadingStyle,
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.y}</b>",
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b>: {point.y}",
                        style: {
                            fontSize: "14px",
                        },
                    },
                    showInLegend: true,
                },
            },
            series: [
                {
                    name: "Total",
                    colorByPoint: true,
                    data: [],
                },
            ],
        };
        option.series[0].data = chartData.data;
        option.title.text = chartData.title;

    return <HighchartsReact highcharts={Highcharts} options={option} />;
};

export default ItemCurrentStatus;
