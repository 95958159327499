import React, { useEffect, useState, useRef } from "react";
import { Panel } from "primereact/panel";
import { mapKey } from "../../utilities/constant";
import { Ripple } from "primereact/ripple";
import DBService from "../../service/dbService";
import { Skeleton } from "primereact/skeleton";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { threeCircleLoader } from "../../utilities/UICommonFunc";
import { WINDZONE_COLORS, WINDZONE_SPEED, SITE_MAPPING_SERVICE_LOGO } from "../../lib/constants";
const svgMarkup =
    '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path style="fill:#01010100; stroke:none;" d="M0 0L0 32L32 32L32 0L0 0z"/><path style="fill:#008100; stroke:none;" d="M15 26L17 26C19.6885 21.918 23.6112 17.9434 24.5918 13C26.4889 3.43692 13.1676 -2.78313 8.17901 6.1088C4.21305 13.1779 11.2793 20.6202 15 26z"/><path style="fill:#ffffff; stroke:none;" d="M15.2593 8.05941C12.4857 8.60038 13.1027 13.3731 15.9954 12.963C19.1715 12.5127 18.4131 7.44428 15.2593 8.05941z"/><path style="fill:#f48221; stroke:none;" d="M24 7L25 8L24 7M10 19L11 20L10 19M19 22L20 23L19 22z"/><path style="fill:#c0a7a5; stroke:none;" d="M9 23L10 24L9 23z"/><path style="fill:#bcc5c800; stroke:none;" d="M17 27L15 27C12.816 23.4744 3.67966 22.9556 6.17593 27.6242C8.18558 31.3828 25.24 31.7858 26.0787 26.9051C27.0422 21.2976 18.5828 24.1389 17 27z"/><path style="fill:#c0a7a5; stroke:none;" d="M13 23L14 24L13 23M18 23L19 24L18 23z"/><path style="fill:#f48221; stroke:none;" d="M14 24L15 25L14 24M17 24L18 25L17 24z"/><path style="fill:#c0a7a500; stroke:none;" d="M14 25L15 26L14 25M4 26L5 27L4 26M15.6667 26.3333L16.3333 26.6667L15.6667 26.3333M27 26L28 27L27 26z"/></svg>';
const selIconSvg =
    '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path style="fill:#01010100; stroke:none;" d="M0 0L0 32L32 32L32 0L0 0z"/><path style="fill:#050c38; stroke:none;" d="M15 26L17 26C19.6885 21.918 23.6112 17.9434 24.5918 13C26.4889 3.43692 13.1676 -2.78313 8.17901 6.1088C4.21305 13.1779 11.2793 20.6202 15 26z"/><path style="fill:#ffffff; stroke:none;" d="M15.2593 8.05941C12.4857 8.60038 13.1027 13.3731 15.9954 12.963C19.1715 12.5127 18.4131 7.44428 15.2593 8.05941z"/><path style="fill:#f48221; stroke:none;" d="M24 7L25 8L24 7M10 19L11 20L10 19M19 22L20 23L19 22z"/><path style="fill:#c0a7a5; stroke:none;" d="M9 23L10 24L9 23z"/><path style="fill:#bcc5c800; stroke:none;" d="M17 27L15 27C12.816 23.4744 3.67966 22.9556 6.17593 27.6242C8.18558 31.3828 25.24 31.7858 26.0787 26.9051C27.0422 21.2976 18.5828 24.1389 17 27z"/><path style="fill:#c0a7a5; stroke:none;" d="M13 23L14 24L13 23M18 23L19 24L18 23z"/><path style="fill:#f48221; stroke:none;" d="M14 24L15 25L14 24M17 24L18 25L17 24z"/><path style="fill:#c0a7a500; stroke:none;" d="M14 25L15 26L14 25M4 26L5 27L4 26M15.6667 26.3333L16.3333 26.6667L15.6667 26.3333M27 26L28 27L27 26z"/></svg>';
var citytimerId;
var ui;

const SiteDashboard = () => {
    const [states, setStates] = useState(null);
    const [cities, setCities] = useState(null);
    const [zipcodes, setZipcodes] = useState(null);
    const [map, setMap] = useState(null);
    const [group, setGroup] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedZip, setSelectedZip] = useState(null);
    const [errMsg, setErrMsg] = useState(null);
    const mapref = React.createRef();
    const sitemapref = React.createRef();
    const [siteView, setSiteView] = useState(null);
    const [sites, setSites] = useState(null);
    const [siteHereMap, setSiteHereMap] = useState(null);
    const [siteGroup, setSiteGroup] = useState(null);
    const [selectedSite, setSelectedSite] = useState(null);
    const [siteH, setSiteH] = useState(null);
    const [siteUI, setSiteUI] = useState(null);
    const [search, setSearch] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);
    const prevSelectedSite = usePrevious(selectedSite);
    const [cardWidth, setCardWidth] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [wzPolygons, setWZPolygons] = useState([]);
    const [infoMsg, setInfoMsg] = useState("");
    const [custLogo, setCustLogo] = useState("");
    const [disDownloadBtn, setDisDownloadBtn] = useState(false);
    const serviceId = localStorage.getItem("serviceId");
    const myStates = JSON.parse(localStorage.getItem("states"));
    const [windZones, setWindZones] = useState({})
    const zonesCount = useRef()
    const service = JSON.parse(localStorage.getItem("service"))

    useEffect(() => {
        let imgs = service.customer.images;
        if (imgs) setCustLogo(imgs[imgs.length - 1]);

        //Need to implement loader
        getStateWiseCount();
    }, []);

    const getStateWiseCount = async () => {
        let query = {
            projection: { latlongs: 0, _id: 0 },
        };
        if (myStates.length > 0) {
            query = {
                filter: {
                    st: {
                        $in: myStates,
                    },
                },
                ...query,
            };
        }
        Promise.all([DBService.getStateWiseSites(query), DBService.getWindzonePolygons(service?.location?.operatingCountry.toLowerCase())]).then((res) => {
            if (res && res.length && res[0] && res[0].length) {
                if (res[1] && res[1].length) {
                    let windzones = {}
                    res[1].forEach((item, index) => {
                        if (!(item.zone in windzones))
                            windzones[item.zone] ={
                                zone: item.zone,
                                windspeed: item.windSpeed + " kmph",
                            }
                    })
                    zonesCount.current = Object.keys(windzones).length
                    setWindZones(windzones)
                }
                setWZPolygons(res[1]);
                if (res[0] && res[0] instanceof Array) {
                    setStates(res[0]);
                }
                let width = document.getElementById("card-map") ? document.getElementById("card-map").offsetWidth : null;
                if(width) setCardWidth(width);
            } else {
                setInfoMsg("Sites upload pending");
            }
        });
    };

    useEffect(() => {
        if (states && states.length) {
            getMap(states);
        }
    }, [states && cardWidth]);

    const getMap = (data) => {
        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(mapref.current, defaultLayers.vector.normal.map, {
            center: { lat: 20.5937, lng: 78.9629 },
            zoom: 5,
            pixelRatio: window.devicePixelRatio || 1,
        });
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        ui = H.ui.UI.createDefault(map, defaultLayers);
        let markers = [];
        let group = new H.map.Group();
        data.forEach((d) => {
            let mark = getMarker(H, { lat: d.midlat, lng: d.midlong }, d, ui);
            markers.push(mark);
        });
        if (wzPolygons && wzPolygons.length) {
            wzPolygons.forEach((g) => {
                let polygon = H.util.wkt.toGeometry(g.data);
                group.addObject(new H.map.Polygon(polygon, { style: { strokeColor: WINDZONE_COLORS[g.zone], fillColor: WINDZONE_COLORS[g.zone] }, data: g.name }));
            });
        }

        group.addObjects(markers);
        map.addObject(group);
        map.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
        });
        setGroup(group);
        setMap(map);
    };

    const getMarker = (H, station, data, ui) => {
        var weight = data.count.toString().length;
        if (weight < 3) weight = 3;
        // Calculate circle size
        let radius = window.innerWidth < 430 ? weight * 7 : weight * 5;
        let diameter = radius * 2;
        let topPadding = window.innerWidth > 1240 ? 60 : 30;
        console.log(window.innerWidth, topPadding)
        // topPadding = 60
        let xPadding = window.innerWidth < 430 ? 5 : 15
        let fontSize = window.innerWidth > 430 ? 15 : weight > 3 ? 22 : 35
        let clusterSvgIcon = '<svg xmlns="http://www.w3.org/2000/svg">';
        clusterSvgIcon += "<g>";
        clusterSvgIcon += '<circle cx="{radius}px" cy="{radius}px" r="{radius}px" fill="#9b3a5b" />';
        clusterSvgIcon += '<text  text-anchor="start" x="' + xPadding + '%" y="' + topPadding + '%" fill="white">{count}</text>';
        clusterSvgIcon += "</g>";
        clusterSvgIcon += "</svg>";

        let svgString = clusterSvgIcon
            .replace(/\{count\}/g, data.count)
            .replace(/\{radius\}/g, radius)
            .replace(/\{fontSize\}/g, fontSize);
        let clusterIcon = new H.map.Icon(svgString, {
            size: { w: diameter, h: diameter },
            anchor: { x: radius, y: radius },
        });
        // let bearsIcon = new H.map.Icon(clusterSvgIcon);
        let marker = new H.map.Marker(station, { icon: clusterIcon, data: data });
        let bubble;

        marker.addEventListener(
            "tap",
            function (evt) {
                let data = evt.target.getData();

                bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                    content: `<div id="vehicle-content"><div id="vehicle-info"><div id="info">${data.st} </div></div></div>`,
                });
                bubble.addClass("info_bubble");
                ui.addBubble(bubble);
                setShowLoader(true);
                setSelectedState([data]);
                getAllSitesForState([data.st]);
            },
            false
        );

        return marker;
    };

    const concatLatlongsArray = (res) => {
        if (res && res.length) {
            setBtnLoading(false);
            let latLongs = [];
            res.map((r) => {
                latLongs = latLongs.concat(r.latlongs);
            });
            if (latLongs && latLongs instanceof Array && latLongs.length) {
                setSites(latLongs);
                if (siteView) {
                    setSiteView(false);
                    setSiteView(true);
                } else {
                    setSiteView(true);
                }
            } else {
                setShowLoader(false);
                setErrMsg("Don't have sites with filters");
            }
        }
    };
    const getAllSitesForState = async (states) => {
        let res = await DBService.getStateWiseSites({ filter: { st: { $in: states } }, projection: { _id: 0, latlongs: 1 } });
        concatLatlongsArray(res);
    };

    const getAllSitesForCities = async (cities) => {
        let res = await DBService.getSitesCities({ filter: { ct: { $in: cities } }, projection: { _id: 0, latlongs: 1 } });
        concatLatlongsArray(res);
    };

    const getAllSitesForZipCodes = async (zipcodes) => {
        let res = await DBService.getSitesZipcodes({ filter: { zip: { $in: zipcodes } }, projection: { _id: 0, latlongs: 1 } });
        concatLatlongsArray(res);
    };

    const capture = (resultContainer, map, ui) => {
        // Capturing area of the map is asynchronous, callback function receives HTML5 canvas
        // element with desired map area rendered on it.
        // We also pass an H.ui.UI reference in order to see the ScaleBar in the output.
        // If dimensions are omitted, whole veiw port will be captured

        map.capture(
            function (canvas) {
                if (canvas) {
                    resultContainer.innerHTML = "";
                    resultContainer.appendChild(canvas);
                    window
                        .html2canvas(document.getElementById("windZoneLegends"), {
                            allowTaint: true,
                            useCORS: true,
                        })
                        .then(function (windCanvas) {
                            resultContainer.appendChild(windCanvas);

                            // document.getElementById('output').appendChild(windCanvas)

                            window
                                .html2canvas(document.getElementById("mapOverlay"), {
                                    allowTaint: true,
                                    useCORS: true,
                                })
                                .then(function (overlayCanvas) {
                                    // document.getElementById('output').appendChild(overlayCanvas)
                                    var ctx = canvas.getContext("2d");
                                    ctx.drawImage(overlayCanvas, 0, 0);

                                    window
                                        .html2canvas(document.getElementById("panel"), {
                                            allowTaint: true,
                                            useCORS: true,
                                        })
                                        .then(function (panelCanvas) {
                                            let image = panelCanvas.toDataURL("image/png", 0.5);
                                            const link = document.createElement("a");
                                            link.download = "my-image-name.png";
                                            link.href = image;
                                            link.click();
                                            setDisDownloadBtn(false);
                                        });
                                })
                                .catch((oe) => {
                                    console.error(oe);
                                });
                        })
                        .catch((we) => {
                            console.error(we);
                        });
                } else {
                    // For example when map is in Panorama mode
                    resultContainer.innerHTML = "Downloading is not supported";
                }
            },
            [ui]
        );
    };

    useEffect(() => {
        if (siteView) {
            if (siteHereMap) {
                document.getElementById("mapref").style.display = "none";
                document.getElementById("sitemapref").style.display = "block";
                let res = siteHereMap.removeObject(siteGroup);
                let markers = [];
                let group = new siteH.map.Group();

                sites.forEach((s) => {
                    let mark = addSiteMarker(siteH, s);
                    markers.push(mark);
                });
                group.addObjects(markers);
                siteHereMap.addObject(group);

                if (wzPolygons && wzPolygons.length) {
                    wzPolygons.forEach((g) => {
                        let polygon = siteH.util.wkt.toGeometry(g.data);
                        siteHereMap.addObject(new siteH.map.Polygon(polygon, { style: { strokeColor: WINDZONE_COLORS[g.zone], fillColor: WINDZONE_COLORS[g.zone] }, data: g.name }));
                    });
                }

                let boundObj = { bounds: group.getBoundingBox() };
                if (markers.length === 1) boundObj.zoom = 13;
                siteHereMap.getViewModel().setLookAtData(boundObj);

                setSiteHereMap(siteHereMap);
                setSiteGroup(group);
                setShowLoader(false);
            } else {
                const H = window.H;
                const platform = new H.service.Platform({
                    apikey: mapKey,
                });
                const defaultLayers = platform.createDefaultLayers();
                document.getElementById("mapref").style.display = "none";
                document.getElementById("sitemapref").style.display = "block";

                const map = new H.Map(sitemapref.current, defaultLayers.vector.normal.map, {
                    center: { lat: 20.5937, lng: 78.9629 },
                    zoom: 5,
                    pixelRatio: window.devicePixelRatio || 1,
                });
                new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
                const ui = H.ui.UI.createDefault(map, defaultLayers);
                let markers = [];
                let group = new H.map.Group();

                sites.forEach((s) => {
                    let mark = addSiteMarker(H, s);
                    markers.push(mark);
                });
                group.addObjects(markers);
                if (wzPolygons && wzPolygons.length) {
                    wzPolygons.forEach((g) => {
                        let polygon = H.util.wkt.toGeometry(g.data);
                        map.addObject(new H.map.Polygon(polygon, { style: { strokeColor: WINDZONE_COLORS[g.zone], fillColor: WINDZONE_COLORS[g.zone] }, data: g.name }));
                    });
                }

                map.addObject(group);
                let boundObj = { bounds: group.getBoundingBox() };
                if (markers.length === 1) boundObj.zoom = 13;
                map.getViewModel().setLookAtData(boundObj);
                setSiteH(H);
                setSiteHereMap(map);
                setSiteGroup(group);
                setSiteUI(ui);
                setShowLoader(false);
            }
        }
    }, [siteView]);

    const addSiteMarker = (H, data) => {
        let bearsIcon = new H.map.Icon(svgMarkup);
        let marker = new H.map.Marker({ lat: data.lat, lng: data.long }, { icon: bearsIcon, data: data });
        return marker;
    };

    useEffect(() => {
        if (siteGroup) {
            let objects = siteGroup.getObjects();
            if (objects.length) {
                objects.forEach((o) => {
                    o.addEventListener("tap", (evt) => getSiteDetails(evt, siteH), false);
                });
            }
        }
    }, [siteGroup]);

    const addClickedMarker = (H, data) => {
        let bearsIcon = new H.map.Icon(selIconSvg);
        let marker = new H.map.Marker({ lat: data.lat, lng: data.long }, { icon: bearsIcon, data: data });
        siteGroup.addObject(marker);
        siteHereMap.addObject(siteGroup);
    };

    const getSiteDetails = async (evt, H) => {
        let data = evt.target.getData();
        evt.target.setIcon(new H.map.Icon(selIconSvg));
        // addClickedMarker(H, data);
        let details = await DBService.getSiteDetails({ filter: { sid: data.sid }, projection: { _id: 0 } });
        details = details[0];
        if (details.mob && details.mob.length === 10) {
            let numArr = details.mob.match(/.{1,3}/g);
            numArr[numArr.length - 2] = numArr[numArr.length - 2] + numArr[numArr.length - 1];
            numArr.splice(-1);
            details.formattedMob = "+91-" + numArr.join("-");
        }
        setSelectedSite(details);
        let a = document.getElementsByClassName("map_overlay");
        a[0].style.display = "block";
    };

    useEffect(() => {
        if (selectedSite) {
            if (siteGroup) {
                let objects = siteGroup.getObjects();
                if (prevSelectedSite && selectedSite.sid !== prevSelectedSite.sid) {
                    let objectIndex = objects.findIndex((o) => o.data.sid === prevSelectedSite.sid);
                    if (objectIndex > -1) {
                        objects[objectIndex].setIcon(new siteH.map.Icon(svgMarkup));
                    }
                }
            }
        }
    }, [selectedSite]);

    const onStateChange = (e) => {
        setBtnLoading(true);
        setCities([]);
        setZipcodes([]);
        setSelectedCity(null);
        setSelectedZip(null);
        setSelectedState(e.value);
    };

    useEffect(() => {
        cityDebounceFunction(getCitiesFromDb, 2000, selectedState);
    }, [selectedState]);

    const cityDebounceFunction = (func, delay, params) => {
        clearTimeout(citytimerId);
        citytimerId = setTimeout(() => {
            func(params);
        }, delay);
    };

    const getCitiesFromDb = async (states) => {
        if (states && states.length) {
            let st = selectedState.map((s) => s.st);
            let query = { filter: { st: { $in: st } }, projection: { ct: 1, count: 1, midlat: 1, midlong: 1 } };
            let zipquery = { filter: { st: { $in: st } }, projection: { zip: 1, count: 1, midlat: 1, midlong: 1 } };
            Promise.all([DBService.getSitesCities(query), DBService.getSitesZipcodes(zipquery)]).then((res) => {
                let cities = res[0];
                let zipcodes = res[1];
                setCities(cities);
                setZipcodes(zipcodes);
                setBtnLoading(false);
            });
        }
    };

    const onCityChange = (e) => {
        setSelectedCity(e.value);
    };

    const onZipCodeChange = (e) => {
        setSelectedZip(e.value);
    };
    const template = (options) => {
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className} onClick={options.onTogglerClick} style={{ cursor: "pointer" }}>
                <span className={titleClassName}>
                    <i className="pi-pw pi pi-filter"></i> Filter
                </span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        );
    };

    const validateInput = () => {
        if (!(selectedState && selectedState.length)) return "Please apply filters to see result";
    };

    const onFilterSubmit = async () => {
        const err = validateInput();

        if (err) {
            return setErrMsg(err);
        }

        setErrMsg(null);
        setBtnLoading(true);
        setShowLoader(true);
        let filter = {};
        if (selectedState && selectedState.length) {
            filter.st = selectedState.map((s) => s.st);
        }

        if (selectedCity && selectedCity.length) {
            filter.ct = selectedCity.map((s) => s.ct);
        }

        if (selectedZip && selectedZip.length) {
            filter.zip = selectedZip.map((s) => s.zip);
        }

        if (filter.st && !filter.ct && !filter.zip && !search) {
            getAllSitesForState(filter.st); //Get data from aggregate collection
        } else if (filter.st && filter.ct && !filter.zip && !search) {
            getAllSitesForCities(filter.ct); //Get data from aggregate collection
        } else if (filter.st && !filter.ct && filter.zip && !search) {
            getAllSitesForZipCodes(filter.zip); //Get data from aggregate collection
        } else if (filter.st && filter.ct && filter.zip && !search) {
            let sites = await DBService.getSitesLatLng({ filter: { st: { $in: filter.st }, ct: { $in: filter.ct }, zip: { $in: filter.zip } }, projection: { _id: 0, lat: 1, long: 1, sid: 1 } }); //  Get data from sites collection
            setBtnLoading(false);
            if (sites && sites instanceof Array && sites.length) {
                let a = document.getElementsByClassName("map_overlay");
                if (a) a[0].style.display = "none";
                setSites(sites);
                if (siteView) {
                    setSiteView(false);
                    setSiteView(true);
                } else setSiteView(true);
            } else {
                setShowLoader(false);
                setErrMsg("Don't have sites with filters");
            }
        } else if (search) {
            let sites = await DBService.getSitesLatLng({
                filter: {
                    st: { $in: filter.st },
                    $or: [{ ct: { $regex: search, $options: "i" } }, { add: { $regex: search, $options: "i" } }, { zip: { $regex: search, $options: "i" } }, { sid: { $regex: search, $options: "i" } }, { email: { $regex: search, $options: "i" } }, { mob: { $regex: search, $options: "i" } }],
                },
                projection: { _id: 0, lat: 1, long: 1, sid: 1 },
            }); //  Get data from sites collection
            setBtnLoading(false);

            if (sites && sites instanceof Array && sites.length) {
                let a = document.getElementsByClassName("map_overlay");
                if (a) a[0].style.display = "none";
                setSites(sites);
                if (siteView) {
                    setSiteView(false);
                    setSiteView(true);
                } else setSiteView(true);
            } else {
                setShowLoader(false);
                setErrMsg("Don't have sites with filters");
            }
        }
    };

    const onMapClick = (e) => {
        let a = document.getElementsByClassName("map_overlay");
        a[0].style.display = "none";
        if (siteGroup) {
            let objects = siteGroup.getObjects();
            if (selectedSite && selectedSite.sid) {
                let objectIndex = objects.findIndex((o) => o.data.sid === selectedSite.sid);
                if (objectIndex > -1) {
                    objects[objectIndex].setIcon(new siteH.map.Icon(svgMarkup));
                }
            }
            setSelectedSite(null);
        }
    };

    const onReset = () => {
        if (ui) {
            let bubbles = ui.getBubbles();
            if (bubbles.length) {
                bubbles.forEach((bub) => {
                    ui.removeBubble(bub);
                });
            }
        }
        let a = document.getElementsByClassName("map_overlay");
        if (a) a[0].style.display = "none";
        document.getElementById("mapref").style.display = "block";
        document.getElementById("sitemapref").style.display = "none";
        setCities([]);
        setZipcodes([]);
        setSelectedState(null);
        setSelectedCity(null);
        setSelectedZip(null);
        setErrMsg(null);
        setSiteView(false);
        setSearch("");
    };

    const handleSearch = (e) => {
        setSearch(e);
    };

    const onDownloadPdf = () => {
        setDisDownloadBtn(true);
        var resultContainer = document.getElementById("panel");
        capture(resultContainer, siteHereMap, siteUI);
    };

    return states && states.length ? (
        <>
            <Panel headerTemplate={template} toggleable collapsed="true">
                <div className="grid">
                    <div className="col-12 md:col-2">
                        <MultiSelect value={selectedState} options={states} onChange={onStateChange} optionLabel="st" placeholder=" Select State" className="m-2 bg-white w-full" filter />
                    </div>
                    {cities && cities.length ? (
                        <div className="col-12 md:col-2">
                            <MultiSelect value={selectedCity} onChange={onCityChange} options={cities} optionLabel="ct" className="m-2 bg-white w-full" placeholder="Select Cities" filter />
                        </div>
                    ) : null}
                    {zipcodes && zipcodes.length ? (
                        <div className="col-12 md:col-2">
                            <MultiSelect value={selectedZip} onChange={onZipCodeChange} options={zipcodes} optionLabel="zip" className="m-2 bg-white w-full" placeholder="Select Zipcodes" filter />
                        </div>
                    ) : null}
                    {selectedState && selectedState.length ? (
                        <div className="col-12 md:col-2">
                            <InputText value={search} onChange={(e) => handleSearch(e.target.value)} className="m-2 bg-white w-full" placeholder="Search" />
                        </div>
                    ) : null}

                    <div className="col-12 md:col-2">
                        <Button label="Search" className="m-2" loading={btnLoading} onClick={onFilterSubmit} />
                        <Button label="Reset" className=" p-button-danger m-2" onClick={onReset} />
                    </div>
                    <div className="col-12">{errMsg ? <span style={{ color: "red" }}>{errMsg}</span> : null}</div>
                </div>
            </Panel>
            {siteView && selectedSite ? (
                <div className="col-12" style={{ height: "3rem" }}>
                    <div style={{ float: "right" }}>
                        <Button label="Download" loading={disDownloadBtn} onClick={onDownloadPdf}></Button>
                    </div>
                </div>
            ) : null}
            <div style={{ paddingLeft: "100rem" }}>
                <div id="panel" style={{ position: "fixed", zIndex: -1000 }}></div>
            </div>

            <div className="col-12" style={{ padding: "0.5rem 0rem" }}>
                <div id="card-map" className="card p-0">
                    {cardWidth ? (
                        <>
                            {showLoader ? (
                                <div className="three_circle_loader flex flex-column" style={{ width: cardWidth }}>
                                    <div className="align-self-center mt-auto mb-auto">{threeCircleLoader(100, 100, "#264653")}</div>
                                </div>
                            ) : null}
                            <div ref={mapref} id="mapref" style={{ width: cardWidth }} onClick={() => onMapClick()}></div>
                            <div className="map_overlay" id="mapOverlay">
                                {selectedSite ? (
                                    <div className="col-12" style={{ overflowY: "auto", height: "70vh" }}>
                                        {SITE_MAPPING_SERVICE_LOGO[serviceId] ?
                                            <div className="info_data col-12">
                                                <img id="overlay_img" src={process.env.PUBLIC_URL + SITE_MAPPING_SERVICE_LOGO[serviceId]} alt="" style={{ height: "3.5rem" }} />
                                            </div> : null}
                                        <div className="info_data col-12">
                                            <p>Site ID</p>
                                            <span>{selectedSite.sid}</span>
                                        </div>
                                        {selectedSite.towertp ? (
                                            <div className="info_data col-12">
                                                <p>Tower Type</p>
                                                <span>{selectedSite.towertp}</span>
                                            </div>
                                        ) : null}
                                        {selectedSite.towerht ? (
                                            <div className="info_data col-12">
                                                <p></p>
                                                <span>{selectedSite.towerht}</span>
                                            </div>
                                        ) : null}
                                        <div className="info_data col-12">
                                            <p>Latitude</p>
                                            <span>{selectedSite.lat}</span>
                                        </div>
                                        <div className="info_data col-12">
                                            <p>Longitude</p>
                                            <span>{selectedSite.long}</span>
                                        </div>
                                        <div className="info_data col-12">
                                            <p>Wind Zone/Wind Speed</p>
                                            <span>{selectedSite.windZone + " / " + selectedSite.windSpeed}</span>
                                        </div>
                                        <div className="info_data col-12">
                                            <p>Address</p>
                                            <span>{selectedSite.add}</span>
                                        </div>
                                        <div className="info_data col-12">
                                            <p>City</p>
                                            <span>{selectedSite.ct}</span>
                                        </div>

                                        <div className="info_data col-12">
                                            <p>Zipcode</p>
                                            <span>{selectedSite.zip}</span>
                                        </div>
                                        <div className="info_data col-12">
                                            <p>State</p>
                                            <span>{selectedSite.st}</span>
                                        </div>

                                        <div className="info_data col-12">
                                            <p style={{ color: "#f4a261" }}>
                                                <u>Contact</u>
                                            </p>
                                            <p>Email</p>
                                            {selectedSite.mail ? (
                                                <a style={{ color: "#ffffff" }} href={"mailto:" + selectedSite.mail}>
                                                    {selectedSite.mail}
                                                </a>
                                            ) : (
                                                "-"
                                            )}
                                            <p>Phone Number</p>
                                            {selectedSite.mob ? (
                                                <a style={{ color: "#ffffff" }} href={"tel:" + selectedSite.mob}>
                                                    {selectedSite.mob}
                                                </a>
                                            ) : (
                                                "-"
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                            </div>

                            <div ref={sitemapref} id="sitemapref" style={{ width: cardWidth }} onClick={() => onMapClick()}></div>


                           <div style={{ width: cardWidth }} id="windZoneLegends">
                           {windZones && Object.keys(windZones).length ? 
                                <div className="col-12" style={{ paddingBottom: "0" }}>
                                    <div className="grid">
                                        <div className="col-2" style={{ fontWeight: "bold", fontSize: "1.2rem", paddingBottom: "0", padding: "0.8rem" }}>
                                            {"Wind Zone / Wind Speed"}
                                        </div>
                                        <div className="col-10" style={{ paddingBottom: "0" }}>
                                            <div className="grid flex flex-wrap" style={{ justifyContent: "space-evenly" }}>
                                                {Object.keys(WINDZONE_COLORS).map((w, i) => {
                                                    if (i + 1 <= zonesCount.current) {
                                                        return (
                                                            <div key={i} className="col-2 grid" style={{ alignItems: "center", justifyContent: "space-around" }}>
                                                                <div className="col-2" style={{ height: "2rem", width: "2rem", backgroundColor: WINDZONE_COLORS[w] }}></div>
                                                                <div className="col-6">{w + " / " + (windZones[w] ? windZones[w]?.windspeed : "-- kmph")}</div>
                                                            </div>
                                                        );
                                                    }
                                                    else return null
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>: null}
                            </div> 
                        </>
                    ) : null}
                </div>
            </div>
        </>
    ) : infoMsg ? (
        <div className="col-12">
            <div className="card flex flex-column align-items-center justify-content-center color3-bg " style={{ height: "60vh" }}>
                <span className="pi pi-info-circle" style={{ fontSize: "3rem" }}></span>
                <h3>{infoMsg}</h3>
            </div>
        </div>
    ) : (
        <Skeleton height="50px" className="mb-2"></Skeleton>
    );
};

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}
export default SiteDashboard;
