import React, { Component } from "react";
import { connect } from 'react-redux';
import { mapKey } from "../../../shared/data/const";
import { convertValue } from "../../../utilities/unitConversion";
import { dispatchAction } from '../../../utilities'; 

class CityReportComponent extends Component {
    mapRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            map: null
        }
    }

    componentDidMount = () => {
        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(this.mapRef.current, defaultLayers.vector.normal.map, {
            center: { lat: this.props.position[0].pos.lat, lng: this.props.position[0].pos.lng },
            zoom: 8,
            pixelRatio: window.devicePixelRatio || 1,
        });
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        H.ui.UI.createDefault(map, defaultLayers);
        this.setState({ map }, () => {
            this.addSVGMarkers(window.H, this.state.map, this.props.position);
        });
    };

    addSVGMarkers = (H, map, pos) => {

        var svgMarkup = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path style="fill:#01010100; stroke:none;" d="M0 0L0 32L32 32L32 0L0 0z"/><path style="fill:#ef3d49; stroke:none;" d="M15 28L17 28C19.368 24.4869 21.95 20.9627 23.5208 17C31.413 -2.9091 1.1202 -3.05081 8.2716 16C9.88256 20.2915 12.4852 24.1989 15 28z"/><path style="fill:#ef2121; stroke:none;" d="M15 5L16 6L15 5z"/><path style="fill:#ffffff; stroke:none;" d="M13.7461 7.03241C10.2098 9.89313 14.9493 15.6823 18.3927 12.3781C21.696 9.20841 17.2399 4.20618 13.7461 7.03241z"/><path style="fill:#ff166b; stroke:none;" d="M12 7L13 8L12 7z"/><path style="fill:#36d1ba; stroke:none;" d="M5 27L5 29C11.8299 31.882 20.17 31.882 27 29L27 27L20 25L20 27L24 27L24 28L8 28L8 27L12 27L12 25L5 27z"/></svg>';

        pos.forEach(item => {
            var bearsIcon = new H.map.Icon(svgMarkup),
            bearsMarker = new H.map.Marker({lat: item.pos.lat, lng: item.pos.lng },
            {icon: bearsIcon, data:item});
            bearsMarker.addEventListener('tap', this.onSVGMarkerClick);
            map.addObject(bearsMarker);
        });
    }
    onSVGMarkerClick = (evt) => {
        let eventData = evt.target.getData();
        if (eventData.customerSiteid) this.props.onMarkerClick(eventData);
    }

    // startClustering = (map, position) => {
    //     let pos = [];
    //     position.map((item) => pos.push(item.pos));
    //     const H = window.H;

    //     let dataPoints = pos.map((item) => {
    //         return new H.clustering.DataPoint(item.lat, item.lng);
    //     });

        
    //     let noiseSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#ef3d49; stroke:none;" d="M31 56L33 56C40.5258 44.8443 53.6928 28.5437 49.1968 14C44.4021 -1.50966 20.7607 -2.25617 15.1335 13.0008C9.81722 27.4147 23.4684 44.8265 31 56z"/><path style="fill:#fb2020; stroke:none;" d="M43 5L44 6L43 5M45 7L46 8L45 7z"/><path style="fill:#FFFFFF; stroke:none;" d="M31.0039 10.7184C19.6233 12.0631 21.6376 29.9849 32.9992 28.5008C44.379 27.0143 42.3713 9.37518 31.0039 10.7184z"/><path style="fill:#fb2020; stroke:none;" d="M24 14L25 15L24 14M39 14L40 15L39 14M27 27L28 28L27 27M17 32L18 33L17 32M24 45L25 46L24 45M38 47L39 48L38 47z"/><path style="fill:#35d1ba; stroke:none;" d="M40 50L50 56C37.8658 59.8545 26.1342 59.8545 14 56L23 50C6.51653 52.2305 13.5425 60.2246 26 61.7145C32.7432 62.521 42.7746 62.6744 48.9406 59.4275C58.7703 54.2514 44.1392 50.5345 40 50z"/><path style="fill:#fb2020; stroke:none;" d="M36 50L37 51L36 50z"/><path style="fill:#27dada; stroke:none;" d="M43 50L44 51L43 50M39 51L40 52L39 51M13 52L14 53L13 52M44 53L45 54L44 53z"/></svg>';
    //     let noiseIcon = new H.map.Icon(noiseSvg, {
    //         size: { w: 40, h: 40 },
    //         anchor: { x: 10, y: 10 },
    //     });

    //     let clusterSvgIcon = '<svg xmlns="http://www.w3.org/2000/svg">';
    //         clusterSvgIcon += '<g>';
    //         clusterSvgIcon += '<circle cx="{radius}px" cy="{radius}px" r="{radius}px" fill="#3f51b5" />';
    //         clusterSvgIcon += '<text x="25%" y="50%" font-family="Verdana" font-size="{fontSize}" fill="white">{count}</text>';
    //         clusterSvgIcon += '</g>';
    //         clusterSvgIcon += '</svg>';

    //     let clusteredDataProvider = new H.clustering.Provider(dataPoints, {
    //         theme: {
    //             getClusterPresentation: function (cluster) {
                    
    //                 var weight = cluster.getWeight();
                    
    //                 let radius = weight * 5;
    //                 let diameter = radius * 2;
                    
    //                 let svgString = clusterSvgIcon.replace(/\{count\}/g, weight).replace(/\{radius\}/g, radius).replace(/\{diameter\}/g, diameter).replace(/\{fontSize\}/g, radius);

    //                 let clusterIcon = new H.map.Icon(svgString, {
    //                     size: { w: diameter, h: diameter },
    //                     anchor: { x: radius, y: radius },
    //                 });
                    
    //                 let clusterMarker = new H.map.Marker(cluster.getPosition(), {
    //                     icon: clusterIcon,
    //                     min: cluster.getMinZoom(),
    //                     max: cluster.getMaxZoom(),
    //                 });
                    
    //                 clusterMarker.setData(cluster);
    //                 return clusterMarker;
    //             },
    //             getNoisePresentation: function (noisePoint) {
                    
    //                 let noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
    //                     icon: noiseIcon,
                        
    //                     min: noisePoint.getMinZoom(),
    //                 });
                    
    //                 noiseMarker.setData(noisePoint);
    //                 return noiseMarker;
    //             },
    //         },
    //         clusteringOptions: {
    //             eps: 0,
    //             minWeight: 2,
    //         },
    //     });
    //     let clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);
    //     map.addLayer(clusteringLayer);
    // };

    render = () => {
        return (
            <>
                <div className="col-12 lg:col-4" style={{overflow: 'hidden', overflowY: 'auto'}}>
                    {this.props.data.map((item, index) => (
                        <div className="card report_opt_card mb-3" key={item.circleName + "-" + index} onClick={this.props.onCityClick.bind(this, item.circleName)} style={{minHeight: 'fit-content'}}>
                            <div className="flex">
                                <div className="card_ioc_grid flex justify-content-center align-items-center mr-2">
                                    <i className="pi pi-map-marker" style={{ fontSize: "1.2em" }}></i>
                                </div>
                                <div className="flex-1">
                                    <p className="text-900 font-medium text-xl mb-0">
                                        {item.circleName}{" "}
                                        <span className="text-xl" style={{ float: "right" }}>
                                            Total: {item.totalSite}
                                        </span>
                                    </p>
                                    {item.details.map((elm, index) => (
                                        <span className="mr-2" key={"state-tower-type-" + index}>
                                            <b>{elm.type}</b>: {elm.total}
                                        </span>
                                    ))}
                                    {
                                        this.props.weather && this.props.weather.length > 0 ? (
                                            <p className="text-primary font-medium vertical-align-middle text-lg" style={{textTransform: 'capitalize'}}>{this.props.weather[index].weather[0].description}, <img  style={{ width: "1em",height: "1em",}} src={process.env.PUBLIC_URL + "/images/thermostat.svg" }/> {convertValue(this.props.weather[index].main.temp, "temperature", this.props.unit)}, {this.props.weather[index].wind.speed > 0 ? (
                                                <>Wind Speed: {convertValue(this.props.weather[index].wind.speed, "speed", this.props.unit, "mph")}</> 
                                            ) : null}</p> 
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="col-12 lg:col-8">
                    <div className="card">
                        <div
                            ref={this.mapRef}
                            style={{
                                width: "100%",
                                height: "76vh",
                                overflow: "hidden",
                            }}
                        ></div>
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
	return { unit: state.changeUnit }
};
export default connect(mapStateToProps, { dispatchAction })(CityReportComponent);