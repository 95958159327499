import React, { Component } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import VehicleOBDHistory from "./VehicleOBDHistory";
import VehicleOBDLive from "./VehicleOBDLive";

class VehicleDetailsObd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            make: null,
            model: null,
        };
    }

    componentDidMount = () => {
        this.setState({
            name: this.props.thingName,
            make: this.props.make,
            model: this.props.model,
        });
    };

    render = () =>
        this.state.name && this.state.make && this.state.model ? (
            <div className="grid m-0">
                <div className="col-12">
                    <TabView>
                        <TabPanel header="For The Day">
                            <VehicleOBDLive vehicleNumber={this.props.vehicleNumber} thingName={this.state.name} make={this.state.make} model={this.state.model} date={this.props.date} reportBack={this.props.reportBack} geofences={this.props.geofences} {...this.props} />
                        </TabPanel>
                        <TabPanel header="History">
                            <VehicleOBDHistory vehicleNumber={this.props.vehicleNumber} name={this.state.name} make={this.state.make} model={this.state.model} reportBack={this.props.reportBack} geofences={this.props.geofences} {...this.props} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        ) : null;
}

export default VehicleDetailsObd;
