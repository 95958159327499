import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import ReactSpeedometer from "react-d3-speedometer";
import { WaterQualityService } from "../../../service/WaterQualityService";

const styles = {
    dial: {
        display: "inline-block",
        width: `100%`,
        height: `auto`,
        color: "#000",
        textAlign: `center`,
        marginTop: `30px`,
        padding: "2px",
    },
    title: {
        fontSize: "1em",
        color: "#000",
    },
};

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ph: null,
            things: null,
            selectedThing: null,
        };
        this.waterQualityService = new WaterQualityService();
    }

    componentDidMount = () => {
        this.setState(
            {
                things: this.props.things,
                selectedThing: this.props.things[0],
            },
            () => this.initReport(this.state.selectedThing)
        );
    };

    initReport = (thingName) => {
        let thing = thingName.name;
        Promise.all([this.waterQualityService.performAction(thing, "ph", "status"), this.waterQualityService.performAction(thing, "tds", "status")])
            .then((res) => {
                // console.log(res);
                // if(res.status === 500) {
                //     console.log('cscsdc');
                // } else {
                //     let forPH = res[0];
                //     let forTDS = res[1];
                // }
            })
            .catch((reason) => {
                let myPH = {
                    topic: "at8hsszxuq/control/anu_aquarium/response",
                    data: "65432 OK 630",
                    status: 200,
                };
                // let myTDS = {
                //     "topic": "at8hsszxuq/control/anu_aquarium/response",
                //     "data": "65432 OK 3040 0 80 44 40",
                //     "status": 200
                // }

                let ph = this.getPH(myPH.data);
                this.setState({ ph });
            });
    };

    getPH = (data) => {
        let p = data.split(" ");
        return p[2] / 100;
    };

    handelThingChange = (e) => {
        this.setState({ selectedThing: e.value }, () => this.initReport(this.state.selectedThing));
    };

    render = () => {
        return (
            <>
                <div className="grid">
                    <div className="field col-12 lg:col-3">
                        <label htmlFor="basic">Select Thing</label>
                        <Dropdown value={this.state.selectedThing} options={this.state.things} onChange={this.handelThingChange} optionLabel="name" placeholder="Select a Thing" className="w-full" />
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 lg:col-3">
                        <div className="card">
                            <div className="flex align-items-center w-100">
                                {/* <div className="text-7xl" style={{lineHeight: '0.5', marginRight: '30px'}}>
                                <FcApproval />
                            </div> */}
                                <img src="images/ph.png" alt="PH images" style={{ width: "35px", height: "35px", marginRight: "19px" }} />
                                <div className="text-4xl text-blue-800">pH</div>
                                <div className="flex-1 text-4xl text-right">{this.state.ph ? this.state.ph : "--"}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-3">
                        <div className="card">
                            <div className="flex align-items-center w-100">
                                {/* <div className="text-7xl" style={{lineHeight: '0.5', marginRight: '30px'}}>
                                <FcApproval />
                            </div> */}
                                <img src="images/wqa_temp.png" alt="PH images" style={{ width: "35px", height: "35px", marginRight: "19px" }} />
                                <div className="text-4xl text-blue-800">Temperature</div>
                                <div className="flex-1 text-4xl text-right">26</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-3">
                        <div className="card">
                            <div className="flex align-items-center w-100">
                                {/* <div className="text-7xl" style={{lineHeight: '0.5', marginRight: '30px'}}>
                                <FcApproval />
                            </div> */}
                                <img src="images/ec.png" alt="PH images" style={{ width: "35px", height: "35px", marginRight: "19px" }} />
                                <div className="text-4xl text-blue-800">EC</div>
                                <div className="flex-1 text-4xl text-right">80</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-3">
                        <div className="card">
                            <div className="flex align-items-center w-100">
                                {/* <div className="text-7xl" style={{lineHeight: '0.5', marginRight: '30px'}}>
                                <FcApproval />
                            </div> */}
                                <img src="images/tds.png" alt="PH images" style={{ width: "35px", height: "35px", marginRight: "19px" }} />
                                <div className="text-4xl text-blue-800">TDS</div>
                                <div className="flex-1 text-4xl text-right">116</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-3">
                        <div className="card">
                            <div className="flex align-items-center w-100">
                                {/* <div className="text-7xl" style={{lineHeight: '0.5', marginRight: '30px'}}>
                                <FcApproval />
                            </div> */}
                                <img src="images/callibration.png" alt="PH images" style={{ width: "35px", height: "35px", marginRight: "19px" }} />
                                <div className="text-4xl text-blue-800">Controller Mode</div>
                                {/* <div className="flex-1 text-4xl text-right">26</div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        {this.state.ph ? (
                            <div style={styles.dial}>
                                <ReactSpeedometer
                                    maxValue={14}
                                    minValue={0}
                                    height={190}
                                    width={290}
                                    value={this.state.ph}
                                    needleTransition="easeQuadIn"
                                    needleTransitionDuration={1000}
                                    needleColor="black"
                                    segmentColors={["#EE1C25", "#F26724", "#F8C511", "#F5ED1C", "#B4D232", "#84C341", "#4CB648", "#32A84A", "#22B46B", "#0AB6B6", "#4690CD", "#3853A4", "#5A51A2", "#63459D", "#462C83"]}
                                    segments={14}
                                />
                                <div style={styles.title}>PH Value</div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </>
        );
    };
}

export default Dashboard;
