import { httpConstants } from "../lib/constants";
import { httpService } from "./httpService";

async function getThingsByService(serviceID) {
    let url = process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL + "/get-things/" + serviceID;
    return httpService(httpConstants.METHOD_TYPE.POST, {}, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0) return Promise.reject();
            return Promise.resolve(response.responseData);
        })
        .catch(function (err) {
            return Promise.reject(err);
        });
}

async function getQuickSiteUrl(payload) {
    let url = process.env.REACT_APP_ANALYTICS_SERVICE_URL + "/quicksight-signed-url";
    return httpService(httpConstants.METHOD_TYPE.POST, payload, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0) return Promise.reject();
            return Promise.resolve(response.responseData);
        })
        .catch(function (err) {
            return Promise.reject(err);
        });
}

async function getQuickSiteDashboardUrl(payload) {
    let url = process.env.REACT_APP_ANALYTICS_SERVICE_URL + "/quicksight-dashboard-url";
    return httpService(httpConstants.METHOD_TYPE.POST, payload, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0) return Promise.reject();
            return Promise.resolve(response.responseData);
        })
        .catch(function (err) {
            return Promise.reject(err);
        });
}

var apis = {
    getThingsByService,
    getQuickSiteUrl,
    getQuickSiteDashboardUrl
};

export default apis;
