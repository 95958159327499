import React, { Component } from "react";
import { connect } from "react-redux";
import { dispatchAction } from "../../../utilities";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import { Badge } from "primereact/badge";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Galleria } from "primereact/galleria";
import "./GalleriaAdvancedDemo.css";
import { convertValue } from "../../../utilities/unitConversion";

class TenancyComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            antennas: null,
            images: null,
            showDialog: false,
            position: "center",
            activeIndex: 2,
            rfAntenna: [],
            mwAntenna: [],
            tenantWise: [],
        };
        this.responsiveOptions = [
            {
                breakpoint: "1024px",
                numVisible: 5,
            },
            {
                breakpoint: "960px",
                numVisible: 4,
            },
            {
                breakpoint: "768px",
                numVisible: 3,
            },
            {
                breakpoint: "560px",
                numVisible: 1,
            },
        ];
        this.itemTemplate = this.itemTemplate.bind(this);
        this.thumbnailTemplate = this.thumbnailTemplate.bind(this);
        this.serviceId = localStorage.getItem("serviceId");
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.imageBodyTemplate = this.imageBodyTemplate.bind(this);
        this.lengthBodyTemplate = this.lengthBodyTemplate.bind(this);
        this.breadthBodyTemplate = this.breadthBodyTemplate.bind(this);
        this.heightBodyTemplate = this.heightBodyTemplate.bind(this);
        this.weightBodyTemplate = this.weightBodyTemplate.bind(this);
        this.heightfrombaseBodyTemplate = this.heightfrombaseBodyTemplate.bind(this);
        this.heightfromgroundBodyTemplate = this.heightfromgroundBodyTemplate.bind(this);

        this.allocatedLoadCapacityTemplate = this.allocatedLoadCapacityTemplate.bind(this);
        this.usedLoadCapacityTemplate = this.usedLoadCapacityTemplate.bind(this);
        this.usedLoadwithWindLoadTemplate = this.usedLoadwithWindLoadTemplate.bind(this);
    }
    componentDidMount() {
        let image_url = process.env.REACT_APP_IMAGE_STATIC_URL + "/";
        let images = [];
        let antennas = [];
        let tenants = [];
        let tenantWise = [];

        if (this.props.tower) {
            let rfAntenna = this.props.tower.tenants.antennas.filter((item) => item.type === "RF");
            let mwAntenna = this.props.tower.tenants.antennas.filter((item) => item.type === "MW");
            this.props.tower.tenants.antennas.forEach((element, index) => {
                if (tenants.length === 0 && element.tennantName !== "") {
                    tenants.push(element.tennantName);
                } else if (!tenants.includes(element.tennantName) && element.tennantName !== "") {
                    tenants.push(element.tennantName);
                }
                images.push({
                    itemImageSrc: image_url + element.antennaImageName,
                    thumbnailImageSrc: image_url + element.antennaImageName,
                    alt: "Antenna Image",
                    title: "Antenna",
                });
                antennas.push({
                    ...element,
                    index: index,
                });
            });
            if (tenants.length > 0) {
                tenantWise = tenants.map((elm) => {
                    let allocatedLoadCapacity = "";
                    let usedLoadCapacity = "";
                    let usedLoadwithWindLoad = "";
                    let rfcount = this.props.tower.tenants.antennas.filter((item) => item.type === "RF" && item.tennantName === elm);
                    let mwcount = this.props.tower.tenants.antennas.filter((item) => item.type === "MW" && item.tennantName === elm);

                    if (this.props.tower.hasOwnProperty("tenant")) {
                        if (this.props.tower.tenant.length > 0) {
                            let tenant_index = this.props.tower.tenant.findIndex((t) => t.name === elm);
                            if (tenant_index > -1) {
                                allocatedLoadCapacity = this.props.tower.tenant[tenant_index].allocatedLoad;
                                usedLoadCapacity = this.props.tower.tenant[tenant_index].usedLoad;
                                usedLoadwithWindLoad = this.props.tower.tenant[tenant_index].usedLoadwithWindLoad;
                            }
                        }
                    }

                    return {
                        name: elm,
                        rfAntenna: rfcount.length,
                        mwAntenna: mwcount.length,
                        allocatedLoadCapacity: allocatedLoadCapacity,
                        usedLoadCapacity: usedLoadCapacity,
                        usedLoadwithWindLoad: usedLoadwithWindLoad,
                    };
                });
            }
            this.setState({ antennas: antennas, images: images, rfAntenna, mwAntenna, tenantWise });
        }
    }

    onClick(name, index) {
        let state = {
            [`${name}`]: true,
            activeIndex: index,
        };
        this.setState(state);
    }

    statusBodyTemplate(item) {
        return item.antennaStatus === "Active" ? <Badge value={item.antennaStatus} severity="success"></Badge> : <Badge value={item.antennaStatus} severity="danger"></Badge>;
    }
    imageBodyTemplate(item) {
        return (
            <img
                src={`https://nextqore-images.s3.amazonaws.com/${item.antennaImageName}`}
                onError={(e) => (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")}
                alt={item.antennaImageName}
                className="antanna-image"
                onClick={(e) => this.onClick("showDialog", item.index)}
            />
        );
    }
    itemTemplate(item) {
        return <img src={item.itemImageSrc} alt={item.alt} height={400} width={400} style={{ objectFit: "contain" }} />;
    }

    thumbnailTemplate(item) {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ width: "100px", height: "80px" }} />;
    }
    lengthBodyTemplate(item) {
        return convertValue(item.length, "length", this.props.unit);
    }
    breadthBodyTemplate(item) {
        return convertValue(item.breadth, "breadth", this.props.unit);
    }
    heightBodyTemplate(item) {
        return convertValue(item.height, "height", this.props.unit);
    }
    weightBodyTemplate(item) {
        return convertValue(item.weight, "weight", this.props.unit);
    }
    heightfrombaseBodyTemplate(item) {
        return convertValue(item.heightfromBase, "height", this.props.unit);
    }
    heightfromgroundBodyTemplate(item) {
        return convertValue(item.heightfromGround, "height", this.props.unit);
    }

    allocatedLoadCapacityTemplate(item) {
        return convertValue(item.allocatedLoadCapacity, "weight", this.props.unit);
    }
    usedLoadCapacityTemplate(item) {
        if (item.usedLoadCapacity > item.allocatedLoadCapacity) {
            return <span style={{ color: "red" }}>{convertValue(item.usedLoadCapacity, "weight", this.props.unit)}</span>;
        } else {
            return convertValue(item.usedLoadCapacity, "weight", this.props.unit);
        }
    }
    usedLoadwithWindLoadTemplate(item) {
        if (item.usedLoadwithWindLoad > item.allocatedLoadCapacity) {
            return <span style={{ color: "red" }}>{convertValue(item.usedLoadwithWindLoad, "weight", this.props.unit)}</span>;
        } else {
            return convertValue(item.usedLoadwithWindLoad, "weight", this.props.unit);
        }
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false,
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Close" icon="pi pi-times" onClick={() => this.onHide(name)} autoFocus />
            </div>
        );
    }

    render = () => {
        let headerGroup = (
            <ColumnGroup>
                <Row>
                    <Column colSpan={10} className="text-0 color1_1-bg"></Column>
                    <Column className="text-0 color1_1-bg" header="Height From" colSpan={2}></Column>
                    <Column className="text-0 color1_1-bg" header="Azimuth" colSpan={2}></Column>
                    <Column className="text-0 color1_1-bg" colSpan={2}></Column>
                </Row>
                <Row>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="type" header="Type" sortable></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="tennantName" header="Tennant Name"></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="make" header="Make"></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="model" header="Model"></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="length" header="Length" sortable></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="breadth" header="Breadth" sortable></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="height" header="Depth" sortable></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="diameter" header="Diameter" sortable></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="weight" header="Weight" sortable></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} header="Image" body={this.imageBodyTemplate}></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="heightfromBase" header="Base" sortable></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="heightfromGround" header="Ground" sortable></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="azimuthTrueNorth" header="True North"></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="azimuthMagnetic" header="Magnetic"></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="downtilt" header="Downtilt"></Column>
                    <Column className="text-0 color1-bg" style={{ width: "100px" }} field="towerLeg" header="Tower Leg" sortable></Column>
                </Row>
            </ColumnGroup>
        );
        let tblHeader = (
            <ColumnGroup>
                <Row>
                    <Column className="text-0 color1-bg" header="Tenant"></Column>
                    <Column className="text-0 color1-bg" header="RF Antennas"></Column>
                    <Column className="text-0 color1-bg" header="MW Antennas"></Column>
                    <Column className="text-0 color1-bg" header="RRU"></Column>
                    <Column className="text-0 color1-bg" header="Allocated Load Capacity"></Column>
                    <Column className="text-0 color1-bg" header="Static Load"></Column>
                    <Column className="text-0 color1-bg" header="Wind Adjusted Load"></Column>
                </Row>
            </ColumnGroup>
        );
        return (
            <div className="grid">
                <Dialog header="" visible={this.state.showDialog} style={{ width: "50vw" }} footer={this.renderFooter("showDialog")} onHide={() => this.onHide("showDialog")}>
                    <Galleria value={this.state.images} responsiveOptions={this.responsiveOptions} activeIndex={this.state.activeIndex} numVisible={5} style={{ maxWidth: "100%", maxHeight: "100%" }} item={this.itemTemplate} thumbnail={this.thumbnailTemplate} />
                </Dialog>
                <div className="col-12 lg:col-12">
                    <div className="grid">
                        <div className="col-12 lg:col-12">
                            <div className="grid">
                                <div className="col-12 lg:col-6">
                                    <div className="card">
                                        <div className="flex justify-content-between mb-0">
                                            <div>
                                                <span className="block text-blue-600 font-medium mb-0">RF Antennas</span>
                                                <div className="text-900 font-medium text-xl my-3">{this.state.rfAntenna.length}</div>
                                            </div>
                                            <div>
                                                <span className="block text-blue-600 font-medium mb-0">MW Antennas</span>
                                                <div className="text-900 font-medium text-xl my-3">{this.state.mwAntenna.length}</div>
                                            </div>
                                            <div className="flex flex-column align-items-end">
                                                <div
                                                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                                                    style={{
                                                        width: "2.5rem",
                                                        height: "2.5rem",
                                                    }}
                                                >
                                                    <i className="pi pi-wifi text-blue-500 text-xl"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6">
                                    <div className="card">
                                        <div className="flex justify-content-between mb-0">
                                            <div>
                                                <span className="block text-blue-600 font-medium mb-0">Designed Load </span>
                                                <div className="text-900 font-medium text-xl my-3">
                                                    {this.props.tower.atcTowerLoading
                                                        ? this.props.tower.atcTowerLoading.deginedLoading
                                                        : this.props.tower.towerDetails.capacity.loadCapacityBuilt !== ""
                                                        ? convertValue(this.props.tower.towerDetails.capacity.loadCapacityBuilt, "weight", this.props.unit)
                                                        : "--"}
                                                </div>
                                            </div>
                                            <div>
                                                <span className="block text-blue-600 font-medium mb-0">Actual Load</span>
                                                {this.props.tower.atcTowerLoading ? (
                                                    <div className="font-medium text-xl my-3" style={{ color: "red" }}>
                                                        {this.props.tower.atcTowerLoading.actualLoading}
                                                    </div>
                                                ) : this.props.tower.towerDetails.capacity.usedLoadCapacity !== "" ? (
                                                    this.props.tower.towerDetails.capacity.usedLoadCapacity > this.props.tower.towerDetails.capacity.loadCapacityBuilt && this.props.tower.towerDetails.capacity.loadCapacityBuilt !== "" ? (
                                                        <div className="font-medium text-xl my-3" style={{ color: "red" }}>
                                                            {convertValue(this.props.tower.towerDetails.capacity.usedLoadCapacity, "weight", this.props.unit)}
                                                        </div>
                                                    ) : (
                                                        <div className="text-900 font-medium text-xl my-3">{convertValue(this.props.tower.towerDetails.capacity.usedLoadCapacity, "weight", this.props.unit)}</div>
                                                    )
                                                ) : (
                                                    "--"
                                                )}
                                            </div>
                                            <div>
                                                {/* <span className="block text-blue-600 font-medium mb-0">Wind Adjusted Load</span>
                                                <div className="text-900 font-medium text-xl my-3">
                                                    {
                                                        this.props.tower.towerDetails.capacity.usedLoadCapacityWithWindLoad !== '' ?
                                                        this.props.tower.towerDetails.capacity.usedLoadCapacityWithWindLoad > this.props.tower.towerDetails.capacity.loadCapacityBuilt && this.props.tower.towerDetails.capacity.loadCapacityBuilt !== '' ? (
                                                            <div className="font-medium text-xl my-3" style={{color: 'red'}}>
                                                                {
                                                                    convertValue(this.props.tower.towerDetails.capacity.usedLoadCapacityWithWindLoad, "weight", this.props.unit)
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div className="text-900 font-medium text-xl my-3">
                                                                {
                                                                    convertValue(this.props.tower.towerDetails.capacity.usedLoadCapacityWithWindLoad, "weight", this.props.unit)
                                                                }
                                                            </div>
                                                        ) : '--'
                                                    }
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.serviceId && this.serviceId === "ciyk7r5v37" ? null : (
                            <div className="col-12 lg:col-12">
                                <div className="card">
                                    <DataTable value={this.state.tenantWise} headerColumnGroup={tblHeader} stripedRows responsiveLayout="scroll">
                                        <Column field="name" className="text-xl color2-bg font-bold"></Column>
                                        <Column field="rfAntenna" className="text-xl color2-bg"></Column>
                                        <Column field="mwAntenna" className="text-xl color2-bg"></Column>
                                        <Column field="rru" className="text-xl color2-bg"></Column>
                                        <Column body={this.allocatedLoadCapacityTemplate} className="text-xl color2-bg"></Column>
                                        <Column body={this.usedLoadCapacityTemplate} className="text-xl color2-bg"></Column>
                                        <Column body={this.usedLoadwithWindLoadTemplate} className="text-xl color2-bg"></Column>
                                    </DataTable>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-12 lg:col-12">
                    <div className="card tenancyTbl">
                        <DataTable value={this.state.antennas} headerColumnGroup={headerGroup} stripedRows showGridlines sortField="type" sortOrder={-1} responsiveLayout="scroll" scrollable scrollHeight="70vh">
                            <Column field="type" className="text-md color2-bg" style={{ width: "100px" }}></Column>
                            <Column field="tennantName" className="text-md color2-bg font-bold" style={{ width: "100px" }}></Column>
                            <Column field="make" className="text-md color2-bg" style={{ width: "100px" }}></Column>
                            <Column field="model" className="text-md color2-bg" style={{ width: "100px" }}></Column>
                            <Column body={this.lengthBodyTemplate} className="text-md color2-bg" style={{ width: "100px" }}></Column>
                            <Column body={this.breadthBodyTemplate} className="text-md color2-bg" style={{ width: "100px" }}></Column>
                            <Column body={this.heightBodyTemplate} className="text-md color2-bg" style={{ width: "100px" }}></Column>
                            <Column field="diameter" className="text-xl color2-bg" style={{ width: "100px" }}></Column>
                            <Column body={this.weightBodyTemplate} className="text-md color2-bg" style={{ width: "100px" }}></Column>
                            <Column body={this.imageBodyTemplate} style={{ width: "100px" }} className="text-xl"></Column>
                            <Column body={this.heightfrombaseBodyTemplate} className="text-md color2-bg" style={{ width: "100px" }}></Column>
                            <Column body={this.heightfromgroundBodyTemplate} className="text-md color2-bg" style={{ width: "100px" }}></Column>
                            <Column field="azimuthTrueNorth" className="text-md color2-bg" style={{ width: "100px" }}></Column>
                            <Column field="azimuthMagnetic" className="text-md color2-bg" style={{ width: "100px" }}></Column>
                            <Column field="downtilt" className="text-md color2-bg" style={{ width: "100px" }}></Column>
                            <Column field="towerLeg" className="text-md color2-bg" style={{ width: "100px" }}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    };
}
const mapStateToProps = (state) => {
    return { unit: state.changeUnit };
};
export default connect(mapStateToProps, { dispatchAction })(TenancyComponent);
