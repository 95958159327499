import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Timeline } from 'primereact/timeline';
import "./Walmart/Lcix.scss"
import walmartService from './Walmart/walmartService';

const TrackingInfo = () => {
    const params = useParams()
    const id = params["id"]
    const [trackingDetails, setTrackingDetails] = useState([])

    useEffect(()=>{
        walmartService.getTrackingInfo(id).then((res)=>{
            if(res && res.responseData && res.responseData.length){
                let sortedTrackinDetails = res.responseData[0].parcelTrackingInfo.sort((a,b)=>{
                    const date1 = new Date(a.ts);
                    const date2 = new Date(b.ts);
                    return date1 - date2;
                })
                setTrackingDetails(sortedTrackinDetails ?? [])
            }
        })
    },[])

    const customizedMarker = (item) => {
        return (
            <span className="flex align-items-center justify-content-center text-white border-circle z-1 shadow-2" style={{ backgroundColor:item.status !== "CANCELED" ? "#26A541" : "#E41E27", width: "1.5rem", height: "1.5rem" }} >
                <i className='pi pi-check'></i>
            </span>
        );
    };
    const customizedContent = (item) => {
        return (
            <div className='p-0 '>
                <p className='m-0 mb-1 text-sm lg:font-semibold'>{item.status}</p>
                <p className=''>{item.ts}</p>
            </div>
        );
    };
    return (
        <div className='col-12'>
            <h1 className='text-center mb-0'>Order Tracking Status</h1>
            <div className='lg:col-offset-2 col-12 lg:col-8'>
                <div className='col-12 grid justify-content-between'>
                    <div className='col-12 lg:col-6'>
                        <div className='card '>
                            <h4 className='underline'>Your Tracking Id</h4>
                            <p className='text-lg ' style={{"wordWrap": "break-word"}}>{id ? id : ""}</p>
                        </div>
                    </div>
                    <div className='col-12 lg:col-6'>
                        <div className='card '>
                            <h4 className='underline'>Latest Status</h4>
                            <p className='text-base m-0 font-semibold'>{trackingDetails.length ? trackingDetails[trackingDetails.length -1].status : ""}</p>
                            <p className='text-base '>{trackingDetails.length ? trackingDetails[trackingDetails.length -1].ts : ""}</p>
                        </div>
                    </div>
                </div>
                <div className='col-12 grid justify-content-center px-3'>
                    <div className='overflow-auto col-12 h-25rem condition-true card'>
                        <h5 className='text-center'>Order Status Timeline</h5>
                        {trackingDetails.length ? <Timeline className='overflow-auto custom-timeline lcix_status' value={trackingDetails} layout="vertical" align="alternate" content={customizedContent} marker={customizedMarker} opposite={<span>&nbsp;</span>} /> : <p className='text-center'>No Timeline Data</p>}
                    </div>                   
                </div>

            </div>
        </div>
    )
}

export default TrackingInfo