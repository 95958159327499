const states = JSON.parse(localStorage.getItem("states"));
const initialState = states ? states : [];

const stateList = (state = initialState, action) => {
    switch (action.type) {
        case "SETSTATES":
            return (state = action.payload);
        default:
            return state;
    }
};
export default stateList;
