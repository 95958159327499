import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

function AddCustomerLogo(props) {
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [formValue, setFormValue] = useState({
        CustomerId: props.customerId,
        CustomerImage: "",
    });

    const handleChange = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const handleBack = () => {
        props.handleBackCustomer();
    };

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("customerId", formValue.CustomerId);
        formData.append("images", file);

        props.handelAddCustomerLogo(formData);
    };

    const handleFile = (event) => {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    };
    return (
        <>
            <div className="flex justify-content-center">
                <div className="flex flex-column gap-2 card ">
                    <label htmlFor="Customer Id" className="mt-4">
                        Customer Id*
                    </label>
                    <InputText id="username" placeholder="Customer Id" name="CustomerId" value={formValue.CustomerId} onChange={handleChange} />

                    <label htmlFor="Select Customer Images" className="mt-4">
                        Select Customer Images*
                    </label>
                    <InputText id="username" type="file" name="CustomerImages" onChange={handleFile} accept="image/png, image/jpeg, image/jpg" />

                    <span className="flex justify-content-between mt-4">
                        <Button label="Back" className="p-button-rounded p-button-danger" onClick={handleBack} />
                        {fileName !== "" && formValue.CustomerId !== "" ? <Button label="Upload" className="p-button-rounded p-button-success" onClick={handleSubmit} /> : <Button label="Upload" className="p-button-rounded p-button-success" onClick={handleSubmit} disabled />}
                    </span>
                </div>
            </div>
        </>
    );
}

export default AddCustomerLogo;
