import React from 'react'
import Report from "../../vehTrackingWithFuel/report"
const FuelReport = () => {
    return (
        <>
            <Report serviceType={"Fuel Tracking"} reportsAccess={["evtr"]} />
        </>
    )
}

export default FuelReport